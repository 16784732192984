import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Account,
  AccountService,
  Address,
  ConfigService,
  FeaturedContent,
  FeaturedContentName,
  FeaturedContentService,
  FeaturedItem,
  LoyaltyPlan,
  OpenCloseTime,
  Order,
  OrderService,
  OrderType,
  RecentlyOrderedItemsService,
  StoreConfig,
  StoreStatus,
  StoreStatusService,
  MerchantStore,
  OrderTypeConfig,
  OrderTimeInfo,
  DeferChoice,
} from 'ngx-web-api';
import { Subscription, of, from, Observable, combineLatest, iif } from 'rxjs';
import { Animations } from '../animations';
import { ModalService } from '../core/services/modal.service';
import { FeaturedItemService } from '../core/services/featured-item.service';
import { InitParamsStorageService } from '../core/services/init-params-storage.service';
import { MenuWrapperService } from '../core/services/menu-wrapper.service';
import { ParamsOrchestratorService } from '../core/services/params-orchestrator.service';
import { ThemeService } from '../core/services/theme.service';
import { Theme } from '../domain/theme.model';
import { AddressWrapperService } from '../core/services/address-wrapper.service';
import { map, mergeMap, take, tap, catchError, filter } from 'rxjs/operators';
import { UiOrchestratorService } from '../core/services/ui-orchestrator.service';
import { AccountLocalStorageService } from '../core/services/account-local-storage.service';
import { AddressSessionStorageService } from '../core/services/address-session-storage.service';
import { BreakpointService } from 'app/core/services/breakpoint.service';
import { faStore, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { DateFormatPipe } from 'ngx-web-api/lib/pipes/date-format.pipe';
import { ReloaderService } from 'app/core/services/reloader.service';
import { GhostHeightsService } from 'app/menu/ghost-heights.service';

@Component({
  selector: 'fts-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    Animations.expanded,
    trigger('fullWidth', [
      state(
        'inactive',
        style({
          width: '250px',
          transform: 'width',
        })
      ),
      state(
        'active',
        style({
          width: '100%',
          transform: 'width',
        })
      ),
      transition('inactive => active', animate('1s linear')),
      transition('active => inactive', animate('.5s 0.5s linear')),
    ]),
    trigger('fullWidthOrderType', [
      state(
        'inactive',
        style({
          left: '0',
          transform: 'left',
        })
      ),
      state(
        'active',
        style({
          left: 'calc(50% - 125px)',
          transform: 'left',
        })
      ),
      transition('inactive => active', animate('1s linear')),
      transition('active => inactive', animate('.5s 0.5s linear')),
    ]),
  ],
})
export class HomeComponent implements OnInit, AfterViewInit {
  expanding = 'inactive';
  expanded = false;
  featuredContent: FeaturedContent;
  store: StoreConfig;
  account: Account;
  loyaltyPlan: LoyaltyPlan;
  order: Order = Order.createDummyOrder();
  orderTimeSet: boolean;
  loadingSubscription: Subscription;
  isMobile = false;
  pendingFeaturedItem?: FeaturedItem;
  theme: Theme;
  timeChooserOverflowVisible = false;
  customerAddress: Address = new Address();
  deferChoice: DeferChoice;
  orderDate: string;
  storeStatus: StoreStatus;
  shouldShowStartButton = false;
  openCloseTime: OpenCloseTime | undefined;
  loadingStates: Subscription;
  isStoreChooserOpened: boolean;
  currentStore: MerchantStore;
  shouldShowFavoriteStoreBtn: boolean;
  favoriteStoreSubscription: Subscription;
  headerFeaturedLinks$: Observable<FeaturedContent[]>;
  isGroupOrder: boolean;
  private orderTypesConfig: OrderTypeConfig[] = [];
  selectedOrderTypeConfig: OrderTypeConfig;
  embedded = false;
  faStore = faStore;
  faCaretDown = faCaretDown;
  orderTimeInfo: OrderTimeInfo;
  hasAvailableDeferOptions: boolean;
  dateFormat = new DateFormatPipe();
  showOrderError = false;
  itemImgHeight: number;

  @ViewChild('storeLogoWrapper', { static: false })
  private storeLogoWrapper: ElementRef;

  constructor(
    private featuredContentService: FeaturedContentService,
    private featuredItemService: FeaturedItemService,
    private accountService: AccountService,
    private configService: ConfigService,
    private router: Router,
    private menuService: MenuWrapperService,
    private orderService: OrderService,
    private storeStatusService: StoreStatusService,
    private themeService: ThemeService,
    private activatedRoute: ActivatedRoute,
    private initParamsStorageService: InitParamsStorageService,
    private recentlyOrderedItemsService: RecentlyOrderedItemsService,
    private paramsOrchestratorService: ParamsOrchestratorService,
    private cdRef: ChangeDetectorRef,
    private addressService: AddressWrapperService,
    private uiOrchestratorService: UiOrchestratorService,
    private modalService: ModalService,
    private accountLocalStorage: AccountLocalStorageService,
    private addressSessionStorageService: AddressSessionStorageService,
    public breakpointService: BreakpointService,
    private reloaderService: ReloaderService,
    private ghostHeightsService: GhostHeightsService
  ) {}

  ngOnInit() {
    this.embedded = this.initParamsStorageService.initParams.embedded;
    this.itemImgHeight = this.ghostHeightsService.getLogoImageHeight();
    this.featuredContentService
      .getFeaturedContentsByAreaName(FeaturedContentName.HOME_PAGE)
      .pipe(
        filter(fc => !!fc),
        take(1)
      )
      .subscribe(featureContents => (this.featuredContent = featureContents[0]));

    this.headerFeaturedLinks$ = this.featuredContentService.getFeaturedContentsByAreaName(FeaturedContentName.HEADER_LINKS);

    this.storeStatusService.storeStatus$.subscribe(storeStatus => {
      this.storeStatus = storeStatus;
      this.computeOpenCloseTime();
    });

    this.activatedRoute.data.subscribe(data => {
      this.store = data['store'];
      const initParams = this.initParamsStorageService.initParams;
      this.isGroupOrder = initParams.makeItAGroupOrder;
    });

    this.configService.loyaltyPlan$.subscribe(plan => (this.loyaltyPlan = plan));
    this.accountService.account$.subscribe(account => (this.account = account));
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
      this.shouldShowStartButton = this.computeShouldShowStartButton();
    });
    this.configService.orderTypesConfig$.subscribe(configs => {
      this.orderTypesConfig = configs;

      if (this.initParamsStorageService.initParams.orderType) {
        this.chooseOrderType(this.initParamsStorageService.initParams.orderType);
        return;
      }

      if (
        this.store.defaultOrderType &&
        this.orderTypesConfig.length &&
        this.orderTypesConfig.map(orderType => orderType.type).indexOf(this.store.defaultOrderType as OrderType) !== -1
      ) {
        this.chooseOrderType(this.store.defaultOrderType as OrderType);
      } else if (this.orderTypesConfig.length === 1) {
        this.chooseOrderType(this.orderTypesConfig[0].type as OrderType);
      }
    });
    this.initOrderTypeAndAddressFromParams();

    this.uiOrchestratorService.storeStates$.subscribe(ss => (this.isStoreChooserOpened = !!ss));

    combineLatest([
      iif(() => this.store.hasStoreChooser, this.configService.fetchMerchantStores(this.store.state), of([])),
      this.accountService.account$,
    ]).subscribe(
      result => {
        const merchantStores = result[0];
        const account = result[1];

        this.currentStore = merchantStores.find(s => s.isCurrentStore);
        this.account = account;
        this.shouldShowFavoriteStoreBtn =
          this.account && this.account.isInstantiated && this.currentStore && this.account.favoriteStore !== this.currentStore.primaryKey;
      },
      e => this.modalService.parseAndNotifyErrors(e)
    );

    this.uiOrchestratorService.setNoContactToSession(false);
  }

  ngAfterViewInit() {
    this.checkMobileSize();
    this.cdRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkMobileSize();
  }

  checkMobileSize() {
    this.isMobile = window.innerWidth <= 767;
  }

  get shouldShowOrderTimeChooser() {
    return this.theme && this.theme.askForOrderTimeUpfront && this.order.hasOrderType;
  }

  get canContinueToMenu() {
    const selectedDeferChoiceIsNotAsap =
      (this.deferChoice && this.deferChoice !== DeferChoice.Asap) || (!this.deferChoice && this.computeShouldShowStartButton());

    if (!this.order.isInitialized) {
      return false;
    }
    if (this.theme && this.theme.askForOrderTimeUpfront && !this.order.deferTime && selectedDeferChoiceIsNotAsap) {
      return false;
    }
    return true;
  }

  handleStoreIconClick() {
    if (this.isStoreChooserOpened) {
      this.closeStoreChooser();
    } else {
      this.openStoreChooser();
    }
  }

  handleChangeClick() {
    this.openStoreChooser();
  }

  handleLogin(): Promise<any> {
    if (this.router.url && !(this.router.url.indexOf('/login') === 0)) {
      return this.activatedRouteData()
        .pipe(
          map(data => data['skipRedirect']),
          map(skipRedirect => (skipRedirect ? {} : { target: decodeURIComponent(this.router.url) })),
          mergeMap(queryParams => this.router.navigate(['/login'], { queryParams }))
        )
        .toPromise();
    }
    return Promise.resolve();
  }

  handleLogout(): void {
    this.accountService.logout().subscribe(
      () => {
        this.accountLocalStorage.setAccountSignedInOut('true');
      },
      () => this.reloaderService.reload('/')
    );
  }

  chooseOrderType(orderType: OrderType) {
    if (!this.orderTypesConfig.find(ot => ot.type === orderType)) {
      return;
    }
    if (orderType !== this.order.orderType) {
      this.order.orderType = orderType;
      this.storeStatusService.updateStoreStatus(orderType);
      this.shouldShowStartButton = this.computeShouldShowStartButton();
      this.order.deferTime = undefined;
      this.selectedOrderTypeConfig = this.orderTypesConfig.find(ot => ot.type === this.order.orderType) || new OrderTypeConfig();
      this.initOrderTimeInfo(orderType);
    }
    if (this.canContinueToMenu) {
      this.showOrderError = false;
    }
  }

  chooseOrderTime(date: string | undefined) {
    this.order.deferTime = date;
    this.orderTimeSet = true;
    this.showOrderError = false;
  }

  chooseOrderDate(date: string | undefined) {
    this.orderDate = date;
    this.computeOpenCloseTime();
  }

  chooseDeferChoice(deferChoice: DeferChoice | undefined) {
    this.deferChoice = deferChoice;
    this.shouldShowStartButton = this.computeShouldShowStartButton();
    this.computeOpenCloseTime();
    if (this.canContinueToMenu) {
      this.showOrderError = false;
    }
  }

  continueToMenu() {
    if ((this.isGroupOrder || this.pendingFeaturedItem || !this.theme.skipIntroPage) && !this.canContinueToMenu) {
      this.showOrderError = true;
      return;
    }
    if (this.canContinueToMenu) {
      this.order.isMobileSource = this.initParamsStorageService.initParams.sourceIsMobile;
      this.loadingSubscription = this.orderService
        .createOrder(this.order)
        .pipe(
          mergeMap(() =>
            !!this.customerAddress.street
              ? of(this.addressSessionStorageService.setAddress(this.customerAddress)).pipe(
                  mergeMap((_unusedVariable: void) => this.orderService.updateOrderCustomerAddress(this.customerAddress)),
                  map(res => !!res),
                  catchError(() => of(false)),
                  mergeMap((successCall: boolean) => (successCall ? of(this.addressSessionStorageService.deleteAddress()) : of(null)))
                )
              : of(null)
          ),
          mergeMap(() => this.menuService.getLatestMenu(this.order.orderType, this.order.deferTime)),
          mergeMap(() => {
            if (this.pendingFeaturedItem && this.pendingFeaturedItem.special) {
              return this.featuredContentService.fetchFeaturedContents(this.order.orderType, this.order.deferTime).pipe(
                tap((featuredContent: FeaturedContent[]) => {
                  this.pendingFeaturedItem = featuredContent
                    .map(featured =>
                      featured.featuredItems.find(({ special }) => {
                        return !!special && !!this.pendingFeaturedItem && special.name === this.pendingFeaturedItem.special.name;
                      })
                    )
                    .find(value => !!value);
                })
              );
            } else {
              return this.featuredContentService.fetchFeaturedContents(this.order.orderType, this.order.deferTime);
            }
          }),
          mergeMap(() => this.recentlyOrderedItemsService.getRecentlyOrderedItems()),
          take(1),
          mergeMap(() => {
            const params = this.isGroupOrder ? { makeItAGroupOrder: true } : {};
            const menuRedirect$ = from(
              this.router.navigate(['menu'], {
                queryParams: params,
                queryParamsHandling: 'merge',
              })
            );
            const markOrderInitDone = () => this.paramsOrchestratorService.markOrderInitializationDone();

            if (this.pendingFeaturedItem) {
              const addFeaturedItem$ = this.featuredItemService.addFeaturedItemToOrder(
                this.pendingFeaturedItem,
                this.featuredContent.name || this.featuredContent.title,
                this.featuredContent.areaName.toString()
              );
              return this.pendingFeaturedItem.special
                ? menuRedirect$.pipe(
                    tap(markOrderInitDone),
                    mergeMap(() => addFeaturedItem$)
                  )
                : addFeaturedItem$.pipe(tap(markOrderInitDone));
            }
            return menuRedirect$.pipe(tap(markOrderInitDone));
          })
        )
        .subscribe(
          () => {},
          response => this.modalService.parseAndNotifyErrors(response)
        );
    } else {
      this.loadingSubscription = this.menuService.fetchMenuAndFeaturedContents(OrderType.Any, undefined).subscribe(
        () => {
          this.router.navigate(['menu']);
        },
        error => this.modalService.parseAndNotifyErrors(error)
      );
    }
  }

  featuredItemClicked(featuredItem: FeaturedItem) {
    if (featuredItem && featuredItem.hasOrderable) {
      this.pendingFeaturedItem = featuredItem;
      if (!this.isMobile) {
        this.expanding = 'active';
      }
    }
  }

  clearPendingFeaturedItem() {
    this.pendingFeaturedItem = undefined;
    if (!this.isMobile) {
      this.expanding = 'inactive';
    }
  }

  fullWidthAnimationDone() {
    if (this.expanding === 'active') {
      this.expanded = true;
    }
  }

  fullWidthAnimationStart() {
    if (this.expanding === 'inactive') {
      this.expanded = false;
    }
  }

  handleMyFavoriteStore() {
    if (this.currentStore && this.account && this.account.isInstantiated) {
      this.favoriteStoreSubscription = this.setMyFavoriteStore(this.currentStore.primaryKey).subscribe(
        account => {
          this.account = account;
          this.shouldShowFavoriteStoreBtn = false;
        },
        err => this.modalService.parseAndNotifyErrors(err)
      );
    }
  }

  setInitHeight() {
    const logoHeight = ((this.storeLogoWrapper.nativeElement as HTMLHtmlElement).querySelector(`fts-store-logo img`) as HTMLImageElement)
      ?.offsetHeight;
    this.ghostHeightsService.setLogoImageHeight(logoHeight);
  }

  private initOrderTimeInfo(orderType: string, street?: string, zip?: string) {
    if (this.storeStatus.isOpen) {
      this.orderService.getAsapNote(orderType, street, zip).subscribe(orderTimeInfo => (this.orderTimeInfo = orderTimeInfo));
    }
  }

  private setMyFavoriteStore(favoriteStore: string): Observable<Account> {
    const accountCopy = new Account().deserialize({ ...this.account, favoriteStore });
    return this.accountService.updateFavoriteStore(accountCopy);
  }

  private activatedRouteData() {
    return of(this.activatedRoute).pipe(
      map((route: ActivatedRoute) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route: ActivatedRoute) => route.data)
    );
  }

  private initOrderTypeAndAddressFromParams() {
    const initParams = this.initParamsStorageService.initParams;

    if (initParams.orderType) {
      const orderType = initParams.orderType as OrderType;
      this.chooseOrderType(orderType);

      const orderTypeConfig = this.orderTypesConfig.find(ot => ot.type === orderType);
      if (!!orderTypeConfig && orderTypeConfig.needsAddress) {
        this.addressService
          .extractAddressAttributesFromParams(initParams)
          .subscribe(r => (this.customerAddress = Object.assign(new Address(), r)));

        if (this.store.usesAutomatedPromiseTime) {
          this.initOrderTimeInfo(orderType, this.customerAddress.street, this.customerAddress.zip);
        } else {
          this.initOrderTimeInfo(orderType);
        }
      } else {
        this.initOrderTimeInfo(orderType);
      }
    }
  }

  private computeOpenCloseTime() {
    if (this.deferChoice === DeferChoice.Future) {
      this.openCloseTime = this.orderDate ? this.storeStatus.getOpenCloseTime(this.orderDate) : undefined;
    } else if (
      !this.storeStatus.hasToday &&
      !this.hasAvailableDeferOptions &&
      this.selectedOrderTypeConfig &&
      !this.selectedOrderTypeConfig.allowWebDeferredOrders
    ) {
      this.openCloseTime = this.storeStatus.getOpenCloseTime(
        this.dateFormat.transform(this.storeStatus.nextOpenTime, this.store.country === 'IE' ? 'yyyy-dd-MM' : 'yyyy-MM-dd')
      );
    } else {
      this.openCloseTime = this.storeStatus.getOpenCloseTime();
    }
  }

  private computeShouldShowStartButton(): boolean {
    return (
      (!!this.deferChoice && this.deferChoice !== DeferChoice.Asap) ||
      !this.hasAvailableDeferOptions ||
      (!!this.theme && !this.theme.askForOrderTimeUpfront && this.order.orderType !== OrderType.Any)
    );
  }

  private openStoreChooser() {
    this.uiOrchestratorService.storeChooseModalTriggered();
    this.loadingStates = this.configService.fetchStoreStates().subscribe(
      ss => {
        this.uiOrchestratorService.setStoreStates(ss);
      },
      e => this.modalService.parseAndNotifyErrors(e)
    );
  }

  private closeStoreChooser() {
    if (this.loadingStates && !this.loadingStates.closed) {
      this.loadingStates.unsubscribe();
    }
    this.uiOrchestratorService.setStoreStates(null);
  }
}
