import { BaseResource } from '../core/base-resource';

export class Tag extends BaseResource {
  backgroundColor: string;
  displayName: string;
  name: string;
  ordinal: number;
  textColor: string;
  abbreviation: string;
}
