import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { ConfirmationModalContext } from '../../../domain/confirmation-modal-context';
import { ModalService } from '../../services/modal.service';
import { NotificationModalType } from '../../../domain/notification-modal-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fts-store-logo',
  templateUrl: './store-logo.component.html',
  styleUrls: ['./store-logo.component.scss'],
})
export class StoreLogoComponent {
  @Input()
  src: string;
  @Input()
  hasFullWidth: boolean;
  @Input()
  compact: boolean;
  @Input()
  logoRedirectToStaticSite: boolean;
  @Input()
  staticSiteUrl: string;
  @Input()
  alignCenter = true;
  @Input()
  inPay = false;
  @Input()
  homepage: boolean;
  @Input()
  itemImgHeight: number;
  @Input()
  isInPrintReceipt = false;
  @Output()
  loadEvent = new EventEmitter();

  constructor(private modalService: ModalService, private router: Router, private translateService: TranslateService) {}

  onLoad() {
    this.loadEvent.emit();
  }

  onClick() {
    if (!(this.logoRedirectToStaticSite && this.staticSiteUrl)) {
      if (this.router.url.indexOf('/editor') !== -1) {
        this.router.navigate(['/menu']);
      } else {
        this.router.navigate(['/intro']);
      }
    } else {
      this.redirectToStaticSite();
    }
  }

  private redirectToStaticSite() {
    const context = new ConfirmationModalContext();
    context.title = this.translateService.instant('component.header.store_logo.redirect_to_static_modal.title');
    context.mainContent = this.translateService.instant('component.header.store_logo.redirect_to_static_modal.mainContent');
    context.submitBtnTitle = this.translateService.instant('component.header.store_logo.redirect_to_static_modal.submitBtnTitle');
    context.dismissBtnTitle = this.translateService.instant('component.header.store_logo.redirect_to_static_modal.dismissBtnTitle');
    context.textOnly = true;
    context.type = NotificationModalType.warning;
    context.submitObservable = of(true);
    context.reverseButtons = true;

    this.modalService.openConfirmationModal(context, false).subscribe(result => {
      if (result) {
        document.location.assign(this.staticSiteUrl);
      }
    });
  }
}
