// List based on the following documentation (We use ApplePay JS API version 3)
// https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916122-supportednetworks?language=javascript

export enum ApplePaySupportedNetworks {
  'amex',
  'chinaUnionPay',
  'discover',
  'interac',
  'jcb',
  'masterCard',
  'privateLabel',
  'visa',
}
