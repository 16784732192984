import { LocalStorageKeys } from './../../domain/local-storage-keys.enum';
import { BrowserStorageHandlerService } from './../services/browser-storage-handler.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Account,
  AccountService,
  ConfigService,
  FeaturedContent,
  FeaturedContentService,
  LoyaltyPlan,
  Order,
  OrderService,
  OrderTypeConfig,
  Panel,
  StoreConfig,
  StoreStatus,
  StoreStatusService,
  FeaturedContentName,
  FeaturedItem,
  OrderType,
  AuditService,
  SocialLinks,
  Utils,
  OrderDeferOptions,
  OpenCloseTime,
  CalendarEvent,
  DeferService,
} from 'ngx-web-api';
import { Observable, of, combineLatest, from, noop, EMPTY } from 'rxjs';
import { filter, map, mergeMap, distinctUntilChanged, take, catchError, tap, switchMap } from 'rxjs/operators';
import { MobileMenu } from '../../domain/mobile-menu.enum';
import { Theme } from '../../domain/theme.model';
import { AccountLocalStorageService } from '../services/account-local-storage.service';
import { InitParamsStorageService } from '../services/init-params-storage.service';
import { MenuWrapperService } from '../services/menu-wrapper.service';
import { ThemeService } from '../services/theme.service';
import { UiOrchestratorService } from '../services/ui-orchestrator.service';
import { BreakpointService } from '../services/breakpoint.service';
import { FocusService } from 'app/focus.service';
import { SocialLinksService } from '../services/social-links.service';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { OrderingUtilsService } from '../services/ordering-utils.service';
import { ModalService } from '../services/modal.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PathService } from 'app/shared/services/path.service';
import { ReloaderService } from '../services/reloader.service';
import { DateFormatPipe } from 'ngx-web-api/lib/pipes/date-format.pipe';

@Component({
  selector: 'fts-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
  store: StoreConfig;
  panel: Panel;
  visibleMenu?: MobileMenu;
  order: Order;
  account: Account;
  loyaltyPlan: LoyaltyPlan;
  storeStatus: StoreStatus;
  theme: Theme;
  storeStates: string[];
  orderTypeConfig: OrderTypeConfig;
  orderTypeConfigs$: Observable<OrderTypeConfig[]>;
  hasMultipleOrderTypeConfigs: boolean;
  headerLinksFeaturedContent$: Observable<FeaturedContent[]>;
  featuredAnnouncements$: Observable<FeaturedItem[]>;
  isInCheckout = false;
  embedded = false;
  cachedOrderType: OrderType;
  cachedDeferTime: string;
  socialLinks$: Observable<SocialLinks>;
  showBanner$: Observable<boolean>;
  bannerUrl: string;
  faTimes = faTimes;
  isMobile: boolean;
  bannerInLocalStorage: string;
  orderAndOrderTypeConfig$: Observable<{ order: Order; orderTypeConfig: OrderTypeConfig }>;
  isSelfOrder$: Observable<boolean>;
  modalRef: NgbModalRef;
  isInSearch = false;
  orderDeferOptions: OrderDeferOptions;
  orderDeferOptionsFetched: boolean;

  @Input()
  isInIntro: boolean;
  @Input()
  isInHome: boolean;
  @Input()
  isInEditor: boolean;
  @Input()
  isInPay: boolean;
  @Input()
  isInPrintReceipt: boolean;
  @Output()
  logoHeightChange: EventEmitter<number> = new EventEmitter();
  @Output()
  notificationsHeightChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('header', { static: false })
  private header: ElementRef;
  @ViewChild('wholeHeader', { static: false })
  private wholeHeader: ElementRef;
  @ViewChild('storeSelectionTemplate', { read: TemplateRef, static: true })
  storeSelectionModalTemplate: TemplateRef<any>;
  selectedDeferDateCalendarDescription: string;
  calendarEvents: CalendarEvent[];
  dateFormat = new DateFormatPipe();

  constructor(
    private configService: ConfigService,
    private menuService: MenuWrapperService,
    private orderService: OrderService,
    private accountService: AccountService,
    private storeStatusService: StoreStatusService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private initParamsStorageService: InitParamsStorageService,
    private uiOrchestrationService: UiOrchestratorService,
    private accountLocalStorage: AccountLocalStorageService,
    private featuredContentService: FeaturedContentService,
    public breakpoint: BreakpointService,
    private focusService: FocusService,
    private auditService: AuditService,
    private socialLinksService: SocialLinksService,
    private orderingUtilsService: OrderingUtilsService,
    private modalService: ModalService,
    private ngbModalService: NgbModal,
    private pathService: PathService,
    private reloaderService: ReloaderService,
    private deferService: DeferService,
    private browserStorageHandlerService: BrowserStorageHandlerService
  ) {
    this.socialLinksService.bannerUrl$.subscribe(bannerUrl => (this.bannerUrl = bannerUrl));
    this.showBanner$ = this.socialLinksService.showBanner$;
    this.socialLinks$ = this.socialLinksService.socialLinks$;
    this.bannerInLocalStorage = this.browserStorageHandlerService.getLocalStorageItem(LocalStorageKeys.MOBILE_APP_BANNER_VISIBILITY);
    this.isSelfOrder$ = this.orderingUtilsService.isSelfOrder$.pipe(take(1));
    this.pathService.inSearch$.pipe(distinctUntilChanged()).subscribe(inSearch => (this.isInSearch = inSearch));
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateVerticalMenuHeight();
    this.menuService.setHeaderHeight(this.wholeHeader?.nativeElement?.clientHeight);
  }

  ngOnInit() {
    this.embedded = this.initParamsStorageService.initParams.embedded;
    this.configService.storeConfig$.subscribe(store => (this.store = store));
    this.isSelfOrder$.pipe(take(1)).subscribe(isSelfOrder => {
      if (isSelfOrder) {
        this.orderTypeConfigs$ = this.configService.allOrderTypesConfig$;
      } else {
        this.orderTypeConfigs$ = this.configService.orderTypesConfig$;
      }
    });
    this.orderTypeConfigs$.subscribe(
      orderTypeConfigs => (this.hasMultipleOrderTypeConfigs = orderTypeConfigs && orderTypeConfigs.length > 1)
    );
    this.configService.loyaltyPlan$.subscribe(plan => (this.loyaltyPlan = plan));
    this.menuService.menu$.subscribe(panel => (this.panel = panel));

    this.orderAndOrderTypeConfig$ = this.orderService.order$.pipe(
      mergeMap((order: Order) =>
        this.configService.findOrderTypeConfig(order.orderType).pipe(map(orderTypeConfig => ({ order, orderTypeConfig })))
      )
    );

    this.orderAndOrderTypeConfig$.subscribe(({ order, orderTypeConfig }) => {
      this.order = order;
      this.orderTypeConfig = orderTypeConfig;
    });

    this.uiOrchestrationService.storeChooseModalOpened$
      .pipe(
        mergeMap(() => this.orderAndOrderTypeConfig$.pipe(take(1))),
        mergeMap(({ order, orderTypeConfig }) =>
          this.orderService.getOrderDeferOptions().pipe(
            map(orderDeferOptions => ({ order, orderTypeConfig, orderDeferOptions })),
            catchError(() => {
              return of({ order, orderTypeConfig, orderDeferOptions: null });
            })
          )
        )
      )
      .subscribe(({ order, orderTypeConfig, orderDeferOptions }) => {
        this.cachedDeferTime = order.deferTime;
        this.cachedOrderType = !!orderTypeConfig ? orderTypeConfig.type : OrderType.Any;
        this.orderDeferOptions = orderDeferOptions;
        if (!!orderDeferOptions) {
          this.orderDeferOptionsFetched = true;
        }
      });

    this.accountService.account$.subscribe(account => (this.account = account));
    this.storeStatusService.storeStatus$.subscribe(storeStatus => (this.storeStatus = storeStatus));
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((val: NavigationEnd) => {
      this.isInCheckout = val.url.indexOf('/checkout') === 0 || (val.url.indexOf('/login') === 0 && val.url.indexOf('precheckout') > -1);
      this.visibleMenu = undefined;
    });
    this.themeService.theme.subscribe(theme => (this.theme = theme));
    this.uiOrchestrationService.storeStates$
      .pipe(
        mergeMap((ss: string[]) => {
          this.storeStates = ss;
          if (!!this.storeStates && window.innerWidth > 767) {
            this.modalRef = this.ngbModalService.open(this.storeSelectionModalTemplate, {
              windowClass: 'padding-0 no-scroll',
              size: 'sm',
            });
            this.focusService.deactivateFocusTrap();
            return from(this.modalRef.result).pipe(catchError(err => of(err)));
          } else if (!this.storeStates && this.modalRef) {
            this.ngbModalService.dismissAll();
            return of(null);
          }

          return of(EMPTY);
        })
      )
      .subscribe(res => {
        if (res === ModalDismissReasons.BACKDROP_CLICK) {
          this.handleCloseStoreSelection();
        }
      }, noop);

    combineLatest([
      this.pathService.inMenu$,
      this.uiOrchestrationService.mobileStoreChooserOverlayVisible$,
      this.breakpoint.isTabletDown$.pipe(distinctUntilChanged()),
    ]).subscribe(([inMenu, storeChooserVisible, isTabletDown]) => {
      if (!(this.theme.hasSinglePageMenu && inMenu)) {
        if (storeChooserVisible && isTabletDown) {
          document.querySelector(`body`).classList.add('mobile-overlay');
        } else {
          document.querySelector(`body`).classList.remove('mobile-overlay');
        }
      }

      if (storeChooserVisible && !isTabletDown) {
        this.focusService.deactivateFocusTrap();
      }
    });

    this.headerLinksFeaturedContent$ = this.featuredContentService.getFeaturedContentsByAreaName(FeaturedContentName.HEADER_LINKS);
    this.featuredAnnouncements$ = this.featuredContentService
      .getFeaturedContentsByAreaName(FeaturedContentName.HEADER_ANNOUNCEMENT)
      .pipe(map((fcs: FeaturedContent[]) => fcs.map(fc => fc.featuredItems).reduce((a, b) => [...a, ...b], [])));

    this.breakpoint.isTabletDown$.subscribe(isMobile => (this.isMobile = isMobile));

    this.uiOrchestrationService.signOut$.subscribe(() => this.handleLogout());

    this.uiOrchestrationService.toggleMenuCategories$.subscribe(() => {
      if (!this.theme.hasSinglePageMenu) {
        if (this.isMobile && this.theme.showCategoriesUpfront) {
          this.router.navigate(['/menu']);
        } else {
          this.toggleMobileMenu(0);
        }
      }
    });

    this.calculateVerticalMenuHeight();
    this.menuService.setHeaderHeight(this.wholeHeader?.nativeElement?.clientHeight);

    this.storeStatusService.storeStatus$
      .pipe(
        filter(status => status.orderType !== OrderType.Any),
        switchMap(status => this.deferService.getCalendarEvents(status.orderType as OrderType).pipe(map(calendarEvents => calendarEvents)))
      )
      .subscribe((calendarEvents: CalendarEvent[]) => (this.calendarEvents = calendarEvents));

    this.storeStatusService.selectedOpenCloseTime$.subscribe(
      (selectedOpenCloseTime: OpenCloseTime) =>
        (this.selectedDeferDateCalendarDescription = this.calcSelectedDeferDateCalendarDescription(selectedOpenCloseTime))
    );

    setTimeout(() => this.onResize(), 1000);
  }

  get showTopOrderTree() {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    const isInCheckout = this.router.url.indexOf('/checkout') === 0;
    const isInPreCheckout = this.router.url.indexOf('/login') === 0 && queryParams['step'] === 'precheckout';
    const isInExpiration = this.router.url.indexOf('/expiration') === 0;
    return (
      this.isInEditor ||
      (!isInCheckout &&
        !isInPreCheckout &&
        !isInExpiration &&
        this.theme &&
        this.theme.hasTopOrderTree &&
        this.order &&
        this.order.isInitialized)
    );
  }

  dismissBanner() {
    this.socialLinksService.dismissBanner();
    this.auditService.createAudit(() => 'Dismiss banner button clicked.');
  }

  dismissBannerAndRedirect() {
    this.socialLinksService.dismissBanner();
    window.open(this.bannerUrl, '_blank');
    this.auditService.createAudit(() => 'Banner clicked');
  }

  onBannerHeaderChange() {
    setTimeout(() => {
      this.menuService.setHeaderHeight(this.wholeHeader?.nativeElement?.clientHeight);
    }, 100);
  }

  toggleMobileMenu(menu: MobileMenu) {
    const menuType = ['Categories menu', 'Order Tree', 'Store chooser modal', 'Right sidebar menu'];
    if (this.visibleMenu !== menu) {
      if (this.isMobile) {
        if (!Utils.isNullOrUndefined(menu)) {
          this.auditService.createAudit(() => `${menuType[menu]} expanded`);
        } else {
          this.auditService.createAudit(() => `${menuType[this.visibleMenu]} collapsed`);
        }
      }
      this.visibleMenu = menu;
    } else {
      if (this.isMobile && !!menu) {
        this.auditService.createAudit(() => `${menuType[menu]} collapsed`);
      }
      this.visibleMenu = undefined;
    }

    setTimeout(() => this.calculateVerticalMenuHeight());
  }

  handleCloseStoreSelection() {
    this.orderService.order$
      .pipe(
        take(1),
        map(order => (order.isInitialized ? order : null)),
        mergeMap((order: Order) => {
          if (!order || !this.cachedOrderType || (order.orderType === this.cachedOrderType && this.cachedDeferTime === order.deferTime)) {
            this.cachedOrderType = !this.cachedOrderType && order && order.orderType ? order.orderType : this.cachedOrderType;
            this.cachedDeferTime = !this.cachedDeferTime && order && order.deferTime ? order.deferTime : this.cachedDeferTime;
            return of(null);
          }
          this.cachedOrderType = order.orderType;
          this.cachedDeferTime = order.deferTime;
          return this.menuService
            .fetchMenuAndFeaturedContents(order.orderType, order.deferTime)
            .pipe(tap(() => this.isInSearch && this.menuService.refreshSearchResults()));
        })
      )
      .subscribe(
        () => {
          this.storeStatusService.selectedOpenCloseTime.next(
            this.storeStatus?.getOpenCloseTime(this.dateFormat.transform(this.order?.deferTime, 'yyyy-MM-dd'))
          );
          this.toggleMobileMenu(undefined);
          this.uiOrchestrationService.setStoreStates(null);
          this.auditService.createAudit(() => `Change Store/Order Type modal dismissed`);
        },
        error => this.modalService.parseAndNotifyErrors(error)
      );
    this.orderDeferOptionsFetched = false;
  }

  handleLogin(): Promise<any> {
    if (this.router.url && !(this.router.url.indexOf('/login') === 0)) {
      return this.activatedRouteData()
        .pipe(
          map(data => data['skipRedirect']),
          map(skipRedirect => (skipRedirect ? {} : { target: decodeURIComponent(this.router.url) })),
          mergeMap(queryParams => this.router.navigate(['/login'], { queryParams }))
        )
        .toPromise();
    }
    return Promise.resolve();
  }

  handleLogout(): void {
    this.accountService.logout().subscribe(
      () => {
        this.accountLocalStorage.setAccountSignedInOut('true');
      },
      () => this.reloaderService.reload('/')
    );
  }

  webPayLogout() {
    this.uiOrchestrationService.triggerWebPaySignOut();
  }

  notificationsDimensionsChanged(event) {
    this.notificationsHeightChange.emit(event.dimensions.height);
  }

  calculateVerticalMenuHeight() {
    const headerHeight = !!this.header ? this.header.nativeElement.clientHeight : 0;
    this.uiOrchestrationService.setHeaderHeight(headerHeight);
    if (!!this.wholeHeader) {
      this.menuService.setHeaderHeight(this.wholeHeader?.nativeElement?.clientHeight);
    }
    this.cdr.detectChanges();
  }

  logoHeightChanged(height: number) {
    this.logoHeightChange.emit(height);
  }

  trackByAnnouncement(_index: number, featuredAnnouncement: FeaturedItem): string {
    return featuredAnnouncement?.title;
  }

  dismissFeaturedAnnouncement(featuredAnnouncement: FeaturedItem) {
    this.featuredAnnouncements$ = this.featuredAnnouncements$.pipe(
      map((fis: FeaturedItem[]) => fis.filter(i => i.title !== featuredAnnouncement?.title))
    );
    this.onResize();
  }

  skipToMainContent() {
    this.focusService.applyFocus(this.isInHome ? 'homeMainContent' : 'mainContent');
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  onSkipToMainFocus() {
    // Force call to force mobile Screen Reader focus behavior
    if (this.isMobile) {
      const el = document.getElementById('skip-to-main-content-btn');
      if (!!el) {
        el.focus();
      }
    }
  }

  private activatedRouteData() {
    return of(this.activatedRoute).pipe(
      map((route: ActivatedRoute) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap((route: ActivatedRoute) => route.data)
    );
  }

  private calcSelectedDeferDateCalendarDescription(selectedOpenCloseTime: OpenCloseTime): string {
    if (!selectedOpenCloseTime || !this.calendarEvents) {
      return '';
    }

    const selectedCalendarEvent = this.calendarEvents?.find(calendarEvent => {
      const calendarEventStartDate = this.dateFormat.transform(calendarEvent.startDate, 'yyyy-MM-dd');
      const calendarEventEndDate = this.dateFormat.transform(calendarEvent.endDate, 'yyyy-MM-dd');
      const selectedOpenTimeDate = this.dateFormat.transform(selectedOpenCloseTime?.open, 'yyyy-MM-dd');
      if (calendarEventStartDate === selectedOpenTimeDate || calendarEventEndDate === selectedOpenTimeDate) {
        return calendarEvent;
      }
    });

    if (!selectedCalendarEvent && !selectedCalendarEvent?.description) {
      return '';
    }

    return selectedCalendarEvent.description;
  }
}
