import { Component, OnInit } from '@angular/core';
import { AuditService, MenuItemsLayout, OrderableItem, OrderedItem, UpsellWrapper } from 'ngx-web-api';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { SpecialWizard } from '../../domain/special-wizard';
import { Subject, Observable } from 'rxjs';
import { EditingItemContext } from 'app/domain/editing-item-context';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UpsellSpecialWizardModalContext } from 'app/domain/upsell-special-wizard-modal-context';

@Component({
  selector: 'fts-upsell-special-modal',
  templateUrl: './upsell-special-modal.component.html',
  styleUrls: ['./upsell-special-modal.component.scss'],
})
export class UpsellSpecialModalComponent implements OnInit {
  public context: UpsellSpecialWizardModalContext;
  specialWizard$: Subject<SpecialWizard> = new Subject<SpecialWizard>();
  result$: Observable<any>;
  upsellWrapper: UpsellWrapper;
  upsellTriggerOrderableItem: OrderableItem;
  menuItemsLayout?: MenuItemsLayout;
  skipNavigation?: boolean;
  upsellTriggerOrderedItem?: OrderedItem;
  upsellTriggerItemId?: string; // This is needed for 'Recently Ordered' items
  editingContext?: EditingItemContext;

  constructor(
    private auditService: AuditService,
    private uiOrchestratorService: UiOrchestratorService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.upsellWrapper = this.context.upsellWrapper;
    this.upsellTriggerOrderableItem = this.context.upsellTriggerOrderableItem;
    this.menuItemsLayout = this.context.menuItemsLayout;
    this.upsellTriggerOrderedItem = this.context.upsellTriggerOrderedItem;
    this.upsellTriggerItemId = this.context.upsellTriggerItemId;
    this.skipNavigation = this.context.skipNavigation;
    this.editingContext = this.context.editingContext;
    this.auditService.createAudit(() => `Upsell Special modal has been opened`);
    this.uiOrchestratorService.informModalOpened();
  }

  hideModal(shouldHide: boolean): void {
    if (shouldHide) {
      this.activeModal.close();
    }
  }

  makeCombo(specialWizard: SpecialWizard): void {
    this.activeModal.close(specialWizard);
  }
}
