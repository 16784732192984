import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Order, OrderService, StoreConfig, StoreStatusService } from 'ngx-web-api';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Injectable()
export class StoreStatusResolver implements Resolve<StoreConfig> {
  constructor(private orderService: OrderService, private storeStatusService: StoreStatusService) {}

  resolve(): Observable<any> {
    return this.orderService.order$.pipe(
      mergeMap((order: Order) => this.storeStatusService.getStoreStatus(order.orderType)),
      take(1)
    );
  }
}
