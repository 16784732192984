import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { OrderSubTax } from './order-sub-tax.model';

export class TaxRateGroup extends BaseResource {
  taxRate: string;
  subTotalName: string;
  taxable: number;
  taxes?: OrderSubTax[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      taxes: DeserializationUtils.deserializeChildren(input, 'taxes', OrderSubTax),
    });
  }
}
