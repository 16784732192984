import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Account,
  AccountService,
  Coupon,
  CouponErrorType,
  CouponResolution,
  DeserializationUtils,
  Fundraiser,
  GroupOrder,
  GroupOrderService,
  Order,
  OrderableItem,
  OrderableItemService,
  OrderableSpecial,
  OrderedFundraiser,
  OrderedItem,
  OrderedItemService,
  OrderedSponsor,
  OrderFundraiserService,
  OrderService,
  OrderSpecialService,
  Sponsor,
  Utils,
} from 'ngx-web-api';
import { combineLatest, EMPTY, from, merge, noop, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { ConfirmationModalContext } from '../../domain/confirmation-modal-context';
import { SpecialWizard } from '../../domain/special-wizard';
import { SpecialWizardModalContext } from '../../domain/special-wizard-modal-context';
import { SpecialWizardModalComponent } from '../../shared/special-wizard-modal/special-wizard-modal.component';
import { GroupOrderOrchestratorService } from './group-order-orchestrator.service';
import { InitParamsStorageService } from './init-params-storage.service';
import { MenuWrapperService } from './menu-wrapper.service';
import { ModalService } from './modal.service';
import { OrderUpsellService } from './order-upsell.service';
import { SpecialWizardService } from './special-wizard.service';
import { TranslateService } from '@ngx-translate/core';
import { ReloaderService } from './reloader.service';
import { FundraiserSponsorsModalContext } from 'app/domain/fundraiser-sponsors-modal-context';
import { FundraiserSponsorsModalComponent } from 'app/shared/fundraiser-sponsors-modal/fundraiser-sponsors-modal.component';
import { ErrorsService } from './errors.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ThemeService } from './theme.service';
import { SlugifyPipe } from 'app/shared/pipes/slugify.pipe';
import { GTMService } from './gtm.service';
import { EcommerceTriggeringArea } from '../../domain/ecommerce-triggering-area.enum';
import { EcommerceTriggeringPoint } from '../../domain/ecommerce-triggering-point.enum';
import { OrderingUtilsService } from './ordering-utils.service';

@Injectable({
  providedIn: 'root',
})
export class ParamsOrchestratorService {
  private navAndOrderInitialized$: Observable<Order>;
  private orderInitDone$: Subject<any> = new Subject();
  private slugifyPipe = new SlugifyPipe();
  targetTabName: string = '';
  targetWasRendered: boolean = false;

  constructor(
    private initParamsStorageService: InitParamsStorageService,
    private orderService: OrderService,
    private orderedItemService: OrderedItemService,
    private orderableItemService: OrderableItemService,
    private orderUpsellService: OrderUpsellService,
    private modalService: ModalService,
    private router: Router,
    private groupOrderOrchestratorService: GroupOrderOrchestratorService,
    private groupOrderService: GroupOrderService,
    private orderSpecialService: OrderSpecialService,
    private specialWizardService: SpecialWizardService,
    private menuService: MenuWrapperService,
    private translateService: TranslateService,
    private reloaderService: ReloaderService,
    private orderFundraiserService: OrderFundraiserService,
    private errorService: ErrorsService,
    private http: HttpClient,
    private themeService: ThemeService,
    private gtmService: GTMService,
    private orderingUtilsService: OrderingUtilsService,
    private accountService: AccountService
  ) {
    this.navAndOrderInitialized$ = merge(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
        mergeMap(() => this.orderService.order$.pipe(take(1))),
        filter((order: Order) => order.isInitialized)
      ),
      this.orderInitDone$.asObservable()
    ).pipe(mergeMap(() => this.orderService.order$.pipe(take(1))));
  }

  public initListeners(): void {
    if (!!document.location?.hash.split('#')[1]) {
      this.targetTabName = this.slugifyPipe.transform(decodeURIComponent(document.location.hash.split('#')[1]));
    }
    this.handleCategoryQueryParam();
    this.handleTabQueryParam();
    this.handleItemQueryParams();
    this.handleSpecialQueryParams();
    this.handleAddCouponQueryParamsForWizard();
    this.handleAddCouponQueryParamsForWizardWithLogin();
    this.handleAddCouponQueryParams();
    this.handleFundraiserQueryParams();
    this.handleMenuNavWithoutOrder();
  }

  public markOrderInitializationDone(): void {
    this.orderInitDone$.next();
  }

  public handleAddCouponViaSpecialOrDirectly(
    order: Order,
    ecommerceTriggeringArea: EcommerceTriggeringArea,
    ecommerceTriggeringPoint: EcommerceTriggeringPoint,
    couponResource?: Coupon
  ): Observable<any> {
    return this.addCouponViaSpecialOrDirectly(order, couponResource, ecommerceTriggeringArea, ecommerceTriggeringPoint);
  }

  private handleTabQueryParam() {
    this.navAndOrderInitialized$
      .pipe(
        filter(() => this.hasTabInitParam()),
        map(() => this.initParamsStorageService.initParams.tab),
        mergeMap(tab =>
          this.themeService.theme.pipe(
            map(theme => ({ theme, tab })),
            take(1) // theme is a stream and mergeMap here has to be limited to 1 emission otherwise this stream lives on forever and even emits when we are past menu navigation/redirection flow
          )
        )
      )
      .subscribe(({ theme, tab }) => {
        if (theme.hasSinglePageMenu) {
          this.navigateToSinglePageMenu(tab);
        } else {
          this.router.navigate(['/menu', decodeURIComponent(tab)], { queryParamsHandling: 'merge' });
        }
      });
  }

  private handleCategoryQueryParam() {
    this.navAndOrderInitialized$
      .pipe(
        filter(() => this.hasOnlyCategoryInitParam()),
        map(() => this.initParamsStorageService.initParams.category),
        mergeMap(category =>
          this.themeService.theme.pipe(
            map(theme => ({ theme, category })),
            take(1) // theme is a stream and mergeMap here has to be limited to 1 emission otherwise this stream lives on forever and even emits when we are past menu navigation/redirection flow
          )
        )
      )
      .subscribe(({ theme, category }) => {
        if (theme.hasSinglePageMenu) {
          this.navigateToSinglePageMenu(category);
        } else {
          // THIS IS THE LINE THAT CAUSES THE NAVIGATION BACK TO MENU
          this.router.navigate(['/menu', decodeURIComponent(category)], { queryParamsHandling: 'merge' });
        }
      });
  }

  private handleItemQueryParams() {
    this.navAndOrderInitialized$
      .pipe(
        filter(() => this.hasItemInitParams()),
        mergeMap((order: Order) => this.canAddToGroupOrderIfExists(order)),
        mergeMap((order: Order) => this.fetchItem(order)),
        mergeMap((item: OrderableItem) =>
          this.openItemEditor(item).pipe(map((orderedItem: OrderedItem) => ({ orderableItem: item, orderedItem })))
        )
      )
      .subscribe(
        ({ orderableItem, orderedItem }) => {
          if (orderedItem) {
            this.gtmService.pushAddToCartEvent({
              items: [orderedItem],
              triggeringArea: EcommerceTriggeringArea.URL,
              triggeringPoint: EcommerceTriggeringPoint.URL_PARAM,
            });
          } else {
            const params = this.initParamsStorageService.initParams;
            const selectedSizePrice = orderableItem.sizePrices.find(sp => sp.label === params.size);
            const defaultSizePrice = orderableItem.sizePrices.find(sp => sp.isWebDefault) || orderableItem.sizePrices[0];
            this.gtmService.pushViewItemEvent(
              orderableItem,
              selectedSizePrice?.size || defaultSizePrice?.label,
              selectedSizePrice?.price || defaultSizePrice?.price,
              params?.quantity || 1,
              EcommerceTriggeringPoint.URL_PARAM,
              EcommerceTriggeringArea.URL
            );
          }
        },
        () =>
          this.openErrorModal(
            this.translateService.instant('component.params_orchestrator.something_went_wrong'),
            this.translateService.instant('component.params_orchestrator.sorry_no_process')
          )
      );
  }

  private handleSpecialQueryParams() {
    this.navAndOrderInitialized$
      .pipe(
        filter(() => this.hasSpecialInitParams()),
        mergeMap((order: Order) => this.canAddToGroupOrderIfExists(order)),
        mergeMap((order: Order) => this.fetchSpecial(order)),
        mergeMap(({ special }: { special: OrderableSpecial }) => this.orderSpecialService.validate(special)),
        catchError(response => {
          if (response.error.meta && response.error.meta.info === CouponErrorType.UNAVAILABLE_MOBILE) {
            this.modalService.openMobileAppCouponModal(response.error.error || response.error.message);
          } else {
            this.openErrorModal(
              this.translateService.instant('component.params_orchestrator.something_went_wrong'),
              response['error']['message']
            );
          }
          return EMPTY;
        }),
        mergeMap(
          (special: OrderableSpecial): Observable<any> => {
            this.gtmService.pushViewPromotionEvent(
              special.label || special.name,
              special.code,
              EcommerceTriggeringArea.URL,
              EcommerceTriggeringPoint.URL_PARAM,
              null
            );
            if (!!special.steps && special.steps.length > 0) {
              return this.specialWizardService.getSpecialWizardConfig(special).pipe(
                mergeMap((specialWizard: SpecialWizard) => {
                  const context = new SpecialWizardModalContext(specialWizard).getConfig();
                  context.ignoreDismissOnNavigate = true;
                  return this.modalService.openModal(SpecialWizardModalComponent, context);
                })
              );
            }

            return this.orderService.addCoupon(Coupon.createCouponByCode(special.code)).pipe(
              map((coupon: CouponResolution) => {
                if (coupon.message) {
                  this.modalService.notifyCoupon(coupon);
                }
                return;
              })
            );
          }
        ),
        catchError(() => EMPTY)
      )
      .subscribe(
        () => {},
        () => {}
      );
  }

  private handleAddCouponQueryParamsForWizard() {
    this.navAndOrderInitialized$
      .pipe(
        filter(() => this.shouldTriggerSpecialWizard()),
        mergeMap((order: Order) =>
          this.addCouponViaSpecialOrDirectly(
            order,
            Coupon.createCouponByCode(
              this.initParamsStorageService.initParams.addCoupon,
              this.initParamsStorageService.initParams.addCouponType
            ),
            EcommerceTriggeringArea.URL,
            EcommerceTriggeringPoint.URL_PARAM
          )
        )
      )
      .subscribe(noop, noop);
  }

  private handleAddCouponQueryParamsForWizardWithLogin() {
    combineLatest([this.navAndOrderInitialized$, this.accountService.account$.pipe(filter(account => account.isInstantiated))])
      .pipe(
        filter(() => this.shouldTriggerSpecialWizardWithAccessToken()),
        mergeMap(([order, _account]: [Order, Account]) =>
          this.addCouponViaSpecialOrDirectly(
            order,
            Coupon.createCouponByCode(
              this.initParamsStorageService.initParams.addCoupon,
              this.initParamsStorageService.initParams.addCouponType
            ),
            EcommerceTriggeringArea.URL,
            EcommerceTriggeringPoint.URL_PARAM
          )
        )
      )
      .subscribe(noop, noop);
  }

  private handleAddCouponQueryParams() {
    this.navAndOrderInitialized$
      .pipe(
        filter(() => this.hasCouponInitParams()),
        mergeMap(() =>
          this.addCoupon(
            Coupon.createCouponByCode(
              this.initParamsStorageService.initParams.addCoupon,
              this.initParamsStorageService.initParams.addCouponType
            ),
            EcommerceTriggeringArea.URL,
            EcommerceTriggeringPoint.URL_PARAM
          )
        )
      )
      .subscribe(noop, noop);
  }

  private handleFundraiserQueryParams() {
    this.navAndOrderInitialized$
      .pipe(
        filter(() => this.hasFundaiserInitParams()),
        mergeMap(() => {
          return this.getFundraiser(this.initParamsStorageService.initParams.fundraisers);
        })
      )
      .subscribe(
        (fundraiser: Fundraiser) => {
          if (!fundraiser) {
            this.openErrorModal(
              this.translateService.instant('component.params_orchestrator.something_went_wrong'),
              this.translateService.instant('component.params_orchestrator.sorry_no_process')
            );
          } else if (fundraiser.sponsorsEnabled) {
            if (this.hasFundaiserSponsorInitParams()) {
              this.addFundrairesWithSponsor(fundraiser);
            } else {
              const context = new FundraiserSponsorsModalContext(fundraiser, undefined).getConfig();
              this.modalService.openModal(FundraiserSponsorsModalComponent, context);
            }
          } else {
            this.addFundraiserToOrder(fundraiser);
          }
        },
        err => {
          this.modalService.openErrorNotificationModal(err.error.meta.info);
        }
      );
  }

  private handleMenuNavWithoutOrder() {
    const hasTabParam = this.hasTabInitParam();
    const hasOnlyCategoryParam = this.hasOnlyCategoryInitParam();
    if (!hasTabParam && !hasOnlyCategoryParam) {
      return;
    }
    const orderTypeParam = this.initParamsStorageService.initParams.orderType;
    const categoryOrTabParam = hasTabParam
      ? this.initParamsStorageService.initParams.tab
      : this.initParamsStorageService.initParams.category;

    combineLatest([this.orderService.order$.pipe(map(order => !order.isInitialized)), this.themeService.theme])
      .pipe(
        filter(([noOrder, theme]) => noOrder && !orderTypeParam && theme.skipIntroPage),
        take(1)
      )
      .subscribe(([_noOrder, theme]) => {
        if (theme.hasSinglePageMenu) {
          this.navigateToSinglePageMenu(categoryOrTabParam);
        } else {
          this.router.navigate(['/menu', decodeURIComponent(categoryOrTabParam)], { queryParamsHandling: 'merge' });
        }
      });
  }

  private navigateToSinglePageMenu(param: string) {
    const slugifiedTabName = this.slugifyPipe.transform(decodeURIComponent(param));
    this.targetTabName = this.slugifyPipe.transform(decodeURIComponent(param));
    this.menuService.setInitParamsTab(slugifiedTabName);
    this.router.navigate(['/menu', 'single-page'], { fragment: slugifiedTabName, queryParamsHandling: 'merge' });
  }

  private addCouponViaSpecialOrDirectly(
    order: Order,
    couponResource: Coupon,
    ecommerceTriggeringArea: EcommerceTriggeringArea,
    ecommerceTriggeringPoint: EcommerceTriggeringPoint
  ): Observable<any> {
    return this.canAddToGroupOrderIfExists(order).pipe(
      mergeMap((cachedOrder: Order) =>
        this.orderService.addCoupon(couponResource).pipe(
          mergeMap((couponResolution: Coupon) =>
            of({ coupon: couponResolution, cachedOrder, appliedDirectly: !couponResolution.errorType })
          ),
          catchError((response: any) => of({ coupon: couponResource, cachedOrder: order, response, appliedDirectly: false }))
        )
      ),
      mergeMap(
        ({
          coupon,
          cachedOrder,
          response,
          appliedDirectly,
        }: {
          coupon: Coupon;
          cachedOrder: Order;
          response: any;
          appliedDirectly: boolean;
        }) =>
          this.orderService.order$.pipe(
            take(1),
            map((latestOrder: Order) => ({ coupon, latestOrder, cachedOrder, addCouponResponse: response, appliedDirectly }))
          )
      ),
      mergeMap(
        ({
          coupon,
          latestOrder,
          cachedOrder,
          addCouponResponse,
          appliedDirectly,
        }: {
          coupon: CouponResolution;
          latestOrder: Order;
          cachedOrder: Order;
          addCouponResponse: any;
          appliedDirectly: boolean;
        }) =>
          this.fetchSpecial(latestOrder, true, couponResource.code, true).pipe(
            /* Set the corresponding response based on the following cases:
              1. If there is a response from the /addCoupon returns the response of the call.
              2. If there is no response from the /addCoupon and the coupon has applied directly to the order return undefined or the response of the /fetchSpecial.
            */
            mergeMap(({ special, response }: { special: OrderableSpecial; response: any }) =>
              of({
                special,
                coupon,
                latestOrder,
                cachedOrder,
                response: !!addCouponResponse ? addCouponResponse : appliedDirectly ? undefined : response,
                appliedDirectly,
              })
            )
          )
      ),
      mergeMap(
        ({
          special,
          coupon,
          latestOrder,
          cachedOrder,
          response,
          appliedDirectly,
        }: {
          special: OrderableSpecial | undefined;
          coupon: CouponResolution;
          latestOrder: Order;
          cachedOrder: Order;
          response: any;
          appliedDirectly: boolean;
        }) => {
          /* In the case where we are not logged in:
            1. Notify the coupon that the coupon added as pending when need it.
            2. Throw the corresponding error message.
          */
          if (coupon.errorType === CouponErrorType.NOT_LOGGED_IN) {
            this.modalService.notifyCoupon(coupon);
            this.openErrorModal(coupon.error, '', true);
            return of(undefined);
          }

          // In case the coupon has an error type `APPLICATION_WITH_SWAP` we need to trigger the addCouponSuccess to proceed with the success flow because the coupon is added to the order.
          if (coupon.errorType === CouponErrorType.APPLICATION_WITH_SWAP) {
            this.addCouponSuccess(coupon, ecommerceTriggeringArea, ecommerceTriggeringPoint, cachedOrder, latestOrder);
            return of(undefined);
          }

          /* In case there is a special and matches a specific category (= If the `special?.postCoupon` is undefined) and the coupon has not added directly to the order then we need to open the special wizard modal:
            1. Remove the pending special from the order.
            2. Return the special to open the modal.
          */
          if (!Utils.isNullOrUndefined(special) && Utils.isNullOrUndefined(special?.postCoupon) && !appliedDirectly) {
            return this.orderService.removePendingDiscount().pipe(mergeMap(() => of(special)));
          } else {
            // In case there is not an error response from both above calls (/addCoupon or /fetchSpecial).
            if (!response) {
              // In case that the special added as pending to the order, we just notify the coupon.
              if (!Utils.isNullOrUndefined(latestOrder?.pendingSpecial)) {
                this.modalService.notifyCoupon(coupon);
                // In case the special was added as pending, we notify the order.
              } else if (latestOrder.specials.length || latestOrder.adjustments.length) {
                this.addCouponSuccess(coupon, ecommerceTriggeringArea, ecommerceTriggeringPoint, cachedOrder, latestOrder);
              }

              return of(undefined);
            } else {
              // In case there is an error response and the coupon has been added as pending (as special or adjustment) to the order.
              return this.orderService.removePendingDiscount().pipe(
                mergeMap(() => {
                  this.addCouponError(response);
                  return of(undefined);
                })
              );
            }
          }
        }
      ),
      mergeMap((special: OrderableSpecial | undefined) => {
        if (!Utils.isNullOrUndefined(special)) {
          this.gtmService.pushViewPromotionEvent(
            special.name || special.label,
            special.code,
            ecommerceTriggeringArea,
            ecommerceTriggeringPoint,
            null
          );
          return this.specialWizardService.getSpecialWizardConfig(special).pipe(
            mergeMap((specialWizard: SpecialWizard) => {
              specialWizard.removePending = true;
              const context = new SpecialWizardModalContext(specialWizard).getConfig();
              context.ignoreDismissOnNavigate = true;
              return this.modalService.openModal(SpecialWizardModalComponent, context);
            })
          );
        } else {
          return of(undefined);
        }
      }),
      catchError((response: any) => {
        this.openErrorModal(
          this.translateService.instant('component.params_orchestrator.something_went_wrong'),
          response['error']['message'] ||
            response['error']['error'] ||
            this.translateService.instant('component.params_orchestrator.requested_special_not_available'),
          true
        );
        return throwError(EMPTY);
      })
    );
  }

  private addCoupon(
    couponResource: Coupon,
    ecommerceTriggeringArea: EcommerceTriggeringArea,
    ecommerceTriggeringPoint: EcommerceTriggeringPoint
  ): Observable<any> {
    return this.orderService.order$.pipe(
      take(1),
      // Cached order will be used to remove items that are already in the cart
      // before adding them again this time with their netPrice instead of their regular price.
      mergeMap(cachedOrder => this.orderService.addCoupon(couponResource).pipe(map((coupon: CouponResolution) => [coupon, cachedOrder]))),
      mergeMap(([coupon, cachedOrder]) =>
        this.orderService.order$.pipe(
          take(1),
          map(order => [coupon, cachedOrder, order])
        )
      ),
      tap(([coupon, cachedOrder, order]: [CouponResolution, Order, Order]) =>
        this.addCouponSuccess(coupon, ecommerceTriggeringArea, ecommerceTriggeringPoint, cachedOrder, order)
      ),
      catchError(response => this.addCouponError(response))
    );
  }

  private addCouponSuccess(
    coupon: CouponResolution,
    ecommerceTriggeringArea: string,
    ecommerceTriggeringPoint: string,
    cachedOrder: Order,
    latestOrder: Order
  ) {
    this.modalService.notifyCoupon(coupon);
    this.gtmService.pushViewPromotionEvent(coupon.name, coupon.code, ecommerceTriggeringArea, ecommerceTriggeringPoint, null);
    const itemsToRemove = this.orderingUtilsService.calcItemsToRemove(cachedOrder, latestOrder);
    if (itemsToRemove.length) {
      this.gtmService.pushRemoveFromCartEvent({
        items: itemsToRemove,
        triggeringArea: ecommerceTriggeringArea,
        triggeringPoint: ecommerceTriggeringPoint,
      });
    }
    const specialToAdd = this.orderingUtilsService.findAddedSpecial(cachedOrder.specials, latestOrder.specials);
    if (!!specialToAdd) {
      this.gtmService.pushAddToCartEvent({
        specialOrdinal: specialToAdd?.ordinal,
        triggeringArea: ecommerceTriggeringArea,
        triggeringPoint: ecommerceTriggeringPoint,
      });
    }
    if (coupon.errorType === CouponErrorType.APPLICATION_WITH_SWAP) {
      this.modalService.openErrorNotificationModal(coupon.error, '', true);
    }
  }

  private addCouponError(response: any) {
    if (response.error.errorType === CouponErrorType.UNAVAILABLE_MOBILE) {
      this.modalService.openMobileAppCouponModal(response.error.error);
      return;
    }
    if (response.error.errorType === CouponErrorType.APPLICATION_FAILED) {
      this.modalService.openCouponInstructionsModal(response.error);
      return;
    }
    this.modalService.openErrorNotificationModal(response.error.error || response.error.message || response.error?.meta?.error, '', true);
    return throwError(EMPTY);
  }

  private shouldTriggerSpecialWizard(): boolean {
    return (
      !!this.initParamsStorageService.initParams.edit &&
      !!this.initParamsStorageService.initParams.addCoupon &&
      !this.initParamsStorageService.initParams.accessToken
    );
  }

  private shouldTriggerSpecialWizardWithAccessToken(): boolean {
    return (
      !!this.initParamsStorageService.initParams.edit &&
      !!this.initParamsStorageService.initParams.addCoupon &&
      !!this.initParamsStorageService.initParams.accessToken
    );
  }

  private hasCouponInitParams(): boolean {
    return !this.initParamsStorageService.initParams.edit && !!this.initParamsStorageService.initParams.addCoupon;
  }

  private hasTabInitParam(): boolean {
    const params = this.initParamsStorageService.initParams;
    return !!params.tab;
  }

  private hasOnlyCategoryInitParam(): boolean {
    const params = this.initParamsStorageService.initParams;
    return !!params.category && !params.item;
  }

  private hasItemInitParams(): boolean {
    const params = this.initParamsStorageService.initParams;
    return !!params.itemCode || (!!params.item && !!params.category);
  }

  private hasSpecialInitParams(): boolean {
    const params = this.initParamsStorageService.initParams;
    return (!!params.specialCode && params.specialCode.length > 0) || !!params.specialName;
  }

  private hasFundaiserInitParams(): boolean {
    const params = this.initParamsStorageService.initParams;
    return !!params.fundraisers;
  }

  private hasFundaiserSponsorInitParams(): boolean {
    const params = this.initParamsStorageService.initParams;
    return !!params.fundraisersSponsors;
  }

  private addFundrairesWithSponsor(fundraiser: Fundraiser) {
    const orderedSponsors: OrderedSponsor[] = [];
    this.initParamsStorageService.initParams.fundraisersSponsors.forEach(sponsorName => {
      const sponsor = new Sponsor().deserialize({
        name: sponsorName,
        notes: '',
      });
      orderedSponsors.push(OrderedSponsor.fromSponsor(sponsor));
    });

    const orderedFundraiser = OrderedFundraiser.fromFundraiser(fundraiser);
    orderedFundraiser.sponsors = orderedSponsors;
    this.orderFundraiserService.addFundraiserToOrder(orderedFundraiser).subscribe(
      () => {
        this.modalService.openSuccessNotificationModal(this.translateService.instant('component.params_orchestrator.fundraiser_added'));
      },
      error => {
        if (error.status === 409) {
          const response = this.errorService.parseAllErrorsFromResponse(error);
          this.modalService.openErrorNotificationModal(
            response?.message ? response?.message : this.translateService.instant('component.params_orchestrator.could_not_find_sponsor')
          );
        } else {
          const context = new FundraiserSponsorsModalContext(fundraiser, undefined, error, orderedSponsors).getConfig();
          this.modalService.openModal(FundraiserSponsorsModalComponent, context);
        }
      }
    );
  }

  private addFundraiserToOrder(fundraiser: Fundraiser) {
    this.orderFundraiserService.addFundraiserToOrder(OrderedFundraiser.fromFundraiser(fundraiser)).subscribe(
      () => {
        this.modalService.openSuccessNotificationModal(this.translateService.instant('component.params_orchestrator.fundraiser_added'));
      },
      error => {
        const response = this.errorService.parseErrorResponse(error);
        this.modalService.openErrorNotificationModal(response?.message || response?.errors?.base[0]);
      }
    );
  }

  private getFundraiser(fundraiserName: string): Observable<Fundraiser> {
    const params: HttpParams = new HttpParams().set('name', fundraiserName);

    return this.http
      .get('/ws/integrated/v1/menu/fundraisers', { params })
      .pipe(map((json: Object) => DeserializationUtils.deserializeObj(json, Fundraiser)));
  }

  private fetchItem(order: Order): Observable<OrderableItem> {
    const params = this.initParamsStorageService.initParams;
    return this.menuService
      .getCategoryItem(
        order.orderType,
        encodeURIComponent(params.category),
        encodeURIComponent(params.item),
        params.itemCode,
        order.deferTime,
        false
      )
      .pipe(
        tap(item => {
          if (item.itemWithRequiredIngredientChoices) {
            this.openErrorModal(
              this.translateService.instant('component.order_warnings_modal.modifications_required'),
              `${this.translateService.instant('component.order_warnings_modal.please_complete')} ${item.category} ${item.name}`
            );
          }
        }),
        catchError(err => {
          this.openErrorModal(
            this.translateService.instant('component.params_orchestrator.item_not_available'),
            this.parseFetchItemError(err)
          );
          if (this.initParamsStorageService.canNavigateToCheckout() && order?.itemsCount === 0) {
            return from(this.router.navigate(['/menu'])).pipe(
              tap(() => this.reloaderService.pushStateWithoutReload(this.router.url.split('?')[0])),
              mergeMap(() => EMPTY)
            );
          }
          return EMPTY;
        })
      );
  }

  private fetchSpecial(
    order: Order,
    withCoupons = false,
    couponCode: string = '',
    fromAddCoupon: boolean = false
  ): Observable<{
    special: OrderableSpecial;
    response: any;
  }> {
    const params = this.initParamsStorageService.initParams;
    return this.menuService
      .getSpecial(
        order.orderType,
        params.specialName,
        !!couponCode ? couponCode : !!params.specialCode ? params.specialCode : params.addCoupon,
        order.deferTime,
        false,
        true,
        withCoupons
      )
      .pipe(
        mergeMap((special: OrderableSpecial) => of({ special, response: undefined })),
        catchError(err => {
          if (fromAddCoupon) {
            return of({ special: undefined, response: err });
          } else {
            this.openErrorModal(
              this.translateService.instant('component.params_orchestrator.special_not_available'),
              this.parseFetchSpecialError(err, params.offerDisplayName),
              true
            );
            return throwError(EMPTY);
          }
        })
      );
  }

  private canAddToGroupOrderIfExists<T>(arg: T): T | any {
    return this.groupOrderService.groupOrder$.pipe(
      take(1),
      mergeMap((order: GroupOrder): T | any => {
        if (order && !order.allowEditOrder) {
          this.groupOrderOrchestratorService.notifyOrderNotEditable();
          return EMPTY;
        }
        return of(arg);
      })
    );
  }

  private openItemEditor(item: OrderableItem): Observable<any> {
    const params = this.initParamsStorageService.initParams;
    let sizeStr;
    if (!item.sizePrices || !item.sizePrices.length) {
      sizeStr = 'Size';
    } else {
      const sizePrice = this.orderableItemService.getSizeOrDefault(item, params.size);
      if (!sizePrice) {
        this.openErrorModal(
          this.translateService.instant('component.params_orchestrator.item_not_available'),
          this.translateService.instant('component.params_orchestrator.item_not_available_category', {
            item: item.name,
            category: item.category,
          })
        );
        return EMPTY;
      }
      sizeStr = sizePrice.size;
    }
    const orderedItem = this.orderedItemService.createOrderedItem(item, sizeStr, params.quantity);
    return this.orderUpsellService.addToOrderOrUpsell(orderedItem, item, null);
  }

  private openErrorModal(title, message, ignoreDismissOnNavigate = false): void {
    const context: ConfirmationModalContext = new ConfirmationModalContext();
    context.hasSubmitBtn = false;
    context.mainContent = `<p class='lead'>${message}</p>`;
    context.dismissBtnTitle = this.translateService.instant('component.params_orchestrator.continue');
    context.title = title;
    context.ignoreDismissOnNavigate = ignoreDismissOnNavigate;
    this.modalService.openConfirmationModal(context);
  }

  private parseFetchItemError(err: any): string {
    const itemName = err['error']['name'];
    const itemCode = err['error']['code'];
    const categoryName = err['error']['category'];
    if (!itemName && !itemCode) {
      return this.translateService.instant('component.params_orchestrator.requested_item_not_available');
    } else if (!!itemName && !!categoryName) {
      return this.translateService.instant('component.params_orchestrator.item_not_available_category', {
        item: itemName,
        category: categoryName,
      });
    } else if (!!itemCode) {
      return this.translateService.instant('component.params_orchestrator.item_not_available_code', { code: itemCode });
    } else {
      return this.translateService.instant('component.params_orchestrator.item_not_available_name', { name: itemName });
    }
  }

  private parseFetchSpecialError(err: any, offerDisplayName?: string): string {
    const specialName = err['error']['name'];
    const specialCode = err['error']['code'];
    const specialError = err['error']['meta']['error'];
    if (offerDisplayName) {
      return this.translateService.instant('component.params_orchestrator.location_not_available', { offer: offerDisplayName });
    } else if (!specialName && !specialCode && !specialError) {
      return this.translateService.instant('component.params_orchestrator.requested_special_not_available');
    } else if (!!specialName) {
      return this.translateService.instant('component.params_orchestrator.special_not_available_name', { special: specialName });
    } else if (!!specialCode) {
      return this.translateService.instant('component.params_orchestrator.special_code_not_available', { code: specialCode });
    } else if (!!specialError) {
      return specialError;
    }
  }
}
