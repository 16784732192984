import { Component, Input, HostBinding } from '@angular/core';
import {
  ConfigService,
  CreditCardType,
  OrderPaymentTypesService,
  PaymentType,
  SocialLinks,
  StoreConfig,
  FeaturedContentService,
  FeaturedContent,
  FeaturedContentName,
  ApplePayService,
} from 'ngx-web-api';
import { Theme } from '../../domain/theme.model';
import { InitParamsStorageService } from '../services/init-params-storage.service';
import { ThemeService } from '../services/theme.service';
import { Observable } from 'rxjs';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SocialLinksService } from '../services/social-links.service';

@Component({
  selector: 'fts-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input()
  isInPay: boolean;
  @Input()
  isInPrintReceipt: boolean;

  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faYoutube = faYoutube;
  faInstagram = faInstagram;
  socialLinks: SocialLinks = new SocialLinks();
  creditCardTypes: CreditCardType[] = [];
  paymentTypes: PaymentType[] = [];
  storeConfig: StoreConfig;
  theme: Theme;

  hasSocialLinks = false;
  hasPaymentTypes = false;
  supportsCreditCards = false;
  paymentTypeNames = '';
  embedded = false;

  footerLinksFeaturedContent$: Observable<FeaturedContent[]>;

  constructor(
    private orderPaymentTypesService: OrderPaymentTypesService,
    private configService: ConfigService,
    private initParamsStorageService: InitParamsStorageService,
    private themeService: ThemeService,
    private featuredContentService: FeaturedContentService,
    private translateService: TranslateService,
    public applePayService: ApplePayService,
    private socialLinksService: SocialLinksService
  ) {
    this.socialLinksService.socialLinks$.subscribe(socialLinks => {
      this.socialLinks = socialLinks;
      this.hasSocialLinks = Object.keys(this.socialLinks).some(key => this.socialLinks[key]);
    });

    this.orderPaymentTypesService.paymentTypes$.subscribe(paymentTypes => {
      this.paymentTypes = paymentTypes;
      this.hasPaymentTypes = this.paymentTypes.length > 0;
      this.supportsCreditCards = this.paymentTypes.some(paymentType => paymentType.isCreditCard);
      this.paymentTypeNames = this.paymentTypes
        .map(type => type.displayName)
        .join(', ')
        .replace(/,(?=[^,]+$)/, ` ${this.translateService.instant('component.footer.or')}`); // the regex finds the last comma
    });

    this.configService.creditCardConfig$.subscribe(ccConfig => (this.creditCardTypes = ccConfig.creditCardTypes));

    this.configService.storeConfig$.subscribe(storeConfig => (this.storeConfig = storeConfig));

    this.themeService.theme.subscribe(theme => (this.theme = theme));

    this.footerLinksFeaturedContent$ = this.featuredContentService.getFeaturedContentsByAreaName(FeaturedContentName.FOOTER_LINKS);

    this.embedded = this.initParamsStorageService.initParams.embedded;
  }

  @HostBinding('class.host-flex')
  get shouldFlex() {
    return this.isInPay;
  }
}
