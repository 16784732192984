import { Component, OnInit } from '@angular/core';
import { AuditService, OrderTypeConfig, StoreConfig } from 'ngx-web-api';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderTypeModalContext } from 'app/domain/order-type-modal-context';

@Component({
  selector: 'fts-order-type-modal',
  templateUrl: './order-type-modal.component.html',
  styleUrls: ['./order-type-modal.component.scss'],
})
export class OrderTypeModalComponent implements OnInit {
  public context: OrderTypeModalContext;
  store: StoreConfig;
  orderTypes: OrderTypeConfig[];

  constructor(
    private auditService: AuditService,
    private uiOrchestratorService: UiOrchestratorService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.store = this.context.store;
    this.orderTypes = this.context.orderTypes;
    this.auditService.createAudit(() => `Order Type modal has been opened`);
    this.uiOrchestratorService.informModalOpened();
  }

  handleCancel(): void {
    this.activeModal.close(false);
  }

  handleOrderInitialized() {
    this.activeModal.close(true);
  }
}
