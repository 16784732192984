<section id="itemEditorModal" tabindex="-1" class="overlay" [ngClass]="{'modal-content primary-bg-color primary-text-color': !(viewportService?.viewport$ | async)?.isMobile}">
  <div class='modal-header sr-only'><h2 id="modal-title" class='modal-title'>{{('component.item_editor_modal.item_editor_modal_title' | translate)}}{{itemName}}</h2></div>
  <div [ngClass]="{'modal-body': !(viewportService?.viewport$ | async)?.isMobile, 'dialog-container primary-bg-color': (viewportService?.viewport$ | async)?.isMobile}">
    <fts-ordered-editor *ngIf="!!itemOrdinalId" [inItemEditorModal]="true" [itemOrdinalId]="itemOrdinalId" (finishLoading)="onFinishLoading($event)"></fts-ordered-editor>
    <fts-orderable-editor *ngIf="!itemOrdinalId" [inItemEditorModal]="true"></fts-orderable-editor>
  </div>
</section>
<div class="loading-indicator-wrapper" *ngIf="!finishLoading && itemOrdinalId && !orderedItemHasWarnings">
  <fts-loading-indicator></fts-loading-indicator>
</div>
