import { Injectable } from '@angular/core';
import { OrderableIngredient } from '../../models/menu/orderable-ingredient.model';
import { OrderedIngredient } from '../../models/ordering/ordered-ingredient.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { Utils } from '../../utils/utils';
import { OrderableQualifier } from '../../models/menu/orderable-qualifier.model';

@Injectable({ providedIn: 'root' })
export class OrderedIngredientService {
  public createOrderedIngredient(ingredient: OrderableIngredient, isLeftHalf = false, isRightHalf = false): OrderedIngredient {
    return this.createIngredient(
      ingredient.name,
      ingredient.isDefault,
      ingredient.isEverything,
      isLeftHalf,
      isRightHalf,
      ingredient.showNoDefault,
      ingredient.qualifiers
    );
  }
  /**@deprecated. Please use createOrderedIngredientFromOrderable instead.**/
  public createdDefaultIngredient(ingredient: string, isLeftHalf = false, isRightHalf = false, showNoDefault = false): OrderedIngredient {
    return this.createIngredient(ingredient, true, false, isLeftHalf, isRightHalf, showNoDefault);
  }
  /**@deprecated. Please use createOrderedIngredientFromOrderable directly instead.**/
  public createEverythingIngredient(ingredient: string, isLeftHalf = false, isRightHalf = false, showNoDefault = false): OrderedIngredient {
    return this.createIngredient(ingredient, false, true, isLeftHalf, isRightHalf, showNoDefault);
  }

  public createOrderedIngredientFromOrderable(
    ingredient: OrderableIngredient,
    isLeftHalf = false,
    isRightHalf = false,
    showNoDefault = false
  ): OrderedIngredient {
    return this.createIngredient(
      ingredient.name,
      ingredient.isDefault,
      ingredient.isEverything,
      isLeftHalf,
      isRightHalf,
      showNoDefault,
      ingredient.qualifiers
    );
  }

  public getCalories(
    orderable: OrderableIngredient,
    size: string,
    isHalf = false,
    qualifiers: string[] = [],
    secondHalfqualifiers: string[] = []
  ): number {
    if (!orderable || !size) {
      return 0;
    }

    const sizePrice = orderable.sizePrices.find(s => s.size === size);
    if (!sizePrice) {
      return 0;
    }

    let totalCalories = 0;

    if ((qualifiers || []).length) {
      const qualifierCalories = this.findQualifierCalories(orderable, size, isHalf, qualifiers);

      if (qualifierCalories.length && (secondHalfqualifiers || []).length && isHalf) {
        totalCalories = Math.max(...qualifierCalories);
      } else if (qualifierCalories.length) {
        return Math.max(...qualifierCalories);
      }
    }

    if ((secondHalfqualifiers || []).length) {
      const secondQualifierCalories = this.findQualifierCalories(orderable, size, isHalf, secondHalfqualifiers);

      if (secondQualifierCalories.length) {
        if (!qualifiers?.length) {
          totalCalories = sizePrice.caloriesHalf;
        }
        totalCalories = totalCalories + Math.max(...secondQualifierCalories);
      }
    }

    if (totalCalories) {
      return totalCalories;
    }

    return isHalf ? sizePrice.caloriesHalf : sizePrice.caloriesFrom;
  }

  private findQualifierCalories(orderable: OrderableIngredient, size: string, isHalf: boolean, qualifiers: string[] = []) {
    return qualifiers
      .map(q => orderable.qualifiers.find(oq => oq.name === q))
      .filter(q => !!q)
      .map(q => (isHalf ? q.halfSizeCaloriesMap[size] : q.sizeCaloriesMap[size]))
      .filter(q => !Utils.isNullOrUndefined(q));
  }

  private createIngredient(
    ingredient: string,
    isDefault: boolean,
    isEverything: boolean,
    isLeftHalf: boolean,
    isRightHalf: boolean,
    showNoDefault: boolean,
    qualifiers: OrderableQualifier[] = []
  ): OrderedIngredient {
    return DeserializationUtils.deserializeObj(
      {
        ingredient,
        isDefault,
        isEverything,
        isLeftHalf,
        isRightHalf,
        showNoDefault,
        qualifiers: qualifiers.filter(q => q.isDefault).map(q => q.name),
      },
      OrderedIngredient
    );
  }
}
