import { Injectable } from '@angular/core';
import { ConfigService, AllowRobots } from 'ngx-web-api';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaTagService {
  constructor(private configService: ConfigService, private meta: Meta) {}

  addMetaTags() {
    this.configService.storeConfig$.subscribe(storeConfig => {
      if (storeConfig.state) {
        this.meta.addTag({ name: 'geo.region', content: storeConfig.state });
      }
      if (storeConfig.city) {
        this.meta.addTag({ name: 'geo.placename', content: storeConfig.city });
      }
      if (storeConfig.latitude && storeConfig.longitude) {
        this.meta.addTag({
          name: 'geo.position',
          content: `${storeConfig.latitude};${storeConfig.longitude}`,
        });
        this.meta.addTag({
          name: 'ICBM',
          content: `${storeConfig.latitude}, ${storeConfig.longitude}`,
        });
      }
      if (storeConfig.latitude) {
        this.meta.addTag({
          name: 'place:location:latitude',
          content: storeConfig.latitude.toString(),
        });
      }
      if (storeConfig.longitude) {
        this.meta.addTag({
          property: 'place:location:longitude',
          content: storeConfig.longitude.toString(),
        });
      }

      if (storeConfig.allowRobots !== AllowRobots.ALLOW) {
        this.meta.addTag({ name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' });
        this.meta.addTag({ name: 'ROBOTS', content: 'NONE' });
      }

      if (storeConfig.documentMetaDescription) {
        this.meta.addTag({
          name: 'description',
          content: storeConfig.documentMetaDescription.toString(),
        });
      }
    });
  }
}
