import { take, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotificationModalType } from './../../domain/notification-modal-type.enum';
import { ConfirmationModalContext } from './../../domain/confirmation-modal-context';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from './../../core/services/modal.service';
import { Component, OnInit } from '@angular/core';
import { AddressValidationResult, OrderService, Order, AuditService } from 'ngx-web-api';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { ReloaderService } from 'app/core/services/reloader.service';
import { InitParamsStorageService } from 'app/core/services/init-params-storage.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressConfirmationModalContext } from 'app/domain/address-confirmation-modal-context';

@Component({
  selector: 'fts-address-confirmation-modal',
  templateUrl: './address-confirmation-modal.component.html',
  styleUrls: ['./address-confirmation-modal.component.scss'],
})
export class AddressConfirmationModalComponent implements OnInit {
  public context: AddressConfirmationModalContext;
  addressOutOfMapValidation: AddressValidationResult;
  redirectParams: object;

  constructor(
    private uiOrchestratorService: UiOrchestratorService,
    private reloaderService: ReloaderService,
    private initParamsStorageService: InitParamsStorageService,
    private activeModal: NgbActiveModal,
    private orderService: OrderService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private auditService: AuditService
  ) {}

  handleSubmit() {
    this.activeModal.close(this.addressOutOfMapValidation.overridable);
  }

  ngOnInit() {
    this.auditService.createAudit(() => ({
      message: `Address confirmation modal opened with the following data context`,
      details: { ...this.context?.addressOutOfMapValidation },
    }));
    this.addressOutOfMapValidation = this.context.addressOutOfMapValidation;
    this.redirectParams = this.context.redirectParams;
    this.uiOrchestratorService.informModalOpened();
  }

  hideModal(): void {
    this.activeModal.close(false);
  }

  goToSuggestedStore(): void {
    // Map Address model to query params address fields
    let mappedAddressParams = {
      address: this.redirectParams['street'],
      diff1: !!this.redirectParams['diff1'] && !!this.redirectParams['value1'] ? this.redirectParams['value1'] : undefined,
      diff2: !!this.redirectParams['diff2'] && !!this.redirectParams['value2'] ? this.redirectParams['value2'] : undefined,
      diff3: !!this.redirectParams['diff3'] && !!this.redirectParams['value3'] ? this.redirectParams['value3'] : undefined,
      addressName: this.redirectParams['name'],
    };
    mappedAddressParams = Object.assign({}, this.redirectParams, mappedAddressParams);

    this.reloaderService.redirect(`/ws/integrated/v1/store/${encodeURIComponent(this.addressOutOfMapValidation.storePrimaryKey)}/url`, {
      ...this.initParamsStorageService.redirectParams(),
      ...mappedAddressParams,
    });
  }

  onChangeStore() {
    const context = new ConfirmationModalContext();
    context.title = this.translateService.instant('component.header.store_info.modal_change_store.title');
    context.mainContent = this.translateService.instant('component.header.store_info.modal_change_store.message');
    context.submitBtnTitle = this.translateService.instant('component.header.store_info.modal_change_store.continue');
    context.dismissBtnTitle = this.translateService.instant('component.header.store_info.modal_change_store.close');
    context.type = NotificationModalType.warning;
    context.submitObservable = of(true);
    this.orderService.order$
      .pipe(
        take(1),
        map((order: Order) => order.isInitialized),
        mergeMap(isInitialized => {
          if (isInitialized) {
            return this.modalService.openConfirmationModal(context);
          }
          return of(true);
        })
      )
      .subscribe(result => {
        if (result) {
          this.goToSuggestedStore();
        }
      });
  }
}
