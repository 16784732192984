<div class='modal-content primary-bg-color primary-text-color'>
  <div class='modal-header secondary-bg-color secondary-text-color'>
    <h2 id="modal-title" class='modal-title float-left'>{{'component.address_confirmation_modal.address_validation' | translate}}</h2>
    <button class='close float-right' (click)='hideModal()' [attr.aria-label]="'component.address_confirmation_modal.close' | translate">
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body'>
    <p>{{addressOutOfMapValidation.error}}</p>
    <p *ngIf='addressOutOfMapValidation.overridable'>{{'component.address_confirmation_modal.sure_to_continue' | translate}}</p>
    <div class='modal-buttons'>
      <button (click)="onChangeStore()" class="btn action-bg-color action-inverse-text-color primary-btn action-btn font-secondary" ftsAuditableButton
      *ngIf='addressOutOfMapValidation.overridable && addressOutOfMapValidation.storeName && addressOutOfMapValidation.storeUrl'>
        {{'component.address_confirmation_modal.goto' | translate}} {{addressOutOfMapValidation.storeName}}
      </button>
      <button (click)="handleSubmit()" class="btn action-bg-color action-inverse-text-color primary-btn action-btn font-secondary" ftsAuditableButton
      *ngIf='addressOutOfMapValidation.overridable || !(addressOutOfMapValidation.storeName && addressOutOfMapValidation.storeUrl)'>
        {{(addressOutOfMapValidation.overridable ? 'component.address_confirmation_modal.continue' :
        'component.address_confirmation_modal.ok') | translate}}
      </button>
      <button (click)="onChangeStore()" class="btn action-bg-color action-inverse-text-color primary-btn action-btn font-secondary" ftsAuditableButton
      *ngIf='!addressOutOfMapValidation.overridable && addressOutOfMapValidation.storeName && addressOutOfMapValidation.storeUrl'>
        {{'component.address_confirmation_modal.goto' | translate}} {{addressOutOfMapValidation.storeName}}
      </button>
      <button class="btn primary-bg-color action-text-color dismiss-btn action-btn font-secondary" (click)='hideModal()' ftsAuditableButton
      *ngIf='(addressOutOfMapValidation.overridable) || (addressOutOfMapValidation.storeName && addressOutOfMapValidation.storeUrl)'>
        {{'component.address_confirmation_modal.cancel' | translate}}
      </button>
    </div>
  </div>
</div>
