import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FeaturedItem } from 'ngx-web-api';
import { ViewportService } from 'app/core/services/viewport.service';
import { ViewPort } from 'app/domain/view-port.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'fts-featured-content-fading',
  templateUrl: './featured-content-fading.component.html',
  styleUrls: ['./featured-content-fading.component.scss'],
})
export class FeaturedContentFadingComponent implements OnInit, OnChanges {
  @Input()
  featuredItems: FeaturedItem[];
  @Input()
  fillParentHeight: boolean;
  @Input()
  speed: number;
  @Input()
  skipLazyLoading: boolean;
  @Input()
  itemImgHeight: number;
  @Output()
  chosenFeaturedItem: EventEmitter<FeaturedItem & { __slotNum?: string }> = new EventEmitter();
  @Output()
  loadImageHeight: EventEmitter<number> = new EventEmitter();

  private SPACE_KEY_CODE = 32;
  private ENTER_KEY_CODE = 13;
  private SPACE_KEY = ' ';
  private ENTER_KEY = 'Enter';
  private maxImageHeight = 0;
  animationStates: string[];
  currentVisibleIndex = 0;
  intervalPointer: any;
  featuredItemsButtonLabels: string[];
  height: string;
  public viewPort$: Observable<ViewPort>;

  constructor(private viewportService: ViewportService) {}

  ngOnInit() {
    this.viewPort$ = this.viewportService.viewport$;
    this.animationStates = [];
    this.animationStates.push('visible');
    this.featuredItems.slice(1).forEach(() => this.animationStates.push('hidden'));
    this.startAnimation();

    this.featuredItemsButtonLabels = this.featuredItems.map(featuredItem => {
      if (featuredItem.item) {
        return `Featured item ${(featuredItem.title || '') + (featuredItem.subtitle || '') || featuredItem.item.name}`;
      } else if (featuredItem.special) {
        return `Featured special ${(featuredItem.title || '') + (featuredItem.subtitle || '') || featuredItem.special.label}`;
      } else {
        return '';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fillParentHeight']) {
      this.calcHeight();
    }
  }

  handleKeyDown(event, featuredItem: FeaturedItem & { __slotNum?: string }, index: number) {
    if (this.isEnterKey(event) || this.isSpaceKey(event)) {
      event.preventDefault();
      event.stopPropagation();
      this.onFeaturedItemChosen(featuredItem, index);
    }
  }

  startAnimation() {
    this.stopAnimation();
    this.intervalPointer = setInterval(() => this.changeVisibleSlide(), this.speed * 1000);
  }

  stopAnimation() {
    clearInterval(this.intervalPointer);
  }

  onImageLoaded(imgHeight: number) {
    if (imgHeight > this.maxImageHeight) {
      this.maxImageHeight = imgHeight;
    }
    this.loadImageHeight.emit(imgHeight);

    this.calcHeight();
  }

  calcHeight() {
    if (this.fillParentHeight) {
      this.height = '100%';
    } else {
      this.height = `${this.maxImageHeight}px`;
    }
  }

  onVisibleIndexChange(index) {
    this.currentVisibleIndex = index;
    this.animationStates.fill('hidden');
    this.animationStates[index] = 'visible';
  }

  onFeaturedItemChosen(featuredItem: FeaturedItem & { __slotNum?: string }, index: number) {
    featuredItem.slotNum = `${index}`;
    this.chosenFeaturedItem.emit(featuredItem);
  }

  focusFeaturedItem(index: number) {
    this.stopAnimation();
    this.onVisibleIndexChange(index);
  }

  private changeVisibleSlide() {
    this.currentVisibleIndex++;

    if (this.currentVisibleIndex >= this.featuredItems.length) {
      this.currentVisibleIndex = 0;
    }

    this.onVisibleIndexChange(this.currentVisibleIndex);
  }

  private isEnterKey(event) {
    return (
      (event.key !== undefined && event.key === this.ENTER_KEY) || (event.keyCode !== undefined && event.keyCode === this.ENTER_KEY_CODE)
    );
  }

  private isSpaceKey(event) {
    return (
      (event.key !== undefined && event.key === this.SPACE_KEY) || (event.keyCode !== undefined && event.keyCode === this.SPACE_KEY_CODE)
    );
  }
}
