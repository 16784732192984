<form class="form" [ngClass]="{'form-horizontal': horizontal}" #addressForm='ngForm'>
  <div class="form-group required" [ngClass]="{'has-error': errors?.name || (showClientValidation && addressNameRef?.touched && !isGuest && !address?.name)}" *ngIf='!isGuest'>
    <label for="addressName" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">
      {{'component.address_form.address_name' | translate}}
    </label>
    <div [ngClass]="{'col-md-8': horizontal}">
      <input type="text"
            [ngModel]="address?.name" (ngModelChange)="address.name=$event"
            class="form-control primary-bg-color primary-text-color"
            id="addressName"
            name="addressName"
            [attr.placeholder]="'component.address_form.name_placeholder' | translate"
            [disabled]="isEditing"
            [required]='!isGuest'
            [ngClass]="{'is-invalid': errors?.name || (showClientValidation && addressNameRef?.touched && !isGuest && !address?.name)}"
            #addressNameRef="ngModel"
            [attr.aria-invalid]="errors?.name || (showClientValidation && addressNameRef?.touched && !isGuest && !address?.name)"
            [attr.aria-describedby]="(errors?.name | generateIds : 'address-form-name-error' :
              (showClientValidation && addressNameRef?.touched && !isGuest && !address.name ? 'address-form-name-client-error' : ''))">
      <span id="address-form-name-error-{{i}}" class="form-text" *ngFor="let error of errors?.name; let i = index">{{error}}</span>
      <span id="address-form-name-client-error" class="form-text" *ngIf='showClientValidation && addressNameRef?.touched && !isGuest && !address.name'>{{errorSet.get('name')}}</span>
    </div>
  </div>
  <div class="form-group required" [ngClass]="{'has-error': errors?.addressType || (showClientValidation && addressTypeRef?.touched && !address.addressType)}">
    <label for="addressType" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">{{'component.address_form.address_type' | translate}}</label>
    <div [ngClass]="{'col-md-8': horizontal}">
      <select [ngModel]="address?.addressType"
              [ngClass]="{'is-invalid': errors?.addressType || (showClientValidation && addressTypeRef?.touched && !address.addressType)}"
              id='addressType'
              name="addressType"
              (ngModelChange)="handleAddressTypeChange($event)"
              class="form-control primary-bg-color primary-text-color"
              selected=""
              required
              #addressTypeRef="ngModel"
              [attr.aria-describedby]="(errors?.addressType | generateIds : 'address-form-addressType-error' :
                (showClientValidation && addressTypeRef?.touched && !address.addressType ? 'address-form-addressType-client-error' : ''))">
        <option disabled [value]="undefined">{{'component.address_form.select' | translate}}...</option>
        <option *ngFor="let option of addressTypes" [value]="option.addressType">{{ option.addressType }}</option>
      </select>
      <span id="address-form-addressType-error-{{i}}" class="form-text" *ngFor="let error of errors?.addressType; let i = index">{{error}}</span>
      <span id="address-form-addressType-client-error" class="form-text" *ngIf='showClientValidation && addressTypeRef?.touched && !address.addressType'>{{errorSet.get('addressType')}}</span>
    </div>
  </div>
  <fts-loading-indicator *ngIf="buildingsLoading"></fts-loading-indicator>
  <ng-container *ngIf="!!address?.addressType && !buildingsLoading">
    <ng-container *ngIf="!!buildings?.length">
      <fts-themeable-radio
          name="building-radio-select"
          [value]="streetTypeEnum.BUILDING"
          [(ngModel)]='streetType'
          [fullWidth]="true"
          [noPadding]="true"
          (ngModelChange)="handleStreetTypeChange($event)"
          [topAlignRadio]="true">
        <div class="form-group" [ngClass]="{'has-error': showClientValidation && streetType === streetTypeEnum.BUILDING && (!selectedBuildingName || showOverrideForBuilding), 'required' : streetType === streetTypeEnum.BUILDING}">
          <label class="col-form-label" for="buildings" [ngClass]="{'col-md-4': horizontal}">{{'component.address_form.building' | translate}}</label>
          <div [ngClass]="{'col-md-8': horizontal}">
            <select [ngModel]="selectedBuildingName"
                    [ngClass]="{'is-invalid': showClientValidation && streetType === streetTypeEnum.BUILDING && (!selectedBuildingName || showOverrideForBuilding)}"
                    [disabled]="streetType !== streetTypeEnum.BUILDING"
                    required
                    class="form-control input-block-level primary-bg-color primary-text-color"
                    id="buildings"
                    name="buildings"
                    [ngClass]="{'input-readonly allow-parent-events' : streetType !== streetTypeEnum.BUILDING}"
                    (ngModelChange)="handleBuildingChange($event)"
                    [attr.aria-describedby]="showClientValidation && streetType === streetTypeEnum.BUILDING && !selectedBuildingName ? 'address-form-buildings-client-error' : null">
              <option disabled [value]="undefined">{{'component.address_form.select' | translate}}...</option>
              <option *ngFor="let building of buildings" [value]="building.buildingName">
                {{building.buildingName}} [{{building.address}}{{storeConfig?.useInternationalStreetFormat ? ' ' + building?.streetNum : ''}}]
              </option>
            </select>
            <span id="address-form-buildings-client-error" class="form-text" *ngIf="showClientValidation && streetType === streetTypeEnum.BUILDING && !selectedBuildingName">{{errorSet.get('buildings')}}</span>
            <span id="address-form-buildings-client-error-{{i}}" role="alert" class="form-text" *ngFor="let error of errors?.buildings; let i = index">{{error}}</span>
            <ng-container [ngTemplateOutlet]="overrideCheckbox" [ngTemplateOutletContext]="{showOverride: showOverrideForBuilding}"></ng-container>
          </div>
        </div>
      </fts-themeable-radio>

      <ng-container [ngTemplateOutlet]="findMyLocationButton" [ngTemplateOutletContext]="{inCheckbox: true}"></ng-container>

      <fts-themeable-radio
        name="building-radio-select"
        [value]="streetTypeEnum.STREET"
        [(ngModel)]='streetType'
        [fullWidth]="true"
        [noPadding]="true"
        (ngModelChange)="handleStreetTypeChange($event)"
        [topAlignRadio]="true">
          <ng-container *ngTemplateOutlet='streetField'></ng-container>
          <ng-container *ngIf="streetType === streetTypeEnum.STREET">
            <ng-container *ngIf="!storeConfig.hideStreetAndZip; else cityHiddenZipFields">
              <ng-container *ngTemplateOutlet='cityStateZipFields'></ng-container>
            </ng-container>
          </ng-container>
      </fts-themeable-radio>
    </ng-container>

    <ng-container *ngIf="!buildings?.length">
      <ng-container [ngTemplateOutlet]="findMyLocationButton" [ngTemplateOutletContext]="{inCheckbox: false}"></ng-container>
      <ng-container *ngTemplateOutlet='streetField'></ng-container>
      <ng-container *ngIf="!storeConfig.hideStreetAndZip; else cityHiddenZipFields">
        <ng-container *ngTemplateOutlet='cityStateZipFields'></ng-container>
      </ng-container>
    </ng-container>

    <div [ngClass]="{'d-none' : (!streetType && !!buildings?.length)}">
      <fts-edit-address-diff [required]="addressType?.requireDiff1"
        [showClientValidation]='showClientValidation'
        [horizontal]='horizontal'
        [(ngModel)]='address.value1'
        [name]='addressType?.diff1'
        [addressTypeDiffNumber]="1"
        [errors]="errors?.value1"
        [diffValueMaxLength]="storeConfig?.addressDiff1ValueMaxLength"
        *ngIf="addressType?.diff1">
      </fts-edit-address-diff>
      <fts-edit-address-diff [required]="addressType?.requireDiff2"
        [showClientValidation]='showClientValidation'
        [horizontal]='horizontal'
        [(ngModel)]='address.value2'
        [name]='addressType?.diff2'
                             [addressTypeDiffNumber]="2"
        [errors]="errors?.value2"
        [diffValueMaxLength]="storeConfig?.addressDiff2ValueMaxLength"
        *ngIf="addressType?.diff2">
      </fts-edit-address-diff>
      <fts-edit-address-diff [required]="addressType?.requireDiff3"
        [showClientValidation]='showClientValidation'
        [horizontal]='horizontal'
        [(ngModel)]='address.value3'
        [name]='addressType?.diff3'
                             [addressTypeDiffNumber]="3"
        [errors]="errors?.value3"
                            [diffValueMaxLength]="storeConfig?.addressDiff3ValueMaxLength"
        *ngIf="addressType?.diff3">
      </fts-edit-address-diff>
    </div>

    <div *ngIf="storeConfig.maxDeliveryInstructionsLength" class="form-group"
      [ngClass]="{'has-error': errors?.deliveryInstructions || (showClientValidation && storeConfig.maxDeliveryInstructionsLength < address?.deliveryInstructions?.length)}">
      <label for="deliveryInstructions" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">{{'component.address_form.instructions' | translate}}</label>
      <span id="address-form-deliveryInstructions-help" *ngIf='deliveryNote || storeConfig?.webAddressInstructionsNote' class="form-text primary-text-color margin-bottom-10">{{deliveryNote || storeConfig?.webAddressInstructionsNote}}</span>
      <div [ngClass]="{'col-md-8': horizontal}">
        <textarea
          [(ngModel)]="address.deliveryInstructions"
          class="form-control vert-resize primary-bg-color primary-text-color"
          [ngClass]="{'is-invalid': (showClientValidation && storeConfig.maxDeliveryInstructionsLength < address?.deliveryInstructions?.length) || errors?.deliveryInstructions}"
          id="deliveryInstructions"
          [maxlength]='storeConfig.maxDeliveryInstructionsLength'
          name="deliveryInstructions"
          [attr.aria-describedby]="(errors?.deliveryInstructions | generateIds : 'address-form-deliveryInstructions-error' :
            ('address-form-deliveryInstructions-charactersLeft' + (deliveryNote || storeConfig?.webAddressInstructionsNote) ? ' address-form-deliveryInstructions-help' : ''))"></textarea>
        <span
          *ngIf='!(showClientValidation && storeConfig.maxDeliveryInstructionsLength < address?.deliveryInstructions?.length)'
          id="address-form-deliveryInstructions-charactersLeft" class="form-text">{{'component.address_form.chars_left' | translate}}: {{instructionsCharactersLeft}}</span>
        <span id="address-form-deliveryInstructions-error-{{i}}" class="form-text" *ngFor="let error of errors?.deliveryInstructions; let i = index">{{error}}</span>
        <span id="address-form-deliveryInstructions-client-error" class="form-text"
        *ngIf='showClientValidation && storeConfig.maxDeliveryInstructionsLength < address?.deliveryInstructions?.length'>{{'component.address_form.validations.delivery_instructions.maxlength' | translate}}
        {{storeConfig.maxDeliveryInstructionsLength}}</span>
      </div>
    </div>

  </ng-container>

  <ng-template #streetField>
    <div [ngClass]="{'row' : storeConfig?.useInternationalStreetFormat}">
      <div [ngClass]="{'col-md-8' : storeConfig?.useInternationalStreetFormat}">
        <div class="form-group"
            [ngClass]="{'has-error': errors?.street || (showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address?.street), 'required' : (streetType === streetTypeEnum.STREET || !buildings?.length)}">
          <label for="street" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">
            {{'component.address_form.street' | translate}}
          </label>
          <div style="position:relative;" [ngClass]="{'col-md-8': horizontal}">
            <div *ngIf="loadingStreets" style="position:absolute;right:20px;" class="loading-button margin-top-10 padded-top-10">
            </div>
            <input *ngIf="streetType !== streetTypeEnum.BUILDING" #streetRef type="text" [value]="address.street || ''" [ngbTypeahead]="streetSearch"
            [inputFormatter]="inputFormatMatches" [resultFormatter]="formatMatches" (selectItem)="typeaheadOnSelect($event)" [maxLength]="storeConfig?.addressMaxLength" (input)="onStreetInput(streetRef.value)"
               class="form-control primary-bg-color primary-text-color" id="street" (blur)="onStreetBlur()"
              name="street" autocomplete="off" required [disabled]="streetType !== streetTypeEnum.STREET && !!buildings?.length" [ngClass]="{'is-invalid': errors?.street || (showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address?.street), 'input-readonly allow-parent-events' : streetType !== streetTypeEnum.STREET && !!buildings?.length}"
              [attr.aria-invalid]="errors?.street || (showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.street)"
              [attr.aria-describedby]="(errors?.street | generateIds : 'address-form-street-error' : (showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.street ? 'address-form-street-client-error' : ''))">
            <input *ngIf="streetType === streetTypeEnum.BUILDING" #streetRef type="text" class="input-readonly form-control primary-bg-color primary-text-color" id="street"
              name="street" autocomplete="off" [disabled]="true" [ngClass]="{'is-invalid': errors?.street || (showClientValidation && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address?.street), 'allow-parent-events' : streetType !== streetTypeEnum.STREET}"
              [attr.aria-invalid]="errors?.street || (showClientValidation && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.street)"
              [attr.aria-describedby]="(errors?.street | generateIds : 'address-form-street-error' : (showClientValidation && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.street ? 'address-form-street-client-error' : ''))">
            <span class="form-text" *ngIf="!!storeConfig?.infoMessageOnOutOfMap && streetType !== streetTypeEnum.BUILDING && emptyTypeaheadFetched">{{storeConfig.infoMessageOnOutOfMap}}</span>
            <span id="address-form-street-error-{{i}}" role="alert" class="form-text" *ngFor="let error of errors?.street; let i = index">{{error}}</span>
            <span id="address-form-street-client-error" class="form-text" *ngIf='showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.street'>{{errorSet.get('street')}}</span>
            <ng-container [ngTemplateOutlet]="overrideCheckbox" [ngTemplateOutletContext]="{showOverride: showOverrideForStreet}"></ng-container>
            <span *ngIf="!!findMyLocationError" class="has-error fw-400" role="alert">{{findMyLocationError}}</span>
          </div>
        </div>
      </div>

      <div [ngClass]="{'col-md-4' : storeConfig?.useInternationalStreetFormat}">
        <div *ngIf="storeConfig?.useInternationalStreetFormat" class="form-group required" [ngClass]="{'has-error': errors?.streetNum || (showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.streetNum), 'required' : (streetType === streetTypeEnum.STREET || !buildings?.length)}">
          <label for="streetNum" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">{{'component.address_form.street_num' | translate}}</label>
          <div [ngClass]="{'col-md-8': horizontal}">
            <input *ngIf="streetType !== streetTypeEnum.BUILDING" type="text" [textMask]="{mask: streetNumMask, guide: false}" [(ngModel)]="address.streetNum" class="form-control primary-bg-color primary-text-color" id="streetNum" name="streetNum" required [ngClass]="{'is-invalid': errors?.streetNum || (showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.streetNum), 'input-readonly allow-parent-events' : streetType !== streetTypeEnum.STREET && !!buildings?.length}"
            [attr.aria-invalid]="errors?.streetNum || (showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.streetNum)"
            [attr.aria-describedby]="(errors?.streetNum | generateIds : 'address-form-streetNum-error')">
            <input *ngIf="streetType === streetTypeEnum.BUILDING" type="text" class="input-readonly form-control primary-bg-color primary-text-color" id="streetNum" name="streetNum" [disabled]="true" [ngClass]="{'is-invalid': errors?.streetNum || (showClientValidation && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.streetNum), 'allow-parent-events' : streetType !== streetTypeEnum.STREET}"
            [attr.aria-invalid]="errors?.streetNum || (showClientValidation && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.streetNum)"
            [attr.aria-describedby]="(errors?.streetNum | generateIds : 'address-form-streetNum-error' : (showClientValidation && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.streetNum ? 'address-form-streetNum-client-error' : ''))">
            <span id="address-form-streetNum-error-{{i}}" class="form-text" *ngFor="let error of errors?.streetNum; let i = index">{{error}}</span>
            <span id="address-form-streetNum-client-error" class="form-text" *ngIf='showClientValidation && streetTouched && (streetType === streetTypeEnum.STREET || !buildings?.length) && !address.streetNum'>{{errorSet.get('streetNum')}}</span>
          </div>
        </div>

      </div>
    </div>

  </ng-template>

  <ng-template #cityStateZipFields>
    <div class="form-group">
      <label for="city" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">{{'component.address_form.city' | translate}}</label>
      <div [ngClass]="{'col-md-8': horizontal}">
        <input type="text" [(ngModel)]="address.city" class="form-control primary-bg-color primary-text-color input-readonly default-cursor" id="city" name="city" disabled required
        [attr.aria-invalid]="errors?.city"
        [attr.aria-describedby]="(errors?.city | generateIds : 'address-form-city-error')">
        <span id="address-form-city-error-{{i}}" class="form-text" *ngFor="let error of errors?.city; let i = index">{{error}}</span>
      </div>
    </div>
    <div [ngClass]="{'row': !horizontal}">
      <div class="form-group" [ngClass]="{'col-6': !horizontal}">
        <label for="state" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">{{'component.address_form.state' | translate}}</label>
        <div [ngClass]="{'col-md-8': horizontal}">
          <input type="text" [(ngModel)]="address.state" class="form-control primary-bg-color primary-text-color input-readonly default-cursor" id="state" name="state" disabled
          [attr.aria-invalid]="errors?.state"
          [attr.aria-describedby]="(errors?.state | generateIds : 'address-form-state-error')">
          <span id="address-form-state-error-{{i}}" class="form-text" *ngFor="let error of errors?.state; let i = index">{{error}}</span>
        </div>
      </div>
      <div class="form-group required" [ngClass]="{'col-6': !horizontal, 'has-error': errors?.zip || (showClientValidation && zipInput?.touched && !address.zip)}">
        <label for="zip" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">{{'component.address_form.zip' | translate}}</label>
        <div [ngClass]="{'col-md-8': horizontal}">
          <input #zipInput="ngModel" type="text" [(ngModel)]="address.zip" (keyup)="handleZipChange(zipInput.value)"
                class="form-control primary-bg-color primary-text-color" id="zip" name="zip" required [ngClass]="{'is-invalid': errors?.zip || (showClientValidation && zipInput?.touched && !address.zip)}"
                [attr.aria-invalid]="errors?.zip || (showClientValidation && zipInput?.touched && !address.zip)"
                [attr.aria-describedby]="(errors?.zip | generateIds : 'address-form-zip-error' : (showClientValidation && zipInput?.touched && !address.zip ? 'address-form-zip-client-error' : ''))">
          <span id="address-form-zip-error-{{i}}" class="form-text" *ngFor="let error of errors?.zip; let i = index">{{error}}</span>
          <span id="address-form-zip-client-error" class="form-text" *ngIf='showClientValidation && zipInput?.touched && !address.zip'>{{'component.address_form.required' | translate}}</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #cityHiddenZipFields>
    <div class="form-group required" [ngClass]="{'has-error': showClientValidation && !address.city}">
      <label for="city" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">{{'component.address_form.city' | translate}}</label>
      <div [ngClass]="{'col-md-8': horizontal}">
        <select [ngModel]="address.zip"
                (ngModelChange)="onCityZipChange($event)"
                [ngClass]="{'is-invalid': showClientValidation && !address.city}"
                id='city'
                name='city'
                class="form-control primary-bg-color primary-text-color"
                selected=""
                required
                [attr.aria-describedby]="(errors?.city | generateIds : 'address-form-city-error' : (showClientValidation && !address.city ? 'address-form-city-client-error' : ''))">
          <option disabled [value]="undefined">{{'component.address_form.select' | translate}}...</option>
          <option *ngFor="let cityZip of cityZips" [value]="cityZip.zip">{{ cityZip.city }}</option>
        </select>
        <span id="address-form-city-error-{{i}}" class="form-text" *ngFor="let error of errors?.city; let i = index">{{error}}</span>
        <span id="address-form-city-client-error" class="form-text" *ngIf='showClientValidation && !address.city'>{{'component.address_form.required' | translate}}</span>
      </div>
    </div>
  </ng-template>
</form>

<ng-template #findMyLocationButton let-inCheckbox="inCheckbox">
  <div *ngIf="storeConfig.needsLocationAccess && viewportService.isOpenInMobileBrowser()" class="d-flex flex-row align-items-center justify-content-start">
    <button ftsAsyncButton [subscription]="findAddressSubscription" ftsAuditableButton class="btn btn-sm btn-rounded action-bg-color action-inverse-text-color mb-2" (click)="findMyLocation()" [ngClass]="{'margin-left-18': inCheckbox}">
      <fa-icon [icon]="faLocationArrow" [fixedWidth]="true"></fa-icon>
      <span class="me-1">{{'component.address_form.use_my_location' | translate}}</span>
    </button>
  </div>
</ng-template>


<ng-template #overrideCheckbox let-showOverride="showOverride">
  <fts-themeable-checkbox *ngIf="showOverride" [(ngModel)]="address.force" [name]="'force'">
    {{'component.address_form.double_checked' | translate}}
  </fts-themeable-checkbox>
</ng-template>

<ng-container>
  <ng-template [ngComponentOutlet]="dynamicLoadModal | async"></ng-template>
</ng-container>
