<div class="form-group" [ngClass]="{'has-error': errors || (showClientValidation && diffRef?.touched && required && !diffValue), 'required': required}">
  <label [for]="(name | slugify)" class="col-form-label" [ngClass]="{'col-md-4': horizontal}">{{ name }}</label>
  <div [ngClass]="{'col-md-8': horizontal}">
    <input [(ngModel)]="diffValue" #diffRef="ngModel"
           (ngModelChange)="handleChange($event)"
           class="form-control primary-bg-color primary-text-color"
           [ngClass]="{'is-invalid': errors || (showClientValidation && diffRef?.touched && required && !diffValue)}"
           [id]="(name | slugify)"
           [name]="(name | slugify)"
           [maxlength]="diffValueMaxLength"
           required
           [attr.aria-invalid]="errors || (showClientValidation && diffRef?.touched && required && !diffValue)"
           [attr.aria-describedby]="(errors | generateIds : 'edit-address-diff-' + (name | slugify) + '-error' : (showClientValidation && diffRef?.touched && required && !diffValue ? 'edit-address-diff-' + (name | slugify) + '-client-error' : ''))">
    <span id="edit-address-diff-{{(name | slugify)}}-error-{{i}}" class="form-text" *ngFor="let error of errors; let i = index">{{error}}</span>
    <span id="edit-address-diff-{{(name | slugify)}}-client-error" class="form-text" *ngIf='showClientValidation && diffRef?.touched && required && !diffValue'>
      {{'component.edit_address_diff.required' | translate}}
    </span>
  </div>
</div>
