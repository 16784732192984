import { Injectable, Type } from '@angular/core';
import {
  AuditService,
  Coupon,
  CouponResolution,
  CouponService,
  FeaturedItem,
  HypermediaService,
  Order,
  OrderableSpecial,
  OrderedSpecial,
  OrderService,
  ReOrder,
  UpsellType,
  Utils,
  AccountService,
} from 'ngx-web-api';
import { ErrorsService } from 'app/core/services/errors.service';

import { Observable, of, throwError, noop, Subject, combineLatest, from } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, take, tap } from 'rxjs/operators';

import { InitModalConfig } from '../../domain/init-modal-config';
import { NotificationModalType } from '../../domain/notification-modal-type.enum';
import { ConfirmationModalContext } from '../../domain/confirmation-modal-context';
import { CouponInstructionsModalContext } from '../../domain/coupon-instructions-modal-context';
import { OrderWarningsModalContext } from '../../domain/order-warnings-modal-context';
import { SpecialWizard } from '../../domain/special-wizard';
import { SpecialWizardModalContext } from '../../domain/special-wizard-modal-context';
import { SpecialInstructionsModalContext } from '../../menu/special-instructions-modal-context';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal/confirmation-modal.component';
import { CouponInstructionsModalComponent } from '../../shared/coupon-instructions-modal/coupon-instructions-modal.component';
import { OrderWarningsModalComponent } from '../../shared/order-warnings-modal/order-warnings-modal.component';
import { SpecialInstructionsModalComponent } from '../../shared/special-instructions-modal/special-instructions-modal.component';
import { SpecialWizardModalComponent } from '../../shared/special-wizard-modal/special-wizard-modal.component';
import { SpecialWizardService } from './special-wizard.service';
import { ParsedErrorResponse } from '../../domain/parsed-error-response.model';
import { TranslateService } from '@ngx-translate/core';
import { ResendVerificationEmailModalContext } from 'app/domain/resend-verification-email-modal-context';
import { ConfirmationModalInlineComponent } from 'app/shared/confirmation-modal-inline/confirmation-modal-inline.component';
import { SocialLinksService } from './social-links.service';
import { OperatingSystem } from 'app/domain/operating-system.enum';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FocusService } from 'app/focus.service';
import { EcommerceTriggeringArea } from '../../domain/ecommerce-triggering-area.enum';
import { GTMService } from './gtm.service';
import { ItemEditorModalContext } from 'app/domain/item-editor-modal-context';
import { ItemEditorModalComponent } from 'app/shared/item-editor-modal/item-editor-modal.component';
import { ReloaderService } from './reloader.service';
import { InitParamsStorageService } from './init-params-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private couponNotify: Subject<any> = new Subject();
  public couponNotify$: Observable<any> = this.couponNotify.asObservable();
  public context: InitModalConfig;
  public response: Subject<any> = new Subject();
  private forceCloseModals = false;
  private keepOpenModal = false;
  public modalIsOpen: boolean;
  private couponNotifyInterval: any;

  constructor(
    private errorService: ErrorsService,
    private specialWizardService: SpecialWizardService,
    private couponService: CouponService,
    private hypermedia: HypermediaService,
    private auditService: AuditService,
    private orderService: OrderService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private socialLinksService: SocialLinksService,
    private ngbModalService: NgbModal,
    private focusService: FocusService,
    private gtmService: GTMService,
    private reloaderService: ReloaderService,
    private paramsService: InitParamsStorageService
  ) {
    const mo = new MutationObserver(() => {
      if (document.body.classList.contains('modal-open')) {
        document.body.parentElement.style.overflow = 'hidden';
        this.modalIsOpen = true;
      } else {
        document.body.parentElement.style.overflow = 'auto';
        this.modalIsOpen = false;
      }
    });
    mo.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  notifyCoupon(coupon: Coupon) {
    if (this.couponService.isIncompatibleOrExcludedError(coupon)) {
      this.openPendingCouponInstructionsModal(coupon);
    } else {
      this.couponNotifyInterval = setInterval(() => {
        if (this.modalIsOpen === false) {
          this.couponNotify.next(coupon);
          clearInterval(this.couponNotifyInterval);
        }
      }, 1000);
    }
  }

  setContext(context: InitModalConfig) {
    this.context = context;
  }

  setResponse(response: any) {
    this.response.next(response);
  }

  openConfirmationModal(context: ConfirmationModalContext, defaultHandling = true, inline = false): Observable<any> {
    const shouldHandleSubmission = defaultHandling && (Utils.isNullOrUndefined(context.hasSubmitBtn) || context.hasSubmitBtn);
    return this.openModal(inline ? ConfirmationModalInlineComponent : ConfirmationModalComponent, context.getConfig()).pipe(
      catchError((errors): any => {
        if (errors === ModalDismissReasons.ESC || errors === ModalDismissReasons.BACKDROP_CLICK) {
          return of(false);
        }
        if (shouldHandleSubmission) {
          this.parseAndNotifyErrors(errors);
        }
        throwError(errors);
      })
    );
  }

  openPendingCouponInstructionsModal(coupon?: CouponResolution, skipInstructions = false): void {
    if (coupon) {
      if (this.couponService.isIncompatibleOrExcludedError(coupon)) {
        const content3 = this.translateService.instant('component.modal_service.pending_coupon_modal.content_3');
        const content4 = this.translateService.instant('component.modal_service.pending_coupon_modal.content_4');
        const content = `<p class="padded-bottom-10 fw-500">${this.translateService.instant(
          'component.modal_service.pending_coupon_modal.content_1'
        )}</p>
                         <p>${this.translateService.instant('component.modal_service.pending_coupon_modal.content_2')}<b>
                        ${content3}</b>${content4}</p>
                        <p class='fw-600 font-size-2'>${coupon.bestCouponName}</p>`;
        this.auditService.createAudit(() => `Offer conflict. Best offer with name "${coupon.bestCouponName}" is applied.`);
        this.openErrorNotificationModal(this.translateService.instant('component.modal_service.pending_coupon_modal.error'), content);
      } else if (!skipInstructions) {
        this.openCouponInstructionsModal(coupon);
      }
    }
  }

  openCouponInstructionsModal(coupon?: Coupon): void {
    if (coupon) {
      const context = new CouponInstructionsModalContext(coupon).getConfig();
      this.openModal(CouponInstructionsModalComponent, context);
    }
  }

  openOrderWarningsModal(order: Order): void {
    const context = new OrderWarningsModalContext(order).getConfig();
    if (order?.pendingSpecial) {
      this.openCouponInstructionsModal(Coupon.fromOrderableCoupon(order.pendingSpecial));
      return;
    }
    this.openModal(OrderWarningsModalComponent, context).subscribe(result => {
      if (!!result) {
        this.openSpecialInstructionsModal(result);
      }
    });
  }

  openCheckoutUpsellWarningsModal(order: Order): Observable<any> {
    const context = new OrderWarningsModalContext(order).getConfig();
    return this.openModal(OrderWarningsModalComponent, context);
  }

  openSpecialInstructionsModal(orderedSpecial: OrderedSpecial): void {
    this.hypermedia
      .get(orderedSpecial.links, 'orderableSpecial')
      .pipe(
        map((json: Object) => new OrderableSpecial().deserialize(json)),
        map((special: OrderableSpecial) => new SpecialInstructionsModalContext(special))
      )
      .subscribe(context => {
        this.openModal(SpecialInstructionsModalComponent, context.getConfig());
      });
  }

  openFeaturedSpecialWizard(
    featuredItem: FeaturedItem,
    featuredAreaName: string,
    featuredContentName: string,
    slotNum?: string
  ): Observable<any> {
    const orderableSpecial = featuredItem.special;
    this.gtmService.pushViewPromotionEvent(
      featuredItem.special.label || featuredItem.special.name,
      featuredItem.special.code,
      EcommerceTriggeringArea.FEATURED_AREA,
      featuredContentName,
      featuredAreaName,
      slotNum
    );
    if (orderableSpecial) {
      return this.specialWizardService.createSpecialWizard(orderableSpecial.links).pipe(
        filter((specialWizard: SpecialWizard) => !!specialWizard),
        tap((specialWizard: SpecialWizard) => {
          specialWizard.isUpsell = true;
          specialWizard.upsellType = UpsellType.FEATURED_AREA_SPECIAL;
          specialWizard.featuredAreaName = featuredAreaName;
          specialWizard.featuredContentName = featuredContentName;
        }),
        map((specialWizard: SpecialWizard) => new SpecialWizardModalContext(specialWizard)),
        mergeMap((context: SpecialWizardModalContext) => this.openModal(SpecialWizardModalComponent, context.getConfig())),
        tap(() => {
          this.auditService.createAudit(() => `Opening special editor for featured special item "${featuredItem.special.name}"`);
        }),
        catchError(error => {
          this.openErrorNotificationModal(
            this.translateService.instant('component.modal_service.special_error'),
            this.errorService.parseErrorResponse(error).message
          );
          return of(null);
        })
      );
    }
    return of(null);
  }

  openNotificationModal(
    type: NotificationModalType,
    title: string,
    mainContent: string,
    needDismissAudit = false,
    ignoreDismissOnNavigate = false,
    cssClass = ''
  ): Observable<any> {
    const context = new ConfirmationModalContext();
    context.title = title;
    context.hasSubmitBtn = false;
    context.dismissBtnTitle = this.translateService.instant('component.modal_service.ok');
    context.reverseButtons = true;
    context.mainContent = mainContent;
    context.type = type;
    context.needDismissAudit = needDismissAudit;
    context.ignoreDismissOnNavigate = ignoreDismissOnNavigate;
    context.class += ` ${cssClass}`;
    return this.openConfirmationModal(context, true);
  }

  openSuccessNotificationModal(title: string, mainContent: string = ''): Observable<any> {
    return this.openNotificationModal(NotificationModalType.success, title, mainContent);
  }

  openInfoNotificationModal(title: string, mainContent: string = '', ignoreDismissOnNavigate = false): Observable<any> {
    return this.openNotificationModal(NotificationModalType.info, title, mainContent, false, ignoreDismissOnNavigate);
  }

  openWarningNotificationModal(title: string, mainContent: string = '', needDismissAudit = false): Observable<any> {
    return this.openNotificationModal(NotificationModalType.warning, title, mainContent, needDismissAudit);
  }

  openErrorNotificationModal(title: any, mainContent: string = '', ignoreDismissOnNavigate = false, cssClass = ''): Observable<any> {
    if (typeof title === 'string') {
      return this.openNotificationModal(NotificationModalType.error, title, mainContent, false, ignoreDismissOnNavigate, cssClass);
    } else if (title.status !== 404) {
      // skip 404 since we use it internally to make sure that something does
      // not exists and we do not want to notify the user
      // TODO: We also need to handle cases that session has expired etc here.
      const errorTitle =
        title['error'].errors && title['error'].errors.webBillingZip[0] ? title['error'].errors.webBillingZip[0] : title['error'].message;
      return this.openNotificationModal(NotificationModalType.error, errorTitle, mainContent, false, ignoreDismissOnNavigate, cssClass);
    }
  }

  openMobileAppCouponModal(error: string) {
    const os = this.socialLinksService.getOperatingSystem();
    const isAndroid = os === OperatingSystem.android;
    const isIOS = os === OperatingSystem.iOS;

    const context = new ConfirmationModalContext();
    context.title = this.translateService.instant('component.modal_service.mobile_error');
    context.hasSubmitBtn = false;
    context.dismissBtnTitle = this.translateService.instant('component.modal_service.ok');
    context.reverseButtons = true;
    context.mainContent = `<p class="margin-bottom-20">${error}</p>`;
    context.type = NotificationModalType.warning;

    combineLatest([this.socialLinksService.androidAppUrl$, this.socialLinksService.iOSAppUrl$]).subscribe(([androidUrl, iOSUrl]) => {
      const isMobile = isAndroid || isIOS;
      context.mainContent += '<p>';
      if ((isAndroid || !isMobile) && !!androidUrl) {
        context.mainContent += `<a href="${androidUrl}"><img src="assets/img/googleplay.svg"></a>`;
      }
      if ((isIOS || !isMobile) && !!iOSUrl) {
        context.mainContent += `<a href="${iOSUrl}"><img src="assets/img/appstore.svg"></a>`;
      }
      context.mainContent += '</p>';
      this.openConfirmationModal(context, true);
    });
  }

  showReorderConfirmationModal(warnings: string[], order: Order, isMobileSource: boolean) {
    const context = new ConfirmationModalContext();
    context.title = this.translateService.instant('component.modal_service.reorder_modal.title');
    context.submitBtnTitle = this.translateService.instant('component.modal_service.reorder_modal.submitBtnTitle');
    context.submitObservable = this.orderService.reOrder(
      new ReOrder().deserialize({
        orderId: order.orderId,
        force: true,
        isMobileSource: isMobileSource,
      }),
      order
    );

    context.hideListStyle = true;
    context.mainContent = '';
    if (warnings.length > 0) {
      warnings.forEach(w => {
        context.mainContent += `<li>${w}</li>`;
      });
      context.mainContent = `<ul>${context.mainContent}</ul>`;
    }

    return this.openConfirmationModal(context, false);
  }

  parseAndNotifyErrors(err: any, ignoreDismissOnNavigate: boolean = false) {
    this.notifyErrors(this.errorService.parseErrorResponse(err), ignoreDismissOnNavigate);
  }

  notifyErrors(errors: ParsedErrorResponse, ignoreDismissOnNavigate: boolean = false) {
    if (errors.message) {
      this.openErrorNotificationModal(errors.message, '', ignoreDismissOnNavigate);
    } else {
      if (errors.errors) {
        Object.keys(errors.errors)
          .map(key => (errors.errors || {})[key])
          .reduce((a, b) => a.concat(b), [])
          .forEach(msg => this.openErrorNotificationModal(msg, '', ignoreDismissOnNavigate));
      } else if (errors.allErrors && errors.allErrors.length && !!errors.allErrors[0]) {
        this.openErrorNotificationModal(errors.allErrors.join(', '), '', ignoreDismissOnNavigate);
      } else {
        this.openErrorNotificationModal(
          this.translateService.instant('component.landing_action.generic_error'),
          '',
          ignoreDismissOnNavigate
        );
      }
    }
  }

  promptUserToVerifyEmail(email: string) {
    const context = new ResendVerificationEmailModalContext(email);
    context.mainContent = `<p>${this.translateService.instant('component.checkout_button.have_to_verify')}</p>${context.mainContent}`;
    context.submitObservable = this.accountService.resendVerificationEmail(email);

    this.openConfirmationModal(context).subscribe(noop);
  }

  openItemEditorModal(itemId?: number) {
    const context = new ItemEditorModalContext(itemId).getConfig();
    this.openModal(ItemEditorModalComponent, context, true);
  }

  canLeaveEditorModal() {
    const context = new ConfirmationModalContext();
    context.title = this.translateService.instant('component.item_editor.editor.can_leave_editor_guard.unsaved_changes_modal.title');
    context.submitBtnTitle = this.translateService.instant(
      'component.item_editor.editor.can_leave_editor_guard.unsaved_changes_modal.submitBtnTitle'
    );
    context.submitObservable = of(true);
    context.dismissBtnTitle = this.translateService.instant(
      'component.item_editor.editor.can_leave_editor_guard.unsaved_changes_modal.dismissBtnTitle'
    );
    context.type = NotificationModalType.warning;
    context.textOnly = true;
    context.reverseButtons = true;
    this.openConfirmationModal(context).subscribe(discardButtonClicked => {
      if (discardButtonClicked) {
        this.closeAllModals();
      }
    });
  }

  notValidSelfOrderModal() {
    const context = new ConfirmationModalContext();
    context.title = this.translateService.instant('component.modal_service.not_valid_self_order_modal.header');
    context.submitBtnTitle = this.translateService.instant('component.modal_service.not_valid_self_order_modal.submit_btn');
    context.hasDismissBtn = false;
    context.type = NotificationModalType.warning;
    context.submitObservable = of(true);
    this.openConfirmationModal(context)
      .pipe(
        tap(() => this.reloaderService.setReloadingStatus(true)),
        tap(() => this.auditService.createAudit(() => 'Missing table number warning modal has opened')),
        mergeMap(() => this.orderService.order$.pipe(take(1))),
        mergeMap(order =>
          this.orderService.clearOrder(order).pipe(
            tap(() => this.paramsService.resetParams(true)),
            mergeMap(() => this.orderService.deleteOrder()),
            finalize(() => this.reloaderService.reload('/'))
          )
        ),
        take(1)
      )
      .subscribe(noop, noop);
  }

  public openModal(component: Type<any>, context: InitModalConfig, itemEditorModal = false): Observable<any> {
    const cont = context.initialState.context;
    this.forceCloseModals = false;
    this.keepOpenModal = context.ignoreDismissOnNavigate;
    const modalRef = this.ngbModalService.open(component, {
      beforeDismiss: () => {
        if (context.ignoreBackdropClick && !this.forceCloseModals && itemEditorModal) {
          this.canLeaveEditorModal();
        }
        if (context.ignoreBackdropClick && !this.forceCloseModals) {
          return false;
        }
        if (cont.title) {
          this.auditService.createAudit(() => `${cont.title} has been dismissed`);
        } else {
          const modalTitle = component?.prototype?.constructor?.name
            .toString()
            .replace(/Component/g, '')
            .replace(/([A-Z])/g, ' $1');
          this.auditService.createAudit(() => `${modalTitle} has been dismissed`);
        }
        return true;
      },
      ariaLabelledBy: 'modal-title',
      size: context.class,
      windowClass: 'slideInUp',
    });
    cont['ref'] = modalRef;
    modalRef.componentInstance.context = cont;
    this.focusService.deactivateFocusTrap();

    return from(modalRef.result).pipe(catchError(() => of(false)));
  }

  public closeAllModals(): void {
    if (this.keepOpenModal) {
      this.forceCloseModals = false;
    } else {
      this.forceCloseModals = true;
      this.ngbModalService.dismissAll();
    }
  }
}
