import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountService,
  GroupOrder,
  GroupOrderService,
  GroupOrderStatus,
  Order,
  OrderService,
  AuditService,
  ConfigService,
  StoreConfig,
  GroupOrderStatusChange,
} from 'ngx-web-api';

import { Observable, of, Subscription, from, noop, EMPTY, throwError, combineLatest, BehaviorSubject } from 'rxjs';
import { catchError, distinctUntilKeyChanged, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { ConfirmationModalContext } from '../../domain/confirmation-modal-context';
import { GrouporderInvitationModalContext } from '../../domain/grouporder-invitation-modal-context';
import { ResendVerificationEmailModalContext } from '../../domain/resend-verification-email-modal-context';
import { MenuWrapperService } from './menu-wrapper.service';
import { ModalService } from './modal.service';
import { TranslateService } from '@ngx-translate/core';
import { GrouporderInvitationModalComponent } from 'app/shared/grouporder-invitation-modal/grouporder-invitation-modal.component';
import { EcommerceTriggeringArea } from '../../domain/ecommerce-triggering-area.enum';
import { EcommerceTriggeringPoint } from '../../domain/ecommerce-triggering-point.enum';
import { UiOrchestratorService } from './ui-orchestrator.service';
import { ThemeService } from './theme.service';
import { PathService } from 'app/shared/services/path.service';
import { BreakpointService } from './breakpoint.service';
import { ViewportService } from './viewport.service';
import { PagePosition } from 'app/domain/page-position.enum';
import { Theme } from 'app/domain/theme.model';
import { ViewPort } from 'app/domain/view-port.model';

@Injectable({
  providedIn: 'root',
})
export class GroupOrderOrchestratorService {
  private router: Router;

  private showGroupOrderButton: BehaviorSubject<[boolean, boolean, boolean, boolean, boolean, boolean]> = new BehaviorSubject([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  public showGroupOrderButton$: Observable<
    [boolean, boolean, boolean, boolean, boolean, boolean]
  > = this.showGroupOrderButton.asObservable();

  constructor(
    private groupOrderService: GroupOrderService,
    private orderService: OrderService,
    private accountService: AccountService,
    private injector: Injector,
    private modalService: ModalService,
    private translateService: TranslateService,
    private auditService: AuditService,
    private uiOrchestrator: UiOrchestratorService,
    private themeService: ThemeService,
    private pathService: PathService,
    private breakPointService: BreakpointService,
    private viewportService: ViewportService,
    private menuWrapperService: MenuWrapperService,
    private configService: ConfigService
  ) {
    this.router = this.injector.get(Router);
    this.groupOrderService.groupOrderStatusChanged$
      .pipe(distinctUntilKeyChanged('status'))
      .subscribe(change => this.handleGroupOrderStatusChange(change));
    this.initShowGroupOrderButton();
  }

  continueToCheckout(): Subscription {
    return this.groupOrderService.groupOrder$
      .pipe(
        take(1),
        mergeMap((groupOrder: GroupOrder) => {
          if (groupOrder.status === GroupOrderStatus.STARTED) {
            const hasJoinedNotReadyMembers = groupOrder.groupMembers.some(m => m.order && !m.ready);
            const hasNotJoinedMembers = groupOrder.groupMembers.some(m => !m.order);

            if (hasNotJoinedMembers || hasJoinedNotReadyMembers) {
              this.confirmProceedWithNotJoinedMembers();
              return of(null);
            }
          }

          return this.groupOrderService.merge().pipe(
            map(() =>
              this.router.navigate(['/checkout', 'details'], {
                state: {
                  ecommerceTriggeringArea: EcommerceTriggeringArea.ORDER_TREE,
                  ecommerceTriggeringPoint: EcommerceTriggeringPoint.CHECKOUT_BUTTON,
                },
              })
            )
          );
        })
      )
      .subscribe(
        () => {},
        e => this.modalService.parseAndNotifyErrors(e)
      );
  }

  fetchInvitationDialogData(): Observable<GrouporderInvitationModalContext> {
    return this.groupOrderService.findFriends().pipe(
      mergeMap(friends => this.groupOrderService.findInvitationDialogConfig().pipe(map(conf => ({ conf, friends })))),
      map(({ conf, friends }) => new GrouporderInvitationModalContext(friends, conf))
    );
  }

  notifyEmailNotVerified(email: string) {
    const context = new ResendVerificationEmailModalContext(email);
    context.mainContent = `<p>${this.translateService.instant('component.group_order_orchestrator.email_not_verified')}</p>${
      context.mainContent
    }`;
    context.ignoreDismissOnNavigate = true;
    context.submitObservable = this.accountService.resendVerificationEmail(email);

    this.modalService.openConfirmationModal(context, false).subscribe(
      () => {},
      e => this.modalService.notifyErrors(e)
    );
  }

  notifyOrderNotEditable(): Observable<any> {
    const title = this.translateService.instant('component.group_order_orchestrator.not_editable_order_modal.title');
    const content = `<p>${this.translateService.instant('component.group_order_orchestrator.not_editable_order_modal.content_1')}</p>
                           <p>${this.translateService.instant(
                             'component.group_order_orchestrator.not_editable_order_modal.content_2'
                           )}</p>`;

    return this.modalService.openInfoNotificationModal(title, content);
  }

  handleReadyAction(g: GroupOrder, menu: MenuWrapperService): Subscription {
    if (g && g.isInvitedMemberOfMemberPaid) {
      return from(
        this.router.navigate(['/checkout', 'order-details'], {
          state: {
            ecommerceTriggeringArea: EcommerceTriggeringArea.ORDER_TREE,
            ecommerceTriggeringPoint: EcommerceTriggeringPoint.CHECKOUT_BUTTON,
          },
        })
      ).subscribe(
        () => {},
        () => {}
      );
    }

    return this.groupOrderService
      .ready()
      .pipe(
        mergeMap(() => menu.getFirstPanelItemName()),
        take(1),
        mergeMap(() => this.orderService.fetchOrder()),
        catchError(error => {
          if (error?.error?.code === 10001) {
            return this.groupOrderService.fetchGroupOrder().pipe(
              tap(() => {
                this.modalService.openErrorNotificationModal(this.translateService.instant('component.group_order_state_buttons.left_go'));
                this.auditService.createAudit(() => ({
                  message: `Member could not be marked as ready`,
                  details: `Member has already left from group order`,
                }));
              }),
              mergeMap(() => EMPTY)
            );
          } else {
            return throwError(error);
          }
        })
      )
      .subscribe(
        firstPanelName => {
          if (g.isInvitedMember) {
            this.notifyReadyForMember(g);
          }
          if (firstPanelName) {
            this.router.navigate(['/menu', firstPanelName]);
          } else {
            this.router.navigate(['/menu']);
          }
        },
        e => {
          this.modalService.parseAndNotifyErrors(e);
          this.auditService.createAudit(() => ({
            message: `Member could not be marked as ready`,
            details: e,
          }));
        }
      );
  }

  canReadyLeaderCheckout(groupOrder: GroupOrder, order: Order) {
    return (!!order && order.itemsCount > 0) || this.hasInvitedMembersReady(groupOrder);
  }

  toggleGroupOrderInvitation() {
    this.auditService.createAudit(() => '"MAKE IT A GROUP ORDER" button clicked.');
    this.groupOrderService.triggerGroupOrderInvitationModal$.next(true);

    combineLatest([this.accountService.account$, this.orderService.order$, this.groupOrderService.groupOrder$])
      .pipe(
        take(1),
        filter(([_account, _order, groupOrder]) => !groupOrder?.isActive),
        mergeMap(([account, order]) => {
          if (!account.isInstantiated) {
            return of(true);
          }
          if (!account.verifiedEmail || account.flagBouncedEmail) {
            this.notifyEmailNotVerified(account.email);
            return EMPTY;
          }

          if (account.isInstantiated && !order?.isInitialized) {
            return this.uiOrchestrator.ensureOrderHasType();
          }

          return this.fetchInvitationDialogData().pipe(
            mergeMap(data => this.modalService.openModal(GrouporderInvitationModalComponent, data.getConfig()))
          );
        })
      )
      .subscribe(confirm => {
        if (confirm === true) {
          this.router.navigate(['/login'], {
            queryParams: { target: 'menu', makeItAGroupOrder: true },
          });
        }
      }, noop);
  }

  addFriendInvitation(g: GroupOrder): Subscription {
    return this.fetchInvitationDialogData()
      .pipe(
        map(
          (ctx: GrouporderInvitationModalContext) =>
            new GrouporderInvitationModalContext(ctx.groupOrderingFriends, ctx.invitationDialogConfig, g)
        ),
        mergeMap((ctx: GrouporderInvitationModalContext) =>
          this.modalService.openModal(GrouporderInvitationModalComponent, ctx.getConfig())
        )
      )
      .subscribe(noop, e => this.modalService.parseAndNotifyErrors(e));
  }

  private initShowGroupOrderButton() {
    combineLatest([
      this.groupOrderService.groupOrder$,
      this.configService.storeConfig$,
      this.themeService.theme,
      this.pathService.availablePagesToShowGroupOrderButton$,
      this.breakPointService.isTabletDown$,
      this.pathService.inMenu$,
      this.orderService.order$,
      this.uiOrchestrator.orderTreeIsOpen$,
      this.viewportService.viewport$,
      this.menuWrapperService.orderIdCookieExists$,
    ])
      .pipe(
        map(
          ([
            groupOrder,
            storeConfig,
            theme,
            availablePagesToShowGroupOrderButton,
            isTabletDown,
            inMenu,
            order,
            orderTreeIsOpen,
            viewport,
            orderIdCookieExists,
          ]: [GroupOrder, StoreConfig, Theme, boolean, boolean, boolean, Order, boolean, ViewPort, boolean]) => {
            const isAllowedToShowGoBtn =
              !(!!order && order.isInitialized && order.isSelfOrderSource) &&
              storeConfig.allowGroupWebOrdering &&
              availablePagesToShowGroupOrderButton;
            const isGroupOrderActive = groupOrder && groupOrder.isActive;
            const showIfNotInOrderTree = !(
              !isTabletDown &&
              theme.orderTreePosition === PagePosition.Right &&
              inMenu &&
              order.isInitialized &&
              !theme.minifiedHeader
            );
            return [
              isAllowedToShowGoBtn,
              isGroupOrderActive,
              showIfNotInOrderTree,
              viewport.isMobile,
              orderTreeIsOpen,
              orderIdCookieExists,
            ];
          }
        )
      )
      .subscribe((all: [boolean, boolean, boolean, boolean, boolean, boolean]) => this.showGroupOrderButton.next(all));
  }

  private confirmProceedWithNotJoinedMembers() {
    this.groupOrderService.groupOrder$.pipe(take(1)).subscribe((go: GroupOrder) => {
      const context = new ConfirmationModalContext();
      context.textOnly = false;
      context.title = this.translateService.instant('component.group_order_orchestrator.no_joined_members_modal.title');
      context.submitBtnTitle = this.translateService.instant('component.group_order_orchestrator.no_joined_members_modal.submitBtnTitle');
      context.mainContent = `
          <p>${this.translateService.instant('component.group_order_orchestrator.no_joined_members_modal.mainContent_1')}</p>
          ${go.groupMembers
            .filter(m => !m.ready)
            .map(m => `<div class='fw-600 text-center'>${m.name}</div>`)
            .join(' ')}
          <p class='margin-top-15'>
            ${this.translateService.instant('component.group_order_orchestrator.no_joined_members_modal.mainContent_2')}
          </p>
        `;
      context.submitObservable = this.groupOrderService.merge().pipe(
        mergeMap(result => {
          if (result) {
            return from(
              this.router.navigate(['/checkout', 'details'], {
                state: {
                  ecommerceTriggeringArea: EcommerceTriggeringArea.ORDER_TREE,
                  ecommerceTriggeringPoint: EcommerceTriggeringPoint.CHECKOUT_BUTTON,
                },
              })
            );
          }

          return of(EMPTY);
        }),
        catchError(error => {
          this.modalService.closeAllModals();
          this.modalService.parseAndNotifyErrors(error);
          return of(null);
        })
      );

      return this.modalService.openConfirmationModal(context, true);
    }, noop);
  }

  private handleGroupOrderStatusChange(change: GroupOrderStatusChange): void {
    if (!change.groupOrder.asLeader) {
      this.handleStatusChangeForMember(change.groupOrder);
    }

    if (change.status === GroupOrderStatus.DONE) {
      this.groupOrderService.removePushNotification();
    }
  }

  private handleStatusChangeForMember(g: GroupOrder) {
    const leader = g.leader || { name };

    switch (g.status) {
      case GroupOrderStatus.CANCELED:
        this.modalService.openInfoNotificationModal(
          this.translateService.instant('component.group_order_orchestrator.go_canceled', { leader: leader.name })
        );
        this.clearOutGroupOrder().subscribe(
          () => this.router.navigate(['/']),
          e => console.error(e)
        );
        break;

      case GroupOrderStatus.PLACING:
        this.handlePlacedForInvitedMember(g);
        break;

      case GroupOrderStatus.EXPIRED:
        this.handleExpiredForInvitedMember(g);
        break;

      case GroupOrderStatus.DONE:
        this.modalService.openInfoNotificationModal(
          this.translateService.instant('component.group_order_orchestrator.go_placed', { leader: leader.name })
        );
        break;

      default:
        break;
    }
  }

  private handlePlacedForInvitedMember(g: GroupOrder) {
    const leader = g.leader || { name };

    if (g.isInvitedMemberNotReady) {
      this.clearOutGroupOrder().subscribe(
        () => this.router.navigate(['/']),
        e => console.error(e)
      );
    } else {
      const queryParams = {
        placedGroupOrder: true,
        isMember: g.isInvitedMember,
        leaderName: g.leader.name,
        groupOrderStarted: g.started,
      };
      from(this.router.navigate(['/checkout', 'enjoy'], { queryParams }))
        .pipe(mergeMap(() => this.clearOutGroupOrder()))
        .subscribe(
          () => {},
          e => console.error(e)
        );
    }
    this.modalService.openInfoNotificationModal(
      this.translateService.instant('component.group_order_orchestrator.go_placing', { leader: leader.name })
    );
  }

  private handleExpiredForInvitedMember(g: GroupOrder) {
    let msg: string;

    if (g.isInvitedMemberNotReady) {
      this.clearOutGroupOrder().subscribe(
        () => this.router.navigate(['/']),
        e => console.error(e)
      );
      msg = this.translateService.instant('component.group_order_orchestrator.go_expired');
    } else {
      const leader = g.leader || { name };
      msg = this.translateService.instant('component.group_order_orchestrator.go_expired_waiting', { leader: leader.name });
    }
    this.modalService.openInfoNotificationModal(msg);
  }

  private clearOutGroupOrder(): Observable<any> {
    return this.orderService.deleteOrder().pipe(
      mergeMap(() => this.orderService.fetchOrder()),
      mergeMap(() => this.groupOrderService.clear())
    );
  }

  private notifyReadyForMember(g: GroupOrder) {
    const leader = g.leader || { name };
    const context = new ConfirmationModalContext();
    context.title = this.translateService.instant('component.group_order_orchestrator.ready_member_modal.title');
    context.dismissBtnTitle = this.translateService.instant('component.group_order_orchestrator.ready_member_modal.dismissBtnTitle');
    context.hasSubmitBtn = false;
    context.mainContent = this.translateService.instant('component.group_order_orchestrator.ready_member_modal.mainContent', {
      leader: leader.name,
    });

    this.modalService.openConfirmationModal(context, false);
  }

  private hasInvitedMembersReady(groupOrder: GroupOrder): boolean {
    return !!groupOrder.groupMembers.find(m => !m.isLeader && m.ready);
  }
}
