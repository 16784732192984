import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { AccountAddressChooserComponent } from './account-address-chooser/account-address-chooser.component';
import { AccountAddressEntryComponent } from './account-address-chooser/account-address-entry/account-address-entry.component';
import { AddNewAddressComponent } from './add-new-address/add-new-address.component';
import { AddressConfirmationModalComponent } from './address-confirmation-modal/address-confirmation-modal.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { AsyncButtonDirective } from './async-button.directive';
import { AuditableButtonDirective } from './auditable-button.directive';
import { BoxComponent } from './box/box.component';
import { CalendarDayChooserComponent } from './calendar-day-chooser/calendar-day-chooser.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { CreditcardModalComponent } from './creditcard-modal/creditcard-modal.component';
import { CustomerQuestionsComponent } from './customer-questions/customer-questions.component';
import { CustomizeItemBtnComponent } from './customize-item-btn/customize-item-btn.component';
import { DimensionsDirective } from './dimensions.directive';
import { EditAddressDiffComponent } from './edit-address-diff/edit-address-diff.component';
import { PropInArrayPipe } from './pipes/prop-in-array.pipe';
import { EmbeddedQuestionnaireFormComponent } from './embedded-questionnaire-form/embedded-questionnaire-form.component';
import { ErrorListPipe } from './pipes/error-list.pipe';
import { ExtensiveOrderTimeChooserComponent } from './extensive-order-time-chooser/extensive-order-time-chooser.component';
import { FeaturedContentFadingComponent } from './featured-content/featured-content-fading/featured-content-fading.component';
import { FeaturedItemFadingComponent } from './featured-content/featured-content-fading/featured-item-fading/featured-item-fading.component';
import { FeaturedContentListComponent } from './featured-content/featured-content-list/featured-content-list.component';
import { FeaturedListItemComponent } from './featured-content/featured-content-list/featured-list-item/featured-list-item.component';
import { FeaturedContentScrollingComponent } from './featured-content/featured-content-scrolling/featured-content-scrolling.component';
import { SlideComponent } from './featured-content/featured-content-scrolling/slide/slide.component';
import { FeaturedContentComponent } from './featured-content/featured-content.component';
import { StepIndicatorComponent } from './featured-content/step-indicator/step-indicator.component';
import { FundraiserSponsorEntryComponent } from './fundraiser-sponsors-modal/fundraiser-sponsor-entry/fundraiser-sponsor-entry.component';
import { FundraiserSponsorsModalComponent } from './fundraiser-sponsors-modal/fundraiser-sponsors-modal.component';
import { NeedsAccountGuard } from './guards/account-guard';
import { NeedsOrderGuard } from './guards/order-guard';
import { NeedsSignedOutGuard } from './guards/signed-out-guard';
import { GuestAddressChooserComponent } from './guest-address-chooser/guest-address-chooser.component';
import { InvitationFormComponent } from './invitation-form/invitation-form.component';
import { QualifierChoiceComponent } from './item-editor/choice/qualifier-choice/qualifier-choice.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { TodayFormatPipe } from './pipes/today-format.pipe';
import { OrderTimeChooserComponent } from './order-time-chooser/order-time-chooser.component';
import { OrderTypeChooserComponent } from './order-type-chooser/order-type-chooser.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { PriceComponent } from './price/price.component';
import { PromiseTimeInfoComponent } from './promise-time-info/promise-time-info.component';
import { QuantitySelectorComponent } from './quantity-selector/quantity-selector.component';
import { DateOfYearComponent } from './question/date-of-year/date-of-year.component';
import { DateComponent } from './question/date/date.component';
import { LongTextComponent } from './question/long-text/long-text.component';
import { OptionDropDownComponent } from './question/option-drop-down/option-drop-down.component';
import { OptionComponent } from './question/option/option.component';
import { QuestionComponent } from './question/question.component';
import { RatingComponent } from './question/rating/rating.component';
import { ReviewComponent } from './question/review/review.component';
import { ScoreComponent } from './question/score/score.component';
import { TextComponent } from './question/text/text.component';
import { YesNoComponent } from './question/yes-no/yes-no.component';
import { QuestionnaireFormComponent } from './questionnaire-form/questionnaire-form.component';
import { RegisterOrLoginComponent } from './register-or-login/register-or-login.component';
import { OrderTypesResolver } from './resolvers/order-types-resolver';
import { StoreConfigResolver } from './resolvers/store-config-resolver';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SizePriceButtonComponent } from './size-price-button/size-price-button.component';
import { SpecialInstructionsModalComponent } from './special-instructions-modal/special-instructions-modal.component';
import { ThemeableCheckboxComponent } from './themeable-checkbox/themeable-checkbox.component';
import { ThemeableRadioComponent } from './themeable-radio/themeable-radio.component';
import { UpsellSpecialModalContentComponent } from './upsell-special-modal-content/upsell-special-modal-content.component';
import { UpsellSpecialModalComponent } from './upsell-special-modal/upsell-special-modal.component';
import { NavLinkComponent } from './vertical-categories-menu/nav-link/nav-link.component';
import { VerticalCategoriesMenuComponent } from './vertical-categories-menu/vertical-categories-menu.component';
import { StoreOpenCloseTimeComponent } from './store-open-close-time/store-open-close-time.component';
import { RecipeDeterminantSizePricesFilterPipe } from './pipes/recipe-determinant-size-prices-filter.pipe';

import { SafeUrlPipe } from './pipes/safe-url.pipe';

import { CompactHeaderOverlayComponent } from './compact-header-overlay/compact-header-overlay.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ReadMoreComponent } from './read-more/read-more.component';
import { CustomPopoverDirective } from './custom-popover.directive';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { RoundUpTimePipe } from './pipes/round-up-time.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { AriaFormDirective } from './aria-form.directive';
import { YoutubePipe } from './pipes/youtube.pipe';
import { HasTokenParamGuard } from './guards/has-token-param.guard';
import { CalcSumPipe } from './pipes/calc-sum.pipe';
import { OrderTypeNamePipe } from './pipes/order-type-name.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { GenerateIdsPipe } from './pipes/generate-ids.pipe';
import { GiftCardFormComponent } from './gift-card-form/gift-card-form.component';
import { GiftCardModalComponent } from './gift-card-modal/gift-card-modal.component';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { SizePricesComponent } from './size-prices/size-prices.component';
import { TagsComponent } from './tags/tags.component';
import { JoinPipe } from './pipes/join.pipe';
import { MessageWithLinksComponent } from './message-with-links/message-with-links.component';
import { SingleIngredientChoiceComponent } from './single-required-choice/single-ingredient-choice/single-ingredient-choice.component';
import { GhostElementComponent } from './ghost-element/ghost-element.component';
import { PictureDirective } from './picture.directive';
import { MultipleChoiceComponent } from './question/multiple-choice/multiple-choice.component';
import { ConfirmationModalInlineComponent } from './confirmation-modal-inline/confirmation-modal-inline.component';
import { AvailableSizePipe } from './pipes/available-size.pipe';
import { SomePipe } from './pipes/some.pipe';
import { QualifierCaloriesPipe } from './pipes/qualifier-calories.pipe';
import { SizePricePipe } from './size-price.pipe';

import { QuestionnaireLinkPipe } from './pipes/questionnaire-link.pipe';
import { QualifierIsOrderedPipe } from './pipes/qualifier-is-ordered.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ItemGroupFilterPipe } from './pipes/item-group-filter.pipe';
import { PropertySumPipe } from './pipes/property-sum.pipe';
import { OrderTypeFormComponent } from './order-type-form/order-type-form.component';
import { OrderTypeModalComponent } from './order-type-modal/order-type-modal.component';
import { CustomerInfoFormComponent } from './customer-info-form/customer-info-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilteringTagsComponent } from './filtering-tags/filtering-tags.component';
import { PhoneVerificationComponent } from './phone-verification/phone-verification.component';
import { FavoriteOrderButtonComponent } from './favorite-order-button/favorite-order-button.component';
import { FtsCurrencyPipe } from './pipes/fts-currency.pipe';
import { RecaptchaValidatorComponent } from './recaptcha-validator/recaptcha-validator.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { AbbreviationPipe } from './pipes/abbreviation.pipe';
import { RemoveQuantityFromPrintNamePipe } from './pipes/remove-quantity-from-print-name.pipe';
import { AnyMatchPipe } from 'ngx-web-api/lib/pipes/any-match.pipe';
import { CreditCardMaskPipe } from 'ngx-web-api/lib/pipes/credit-card-mask.pipe';
import { GiftCardMaskPipe } from 'ngx-web-api/lib/pipes/gift-card-mask.pipe';
import { LeftPadPipe } from 'ngx-web-api/lib/pipes/left-pad.pipe';
import { NoneMatchPipe } from 'ngx-web-api/lib/pipes/none-match.pipe';
import { RgbaPipe } from 'ngx-web-api/lib/pipes/rgba.pipe';
import { DateFormatPipe } from 'ngx-web-api/lib/pipes/date-format.pipe';
import { MenuIndexComponent } from './menu-index/menu-index.component';
import { FilterEmptyGroupsPipe } from './pipes/filter-empty-groups.pipe';
import { AccountResourceConfigResolver } from './resolvers/account-resource-config-resolver';
import { StickyDirective } from './sticky.directive';
import { SelfOrderingInfoFormComponent } from './self-ordering-info-form/self-ordering-info-form.component';
import { IngredientTagComponent } from './item-editor/choice/ingredient-tag/ingredient-tags.component';
import { CodeVerificationFormComponent } from 'app/shared/code-verification-form/code-verification-form.component';
import { StoreStatusResolver } from './resolvers/store-status-resolver';
import { EmptyOrderComponent } from './empty-order/empty-order.component';
import { SmsSignUpInformationComponent } from './sms-sign-up-information/sms-sign-up-information.component';
import { GrouporderButtonComponent } from './grouporder-button/grouporder-button.component';
import { SmsModalComponent } from './sms-modal/sms-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TextMaskModule,
    FontAwesomeModule,
    TranslateModule,
    NgbModule,
    RecaptchaModule,
  ],
  declarations: [
    ConfirmationModalComponent,
    CreditcardModalComponent,
    BoxComponent,
    CreditCardFormComponent,
    ThemeableRadioComponent,
    OrderTypeChooserComponent,
    OrderTimeChooserComponent,
    ExtensiveOrderTimeChooserComponent,
    SafeHtmlPipe,
    PageTitleComponent,
    ThemeableCheckboxComponent,
    AddressModalComponent,
    AddressFormComponent,
    AccountAddressChooserComponent,
    AccountAddressEntryComponent,
    AddNewAddressComponent,
    EditAddressDiffComponent,
    FeaturedContentComponent,
    FeaturedContentListComponent,
    FeaturedContentFadingComponent,
    FeaturedItemFadingComponent,
    GuestAddressChooserComponent,
    AddressConfirmationModalComponent,
    AsyncButtonDirective,
    QuantitySelectorComponent,
    AsyncButtonDirective,
    EmbeddedQuestionnaireFormComponent,
    QuestionComponent,
    CustomerQuestionsComponent,
    DateOfYearComponent,
    LongTextComponent,
    OptionComponent,
    OptionDropDownComponent,
    ScoreComponent,
    TextComponent,
    YesNoComponent,
    RatingComponent,
    DateComponent,
    ReviewComponent,
    FeaturedContentScrollingComponent,
    SlideComponent,
    StepIndicatorComponent,
    QualifierChoiceComponent,
    PropInArrayPipe,
    ErrorListPipe,
    LoadingIndicatorComponent,
    AuditableButtonDirective,
    SizePriceButtonComponent,
    DimensionsDirective,
    VerticalCategoriesMenuComponent,
    NavLinkComponent,
    InvitationFormComponent,
    PriceComponent,
    FeaturedListItemComponent,
    UpsellSpecialModalComponent,
    UpsellSpecialModalContentComponent,
    TodayFormatPipe,
    RegisterOrLoginComponent,
    QuestionnaireFormComponent,
    CalendarDayChooserComponent,
    PromiseTimeInfoComponent,
    FundraiserSponsorsModalComponent,
    FundraiserSponsorEntryComponent,
    SpecialInstructionsModalComponent,
    CustomizeItemBtnComponent,
    StoreOpenCloseTimeComponent,
    RecipeDeterminantSizePricesFilterPipe,
    SafeUrlPipe,
    CompactHeaderOverlayComponent,
    TruncatePipe,
    ReadMoreComponent,
    CustomPopoverDirective,
    PhoneVerificationComponent,
    BackToTopComponent,
    RoundUpTimePipe,
    SlugifyPipe,
    AriaFormDirective,
    YoutubePipe,
    CalcSumPipe,
    OrderTypeNamePipe,
    GenerateIdsPipe,
    GiftCardFormComponent,
    GiftCardModalComponent,
    ForgotPasswordModalComponent,
    SizePricesComponent,
    TagsComponent,
    JoinPipe,
    MessageWithLinksComponent,
    SingleIngredientChoiceComponent,
    GhostElementComponent,
    PictureDirective,
    MultipleChoiceComponent,
    ConfirmationModalInlineComponent,
    AvailableSizePipe,
    SomePipe,
    QualifierCaloriesPipe,
    SizePricePipe,
    QuestionnaireLinkPipe,
    QualifierIsOrderedPipe,
    FilterPipe,
    ItemGroupFilterPipe,
    PropertySumPipe,
    OrderTypeFormComponent,
    OrderTypeModalComponent,
    CustomerInfoFormComponent,
    FilteringTagsComponent,
    FavoriteOrderButtonComponent,
    FtsCurrencyPipe,
    RecaptchaValidatorComponent,
    AbbreviationPipe,
    RemoveQuantityFromPrintNamePipe,
    MenuIndexComponent,
    FilterEmptyGroupsPipe,
    AnyMatchPipe,
    CreditCardMaskPipe,
    GiftCardMaskPipe,
    LeftPadPipe,
    NoneMatchPipe,
    RgbaPipe,
    DateFormatPipe,
    ErrorListPipe,
    StickyDirective,
    SelfOrderingInfoFormComponent,
    IngredientTagComponent,
    CodeVerificationFormComponent,
    SmsSignUpInformationComponent,
    EmptyOrderComponent,
    GrouporderButtonComponent,
    SmsModalComponent,
  ],
  providers: [
    StoreConfigResolver,
    OrderTypesResolver,
    AccountResourceConfigResolver,
    NeedsSignedOutGuard,
    NeedsAccountGuard,
    NeedsOrderGuard,
    HasTokenParamGuard,
    FtsCurrencyPipe,
    AnyMatchPipe,
    CreditCardMaskPipe,
    GiftCardMaskPipe,
    LeftPadPipe,
    NoneMatchPipe,
    RgbaPipe,
    DateFormatPipe,
    ErrorListPipe,
    StoreStatusResolver,
  ],
  exports: [
    SizePricePipe,
    ConfirmationModalComponent,
    CreditcardModalComponent,
    BoxComponent,
    ThemeableRadioComponent,
    OrderTypeChooserComponent,
    OrderTimeChooserComponent,
    StoreOpenCloseTimeComponent,
    ExtensiveOrderTimeChooserComponent,
    CreditCardFormComponent,
    SafeHtmlPipe,
    PageTitleComponent,
    ThemeableCheckboxComponent,
    AddressModalComponent,
    AddressFormComponent,
    TodayFormatPipe,
    FeaturedContentComponent,
    AccountAddressChooserComponent,
    AccountAddressEntryComponent,
    AddNewAddressComponent,
    GuestAddressChooserComponent,
    AddressConfirmationModalComponent,
    AsyncButtonDirective,
    AuditableButtonDirective,
    QuantitySelectorComponent,
    EmbeddedQuestionnaireFormComponent,
    QuestionComponent,
    CustomerQuestionsComponent,
    DateOfYearComponent,
    LongTextComponent,
    OptionComponent,
    OptionDropDownComponent,
    ScoreComponent,
    TextComponent,
    YesNoComponent,
    RatingComponent,
    DateComponent,
    ReviewComponent,
    QualifierChoiceComponent,
    PropInArrayPipe,
    ErrorListPipe,
    LoadingIndicatorComponent,
    SizePriceButtonComponent,
    DimensionsDirective,
    VerticalCategoriesMenuComponent,
    NavLinkComponent,
    InvitationFormComponent,
    PriceComponent,
    UpsellSpecialModalComponent,
    UpsellSpecialModalContentComponent,
    RegisterOrLoginComponent,
    QuestionnaireFormComponent,
    CalendarDayChooserComponent,
    PromiseTimeInfoComponent,
    CustomizeItemBtnComponent,
    RecipeDeterminantSizePricesFilterPipe,
    SafeUrlPipe,
    RoundUpTimePipe,
    CompactHeaderOverlayComponent,
    ReadMoreComponent,
    BackToTopComponent,
    AriaFormDirective,
    CalcSumPipe,
    SlugifyPipe,
    GenerateIdsPipe,
    GiftCardFormComponent,
    GiftCardModalComponent,
    SizePricesComponent,
    TagsComponent,
    JoinPipe,
    MessageWithLinksComponent,
    SingleIngredientChoiceComponent,
    GhostElementComponent,
    PictureDirective,
    MultipleChoiceComponent,
    ConfirmationModalInlineComponent,
    QuestionnaireLinkPipe,
    FilterPipe,
    ItemGroupFilterPipe,
    PropertySumPipe,
    OrderTypeFormComponent,
    OrderTypeModalComponent,
    CustomerInfoFormComponent,
    FilteringTagsComponent,
    PhoneVerificationComponent,
    QualifierIsOrderedPipe,
    FavoriteOrderButtonComponent,
    FtsCurrencyPipe,
    RecaptchaValidatorComponent,
    AbbreviationPipe,
    RemoveQuantityFromPrintNamePipe,
    MenuIndexComponent,
    AnyMatchPipe,
    CreditCardMaskPipe,
    GiftCardMaskPipe,
    LeftPadPipe,
    NoneMatchPipe,
    RgbaPipe,
    DateFormatPipe,
    ErrorListPipe,
    FilterEmptyGroupsPipe,
    StickyDirective,
    SelfOrderingInfoFormComponent,
    IngredientTagComponent,
    CodeVerificationFormComponent,
    EmptyOrderComponent,
    SmsSignUpInformationComponent,
    GrouporderButtonComponent,
  ],
})
export class SharedModule {}
