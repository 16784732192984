import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CwoService } from 'app/core/services/cwo.service';
import { Observable, of } from 'rxjs';
import { FTS_CONFIG, FtsConfig } from '../models/core/fts-config';

@Injectable()
export class XRequestedWithInterceptor implements HttpInterceptor {
  private storeName: string;
  constructor(@Inject(FTS_CONFIG) private config: FtsConfig, private cwoService: CwoService) {
    this.storeName = this.cwoService.storeName;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.storeName || this.storeName === 'not-found' || window['___ftsCwoInitFailedNoValidStoreId___']) {
      return of(null);
    }
    return next.handle(
      req.clone({
        setHeaders: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-FTS-CLIENT': this.config.ftsClient,
          'X-FTS-STORE': this.storeName,
        },
      })
    );
  }
}
