<div [ngClass]="{'cover': fillParentHeight , 'images-wrapper-vertical':showVertically || !fillParentHeight, 'images-wrapper-horizontal':!showVertically}">
  <div *ngFor='let featuredItem of featuredItems; let i = index'
       class='image-wrapper'
       [ngClass]="{'cursor-pointer': featuredItem.isClickable}">
    <fts-featured-list-item
      [fillParentHeight]=fillParentHeight
      [featuredItem]=featuredItem
      [skipLazyLoading]="skipLazyLoading"
      [itemImgHeight]="itemImgHeight"
      (chosenFeaturedItem)="onFeaturedItemChosen($event, i + 1)"
      (heightChange)="onLoadImage($event)">
    </fts-featured-list-item>
  </div>
</div>
