import { Component, Input, OnChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { ConfigService, OrderType, OrderTypeConfig, StoreConfig, MerchantStore, Account, AccountService, OrderService } from 'ngx-web-api';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { UiOrchestratorService } from '../../services/ui-orchestrator.service';
import { Theme } from '../../../domain/theme.model';
import { ThemeService } from '../../services/theme.service';
import { MobileMenu } from '../../../domain/mobile-menu.enum';
import { faStore } from '@fortawesome/pro-solid-svg-icons';
import { OrderingUtilsService } from 'app/core/services/ordering-utils.service';
import { PathService } from 'app/shared/services/path.service';
import { ViewportService } from '../../services/viewport.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fts-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss'],
})
export class StoreInfoComponent implements OnChanges, OnInit {
  @Input()
  storeConfig: StoreConfig;
  @Input()
  orderType: OrderType;
  @Input()
  compact = false;
  @Input()
  hasMultipleOrderTypeConfigs = false;
  @Input()
  showChangeOrderTypeBtn = false;
  @Input()
  minifiedHeader = false;
  @Output()
  onToggleMobileMenu: EventEmitter<MobileMenu> = new EventEmitter();

  account: Account;
  theme: Theme;
  orderTypeConfig: OrderTypeConfig;
  loadingStates: Subscription;
  isStoreChooserOpened = true;
  menus = MobileMenu;
  currentStore: MerchantStore;
  shouldShowFavoriteStoreBtn: boolean;
  favoriteStoreSubscription: Subscription;
  faStore = faStore;
  isSelfOrder$: Observable<boolean>;
  isInThanksPage$: Observable<boolean>;
  isInTrackerPage$: Observable<boolean>;
  isEuCountry: boolean;

  constructor(
    private configService: ConfigService,
    private modalService: ModalService,
    private uiOrchestratorService: UiOrchestratorService,
    private themeService: ThemeService,
    private accountService: AccountService,
    public orderService: OrderService,
    private orderingUtilsService: OrderingUtilsService,
    private pathService: PathService,
    public viewportService: ViewportService
  ) {
    this.isSelfOrder$ = this.orderingUtilsService.isSelfOrder$.pipe(take(1));
    this.isInThanksPage$ = this.pathService.inThanks$;
    this.isInTrackerPage$ = this.pathService.inTracker$;
  }

  ngOnInit() {
    this.uiOrchestratorService.storeStates$.subscribe(ss => (this.isStoreChooserOpened = !!ss));
    this.themeService.theme.subscribe(theme => (this.theme = theme));
    if (this.storeConfig.hasStoreChooser) {
      combineLatest([this.configService.fetchMerchantStores(this.storeConfig.state), this.accountService.account$]).subscribe(
        result => {
          const merchantStores = result[0];
          const account = result[1];

          this.currentStore = merchantStores.find(s => s.isCurrentStore);
          this.account = account;
          this.shouldShowFavoriteStoreBtn =
            this.account && this.account.isInstantiated && this.currentStore && this.account.favoriteStore !== this.currentStore.primaryKey;
        },
        e => this.modalService.parseAndNotifyErrors(e)
      );
    }
    this.configService.storeConfig$.subscribe((cfg: StoreConfig) => {
      this.isEuCountry = cfg.country === 'IE';
    });
  }

  ngOnChanges(changes: any): void {
    if (!!changes.orderType && !!changes.orderType.currentValue) {
      this.configService.findOrderTypeConfig(this.orderType).subscribe(orderTypeConfig => {
        this.orderTypeConfig = orderTypeConfig;
      });
    }

    const visibleMenuChange = changes['visibleMenu'];
    if (visibleMenuChange) {
      if (visibleMenuChange.currentValue === MobileMenu.storeChooser) {
        this.openStoreChooser();
      }

      if (visibleMenuChange.previousValue === MobileMenu.storeChooser) {
        this.closeStoreChooser();
      }
    }
  }

  get hasOrderType() {
    return this.orderType && this.orderType !== OrderType.Any && !!this.orderTypeConfig;
  }

  handleChangeClick() {
    this.openStoreChooser();
  }

  toggleMobileMenu(visibleMenu: MobileMenu) {
    this.onToggleMobileMenu.next(visibleMenu);
  }

  handleMyFavoriteStore() {
    if (this.currentStore && this.account && this.account.isInstantiated) {
      this.favoriteStoreSubscription = this.setMyFavoriteStore(this.currentStore.primaryKey).subscribe(
        account => {
          this.account = account;
          this.shouldShowFavoriteStoreBtn = false;
        },
        err => this.modalService.parseAndNotifyErrors(err)
      );
    }
  }

  private setMyFavoriteStore(favoriteStore: string): Observable<Account> {
    const accountCopy = new Account().deserialize({ ...this.account, favoriteStore });
    return this.accountService.updateFavoriteStore(accountCopy);
  }

  private closeStoreChooser() {
    if (this.loadingStates && !this.loadingStates.closed) {
      this.loadingStates.unsubscribe();
    }
    this.uiOrchestratorService.setStoreStates(null);
  }

  private openStoreChooser() {
    this.uiOrchestratorService.storeChooseModalTriggered();
    if (!this.storeConfig.hasStoreChooser) {
      this.uiOrchestratorService.setStoreStates([]);
      return;
    }
    this.loadingStates = this.configService.fetchStoreStates().subscribe(
      (ss: string[]) => {
        this.uiOrchestratorService.setStoreStates(ss);
      },
      e => this.modalService.parseAndNotifyErrors(e)
    );
  }
}
