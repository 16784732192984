import { BaseResource } from './base-resource';

export class TipConfig extends BaseResource {
  readonly defaultSuggestion: number;
  readonly excessiveMinimum: number;
  readonly excessivePercent: number;
  readonly maxAllowedTip: number;
  readonly suggestions: number[];
  readonly tipsEnabled: boolean;
}
