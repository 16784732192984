import { Injectable } from '@angular/core';
import { CouponStatus } from '../../models/core/coupon-status.enum';
import { CouponType } from '../../models/core/coupon-type.enum';
import { Coupon } from '../../models/core/coupon.model';
import { CouponErrorType } from '../../models/ordering/coupon-error-type.enum';
import { isTomorrow } from 'date-fns';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { Utils } from '../../utils/utils';

@Injectable({ providedIn: 'root' })
export class CouponService {
  private dateFormat: DateFormatPipe = new DateFormatPipe();

  /**
   * Determine whether a {@link Coupon} is a loyalty coupon. This means that the coupon' type
   * is either "loyalty" or "optional".
   * @param coupon the coupon to check
   * @returns true if coupon is loyalty
   */
  public isLoyalty(coupon: Coupon): boolean {
    return coupon.type === CouponType.LOYALTY || coupon.type === CouponType.OPTIONAL;
  }

  /**
   * Determine whether a {@link Coupon} is an optional coupon.
   * @param coupon the coupon to check
   * @returns true if coupon is optional
   */
  public isOptional(coupon: Coupon): boolean {
    return coupon.type === CouponType.OPTIONAL;
  }

  /**
   * Determine whether a {@link Coupon} can be applied (aka is applicable). This means tha the coupon's
   * status is "applicable".
   * @param coupon the coupon to check
   * @returns true if coupon can be applied
   */
  public isApplicable(coupon: Coupon): boolean {
    return coupon.status === CouponStatus.APPLICABLE;
  }

  /**
   * Determine whether a {@link Coupon} can be active. This means that the coupon's
   * status is "applicable" or that the coupon is satisfied by order.
   * @param coupon the coupon to check
   * @returns true if coupon can be active
   */
  public isActive(coupon: Coupon): boolean {
    return this.isApplicable(coupon) || this.isSatisfiedByOrder(coupon);
  }

  /**
   * Determine whether a {@link Coupon} can be satisfied by order. This means that the coupon's
   * is an optional coupon and the coupon's status is "non_applicable".
   * @param coupon the coupon to check
   * @returns true if coupon is satisfied by the order
   */
  public isSatisfiedByOrder(coupon: Coupon): boolean {
    return this.isOptional(coupon) && this.isNonApplicable(coupon);
  }

  /**
   * Determine whether a {@link Coupon} has been applied. This means tha the coupon's status is "applied".
   * @param coupon the coupon to check
   * @returns true if coupon has been applied
   */
  public isApplied(coupon: Coupon): boolean {
    return coupon.status === CouponStatus.APPLIED;
  }

  /**
   * Determine whether a {@link Coupon} cannot be applied. This means that the coupon's status is "non_applicable".
   * @param coupon the coupon to check
   * @returns true if coupon cannot be applied
   */
  public isNonApplicable(coupon: Coupon): boolean {
    return coupon.status === CouponStatus.NON_APPLICABLE;
  }

  /**
   * Return a label that differentiates coupons.
   * @param coupon the coupon for which the type label will be returned
   * @return "Loyalty Coupon" for coupons that are loyalty and "One time Coupon" for all other coupons
   */
  public getTypeLabel(coupon: Coupon): string {
    return this.isLoyalty(coupon) ? 'Loyalty Coupon' : 'One time Coupon';
  }

  /**
   * Check whether the coupon will expire tomorrow. Note that the logic will not verify that the coupon will expire
   * <b>by</b> tomorrow, but only whether it will expire tomorrow.
   * @param coupon the coupon to check whether it will expire tomorrow
   * @return true if the coupon will expire tomorrow
   */
  public expiresInApproximatelyOneDay(coupon: Coupon): boolean {
    return isTomorrow(Utils.parseIsoDate(coupon.expirationDate));
  }

  /**
   * Get the coupon's expiration data formatted in 'MM/DD/YYYY' format
   * @param coupon the coupon fto get the formatted expiration date
   * @return the formatted expiration date
   */
  public getFormattedExpirationDate(coupon: Coupon): string {
    return this.dateFormat.transform(coupon.expirationDate, 'MM/dd/yyyy');
  }

  /**
   * Check whether the coupon's error type is "incompatible" or "is_excluded". This utility
   * function can be used in order to check whether a coupon was added to the order and come into
   * conflict with another (or more) coupons and the server tried to resolve the best offer.
   */
  public isIncompatibleOrExcludedError(coupon: Coupon): boolean {
    return coupon.errorType === CouponErrorType.INCOMPATIBLE || coupon.errorType === CouponErrorType.IS_EXCLUDED;
  }
}
