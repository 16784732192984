import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AccountAddressesService, Address, AuditService } from 'ngx-web-api';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'fts-account-address-entry',
  templateUrl: './account-address-entry.component.html',
  styleUrls: ['./account-address-entry.component.scss'],
})
export class AccountAddressEntryComponent {
  @Input()
  address: Address;
  @Input()
  selected: boolean;
  @Input()
  isEditing: boolean;
  @Input()
  hideZip: boolean;
  @Input()
  useIntlAddressFormat: boolean;
  @Input()
  disableRadioButtons: boolean;
  @Input()
  hideInstructions: boolean;

  @Output()
  editingAddress: EventEmitter<Address> = new EventEmitter();
  @Output()
  addressDeleted: EventEmitter<void> = new EventEmitter();
  @Output()
  deletingAddress: EventEmitter<boolean> = new EventEmitter();

  isDeleting: boolean;
  faTimes = faTimes;
  faCircleNotch = faCircleNotch;
  faPencil = faPencil;

  constructor(private accountAddressesService: AccountAddressesService, private auditService: AuditService) {}

  onEditing() {
    this.isEditing = true;
    this.editingAddress.emit(this.address);
  }

  deleteAddress(address: Address) {
    this.isDeleting = true;
    this.deletingAddress.emit(true);
    this.accountAddressesService.deleteAddress(address).subscribe(
      () => {
        this.addressDeleted.emit();
      },
      error => {
        this.auditService.createAudit(() => ({
          message: `Address "${address.name}" could not be deleted`,
          details: error,
        }));
        this.isDeleting = false;
      }
    );
  }
}
