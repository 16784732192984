import { BaseResource } from '../core/base-resource';
import { PriceStatus } from './price-status.enum';

export class SizePrice extends BaseResource {
  size: string;
  label: string;
  price: number;
  status: PriceStatus;
  isWebDefault: boolean;
  caloriesFrom?: number;
  caloriesTo?: number;
  caloriesHalf?: number;
  servings?: number;
  servingsLabel?: string;
  halfPrice: number;
  hasNWayPricing: boolean;

  get isUnavailable() {
    return this.status === PriceStatus.OUT_OF_STOCK || this.status === PriceStatus.SOLD_OUT;
  }

  get isAvailable() {
    return this.status === PriceStatus.AVAILABLE;
  }

  get isFree() {
    return this.status === PriceStatus.FREE;
  }

  get statusLabel() {
    return this.status.replace(/_/g, ' ');
  }
}
