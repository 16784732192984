import { Coupon } from 'ngx-web-api';
import { BasicModalContext } from './basic-modal-context';

export class CouponInstructionsModalContext extends BasicModalContext {
  constructor(public selectedCoupon: Coupon) {
    super();
    this.class = 'sm';
    this.ignoreDismissOnNavigate = true;
    this.config = { selectedCoupon };
  }
}
