<div class="row">
  <div class="col-12 text-center">
    <fieldset [ngClass]="{'d-flex': isInStoreSelection}">
      <ng-container *ngIf="!isInStoreSelection">
        <legend class="step-legend" [ngClass]="{'secondary-text-color fw-600': isInHome}">
          <span *ngIf="isInIntro && deferChoice">{{(selectedOrderTypeConfig?.needsAddress ? 'component.order_time_chooser.when_delivered' : 'component.order_time_chooser.when_ready') | translate}}</span>
          <span *ngIf='isInHome && availableDeferOptions?.length > 0'>{{'component.order_time_chooser.when' | translate}}</span>
        </legend>

        <p class="small no-margin fw-600" *ngIf="(isInIntro && !deferChoice) || (isInHome && availableDeferOptions?.length === 0)">
          {{'component.order_time_chooser.store_closed' | translate}}
          <span *ngIf="!storeStatus?.hasToday">
            {{'component.order_time_chooser.place_order_at' | translate: {orderType: selectedOrderTypeConfig?.displayNameOrName, openAt: storeStatus?.nextOpenTime | dateFormat: isEuCountry ? "dd/MM" : "MM/dd"} }}
          </span>
        </p>

        <fts-store-open-close-time *ngIf="isInIntro"
                                  [orderType]="orderType"
                                  [openCloseTime]="openCloseTime"></fts-store-open-close-time>

        <p class="small no-margin fw-600" *ngIf='(deferChoice == deferChoices.Asap || (deferChoice == undefined && availableDeferOptions?.length > 0)) && !theme.hideIntroAsapNote && !storeConfig.usesAutomatedPromiseTime'>
          {{ orderTimeInfo?.asapNote }}
        </p>

        <hr *ngIf="isInIntro && deferChoice" aria-hidden="true">
      </ng-container>

      <div *ngIf="isInIntro">
        <fts-themeable-radio
          class="font-secondary"
          [label]="selectedOrderTypeConfig?.asapLabel || 'component.defer_choice.asap' | translate"
          [value]="deferChoices.Asap"
          [id]="deferChoices.Asap"
          [name]="'defer-choice'"
          [ngModel]='deferChoice'
          *ngIf='storeStatus?.isOpen && selectedOrderTypeConfig?.hasAsap'
          (ngModelChange)="changeDeferChoice($event)"
        ></fts-themeable-radio>

        <ng-container *ngIf="selectedOrderTypeConfig.allowWebDeferredOrders">
          <fts-themeable-radio
            class="font-secondary"
            [label]="'component.defer_choice.today' | translate"
            [value]="deferChoices.Today"
            [id]="deferChoices.Today"
            [name]="'defer-choice'"
            [ngModel]='deferChoice'
            *ngIf="storeStatus?.hasToday"
            (ngModelChange)="changeDeferChoice($event)"
          ></fts-themeable-radio>

          <fts-themeable-radio
            class="font-secondary"
            [label]="'component.defer_choice.future' | translate"
            [value]="deferChoices.Future"
            [id]="deferChoices.Future"
            [name]="'defer-choice'"
            [ngModel]='deferChoice'
            *ngIf='futureDates?.length'
            (ngModelChange)='changeDeferChoice($event)'
          ></fts-themeable-radio>
        </ng-container>
      </div>

      <div class='margin-top-20' [@expanded] *ngIf="shouldShowAsapButton">
        <button
          ftsAsyncButton
          ftsAuditableButton
          auditName="Continue to menu asap"
          [subscription]='loadingSubscription'
          (click)='asapButtonOnClick()'
          id='continue-to-menu'
          class='btn btn-lg intro-highlight-bg-color intro-highlight-inverse-text-color shadowed fw-600 margin-bottom-15 font-secondary'>
          {{selectedOrderTypeConfig?.asapLabel || 'component.order_time_chooser.asap' | translate}}
        </button>
        <p *ngIf="shouldShowDeferOptionChooser" class="small no-margin fw-600">- {{'component.order_time_chooser.or' | translate}} -</p>
      </div>

      <select class='form-control primary-bg-color primary-text-color' [attr.aria-label]="'component.order_time_chooser.order_time_options' | translate"
              *ngIf='(isInHome && shouldShowDeferOptionChooser) || isInStoreSelection' [ngModel]='deferChoice' (ngModelChange)='changeDeferChoice($event)'
              id="defer-choice-dropdown"
              [disabled]="disabled"
              [ngClass]="{
                'isInHome home-defer-option-chooser': isInHome,
                'isInStoreSelection margin-left-auto': isInStoreSelection,
                'margin-right-auto': !deferChoice || deferChoice === deferChoices.Asap,
                'margin-right-10': deferChoice !== deferChoices.Asap
              }">
        <option *ngFor="let deferOption of availableDeferOptions; trackBy: trackByDeferOption" [disabled]="deferOption.disabled" [value]='deferOption.value'>{{deferOption.label}}</option>
      </select>

      <div *ngIf="deferChoice && deferChoice !== deferChoices.Asap" [ngClass]="{'time-slot-wrapper': isInIntro, 'd-flex margin-right-auto': isInStoreSelection}">

        <ng-container *ngIf="deferChoice === deferChoices.Future">
          <div class="time-selector" *ngIf="theme?.daySlotChooserStyle === daySlotChooserStyles.SimpleList || isInStoreSelection">
            <select class='form-control primary-bg-color primary-text-color' [attr.aria-label]="'component.order_time_chooser.date_options' | translate"
                    [(ngModel)]="selectedDeferDate" (ngModelChange)='onDeferDateChange($event)'
                    id="defer-date-dropdown"
                    [ngClass]="{
                      'isInHome': isInHome,
                      'home-date-option-chooser': !isInStoreSelection,
                      'isInStoreSelection': isInStoreSelection,
                      'margin-right-10': deferChoice === deferChoices.Future && !!selectedDeferDate
                    }">
              <option disabled [ngValue]='undefined'>{{'component.order_time_chooser.date' | translate}}</option>
              <option *ngFor='let day of futureDates' [ngValue]='day'>{{day | dateFormat: isEuCountry ? "EEE d/M" : "EEE M/d"}}</option>
            </select>
          </div>
          <div class="calendar-day-chooser-container" [ngClass]="{'isInHome primary-text-color': isInHome}">
            <fts-calendar-day-chooser *ngIf="theme?.daySlotChooserStyle === daySlotChooserStyles.Calendar && !isInStoreSelection"
                                      [days]="futureDaySlots"
                                      [placeholder]="'component.order_time_chooser.date' | translate"
                                      [selectedDay]="selectedFutureDay"
                                      [isOpen]="isCalendarChooserOpen"
                                      [startOfWeek]="storeConfig.startCalendarWeekOn"
                                      (daySelected)="calendarDaySelected($event)"></fts-calendar-day-chooser>
          </div>
        </ng-container>

        <ng-container *ngIf="timeSlots.length">
          <div class="time-selector timeslot-selector" *ngIf="theme?.timeSlotChooserStyle === timeslotChooserStyles.HoursMinutes && !isInStoreSelection">
            <select class='form-control primary-bg-color primary-text-color' [attr.aria-label]="'component.order_time_chooser.hours_options' | translate"
                    [(ngModel)]='selectedHour'
                    (ngModelChange)='selectedTimeSlotHourChanged()'
                    [disabled]="selectedDeferDate == undefined && deferChoice !== deferChoices.Today"
                    id="defer-time-hours-dropdown">
              <option disabled [ngValue]='undefined'>HH</option>
              <option *ngFor='let hour of timeSlotsHours' [value]='hour'>{{hour}}</option>
            </select>

            <select class='form-control primary-text-color' [ngClass]="{'primary-bg-color' : !!selectedHour}" [attr.aria-label]="'component.order_time_chooser.minutes_options' | translate"
                    [ngModel]='selectedMinutes' [disabled]="!selectedHour" (ngModelChange)='selectedTimeSlotMinutesChanged($event)'
                    id="defer-time-minutes-dropdown">
              <option disabled [ngValue]='undefined'>MM</option>
              <option *ngFor='let minutes of timeSlotsMinutes' [value]='minutes'>{{minutes}}</option>
            </select>

            <select class='form-control primary-text-color text-uppercase' [ngClass]="{'primary-bg-color' : !!selectedMinutes}" [attr.aria-label]="'component.order_time_chooser.am_pm_options' | translate"
                    [(ngModel)]='selectedDeferTime' [disabled]="!selectedMinutes" (ngModelChange)='onTimeSlotChange($event)' id="defer-time-timeslot-dropdown">
              <option disabled *ngIf='!selectedDeferTime' [value]='undefined'>{{suggestDayPart()}}</option>
              <option class='text-uppercase' *ngFor="let timeSlot of filteredTimeSlots" [value]='timeSlot'>{{timeSlot | dateFormat: 'a'}}</option>
            </select>
          </div>
          <div class="time-selector " *ngIf="theme?.timeSlotChooserStyle === timeslotChooserStyles.SimpleList || isInStoreSelection">
            <select class='form-control primary-bg-color primary-text-color' [attr.aria-label]="'component.order_time_chooser.time_options' | translate"
                    [ngClass]="{'isInHome': isInHome, 'isInStoreSelection': isInStoreSelection}"
                    [(ngModel)]='selectedTimeSlot'
                    (ngModelChange)='selectedTimeSlotChanged()'
                    [disabled]="(selectedDeferDate == undefined && deferChoice !== deferChoices.Today) || disabled"
                    id="defer-time-dropdown">
              <option disabled [ngValue]='undefined'>{{'component.order_time_chooser.time' | translate}}</option>
              <option *ngFor='let timeSlot of timeSlots' [value]='timeSlot'>{{timeSlot | dateFormat: 'h:mm a'}}</option>
            </select>
          </div>

          <div class="time-selector " *ngIf="theme?.timeSlotChooserStyle === timeslotChooserStyles.RangeList && !isInStoreSelection">
            <select class='form-control primary-bg-color primary-text-color' [attr.aria-label]="'component.order_time_chooser.day_options' | translate"
                    [ngClass]="{'isInHome': isInHome}"
                    [(ngModel)]='selectedTimeSlot'
                    (ngModelChange)='selectedTimeSlotChanged()'
                    [disabled]="selectedDeferDate == undefined && deferChoice !== deferChoices.Today"
                    id="defer-time-range-dropdown">
              <option disabled [ngValue]='undefined'>{{'component.order_time_chooser.timeslot' | translate}}</option>
              <option *ngFor='let timeSlot of timeSlots'
                      [value]='timeSlot'>{{timeSlot | dateFormat: 'h:mm'}} - {{endOfTimeSlot(timeSlot) | dateFormat: 'h:mm a'}}</option>
            </select>
          </div>
        </ng-container>

        <div *ngIf="!timeSlots.length && (deferChoice === deferChoices.Today || (deferChoice === deferChoices.Future && selectedDeferDate !== undefined))" class="loading-button margin-top-10 padded-top-10"></div>

      </div>
    </fieldset>
  </div>
  <div *ngIf="loadingTimeDefferOptions$ | async" class="loading-button margin-top-10 padded-top-10">
  </div>
</div>
