import { Injectable, ElementRef } from '@angular/core';
import focusTrap, { FocusTrap } from 'focus-trap';
import { ViewportService } from './core/services/viewport.service';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ViewPort } from './domain/view-port.model';

@Injectable({
  providedIn: 'root',
})
export class FocusService {
  private _el: ElementRef;
  private trap: FocusTrap;

  private isMobile: boolean;
  private scrollableEl: any;

  set focusElement(el: ElementRef) {
    this._el = el;
  }

  get focusElement() {
    return this._el;
  }

  set scrollableElement(element: any) {
    this.scrollableEl = element;
  }

  constructor(private viewPortService: ViewportService) {
    this.viewPortService.viewport$
      .pipe(
        map((viewport: ViewPort) => viewport.isMobile),
        distinctUntilChanged()
      )
      .subscribe((isMobile: boolean) => (this.isMobile = isMobile));
  }

  applyFocus(elementId?: string) {
    if (!!elementId) {
      document.getElementById(elementId).setAttribute('tabindex', '-1');
      document.getElementById(elementId).focus();
    } else if (this._el) {
      this._el.nativeElement.setAttribute('tabindex', '-1');
      this._el.nativeElement.focus();
    }
  }

  resetFocus() {
    this._el = null;
  }

  activateFocusTrap(element: string | HTMLElement, fallbackFocusElement?: string) {
    this.deactivateFocusTrap();
    const mobileStoreEl = typeof element === 'string' ? document.getElementById(element) : element;
    if (!!mobileStoreEl) {
      this.trap = focusTrap(mobileStoreEl, {
        fallbackFocus: document.getElementById(fallbackFocusElement),
        escapeDeactivates: true,
        returnFocusOnDeactivate: true,
        clickOutsideDeactivates: true,
      });
      this.trap.activate();
    }
  }

  deactivateFocusTrap(returnFocus = true) {
    if (!!this.trap) {
      this.trap.deactivate({ returnFocus });
    }
  }

  activateTrapAndScrollToElement(element: ElementRef, extraOffset: number) {
    if (element) {
      this.activateFocusTrap(element.nativeElement);

      if (this.isMobile) {
        const offsetTop = element.nativeElement.getBoundingClientRect().top;
        if (!!this.scrollableEl && offsetTop < extraOffset) {
          this.scrollableEl.scrollBy({ top: offsetTop - 200, behavior: 'smooth' });
        }
      } else {
        element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }
}
