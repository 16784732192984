import { BaseResource } from './base-resource';

export class MerchantStore extends BaseResource {
  readonly storeId: string;
  readonly primaryKey: string;
  readonly name: string;
  readonly address: string;
  readonly telephone: string;
  readonly link: string;
  readonly baseUrl: string;
  readonly zip: string;
  readonly state: string;
  readonly city: string;
  readonly longitude: string;
  readonly latitude: string;
  readonly lastUpdated: string;
  readonly displayName: string;
  readonly isCurrentStore: boolean;
  readonly distance: number;
}
