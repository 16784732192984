import { ValidatedResource } from '../core/validated-resource';
import { ImageAlternativeType } from './image-alternative-type.enum';
import { Picture } from './picture.model';
import { subWeeks, isBefore } from 'date-fns';
import { Utils } from '../../utils/utils';

export class RecentlyOrderedItem extends ValidatedResource {
  name: string;
  category: string;
  labelSymbol: string;
  description: string;
  size: string;
  sizeLabel: string;
  price: number;
  itemId: string;
  lastOrderedDescription: string;
  caloriesFrom?: number;
  caloriesTo?: number;
  servings?: number;
  servingsLabel?: string;
  showFullSizeCalories: boolean;
  caloriesDisclaimer: string;
  lastOrderedTime: string;
  webLabel: string;

  pictures: Picture[];

  constructor() {
    super();
  }

  get hasImage(): boolean {
    return !!this.pictures && this.pictures.length > 0;
  }

  getItemPicture(type: ImageAlternativeType = ImageAlternativeType.ORIGINAL): Picture | undefined {
    if (!this.hasImage) {
      return undefined;
    }
    return this.pictures.find(p => p.type === type);
  }

  get imageSrcOrDefault(): string {
    let src; //  = PanelItem.DefaultImageUrl

    const itemPicture = this.getItemPicture();
    if (!!itemPicture) {
      src = itemPicture.src;
    }

    return src;
  }

  get imageSrcsetOrDefault(): string {
    let srcset; // = PanelItem.DefaultImageUrl

    const itemPicture = this.getItemPicture();

    if (!!itemPicture) {
      srcset = itemPicture.srcset;
    }

    return srcset;
  }

  get isMoreThatAWeekAgo(): boolean {
    return isBefore(Utils.parseIsoDate(this.lastOrderedTime), subWeeks(Utils.getTzDate(), 1));
  }
}
