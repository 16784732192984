<div class='modal-content primary-bg-color primary-text-color'>
  <div class='modal-header secondary-bg-color secondary-text-color'>
    <h2 id="modal-title" class='modal-title float-left'>{{'component.upsell_special_modal_content.would_you_like_combo' | translate: {category: upsellTriggerOrderableItem?.category} }}</h2>
    <button class='close float-right' (click)='handleCloseModal()' [attr.aria-label]="'component.upsell_special_modal_content.close' | translate">
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body text-left'>
    <ng-container *ngIf='upsellWrapper?.special.imageUrl'>
      <img ftsPicture #pictureRef='pictureRef' [imgSrc]='upsellWrapper?.special.imageUrl'
         class="img-fluid" [alt]="'component.upsell_special_modal_content.combo_image' | translate: {category: upsellTriggerOrderableItem?.category} "/>
      <fts-ghost-element *ngIf='upsellWrapper?.special.imageUrl  && !pictureRef?.isLoaded' [height]="(viewportService?.viewport$ | async)?.isMobile ? 250 : 420"></fts-ghost-element>
    </ng-container>
    <h2 class="font-secondary fw-500" *ngIf="upsellWrapper?.special.title">{{upsellWrapper?.special.title}}</h2>
    <p *ngIf="upsellWrapper?.special.subTitle">{{upsellWrapper?.special.subTitle}}</p>
  </div>
  <div class='modal-footer'>
    <button ftsAuditableButton ftsAsyncButton [subscription]="addItemLoadingSubscription" class="btn btn primary-bg-color action-text-color text-uppercase dismiss-btn action-btn font-secondary"
            (click)='handleAddItem()'>
      <span class='visible-xs-inline'>{{'component.upsell_special_modal_content.no_thanks' | translate}}</span>
      <span class='hidden-xs'>{{'component.upsell_special_modal_content.no_thanks_just' | translate: {category: upsellTriggerOrderableItem?.category} }}</span>
    </button>
    <button ftsAuditableButton ftsAsyncButton [subscription]="makeComboLoadingSubscription" class="btn action-bg-color action-inverse-text-color text-uppercase primary-btn action-btn font-secondary"
            (click)="handleMakeCombo()" >{{'component.upsell_special_modal_content.make_it_combo' | translate}}</button>
  </div>
</div>
