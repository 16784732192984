import { LocalStorageKeys } from './../../domain/local-storage-keys.enum';
import { BrowserStorageHandlerService } from './browser-storage-handler.service';
import { Injectable } from '@angular/core';
import { AccountService } from 'ngx-web-api';
import { ReloaderService } from '../../core/services/reloader.service';

@Injectable({
  providedIn: 'root',
})
export class AccountLocalStorageService {
  constructor(
    private accountService: AccountService,
    private reloaderService: ReloaderService,
    private browserStorageHandlerService: BrowserStorageHandlerService
  ) {}

  public setupAccountUpdatedListener() {
    window.addEventListener('storage', e => {
      if (e.key === LocalStorageKeys.ACCOUNT_UPDATED && e.newValue === 'true') {
        this.browserStorageHandlerService.setLocalStorageItem(LocalStorageKeys.ACCOUNT_UPDATED, 'false');
        this.accountService.fetchAccount().subscribe(
          () => {},
          () => {}
        );
      }
    });
  }
  public setupAccountSignedInOutListener() {
    window.addEventListener('storage', e => {
      if (e.key === LocalStorageKeys.ACCOUNT_SIGNED_IN_OUT && e.newValue === 'true') {
        this.browserStorageHandlerService.setLocalStorageItem(LocalStorageKeys.ACCOUNT_SIGNED_IN_OUT, 'false');
        this.reloaderService.reload();
      }
    });
  }

  public setAccountUpdated(value: string) {
    this.browserStorageHandlerService.setLocalStorageItem(LocalStorageKeys.ACCOUNT_UPDATED, value);
  }

  public setAccountSignedInOut(value: string) {
    this.browserStorageHandlerService.setLocalStorageItem(LocalStorageKeys.ACCOUNT_SIGNED_IN_OUT, value);
  }
}
