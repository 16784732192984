import { Component, OnInit, Input, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { Order, AuditService } from 'ngx-web-api';
import { Animations } from '../../../animations';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { UiOrchestratorService } from '../../services/ui-orchestrator.service';
import { FocusService } from '../../../focus.service';
import { PathService } from 'app/shared/services/path.service';

@Component({
  selector: 'fts-menu-cart',
  templateUrl: './menu-cart.component.html',
  styleUrls: ['./menu-cart.component.scss'],
  animations: [Animations.expanded],
})
export class MenuCartComponent implements OnInit, OnDestroy {
  @Input()
  order: Order;
  @Input()
  expandedOrderTree: boolean;
  @Input()
  isOverlay: boolean;

  orderTreeComponent: any;
  orderTreeVisible = false;
  destroy$ = new Subject<void>();
  private upsellTrigeredOrderTreeVisibility = false;

  constructor(
    private router: Router,
    private auditService: AuditService,
    private uiOrchestratorService: UiOrchestratorService,
    private cartContainer: ElementRef,
    private focusService: FocusService,
    private pathService: PathService
  ) {
    if (this.pathService.inOrderRelatedRoutes()) {
      this.importLazyComponents();
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((val: NavigationEnd) => {
        if (this.pathService.inOrderRelatedRoutes(val.url)) {
          this.importLazyComponents();
        }
        this.orderTreeVisible = false;
      });

    this.uiOrchestratorService.orderTreeShown$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.toggleOrderTree();
      this.upsellTrigeredOrderTreeVisibility = true;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyPress(event) {
    if (this.orderTreeVisible && ((event.key !== undefined && event.key === 'Esc') || event.key === 'Escape')) {
      this.toggleOrderTree();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event): void {
    if (
      this.cartContainer &&
      !this.cartContainer.nativeElement.contains(event.target) &&
      this.orderTreeVisible &&
      !this.upsellTrigeredOrderTreeVisibility &&
      !this.uiOrchestratorService.persistOrderTree &&
      !event.target.getAttribute('data-ngb-dropdown-item') &&
      !event.target.getAttribute('invite-friends-button') &&
      !event.target.getAttribute('group-order-editor-overlay') &&
      !event.target.getAttribute('copy-go-link-button-container') &&
      !event.target.getAttribute('edit-go-button-container')
    ) {
      this.toggleOrderTree();
    }

    if (this.orderTreeVisible && this.upsellTrigeredOrderTreeVisibility) {
      this.upsellTrigeredOrderTreeVisibility = false;
    }

    if (this.uiOrchestratorService.persistOrderTree) {
      this.uiOrchestratorService.persistOrderTree = false;
    }
  }

  toggleOrderTree() {
    this.orderTreeVisible = !this.orderTreeVisible;
    this.auditService.createAudit(() => `Order Tree ${this.orderTreeVisible ? 'expanded' : 'collapsed'}.`);

    setTimeout(() => {
      if (this.orderTreeVisible) {
        this.focusService.activateFocusTrap('order-tree');
      } else {
        this.focusService.deactivateFocusTrap();
      }
    });
  }

  private importLazyComponents() {
    if (!this.orderTreeComponent) {
      this.orderTreeComponent = import(`../../../shared/menu-order-tree/menu-order-tree.component`).then(
        ({ MenuOrderTreeComponent }) => MenuOrderTreeComponent
      );
    }
  }
}
