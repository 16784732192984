import { Injectable } from '@angular/core';
import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

/**
 * This is an extensive url serializer that properly serializes characters such as: !'()*
 * It seems that there is an issue with the router's current url encoding.
 * In specific, here is an issue with parenthesis: https://github.com/angular/angular/issues/10280
 */
@Injectable()
export class ExtensiveUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    const xurl = url.replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16)}`);
    return this.defaultUrlSerializer.parse(xurl);
  }

  serialize(tree: UrlTree): string {
    return this.defaultUrlSerializer.serialize(tree);
  }
}
