import { BasicModalContext } from './basic-modal-context';
import { Observable } from 'rxjs';
import { NotificationModalType } from '../domain/notification-modal-type.enum';

export class ConfirmationModalContext extends BasicModalContext {
  set title(title: string) {
    this.config = Object.assign(this.config || {}, { title });
  }

  get title(): string {
    return this.config.title;
  }

  set dismissBtnTitle(dismissBtnTitle: string) {
    this.config = Object.assign(this.config || {}, { dismissBtnTitle });
  }

  get dismissBtnTitle(): string {
    return this.config.dismissBtnTitle;
  }

  set submitBtnTitle(submitBtnTitle: string) {
    this.config = Object.assign(this.config || {}, { submitBtnTitle });
  }

  get submitBtnTitle(): string {
    return this.config.submitBtnTitle;
  }

  set hasSubmitBtn(hasSubmitBtn: boolean) {
    this.config = Object.assign(this.config || {}, { hasSubmitBtn });
  }

  get hasSubmitBtn(): boolean {
    return this.config.hasSubmitBtn;
  }

  set hasDismissBtn(hasDismissBtn: boolean) {
    this.config = Object.assign(this.config || {}, { hasDismissBtn });
  }

  get hasDismissBtn(): boolean {
    return this.config.hasDismissBtn;
  }

  set mainContent(mainContent: string) {
    this.config = Object.assign(this.config || {}, { mainContent });
  }

  get mainContent(): string {
    return this.config.mainContent;
  }

  set submitObservable(submitObservable: Observable<any>) {
    this.config = Object.assign(this.config || {}, { submitObservable });
  }

  get submitObservable(): Observable<any> {
    return this.config.submitObservable;
  }

  set textOnly(textOnly: boolean) {
    this.config = Object.assign(this.config || {}, { textOnly });
  }

  get textOnly(): boolean {
    return this.config.textOnly;
  }

  set reverseButtons(reverseButtons: boolean) {
    this.config = Object.assign(this.config || {}, { reverseButtons });
  }

  get reverseButtons(): boolean {
    return this.config.reverseButtons;
  }

  set isDismissed(isDismissed: boolean) {
    this.config = Object.assign(this.config || {}, { isDismissed });
  }

  get isDismissed(): boolean {
    return this.config.isDismissed;
  }

  set type(type: NotificationModalType) {
    this.config = Object.assign(this.config || {}, { type });
  }

  get type(): NotificationModalType {
    return this.config.type;
  }

  set needDismissAudit(needDismissAudit: boolean) {
    this.config = Object.assign(this.config || {}, { needDismissAudit });
  }

  get needDismissAudit(): boolean {
    return this.config.needDismissAudit;
  }

  constructor() {
    super();
    this.class = 'sm';
  }

  get hideListStyle(): boolean {
    return this.config.hideListStyle;
  }

  set hideListStyle(hideListStyle: boolean) {
    this.config.hidelistStyle = Object.assign(this.config || {}, { hideListStyle });
  }
}
