<div class='modal-content primary-bg-color primary-text-color'>
  <div class='modal-header secondary-bg-color secondary-text-color'>
    <h2 id="modal-title" class='modal-title float-left font-secondary'>{{title}}</h2>
    <button class='close' (click)='hideModal()' [attr.aria-label]="'component.address_modal.close' | translate">
      <span aria-hidden='true'><span aria-hidden='true'>&times;</span></span>
    </button>
  </div>
  <div class='modal-body'>
    <fts-address-form #addressForm
                      [address]="address"
                      [isEditing]="isEditing"
                      [horizontal]="false"
                      [showClientValidation]='showAddressClientValidation || isEditing'
                      (submitSuccess)="handleSuccess()"
                      (addressErrors)="onAddressErrors($event)"
                      class="text-left"></fts-address-form>

    <div *ngFor="let error of addressErrors" role="alert" aria-atomic="true" class="alert alert-danger">{{error}}</div>

    <div class='modal-buttons'>
      <button ftsAsyncButton [subscription]='loadingSubscription' (click)="handleSubmit()" class="btn secondary-bg-color secondary-text-color primary-btn action-btn font-secondary">
        {{submitBtnTitle}}
      </button>
    </div>
  </div>
</div>
