import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { CwoService } from 'app/core/services/cwo.service';
import { map, tap, mergeMap } from 'rxjs/operators';
import { AddressType } from '../../models/core/address-type.model';
import { AutoReplyType } from '../../models/core/auto-reply-type.enum';
import { AutoReply } from '../../models/core/auto-reply.model';
import { CreditCardConfig } from '../../models/core/credit-card-config.model';
import { LoyaltyBadge } from '../../models/core/loyalty-badge.model';
import { LoyaltyPlan } from '../../models/core/loyalty-plan.model';
import { MerchantStore } from '../../models/core/merchant-store.model';
import { OrderTypeConfig } from '../../models/core/order-type-config.model';
import { OrderType } from '../../models/core/order-type.enum';
import { SocialLinks } from '../../models/core/social-links.model';
import { StoreConfig } from '../../models/core/store-config.model';
import { TipConfig } from '../../models/core/tip-config.model';
import { makeMyCustomTransport } from '../../utils/sentry-transport';
import { Address } from '../../models/core/address.model';
import { DeliveryCompany } from '../../models/core/delivery-company.model';
import { AccountResourceConfig } from '../../models/account/account-resource-config.model';
import { ErrorHandlerOptions, ERROR_HANDLER_OPTIONS } from '../../utils/error-handler-options';
import { Event, EventHint } from '@sentry/types';
import { Order } from 'ngx-web-api/lib/models/ordering/order.model';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  protected storeConfig: BehaviorSubject<StoreConfig> = new BehaviorSubject(new StoreConfig());
  public storeConfig$: Observable<StoreConfig> = this.storeConfig.asObservable();

  private tipConfig: BehaviorSubject<TipConfig> = new BehaviorSubject(new TipConfig());
  public tipConfig$: Observable<TipConfig> = this.tipConfig.asObservable();

  private loyaltyPlan: BehaviorSubject<LoyaltyPlan> = new BehaviorSubject(new LoyaltyPlan());
  public loyaltyPlan$: Observable<LoyaltyPlan> = this.loyaltyPlan.asObservable();

  private loyaltyBadges: BehaviorSubject<LoyaltyBadge[]> = new BehaviorSubject([]);
  public loyaltyBadges$: Observable<LoyaltyBadge[]> = this.loyaltyBadges.asObservable();

  protected creditCardConfig: BehaviorSubject<CreditCardConfig> = new BehaviorSubject(new CreditCardConfig());
  public creditCardConfig$: Observable<CreditCardConfig> = this.creditCardConfig.asObservable();

  private orderTypesConfig: BehaviorSubject<OrderTypeConfig[]> = new BehaviorSubject([]);
  /**
   * @returns only the webEnabled OrderTypesConfig.
   */
  public orderTypesConfig$: Observable<OrderTypeConfig[]> = this.orderTypesConfig.asObservable();

  private allOrderTypesConfig: BehaviorSubject<OrderTypeConfig[]> = new BehaviorSubject([]);
  /**
   * @returns the webEnabled as well as the selfOrdering OrderTypesConfig.
   */
  public allOrderTypesConfig$: Observable<OrderTypeConfig[]> = this.allOrderTypesConfig.asObservable();

  private addressTypes: BehaviorSubject<AddressType[]> = new BehaviorSubject([]);
  public addressTypes$: Observable<AddressType[]> = this.addressTypes.asObservable();

  private socialLinks: BehaviorSubject<SocialLinks> = new BehaviorSubject(new SocialLinks());
  public socialLinks$: Observable<SocialLinks> = this.socialLinks.asObservable();

  private deliveryCompanies: BehaviorSubject<DeliveryCompany[]> = new BehaviorSubject([]);
  public deliveryCompanies$: Observable<DeliveryCompany[]> = this.deliveryCompanies.asObservable();

  private cachedSetryError: any = {};
  private defaultThrottleTimeInMs = 30000; //

  constructor(
    private http: HttpClient,
    @Inject(ERROR_HANDLER_OPTIONS) private options: ErrorHandlerOptions,
    private cwoService: CwoService
  ) {
    // Fallback to default value if InjectionToken options property defaultThrottleTimeInMs is not defined
    this.defaultThrottleTimeInMs = this.options?.defaultThrottleTimeInMs ?? this.defaultThrottleTimeInMs;
  }
  // Custom error rate limiter. More info :
  // https://github.com/getsentry/sentry-javascript/issues/435#issuecomment-605634518
  beforeSend = (event: Event, hint?: EventHint) => {
    const msg = (hint?.originalException as Error)?.message ?? (hint?.originalException as string);
    // If error message is already in cache (sent in the last {defaultThrottleTimeInMs} ms) then prevent spamming
    if (msg && msg in this.cachedSetryError) {
      console.warn(`Sentry reporting limited for the repeated error : '${msg}'`);
      return null;
    }
    this.cachedSetryError[msg] = true;
    // Remove error from cache after {defaultThrottleTimeInMs} ms
    setTimeout(() => {
      delete this.cachedSetryError[msg];
    }, this.defaultThrottleTimeInMs);
    return event;
  };

  public fetchStoreConfig(): Observable<StoreConfig> {
    return this.http.get('/ws/integrated/v1/ordering/config/store').pipe(
      map((json: Object) => new StoreConfig().deserialize(json)),
      tap((config: StoreConfig) => {
        config.allowGroupWebOrdering = false;
        this.storeConfig.next(config);
      }),
      tap((config: StoreConfig) => {
        if (config.sentryDSN) {
          Sentry.init({
            autoSessionTracking: false,
            dsn: config.sentryDSN,
            release: config.release,
            transport: makeMyCustomTransport,
            maxValueLength: 500,
            ignoreErrors: this.options?.ignoreErrors,
            denyUrls: this.options?.denyUrls,
            allowUrls: this.options?.allowUrls,
            beforeSend: this.options?.beforeSend === 'DEFAULT' ? this.beforeSend : this.options?.beforeSend,
            sampleRate: this.options?.sampleRate,
          });
        }
      })
    );
  }

  public fetchTipConfig(): Observable<TipConfig> {
    return this.http.get('/ws/integrated/v1/ordering/config/tips').pipe(
      map((json: Object) => new TipConfig().deserialize(json)),
      tap((config: TipConfig) => this.tipConfig.next(config))
    );
  }

  public fetchLoyaltyPlanConfig(): Observable<LoyaltyPlan> {
    return this.http.get('/ws/integrated/v1/ordering/config/loyalty/plans').pipe(
      map((json: Object[]) => (json.length > 0 ? json[0] : {})),
      map(plan => new LoyaltyPlan().deserialize(plan)),
      tap((config: LoyaltyPlan) => this.loyaltyPlan.next(config))
    );
  }

  public fetchLoyaltyBadgesConfig(): Observable<LoyaltyBadge[]> {
    return this.http.get('/ws/webordering/loyalty/badges').pipe(
      map((json: Object): Object[] => (json['response'].badges || []).map(badge => new LoyaltyBadge().deserialize(badge))),
      tap((badges: LoyaltyBadge[]) => this.loyaltyBadges.next(badges))
    );
  }

  public fetchCreditCardConfig(): Observable<CreditCardConfig> {
    return this.http.get('/ws/integrated/v1/ordering/config/creditCards').pipe(
      map((json: Object) => new CreditCardConfig().deserialize(json)),
      tap((config: CreditCardConfig) => this.creditCardConfig.next(config))
    );
  }

  public getAutoReply(type: AutoReplyType): Observable<AutoReply> {
    const params: HttpParams = new HttpParams().set('type', type);
    return this.http
      .get('/ws/integrated/v1/ordering/config/autoReply', { params })
      .pipe(map((json: Object) => new AutoReply().deserialize(json)));
  }

  /**
   * Returns the OrderTypesConfig respecting the 2 provided params which act as filters.
   * @param orderSource  source that used when placing the order
   * @param allOrderTypes determines if the selfOrdeing OrderTypesConfig will be included in the returning result or not
   */
  public fetchOrderTypesConfig(orderSource?: string, allOrderTypes = false): Observable<OrderTypeConfig[]> {
    let params: HttpParams = new HttpParams();
    if (!!orderSource) {
      params = params.set('orderSource', orderSource);
    }
    return this.http.get('/ws/integrated/v1/ordering/config/orderTypes', { params }).pipe(
      map((json: Object[]) => (json || []).map(ot => new OrderTypeConfig().deserialize(ot))),
      tap((orderTypes: OrderTypeConfig[]) => {
        this.allOrderTypesConfig.next(orderTypes);
        this.orderTypesConfig.next(orderTypes.filter(type => type.webEnabled));
      }),
      mergeMap(() => (allOrderTypes ? this.allOrderTypesConfig$ : this.orderTypesConfig$))
    );
  }

  public findOrderTypeConfig(type: OrderType): Observable<OrderTypeConfig> {
    return this.allOrderTypesConfig$.pipe(map((configs: OrderTypeConfig[]) => configs.find(cfg => type === cfg.type)));
  }

  public getOrderTypeFromConfigs(type: OrderType | string, configs: OrderTypeConfig[]) {
    if (Array.isArray(configs) && type in OrderType) {
      return configs.find(cfg => type === cfg.type);
    }
    return null;
  }

  public fetchAddressTypesConfig(): Observable<AddressType[]> {
    return this.http.get('/ws/integrated/v1/ordering/config/addressTypes').pipe(
      map((json: Object[]) => (json || []).map(at => new AddressType().deserialize(at))),
      tap((addressTypes: AddressType[]) => this.addressTypes.next(addressTypes))
    );
  }

  public fetchSocialLinks(): Observable<SocialLinks> {
    return this.http.get('/ws/integrated/v1/ordering/config/social').pipe(
      map(json => new SocialLinks().deserialize(json)),
      tap(socialLinks => this.socialLinks.next(socialLinks))
    );
  }

  public fetchStoreStates(): Observable<string[]> {
    const params = new HttpParams().set('webOrdering', 'true');
    return this.http.get<string[]>('/ws/integrated/v1/ordering/config/chain/states', { params });
  }

  public fetchMerchantStores(state?: string): Observable<MerchantStore[]> {
    let params = new HttpParams().set('webOrdering', 'true');
    if (!!state) {
      params = params.set('state', state);
    }
    return this.http
      .get('/ws/integrated/v1/ordering/config/chain/stores', { params })
      .pipe(map((json: Object[]) => (json || []).map(ms => new MerchantStore().deserialize(ms))));
  }

  public fetchDeliveryCompanies(): Observable<DeliveryCompany[]> {
    return this.http.get('/ws/integrated/v1/ordering/config/deliveryCompanies').pipe(
      map((json: Object[]) => (json || []).map(dc => new DeliveryCompany().deserialize(dc))),
      tap((deliveryCompanies: DeliveryCompany[]) => this.deliveryCompanies.next(deliveryCompanies))
    );
  }

  public getPhoneProviders(): Observable<string[]> {
    return this.http.get<string[]>('/ws/integrated/v1/ordering/config/phoneProviders');
  }

  public getAccountResourceConfig(): Observable<AccountResourceConfig> {
    return this.http.get<AccountResourceConfig>('/ws/integrated/v1/ordering/config/account');
  }

  public getAddressSupportingStores(address: string | Partial<Address>): Observable<MerchantStore[]> {
    let params: HttpParams = new HttpParams();
    if (typeof address === 'string') {
      params = !!address ? params.set('address', address) : params;
    } else if (!!address) {
      params = !!address.street ? params.set('street', address.street) : params;
      params = !!address.city ? params.set('city', address.city) : params;
      params = !!address.state ? params.set('state', address.state) : params;
      params = !!address.zip ? params.set('zip', address.zip) : params;
      params = !!address.addressType ? params.set('addressType', address.addressType) : params;
    }

    return this.http.get<MerchantStore[]>('/ws/integrated/v1/ordering/addressSupportingStores', { params });
  }

  public getMapPointSupportingStores(latitude: number, longtitude: number): Observable<MerchantStore[]> {
    const params: HttpParams = new HttpParams().set('lat', latitude.toString()).set('lng', longtitude.toString());
    return this.http.get<MerchantStore[]>('/ws/integrated/v1/ordering/mapPointSupportingStores', { params });
  }

  public handlePrintReceipt(order: Order): void {
    const url = `/printReceipt?orderId=${order.orderId}&store=${this.cwoService.storeName}`;
    window.open(url, undefined, 'height=600,width=530,status=yes,toolbar=no,menubar=no,location=no');
  }
}
