import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(strings: any[], separator: string): any {
    if (!!strings && strings.length > 0 && separator && typeof separator === 'string') {
      return strings.join(separator);
    }
    return null;
  }
}
