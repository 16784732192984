import { DeserializationUtils } from '../../utils/deserialization-utils';
import { ValidatedResource } from '../core/validated-resource';
import { Coupon } from '../public_api';
import { ImageAlternativeType } from './image-alternative-type.enum';
import { OrderableSpecialStep } from './orderable-special-step.model';
import { Picture } from './picture.model';

export class OrderableSpecial extends ValidatedResource {
  name: string;
  label: string;
  description: string;
  pictures?: Picture[];
  type: string;
  instructions: string;
  disclaimer: string;
  start: string;
  end: string;
  isCombo: boolean;
  orderTypes: string[];
  code?: string;
  steps: OrderableSpecialStep[];
  postCoupon: Partial<Coupon>;

  constructor() {
    super();
  }

  get hasImage(): boolean {
    return !!this.pictures && this.pictures.length > 0;
  }

  getSpecialPicture(type: ImageAlternativeType = ImageAlternativeType.ORIGINAL): Picture | undefined {
    return (this.pictures || []).find(p => p.type === type);
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      steps: DeserializationUtils.deserializeChildren(input, 'steps', OrderableSpecialStep),
    });
  }
}
