import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isSameDay, format } from 'date-fns';
import { Utils } from 'ngx-web-api';

@Pipe({
  name: 'todayFormat',
})
export class TodayFormatPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, showDayFirst = false, isEuCountry = false): any {
    if (!value) {
      return;
    }

    const p = Utils.parseIsoDate(value);
    const dateFormat = isEuCountry ? 'd MMM' : 'MMM d';
    const strFormat = isSameDay(new Date(), p)
      ? 'h:mm a'
      : showDayFirst
      ? `${dateFormat}, h:mm a`
      : `h:mm a '${this.translateService.instant('component.today_format_pipe.on')}' ${dateFormat}`;

    return format(p, strFormat);
  }
}
