import { Utils } from '../../utils/utils';
import { cardTypes } from './credit-cards-spec';

import { ValidatedResource } from './validated-resource';
import { isAfter, parse, subMonths } from 'date-fns';
import { DateFormatPipe } from '../../pipes/date-format.pipe';

export class CreditCard extends ValidatedResource {
  readonly ordinal: number;
  private _number: string;

  token: string;
  cardType: string;
  lastDigits: string;
  firstName: string;
  lastName: string;
  expiration: string;
  cvv: string;
  webBillingZip: string;
  webBillingAddress: string;
  readonly paymentOrdinal: number;
  acceptedCcTerms: boolean;
  transId: string;
  recaptcha: string;
  private dateFormat: DateFormatPipe = new DateFormatPipe();
  private _expMonth: string;
  private _expYear: string;

  constructor() {
    super();
  }

  get number(): string {
    return this._number;
  }

  set number(val: string) {
    this._number = val;
    this.lastDigits = this.resolveLastDigits();
    this.cardType = this.resolveCardType();
  }

  set tsepCardType(cardTypeChar: string) {
    switch (cardTypeChar) {
      case 'V': {
        this.cardType = 'Visa';
        break;
      }
      case 'M': {
        this.cardType = 'MasterCard';
        break;
      }
      case 'X': {
        this.cardType = 'Amex';
        break;
      }
      case 'R': {
        this.cardType = 'Discover';
        break;
      }
      case 'J': {
        this.cardType = 'JCB';
        break;
      }
    }
  }

  get expirationMonth(): string {
    if (!!this._expMonth) {
      return this._expMonth;
    }
    if (this.expiration?.length === 4) {
      return this.expiration.slice(0, 2);
    }
    return '';
  }

  set expirationMonth(val: string) {
    this._expMonth = val;
    this.expiration = `${this._expMonth}${this.expirationYear}`;
  }

  get expirationYear(): string {
    if (!!this._expYear) {
      return this._expYear;
    }
    if (this.expiration?.length === 4) {
      return this.expiration.slice(2);
    }
    return '';
  }

  set expirationYear(val: string) {
    this._expYear = val;
    this.expiration = `${this.expirationMonth}${this._expYear}`;
  }

  get expirationYearFull(): string {
    return !!this.expirationYear ? `${parseInt(this.expirationYear, 10) + 2000}` : '';
  }

  get isExpired(): boolean {
    const now = Utils.getTzDate(subMonths(new Date(), 1));
    const formattedExpirationDate = this.dateFormat.transform(this.expiration, 'MMddyyyy', 'MMyy');
    const parsedExpirationDate = Utils.getTzDate(parse(formattedExpirationDate, 'MMddyyyy', now));
    return isAfter(now, parsedExpirationDate);
  }

  /** @deprecated Please format the expiration using moment pipe */
  get formattedExpiration(): string {
    return this.dateFormat.transform(this.expiration, 'MM/yy', 'MMyy');
  }

  get isEditing(): boolean {
    return !!this.ordinal;
  }

  private resolveLastDigits(): string {
    if (this.number) {
      return this._number.slice(-4);
    }

    return '';
  }

  private resolveCardType(): string {
    if (Utils.isNullOrUndefined(this._number) || this._number.length < 6) {
      return '';
    }

    const firstSixDigits = parseInt(this._number.slice(0, 6), 10);
    const cardNumberLength = this._number.length;

    for (let i = 0, size = cardTypes.length; i < size; i++) {
      const cardType = cardTypes[i];
      for (let j = 0, rangesSize = cardType.ranges.length; j < rangesSize; j++) {
        const range = cardType.ranges[j];
        if (range.length.indexOf(cardNumberLength) !== -1 && range.min <= firstSixDigits && firstSixDigits <= range.max) {
          return cardType.type;
        }
      }
    }

    return '';
  }

  get isInstantiated(): boolean {
    return !!this.number || !!this.lastDigits;
  }

  serialize(): any {
    return {
      ordinal: this.ordinal,
      number: this.number,
      token: this.token,
      cardType: this.cardType,
      lastDigits: this.lastDigits,
      firstName: this.firstName,
      lastName: this.lastName,
      expiration: this.expiration,
      cvv: this.cvv,
      webBillingZip: this.webBillingZip,
      webBillingAddress: this.webBillingAddress,
      acceptedCcTerms: this.acceptedCcTerms,
      paymentOrdinal: this.paymentOrdinal,
      transId: this.transId,
      recaptcha: this.recaptcha,
    };
  }
}
