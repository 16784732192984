import { OrderTypeConfig } from '../../models/core/order-type-config.model';
import { OrderType } from '../../models/core/order-type.enum';

/** @deprecated ConfigService::orderTypesConfig$ will now contain all needed orderTypes. **/
export class StaticOrderTypeConfigs {
  public static dineIn: OrderTypeConfig = ({
    type: OrderType.DineIn,
    name: 'Dine In',
    displayName: 'Dine In',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'Dine In',
  } as any) as OrderTypeConfig;

  public static bar: OrderTypeConfig = ({
    type: OrderType.Bar,
    name: 'Bar',
    displayName: 'Bar',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'Bar',
  } as any) as OrderTypeConfig;

  public static giftReload: OrderTypeConfig = ({
    type: OrderType.GiftReload,
    name: 'Gift Reload',
    displayName: 'Gift Reload',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'Gift Reload',
  } as any) as OrderTypeConfig;

  public static driveThru: OrderTypeConfig = ({
    type: OrderType.DriveThru,
    name: 'Drive Thru',
    displayName: 'Drive Thru',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'Drive Thru',
  } as any) as OrderTypeConfig;

  public static preOrder: OrderTypeConfig = ({
    type: OrderType.PreOrder,
    name: 'PreOrder',
    displayName: 'PreOrder',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'PreOrder',
  } as any) as OrderTypeConfig;

  public static banquet: OrderTypeConfig = ({
    type: OrderType.Banquet,
    name: 'Banquet',
    displayName: 'Banquet',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'Banquet',
  } as any) as OrderTypeConfig;

  public static toStay: OrderTypeConfig = ({
    type: OrderType.ToStay,
    name: 'To Stay',
    displayName: 'To Stay',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'To Stay',
  } as any) as OrderTypeConfig;

  public static forHere: OrderTypeConfig = ({
    type: OrderType.ForHere,
    name: 'For Here',
    displayName: 'For Here',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'For Here',
  } as any) as OrderTypeConfig;

  public static toGo: OrderTypeConfig = ({
    type: OrderType.ToGo,
    name: 'To Go',
    displayName: 'To Go',
    hasAsap: true,
    allowWebDeferredOrders: false,
    displayNameOrName: 'To Go',
  } as any) as OrderTypeConfig;

  public static staticOrderTypeConfigs: OrderTypeConfig[] = [
    StaticOrderTypeConfigs.dineIn,
    StaticOrderTypeConfigs.bar,
    StaticOrderTypeConfigs.giftReload,
    StaticOrderTypeConfigs.driveThru,
    StaticOrderTypeConfigs.preOrder,
    StaticOrderTypeConfigs.banquet,
    StaticOrderTypeConfigs.toStay,
    StaticOrderTypeConfigs.forHere,
    StaticOrderTypeConfigs.toGo,
  ];

  public static getStaticConfig(orderType: OrderType): OrderTypeConfig {
    const resolvedType = StaticOrderTypeConfigs.staticOrderTypeConfigs.find(c => c.type === orderType);
    return resolvedType;
  }
}
