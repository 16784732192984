import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { QuestionnaireAnswer } from './questionnaire-answer.model';

export class SurveyAnswer extends BaseResource {
  refCode: string;
  questionnaire: QuestionnaireAnswer;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      questionnaire: DeserializationUtils.deserializeChild(input, 'questionnaire', QuestionnaireAnswer),
    });
  }

  serialize(): any {
    return {
      refCode: this.refCode,
      questionnaire: this.questionnaire ? this.questionnaire.serialize() : undefined,
    };
  }
}
