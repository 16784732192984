import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Constants } from './constants';

// If we come in need of many custom validators perhaps we should consider using a library like ngx-validators
// https://github.com/Nightapes/ngx-validators

export class CustomValidators {
  static confirm(field: string, confirmField: string): ValidatorFn {
    const validator = (group: FormGroup): { [key: string]: any } => {
      const fieldControl = group.controls[field];
      const confirmationControl = group.controls[confirmField];

      if (fieldControl.value !== confirmationControl.value) {
        confirmationControl.setErrors(Object.assign({}, confirmationControl.errors, { confirm: true }));
      } else if (confirmationControl.hasError('confirm')) {
        const errors = confirmationControl.errors || {};
        delete errors['confirm'];
        confirmationControl.setErrors(errors);
      }

      return {};
    };
    return validator as any;
  }

  // https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
  static email(control: AbstractControl): { [key: string]: boolean } {
    if (!control.value) {
      return {};
    }
    // eslint-disable-next-line max-len
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (pattern.test(control.value)) {
      return {};
    }
    return { email: true };
  }

  static phone(control: AbstractControl): { [key: string]: boolean } {
    if (!control.value) {
      return {};
    }

    const val: string = control.value;
    if (val.replace(/\D+/g, '').length !== Constants.PHONE_DIGITS) {
      return { phone: true };
    }

    return {};
  }

  static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { required: true };
  }

  /*
    Prevent the non-allowed symbols for number input fields. The only allowed symbols 
    are all the numbers of the number row of the keyboard and the numpad, the backspace, 
    the bullets from both keyboard points and the comma.
  */
  public preventNonAllowedSymbolsForNumberInputs(e: any) {
    if ((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === '.' || e.key === ',') {
      return true;
    }
    return false;
  }

  public onPastePreventDefault(e: any) {
    e.preventDefault();
  }
}
