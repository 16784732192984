import { InjectableRxStompConfig } from '@stomp/ng2-stompjs';

export class WebSocketUtils {
  public static getWebSocketConfiguration(socketRelativePath?: string): InjectableRxStompConfig {
    const socketPath = socketRelativePath || '/ws/socket/ordering/websocket';
    const host = window.location.host;
    const protocol = window.location.protocol === 'http:' ? 'ws://' : 'wss://';
    return {
      brokerURL: `${protocol}${host}${socketPath}`,
      heartbeatIncoming: 0,
      heartbeatOutgoing: 20000,
      reconnectDelay: 200,
    };
  }
}
