import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService, OrderType, OrderTypeConfig } from 'ngx-web-api';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'orderTypeName',
})
export class OrderTypeNamePipe implements PipeTransform {
  constructor(private configService: ConfigService) {}

  transform(orderType: OrderType, args?: any): Observable<string> {
    return this.configService
      .findOrderTypeConfig(orderType)
      .pipe(map((config: OrderTypeConfig) => (config ? config.displayNameOrName : orderType)));
  }
}
