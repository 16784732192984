import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Coupon } from '../../models/core/coupon.model';
import { GenericObject } from '../../models/core/generic-object';
import { LateAwardEmailInfo } from '../../models/order-tracker/late-award-email-info.model';
import { OrderTrackerStatus } from '../../models/order-tracker/order-tracker-status.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { HypermediaService } from '../core/hypermedia.service';
import { ParkingSpot } from '../../models/order-tracker/parking-spot.model';

@Injectable({ providedIn: 'root' })
export class OrderTrackerService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering';
  private readonly TRACKER_PATH = `${this.BASE_PATH}/orderTracker`;

  constructor(private httpClient: HttpClient, private hypermediaService: HypermediaService) {}

  public getOrderStatus(id: string): Observable<OrderTrackerStatus> {
    const params: HttpParams = new HttpParams().set('order', id);

    return this.httpClient
      .get(`${this.TRACKER_PATH}/status`, { params })
      .pipe(map((json: Object) => DeserializationUtils.deserializeObj(json, OrderTrackerStatus)));
  }

  public issueRunningLateCoupon(orderId: string): Observable<Coupon> {
    const params: HttpParams = new HttpParams().set('order', orderId);

    return this.httpClient
      .get(`${this.TRACKER_PATH}/lateAward`, { params })
      .pipe(map((json: Object) => DeserializationUtils.deserializeObj(json, Coupon)));
  }

  public searchOrders(phone: string, orderNumber?: string): Observable<OrderTrackerStatus> {
    let params: HttpParams = new HttpParams().set('phone', phone);
    if (orderNumber) {
      params = params.set('orderNumber', orderNumber);
    }

    return this.httpClient
      .get(`${this.TRACKER_PATH}/query`, { params })
      .pipe(map((json: Object) => DeserializationUtils.deserializeObj(json, OrderTrackerStatus)));
  }

  public sendLateCouponEmail(data: LateAwardEmailInfo): Observable<string> {
    return this.httpClient
      .post<GenericObject<string>>(`${this.TRACKER_PATH}/lateAwardEmail`, data)
      .pipe(map((response: GenericObject<string>) => response['message']));
  }

  public getDriverPhotoURL(trackerStatus: OrderTrackerStatus): string {
    const driverPhotoLink = this.hypermediaService.findLink(trackerStatus.links, 'driverPhoto');
    return driverPhotoLink ? driverPhotoLink.href : '';
  }

  public getParkingSpots(): Observable<ParkingSpot[]> {
    return this.httpClient
      .get(`${this.BASE_PATH}/order/parkingSpots`)
      .pipe(map((json: Object[]) => DeserializationUtils.deserializeArray(json, ParkingSpot)));
  }
}
