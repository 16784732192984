"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsWallet";
var width = 514;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "m 513.05567,289.25361 h -24.28041 v -79.17526 0 0 c 0,-6.33402 -4.22268,-10.5567 -10.5567,-10.5567 h -10.5567 c 0,-1.05567 0,-2.11134 0,-3.16701 l -13.72372,-44.33815 h 25.33609 c 6.33402,0 10.5567,-4.22268 10.5567,-10.5567 0,-6.33402 -4.22268,-10.5567 -10.5567,-10.5567 H 447.60412 L 408.54433,7.3896907 C 407.48866,4.2226804 405.37732,2.1113402 403.26598,1.0556701 401.15464,0 397.98763,0 395.87629,0 L 48.560825,107.67835 c -3.167011,1.05567 -5.278351,3.16701 -6.334021,5.27835 -1.05567,2.11134 -1.05567,5.27835 -1.05567,8.44536 l 3.16701,9.50103 C 20.057732,130.90309 0,150.96082 0,175.24124 V 466.60619 C 0,490.8866 20.057732,512 44.338144,512 H 478.21856 c 6.33402,0 10.5567,-4.22268 10.5567,-10.5567 v -83.39794 h 24.28041 c 6.33402,0 10.5567,-4.22268 10.5567,-10.5567 V 299.81031 c 0,-6.33402 -5.27835,-10.5567 -10.5567,-10.5567 z M 391.65361,24.280412 443.38144,193.18763 420.1567,199.52165 H 87.620619 L 64.395876,124.56907 Z M 44.338144,152.01649 h 6.334021 l 14.779381,47.50516 H 44.338144 v 0 c -12.668041,0 -23.224742,-10.5567 -23.224742,-23.22474 0,-13.72371 10.556701,-24.28042 23.224742,-24.28042 z M 467.66186,489.83093 H 44.338144 c -12.668041,0 -23.224742,-10.5567 -23.224742,-24.28041 V 212.18969 c 7.389691,4.22268 14.779382,7.38969 23.224742,7.38969 H 467.66186 v 68.61856 h -67.56289 c -35.89278,0 -64.39588,28.50309 -64.39588,64.39587 0,35.89279 28.5031,64.39588 64.39588,64.39588 h 67.56289 z m 34.83711,-92.89897 H 401.15464 c -24.28041,0 -43.28248,-19.00206 -43.28248,-43.28248 0,-24.28041 19.00207,-43.28247 43.28248,-43.28247 h 101.34433 z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.faFtsWallet = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
