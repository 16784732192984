<div class="primary-bg-color main-wrapper" [ngClass]="{'colapsed':colapsed}">
  <div *ngIf="!colapsed" class="right-container">
    <div>
      <fts-loading-indicator class="selection-text-color spinner" *ngIf="!imageLoaded"></fts-loading-indicator>
      <img (load)="imageLoadedEvent()" [src]="imgSrc" alt="Coupons Image">
    </div>
  </div>
  <div class="left-container-wrapper" [ngClass]="{'left-container-wrapper-colapsed':colapsed}">
    <div class="left-container" [ngClass]="{'colapsed':colapsed}">
      <fa-icon class="selection-text-color" (click)="close()" [icon]="faXmark"></fa-icon>
      <div class="header">
        <span class="modal-title">
        {{translateService.instant('component.sms_sub_promo_modal.title')}}
        </span>
        <span class="coupon-title text-uppercase" [style.fontSize]="fontSize+'rem'">
          {{title}}
        </span>
        <span class="details primary-text-color">
          {{translateService.instant('component.sms_sub_promo_modal.purchase_info')}}
        </span>
      </div>
      <div class="main-content">

        <form [ftsAriaForm]="form" [formGroup]='form' #profileform="ngForm" novalidate>
          <div *ngIf="!isUserInstantiated" class="input-wrapper"> 
            <input [ngClass]="{'error': errors?.name?.length}" id="sms-modal-name" formControlName="name"  type="text" placeholder="Name" style="margin-top: 0 !important;">
            <div class="error-text-space-keep">
              <span class="error-text has-error" *ngFor="let error of errors?.name">{{error}}</span>
            </div>
          </div>
          <div *ngIf="!isUserInstantiated" class="input-wrapper"> 
            <input  [ngClass]="{'error': errors?.email?.length}" id="sms-modal-email" formControlName="email"  type="text" placeholder="Email">
            <div class="error-text-space-keep">
              <span class="error-text has-error" *ngFor="let error of errors?.email">{{error}}</span>
            </div>
          </div>
          <div class="input-wrapper"> 
            <input  [ngClass]="{'error': errors?.phone?.length}" id="sms-modal-phone" formControlName="phone"  [textMask]="{mask: phoneMask}" type="text" placeholder="Phone Number">
            <div class="error-text-space-keep">
              <span class="error-text has-error" *ngFor="let error of errors?.phone">{{error}}</span>
            </div>
          </div>
          <div *ngIf="isUserInstantiated && !account.phone" class="input-wrapper"> 
            <input  [ngClass]="{'error': errors?.password?.length}" id="sms-modal-password" formControlName="password"   type="password" placeholder="Current Password">
            <div class="error-text-space-keep">
              <span class="error-text has-error" *ngFor="let error of errors?.password">{{error}}</span>
            </div>
          </div>
        </form>

        <div class="fotter-wrapper" [ngClass]="{'fotter-wrapper-logedin':isUserInstantiated}">
          <div style="line-height: 0.9375rem;">
            <span class="disclamer primary-text-color">
              {{translateService.instant('component.sms_sub_promo_modal.disclamer')}}
            </span>
          </div>
          <button ftsAsyncButton [subscription]="loadingSub" (click)="submitClicked()" class="action-bg-color action-inverse-text-color submit-btn font-secondary">
            {{translateService.instant('component.sms_sub_promo_modal.subscribe_btn')}}
          </button>
          <button (click)="noThanksClicked()" class="primary-bg-color primary-text-color font-secondary">
            {{translateService.instant('component.sms_sub_promo_modal.no_thanks_btn')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>