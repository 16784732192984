import { BaseResource } from '../core/base-resource';
import { IngredientChoice } from './ingredient-choice.model';

export class IngredientChoiceGroup extends BaseResource {
  name: string;
  choices: string[];
  isExpanded?: boolean;
  ingredientChoices?: IngredientChoice[];
  selectedChoice?: IngredientChoice;
}
