import { IngredientChoicesType, MenuItemsLayout } from 'ngx-web-api';
import { PagePosition } from './page-position.enum';
import { AccountMenuStyle } from './account-menu-style.enum';
import { TimeslotChooserStyle } from '../shared/order-time-chooser/timeslot-chooser-style.enum';
import { DaySlotChooserStyle } from './day-slot-chooser-style';
import { LoyaltyBuilderImage } from './loyalty-builder-images.model';

export class Theme {
  menuItemsLayout: MenuItemsLayout;
  orderTreePosition: PagePosition;
  menuPosition: PagePosition;
  compactHeader: boolean;
  accountMenuStyle: AccountMenuStyle;
  ingredientChoicesType: IngredientChoicesType;
  menuIconsPosition: PagePosition;
  addToOrderUpfront: boolean;
  recentlyOrderedItemIcon: string;
  timeSlotChooserStyle: TimeslotChooserStyle;
  actionColor: string;
  daySlotChooserStyle: DaySlotChooserStyle;
  askForOrderTimeUpfront: boolean;
  actionInverseColor: string;
  active: boolean;
  bannerBlur: number;
  bannerOverlayOpacity: number;
  compactHeaderImageBlur: number;
  compactHeaderImageOverlayOpac: number;
  defaultTheme: boolean;
  errorMessageBackgroundColor: string;
  errorMessageTextColor: string;
  errorTextColor: string;
  fontPrimary: string;
  fontSecondary: string;
  fontTertiary: string;
  homePageOverlayOpacity: number;
  introHighlightColor: string;
  introHighlightInverseColor: string;
  name: string;
  logoAlignment: string;
  notificationMessageBgColor: string;
  notificationMessageTextColor: string;
  primaryBackgroundColor: string;
  primaryTextColor: string;
  secondaryBackgroundColor: string;
  secondaryColor: string;
  secondaryInverseColor: string;
  secondaryTextColor: string;
  selectionColor: string;
  selectionInverseColor: string;
  specialIndicatorColor: string;
  useCustomFonts: boolean;
  hideContactQuestionnaireLink: boolean;
  hideCareerQuestionnaireLink: boolean;
  skipIntroPage: boolean;
  hideIntroAsapNote: boolean;
  compactCardItems: boolean;
  hideCompactCardItemSizePrice: boolean;
  twoColumnedQuestionnaires: boolean;
  showCategoriesUpfront: boolean;
  hasSinglePageMenu: boolean;
  minifiedHeader: boolean;
  tagFilteringStyle: string;
  minifiedMenu: boolean;
  showSearchMenuLink: boolean;
  showItemEditorModal: boolean;
  hasTiledMenu: boolean;
  ratingStarsColor: string;
  loyaltyBuilder: boolean;
  loyaltyBuilderImages?: LoyaltyBuilderImage[];
  showItemImage: boolean;
  couponStyle: string;
  hideLoyaltyCouponInfo: boolean;
  showHalfAndHalfImages: boolean;
  private _shouldShowTiledMenu: boolean = false;

  get hasCardLayout(): boolean {
    return this.menuItemsLayout && this.menuItemsLayout === MenuItemsLayout.CardMenu;
  }

  get hasSlidingCardLayout(): boolean {
    return this.menuItemsLayout && this.menuItemsLayout === MenuItemsLayout.SlidingCardMenu;
  }

  get hasListLayout(): boolean {
    return this.menuItemsLayout && this.menuItemsLayout === MenuItemsLayout.ListMenu;
  }

  get hasFlatLayout(): boolean {
    return this.menuItemsLayout && this.menuItemsLayout === MenuItemsLayout.FlatMenu;
  }

  get hasTopOrderTree(): boolean {
    return this.orderTreePosition === PagePosition.Top;
  }

  get hasTopMenu(): boolean {
    return this.menuPosition === PagePosition.Top;
  }

  get hasCompactAccountMenu(): boolean {
    return this.accountMenuStyle === AccountMenuStyle.Compact;
  }

  get hasLeftMenuIcons(): boolean {
    return this.menuIconsPosition === PagePosition.Left;
  }

  get treeOrMenuOnTop(): boolean {
    return this.hasTopOrderTree || this.hasTopMenu;
  }

  get treeAndMenuOnTop(): boolean {
    return this.hasTopOrderTree && this.hasTopMenu;
  }

  get ingredientChoicesAsSteps(): boolean {
    return this.ingredientChoicesType === IngredientChoicesType.Steps;
  }

  get shouldShowCategoriesUpfront(): boolean {
    return this.showCategoriesUpfront && !this.minifiedMenu;
  }

  get shouldShowTiledMenu() {
    return this._shouldShowTiledMenu;
  }

  set shouldShowTiledMenu(show: boolean) {
    this._shouldShowTiledMenu = show;
  }

  get isExpandedHeader(): boolean {
    return !this.compactHeader && !this.minifiedHeader;
  }
}
