import { Injectable, Inject, Optional } from '@angular/core';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { StoreConfig } from '../../models/core/store-config.model';
import { IngredientChoice } from '../../models/menu/ingredient-choice.model';
import { OrderableIngredient } from '../../models/menu/orderable-ingredient.model';
import { ChoiceValidationResult } from '../../models/ordering/choice-validation-result.model';
import { OrderedChoice } from '../../models/ordering/ordered-choice.model';
import { OrderedIngredient } from '../../models/ordering/ordered-ingredient.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { ConfigService } from '../core/config.service';
import { TranslationWrapperService, TRANSLATION_SERVICE_TOKEN } from '../../models/ordering/translation-wrapper-service.model';

@Injectable({ providedIn: 'root' })
export class OrderedChoiceService {
  private halfPricePolicyAsWhole: boolean;
  private enforcedHalvesRoundDown: boolean;

  constructor(
    private configService: ConfigService,
    @Optional() @Inject(TRANSLATION_SERVICE_TOKEN) private translationService: TranslationWrapperService
  ) {
    this.configService.storeConfig$
      .pipe(
        filter(s => !!s),
        distinctUntilChanged()
      )
      .subscribe((storeConfig: StoreConfig) => {
        this.halfPricePolicyAsWhole = storeConfig.halfPricePolicyAsWhole;
        this.enforcedHalvesRoundDown = storeConfig.enforcedHalvesRoundDown;
      });
  }

  public createOrderedChoice(choice: IngredientChoice): OrderedChoice {
    return DeserializationUtils.deserializeObj(
      {
        name: choice.name,
        ingredients: [],
        removedDefaults: [],
        removedEverythings: [],
      },
      OrderedChoice
    );
  }

  public getIngredient(orderedChoice: OrderedChoice, ingredient: OrderableIngredient): OrderedIngredient | undefined {
    return (orderedChoice.ingredients || []).find(i => i.ingredient === ingredient.name);
  }

  /**
   * Validate an ordered choice against its corresponding orderable choice.
   * @returns an object containing all the information of the validation. Info include
   * a short and more detailed message, whether the choice is valid and all information needed to create a custom message.
   */
  public validateChoice(
    orderedChoice: OrderedChoice,
    orderableChoice: IngredientChoice,
    size?: string,
    isHalfAndHalf: boolean = false
  ): ChoiceValidationResult {
    const choiceName = orderableChoice.name;
    const enforcedIngredients = orderableChoice.enforcedIngredients;
    const maxAllowedIngredients = orderableChoice.maxIngredients;
    const howManyIngredientsCount = (orderedChoice.ingredients || []).length;
    const enforcedIngredientsCount = this.getEnforcedIngredientsCount(orderedChoice, orderableChoice);
    const result = new ChoiceValidationResult();
    const availableIngredientsCount = orderableChoice.ingredients.filter(i => i.sizePrices.find(sp => sp.size === size)).length;

    if (size && this.hasInvalidIngredients(orderedChoice, orderableChoice, size)) {
      result.valid = false;
      result.hasInvalidIngredients = true;
      result.shortMessage = !!this.translationService
        ? this.constructValidationMessage('component.choice_validation_messages.unavailable_short')
        : 'Some of the selected ingredients are unavailable';
      result.longMessage = !!this.translationService
        ? this.constructValidationMessage('component.choice_validation_messages.unavailable_long')
        : 'Some of the selected ingredients are unavailable';
    } else if (
      maxAllowedIngredients > 0 &&
      enforcedIngredients > 0 &&
      maxAllowedIngredients === enforcedIngredients &&
      howManyIngredientsCount !== maxAllowedIngredients &&
      (!size || availableIngredientsCount >= enforcedIngredients)
    ) {
      result.valid = false;
      result.exactIngredients = maxAllowedIngredients;
      result.currentIngredients = howManyIngredientsCount;
      if (!!this.translationService) {
        result.shortMessage =
          maxAllowedIngredients > 1
            ? this.constructValidationMessage(
                'component.choice_validation_messages.exactly_short_plural',
                choiceName,
                maxAllowedIngredients
              )
            : this.constructValidationMessage(
                'component.choice_validation_messages.exactly_short_singular',
                choiceName,
                maxAllowedIngredients
              );
        result.longMessage = this.constructValidationMessage(
          'component.choice_validation_messages.exactly_long',
          choiceName,
          maxAllowedIngredients
        );
      } else {
        result.shortMessage = `${maxAllowedIngredients} Choice${maxAllowedIngredients > 1 ? 's' : ''} Required`;
        result.longMessage = `You must choose exactly ${maxAllowedIngredients} ${choiceName}`;
      }
    } else if (maxAllowedIngredients > 0 && howManyIngredientsCount > maxAllowedIngredients) {
      result.valid = false;
      result.maxIngredients = maxAllowedIngredients;
      result.currentIngredients = howManyIngredientsCount;
      if (!!this.translationService) {
        result.shortMessage =
          maxAllowedIngredients > 1
            ? this.constructValidationMessage(
                isHalfAndHalf
                  ? 'component.choice_validation_messages.most_short_plural_half_and_half'
                  : 'component.choice_validation_messages.most_short_plural',
                choiceName,
                maxAllowedIngredients
              )
            : this.constructValidationMessage(
                'component.choice_validation_messages.most_short_singular',
                choiceName,
                maxAllowedIngredients
              );
        result.longMessage = this.constructValidationMessage(
          'component.choice_validation_messages.most_long',
          choiceName,
          maxAllowedIngredients
        );
      } else {
        result.shortMessage = `${maxAllowedIngredients} Choice${maxAllowedIngredients > 1 ? 's' : ''} At Most`;
        result.longMessage = `You must choose at most ${maxAllowedIngredients} ${choiceName}`;
      }
    } else if (
      enforcedIngredients > 0 &&
      enforcedIngredientsCount < enforcedIngredients &&
      (!size || availableIngredientsCount >= enforcedIngredients)
    ) {
      result.valid = false;
      result.minIngredients = enforcedIngredients;
      result.currentIngredients = enforcedIngredientsCount;
      if (!!this.translationService) {
        result.shortMessage =
          enforcedIngredients > 1
            ? this.constructValidationMessage(
                'component.choice_validation_messages.least_short_plural',
                choiceName,
                null,
                enforcedIngredients
              )
            : this.constructValidationMessage(
                'component.choice_validation_messages.least_short_singular',
                choiceName,
                null,
                enforcedIngredients
              );
        result.longMessage = this.constructValidationMessage(
          'component.choice_validation_messages.least_long',
          choiceName,
          null,
          enforcedIngredients
        );
      } else {
        result.shortMessage = `${enforcedIngredients} Choice${enforcedIngredients > 1 ? 's' : ''} At Least`;
        result.longMessage = `You must choose at least ${enforcedIngredients} ${choiceName}`;
      }
    } else if (orderableChoice.strictSubstitutions) {
      const removedDefaultsCount =
        orderableChoice.ingredients.filter(i => i.isDefault).length - orderedChoice.ingredients.filter(i => i.isDefault).length;
      if (removedDefaultsCount > orderableChoice.maxSubstitutions) {
        result.valid = false;
        result.shortMessage = `Max ${orderableChoice.maxSubstitutions} substitutions`;
        result.longMessage = `You can substitute at most ${orderableChoice.maxSubstitutions} default ingredients`;
      }
    }

    return result;
  }

  public getEnforcedIngredientsCount(orderedChoice: OrderedChoice, orderableChoice: IngredientChoice): number {
    let weight = (orderedChoice.ingredients || [])
      .map(orderedIngredient =>
        this.calcIngredientWeight(
          orderedIngredient,
          orderableChoice.ingredients.find(ing => ing.name === orderedIngredient.ingredient)
        )
      )
      .reduce((a, b) => a + b, 0);

    if (!this.enforcedHalvesRoundDown) {
      weight += 0.5;
    }

    return Math.floor(weight);
  }

  public calcIngredientWeight(orderedIngredient: OrderedIngredient, orderableIngredient?: OrderableIngredient): number {
    let weight = this.calcQualifierWeight(orderedIngredient, orderableIngredient);
    weight += orderedIngredient.secondHalfQualifiers?.length ? this.calcQualifierWeight(orderedIngredient, orderableIngredient, true) : 0;

    if (orderableIngredient && (orderedIngredient.isLeftHalf || orderedIngredient.isRightHalf) && !this.halfPricePolicyAsWhole) {
      weight /= 2.0;
    }

    return weight;
  }

  public calcQualifierWeight(
    orderedIngredient: OrderedIngredient,
    orderableIngredient?: OrderableIngredient,
    isSecondHalf?: boolean
  ): number {
    let weight = 0;

    if (orderableIngredient) {
      if (!orderableIngredient.isDefault || orderableIngredient.replaceable) {
        weight += orderableIngredient.weight;
      }

      const orderedQualifiers = isSecondHalf ? orderedIngredient.secondHalfQualifiers : orderedIngredient.qualifiers;
      if (!!orderedQualifiers) {
        weight += orderedQualifiers
          .map(q => orderableIngredient.qualifiers.find(oQualifier => oQualifier.name === q))
          .map(q => (q ? q.priceFactor : 0))
          .reduce((a, b) => a + b, 0);
      }
    }
    return weight;
  }

  public hasInvalidIngredients(orderedChoice: OrderedChoice, orderableChoice: IngredientChoice, size?: string): boolean {
    if (size) {
      return (orderedChoice.ingredients || [])
        .map(orderedIngredient => orderableChoice.ingredients.find(i => i.name === orderedIngredient.ingredient))
        .some(i => !!i && !i.getSizePrice(size));
    }
    return false;
  }

  private constructValidationMessage(key: string, choiceName = '', maxAllowedIngredients = 0, enforcedIngredients = 0): string {
    return this.translationService.translate(key, {
      choiceName: choiceName,
      maxAllowedIngredients: maxAllowedIngredients,
      enforcedIngredients: enforcedIngredients,
    });
  }
}
