import { DeserializationUtils } from '../../utils/deserialization-utils';
import { Utils } from '../../utils/utils';
import { BaseResource } from '../core/base-resource';
import { MenuItemsLayout } from './menu-items-layout.enum';
import { PanelItem } from './panel-item.model';
import { SizePrice } from './size-price.model';
import { Tag } from './tag.model';

export class Group extends BaseResource {
  name: string;
  description: string;
  ordinal: number;
  primaryItems: number;
  secondaryItems: number;
  minimumPrice: number;
  layout: MenuItemsLayout;
  hasSpecials?: boolean;
  panelItems: PanelItem[];
  tags: Tag[];

  get sizePrices(): SizePrice[] {
    return Utils.flatten(this.panelItems.map(cat => cat.sizePrices))
      .filter(sizePrice => !!sizePrice)
      .filter((sizePrice, index, sizePrices) => sizePrices.findIndex(sp => sp.size === sizePrice.size) === index);
  }

  get hasItems(): boolean {
    return this.panelItems.some(panelItem => panelItem.isItem);
  }

  get hasCardLayout(): boolean {
    return this.layout === MenuItemsLayout.CardMenu;
  }

  /** @deprecated. Table layout is no longer supported. */
  get hasTableLayout(): boolean {
    return this.layout === MenuItemsLayout.TableMenu;
  }

  get hasListLayout(): boolean {
    return this.layout === MenuItemsLayout.ListMenu;
  }

  get hasFlatLayout(): boolean {
    return this.layout === MenuItemsLayout.FlatMenu;
  }

  get hasSlidingCardLayout(): boolean {
    return this.layout === MenuItemsLayout.SlidingCardMenu;
  }

  get hasOnlyFundraisers(): boolean {
    return this.panelItems.some(item => item.isFundraiser);
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      panelItems: DeserializationUtils.deserializeChildren(input, 'panelItems', PanelItem),
      tags: DeserializationUtils.deserializeChildren(input, 'tags', Tag),
    });
  }
}
