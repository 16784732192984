import { BaseResource } from '../core/base-resource';

export class LoyaltyNotification extends BaseResource {
  readonly name: string;
  readonly description: string;
  readonly editable: boolean;
  checked: boolean;

  serialize(): any {
    return {
      name: this.name,
      checked: this.checked,
    };
  }
}
