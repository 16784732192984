"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsCompass";
var width = 443.81;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "m221.9 0c-31.555 0-57.001 25.447-57.001 57.001 0 6.1074 1.0175 11.197 3.0533 17.304-97.718 24.429-167.95 114-167.95 214.78 0 123.16 99.754 222.92 221.9 222.92 123.16 0 222.92-99.754 221.9-221.9 0-101.79-70.235-191.36-167.95-215.79 2.0358-5.0895 3.0533-11.197 3.0533-17.304 0-31.555-25.447-57.001-57.001-57.001zm0 29.519c15.268 0 27.484 12.214 27.484 27.482 0 4.0716-1.0195 8.1437-3.0553 12.215-18.322-2.0358-30.537-2.0358-48.859 0-2.0358-4.0716-3.0533-8.1437-3.0533-12.215 0-15.268 12.215-27.482 27.484-27.482zm-15.269 68.199v33.591c0 8.1431 7.1254 15.269 15.269 15.269 8.1431 0 15.269-7.1255 15.269-15.269v-33.591c94.664 7.1252 168.97 81.431 176.09 176.09h-33.591c-8.1431 0-15.269 7.1255-15.269 15.269s7.1255 15.269 15.269 15.269h33.591c-7.1252 94.664-81.431 168.97-176.09 176.09v-33.591c0-8.1431-7.1255-15.269-15.269-15.269-8.1431 0-15.269 7.1255-15.269 15.269v33.591c-94.664-7.1252-168.97-81.431-176.09-176.09h33.591c8.1431 0 15.269-7.1255 15.269-15.269s-7.1255-15.269-15.269-15.269h-33.591c7.1252-94.664 81.431-168.97 176.09-176.09zm127.2 65.462c-2.3618 0-4.7951.57396-7.0854 1.7191l-146.58 76.341-3.0533 3.0533c-1.0179 1.0179-1.0176 1.0177-2.0355 2.0356l-77.359 147.6c-3.0537 5.0895-2.0361 12.215 3.0533 17.304 2.0358 2.0358 6.1062 4.0711 10.178 4.0711 3.0537 0 5.0905.00011 8.1442-1.0178l145.56-76.341v-1.0178c1.0179 0 2.0374-1.0196 3.0553-2.0375l2.0355-2.0356 77.359-147.59c3.0537-5.0895 2.0362-12.215-3.0533-17.304-2.5447-3.1809-6.2824-4.7724-10.219-4.7724zm-35.587 50.576-44.786 85.503-40.717-40.715zm-106.88 66.163 40.717 40.717-85.503 44.786z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.faFtsCompass = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
