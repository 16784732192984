export enum QuestionType {
  YES_NO = 'Yes No',
  TEXT = 'Text',
  LONG_TEXT = 'Long Text',
  OPTION_DROP_DOWN = 'Option - Drop Down',
  OPTION = 'Option',
  DATE = 'Date',
  DAY_OF_YEAR = 'Day Of Year',
  RATING = 'Rating',
  SCORE = 'Score',
  CHECK = 'Check',
  RECAPTCHA = 'ReCaptcha',
  AUTOCOMPLETE = 'Autocomplete',
  MULTIPLE_CHOICE = 'Multiple Choice',
}
