import { CwoService } from './cwo.service';
import { Injectable } from '@angular/core';
import { SessionStorageKeys } from '../../domain/session-storage-keys.enum';
import { TransientLocalStorageKeys, PersistentLocalStorageKeys } from '../../domain/local-storage-keys.enum';
import { CookiePersistentKeys, RedirectKeys } from 'ngx-web-api';

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageHandlerService {
  private readonly STORE_ID_KEY = 'storeId';
  private storeName: string;

  constructor(private cwoService: CwoService) {}

  public initStoreIdStorageKey() {
    this.storeName = this.getStoreName();

    if (this.cwoService.storeName !== this.storeName) {
      this.deleteLocalBrowserStorageKeysPerStore();
      this.setStoreName(this.cwoService.storeName);
      this.storeName = this.cwoService.storeName;
    }
  }

  public setSessionStorageItem(keyName: SessionStorageKeys | string, value?: any | undefined): void {
    if (value === undefined) {
      sessionStorage.removeItem(this.resolveKeyName(keyName));
      return;
    }
    sessionStorage.setItem(this.resolveKeyName(keyName), value);
  }

  public getSessionStorageItem(keyName: SessionStorageKeys | string): string {
    return sessionStorage.getItem(this.resolveKeyName(keyName));
  }

  public setLocalStorageItem(
    keyName: TransientLocalStorageKeys | PersistentLocalStorageKeys | RedirectKeys | string,
    value?: any | undefined
  ): void {
    if (value === undefined) {
      localStorage.removeItem(this.resolveKeyName(keyName));
      return;
    }
    localStorage.setItem(this.resolveKeyName(keyName), value);
  }

  public getLocalStorageItem(keyName: TransientLocalStorageKeys | PersistentLocalStorageKeys | RedirectKeys | string): string {
    return localStorage.getItem(this.resolveKeyName(keyName));
  }

  // If key name is part of the persistent keys then add a suffix, otherwise return as is
  public resolveKeyName(keyName: TransientLocalStorageKeys | PersistentLocalStorageKeys | RedirectKeys | string) {
    return keyName in PersistentLocalStorageKeys || keyName in CookiePersistentKeys ? `${keyName}__${this.storeName}` : keyName;
  }

  private getStoreName() {
    return this.getSessionStorageItem(this.STORE_ID_KEY);
  }

  private setStoreName(storeId: string) {
    this.setSessionStorageItem(this.STORE_ID_KEY, storeId);
  }

  private deleteLocalBrowserStorageKeysPerStore() {
    const localStorageKeys = /PANEL_|ITEM_|FEATURED_|STORE_LOGO_|ACCOUNT_UPDATED|ACCOUNT_SIGNED_IN_OUT/;
    Object.keys(localStorage).forEach(key => {
      if (localStorageKeys.test(key)) {
        localStorage.removeItem(key);
      }
    });
    sessionStorage.clear();
  }
}
