import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  HostListener,
} from '@angular/core';
import { StoreConfig, Order, LoyaltyPlan, Account, ConfigService, Utils, Panel, FeaturedContent } from 'ngx-web-api';
import { faFtsAccount } from '../../../shared/custom-fa-icons/faFtsAccount';
import { faFtsCart } from '../../../shared/custom-fa-icons/faFtsCart';
import { faFtsStore } from '../../../shared/custom-fa-icons/faFtsStore';
import { Theme } from '../../../domain/theme.model';
import { BreakpointService } from '../../services/breakpoint.service';
import { MobileMenu } from '../../../domain/mobile-menu.enum';
import { UiOrchestratorService } from '../../services/ui-orchestrator.service';
import { takeUntil, filter, shareReplay } from 'rxjs/operators';
import { Subject, Subscription, Observable, combineLatest } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { FocusService } from '../../../focus.service';
import { Router, NavigationEnd } from '@angular/router';
import { PathService } from '../../../shared/services/path.service';
import { Animations } from '../../../animations';
import { MenuWrapperService } from '../../services/menu-wrapper.service';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faStore } from '@fortawesome/pro-solid-svg-icons';
@Component({
  selector: 'fts-minified-header',
  templateUrl: './minified-header.component.html',
  styleUrls: ['./minified-header.component.scss'],
  animations: [Animations.expanded],
})
export class MinifiedHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  store: StoreConfig;
  @Input()
  order: Order;
  @Input()
  account: Account;
  @Input()
  theme: Theme;
  @Input()
  loyaltyPlan: LoyaltyPlan;
  @Input()
  hasMultipleOrderTypeConfigs: boolean;
  @Input()
  isInIntro: boolean;
  @Input()
  isInHome: boolean;
  @Input()
  isSelfOrder: boolean;
  @Input()
  isInEditor: boolean;
  @Input()
  isInCheckout: boolean;
  @Input()
  visibleMenu?: MobileMenu;
  @Input()
  panel: Panel;
  @Input()
  featuredContents: FeaturedContent[];

  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onToggleMobileMenu: EventEmitter<MobileMenu> = new EventEmitter();

  faFtsAccount = faFtsAccount;
  faFtsCart = faFtsCart;
  faFtsStore = faFtsStore;
  menus = MobileMenu;
  private destroy$: Subject<void> = new Subject<void>();
  mobileOrderMenu: any;
  orderNotification: any;
  loadingStoreStates: Subscription;
  private isMobile: boolean;
  isInThanksPage$: Observable<boolean>;
  openSideBarMenu = false;
  isInLogin = false;
  isInCheckoutDetails = false;
  isInTrackerPage$: Observable<boolean>;
  showMenuBtn = false;
  faChevronLeft = faChevronLeft;
  isSmallDesktop = false;
  faStore = faStore;
  shouldRenderOverlayMenu = false;
  showMinifiedMenu = false;
  mobileMenuHeight$: Observable<number>;
  mobileMenuTopPosition$: Observable<number>;
  contentMobileMenuHeight$: Observable<number>;
  orderTreeIsOpen = false;

  constructor(
    public breakpointService: BreakpointService,
    private uiOrchestrationService: UiOrchestratorService,
    private modalService: ModalService,
    private configService: ConfigService,
    private cdRef: ChangeDetectorRef,
    private focusService: FocusService,
    private pathService: PathService,
    private router: Router,
    public menuWrapperService: MenuWrapperService
  ) {
    this.isInThanksPage$ = this.pathService.inThanks$;
    this.isInTrackerPage$ = this.pathService.inTracker$;
    if (this.pathService.inOrderRelatedRoutes()) {
      this.importLazyComponents();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenWidth();
  }

  ngOnInit(): void {
    this.mobileMenuHeight$ = this.uiOrchestrationService.mobileMenuHeight$;
    this.mobileMenuTopPosition$ = this.uiOrchestrationService.mobileMenuTopPosition$;
    this.contentMobileMenuHeight$ = this.uiOrchestrationService.contentMobileMenuHeight$;

    this.checkScreenWidth();
    this.breakpointService.isTabletDown$.pipe(takeUntil(this.destroy$)).subscribe(isMobile => {
      this.isMobile = isMobile;
      this.calcShouldRenderOverlayMenu();
    });

    this.uiOrchestrationService.orderTreeShown$
      .pipe(takeUntil<void>(this.destroy$))
      .subscribe(() => this.toggleMobileMenu(this.menus.orderTree));

    combineLatest([this.pathService.inMenu$, this.uiOrchestrationService.orderTreeIsOpen$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([inMenu, isOpen]) => {
        this.orderTreeIsOpen = isOpen;
        if (!(this.theme.hasSinglePageMenu && inMenu)) {
          if (isOpen && this.isMobile) {
            document.querySelector(`body`).classList.add('mobile-overlay');
          } else {
            document.querySelector(`body`).classList.remove('mobile-overlay');
          }
        }
        this.cdRef.detectChanges();
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((val: NavigationEnd) => {
        this.isInIntro = val.urlAfterRedirects.split('?')[0] === '/intro';
        this.isInCheckoutDetails = val.url === '/checkout/details';
        this.isInLogin = val.urlAfterRedirects.split('?')[0] === '/login';
        if (this.pathService.inOrderRelatedRoutes(val.url)) {
          this.importLazyComponents();
        }
      });

    this.uiOrchestrationService.showMinifiedMenu$.pipe(shareReplay(), takeUntil(this.destroy$)).subscribe(showMinifiedMenu => {
      this.showMinifiedMenu = showMinifiedMenu;
      this.cdRef.detectChanges();
      this.calcShouldRenderOverlayMenu();
    });

    this.uiOrchestrationService.showMenuBtn$.pipe(takeUntil(this.destroy$)).subscribe(showMenuBtn => {
      this.showMenuBtn = showMenuBtn;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const visibleMenuChange = changes['visibleMenu'];
    if (!visibleMenuChange) {
      this.uiOrchestrationService.setMobileMenuChanged(true);
      return;
    }

    this.calcShouldRenderOverlayMenu();

    if (visibleMenuChange && !Utils.isNullOrUndefined(visibleMenuChange.currentValue) && this.isMobile) {
      setTimeout(() => {
        this.focusService.applyFocus('overlay-menu');
      }, 0);
    }

    if (visibleMenuChange.currentValue === MobileMenu.storeChooser) {
      this.openStoreChooser();
    }

    if (visibleMenuChange.previousValue === MobileMenu.storeChooser) {
      this.closeStoreChooser();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleAccountIconClicked() {
    if (this.account.isInstantiated || this.isInLogin) {
      this.openSideBarMenu = true;
    } else {
      this.login.emit();
    }
  }

  handleOverlayClick() {
    this.onToggleMobileMenu.next(undefined);
  }

  toggleStoreChooser() {
    this.onToggleMobileMenu.next(MobileMenu.storeChooser);
  }

  toggleMobileMenu(visibleMenu: MobileMenu) {
    this.onToggleMobileMenu.next(visibleMenu);
  }

  expandedAnimationEnd() {
    this.uiOrchestrationService.setOrderTreeAnimationEnd();
  }

  private closeStoreChooser() {
    if (this.loadingStoreStates && !this.loadingStoreStates.closed) {
      this.loadingStoreStates.unsubscribe();
    }
    this.uiOrchestrationService.setStoreStates(null);
  }

  private openStoreChooser() {
    this.uiOrchestrationService.storeChooseModalTriggered();
    if (!this.store.hasStoreChooser) {
      this.uiOrchestrationService.setStoreStates([]);
      return;
    }
    this.loadingStoreStates = this.configService
      .fetchStoreStates()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (ss: string[]) => {
          this.uiOrchestrationService.setStoreStates(ss);
        },
        e => this.modalService.parseAndNotifyErrors(e)
      );
  }

  private importLazyComponents() {
    if (!this.mobileOrderMenu) {
      this.mobileOrderMenu = import(`../mobile-order-menu/mobile-order-menu.component`).then(
        ({ MobileOrderMenuComponent }) => MobileOrderMenuComponent
      );
    }
    if (!this.orderNotification) {
      this.orderNotification = import(`../../../shared/order-notification/order-notification.component`).then(
        ({ OrderNotificationComponent }) => OrderNotificationComponent
      );
    }
  }

  private calcShouldRenderOverlayMenu(): void {
    this.shouldRenderOverlayMenu =
      this.visibleMenu !== undefined &&
      this.visibleMenu !== this.menus.storeChooser &&
      !(this.visibleMenu === this.menus.orderTree && !this.isMobile);
  }

  private checkScreenWidth() {
    this.isSmallDesktop = window.innerWidth < 1100;
  }
}
