import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FeaturedItem, Picture } from 'ngx-web-api';
import { MenuWrapperService } from '../../../../core/services/menu-wrapper.service';

@Component({
  selector: 'fts-featured-item-fading',
  templateUrl: './featured-item-fading.component.html',
  styleUrls: ['./featured-item-fading.component.scss'],
  animations: [
    trigger('display', [
      state('hidden', style({ opacity: 0, zIndex: 0 })),
      state('visible', style({ opacity: 1, zIndex: 1 })),
      state('void', style({ opacity: 0 })),
      transition('* <=> *', animate('1s')),
    ]),
  ],
})
export class FeaturedItemFadingComponent implements OnInit {
  @Input()
  fillParentHeight: boolean;
  @Input()
  featuredItem: FeaturedItem;
  @Input()
  state: string;
  @Input()
  skipLazyLoading: boolean;
  @Input()
  itemImgHeight: number;
  @Output()
  heightChange: EventEmitter<number> = new EventEmitter();
  @ViewChild('imgEl', { static: true })
  private imageElement: ElementRef;

  featuredItemPicture: Picture;

  constructor(private menuWrapperService: MenuWrapperService) {}

  ngOnInit() {
    this.featuredItemPicture = this.menuWrapperService.getPicture(this.featuredItem.pictures);
  }

  imgLoaded() {
    this.heightChange.emit(this.imageElement.nativeElement.clientHeight);
  }
}
