import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ViewPort } from '../../domain/view-port.model';

@Injectable({
  providedIn: 'root',
})
export class ViewportService {
  private viewport: BehaviorSubject<ViewPort> = new BehaviorSubject(new ViewPort());
  public viewport$: Observable<ViewPort> = this.viewport.asObservable();

  checkSize(window) {
    const viewport = new ViewPort();
    viewport.isMobile = window.innerWidth <= 767;
    viewport.isTablet = window.innerWidth <= 991 && !viewport.isMobile;
    viewport.isDesktop = !viewport.isMobile && !viewport.isTablet;
    viewport.isAbove1200pixels = window.innerWidth >= 1200;
    this.viewport.next(viewport);
  }

  /**
   * Detect if user is on a mobile device browser
   * See following link for more details :
   * https://stackoverflow.com/a/21742107/2025271
   **/
  isOpenInMobileBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    return (
      /windows phone/i.test(userAgent) ||
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
}
