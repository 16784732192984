import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'fts-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss'],
})
export class StepIndicatorComponent implements OnChanges {
  @Input()
  numberOfSteps: number;
  @Input()
  currentStep: number;
  @Output()
  currentStepChange: EventEmitter<number> = new EventEmitter();
  @Output()
  stopStepIndicator: EventEmitter<void> = new EventEmitter();
  @Output()
  startStepIndicator: EventEmitter<void> = new EventEmitter();

  steps: number[] = [];
  featuredItemsButtonLabels: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['numberOfSteps']) {
      this.steps = Array.from({ length: this.numberOfSteps }, (_v, k) => k);
    }
  }

  selectStep(step) {
    this.stopStepIndicator.emit();
    this.currentStepChange.emit(step);
  }
}
