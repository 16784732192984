import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Theme } from 'app/domain/theme.model';
import { ViewportService } from 'app/core/services/viewport.service';
import { SlugifyPipe } from 'app/shared/pipes/slugify.pipe';
import { UiOrchestratorService } from '../../../core/services/ui-orchestrator.service';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { interval, Subject } from 'rxjs';
import { MenuWrapperService } from 'app/core/services/menu-wrapper.service';
import { SocialLinksService } from 'app/core/services/social-links.service';
import { OperatingSystem } from 'app/domain/operating-system.enum';
import { FocusService } from 'app/focus.service';
import { ScrollEventService } from 'app/shared/scroll-event.service';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { CwoService } from 'app/core/services/cwo.service';

@Component({
  selector: 'fts-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss'],
})
export class NavLinkComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  theme: Theme;
  @Input()
  index: number;
  @Input()
  ngRouterLink: string;
  @Input()
  tabName: string;
  @Input()
  tabIcon: string;
  @Input()
  hasLeftMenuIcons: boolean;
  @Input()
  showTabIcons: boolean;
  @Input()
  isUpFrontMenu = false;
  @Input()
  isInMinifiedMenu = false;
  @Input()
  isSearchLink = false;
  @Input()
  isTiledMenuIndexLink = false;
  @Output()
  onTabClick: EventEmitter<void> = new EventEmitter<void>();

  faSearch = faSearch;
  faChevronLeft = faChevronLeft;
  isActive = false;
  navActionGiven = false;
  private slugifyPipe = new SlugifyPipe();
  private destroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public viewportService: ViewportService,
    public uiOrchestrationService: UiOrchestratorService,
    private menuService: MenuWrapperService,
    private socialLinksService: SocialLinksService,
    private focusService: FocusService,
    private scrollEventService: ScrollEventService,
    private navLinkRef: ElementRef,
    private cwoService: CwoService
  ) {
    this.uiOrchestrationService.navActionGivenOnLoad$.subscribe(value => (this.navActionGiven = value));
  }

  ngOnInit() {
    if (!this.theme.hasSinglePageMenu) {
      this.checkIfActive();
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.checkIfActive();
      });
    } else {
      const fragmentId = this.slugifyPipe.transform(this.tabName);
      this.menuService.activeTabId$
        .pipe(takeUntil(this.destroy$))
        .subscribe(id => ((this.isActive = id === fragmentId), this.scrollMinifiedMenu()));
      this.route.fragment.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.isActive = this.isSearchLink ? location.pathname.includes(`menu/search`) : location.hash === `#${fragmentId}`;
        this.scrollMinifiedMenu();
      });
    }
  }

  ngAfterViewInit() {
    this.scrollMinifiedMenu();
    interval(1000)
      .pipe(take(3))
      .subscribe(() => {
        if (!this.navActionGiven) {
          this.scrollMinifiedMenu();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  tabClick() {
    this.onTabClick.emit();
  }

  navigateToTab() {
    if (this.theme.hasSinglePageMenu && !this.isSearchLink && !this.router.url.includes('/menu/search')) {
      if (this.theme.shouldShowTiledMenu) {
        this.uiOrchestrationService.setTiledMenuLinks(false);
        if (this.isTiledMenuIndexLink) {
          this.onTabClick.emit();
          return;
        }
      }
      const url = this.ngRouterLink.split('#');
      this.menuService.setActiveTabId(url[1]);
      this.scrollEventService.onHashNavigation();
      location.assign(`${this.cwoService.baseHref}${url[0]}#${url[1]}`);
      // Fix for Android TalkBack which does not focus on the section we need
      if (this.socialLinksService.getOperatingSystem() === OperatingSystem.android) {
        setTimeout(() => this.focusService.applyFocus(url[1]));
      }
    } else {
      this.router.navigateByUrl(this.ngRouterLink);
    }

    this.onTabClick.emit();
  }

  private checkIfActive() {
    const urlTree = this.router.parseUrl(this.router.url);
    const urlWithoutParams = urlTree.root.children['primary']?.segments.map(it => it.path).join('/');
    this.isActive = urlWithoutParams === decodeURIComponent(this.ngRouterLink);
    this.scrollMinifiedMenu();
  }

  private scrollMinifiedMenu() {
    if (!!this.navLinkRef && this.isInMinifiedMenu && this.isActive) {
      const btnRefRect: DOMRect = this.navLinkRef.nativeElement.getBoundingClientRect();
      const btnRef: HTMLElement = this.navLinkRef.nativeElement;
      const parentRef: HTMLElement = btnRef.parentElement;
      if (!parentRef) {
        return;
      }
      if (btnRefRect.x + btnRefRect.width > window.innerWidth - 50 || btnRefRect.x < 50) {
        parentRef.scrollTo({ left: btnRef.offsetLeft - (window.innerWidth / 2 - btnRef.offsetWidth / 2), top: 0, behavior: 'smooth' });
      }
    }
  }
}
