export enum BootstrapBreakpoints {
  XS = '(max-width: 575.98px)',

  SM_MIN = '(min-width: 576px)',
  SM_MAX = '(max-width: 767.98px)',
  SM = '(min-width: 576px) and  (max-width: 767.98px)',

  MD_MIN = '(min-width: 768px)',
  MD_MAX = '(max-width: 991.98px)',
  MD = '(min-width: 768px) and (max-width: 991.98px)',

  LG_MIN = '(min-width: 992px)',
  LG_MAX = '(max-width: 1199.98px)',
  LG = '(min-width: 992px) and (max-width: 1199.98px)',

  XL = '(min-width: 1200px)',
}
