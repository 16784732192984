import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Account } from '../../models/account/account.model';
import { CreditCard } from '../../models/core/credit-card.model';
import { StoreConfig } from '../../models/core/store-config.model';
import { PaymentSetup } from '../../models/ordering/payment-setup';
import { CreditCardMaskPipe } from '../../pipes/credit-card-mask.pipe';
import { AuditService } from '../core/audit.service';
import { ConfigService } from '../core/config.service';
import { AccountService } from './account.service';
import { PaymentSetupRequest } from '../../models/ordering/payment-setup-request.model';
import { PaymentSetupType } from '../../models/ordering/payment-setup-type.enum';

@Injectable({ providedIn: 'root' })
export class AccountCreditCardsService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/account/creditCards';
  private reloadCreditCards: Subject<void> = new Subject<void>();

  private creditCards: BehaviorSubject<CreditCard[]> = new BehaviorSubject([]);
  public creditCards$: Observable<CreditCard[]> = this.creditCards.asObservable();

  constructor(
    private http: HttpClient,
    private auditService: AuditService,
    private creditCardMask: CreditCardMaskPipe,
    private accountService: AccountService,
    private configService: ConfigService
  ) {
    this.initCreditCardsLoader();
  }

  public addCreditCard(creditCard: CreditCard): Observable<CreditCard> {
    return this.http.post(this.BASE_PATH, creditCard.serialize()).pipe(
      map(json => new CreditCard().deserialize(json)),
      tap((cc: CreditCard) => this.auditCreditCardAddition(cc)),
      tap(() => this.reloadCreditCards.next())
    );
  }

  public updateCreditCard(creditCard: CreditCard): Observable<CreditCard> {
    return this.http.put(`${this.BASE_PATH}/${creditCard.ordinal}`, creditCard.serialize()).pipe(
      map(json => new CreditCard().deserialize(json)),
      tap((cc: CreditCard) => this.auditCreditCardUpdate(cc)),
      tap(() => this.reloadCreditCards.next())
    );
  }

  public deleteCreditCard(creditCard: CreditCard): Observable<any> {
    return this.http.delete(`${this.BASE_PATH}/${creditCard.ordinal}`).pipe(
      tap(() => this.auditCreditCardRemoval(creditCard)),
      tap(() => this.reloadCreditCards.next())
    );
  }

  public setupWorldpay(isMobileSource?: boolean, recaptcha?: string): Observable<PaymentSetup> {
    const paymentSetupRequest: PaymentSetupRequest = {
      type: PaymentSetupType.WORLDPAY,
      isMobileSource: !!isMobileSource,
    };
    if (!!recaptcha) {
      paymentSetupRequest.recaptcha = encodeURIComponent(recaptcha);
    }

    return this.http.post(`${this.BASE_PATH}/setup`, paymentSetupRequest).pipe(map((json: Object) => new PaymentSetup().deserialize(json)));
  }

  public refreshCreditCards() {
    this.reloadCreditCards.next();
  }

  /**************************************************** Helpers *****************************************************/

  private initCreditCardsLoader(): void {
    merge(this.accountService.account$, this.reloadCreditCards.asObservable())
      .pipe(
        switchMap(() => this.accountService.account$),
        filter((account: Account) => account.isInstantiated),
        mergeMap(() => this.configService.storeConfig$),
        filter((storeConfig: StoreConfig) => storeConfig.hasCreditCard),
        mergeMap(() => this.http.get(this.BASE_PATH)),
        map((json: Object[]) => (json || []).map(c => new CreditCard().deserialize(c)))
      )
      .subscribe(
        creditCards => this.creditCards.next(creditCards),
        () => {}
      );
  }

  /************************************************ Auditing helpers ************************************************/

  private auditCreditCardAddition(creditCard: CreditCard): void {
    const maskedNumber = this.creditCardMask.transform(creditCard.lastDigits);
    this.auditService.createAudit(() => `Credit Card "${maskedNumber}" has been added to wallet`);
  }

  private auditCreditCardRemoval(creditCard: CreditCard): void {
    const maskedNumber = this.creditCardMask.transform(creditCard.lastDigits);
    this.auditService.createAudit(() => `Credit Card "${maskedNumber}" has been deleted`);
  }

  private auditCreditCardUpdate(creditCard: CreditCard): void {
    const maskedNumber = this.creditCardMask.transform(creditCard.lastDigits);
    this.auditService.createAudit(() => `Credit Card "${maskedNumber}" has been updated`);
  }
}
