import { ValidatedResource } from '../core/validated-resource';
import { OrderableIngredient } from '../menu/orderable-ingredient.model';

export class OrderedIngredient extends ValidatedResource {
  ingredient: string;
  ordinal: number;
  isLeftHalf: boolean;
  isRightHalf: boolean;
  qualifiers?: string[];
  secondHalfQualifiers?: string[];
  isDefault?: boolean;
  isEverything: boolean;
  weight: number;
  showDefault: boolean;
  showNoDefault: boolean;
  manualPrice: number;
  price?: number;
  isSecondHalf?: boolean;
  showIngredientPrice: boolean;
  priceParticipation: number;
  secondHalfPriceParticipation: number;

  static createDefaultIngredient(ingredient: string): OrderedIngredient {
    return new OrderedIngredient().deserialize({
      ingredient: ingredient,
      isDefault: true,
    });
  }

  static createFromOrderable(ingredient: OrderableIngredient, isLeftHalf = false, isRightHalf = false) {
    return new OrderedIngredient().deserialize({
      ingredient: ingredient.name,
      isDefault: ingredient.isDefault,
      isEverything: ingredient.isEverything,
      isLeftHalf: isLeftHalf,
      isRightHalf: isRightHalf,
      qualifiers: ingredient.qualifiers && ingredient.qualifiers.filter(q => q.isDefault).map(q => q.name),
    });
  }

  chooseHalf(isLeftHalf = false, isRightHalf = false) {
    this.isLeftHalf = isLeftHalf;
    this.isRightHalf = isRightHalf;
  }

  get formattedQualifiers(): string {
    return (this.qualifiers || []).join(' ');
  }

  get formattedSecondHalfQuals(): string {
    return (this.secondHalfQualifiers || []).join(' ');
  }

  serialize(): any {
    return {
      ingredient: this.ingredient,
      isLeftHalf: this.isLeftHalf,
      isRightHalf: this.isRightHalf,
      qualifiers: this.qualifiers,
      manualPrice: this.manualPrice,
      secondHalfQualifiers: this.secondHalfQualifiers,
      isSecondHalf: this.isSecondHalf,
    };
  }
}
