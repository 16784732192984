import { Pipe, PipeTransform, Inject, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { StoreConfig, ConfigService } from 'ngx-web-api';
import { take } from 'rxjs/operators';

@Pipe({
  name: 'currency',
})
export class FtsCurrencyPipe extends CurrencyPipe implements PipeTransform {
  private storeConfig: StoreConfig;

  constructor(
    @Inject(LOCALE_ID) _locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) _defaultCurrencyCode: string = 'USD',
    private configService?: ConfigService
  ) {
    super(_locale, _defaultCurrencyCode);
    this.configService.storeConfig$.pipe(take(1)).subscribe(store => (this.storeConfig = store));
  }

  transform(value: any, currencyCode?: string, _defaultCurrencyCode?: string, digitsInfo?: string): any {
    if (currencyCode === 'USD' && this.storeConfig?.country === 'IE') {
      return super.transform(value, 'EUR', _defaultCurrencyCode, digitsInfo);
    }
    return super.transform(value, currencyCode, _defaultCurrencyCode, digitsInfo);
  }
}
