import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from './base-resource';
import { LoyaltyPlanAward } from './loyalty-plan-award.model';

export class LoyaltyPlan extends BaseResource {
  readonly id: string;
  readonly loyaltyPlanName: string;
  readonly needsLoyaltyCards: boolean;
  readonly hasLoyaltyCards: boolean;
  readonly needsGiftCards: boolean;
  readonly hasGiftCards: boolean;
  readonly needsName: boolean;
  readonly hasEmail: boolean;
  readonly needsEmail: boolean;
  readonly hasPhone: boolean;
  readonly needsPhone: boolean;
  readonly rules: string[];
  readonly awards: LoyaltyPlanAward[];
  readonly closingAwardThreshold: number;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      awards: DeserializationUtils.deserializeChildren(input, 'awards', LoyaltyPlanAward),
    });
  }
}
