import { Directive, ElementRef, Input, Renderer2, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { ScrollEventService } from './scroll-event.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Utils } from 'ngx-web-api';

@Directive({
  selector: '[ftsSticky]',
  exportAs: 'ftsStickyRef',
})
export class StickyDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  stackingOrder: number;
  @Input()
  topMargin: number;
  @Input()
  hideBelowPrevious = false;
  @Input()
  hideOnScrollDown = false;
  @Input()
  zIndex: number;

  public elOffsetHeight = 0;

  private unlisten: () => void;
  private stickyElements: HTMLElement[];
  private previousSticky: HTMLElement;
  private scrollY = 0;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private el: ElementRef, private renderer: Renderer2, private scrollEventService: ScrollEventService) {
    this.unlisten = this.renderer.listen(window, 'stickyAdd', (event: any) => {
      setTimeout(() => {
        this.refreshStickyList();
      }, 500 + (this.stackingOrder ?? 0)); // Needs delay for the rendering to be completed and the height calculations to be correct
    });
    this.refreshStickyList();
  }

  ngOnInit() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'data-sticky',
      `${this.stackingOrder ?? (this.stickyElements?.length ? this.stickyElements?.length + 1 : 0)}`
    );

    this.renderer.setStyle(this.el.nativeElement, 'position', 'sticky');
    this.renderer.setStyle(this.el.nativeElement, 'position', '-webkit-sticky');
    this.renderer.setStyle(
      this.el.nativeElement,
      'z-index',
      `${this.zIndex ?? (this.hideBelowPrevious ? 100 - this.stackingOrder : 100 + this.stackingOrder)}`
    );

    const newStickyEvent = new CustomEvent('stickyAdd', {
      detail: {
        order: this.stackingOrder ?? 0,
        top: this.topMargin || 50,
      },
    });
    window.dispatchEvent(newStickyEvent);

    if (this.hideOnScrollDown && (this.el?.nativeElement as HTMLElement)?.parentElement.style.display !== 'none') {
      this.scrollEventService.topMenuThrottled$.pipe(takeUntil(this.destroy$)).subscribe(() => this.hideOnScrollDownCheck());
    }
    this.elOffsetHeight = (this.el.nativeElement as HTMLElement)?.offsetHeight;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.topMargin) {
      this.refreshStickyList();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.unlisten();
  }

  refreshStickyList() {
    this.stickyElements = Array.from(document.querySelectorAll(`[data-sticky]`));
    this.previousSticky = this.stickyElements.find(el => parseInt(el.getAttribute('data-sticky'), 10) === this.stackingOrder - 1);

    if (Utils.isNullOrUndefined(this.topMargin) && this.previousSticky?.offsetHeight > 0) {
      const previousTop = parseInt(this.previousSticky.style.top, 10) || 0;
      if (previousTop <= 0) {
        // If previous sticky has top: 0px then we use its offsetHeight because it's not affected by rendering process
        this.renderer.setStyle(this.el.nativeElement, 'top', `${Math.round(this.previousSticky?.offsetHeight)}px`);
      } else {
        // If previous sticky has top > 0px we must use its rectangle bottom which might be affected by the rendering process and requires
        // a timeout to acquire a stable value. Still this is not as smooth as the above, but is only used on the BannerHeader component
        // scroll/fading header animation
        setTimeout(() => this.renderer.setStyle(this.el.nativeElement, 'top', `${this.previousSticky.getBoundingClientRect().bottom}px`));
      }
      return;
    }
    this.renderer.setStyle(this.el.nativeElement, 'top', `${this.topMargin ?? 0}px`);
  }

  getElementHeight(el: HTMLElement) {
    if (!el) {
      return null;
    }
    return window.getComputedStyle(el, null)?.getPropertyValue('height');
  }

  hideOnScrollDownCheck() {
    if (window.scrollY - this.scrollY > 0 && !this.scrollEventService.blockingScrollEvent.value) {
      this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
      this.renderer.setStyle(this.el.nativeElement, 'top', 'unset');
      this.elOffsetHeight = 0;
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'position', 'sticky');
      this.renderer.setStyle(this.el.nativeElement, 'position', '-webkit-sticky');
      this.renderer.setStyle(this.el.nativeElement, 'top', `${this.topMargin}px`);
      this.elOffsetHeight = (this.el.nativeElement as HTMLElement)?.offsetHeight;
    }
    this.scrollY = window.scrollY;
  }
}
