import { Component, Input, OnChanges, SimpleChanges, AfterViewInit, OnInit, Output, EventEmitter } from '@angular/core';
import { Panel, RecentlyOrderedItem, RecentlyOrderedItemsService, TagTab, Tab } from 'ngx-web-api';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ThemeService } from '../../core/services/theme.service';
import { Theme } from '../../domain/theme.model';
import { ViewportService } from 'app/core/services/viewport.service';
import { FocusService } from '../../focus.service';
import { ViewPort } from '../../domain/view-port.model';
import { MobileMenu } from 'app/domain/mobile-menu.enum';
import { SlugifyPipe } from '../pipes/slugify.pipe';
import { MenuWrapperService } from 'app/core/services/menu-wrapper.service';

@Component({
  selector: 'fts-vertical-categories-menu',
  templateUrl: './vertical-categories-menu.component.html',
  styleUrls: ['./vertical-categories-menu.component.scss'],
})
export class VerticalCategoriesMenuComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  panel: Panel;
  @Input()
  hasLeftMenuIcons: boolean;
  @Output()
  onTabClick: EventEmitter<MobileMenu> = new EventEmitter<MobileMenu>();

  theme: Theme;
  hasRecentlyOrderedItems: boolean;
  panelTabs: (Tab | TagTab)[] = [];
  isMobile = false;
  isInTablet = false;
  tabLinksMap: { [key: string]: string } = {};
  private slugifyPipe = new SlugifyPipe();
  headerHeight: number;
  recentlyOrderedLabel = 'RecentlyOrdered';

  constructor(
    private recentlyOrderedItemsService: RecentlyOrderedItemsService,
    private themeService: ThemeService,
    public viewPortService: ViewportService,
    private focusService: FocusService,
    private menuService: MenuWrapperService
  ) {
    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
      this.initTabLinks();
    });
    this.recentlyOrderedItemsService.recentlyOrderedItems$
      .pipe(map((recentlyOrderedItems: RecentlyOrderedItem[]) => recentlyOrderedItems && recentlyOrderedItems.length > 0))
      .subscribe((hasRecentlyOrderedItems: boolean) => (this.hasRecentlyOrderedItems = hasRecentlyOrderedItems));
  }

  ngOnInit() {
    this.menuService.headerHeight$.subscribe(headerHeight => (this.headerHeight = headerHeight));
    this.viewPortService.viewport$.pipe(distinctUntilChanged()).subscribe((viewport: ViewPort) => {
      this.isMobile = viewport.isMobile;
      this.isInTablet = viewport.isTablet;
    });
  }

  ngAfterViewInit() {
    if (this.isMobile) {
      this.focusService.applyFocus('categoryLinksContainer');
    }
  }

  get showTabIcons(): boolean {
    return this.panel && this.panel.tabs && (this.panel.tabs.some(tab => !!tab.image) || this.panel.tagTabs.some(tagTab => !!tagTab.image));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['panel'] && !!this.panel) {
      this.panelTabs = [...(this.panel.tagTabs || []), ...(this.panel.tabs || [])];
      this.initTabLinks();
    }
  }

  tabClick() {
    this.onTabClick.emit(undefined);
  }

  private initTabLinks() {
    if (this.theme.hasSinglePageMenu) {
      this.panelTabs.forEach(panelTab => {
        this.tabLinksMap[panelTab.name] = `menu/single-page#${this.slugifyPipe.transform(panelTab.name)}`;
      });
      this.recentlyOrderedLabel = 'Recently Ordered';
    } else {
      this.panelTabs.forEach(panelTab => {
        this.tabLinksMap[panelTab.name] = `menu/${encodeURIComponent(panelTab.name)}`;
      });
    }
    const recentlyOrderedLink = this.theme.hasSinglePageMenu
      ? `menu/single-page#${this.slugifyPipe.transform(this.recentlyOrderedLabel)}`
      : `menu/${this.recentlyOrderedLabel}`;

    this.tabLinksMap[this.recentlyOrderedLabel] = recentlyOrderedLink;
  }
}
