import { SessionStorageKeys } from './../../domain/session-storage-keys.enum';
import { BrowserStorageHandlerService } from './browser-storage-handler.service';
import { Injectable, Inject } from '@angular/core';
import { Subject, Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { PathService } from '../../shared/services/path.service';
import { ThemeService } from './theme.service';
import { distinctUntilChanged, filter, map, mergeMap, startWith, take } from 'rxjs/operators';
import { BreakpointService } from './breakpoint.service';
import { Theme } from '../../domain/theme.model';
import { DOCUMENT } from '@angular/common';
import { ConfigService, Order, OrderService, OrderType, OrderTypeConfig, StoreConfig } from 'ngx-web-api';
import { OrderTypeModalContext } from 'app/domain/order-type-modal-context';
import { OrderTypeModalComponent } from 'app/shared/order-type-modal/order-type-modal.component';
import { ModalService } from './modal.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UiOrchestratorService {
  private modalOpened: Subject<void> = new Subject<void>();
  public modalOpened$ = this.modalOpened.asObservable();

  private headerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public headerHeight$: Observable<number> = this.headerHeight.asObservable();

  private orderTreeShown: Subject<void> = new Subject<void>();
  public orderTreeShown$: Observable<void> = this.orderTreeShown.asObservable();

  private orderTreeIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public orderTreeIsOpen$: Observable<boolean> = this.orderTreeIsOpen.asObservable();

  private stepControlsPositionChange: Subject<void> = new Subject<void>();
  public stepControlsPositionChange$: Observable<void> = this.stepControlsPositionChange.asObservable();

  private storeStates: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  public storeStates$: Observable<string[]> = this.storeStates.asObservable();

  private checkoutButtonDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public checkoutButtonDisabled$: Observable<boolean> = this.checkoutButtonDisabled.asObservable();

  private mobileStoreChooserOverlayVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mobileStoreChooserOverlayVisible$: Observable<boolean> = this.mobileStoreChooserOverlayVisible.asObservable();

  private webPaySignOut: Subject<void> = new Subject();
  public webPaySignOut$: Observable<void> = this.webPaySignOut.asObservable();

  private signOut: Subject<void> = new Subject();
  public signOut$: Observable<void> = this.signOut.asObservable();

  private storeChooseModalOpened: Subject<void> = new Subject<void>();
  public storeChooseModalOpened$ = this.storeChooseModalOpened.asObservable();

  private orderTreeAnimationEnd: Subject<void> = new Subject<void>();
  public orderTreeAnimationEnd$: Observable<void> = this.orderTreeAnimationEnd.asObservable();

  private toggleMenuCategories: Subject<void> = new Subject<void>();
  public toggleMenuCategories$: Observable<void> = this.toggleMenuCategories.asObservable();

  private clearCvv: Subject<void> = new Subject<void>();
  public clearCvv$ = this.clearCvv.asObservable();

  private minifiedMenuFullyRendered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public minifiedMenuFullyRendered$: Observable<boolean> = this.minifiedMenuFullyRendered.asObservable();

  private showMinifiedMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showMinifiedMenu$: Observable<boolean> = this.showMinifiedMenu.asObservable();

  private showMenuBtn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showMenuBtn$: Observable<boolean> = this.showMenuBtn.asObservable();

  private mobileMenuHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public mobileMenuHeight$: Observable<number> = this.mobileMenuHeight.asObservable();

  private mobileMenuTopPosition: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public mobileMenuTopPosition$: Observable<number> = this.mobileMenuTopPosition.asObservable();

  private contentMobileMenuHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public contentMobileMenuHeight$: Observable<number> = this.contentMobileMenuHeight.asObservable();

  private mobileMenuChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mobileMenuChanged$ = this.mobileMenuChanged.asObservable();

  private showSinglePageMobileUpfrontMenu: Subject<boolean> = new Subject<boolean>();
  public showSinglePageMobileUpfrontMenu$: Observable<boolean> = this.showSinglePageMobileUpfrontMenu.asObservable();

  public persistOrderTree: boolean;

  private searchingMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public searchingMenu$: Observable<boolean> = this.searchingMenu.asObservable();

  private bannerHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public bannerHeight$: Observable<number> = this.bannerHeight.asObservable();

  private orderInitDoneFromOrderTypeModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public orderInitDoneFromOrderTypeModal$: Observable<boolean> = this.orderInitDoneFromOrderTypeModal.asObservable();

  private showTiledMenuLinks: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showTiledMenuLinks$: Observable<boolean> = this.showTiledMenuLinks.asObservable();

  private menuTabLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public menuTabLoading$ = this.menuTabLoading.asObservable();

  private navActionGivenOnLoad: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public navActionGivenOnLoad$ = this.navActionGivenOnLoad.asObservable();

  private theme: Theme;

  public showUpfrontOrTiled$ = combineLatest([this.showSinglePageMobileUpfrontMenu$.pipe(startWith(false)), this.showTiledMenuLinks$]).pipe(
    map(([upfront, tiled]) => upfront || tiled),
    distinctUntilChanged()
  );
  public showUpfront$ = this.showSinglePageMobileUpfrontMenu$.pipe(startWith(false)).pipe(
    map(upfront => upfront),
    distinctUntilChanged()
  );

  constructor(
    private pathService: PathService,
    private themeService: ThemeService,
    private breakpointService: BreakpointService,
    private orderService: OrderService,
    private configService: ConfigService,
    private modalService: ModalService,
    private router: Router,
    private browserStorageHandlerService: BrowserStorageHandlerService,
    @Inject(DOCUMENT) private document: any
  ) {
    combineLatest([this.headerHeight$, this.themeService.theme, this.breakpointService.isTabletDown$, this.mobileMenuChanged$]).subscribe(
      ([headerHeight, theme, isMobile, mobileMenuChanged]) => ((this.theme = theme), this.calculateMobileMenuHeight(theme, isMobile))
    );

    combineLatest([this.themeService.theme, this.pathService.inMenu$, this.breakpointService.isTabletDown$])
      .pipe(map(([theme, inMenu, isMobile]) => theme.minifiedMenu && inMenu && isMobile && !theme.shouldShowTiledMenu))
      .subscribe(showMinifiedMenu => this.showMinifiedMenu.next(showMinifiedMenu));

    combineLatest([
      this.showMinifiedMenu$,
      this.themeService.theme,
      this.pathService.inIntro$,
      this.pathService.inHome$,
      this.showUpfront$,
    ]).subscribe(([showMinifiedMenu, theme, inIntro, inHome, showUpfront]) => {
      const showBtnOnHomeOrIntro = theme.skipIntroPage || !(inIntro || inHome);
      this.showMenuBtn.next(!showMinifiedMenu && !showUpfront && showBtnOnHomeOrIntro);
    });

    combineLatest([this.showUpfrontOrTiled$, this.themeService.theme]).subscribe(([showUpfrontOrTiled, theme]) => {
      if (showUpfrontOrTiled && theme.hasSinglePageMenu) {
        document.body.parentElement.style.overflow = 'hidden';
      } else {
        document.body.parentElement.style.overflow = 'auto';
      }
    });
  }

  openMenuCategories() {
    this.toggleMenuCategories.next();
  }

  public informModalOpened(): void {
    this.modalOpened.next();
  }

  public setHeaderHeight(height: number) {
    this.headerHeight.next(height);
  }

  public setOrderTreeShown(): void {
    this.orderTreeShown.next();
  }

  public setOrderTreeIsOpen(event): void {
    this.orderTreeIsOpen.next(event);
  }

  public recalculateStepControlsPosition() {
    this.stepControlsPositionChange.next();
  }

  public setStoreStates(ss: string[]): void {
    this.storeStates.next(ss);
  }

  public disableCheckoutButton(disabled: boolean = false) {
    this.checkoutButtonDisabled.next(disabled);
  }

  public showMobileStoreChooserOverlay(show = false) {
    this.mobileStoreChooserOverlayVisible.next(show);
  }

  public storeChooseModalTriggered(): void {
    this.storeChooseModalOpened.next();
  }

  public clearCvvTriggered(): void {
    this.clearCvv.next();
  }

  public isNoContactOrder(): boolean {
    return this.browserStorageHandlerService.getSessionStorageItem(SessionStorageKeys.noContact) === 'true';
  }

  public setNoContactToSession(noContact: boolean) {
    this.browserStorageHandlerService.setSessionStorageItem(SessionStorageKeys.noContact, noContact.toString());
  }

  public triggerWebPaySignOut() {
    this.webPaySignOut.next();
  }

  public triggerSignOut() {
    this.signOut.next();
  }

  public setOrderTreeAnimationEnd() {
    this.orderTreeAnimationEnd.next();
  }

  public setMinifiedMenuFullyRendered(status: boolean) {
    this.minifiedMenuFullyRendered.next(status);
  }

  public calculateMobileMenuHeight(theme: Theme, isMobile: boolean) {
    if (!this.headerHeight.value) {
      this.mobileMenuHeight.next(0);
      return;
    }
    const headerMenu = this.document.getElementById('headerMenu');
    this.mobileMenuTopPosition.next(theme.compactHeader ? this.headerHeight.value : headerMenu?.clientHeight);
    const headerHeight = headerMenu?.clientHeight !== 0 ? headerMenu?.clientHeight : this.headerHeight.value;
    this.mobileMenuHeight.next(window.innerHeight - headerHeight - headerMenu?.getBoundingClientRect().top);

    const minifiedMenu = this.document.getElementById('minifiedMenu');
    if (minifiedMenu) {
      if (theme.compactHeader || theme.minifiedHeader) {
        this.mobileMenuTopPosition.next(this.mobileMenuTopPosition.value - minifiedMenu.clientHeight);
      }
      if (theme.minifiedHeader) {
        this.mobileMenuHeight.next(this.mobileMenuHeight.value + minifiedMenu.clientHeight);
      }
      if (!theme.minifiedHeader && !theme.compactHeader) {
        const storeInfoHeight = this.document.getElementById(`full-address`)?.offsetHeight || 0;
        const menuLinksHeight = this.document.getElementById(`menu-links`)?.offsetHeight || 0;
        const notificationHeight = this.document.getElementById(`notifications`)?.offsetHeight || 0;
        this.mobileMenuHeight.next(
          window.innerHeight - storeInfoHeight - (menuLinksHeight - minifiedMenu.offsetHeight) - notificationHeight
        );
      }
    }
    this.contentMobileMenuHeight.next(
      (theme.minifiedHeader && this.orderTreeIsOpen.value) || (!theme.minifiedHeader && this.orderTreeIsOpen.value && isMobile)
        ? 0
        : this.mobileMenuHeight.value
    );
  }

  public setMobileMenuChanged(status: boolean): void {
    this.mobileMenuChanged.next(status);
  }

  public setSearchingMenu(searching: boolean) {
    this.searchingMenu.next(searching);
  }

  public setSinglePageMobileUpfrontMenu(show: boolean) {
    this.showSinglePageMobileUpfrontMenu.next(show);
  }

  public setBannerHeight(height: number) {
    this.bannerHeight.next(height);
  }

  public markedOrderInitializationDoneFromOrderTypeModal(status: boolean): void {
    this.orderInitDoneFromOrderTypeModal.next(status);
  }

  public setTiledMenuLinks(show: boolean) {
    this.showTiledMenuLinks.next(show);
  }

  public setMenuTabLoading(loading: boolean) {
    this.menuTabLoading.next(loading);
  }

  public setNavActionGivenOnLoad(actionGiven: boolean) {
    this.navActionGivenOnLoad.next(actionGiven);
  }

  public ensureOrderHasType(): Observable<boolean> {
    return combineLatest([this.orderService.order$, this.configService.storeConfig$, this.configService.orderTypesConfig$]).pipe(
      mergeMap(([order, storeConfig, orderTypeConfigs]: [Order, StoreConfig, OrderTypeConfig[]]) => {
        if (order.orderType === OrderType.Any) {
          const context = new OrderTypeModalContext(storeConfig, orderTypeConfigs).getConfig();
          return this.modalService.openModal(OrderTypeModalComponent, context);
        } else {
          return of(true);
        }
      }),
      take(1),
      filter((orderTypeSelected: boolean) => orderTypeSelected)
    );
  }

  public navigateToTiledMenuIndex() {
    if (this.theme.hasSinglePageMenu) {
      this.setTiledMenuLinks(true);
      if (this.router.url.includes(`menu/search`)) {
        this.router.navigateByUrl(`menu/single-page`);
      }
      this.setHorizontalSpaceForSinglePageTiledMenu();
      return;
    }
    this.router.navigate(['menu']);
  }

  public setHorizontalSpaceForSinglePageTiledMenu() {
    const tiledMenuLinks = document.getElementById('tiled-menu-links-container');
    if (window.innerWidth > 1280) {
      if (!!tiledMenuLinks) {
        tiledMenuLinks.style.paddingRight = `${(window.innerWidth - 1280) * 0.5}px`;
        tiledMenuLinks.style.paddingLeft = `${(window.innerWidth - 1280) * 0.5}px`;
      }
    } else {
      if (!!tiledMenuLinks) {
        tiledMenuLinks.style.paddingRight = '0px';
        tiledMenuLinks.style.paddingLeft = '0px';
      }
    }
  }
}
