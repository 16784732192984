<div class='compact-account-menu-wrapper primary-bg-color primary-text-color' [ngClass]="{'space-right': hasTopOrderTree}" #accountMenu>
  <button class='btn btn-link btn-undecorated font-secondary account-button' (click)='toggleMenuVisibility()'
          [attr.aria-label]="'component.header.collapsible_account_menu.acc_options' | translate">
    <fa-icon [fixedWidth]="true" [icon]="faUserCircle"></fa-icon>
  </button>

  <div class='compact-account-menu primary-bg-color primary-text-color' [@expanded]='menuVisible' [ngClass]="{'left-positioned': leftPositioned}">
    <ng-container *ngIf='account?.isInstantiated && menuVisible'>
      <a *ngIf='!(breakPointService.isXSMobile$ | async)'
        [routerLink]="['/account', 'profile']"
        class="btn btn-link btn-block btn-header text-uppercase account-link font-secondary"
        id="account-link-profile">
        {{'component.header.collapsible_account_menu.profile' | translate}}
      </a>

      <a *ngIf='store.hasLoyalty && !!loyaltyPlan?.id && !(breakPointService.isDesktop$ | async) && !store.hasThirdPartyLoyalty'
        [routerLink]="['/loyalty']"
        class="btn btn-link btn-block btn-header text-uppercase account-link font-secondary"
        id="account-link-loyalty">{{loyaltyPlan?.loyaltyPlanName}}</a>

      <a *ngIf="!(breakPointService.isDesktop$ | async)"
        [routerLink]="['/account', 'my-orders']"
        class="btn btn-link btn-block btn-header text-uppercase account-link font-secondary account-link-order-history"
        id="account-link-order-history">{{'component.header.collapsible_account_menu.order_history' | translate}}</a>

      <a *ngIf='!(breakPointService.isXSMobile$ | async)'
        [routerLink]="['/account', 'addresses']"
        class="btn btn-link btn-block btn-header text-uppercase account-link font-secondary"
        id="account-link-address-book">{{'component.header.collapsible_account_menu.address_book' | translate}}</a>

      <a *ngIf="(store.hasCreditCard || store.hasGiftCard) && !(breakPointService.isXSMobile$ | async)"
        [routerLink]="['/account', 'wallet']"
        class="btn btn-link btn-block btn-header text-uppercase account-link font-secondary"
        id="account-link-wallet">{{'component.header.collapsible_account_menu.wallet' | translate}}</a>

      <a *ngIf="!(breakPointService.isDesktop$ | async) && !store.hasThirdPartyLoyalty"
        [routerLink]="['/account', 'coupons']"
        class="btn btn-link btn-block btn-header text-uppercase account-link font-secondary account-link-coupons"
        id="account-link-coupons">{{'component.header.collapsible_account_menu.coupons' | translate}}</a>

      <a *ngIf="account?.hasDonations && !(breakPointService.isXSMobile$ | async)"
        [routerLink]="['/account', 'donations']"
        class="btn btn-link btn-block btn-header text-uppercase account-link font-secondary"
        id="account-link-donations">{{'component.header.collapsible_account_menu.donations' | translate}}</a>

      <hr class='small-margin' aria-hidden="true">

      <button *ngIf='!(breakPointService.isXSMobile$ | async)'
              id="account-link-logout"
              class='btn btn-link btn-block btn-header text-uppercase account-link font-secondary'
              (click)='handleLogout()'>{{'component.header.collapsible_account_menu.sign_out' | translate}}
      </button>

    </ng-container>

    <ng-container *ngIf='!account?.isInstantiated && menuVisible'>
      <button (click)="handleLogin()" class='btn btn-link btn-lg btn-undecorated btn-block text-left font-secondary'>
        {{'component.header.collapsible_account_menu.sign_in' | translate}}
      </button>
      <button [routerLink]="['/register']" class='btn btn-link btn-lg btn-undecorated btn-block text-left font-secondary'>
        {{'component.header.collapsible_account_menu.sign_up' | translate}}
      </button>
    </ng-container>

  </div>
</div>
