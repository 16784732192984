import { Component, OnInit, ViewChild } from '@angular/core';
import { Address } from 'ngx-web-api';
import { Subscription } from 'rxjs';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { AddressFormComponent } from '../address-form/address-form.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressModalContext } from 'app/domain/address-modal-context';

@Component({
  selector: 'fts-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {
  @ViewChild('addressForm', { static: true })
  public addressForm: AddressFormComponent;

  public context: AddressModalContext;
  address: Address = new Address();
  isEditing = false;
  addressErrors: string[];

  showAddressClientValidation = false;

  constructor(
    private uiOrchestratorService: UiOrchestratorService,
    private translateService: TranslateService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.address = this.context.address;
    this.isEditing = this.context.isEditing;
    this.uiOrchestratorService.informModalOpened();
  }

  hideModal(): void {
    this.activeModal.close(null);
  }

  get title(): string {
    return this.isEditing
      ? this.translateService.instant('component.address_modal.update_address')
      : this.translateService.instant('component.address_modal.add');
  }

  get submitBtnTitle(): string {
    return this.isEditing
      ? this.translateService.instant('component.address_modal.update')
      : this.translateService.instant('component.address_modal.save');
  }

  get loadingSubscription(): Subscription {
    return this.addressForm.loadingSubscription;
  }

  handleSubmit(): void {
    this.showAddressClientValidation = false;
    if (!this.addressForm.isFormValid) {
      this.addressForm.updateValidationErrors();
      this.showAddressClientValidation = true;
      return;
    }

    if (this.isEditing) {
      this.editAddress();
    } else {
      this.createAddress();
    }
  }

  handleSuccess() {
    this.hideModal();
  }

  onAddressErrors(errors: string[]) {
    this.addressErrors = Array.from(errors);
  }

  private editAddress() {
    this.addressForm.handleEdit();
  }

  private createAddress() {
    this.addressForm.handleCreate();
  }
}
