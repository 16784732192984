<ng-container *ngFor="let featuredContent of featuredContents">
   <a *ngFor="let featuredItem of featuredContent.featuredItems"
      [href]='featuredItem.link'
      [target]="featuredItem.target"
      [ngClass]="{'fw-600': compactHeader}"
      class="btn btn-link btn-header hidden-xs text-uppercase account-link font-secondary featured-link"
      [id]='"header-featured-link-" + (featuredItem.title | slugify)'>
      {{featuredItem.title}}
   </a>
</ng-container>

<ng-container *ngIf='account?.isInstantiated && (breakPointService.isDesktop$ | async)'>
  <a *ngIf="!compactAccountMenu" [routerLink]="['/account', 'profile']" [ngClass]="{'fw-600': compactHeader}"
     class="btn btn-link btn-header text-uppercase account-link font-secondary" id="account-link-profile">
    {{'component.header.collapsible_account_menu.profile' | translate}}
  </a>

  <a *ngIf='store.hasLoyalty && !!loyaltyPlan.id && !store.hasThirdPartyLoyalty' [routerLink]="['/loyalty']" [ngClass]="{'fw-600': compactHeader}"
     class="btn btn-link btn-header text-uppercase account-link font-secondary"
     id="account-link-loyalty">{{loyaltyPlan.loyaltyPlanName}}</a>

  <a [routerLink]="['/account', 'my-orders']" [ngClass]="{'fw-600': compactHeader}"
     class="btn btn-link btn-header text-uppercase account-link font-secondary account-link-order-history"
     id="account-link-order-history">{{'component.header.collapsible_account_menu.order_history' | translate}}</a>

  <a *ngIf="!compactAccountMenu" [routerLink]="['/account', 'addresses']" [ngClass]="{'fw-600': compactHeader}"
     class="btn btn-link btn-header text-uppercase account-link font-secondary"
     id="account-link-address-book">{{'component.header.collapsible_account_menu.address_book' | translate}}</a>

  <a *ngIf="!compactAccountMenu && (store.hasCreditCard || store.hasGiftCard)" [routerLink]="['/account', 'wallet']"
     [ngClass]="{'fw-600': compactHeader}"
     class="btn btn-link btn-header text-uppercase account-link font-secondary"
     id="account-link-wallet">{{'component.header.collapsible_account_menu.wallet' | translate}}</a>

  <a *ngIf='!store.hasThirdPartyLoyalty' [routerLink]="['/account', 'coupons']" [ngClass]="{'fw-600': compactHeader}"
     class="btn btn-link btn-header text-uppercase account-link font-secondary account-link-coupons"
     id="account-link-coupons">{{'component.header.collapsible_account_menu.coupons' | translate}}</a>

  <a *ngIf="account?.hasDonations && !compactAccountMenu"
     [routerLink]="['/account', 'donations']" [ngClass]="{'fw-600': compactHeader}"
     class="btn btn-link btn-header text-uppercase account-link font-secondary"
     id="account-link-donations">{{'component.header.collapsible_account_menu.donations' | translate}}</a>

   <fts-track-order-btn *ngIf="!(isSelfOrder$ | async) && !(breakPointService.isTabletDown$ | async)"
                         class="account-link"
                         [fontWeight]="compactHeader"
                         ></fts-track-order-btn>

  <span *ngIf="!compactAccountMenu" class='separator fw-300 font-primary account-link-separator'>|</span>

  <button *ngIf="!compactAccountMenu"
          id="account-link-logout" [ngClass]="{'fw-600': compactHeader}"
          class='btn btn-link btn-header text-uppercase account-link font-secondary'
          (click)='handleLogout()'>{{'component.header.collapsible_account_menu.sign_out' | translate}}
  </button>

</ng-container>

<ng-container *ngIf='!account?.isInstantiated && !(breakPointService.isTabletDown$ | async)'>
  <button
    class='btn btn-link btn-header text-uppercase account-link font-secondary'
    id="account-link-my-orders"
    [ngClass]="{'fw-600': compactHeader}"
    [routerLink]="['/account', 'my-orders']">{{'component.header.collapsible_account_menu.my_orders' | translate}}
  </button>

  <a *ngIf='store.hasLoyalty && !!loyaltyPlan.id && !store.hasThirdPartyLoyalty' [routerLink]="['/loyalty']" [ngClass]="{'fw-600': compactHeader}"
     class="btn btn-link btn-header text-uppercase account-link font-secondary"
     id="account-link-loyalty-not-logged-in">{{loyaltyPlan.loyaltyPlanName}}</a>

  <fts-track-order-btn *ngIf="!(isSelfOrder$ | async) && !(breakPointService.isTabletDown$ | async)"
                        class="account-link"
                        [fontWeight]="compactHeader"></fts-track-order-btn>

  <ng-container *ngIf="isInHome || (!compactHeader && !(breakPointService.isMdViewport$ | async)) || (breakPointService.isDesktop$ | async) || ((breakPointService.isMdViewport$ | async) && isInIntro)">
    <button
      class='btn btn-link btn-header text-uppercase account-link font-secondary'
      id="account-link-login"
      [ngClass]="{'fw-600': compactHeader}"
      (click)="handleLogin()">{{'component.header.collapsible_account_menu.sign_in' | translate}}
    </button>

    <a [routerLink]="['/register']"
       [ngClass]="{'fw-600': compactHeader}"
       class='btn btn-link btn-header text-uppercase account-link font-secondary'
       id="account-link-register">{{'component.header.collapsible_account_menu.sign_up' | translate}}</a>
  </ng-container>
</ng-container>
