import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Account, LoyaltyPlan, Order, Panel, StoreConfig, OrderTypeConfig, FeaturedContent, Utils } from 'ngx-web-api';

import { Animations } from '../../../animations';
import { MobileMenu } from '../../../domain/mobile-menu.enum';
import { Theme } from '../../../domain/theme.model';
import { PagePosition } from '../../../domain/page-position.enum';
import { filter, takeUntil } from 'rxjs/operators';
import { BreakpointService } from '../../services/breakpoint.service';
import { faStore } from '@fortawesome/pro-solid-svg-icons';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { PathService } from 'app/shared/services/path.service';

@Component({
  selector: 'fts-compact-header',
  templateUrl: './compact-header.component.html',
  styleUrls: ['./compact-header.component.scss'],
  animations: [Animations.slideX],
})
export class CompactHeaderComponent implements OnInit, OnChanges, OnDestroy {
  showIpadStoreInfo = false;
  faStore = faStore;
  isInCheckoutDetails = false;
  orderInitialized = false;
  headerOrderTree: any;
  orderNotification: any;
  destroy$ = new Subject<void>();
  pagePosition = PagePosition;
  isInTrackerPage$: Observable<boolean>;

  @ViewChild('storeInfoBtn', { static: false })
  storeInfoBtn: ElementRef;
  @ViewChild('groupOrderButton', { static: false }) set groupOrderButton(groupOrderButton: ElementRef) {
    this.cdRef.detectChanges();
  }

  @Input()
  store: StoreConfig;
  @Input()
  account: Account;
  @Input()
  theme: Theme;
  @Input()
  loyaltyPlan: LoyaltyPlan;
  @Input()
  panel: Panel;
  @Input()
  visibleMenu?: MobileMenu;
  @Input()
  order: Order;
  @Input()
  showTopOrderTree: boolean;
  @Input()
  orderTypeConfig: OrderTypeConfig;
  @Input()
  hasMultipleOrderTypeConfigs: boolean;
  @Input()
  isInCheckout: boolean;
  @Input()
  isInIntro: boolean;
  @Input()
  featuredContents: FeaturedContent[];
  @Input()
  embedded: boolean;
  @Input()
  isSelfOrder: boolean;

  @Output()
  onToggleMobileMenu: EventEmitter<MobileMenu> = new EventEmitter();
  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  visibleMenuChange: EventEmitter<MobileMenu | undefined> = new EventEmitter<MobileMenu | undefined>();

  constructor(
    public breakPointService: BreakpointService,
    private router: Router,
    public pathService: PathService,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.pathService.inOrderRelatedRoutes()) {
      this.importLazyComponents();
    }
    this.isInTrackerPage$ = this.pathService.inTracker$;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((val: NavigationEnd) => {
        this.isInCheckoutDetails = val.url === '/checkout/details';
        if (this.pathService.inOrderRelatedRoutes(val.url)) {
          this.importLazyComponents();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order']) {
      this.orderInitialized = !!this.order && !this.order.dummy && this.order.isInitialized;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:click', ['$event'])
  onClick(event): void {
    if (this.storeInfoBtn && !this.storeInfoBtn.nativeElement.contains(event.target)) {
      this.showIpadStoreInfo = false;
    }
  }

  toggleMobileMenu(visibleMenu?: MobileMenu) {
    this.onToggleMobileMenu.next(visibleMenu);
  }

  handleLogin() {
    this.login.emit();
  }

  handleLogout() {
    this.logout.emit();
  }

  orderTreeVisibilityChanged(visible) {
    if (Utils.isNullOrUndefined(this.visibleMenu) || this.visibleMenu === MobileMenu.orderTree) {
      this.visibleMenuChange.emit(visible ? MobileMenu.orderTree : undefined);
      this.toggleMobileMenu(visible ? MobileMenu.orderTree : undefined);
    }
  }

  private importLazyComponents() {
    if (!this.headerOrderTree) {
      this.headerOrderTree = import(`../header-order-tree/header-order-tree.component`).then(
        ({ HeaderOrderTreeComponent }) => HeaderOrderTreeComponent
      );
    }
    if (!this.orderNotification) {
      this.orderNotification = import(`../../../shared/order-notification/order-notification.component`).then(
        ({ OrderNotificationComponent }) => OrderNotificationComponent
      );
    }
  }
}
