import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from './base-resource';
import { DonationInfo } from './donation-info.model';

export class Charity extends BaseResource {
  name: string;
  title: string;
  image: string;
  description: string;
  longDescription: string;
  externalLink: string;
  donationInfos: DonationInfo[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      donationInfos: DeserializationUtils.deserializeChildren(input, 'donationInfos', DonationInfo),
    });
  }
}
