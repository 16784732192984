import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService, Utils, TimezoneService, AuditService } from 'ngx-web-api';
import { Subscription, of, interval } from 'rxjs';
import { ModalService } from '../../core/services/modal.service';
import { ConfirmationModalContext } from '../../domain/confirmation-modal-context';
import { NotificationModalType } from '../../domain/notification-modal-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { differenceInMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionExpirationService {
  private notifyThreshold = 2; // minutes
  private sessionExpirationCheckInterval = 30000; // milliseconds
  private notified: Subscription;
  private notificationModalTimestamp: string;
  private sessionExpirationTimestamp: string;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private tzService: TimezoneService,
    private auditService: AuditService
  ) {}

  startChecking() {
    this.subscribeToNotification();
  }

  subscribeToNotification() {
    if (!!this.notified && !this.notified.closed) {
      this.notified.unsubscribe();
    }
    this.notified = interval(this.sessionExpirationCheckInterval)
      .pipe(filter(() => this.router.url.indexOf('expiration') === -1))
      .subscribe(() => {
        if (this.shouldNotifyUser()) {
          this.cacheNotificationTimestamps();
          this.notified.unsubscribe();
          this.openExpirationModal();
        }
      });
  }

  private openExpirationModal() {
    const context = new ConfirmationModalContext();
    context.ignoreBackdropClick = true;
    context.title = this.translateService.instant('component.session_expiration.expiration_modal.title');
    context.mainContent = this.translateService.instant('component.session_expiration.expiration_modal.mainContent');
    context.submitBtnTitle = this.translateService.instant('component.session_expiration.expiration_modal.submitBtnTitle');
    context.hasDismissBtn = false;
    context.textOnly = true;
    context.type = NotificationModalType.warning;
    context.submitObservable = of(true);

    this.modalService.openConfirmationModal(context, true).subscribe(
      updateSession => {
        // eslint-disable-next-line max-len
        const timestampMessage = `after having been previously notified at ${this.notificationModalTimestamp} (client time) that the session will expire at ${this.sessionExpirationTimestamp} (cookie expiration time)`;
        this.subscribeToNotification();
        if (!!updateSession) {
          this.auditService.createAudit(() => `User clicked on 'Update my session' button, ${timestampMessage}`);
        } else {
          this.auditService.createAudit(() => `User dismissed session expiration notification modal ${timestampMessage}`);
        }
      },
      error => {
        // This should never be called, unless someone changes ConfirmationModalContext.submitObservable to perform a network request
        this.auditService.createAudit(() => ({
          message: `Session expiration modal has been dismissed for unknown reason with error (see details)`,
          details: error,
        }));
        this.subscribeToNotification();
      }
    );
  }

  private shouldNotifyUser(): boolean {
    const remainingMinutes = Math.round(this.getRemainingMinutes());
    if (remainingMinutes < 0) {
      return false;
    }

    return remainingMinutes <= this.notifyThreshold;
  }

  private getRemainingMinutes(): number {
    return differenceInMinutes(this.getSessionExpiration(), this.getTimeInServerTZ());
  }

  private getSessionExpiration(): Date {
    const sessionExpirationStr = this.cookieService.getCookie('sessionExpiry');
    return Utils.parseIsoDate(sessionExpirationStr) || this.getTimeInServerTZ();
  }

  private getTimeInServerTZ(): Date {
    return utcToZonedTime(new Date(), this.tzService.getTimeZone());
  }

  private cacheNotificationTimestamps() {
    this.notificationModalTimestamp = new Date().toISOString();
    this.sessionExpirationTimestamp = this.cookieService.getCookie('sessionExpiry');
  }
}
