import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { Tab } from './tab.model';
import { TagTab } from './tag-tab.model';

export class Panel extends BaseResource {
  name: string;
  tabs: Tab[];
  tagTabs: TagTab[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      tabs: DeserializationUtils.deserializeChildren(input, 'tabs', Tab),
      tagTabs: DeserializationUtils.deserializeChildren(input, 'tagTabs', TagTab),
    });
  }
}
