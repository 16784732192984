import { GroupOrder, GroupOrderFriend } from 'ngx-web-api';

import { BasicModalContext } from './basic-modal-context';
import { InvitationDialogConfig } from './invitation-dialog-config';

export class GrouporderInvitationModalContext extends BasicModalContext {
  constructor(
    public groupOrderingFriends: GroupOrderFriend[],
    public invitationDialogConfig: InvitationDialogConfig,
    public groupOrder?: GroupOrder,
    public changeExpirationMode?: boolean
  ) {
    super();
    this.class = 'md';
    this.config = { groupOrder, groupOrderingFriends, invitationDialogConfig, changeExpirationMode };
  }
}
