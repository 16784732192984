export enum EcommerceTriggeringPoint {
  ORDER_BUTTON = 'Order button',
  UPDATE_BUTTON = 'Update button',
  COUPON_INPUT = 'Coupon input',
  ACCOUNT_COUPON = 'Account Coupon',
  CHECKOUT_BUTTON = 'Checkout button',
  REORDER_BUTTON = 'Reorder button',
  APPLICABLE_SPECIAL = 'Applicable Special',
  CLEAR_ORDER_BUTTON = 'Clear Order button',
  REMOVE_BUTTON = 'Remove button',
  REMOVE_SPECIAL_BUTTON = 'Remove Special button',
  MODIFY_BUTTON = 'Modify button',
  QUANTITY_INPUT = 'Quantity input',
  PLACE_ORDER_BUTTON = 'Place Order button',
  URL_PARAM = 'URL Param',
  COMBO = 'Combo',
}
