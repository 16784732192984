import { InjectionToken } from '@angular/core';
import { Event, EventHint } from '@sentry/types';

export interface ErrorHandlerOptions {
  environment: string;
  skipEmailReporting?: boolean;
  skipSentryReporting?: boolean;
  // Following options are Sentry config related. Please check the documentation for more details :
  // https://docs.sentry.io/platforms/javascript/guides/electron/configuration/options/
  // https://docs.sentry.io/clients/javascript/tips/
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  ignoreErrors?: Array<string | RegExp>;
  denyUrls?: Array<string | RegExp>;
  allowUrls?: Array<string | RegExp>;
  sampleRate?: number;
  beforeSend?: 'DEFAULT' | ((event: Event, hint?: EventHint) => Promise<Event | null> | Event | null);
  defaultThrottleTimeInMs?: number;
}

export const ERROR_HANDLER_OPTIONS = new InjectionToken<ErrorHandlerOptions>('error.handler.options');
