import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Account } from '../../models/account/account.model';
import { GiftCardWrapper } from '../../models/core/gift-card-wrapper.model';
import { GiftCard } from '../../models/core/gift-card.model';
import { StoreConfig } from '../../models/core/store-config.model';
import { GiftCardMaskPipe } from '../../pipes/gift-card-mask.pipe';
import { AuditService } from '../core/audit.service';
import { ConfigService } from '../core/config.service';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class AccountGiftCardsService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/account/giftcards';
  private reloadGiftCards: Subject<void> = new Subject<void>();

  private giftCards: BehaviorSubject<GiftCard[]> = new BehaviorSubject([]);
  public giftCards$: Observable<GiftCard[]> = this.giftCards.asObservable();

  constructor(
    private http: HttpClient,
    private auditService: AuditService,
    private giftCardMask: GiftCardMaskPipe,
    private accountService: AccountService,
    private configService: ConfigService
  ) {
    this.initGiftCardsLoader();
  }

  public deleteGiftCard(giftCard: GiftCard): Observable<any> {
    return this.http.delete(`${this.BASE_PATH}/${giftCard.number}`).pipe(
      tap(() => this.auditGiftCardRemoval(giftCard)),
      tap(() => this.reloadGiftCards.next())
    );
  }

  public updateGiftCard(giftCard: GiftCard): Observable<any> {
    return this.http.put(`${this.BASE_PATH}/${giftCard.number}`, giftCard.serialize()).pipe(
      tap(() => this.auditGiftCardNameChanged(giftCard)),
      tap(() => this.reloadGiftCards.next())
    );
  }

  public sendActivationWithEmailGiftCard(cardNumber: string, email: string): Observable<Object> {
    const encodeUrl = `${this.BASE_PATH}/sendActivationEmail?cardNumber=${cardNumber}&email=${encodeURIComponent(email)}`;
    return this.http
      .post(encodeUrl, {})
      .pipe(
        tap(() =>
          this.auditService.createAudit(() => `Gift Card activation successfully requested for ${email} with card number ${cardNumber}`)
        )
      );
  }

  /**************************************************** Helpers *****************************************************/

  private initGiftCardsLoader(): void {
    merge(this.accountService.account$, this.reloadGiftCards.asObservable())
      .pipe(
        switchMap(() => this.accountService.account$),
        filter((account: Account) => account.isInstantiated),
        mergeMap(() => this.configService.storeConfig$),
        filter((storeConfig: StoreConfig) => storeConfig.hasGiftCard),
        mergeMap(() => this.http.get(this.BASE_PATH)),
        map(json => new GiftCardWrapper().deserialize(json).data)
      )
      .subscribe(
        giftCards => this.giftCards.next(giftCards),
        () => {}
      );
  }

  /************************************************ Auditing helpers ************************************************/

  private auditGiftCardRemoval(giftCard: GiftCard): void {
    const maskedNumber = this.giftCardMask.transform(giftCard.number);
    this.auditService.createAudit(() => `Gift Card "${maskedNumber}" has been deleted`);
  }

  private auditGiftCardNameChanged(giftCard: GiftCard): void {
    const maskedNumber = this.giftCardMask.transform(giftCard.number);
    this.auditService.createAudit(() => `Gift Card "${maskedNumber}" name has been changed`);
  }
}
