import { Pipe, PipeTransform } from '@angular/core';
import { LeftPadPipe } from './left-pad.pipe';

@Pipe({ name: 'creditCardMask' })
export class CreditCardMaskPipe implements PipeTransform {
  constructor(private leftPad: LeftPadPipe) {}

  transform(lastFourDigits: string, hasSpaces = true, length = 16): string {
    let result = this.leftPad.transform(lastFourDigits, '*', length);
    if (hasSpaces) {
      const matchObject = result.match(/.{1,4}/g);

      if (matchObject) {
        result = matchObject.join(' ');
      }
    }

    return result;
  }
}
