import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderService, ConfigService } from 'ngx-web-api';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SelfSourceOrderGuard implements CanActivate {
  constructor(private orderService: OrderService, private router: Router, private configService: ConfigService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.orderService.order$.pipe(
      mergeMap(order => this.configService.findOrderTypeConfig(order.orderType).pipe(map(config => ({ order, config })))),
      map(({ order, config }) => !!order && order.isInitialized && !!config),
      map(isSelfSourceOrder => isSelfSourceOrder || this.router.parseUrl('/'))
    );
  }
}
