import { Injectable } from '@angular/core';
import {
  Order,
  OrderableItem,
  ConfigService,
  Group,
  Utils,
  OrderedItem,
  OrderService,
  RecentlyOrderedItem,
  GroupOrderService,
  GroupOrder,
} from 'ngx-web-api';
import { take } from 'rxjs/operators';
import { EcommerceItem } from '../../domain/ecommerce-item';
import { EcommerceSpecial } from '../../domain/ecommerce-special';
import { EcommerceCart } from '../../domain/ecommerce-cart';
import { EcommerceTriggeringArea } from '../../domain/ecommerce-triggering-area.enum';
import { EcommerceTriggeringPoint } from '../../domain/ecommerce-triggering-point.enum';
import { combineLatest } from 'rxjs';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class GTMService {
  readonly CURRENCY_CODE = 'USD';
  private hasGtmAccountNumber: boolean;

  constructor(private configService: ConfigService, private orderService: OrderService, private groupOrderService: GroupOrderService) {}

  public setupGTM() {
    this.configService.storeConfig$.subscribe(storeConfig => {
      this.hasGtmAccountNumber = !!storeConfig.gtmAccountNumber;
      if (storeConfig.gtmAccountNumber && storeConfig.gtmAccountNumber.length > 0) {
        document.getElementById('gtm-header-script').innerHTML = this.getGtmHeaderScript(storeConfig.gtmAccountNumber);
        document.getElementById('gtm-body-script').innerHTML = this.getGtmBodyScript(storeConfig.gtmAccountNumber);
      }
    });
  }

  public pushViewItemListEvent({
    tabName,
    groups,
    recentlyOrderedItems,
  }: {
    tabName: string;
    groups?: Group[];
    recentlyOrderedItems?: RecentlyOrderedItem[];
  }) {
    if (groups?.length === 0 || recentlyOrderedItems?.length === 0 || !this.hasGtmAccountNumber) {
      return;
    }

    let ecommerceItems: EcommerceItem[] = [];
    if (groups?.length) {
      ecommerceItems = Utils.flatten(
        groups.map(group => {
          return group.panelItems.map(item => {
            if (item.isItem) {
              const defaultSizePrice = item.sizePrices.find(sp => sp.isWebDefault) || item.sizePrices[0];

              // utilized for regular panel items
              return {
                item_name: item.label || item.name,
                item_category: tabName,
                item_category2: EcommerceTriggeringArea.MENU,
                item_category3: defaultSizePrice.size,
                item_category4: item.tags.length ? item.tags.map(tag => tag.displayName).join(', ') : undefined,
                price: defaultSizePrice.price,
                quantity: item.minimumQuantity,
              };
            } else if (item.isSpecial) {
              // utilized for special panel items
              return {
                item_name: item.label || item.name,
                item_category: tabName,
                item_category2: EcommerceTriggeringArea.MENU,
                price: item.specialFixedPrice,
              };
            }
          });
        })
      );
    } else if (recentlyOrderedItems?.length) {
      ecommerceItems = recentlyOrderedItems.map(item => {
        // utilized for recently ordered items
        return {
          item_name: item.webLabel || item.name,
          item_category: tabName,
          item_category2: EcommerceTriggeringArea.MENU,
          item_category3: item.sizeLabel,
          price: item.price,
          quantity: 1,
        };
      });
    }
    ecommerceItems = ecommerceItems.filter(item => !!item); // filtering out the 'undenifed' items, e.g. fundraisers

    this.pushEventToDataLayer({
      event: 'view_item_list',
      ecommerce: this.createEcommerceCart({ items: ecommerceItems }),
    });
  }

  public pushViewItemEvent(
    item: OrderableItem | RecentlyOrderedItem,
    size: string,
    price: number,
    quantity: number,
    triggeringPoint: string,
    triggeringArea = EcommerceTriggeringArea.MENU
  ) {
    if (!this.hasGtmAccountNumber) {
      return;
    }
    const itemLabel = (item instanceof RecentlyOrderedItem ? item.webLabel : item.label) || item.name;
    const ecommerceItem: EcommerceItem = {
      item_name: itemLabel,
      item_category: item.category,
      item_category2: triggeringArea,
      item_category3: triggeringPoint,
      item_category4: size,
      item_category5: item?.tags?.length ? item.tags.map(tag => tag.displayName).join(', ') : undefined,
      price: price,
      quantity: quantity,
    };
    this.pushEventToDataLayer({ event: 'view_item', ecommerce: this.createEcommerceCart({ items: [ecommerceItem] }) });
    this.pushEventToDataLayer({ event: 'select_item', ecommerce: this.createEcommerceCart({ items: [ecommerceItem] }) });
  }

  public pushViewPromotionEvent(
    specialName: string,
    couponCode: string,
    triggeringArea: string,
    triggeringPoint: string,
    triggeringCategory: string,
    triggeringSlot?: string
  ) {
    if (!this.hasGtmAccountNumber) {
      return;
    }
    const ecommerceSpecial: EcommerceSpecial = {
      promotion_id: couponCode,
      promotion_name: specialName,
      creative_name: `${triggeringArea} - ${triggeringPoint}`,
      creative_slot: `${triggeringCategory || triggeringPoint} - Slot ${triggeringSlot ?? '1'}`,
      items: undefined,
    };
    this.pushEventToDataLayer({ event: 'view_promotion', ecommerce: ecommerceSpecial });
    this.pushEventToDataLayer({ event: 'select_promotion', ecommerce: ecommerceSpecial });
  }

  public pushAddToCartEvent({
    items,
    triggeringArea,
    triggeringPoint,
    specialOrdinal,
    couponCode,
    couponName,
    specialName,
  }: {
    items?: OrderedItem[];
    triggeringArea?: string;
    triggeringPoint?: string;
    specialOrdinal?: number;
    couponCode?: string;
    couponName?: string;
    specialName?: string;
  }) {
    if (!this.hasGtmAccountNumber) {
      return;
    }
    let ecommerceItems: EcommerceItem[] = [];
    let special = undefined;
    this.orderService.order$.pipe(take(1)).subscribe(order => {
      const adjustmentAdded = order.adjustments.find(
        adj => (couponCode && adj.couponCode === couponCode) || (couponName && adj.name === couponName)
      );
      const specialAdded = order.specials.find(
        sp => (couponCode && sp.couponCode === couponCode) || (couponName && sp.special === couponName)
      );

      if (adjustmentAdded) {
        // We should not fire an add_to_cart event if an adjustment is added.
        // Since adjustments are not associated with items, we only track them in
        // begin_checkout and purchase events using the EcommerceCart::coupon.
        return;
      } else if (specialAdded) {
        // Utilized if special is added by couponCode or couponName
        ecommerceItems = specialAdded.items.map(item => {
          return {
            item_name: item.printName || item.item,
            item_category: item.category,
            item_category2: triggeringArea,
            item_category3: triggeringPoint,
            item_category4: item.size,
            coupon: specialAdded.special,
            price: this.calcPrice(item.netPrice, item.quantity),
            quantity: item.quantity,
          };
        });

        if (ecommerceItems.length !== 0) {
          this.pushEventToDataLayer({
            event: 'add_to_cart',
            ecommerce: this.createEcommerceCart({
              value: specialAdded.sellingPrice,
              items: ecommerceItems,
            }),
          });
        }
        return;
      }

      if (specialOrdinal >= 0 || !!specialName) {
        // Utilized if we can retrieve the special from the order using the special ordinal
        special = order.specials.find(sp => sp.ordinal === specialOrdinal || sp.special === specialName);
        ecommerceItems = special.items.map(item => {
          return {
            item_name: item.printName || item.item,
            item_category: item.category,
            item_category2: triggeringArea,
            item_category3: triggeringPoint,
            item_category4: item.size,
            coupon: special.special,
            price: this.calcPrice(item.netPrice, item.quantity),
            quantity: item.quantity,
          };
        });
      } else if (items?.length > 0) {
        /**
         * If the last item (e.g. A) that is added to the cart, fulfills a special's requirements then
         * we need to find the rest of the items that were already added to the cart (e.g. B and C, itemsAlreadyInCart)
         * and remove them from the order using their regular prices and not the netPrice (due to being part
         * of a special). In order to do so, the 'ignoreIfPartOfSpecial' param is utilized to remove
         * the itemsAlreadyInCart before adding them again as part of the special along with the item A.
         */
        if (items.length === 1 && items[0].specialOrdinal >= 0 && order?.specials.length) {
          const specialFulfilledByAddedItem = order.specials.find(sp => sp.ordinal === items[0].specialOrdinal);
          const itemsAlreadyInCart = specialFulfilledByAddedItem.items.filter(i => i.itemId !== items[0].itemId);
          if (itemsAlreadyInCart.length !== 0) {
            this.pushRemoveFromCartEvent({
              items: itemsAlreadyInCart,
              triggeringArea,
              triggeringPoint,
              ignoreIfPartOfSpecial: true,
            });
          }
          this.pushAddToCartEvent({
            specialOrdinal: specialFulfilledByAddedItem.ordinal,
            triggeringArea,
            triggeringPoint,
          });
          return;
        } else {
          // Utilized to track any items that are provided by the related argument (items)
          ecommerceItems = items.map(item => {
            return {
              item_name: item.printName || item.item,
              item_category: item.category,
              item_category2: triggeringArea,
              item_category3: triggeringPoint,
              item_category4: item.size,
              price: this.calcPrice(item.sellingPrice, item.quantity),
              quantity: item.quantity,
            };
          });
        }
      }

      if (ecommerceItems.length !== 0) {
        this.pushEventToDataLayer({
          event: 'add_to_cart',
          ecommerce: this.createEcommerceCart({
            value: special ? special.sellingPrice : this.calcTotalPrice(ecommerceItems.map(item => item.price * item.quantity)) || 0,
            items: ecommerceItems,
          }),
        });
      }
    });
  }

  /**
   * The 'ignoreIfPartOfSpecial'should be set to true in order to remove items
   * that are already in cart before adding them again as part of the special.
   */
  public pushRemoveFromCartEvent({
    order,
    items,
    specialName,
    triggeringArea,
    triggeringPoint,
    ignoreIfPartOfSpecial,
  }: {
    order?: Order;
    items?: OrderedItem[];
    specialName?: string;
    triggeringArea?: string;
    triggeringPoint?: string;
    ignoreIfPartOfSpecial?: boolean;
  }) {
    if (!this.hasGtmAccountNumber) {
      return;
    }
    let ecommerceItems: EcommerceItem[] = [];
    if (order) {
      // Utilized when clearing the order
      ecommerceItems = this.createEcommerceItemsFromOrder(order, triggeringArea, triggeringPoint);
    } else if (items.length > 0) {
      ecommerceItems = items.map(item => {
        return {
          item_name: item.printName || item.item,
          item_category: item.category,
          item_category2: triggeringArea,
          item_category3: triggeringPoint,
          item_category4: item.size,
          coupon: !ignoreIfPartOfSpecial && item.specialOrdinal >= 0 ? specialName : undefined,
          price: this.calcPrice(!ignoreIfPartOfSpecial && item.specialOrdinal >= 0 ? item.netPrice : item.sellingPrice, item.quantity),
          quantity: item.quantity,
        };
      });
    }

    this.pushEventToDataLayer({
      event: 'remove_from_cart',
      ecommerce: this.createEcommerceCart({
        value: this.calcTotalPrice(ecommerceItems.map(item => item.price * item.quantity)),
        items: ecommerceItems,
      }),
    });
  }

  public pushBeginCheckoutEvent(triggeringArea: string, triggeringPoint: string) {
    if (!this.hasGtmAccountNumber) {
      return;
    }
    combineLatest([this.orderService.order$, this.groupOrderService.groupOrder$])
      .pipe(take(1))
      .subscribe(([order, go]) => {
        const leaderName = go?.merged && go?.memberPayment ? go?.groupMembers.find(m => m.isLeader).name : undefined;
        const ecommerceItems: EcommerceItem[] = this.createEcommerceItemsFromOrder(order, triggeringArea, triggeringPoint, leaderName);
        this.pushEventToDataLayer({
          event: 'begin_checkout',
          ecommerce: this.createEcommerceCart({
            value: leaderName ? order.due : order.price,
            coupon: order.adjustments.length ? order.adjustments.map(adj => adj.name).join(', ') : undefined,
            items: ecommerceItems,
          }),
        });
      });
  }

  public pushPurchaseEvent(order: Order, groupOrder: GroupOrder) {
    if (!this.hasGtmAccountNumber) {
      return;
    }
    const leaderName = groupOrder?.merged && groupOrder?.memberPayment ? groupOrder?.groupMembers.find(m => m.isLeader).name : undefined;
    const ecommerceItems = this.createEcommerceItemsFromOrder(
      order,
      EcommerceTriggeringArea.CHECKOUT,
      EcommerceTriggeringPoint.PLACE_ORDER_BUTTON,
      leaderName
    );

    let price = 0;
    if (leaderName) {
      if (order.due > 0) {
        price = order.due; // if the leader pays with cash
      } else {
        price = order.payments.length ? order.payments[order.payments.length - 1].amount : 0;
      }
    } else {
      price = order.price;
    }

    this.configService.storeConfig$.pipe(take(1)).subscribe(storeConfig => {
      this.pushEventToDataLayer({
        event: 'purchase',
        ecommerce: this.createEcommerceCart({
          transaction_id: order.orderId,
          value: price,
          affiliation: storeConfig.backOfficeStoreCode,
          coupon: order.adjustments.length ? order.adjustments.map(adj => adj.name).join(', ') : undefined,
          shipping: (order.deliveryCharge || 0) + (order.customerDriverFee || 0),
          tax: order.tax,
          items: ecommerceItems,
        }),
      });
    });
  }

  private getGtmHeaderScript(gtmAccountNumber: string): string {
    return `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmAccountNumber}');`;
  }

  private getGtmBodyScript(gtmAccountNumber: string) {
    return `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmAccountNumber}"
              height="0" width="0" style="display:none;visibility:hidden">
      </iframe>`;
  }

  private pushEventToDataLayer(event: any) {
    if (window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(event);
    }
  }

  private createEcommerceItemsFromOrder(
    order: Order,
    triggeringArea?: string,
    triggeringPoint?: string,
    leaderName?: string
  ): EcommerceItem[] {
    const orderedItems = leaderName ? order.items.filter(i => i.memberName === leaderName) : order.items;
    const ecommerceItems: EcommerceItem[] = orderedItems.map(item => {
      return {
        item_name: item.printName || item.item,
        item_category: item.category,
        item_category2: triggeringArea,
        item_category3: triggeringPoint,
        item_category4: item.size,
        price: this.calcPrice(item.netPrice, item.quantity),
        quantity: item.quantity,
      };
    });

    const orderedSpecials = leaderName ? order.specials.filter(sp => !!sp.items.find(i => i.memberName === leaderName)) : order.specials;
    const specialItems = Utils.flatten(
      orderedSpecials.map(special => {
        return special.items.map(item => {
          return {
            item_name: item.printName || item.item,
            item_category: item.category,
            item_category2: triggeringArea,
            item_category3: triggeringPoint,
            item_category4: item.size,
            coupon: special.special,
            price: this.calcPrice(item.netPrice, item.quantity),
            quantity: item.quantity,
          };
        });
      })
    );

    ecommerceItems.push(...specialItems);

    return ecommerceItems;
  }

  private createEcommerceCart(ecommerceCart: EcommerceCart): EcommerceCart {
    return {
      currency: this.CURRENCY_CODE,
      value: ecommerceCart.value,
      coupon: ecommerceCart.coupon,
      transaction_id: ecommerceCart.transaction_id,
      affiliation: ecommerceCart.affiliation,
      shipping: ecommerceCart.shipping,
      tax: ecommerceCart.tax,
      items: ecommerceCart.items,
    };
  }

  private calcTotalPrice(prices: number[]): number {
    return Math.round(prices.reduce((a, b) => a + b, 0) * 100) / 100 || 0;
  }

  private calcPrice(price: number, quantity: number): number {
    if (quantity <= 1 || Utils.isNullOrUndefined(quantity)) {
      return price;
    }

    return Number((price / quantity).toFixed(2));
  }
}
