import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';

import { Upsell } from './upsell.model';

export class UpsellGroup extends BaseResource {
  title: string;
  upsellItems: Upsell[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      upsellItems: DeserializationUtils.deserializeChildren(input, 'upsellItems', Upsell),
    });
  }
}
