import { ViewportService } from './../../core/services/viewport.service';
import { AcknowledgeGeolocationAddressModalComponentContext } from './../../domain/acknowledge-geolocation-address-modal-context';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
  OnChanges,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  Renderer2,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import {
  AccountAddressesService,
  Address,
  AddressType,
  Audit,
  AuditService,
  ConfigService,
  GenericObject,
  StoreConfig,
  Street,
  Utils,
  CityZip,
  OrderService,
  OrderTypeConfig,
  AccountService,
  AddressValidationResult,
} from 'ngx-web-api';

import { Observable, of, combineLatest, Subscription, Subject, noop } from 'rxjs';
import { catchError, map, tap, filter, concatMap, mergeMap, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AddressWrapperService } from '../../core/services/address-wrapper.service';
import { StreetType } from '../../domain/street-type.enum';
import { ModalService } from '../../core/services/modal.service';
import { AddressSessionStorageService } from '../../core/services/address-session-storage.service';
import { CheckoutDetailsInvalidFieldsFocusService } from '../../core/services/checkout-details-invalid-fields-focus.service';
import { TranslateService } from '@ngx-translate/core';
import { CheckoutService } from 'app/checkout/checkout.service';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons';
import { Position } from '@maplibre/ngx-maplibre-gl';
import { ErrorsService } from 'app/core/services/errors.service';
import { GoogleMapsApiKeyService } from 'app/core/services/google-maps-api-key.service';

@Component({
  selector: 'fts-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  address = new Address();
  @Input()
  isEditing = false;
  @Input()
  isGuest = false;
  @Input()
  horizontal = true;
  @Input()
  showClientValidation = false;
  @Input()
  errors: GenericObject<string[]> = {};
  @Input()
  orderTypeConfig: OrderTypeConfig;
  @Input()
  noContact: boolean;
  @Input()
  deliveryNote: string;
  @Input()
  isInIntro: boolean;

  @Output()
  addressChange = new EventEmitter<Address>();
  @Output()
  streetChange = new EventEmitter<Street>();
  @Output()
  submitSuccess = new EventEmitter<Address>();
  @Output()
  addressErrors = new EventEmitter<string[]>();
  @Output()
  addressTypeChange = new EventEmitter<string>();
  @Output()
  addressHasChanged = new EventEmitter<void>();

  @ViewChild('addressForm', { static: true })
  addressForm: NgForm;
  @ViewChild('streetRef', { static: false })
  streetRef: ElementRef;
  @ViewChild('streetRef', { static: false })
  set streetRefEvent(element: ElementRef) {
    // see: https://github.com/ng-bootstrap/ng-bootstrap/issues/2871
    if (!!element) {
      this.renderer.removeAttribute(element.nativeElement, 'aria-multiline');
    }
  }

  @ViewChildren(NgModel, { read: ElementRef })
  formFields: QueryList<ElementRef>;

  private streetNameValue: Subject<string> = new Subject();
  private zipValue: Subject<string> = new Subject();
  private destroy$: Subject<void> = new Subject<void>();
  private shouldClearSelectedAddress = false;

  message: string;

  showOverrideForStreet = false;
  showOverrideForBuilding = false;
  force = false;
  emptyTypeaheadFetched: boolean;
  addressTypes: AddressType[] = [];
  streets: Observable<Street[]>;

  loadingSubscription: Subscription;
  storeConfig: StoreConfig;

  buildings: Street[] = [];
  selectedBuildingName: string;
  streetTypeEnum = StreetType;
  streetType: StreetType;
  addressType: AddressType;
  buildingsLoading: boolean;
  cityZips: CityZip[];
  streetNumMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  streetTouched = false;
  faLocationArrow = faLocationArrow;
  findAddressSubscription: Subscription;
  findMyLocationError: string;
  public dynamicLoadModal: any;
  public loadingStreets: boolean;

  errorSet: Map<string, string> = new Map([
    ['name', this.translateService.instant('component.address_form.validations.name')],
    ['addressType', this.translateService.instant('component.address_form.validations.addressType')],
    ['street', this.translateService.instant('component.address_form.validations.street')],
    ['streetNum', this.translateService.instant('component.address_form.validations.streetNum')],
    ['buildings', this.translateService.instant('component.address_form.validations.buildings')],
    ['zip', this.translateService.instant('component.address_form.validations.zip')],
  ]);
  constructor(
    private addressService: AddressWrapperService,
    private accountAddressesService: AccountAddressesService,
    private configService: ConfigService,
    private modalService: ModalService,
    private auditService: AuditService,
    private addressSessionStorageService: AddressSessionStorageService,
    private checkoutDetailsInvalidFieldsFocusService: CheckoutDetailsInvalidFieldsFocusService,
    private translateService: TranslateService,
    private orderService: OrderService,
    private checkoutService: CheckoutService,
    private renderer: Renderer2,
    private liveAnnouncer: LiveAnnouncer,
    private accountService: AccountService,
    private errorService: ErrorsService,
    public viewportService: ViewportService,
    private googleMapsKeyService: GoogleMapsApiKeyService
  ) {
    this.loadingStreets = false;
    this.googleMapsKeyService.loadLibrary();
    this.configService.addressTypes$.pipe(takeUntil(this.destroy$)).subscribe(
      (types: AddressType[]) => (this.addressTypes = types),
      error =>
        this.handleError(error, {
          message: this.translateService.instant('component.address_form.address_types'),
        })
    );
    this.configService.storeConfig$.pipe(takeUntil(this.destroy$)).subscribe((storeConfig: StoreConfig) => {
      this.storeConfig = storeConfig;
    });

    this.configService.storeConfig$
      .pipe(
        concatMap((storeConfig: StoreConfig) => {
          if (storeConfig.hideStreetAndZip) {
            return this.addressService.getCities();
          } else {
            return of([]);
          }
        })
      )
      .subscribe((cityZips: CityZip[]) => (this.cityZips = cityZips));

    this.checkoutDetailsInvalidFieldsFocusService.firstInvalidFieldName$
      .pipe(
        map(name => this.formFields.find(el => el.nativeElement.name === name)),
        filter(el => !!el),
        takeUntil(this.destroy$)
      )
      .subscribe((field: ElementRef) => field.nativeElement.focus());
  }

  inputFormatMatches = (value: Street) => value.address || '';
  formatMatches = (value: Street) => value.fullAddress || '';

  streetSearch = (text$: Observable<any>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      mergeMap((term: string) => {
        this.address.street = term;
        if (term && term.length > 2) {
          this.loadingStreets = true;
          return this.addressService.getStreetMatches(term).pipe(map((streets: Street[]) => <[Street[], boolean]>[streets, true]));
        } else {
          return of(<[Street[], boolean]>[[], false]);
        }
      }),
      tap(() => {
        this.loadingStreets = false;
      }),
      tap(([streets, hasFetchedStreets]: [Street[], boolean]) => (this.emptyTypeaheadFetched = streets.length <= 0 && hasFetchedStreets)),
      map(([streets]): Street[] =>
        streets.map(street =>
          new Street().deserialize({
            ...street,
            fullAddress: `${street.address}, ${street.city}, ${street.state} ${street.zip}`,
          })
        )
      ),
      catchError(() => {
        this.loadingStreets = false;
        return of<Street[]>([]);
      })
      // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
    );

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['address'] &&
      this.address &&
      (changes['address'].firstChange || changes['address'].previousValue?.addressType !== changes['address'].currentValue.addressType)
    ) {
      this.getAddressFromSessionStorage();
      this.addressType = this.addressTypes.find(type => type.addressType === this.address.addressType);
      if (!this.addressType) {
        this.address.addressType = undefined;
      }

      if (this.storeConfig.useBuildingsInWeb && !!this.address && !!this.address.addressType) {
        this.buildingsLoading = true;
        this.addressService.getBuildings(this.address.addressType).subscribe((buildings: Street[]) => {
          this.buildingsLoading = false;
          this.buildings = buildings;
          let matchedBuilding = this.buildings.find(b => b.buildingName === this.address.buildingName);
          this.streetType =
            !!this.address.buildingName && this.buildings.length && !!matchedBuilding ? StreetType.BUILDING : StreetType.STREET;
          if (this.streetType === StreetType.BUILDING) {
            this.handleBuildingChange(this.address.buildingName, this.address);
          }
        });
      }
    }
    this.checkAddressTypeLengthOne();
  }

  ngOnInit(): void {
    combineLatest([this.orderService.order$, this.accountService.account$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([_order, _account]) => {
        this.getAddressFromSessionStorage();
      });
    this.checkAddressTypeLengthOne();
    this.setupStreetChangeListener();
    this.initValidationEventListener();

    this.modalService.response.subscribe((result: any) => {
      if (!!result?.matchedStreet) {
        this.setMatchedStreet(result.matchedStreet, result.edit);
      }
    });
  }

  checkAddressTypeLengthOne() {
    if (this.addressTypes?.length === 1 && !this.address.addressType) {
      this.handleAddressTypeChange(this.addressTypes[0].addressType);
    }
  }

  ngAfterViewInit() {
    if (!!this.addressForm) {
      this.addressForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((formValue: any) => {
        this.addressHasChanged.emit();
        this.checkoutService.setAddressFormValid(this.addressForm.valid);
        const errorList = [];
        this.findMyLocationError = '';
        if (this.buildings.length && !this.streetType) {
          errorList.push(this.translateService.instant('component.address_form.please_fill'));
          this.addressErrors.emit(errorList);
          return;
        }
        Object.keys(this.addressForm.controls).forEach(key => {
          this.checkoutDetailsInvalidFieldsFocusService.addAddressFieldName(key);
          const control = this.addressForm.controls[key];
          if (control && control.invalid && control.touched) {
            errorList.push(this.errorSet.get(key) || this.translateService.instant('component.address_form.please_fill'));
          }
        });
        this.addressErrors.emit(errorList);
        this.addressService.isAddressValid(this.isFormValid);
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.checkoutService.setAddressFormValid(true);
  }

  onCityZipChange(zip: string) {
    const cityZip = this.cityZips.find(cz => cz.zip === zip);
    if (cityZip) {
      this.address.city = cityZip.city;
      this.address.zip = cityZip.zip;
    }
  }

  onStreetBlur() {
    this.streetTouched = true;
  }

  onStreetInput(val: string) {
    this.address.street = this.streetRef.nativeElement.value;
    if (!!val) {
      this.addressErrors.emit([]);
    }
  }

  public typeaheadOnSelect(e: NgbTypeaheadSelectItemEvent): void {
    const match: Street = e.item;
    this.address.street = match.address;
    this.address.city = match.city;
    this.address.zip = match.zip;
    this.address.state = match.state;
    this.address.buildingName = match.buildingName;
    this.streetNameValue.next(match.address);
    this.zipValue.next(match.zip);
  }

  get instructionsCharactersLeft(): number {
    const maxLength = this.storeConfig.maxDeliveryInstructionsLength || 0;
    return this.address.deliveryInstructions ? maxLength - this.address.deliveryInstructions.length : maxLength;
  }

  handleZipChange(zip: string) {
    this.zipValue.next(zip);
  }

  handleAddressTypeChange(addressType: string) {
    const previousAddressTypeSelected = this.addressType;
    if (previousAddressTypeSelected) {
      this.addressForm.form.removeControl(this.addressType.diff1);
      this.addressForm.form.removeControl(this.addressType.diff2);
      this.addressForm.form.removeControl(this.addressType.diff3);

      this.errors = null;
    }
    this.address.addressType = addressType;
    this.addressTypeChange.emit(addressType);
    this.addressType = this.addressTypes.find(type => type.addressType === addressType);
    if (this.addressType) {
      // we have to set diff names explicitly
      this.address.diff1 = this.addressType.diff1;
      this.address.diff2 = this.addressType.diff2;
      this.address.diff3 = this.addressType.diff3;

      // cleanup stale values
      this.address.value1 = undefined;
      this.address.value2 = undefined;
      this.address.value3 = undefined;
    }

    if (this.storeConfig.useBuildingsInWeb) {
      if (!Utils.isNullOrUndefined(previousAddressTypeSelected) && !!this.address.street) {
        this.clearSelectedAddress();
      }
      this.buildingsLoading = true;
      this.addressService.getBuildings(addressType).subscribe((buildings: Street[]) => {
        this.buildingsLoading = false;
        this.buildings = buildings;
      });
      this.streetType = undefined;
    }
  }

  handleBuildingChange(buildingName: string, currentAddress?: Address) {
    let selectedBuilding = this.buildings.find(b => b.buildingName === buildingName);
    if (!selectedBuilding && !!currentAddress && currentAddress.street) {
      selectedBuilding = this.buildings.find(b => b.address.toLowerCase() === currentAddress.street.toLowerCase());
    }
    if (!selectedBuilding) {
      this.modalService.openErrorNotificationModal(
        this.translateService.instant('component.address_form.address_error'),
        this.translateService.instant('component.address_form.not_found_building')
      );
      this.clearSelectedAddress();
      return;
    }
    this.selectedBuildingName = buildingName;

    this.address.street = selectedBuilding.address;
    this.address.streetNum = selectedBuilding.streetNum;
    this.address.city = selectedBuilding.city;
    this.address.zip = selectedBuilding.zip;
    this.address.state = selectedBuilding.state;
    this.address.buildingName = selectedBuilding.buildingName;
    this.streetNameValue.next(selectedBuilding.address);
    this.zipValue.next(selectedBuilding.zip);

    if (!!this.selectedBuildingName) {
      this.shouldClearSelectedAddress = true;
    }
  }

  handleStreetTypeChange(streetType: StreetType) {
    if (this.shouldClearSelectedAddress || streetType !== StreetType.STREET) {
      this.clearSelectedAddress();
    }
    if (!this.shouldClearSelectedAddress) {
      this.shouldClearSelectedAddress = true;
    }
    if (streetType === StreetType.STREET) {
      setTimeout(() => this.streetRef.nativeElement.focus(), 1);
    }
  }

  clearSelectedAddress() {
    this.address.street = undefined;
    this.address.streetNum = undefined;
    this.address.city = undefined;
    this.address.zip = undefined;
    this.address.state = undefined;
    this.address.buildingName = undefined;
    this.selectedBuildingName = undefined;
    this.clearErrors();
  }

  handleCreate(): void {
    this.clearErrors();
    if (!this.isFormValid) {
      this.updateValidationErrors();
      return;
    }
    if (this.address.force) {
      this.createAddress();
    } else {
      this.validateNewAddress();
    }
  }

  get isFormValid(): boolean {
    return !!this.addressForm.valid && (!!this.streetRef?.nativeElement.value || this.streetType === StreetType.BUILDING);
  }

  validateNewAddress(): void {
    this.address.name = this.address.name.trim();
    this.loadingSubscription = this.doValidateAddress();
  }

  doValidateAddress(selectAddress?: boolean): Subscription {
    return this.addressService
      .validateNewAddress(this.address.copy())
      .pipe(
        mergeMap(validation => {
          if (!validation.error) {
            const updateAddress$ = selectAddress ? this.orderService.updateOrderCustomerAddress(this.address) : of(null);
            return updateAddress$.pipe(map(selectedAddress => ({ validation, selectedAddress })));
          } else {
            return of({ validation, selectedAddress: null });
          }
        })
      )
      .subscribe(
        ({ validation }) => {
          this.handleValidationAddressError(validation, false);
        },
        err => this.handleError(err, { message: this.translateService.instant('component.address_form.no_validate') })
      );
  }

  handleEdit(): void {
    this.clearErrors();
    if (!this.isFormValid) {
      this.updateValidationErrors();
      return;
    }
    if (this.address.force) {
      this.editAddress();
    } else {
      this.validateExistingAddress();
    }
  }

  handleError(err, action: Audit) {
    if (err.status === 422) {
      this.errors = err.error.errors;
      action.details = this.errors;
    } else if (err.status === 409) {
      this.message = err.error.message;
      action.details = this.message;
    } else {
      this.modalService.openErrorNotificationModal(err);
      action.details = err;
    }
    this.auditService.createAudit(() => action);
  }

  public updateValidationErrors() {
    if (!!this.addressForm) {
      const invalidField = Object.keys(this.addressForm.controls).find(key => this.addressForm.controls[key].invalid);
      if (invalidField) {
        this.addressForm.controls[invalidField].markAsTouched();
        this.addressForm.controls[invalidField].markAsDirty();
        this.addressForm.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      }
      if (!!this.addressType && this.streetType !== StreetType.BUILDING && !this.streetRef?.nativeElement.value) {
        this.onStreetBlur();
        this.addressErrors.emit([this.translateService.instant(this.errorSet.get('street'))]);
      }
      if (this.streetType === StreetType.BUILDING && !this.selectedBuildingName) {
        this.addressErrors.emit([this.translateService.instant(this.errorSet.get('buildings'))]);
      }
    }
  }

  findMyLocation() {
    this.errors = {};
    this.findMyLocationError = '';
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (location: Position) => {
          this.auditService.createAudit(
            () =>
              `Use My Location Detection: Latitude: ${location.coords.latitude.toString()}, Longitude: ${location.coords.longitude.toString()}`
          );
          this.findAddressSubscription = this.addressService
            .findAddress(location.coords.latitude.toString(), location.coords.longitude.toString())
            .pipe(
              tap(matchedStreet => {
                if (!Utils.isNullOrUndefined(this.storeConfig.googleMapsAPIKey)) {
                  this.setMatchedStreet(matchedStreet);
                }

                this.modalService.setContext(
                  new AcknowledgeGeolocationAddressModalComponentContext(
                    location.coords.latitude,
                    location.coords.longitude,
                    matchedStreet
                  ).getConfig()
                );
                this.dynamicLoadModal = import(`../../shared/dynamic-load-modal/dynamic-load-modal.component`).then(
                  ({ DynamicLoadModalComponent }) => DynamicLoadModalComponent
                );
              })
            )
            .subscribe(noop, (error: any) => {
              const errorResponse = this.errorService.parseAllErrorsFromResponse(error);
              const errorMessage = errorResponse.message || errorResponse.allErrors.join(', ');
              this.auditService.createAudit(() => `Use My Location Detection: ${errorMessage}`);
              this.findMyLocationError = errorMessage || this.translateService.instant('component.address_form.no_location');
            });
        },
        (error: any) => {
          this.auditService.createAudit(() => `Use My Location Detection: ${error && error.message}`);
          this.findMyLocationError = this.translateService.instant('component.address_form.no_location');
        },
        { enableHighAccuracy: true }
      );
    } else {
      this.auditService.createAudit(() => `The HTML Geolocation Service is not supported`);
      this.findMyLocationError = this.translateService.instant('component.address_form.no_location');
    }
  }

  private setMatchedStreet(address: any, edit: boolean = false) {
    this.clearSelectedAddress();
    this.auditService.createAudit(() => ({
      message: 'Use My Location Detection (Matched Address)',
      details: address,
    }));
    this.streetType = StreetType.STREET;
    this.address.street = address?.street;
    this.address.city = address?.city;
    this.address.zip = address?.zip;
    this.address.state = address?.state;
    this.address.streetNum = address?.streetNum;
    this.streetNameValue.next(address?.street);
    this.zipValue.next(address?.zip);

    if (!!this.streetRef.nativeElement) {
      setTimeout(() => {
        if (edit) {
          this.streetRef.nativeElement.focus();
        } else if (this.isInIntro) {
          document.getElementById('continue-to-menu')?.focus();
        }
      }, 500);
    }
  }

  private initValidationEventListener() {
    this.checkoutDetailsInvalidFieldsFocusService.forceValidate$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateValidationErrors(), noop);
  }

  private setupStreetChangeListener() {
    combineLatest([this.streetNameValue, this.zipValue])
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(result => !!result[0] && !!result[1])
      )
      .subscribe(result => {
        const street = new Street().deserialize({
          address: result[0],
          zip: result[1],
        });
        this.streetChange.emit(street);
      });
  }

  private createAddress(): void {
    this.address.street = this.address.street.replace(/  +/g, ' ');
    this.loadingSubscription = this.accountAddressesService.addAddress(this.address.copy()).subscribe(
      address => {
        this.address = address;
        this.submitSuccess.emit(address);
        this.addressSessionStorageService.deleteAddress();
        this.liveAnnouncer.announce(this.translateService.instant('component.address_form.add_new_address_success'), 2000);
      },
      err => this.handleError(err, { message: this.translateService.instant('component.address_form.create_failed') })
    );
  }

  private editAddress(): void {
    this.clearErrors();
    this.address.street = this.address.street.replace(/  +/g, ' ');
    this.loadingSubscription = this.accountAddressesService.updateAddress(this.address.copy()).subscribe(
      address => {
        this.address = address;
        this.submitSuccess.emit(address);
      },
      err => this.handleError(err, { message: this.translateService.instant('component.address_form.update_failed') })
    );
  }

  private validateExistingAddress(): void {
    this.loadingSubscription = this.addressService.validateExistingAddress(this.address.copy()).subscribe(
      validation => {
        this.handleValidationAddressError(validation, true);
      },
      err =>
        this.handleError(err, {
          message: this.translateService.instant('component.address_form.existing_no_validate'),
        })
    );
  }

  private getAddressFromSessionStorage() {
    const sessionStoredAddress = this.addressSessionStorageService.getAddress();
    if (!!this.address && !this.address?.street && !!sessionStoredAddress) {
      this.address.addressType = sessionStoredAddress.addressType;
      this.addressType = this.addressTypes.find(type => type.addressType === this.address.addressType);
      this.address.street = sessionStoredAddress.street;
      this.address.streetNum = sessionStoredAddress.streetNum;
      this.address.zip = sessionStoredAddress.zip;
      this.address.lng = sessionStoredAddress.lng;
      this.address.lat = sessionStoredAddress.lat;
      this.address.value1 = sessionStoredAddress.value1;
      this.address.value2 = sessionStoredAddress.value2;
      this.address.value3 = sessionStoredAddress.value3;
      this.address.buildingName = sessionStoredAddress.buildingName;
    }
  }

  private clearErrors() {
    this.findMyLocationError = '';
    this.showOverrideForStreet = false;
    this.showOverrideForBuilding = false;
    this.errors = {};
    this.addressErrors.emit([]);
  }

  private handleValidationAddressError(validation: AddressValidationResult, validateExistingAddress: boolean) {
    if (validation.error && !this.selectedBuildingName) {
      this.errors['street'] = [validation.error];
      this.showOverrideForStreet = validation.overridable;
    } else if (validation.error && this.selectedBuildingName) {
      this.errors['buildings'] = [validation.error];
      this.showOverrideForBuilding = validation.overridable;
      this.showClientValidation = true;
    } else {
      validateExistingAddress ? this.editAddress() : this.createAddress();
    }
  }
}
