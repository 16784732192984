import { Injectable } from '@angular/core';
import { timeZone, setZone } from '../../models/globals';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  public setTimeZone(timezoneIANA: string) {
    setZone(timezoneIANA);
  }

  public getTimeZone(): string {
    return timeZone;
  }
}
