import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Coupon } from 'ngx-web-api';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { CouponInstructionsModalContext } from '../../domain/coupon-instructions-modal-context';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FocusService } from 'app/focus.service';
import { BreakpointService } from 'app/core/services/breakpoint.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'fts-coupon-instructions-modal',
  templateUrl: './coupon-instructions-modal.component.html',
  styleUrls: ['./coupon-instructions-modal.component.scss'],
})
export class CouponInstructionsModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalRef')
  modalRef: ElementRef;

  public context: CouponInstructionsModalContext;
  coupon: Coupon;
  destroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private uiOrchestratorService: UiOrchestratorService,
    public activeModal: NgbActiveModal,
    private focusService: FocusService,
    private breakpoint: BreakpointService
  ) {}

  @HostListener('document:keydown', ['$event'])
  onKeyPress(event) {
    if ((event.key !== undefined && event.key === 'Esc') || event.key === 'Escape') {
      this.hideModal(null);
    }
  }

  ngOnInit() {
    this.coupon = this.context.selectedCoupon;
    this.uiOrchestratorService.informModalOpened();
    this.breakpoint.isTabletDown$.pipe(takeUntil(this.destroy$)).subscribe(isMobile => {
      if (isMobile) {
        setTimeout(() => this.focusService.activateFocusTrap(this.modalRef.nativeElement));
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hideModal(res): void {
    this.activeModal.close(res);
  }

  handleLogin(): void {
    this.router.navigate(['/login']);
    this.hideModal(null);
  }
}
