import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeaturedItem } from 'ngx-web-api';

@Component({
  selector: 'fts-featured-content-list',
  templateUrl: './featured-content-list.component.html',
  styleUrls: ['./featured-content-list.component.scss'],
})
export class FeaturedContentListComponent {
  @Input()
  showVertically: boolean;
  @Input()
  fillParentHeight: boolean;
  @Input()
  featuredItems: FeaturedItem[];
  @Input()
  skipLazyLoading: boolean;
  @Input()
  itemImgHeight: number;
  @Output()
  chosenFeaturedItem: EventEmitter<FeaturedItem & { __slotNum?: string }> = new EventEmitter();
  @Output()
  loadImageHeight: EventEmitter<number> = new EventEmitter();

  private maxFontSize = 100;
  private fontReducerFactor = 10;

  get scaledFontSize() {
    return `${this.featuredItems.length > 1 ? this.maxFontSize - this.fontReducerFactor * this.featuredItems.length : this.maxFontSize}%`;
  }

  onLoadImage(imageHeight: number) {
    if (!!imageHeight) {
      this.loadImageHeight.emit(imageHeight);
    }
  }

  onFeaturedItemChosen(featuredItem: FeaturedItem & { __slotNum?: string }, index: number) {
    featuredItem.slotNum = `${index}`;
    this.chosenFeaturedItem.emit(featuredItem);
  }
}
