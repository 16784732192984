import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { OrderType } from '../../models/core/order-type.enum';
import { FeaturedContentName } from '../../models/menu/featured-content-name.enum';
import { FeaturedContent } from '../../models/menu/featured-content.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';

@Injectable({ providedIn: 'root' })
export class FeaturedContentService {
  private featuredContents: BehaviorSubject<FeaturedContent[]> = new BehaviorSubject([]);
  public featuredContents$: Observable<FeaturedContent[]> = this.featuredContents.asObservable();

  constructor(private http: HttpClient) {}

  public fetchFeaturedContents(orderType: OrderType, orderTime?: string): Observable<FeaturedContent[]> {
    let query = `orderType=${orderType.toString()}`;
    if (orderTime) {
      query = query.concat(`&date=${encodeURIComponent(orderTime)}`);
    }

    return this.http.get(`/ws/integrated/v1/menu/featuredContent?${query}`).pipe(
      map((json: Object[]) => DeserializationUtils.deserializeArray(json, FeaturedContent)),
      tap((featureContents: FeaturedContent[]) => this.featuredContents.next(featureContents))
    );
  }

  getFeaturedContentsByAreaName(areaName: FeaturedContentName): Observable<FeaturedContent[]> {
    return this.featuredContents$.pipe(
      filter(f => f !== null),
      map((featuredContents: FeaturedContent[]) => featuredContents.filter(featuredContent => featuredContent.areaName === areaName))
    );
  }
}
