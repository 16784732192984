<ng-container *ngIf="!radioStyle">
<select *ngIf="!!storeStates && storeStates.length > 1 || closing" [ngModel]="selectedStoreState" (ngModelChange)="onStoreStateChange($event)"
        class="store-states form-control primary-bg-color primary-text-color"
        [attr.aria-label]="'component.header.store_info.store_selection.merchant_store_selector.state' | translate">
  <option *ngFor="let storeState of storeStates" [value]="storeState">{{ storeState }}</option>
</select>

<select *ngIf="!!merchantStores && !!merchantStores.length" [disabled]="loadingMerchantStores" [ngModel]="selectedMerchantStore" (ngModelChange)="onMerchantStoreChange($event)"
        class="form-control primary-bg-color primary-text-color"
        [attr.aria-label]="'component.header.store_info.store_selection.merchant_store_selector.store' | translate">
  <option *ngIf="loadingMerchantStores">{{'component.header.store_info.store_selection.merchant_store_selector.loading' | translate}} ...</option>
  <ng-container *ngIf="!loadingMerchantStores">
    <option *ngFor="let merchantStore of merchantStores" [ngValue]="merchantStore">
      {{!!merchantStore.name ? merchantStore.name + ' - ' : ''}}{{merchantStore.address}}{{!!merchantStore.city ? ', ' + merchantStore.city : ''}}{{!!merchantStore.state ? ', ' + merchantStore.state : ''}}{{!!merchantStore.zip ? ', ' + merchantStore.zip : ''}}
    </option>
  </ng-container>
</select>

<div *ngIf="!loadingMerchantStores && !merchantStores?.length && !!currentStoreAddress" class="primary-text-color store-info-text">{{currentStoreAddress}}</div>
</ng-container>

<ng-container *ngIf="radioStyle">
  <div class="loader action-text-color" *ngIf="loadingMerchantStores">
    <fts-loading-indicator></fts-loading-indicator>
  </div>

  <cdk-virtual-scroll-viewport *ngIf="!loadingMerchantStores" itemSize="50" [ngStyle]="{'height': !groupedStores?.length ? '0px' : !isTabletDown ? '100%' : (containerHeight + 'px')}">
    <div class="font-secondary" *cdkVirtualFor="let merchantStore of groupedStores">
      <div *ngIf="!!merchantStore._group" class="primary-text-color city-label text-uppercase">{{merchantStore._group}}</div>
      <div *ngIf="!merchantStore._group"
        class="store-selection"
        [ngClass]="{'secondary-bg-color' : !!selectedMerchantStore && selectedMerchantStore?.primaryKey === merchantStore?.primaryKey, 'border-bt' : merchantStore.isCurrentStore}">
          <input type="radio"
                name="store-selector-radio-group"
                #inputRef
                [ngModel]="selectedMerchantStore"
                (ngModelChange)="onMerchantStoreChange($event)"
                [id]="merchantStore.address"
                [value]="merchantStore">
            <label [for]="merchantStore.address" class="label-wrapper primary-text-color"
                   [ngClass]="{'secondary-text-color no-events' : !!selectedMerchantStore && selectedMerchantStore?.primaryKey === merchantStore?.primaryKey}">
              <fa-icon class="address-icon" [fixedWidth]="true" [icon]="merchantStore?.isCurrentStore ? faCheck : faFtsLocation"></fa-icon>
              <div class="store-text">
                {{merchantStore.address}}{{!!merchantStore.zip ? ', ' + merchantStore.zip : ''}}
                <div *ngIf="!merchantStore?.isCurrentStore && !!merchantStore.distance" class="smaller text-uppercase">
                  {{merchantStore.distance | number:'0.2-2'}}&nbsp;<span translate>component.header.store_info.store_selection.merchant_store_selector.miles</span>
                </div>
                <div *ngIf="merchantStore?.isCurrentStore" class="smaller text-uppercase">
                  <span translate>component.header.store_info.store_selection.merchant_store_selector.current_store</span>
                </div>
              </div>
            </label>
            <button ftsAuditableButton auditName="GO" [@fadeInOut]
              *ngIf="!merchantStore?.isCurrentStore && !!selectedMerchantStore && selectedMerchantStore?.primaryKey === merchantStore?.primaryKey"
              (click)="onChangeStore()" class="btn btn-sm go-btn action-bg-color action-inverse-text-color text-uppercase font-secondary" translate>
                component.header.store_info.store_selection.merchant_store_selector.go
            </button>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>