import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Audit } from '../../models/core/audit.model';

@Injectable({ providedIn: 'root' })
export class AuditService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/audits';

  constructor(private http: HttpClient) {}

  public createAudit(auditCallback: () => string | Audit, referrer?: string): void {
    try {
      let headers = new HttpHeaders();
      if (!!referrer) {
        headers = headers.set('x-fts-referrer', referrer);
      }
      let audit = auditCallback();
      if (typeof audit === 'object') {
        const { message, details = null } = audit;
        audit = [message, details ? JSON.stringify(details) : ''].join(': ');
      }
      this.http.post(this.BASE_PATH, { audit }, { headers }).subscribe(
        () => {},
        () => {}
      );
    } catch (error) {
      this.http.post(this.BASE_PATH, { audit: error }).subscribe(
        () => {},
        () => {}
      );
    }
  }
}
