import { BaseResource } from '../core/base-resource';

export class GoCartTransactionInfo extends BaseResource {
  networkTransactionId: string;
  gatewayTransactionId: string;
  gatewayResponse: string;

  deserialize(input: any): this {
    return super.deserialize(input);
  }

  serialize(): any {
    return super.serialize();
  }
}
