import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import * as elementResizeDetector from 'element-resize-detector';

@Directive({
  selector: '[ftsDimensions]',
})
export class DimensionsDirective implements OnInit {
  public observer: any;

  @Output()
  onDimensionsChange: EventEmitter<any> = new EventEmitter();

  constructor(public el: ElementRef) {}

  ngOnInit(): void {
    const { nativeElement } = this.el;
    const event = new CustomEvent('dimensions');

    this.observer = elementResizeDetector({ strategy: 'scroll' });
    this.observer.listenTo(nativeElement, element => {
      event['dimensions'] = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };
      this.onDimensionsChange.emit(event);
    });
  }
}
