import { Component, OnInit } from '@angular/core';
import { Utils, AuditService } from 'ngx-web-api';
import { Observable, Subscription, Subject, of } from 'rxjs';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { NotificationModalType } from '../../domain/notification-modal-type.enum';
import { take, mergeMap, map, tap, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalContext } from 'app/domain/confirmation-modal-context';

@Component({
  selector: 'fts-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  result: any;
  result$: Observable<any>;
  submit$: Subject<boolean> = new Subject<boolean>();

  public context: ConfirmationModalContext;
  title: string;
  dismissBtnTitle: string;
  submitBtnTitle: string;
  hasSubmitBtn: boolean;
  hasDismissBtn: boolean;
  textOnly: boolean;
  mainContent: string;
  loadingSubscription: Subscription;
  loading: Subject<boolean> = new Subject<boolean>();
  loading$: Observable<boolean>;
  type: NotificationModalType;
  submitObservable: Observable<any>;
  reverseButtons: boolean;
  needDismissAudit: boolean;
  hideListStyle: boolean;

  headerBackgroundClass: String;

  private headerBackgroundClassesSet: Map<String, String> = new Map([
    [NotificationModalType.success, 'success-bg-color'],
    [NotificationModalType.info, 'info-bg-color'],
    [NotificationModalType.warning, 'alert alert-danger'],
    [NotificationModalType.error, 'alert alert-danger'],
  ]);

  constructor(
    private uiOrchestratorService: UiOrchestratorService,
    private translateService: TranslateService,
    public activeModal: NgbActiveModal,
    private auditService: AuditService
  ) {}

  ngOnInit() {
    this.title = this.context.title;
    this.dismissBtnTitle = this.context.dismissBtnTitle || this.translateService.instant('component.confirmation_modal.cancel');
    this.submitBtnTitle = this.context.submitBtnTitle || this.translateService.instant('component.confirmation_modal.save');
    this.hasSubmitBtn = Utils.isNullOrUndefined(this.context.hasSubmitBtn) ? true : this.context.hasSubmitBtn;
    this.hasDismissBtn = Utils.isNullOrUndefined(this.context.hasDismissBtn) ? true : this.context.hasDismissBtn;
    this.mainContent = this.context.mainContent;
    this.submitObservable = this.context.submitObservable;
    this.textOnly = this.context.textOnly;
    this.type = this.context.type;
    this.reverseButtons = this.context.reverseButtons;
    this.headerBackgroundClass = this.headerBackgroundClassesSet.get(this.type);
    this.needDismissAudit = this.context.needDismissAudit;
    this.hideListStyle = this.context.hideListStyle;

    this.uiOrchestratorService.informModalOpened();

    this.submit$
      .pipe(
        tap(() => {
          if (this.result) {
            this.loadingSubscription = this.loading$.subscribe();
          }
        }),
        mergeMap((): any => {
          if (this.result) {
            return this.submitObservable;
          } else {
            return of(false);
          }
        }),
        map(res => !!res),
        finalize(() => this.loading.next(true))
      )
      .subscribe(res => {
        this.activeModal.close(true);
      });

    this.loading$ = this.loading.pipe(take(1));
  }

  hideModal(): void {
    this.activeModal.close(false);
    this.result = false;
    this.submit$.next(false);
    if (this.needDismissAudit) {
      this.auditService.createAudit(() => `${this.title} modal has been dismissed`);
    }
  }

  public handleDismiss() {
    this.activeModal.close(null);
    this.result = false;
    this.submit$.next(false);
  }

  public handleSubmit(): void {
    this.result = true;
    this.submit$.next(true);
  }
}
