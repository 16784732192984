import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slugify',
})
export class SlugifyPipe implements PipeTransform {
  transform(str: string = ''): any {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9\s&\-!]/g, '')
      .replace(/\s/g, '-');
  }
}
