import { BaseResource } from './base-resource';
import { FieldDisplayPolicy } from './field-display-policy.enum';
import { HalfPricePolicy } from './half-price-policy.enum';
import { AllowRobots } from './allow-robots.enum';
import { ExtraIngredientUpsellStrategy } from '../menu/extra-ingredient-upsell-strategy.enum';
import { PrivacyPolicyAcknowledgment } from './privacy-policy-acknowledgment.enum';
import { FieldVerificationMethod } from './field-verification-method.enum';
import { StoreFinderMethod } from './store-finder-method.enum';
import { BusinessReviewSite } from './business-review-site.model';
import { HideAccountCoupons } from './hide-account-coupons.enum';
import { DeleteSpecial } from './delete-special.enum';
import { CheckoutUpsellArea } from './checkout-upsell-area.enum';
import { SmsSubscriptionOption } from './sms-subscription-option.enum';

export class StoreConfig extends BaseResource {
  readonly address: string;
  readonly addressDiff1ValueMaxLength: number;
  readonly addressDiff2ValueMaxLength: number;
  readonly addressDiff3ValueMaxLength: number;
  readonly addressMaxLength: number;
  readonly allowCustomerInstructions: boolean;
  readonly allowGiftAwardsForSelfOrders: boolean;
  allowGroupWebOrdering: boolean;
  readonly allowRobots: AllowRobots;
  readonly allowUnregisteredUsersToOrder: boolean;
  readonly allowUnverifiedUsersToOrder: boolean;
  readonly allowWebGiftCardPayments: boolean;
  readonly askForAddressUpfront: boolean;
  readonly backOfficeStoreCode: string;
  readonly caloriesDisclaimer: string;
  readonly careersQuestionnaireLink?: string;
  readonly certificateLink: string;
  readonly certificateLogo: string;
  readonly checkoutPhoneFieldNote: string;
  readonly city: string;
  readonly contactQuestionnaireLink?: string;
  readonly contentPageFooter: string;
  readonly webOrderingIntroDisclaimer: string;
  readonly corporateName: string;
  readonly corporatePhone: string;
  readonly customCssUrl: string;
  readonly customerInstructionsDisclaimer: string;
  readonly defaultOrderType: string;
  readonly documentTitle: string;
  readonly documentMetaDescription: string;
  readonly egiftNote: string;
  readonly enforcedHalvesRoundDown: boolean;
  readonly facebookAppId: string;
  readonly favicon: string;
  readonly foodtecLogo: string;
  readonly giftCardPurchaseAmountSuggestions: number[];
  readonly giftNumberLength: number;
  readonly giftPinMaxLength: number;
  readonly giftPinMinLength: number;
  readonly giftCardsMayRequirePin: boolean;
  readonly googleMapsAPIKey: string;
  readonly gtmAccountNumber: string;
  readonly guestCheckoutEmailFieldNote: string;
  readonly guestCheckoutEmailPolicy: FieldDisplayPolicy;
  readonly halfPricePolicy: HalfPricePolicy;
  readonly halfPricePolicyAsWhole: boolean;
  readonly hasCreditCard: boolean;
  readonly hasEGift: boolean;
  readonly hasEmailSurveys: boolean;
  readonly hasGiftCard: boolean;
  readonly hasLoyalty: boolean;
  readonly hasStoreChooser: boolean;
  readonly hideStreetAndZip: boolean;
  readonly houseAccountsRequireName: boolean;
  readonly houseAccountsRequirePhone: boolean;
  readonly infoMessageOnOutOfMap: string;
  readonly itemizeTaxRates: boolean;
  readonly latitude: number;
  readonly logo: string;
  readonly logoRedirectToStaticSite: boolean;
  readonly longitude: number;
  readonly maxDeferDays: number;
  readonly maxDeliveryInstructionsLength: number;
  readonly maxSpecialInstructionsLength: number;
  readonly name: string;
  readonly needsLastName: boolean;
  readonly needsPhoneConfirmation: boolean;
  readonly oldWebOrderingUrl: string;
  readonly orderTrackerRequireOrderNumber: boolean;
  readonly orderTrackerUsesCarIcons: boolean;
  readonly osmStyleURL: string;
  readonly osmTileServerURL: string;
  readonly phone: string;
  readonly popularEmailDomains: string[];
  readonly recaptchaSiteKey: string;
  readonly release: string;
  readonly sentryDSN: string;
  readonly showCalories: boolean;
  readonly showCaloriesForSizeUpsells: boolean;
  readonly showCaloriesOnCheckout: boolean;
  readonly showCharities: boolean;
  readonly showFoodFeedback: boolean;
  readonly showLifetimeLoyaltyPoints: boolean;
  readonly showWebsiteFeedback: boolean;
  readonly skipRegistrationRecaptcha: boolean;
  readonly state: string;
  readonly staticSiteUrl: string;
  readonly tileServerAttribution: string;
  readonly timeZone: string;
  readonly useBuildingsInWeb: boolean;
  readonly usesAutomatedPromiseTime: boolean;
  readonly webAddressInstructionsNote?: string;
  readonly webCheckoutCouponsNote: string;
  readonly webCheckoutCreditCardNote: string;
  readonly webClosedForVacation: boolean;
  readonly webNextOpenDayMessage: string;
  readonly webOrderingServiceName: string;
  readonly webOrderingServiceChargeDescription: string;
  readonly webPhoneFieldNote: string;
  readonly webVacationMessage: string;
  readonly xtraLabel: string;
  readonly zip: string;
  readonly groupOrderVideoLink: string;
  readonly showEGiftButton: boolean;
  readonly eGiftPageLink?: string;
  readonly notificationsEmployeeName: string;
  readonly notificationsEmployeePhoto: string;
  readonly notificationsEmployeeRole: string;
  readonly everythingBut: string;
  readonly justWhenNothing: boolean;
  readonly enumerateEverything: boolean;
  readonly everythingButThreshold: number;
  readonly prefixNoEverything: boolean;
  readonly hasFoodtecGC: boolean;
  readonly canonicalLinkURL: string;
  readonly privacyPolicyUrl: string;
  readonly termsAndConditionsUrl: string;
  readonly useInternationalStreetFormat: boolean;
  readonly hasLoyaltyBadges: boolean;
  readonly extraIngredientUpsellStrategy: ExtraIngredientUpsellStrategy;
  readonly privacyPolicyAcknowledgment: PrivacyPolicyAcknowledgment;
  readonly needsEmailConfirmation: boolean;
  readonly fieldVerificationMethod: FieldVerificationMethod;
  readonly showApplicableSpecialsOnCheckout: boolean;
  readonly storeFinderMethod: StoreFinderMethod;
  readonly businessReviewSites: BusinessReviewSite[];
  readonly hasSMSProvider: boolean;
  readonly hideAccountCoupons: HideAccountCoupons;
  readonly showHalfNoDefault: boolean;
  readonly allowWebCouponCodesUpFront: boolean;
  readonly showExpandedIngredientList: boolean;
  readonly smsCampaigns: boolean;
  readonly supportThirdPartyGiftCards: boolean;
  readonly allowWebCashTips: boolean;
  readonly hasFavoriteOrder: boolean;
  readonly hasThirdPartyLoyalty: boolean;
  readonly hasOrderTypeUpsells: boolean;
  readonly deleteSpecial: DeleteSpecial;
  readonly showExpandedSpecialItemList: boolean;
  readonly hasItemGroupBatching: boolean;
  readonly checkoutUpsellArea: CheckoutUpsellArea;
  readonly itemInstructionsExpanded: boolean;
  readonly showSizeSpecificIngredients: boolean;
  readonly smsSubscriptions: SmsSubscriptionOption;
  readonly itemWebDescriptionInEditor: boolean;
  readonly pushNotificationCampaigns: boolean;
  readonly supportReceiptViaEmail: boolean;
  readonly isZipRequired: boolean;
  readonly registrationSpecial: string;
  readonly pushNotifications: boolean;
  readonly showIngredientPricesOnOrder: boolean;
  readonly startCalendarWeekOn: number;
  readonly needsLocationAccess: boolean;
  readonly hasSmsOffersCoupon: boolean;

  get giftNumberMask() {
    const result: (RegExp | string)[] = [];
    const numOfTriplets = Math.floor(this.giftNumberLength / 3);
    const numOfDashes = numOfTriplets - 1;
    const remainder = this.giftNumberLength % 3;

    for (let i = 0, dashes = numOfDashes; i < numOfTriplets; i++) {
      result.push(/\d/, /\d/, /\d/);
      if (dashes > 0) {
        result.push('-');
        dashes--;
      }
    }

    for (let j = 0; j < remainder; j++) {
      result.push('-');
      result.push(/\d/);
    }

    return result;
  }

  get giftPinMask() {
    let result;

    if (this.giftPinMinLength === this.giftPinMaxLength) {
      result = [];
      for (let i = 0; i < this.giftPinMaxLength; i++) {
        result.push(/\d/);
      }
    }

    return result;
  }

  get isInitialized(): boolean {
    return !!this.name;
  }

  get storeFullAddressWithPhone(): string {
    return [this.name, this.address, this.zip, this.city, this.state, this.phone].filter(v => !!v).join(', ');
  }

  get fullAddress(): string {
    return `${[this.address, this.city, this.state].filter(v => !!v).join(', ')} ${this.zip || ''}`;
  }

  get supportsSmsSubscriptions(): boolean {
    return !![SmsSubscriptionOption.SIMPLE, SmsSubscriptionOption.DOUBLE].find(option => option === this.smsSubscriptions);
  }
}
