import {
  Directive,
  HostBinding,
  Input,
  AfterViewInit,
  ElementRef,
  HostListener,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { BreakpointService } from 'app/core/services/breakpoint.service';

@Directive({
  selector: 'img[ftsPicture]',
  exportAs: 'pictureRef',
})
export class PictureDirective implements AfterViewInit, OnChanges {
  @Input()
  imgSrc: string;
  @Input()
  imgSrcset: string;
  @Output()
  pictureSet: EventEmitter<void> = new EventEmitter();
  @Input()
  skipLazyLoading: boolean;
  @Input()
  isFeatureItem: boolean;
  @Input()
  imgName: string;

  isLoaded = false;
  private imgsrc?: string;
  private imgsrcset?: string;
  private initPosition?: string;
  isMobile = false;

  constructor(private el: ElementRef, private breakPointService: BreakpointService) {
    this.el.nativeElement.style.height = '0px';
    this.initPosition = this.el.nativeElement.style.position;
    this.el.nativeElement.style.position = 'absolute';
    this.breakPointService.isTabletDown$.subscribe(isMobile => (this.isMobile = isMobile));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['skipLazyLoading'] && this.skipLazyLoading && !this.isLoaded) {
      this.setPicture();
    }
    if (changes['imgSrc']) {
      this.setPicture();
    }
  }

  @HostBinding('src')
  public get src() {
    return this.imgSrc ? this.imgsrc : '';
  }

  @HostBinding('srcset')
  public get srcset() {
    return this.imgSrcset ? this.imgsrcset : '';
  }

  @HostListener('load', ['$event'])
  onLoad(event) {
    this.el.nativeElement.style.height = 'auto';
    this.el.nativeElement.style.position = this.initPosition;
    if (this.el.nativeElement.parentNode.childNodes[0] != this.el.nativeElement) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement.parentNode.childNodes[0]);
    }
    this.isLoaded = true;
  }

  setPicture(): void {
    if (this.isFeatureItem) {
      const mobileSrcsetImage = this.imgSrcset?.split(', ').find(srcSetPic => srcSetPic.includes(' mobile'));
      if (!!mobileSrcsetImage) {
        if (this.isMobile) {
          this.imgsrc = mobileSrcsetImage.substring(0, mobileSrcsetImage.lastIndexOf(' '));
        } else {
          this.imgsrc = this.imgSrc;
        }
        this.imgsrcset = '';
      } else {
        this.imgsrc = this.imgSrc;
        this.imgsrcset = this.imgSrcset;
      }
    } else {
      this.imgsrc = this.imgSrc;
      this.imgsrcset = this.imgSrcset;
      this.pictureSet.emit();
    }
  }

  ngAfterViewInit() {
    this.el.nativeElement.parentNode.insertAdjacentHTML(
      'afterbegin',
      '<span style="opacity: 0; height: 1px !important; width: 0px !important; position: absolute;"> b </span>'
    );
    this.canLazyLoad() ? this.lazyLoadImage() : this.setPicture();
  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window && !this.skipLazyLoading;
  }

  private lazyLoadImage() {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.setPicture();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }
}
