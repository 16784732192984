import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from './base-resource';
import { CreditCardType } from './credit-card-type.model';
import { TsysTokenConfig } from '../ordering/tsys-token-config';
import { GoCartConfig } from '../ordering/go-cart-config';

export class CreditCardConfig extends BaseResource {
  readonly needsBillingAddress: boolean;
  readonly needsBillingZip: boolean;
  readonly billingAddressNote: string;
  readonly needsCvv: boolean;
  readonly payeezyConfig: Object;
  readonly supportsTokenization: boolean;
  readonly needsCcTermsAcknowledgement: boolean;
  readonly ccTerms: string;
  readonly usesExternalPaymentPage: boolean;
  readonly creditCardTypes: CreditCardType[];
  readonly allowAlphanumericBillingZipCodes: boolean;
  readonly supportsApplePay: boolean;
  readonly tsysTokenConfig: TsysTokenConfig;
  readonly requireCardHolderName: boolean;
  readonly usesRecaptchaValidation: boolean;
  readonly recaptchaCcSiteKey: string;
  readonly goCartConfig: GoCartConfig;

  get supportsGoCart(): boolean {
    return !!this.goCartConfig?.hostUrl && !!this.goCartConfig?.merchantId;
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      creditCardTypes: DeserializationUtils.deserializeChildren(input, 'creditCardTypes', CreditCardType),
    });
  }
}
