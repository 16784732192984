export enum CouponErrorType {
  ADJUSTMENT_FAILED = 'ADJUSTMENT_FAILED',
  ADJUSTMENT_NOT_IN_EFFECT = 'ADJUSTMENT_NOT_IN_EFFECT',
  ADJUSTMENT_TIME_RESTRICTED = 'ADJUSTMENT_TIME_RESTRICTED',
  ALREADY_USED = 'ALREADY_USED',
  APPLICATION_FAILED = 'APPLICATION_FAILED',
  APPLICATION_FAILED_FROM_SOURCE = 'APPLICATION_FAILED_FROM_SOURCE',
  CAMPAIGN_EXPIRED = 'CAMPAIGN_EXPIRED',
  CAMPAIGN_VALID_AFTER = 'CAMPAIGN_VALID_AFTER',
  CAMPAIGN_WILL_EXPIRE = 'CAMPAIGN_WILL_EXPIRE',
  CODE_IS_EMPTY = 'CODE_IS_EMPTY',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  EXCEEDED_MAX_OCCURRENCES = 'EXCEEDED_MAX_OCCURRENCES',
  GENERIC_ERROR = 'GENERIC_ERROR',
  HAS_EXPIRED = 'HAS_EXPIRED',
  INCOMPATIBLE = 'INCOMPATIBLE',
  INVALID_CONFIGURATION = 'INVALID_CONFIGURATION',
  INVALID_SPECIAL = 'INVALID_SPECIAL',
  IS_EXCLUDED = 'IS_EXCLUDED',
  NOT_AVAILABLE_FOR_ACCOUNT = 'NOT_AVAILABLE_FOR_ACCOUNT',
  NOT_FOUND = 'NOT_FOUND',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  NOT_VERIFIED = 'NOT_VERIFIED',
  NULL_ORDER_TYPE = 'NULL_ORDER_TYPE',
  ORDER_NOT_PRESENT = 'ORDER_NOT_PRESENT',
  SPECIAL_NOT_IN_EFFECT = 'SPECIAL_NOT_IN_EFFECT',
  SPECIAL_TIME_RESTRICTED = 'SPECIAL_TIME_RESTRICTED',
  UNAPPLICABLE = 'UNAPPLICABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  WILL_EXPIRE = 'WILL_EXPIRE',
  UNAVAILABLE_MOBILE = 'UNAVAILABLE_MOBILE',
  APPLICATION_WITH_SWAP = 'APPLICATION_WITH_SWAP',
}
