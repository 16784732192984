export enum EcommerceTriggeringArea {
  MENU = 'Menu',
  RECENTLY_ORDERED = 'Recently Ordered',
  ITEM_EDITOR = 'Item Editor',
  SPECIAL_WIZARD = 'Special Wizard',
  ORDER_TREE = 'Order Tree',
  COUPONS_PAGE = 'Coupons page',
  MY_ORDERS_PAGE = 'My Orders page',
  CHECKOUT = 'Checkout',
  URL = 'URL',
  FEATURED_AREA = 'Featured Area',
  UPSELL = 'Upsell',
  COUPON_MODAL = 'Coupon Modal',
}
