import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import {
  ERROR_HANDLER_OPTIONS,
  ExtensiveUrlSerializer,
  FtsErrorHandler,
  FTS_CONFIG,
  XRequestedWithInterceptor,
  XsrfInterceptor,
  TRANSLATION_SERVICE_TOKEN,
  ErrorHandlerOptions,
  FtsErrorReporterService,
} from 'ngx-web-api';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './routing.module';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AcceptInterceptor } from './core/interceptors/accept-interceptor';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCcAmex, faCcDinersClub, faCcDiscover, faCcJcb, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateFormatJsCompiler } from 'ngx-translate-formatjs-compiler';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons';
import { CsrfInterceptor } from './core/interceptors/csrf-interceptor';
import { TranslationInjectionService } from './core/services/translation-injection.service';
import { NetworkErrorInterceptor } from './core/interceptors/network-error-interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_BASE_HREF } from '@angular/common';
import { getCwoBaseHref } from '../assets/scripts/fts-cwo.js';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateFormatJsCompiler,
      },
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: () => {
        return getCwoBaseHref();
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XRequestedWithInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XsrfInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkErrorInterceptor,
      multi: true,
    },
    {
      provide: FTS_CONFIG,
      useValue: {
        ftsClient: 'latte',
      },
    },
    { provide: UrlSerializer, useClass: ExtensiveUrlSerializer },
    {
      provide: ERROR_HANDLER_OPTIONS,
      useValue: ({
        environment: environment.production ? 'production' : 'development',
        skipEmailReporting: true,
        // Filtering Sentry documentation :
        // https://docs.sentry.io/platforms/javascript/configuration/filtering/
        ignoreErrors: [
          // Random plugins/extensions
          'top.GLOBALS',
          // Facebook borked
          'fb_xd_fragment',
          // Errors related to 33779 issue ID
          'ChunkLoadError',
          `UnhandledRejection: "Timeout"`,
          `UnhandledRejection: Non-Error promise rejection captured with value: Timeout`,
          `NetworkError: HTTP Request Failed`,
          `UnsubscriptionError`,
        ],
        denyUrls: [
          // Facebook related
          /graph\.facebook\.com/i,
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
        ],
        sampleRate: 1,
        beforeSend: 'DEFAULT',
        defaultThrottleTimeInMs: 10000,
      } as any) as ErrorHandlerOptions,
    },
    FtsErrorReporterService,
    { provide: ErrorHandler, useClass: FtsErrorHandler },
    {
      provide: TRANSLATION_SERVICE_TOKEN,
      useClass: TranslationInjectionService,
    },
  ],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faCcDinersClub, faCreditCard, faCcVisa, faCcMastercard, faCcAmex, faCcDiscover, faCcJcb);
  }
}
