import { BaseResource } from '../core/base-resource';

export class ChoiceValidationResult extends BaseResource {
  valid = true;

  hasInvalidIngredients = false;

  minIngredients?: number;
  maxIngredients?: number;
  exactIngredients?: number;
  currentIngredients?: number;

  shortMessage?: string;
  longMessage?: string;
}
