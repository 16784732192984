import { BaseResource } from '../core/base-resource';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { Group } from './group.model';

export class TagTab extends BaseResource {
  keyName: string;
  name: string;
  image: string;
  sortIndex: number;
  mobileImage: string;
  description: string;
  groups: Group[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      groups: DeserializationUtils.deserializeChildren(input, 'groups', Group),
    });
  }
}
