import { DeserializationUtils } from '../../utils/deserialization-utils';
import { ValidatedResource } from '../core/validated-resource';
import { OrderedItem } from './ordered-item.model';
import { UpsellType } from './upsell-type.enum';

export class OrderedSpecial extends ValidatedResource {
  ordinal: number;
  special: string;
  panelItemId: string;
  couponCode: string;
  sellingPrice: number;
  items: OrderedItem[];
  discount: number;
  isUpsell: boolean;
  featuredContentName?: string;
  featuredAreaName?: string;
  upsellType?: UpsellType;
  bucketPrintedItems: string[];

  hasNoItems() {
    return !this.items || this.items.length === 0;
  }

  isValid(): boolean {
    return (!this.warnings || !this.warnings.length) && !this.errors && (this.hasNoItems() || !this.items.some(item => !item.isValid()));
  }

  get hasValidItems(): boolean {
    return this.hasNoItems() || !this.items.some(item => !item.isValid());
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      items: DeserializationUtils.deserializeChildren(input, 'items', OrderedItem),
    });
  }

  serialize(): any {
    return {
      special: this.special,
      panelItemId: this.panelItemId,
      couponCode: this.couponCode,
      items: (this.items || []).map(item => item.serialize()),
      isUpsell: this.isUpsell,
      upsellType: this.upsellType,
      featuredContentName: this.featuredContentName,
      featuredAreaName: this.featuredAreaName,
    };
  }
}
