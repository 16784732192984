import { Component, Input } from '@angular/core';
import { BreakpointService } from '../../services/breakpoint.service';

@Component({
  selector: 'fts-store-phone',
  templateUrl: './store-phone.component.html',
  styleUrls: ['./store-phone.component.scss'],
})
export class StorePhoneComponent {
  @Input()
  phone: string;
  @Input()
  isInHome: boolean;
  @Input()
  minifiedHeader = false;

  constructor(public breakPointService: BreakpointService) {}
}
