// All keys here will be suffixed
export enum PersistentLocalStorageKeys {
  MOBILE_APP_BANNER_VISIBILITY = 'MOBILE_APP_BANNER_VISIBILITY',
  PolicyConsent = 'PolicyConsent',
  GC_INFO = 'GC_INFO',
}

// All keys here will NOT be suffixed
export enum TransientLocalStorageKeys {
  ftsTipPaidWithGc = 'ftsTipPaidWithGc',
  ACCOUNT_UPDATED = 'ACCOUNT_UPDATED',
  ACCOUNT_SIGNED_IN_OUT = 'ACCOUNT_SIGNED_IN_OUT',
}

export const LocalStorageKeys = {
  ...TransientLocalStorageKeys,
  ...PersistentLocalStorageKeys,
};
