import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Account, AccountService } from 'ngx-web-api';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class NeedsAccountGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.accountService.account$.pipe(
      filter((account: Account) => !account.dummy),
      map((account: Account) => {
        if (!account.isInstantiated) {
          const target = state.url.split('?')[0];
          this.router.navigate(['/login'], {
            queryParams: Object.assign({}, route.queryParams, { target }),
          });
          return false;
        }

        return true;
      })
    );
  }
}
