export enum FeaturedContentName {
  BASE_PAGE_CATEGORY = 'Base Page Category',
  BASE_PAGE_TOP = 'Base Page Top',
  BASE_PAGE_LEFT = 'Base Page Left',
  BASE_PAGE_RIGHT = 'Base Page Right',
  RELOAD_GIFT_CARD = 'Reload Gift Card',
  CONFIRMED_PAGE_TOP = 'Confirmed Page Top',
  CONFIRMED_PAGE_BOTTOM = 'Confirmed Page Bottom',
  REGISTER_PAGE = 'Register Page',
  HOME_PAGE = 'Home Page',
  HEADER_LINKS = 'Header Links',
  FOOTER_LINKS = 'Footer Links',
  TOP_BANNER = 'Top Banner',
  ENJOY_PAGE = 'Enjoy Page',
  TRACKER_PAGE = 'Tracker Page',
  HEADER_ANNOUNCEMENT = 'Header Announcement',
}
