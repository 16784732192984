import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { IngredientChoice } from './ingredient-choice.model';

import { OrderableItem } from './orderable-item.model';
import { OrderableSpecial } from './orderable-special.model';
import { Picture } from './picture.model';
import { SizePrice } from './size-price.model';

export class Upsell extends BaseResource {
  title: string;
  subTitle: string;
  imageUrl: string;
  stepOrdinal: number;
  alternativeOrdinal: number;
  pictures: Picture[];
  orderableSpecial: OrderableSpecial;
  size: SizePrice;
  orderableItem: OrderableItem;
  maxQuantity: number;
  unsatisfiedIngredientChoice: IngredientChoice;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      size: DeserializationUtils.deserializeChild(input, 'size', SizePrice),
      orderableSpecial: DeserializationUtils.deserializeChild(input, 'orderableSpecial', OrderableSpecial),
      orderableItem: DeserializationUtils.deserializeChild(input, 'orderableItem', OrderableItem),
      unsatisfiedIngredientChoice: DeserializationUtils.deserializeChild(input, 'unsatisfiedIngredientChoice', IngredientChoice),
    });
  }
}
