<form *ngIf="!!storeFinderForm" [formGroup]="storeFinderForm" (submit)="onFindStore()">
  <div *ngIf="storeConfig?.storeFinderMethod === storeFinderMethod.SINGLE" class="address-input-wrapper">
    <div class="address-input">
      <div class="full-width" [ngClass]="{'has-error': serverErrors?.address?.length}">
        <label class="sr-only" for="address" translate>component.header.store_info.store_selection.store_finder_form.address_label</label>
        <input #addressInput id="address" formControlName="address" class="form-control primary-bg-color primary-text-color"
              placeholder="{{'component.header.store_info.store_selection.store_finder_form.enter_address_placeholder' | translate}}"
              (input)="onAddressInput(addressInput.value)" 
              [ngClass]="{'is-invalid': serverErrors?.address?.length}" 
              [attr.aria-describedby]="(serverErrors?.address | generateIds : 'street-error')">
        <span id="street-error-{{i}}" class="form-text text-left" *ngFor="let error of serverErrors?.address; let i = index">{{error}}</span>
      </div>
      <button *ngIf="showClearBtn" type="button" class="btn btn-link btn-undecorated clear-btn padding-0 margin-0"
              (click)="addressInput.value = ''; onAddressInput()"
              [attr.aria-label]="'component.header.store_info.store_selection.store_finder_form.clear_btn_label' | translate">
        <img src="assets/img/clear.svg" alt="" aria-hidden="true"/>
      </button>
    </div>

    <button ftsAsyncButton ftsAuditableButton auditName="Find store" [subscription]="this.loadingStores" [showSpinner]="false" class="btn no-padding primary-text-color find-store-btn"
            [attr.aria-label]="'component.header.store_info.store_selection.store_finder_form.find_store' | translate" type="submit">
      <fa-icon class="margin-left-10 address-icon" [fixedWidth]="true" [icon]="faFtsArrowRight"></fa-icon>
    </button>
  </div>

  <ng-container *ngIf="storeConfig?.storeFinderMethod === storeFinderMethod.SPLIT">

    <div class="form-row" *ngIf="needsAddress">
      <div class="form-group required" [ngClass]="{'has-error': serverErrors?.street?.length}">
        <label class="sr-only" for="street" translate>component.header.store_info.store_selection.store_finder_form.street_label</label>
        <input id="street" type="text" class="form-control primary-bg-color primary-text-color" formControlName="street"
          placeholder="{{'component.header.store_info.store_selection.store_finder_form.street_label' | translate}}"
          [ngClass]="{'is-invalid': serverErrors?.street?.length}" [attr.aria-describedby]="(serverErrors?.street | generateIds : 'street-error')">
        <span id="street-error-{{i}}" class="form-text text-left" *ngFor="let error of serverErrors?.street; let i = index">{{error}}</span>
      </div>
    </div>

    <div class="form-row" *ngIf="!needsAddress">
      <div class="form-group required" [ngClass]="{'has-error': serverErrors?.city?.length}">
        <label class="sr-only" for="city" translate>component.header.store_info.store_selection.store_finder_form.city_label</label>
        <input id="city" type="text" class="form-control primary-bg-color primary-text-color" formControlName="city"
          placeholder="{{'component.header.store_info.store_selection.store_finder_form.city_label' | translate}}"
          [ngClass]="{'is-invalid': serverErrors?.city?.length}" [attr.aria-describedby]="(serverErrors?.city | generateIds : 'city-error')">
        <span id="city-error-{{i}}" class="form-text text-left" *ngFor="let error of serverErrors?.city; let i = index">{{error}}</span>
      </div>
    </div>

    <div class="form-row">
      
      <div class="form-group required width-50" [ngClass]="{'has-error': serverErrors?.city?.length}" *ngIf="needsAddress">
        <label class="sr-only" for="city" translate>component.header.store_info.store_selection.store_finder_form.city_label</label>
        <input id="city" type="text" class="form-control primary-bg-color primary-text-color" formControlName="city"
          placeholder="{{'component.header.store_info.store_selection.store_finder_form.city_label' | translate}}"
          [ngClass]="{'is-invalid': serverErrors?.city?.length}" [attr.aria-describedby]="(serverErrors?.city | generateIds : 'city-error')">
        <span id="city-error-{{i}}" class="form-text text-left" *ngFor="let error of serverErrors?.city; let i = index">{{error}}</span>
      </div>

      <div class="form-group required" [ngClass]="{'has-error': serverErrors?.zip?.length, 'width-50 padded-right-15': !needsAddress, 'width-25 padded-left-5 padded-right-5': needsAddress}">
        <label class="sr-only" for="zip" translate>component.header.store_info.store_selection.store_finder_form.zip_label</label>
        <input id="zip" type="text" class="form-control primary-bg-color primary-text-color" formControlName="zip"
          placeholder="{{'component.header.store_info.store_selection.store_finder_form.zip_label' | translate}}"
          [ngClass]="{'is-invalid': serverErrors?.zip?.length}" [attr.aria-describedby]="(serverErrors?.zip | generateIds : 'zip-error')">
        <span id="zip-error-{{i}}" class="form-text text-left" *ngFor="let error of serverErrors?.zip; let i = index">{{error}}</span>
      </div>

      <div class="form-group required" [ngClass]="{'has-error': serverErrors?.state?.length, 'width-50': !needsAddress, 'width-25': needsAddress}">
        <label class="sr-only" for="state" translate>component.header.store_info.store_selection.store_finder_form.state_label</label>
        <select class="form-control primary-bg-color primary-text-color" id="state" formControlName="state"
                [attr.aria-hidden]="!storeStates?.length" [ngClass]="{'is-invalid': serverErrors?.state?.length}"
                [attr.aria-label]="'component.header.store_info.store_selection.merchant_store_selector.state' | translate">
          <option disabled selected value="">{{'component.header.store_info.store_selection.store_finder_form.state_label' | translate}}</option>
          <option *ngFor="let storeState of storeStates" [value]="storeState">{{ storeState }}</option>
        </select>
        <span id="state-error-{{i}}" class="form-text text-left" *ngFor="let error of serverErrors?.state; let i = index">{{error}}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <button ftsAsyncButton ftsAuditableButton auditName="Find store" [subscription]="this.loadingStores" [showSpinner]="false"
                class="btn btn-block selection-bg-color selection-inverse-text-color find-store-extended-btn text-uppercase font-primary"
                type="submit">
          <span translate>component.header.store_info.store_selection.store_finder_form.find_store</span>
        </button>
      </div>
    </div>

  </ng-container>
</form>
