import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { OrderableSpecialStepAlternativeItem } from './orderable-special-step-alternative-item.model';
import { SizeSurcharge } from './size-surcharges.model';

export class OrderableSpecialStepAlternative extends BaseResource {
  alternativeId: number;
  category: string;
  items: OrderableSpecialStepAlternativeItem[];
  sizes: string[];
  sizeSurcharges: SizeSurcharge[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      items: DeserializationUtils.deserializeChildren(input, 'items', OrderableSpecialStepAlternativeItem),
    });
  }
}
