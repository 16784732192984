import { Utils } from '../../utils/utils';
import { BaseResource } from '../core/base-resource';

export class LoyaltySignupData extends BaseResource {
  planName: string;
  cellphone: string;
  loyaltyCard: string;
  giftCard: string;

  serialize(): any {
    return {
      planName: this.planName,
      cellphone: Utils.serializePhone(this.cellphone),
      loyaltyCard: this.loyaltyCard,
      giftCard: this.giftCard,
    };
  }
}
