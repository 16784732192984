import { BaseResource } from './base-resource';
import { GenericObject } from './generic-object';
import { OpenCloseTime } from './open-close-time.model';

export class StoreStatus extends BaseResource {
  readonly asapNote: string;
  readonly closedMessage?: string;
  readonly closeTime?: string;
  readonly closingTimeMessage?: string;
  readonly deferredDayTimes: GenericObject<OpenCloseTime>;
  readonly hasToday: boolean;
  readonly isClosedForVacation: boolean;
  readonly isOpen: boolean;
  readonly nextOpenDayMessage?: string;
  readonly nextOpenTime?: string;
  readonly now: string;
  readonly openedTime?: string;
  readonly openingTimeMessage: string;
  readonly orderType: string;
  readonly timezone: string;
  readonly vacationMessage: string;
  readonly warningTimeMessage: string;

  getOpenCloseTime(date?: string): OpenCloseTime | undefined {
    if (date) {
      return this.deferredDayTimes[date];
    } else if (this.isOpen) {
      return { open: this.openedTime, close: this.closeTime } as OpenCloseTime;
    } else if (this.hasToday) {
      return { open: this.nextOpenTime, close: this.closeTime } as OpenCloseTime;
    }
  }

  get statusMessage(): string | undefined {
    if (this.isClosedForVacation) {
      return this.vacationMessage;
    }
    if (!this.isOpen) {
      return this.openingTimeMessage || this.closedMessage || this.closingTimeMessage || this.nextOpenDayMessage;
    } else {
      return this.warningTimeMessage;
    }
  }
}
