import { Injectable } from '@angular/core';
import { StoreConfig, ConfigService } from 'ngx-web-api';
import { noop } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CanonicalUrlService {
  constructor(private configService: ConfigService) {}

  loadCanonicalUrl() {
    this.configService.storeConfig$.pipe(take(1)).subscribe((config: StoreConfig) => {
      if (config && config.canonicalLinkURL) {
        const link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', config.canonicalLinkURL);
        document.head.appendChild(link);
      }
    }, noop);
  }
}
