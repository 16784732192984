"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsAccount";
var width = 423;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "M 405.66154,446.03077 C 349.53846,489.35385 282.58462,512 211.69231,512 141.78462,512 74.830769,489.35385 18.707692,447.01538 L 0,433.23077 4.9230769,431.26154 H 0.98461539 C 106.33846,386.95385 128.98462,354.46154 132.92308,348.55385 V 328.86154 C 115.2,309.16923 101.41538,284.55385 93.538462,256 c -12.8,-10.83077 -20.676923,-27.56923 -20.676923,-44.30769 0,-11.81539 3.938461,-23.63077 9.846153,-32.49231 V 121.10769 C 82.707692,45.292308 131.93846,0 213.66154,0 296.36923,0 344.61539,44.307693 344.61539,121.10769 V 179.2 c 6.8923,9.84615 9.84615,20.67692 9.84615,32.49231 0,17.72307 -7.87692,33.47692 -20.67692,44.30769 -8.86154,28.55385 -22.64616,54.15385 -39.38462,72.86154 v 19.69231 c 2.95385,5.90769 23.63077,39.38461 127.01539,82.70769 h -0.98462 l 2.95385,0.98461 z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.faFtsAccount = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
