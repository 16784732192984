<div class="text-center">
  <fieldset>
    <legend class="step-legend" [ngClass]="{' secondary-text-color fw-600': isInHome}">{{(isInHome ? 'component.order_type_chooser.type_of_order' : 'component.order_type_chooser.how_order') | translate}}</legend>
    <hr *ngIf="!isInHome" aria-hidden="true">
    <div class="delivery-companies" *ngIf="(deliveryCompanies$ | async)">
      <div *ngFor="let deliveryCompany of (deliveryCompanies$ | async)" class="delivery-company">
        <a
          *ngIf="!!deliveryCompany.link && !!deliveryCompany.name"
          class="btn btn-link selection-bg-color selection-inverse-text-color selection-text-color fw-600 font-secondary text-uppercase"
          [href]="deliveryCompany.link"
          target="_blank"
          [attr.aria-label]="deliveryCompany.name + ('route.new_tab.opens_new_tab' | translate)">
          {{deliveryCompany.name}}
        </a>
        <p *ngIf="!!deliveryCompany.description">{{deliveryCompany.description}}</p>
      </div>
    </div>

    <fts-themeable-radio
      *ngFor='let orderType of orderTypes'
      [label]='orderType.displayNameOrName'
      [id]="orderType.displayNameOrName"
      [value]='orderType.type'
      [ariaLabel]="orderType.displayNameOrName"
      [name]="'order-type'"
      class="font-secondary text-uppercase"
      [ngClass]="{'home-style-radio text-left': isInHome}"
      [ngModel]='selectedOrderType'
      [highlighted]='isInHome'
      [attr.aria-describedby]="description && selectedOrderTypeConfig?.type === orderType.type ? 'description': undefined"
      (ngModelChange)='chooseOrderType($event)'>
    </fts-themeable-radio>
    <p [@expanded] *ngIf="description" id="description" #descRef>{{description}}</p>
  </fieldset>
</div>
