import { HttpClient, HttpParams, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, share, tap } from 'rxjs/operators';
import { Address } from '../../models/core/address.model';
import { Coupon } from '../../models/core/coupon.model';
import { CouponResolution } from '../../models/core/coupon.resolution.model';
import { Adjustment } from '../../models/ordering/adjustment.model';
import { OrderCustomer } from '../../models/ordering/order-customer.model';
import { OrderDeferOptions } from '../../models/ordering/order-defer-options.model';
import { OrderTimeInfo } from '../../models/ordering/order-time-info.model';
import { OrderWarningType } from '../../models/ordering/order-warning-type.enum';
import { OrderWarning } from '../../models/ordering/order-warning.model';
import { Order } from '../../models/ordering/order.model';
import { OrderedItem } from '../../models/ordering/ordered-item.model';
import { OrderedSpecial } from '../../models/ordering/ordered-special.model';
import { ReOrder } from '../../models/ordering/re-order.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { AccountCouponsService } from '../account/account-coupons.service';
import { AuditService } from '../core/audit.service';
import { OrderPaymentTypesService } from './order-payment-types.service';
import { AddressValidationResult } from '../../models/core/address-validation-result.model';
import { PlacedOrderCustomer } from '../../models/post-order/placed-order-customer.model';
import { OrderSmsNotification } from '../../models/post-order/order-sms.notification';
import { NotificationUpsellGroup } from '../../models/menu/notification-upsell-group.model';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { ParkingSpot } from '../../models/order-tracker/parking-spot.model';
import { OrderTypeUpsell } from '../../models/ordering/order-type-upsell.model';
import { BrowserStorageHandlerService } from 'app/core/services/browser-storage-handler.service';
import { LocalStorageKeys } from 'app/domain/local-storage-keys.enum';

@Injectable({ providedIn: 'root' })
export class OrderService {
  protected readonly BASE_PATH = '/ws/integrated/v1/ordering/order';

  private orderCreated: BehaviorSubject<Order> = new BehaviorSubject<Order>(Order.createDummyOrder());
  public orderCreated$: Observable<Order> = this.orderCreated.asObservable();

  private orderPlaced: Subject<void> = new Subject<void>();
  public orderPlaced$: Observable<void> = this.orderPlaced.asObservable();

  public order: BehaviorSubject<Order> = new BehaviorSubject(Order.createDummyOrder());
  public order$: Observable<Order> = this.order.asObservable();
  public orderCustomer$: Observable<OrderCustomer> = this.order$.pipe(
    map((order: Order) => order.customer),
    filter(o => !!o)
  );
  private dateFormat: DateFormatPipe = new DateFormatPipe();

  constructor(
    protected http: HttpClient,
    protected auditService: AuditService,
    private orderPaymentTypesService: OrderPaymentTypesService,
    private accountCouponsService: AccountCouponsService,
    private browserStorageHandlerService: BrowserStorageHandlerService
  ) {}

  public fetchOrder(replaceOrder = true): Observable<Order> {
    return this.http.get(this.BASE_PATH).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      catchError(() => of(new Order())),
      tap((order: Order) => replaceOrder && this.order.next(order))
    );
  }

  public fetchOrderReceipt(orderId: string): Observable<Order> {
    return this.http
      .get(`${this.BASE_PATH}/${encodeURIComponent(orderId)}?isReceipt=true`)
      .pipe(map(json => DeserializationUtils.deserializeObj(json, Order)));
  }

  public getOrderByToken(token: string): Observable<Order> {
    return this.http.get(`${this.BASE_PATH}/token/${encodeURIComponent(token)}`).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      catchError(() => of(new Order()))
    );
  }

  public verifyOrderByToken(token: string) {
    return this.http.post(`${this.BASE_PATH}/token/verify`, { token }).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      catchError(() => of(new Order()))
    );
  }

  public createOrder(order: Order): Observable<Order> {
    return this.http.post(this.BASE_PATH, order.serialize()).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      tap((o: Order) => this.order.next(o)),
      tap((o: Order) => this.orderCreated.next(o)),
      tap(() => this.accountCouponsService.reloadCoupons()),
      tap(() =>
        this.browserStorageHandlerService.setLocalStorageItem(
          LocalStorageKeys.GC_INFO,
          `{
          "tipPaidWithGc":0.00 ,
          "fullDue":false,
          "wasBefore":false,
          "incoming":0,
          "giftCards":{}
        }
          `
        )
      ),
      mergeMap(o => this.orderPaymentTypesService.fetchPaymentTypes().pipe(map(() => o))),
      catchError(error => {
        if (error?.error?.message === 'Order already exists') {
          return this.fetchAndUpdateAnExistingOrder(order);
        }
        this.order.next(new Order());
        return throwError(error);
      })
    );
  }

  public deleteOrder(): Observable<Order> {
    return this.http.delete(this.BASE_PATH).pipe(
      mergeMap(() => of(Order.createDummyOrder())),
      tap((order: Order) => this.order.next(order))
    );
  }

  public clearOrder(order: Order): Observable<Order> {
    return this.http.put(`${this.BASE_PATH}/clear`, order.serialize()).pipe(
      tap(() => this.auditService.createAudit(() => `Order has been cleared`)),
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      tap((o: Order) => this.order.next(o))
    );
  }

  public updateOrder(
    order: Order,
    shouldFetchPaymentTypesAndCoupons = true,
    orderTypeUpsold = false,
    forceEmptyDeferTime = false
  ): Observable<Order> {
    let params: HttpParams = new HttpParams();
    if (orderTypeUpsold) {
      params = params.set('orderTypeUpsold', 'true');
    }
    if (forceEmptyDeferTime) {
      params = params.set('forceEmptyDeferTime', 'true');
    }
    const operation$ = this.http.put(this.BASE_PATH, order.serialize(), { params }).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      tap((o: Order) => this.order.next(o))
    );
    if (shouldFetchPaymentTypesAndCoupons) {
      return operation$.pipe(
        tap(() => this.accountCouponsService.reloadCoupons()),
        mergeMap(o => this.orderPaymentTypesService.fetchPaymentTypes().pipe(map(() => o)))
      );
    }
    return operation$;
  }

  public placeOrder(): Observable<Order> {
    return this.http.post(`${this.BASE_PATH}/place`, this.order.getValue().serialize()).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      tap((order: Order) => this.auditOrderPlacement(order)),
      tap(() => this.orderPlaced.next())
    );
  }

  public getPlacedOrder(): Observable<Order> {
    return this.http.get(`${this.BASE_PATH}/placedOrderId`).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      map((orderIdJson: Order) => orderIdJson.orderId),
      mergeMap((orderId: string) => this.http.get(`${this.BASE_PATH}/${encodeURIComponent(orderId)}`)),
      map(orderJson => DeserializationUtils.deserializeObj(orderJson, Order))
    );
  }

  public getTimeInfo(orderType: string): Observable<OrderTimeInfo> {
    const params: HttpParams = new HttpParams().set('orderType', orderType);

    return this.http
      .get(`${this.BASE_PATH}/timeinfo`, { params })
      .pipe(map(json => DeserializationUtils.deserializeObj(json, OrderTimeInfo)));
  }

  public createWebSessionByOrderId(orderId: string, isExternalRef = false): Observable<Order> {
    let params: HttpParams = new HttpParams();
    if (isExternalRef) {
      params = params.set('isExternalRef', isExternalRef.toString());
    }

    return this.http.post<Order>(`${this.BASE_PATH}/webPay/${encodeURIComponent(orderId)}`, {}, { params }).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      tap((o: Order) => this.order.next(o)),
      mergeMap((o: Order) => this.orderPaymentTypesService.fetchPaymentTypes().pipe(map(() => o)))
    );
  }

  public webPay(): Observable<Order> {
    return this.http.post<Order>(`${this.BASE_PATH}/webPay`, {}).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Order)),
      tap((o: Order) => this.order.next(o))
    );
  }

  public addPartialTip(tip: number): Observable<Order> {
    return this.http
      .put<Order>(`${this.BASE_PATH}/webPay/addTip`, { tip: tip })
      .pipe(
        map(json => DeserializationUtils.deserializeObj(json, Order)),
        tap((o: Order) => this.order.next(o))
      );
  }

  public customerHasArrived(orderId: string, parkingSpot?: ParkingSpot): Observable<Order> {
    return this.http
      .put<Order>(`${this.BASE_PATH}/${encodeURIComponent(orderId)}/arrived`, parkingSpot || {})
      .pipe(map(json => DeserializationUtils.deserializeObj(json, Order)));
  }

  /*************************************************** Side API *****************************************************/

  public updateOrderCustomer(customer: OrderCustomer, isConfirmMethod = false): Observable<OrderCustomer> {
    return this.http.put(`${this.BASE_PATH}/customer`, customer.serialize(isConfirmMethod)).pipe(
      map(json => DeserializationUtils.deserializeObj(json, OrderCustomer)),
      tap((updatedCustomer: OrderCustomer) => this.auditOrderCustomerUpdate(updatedCustomer)),
      tap((updatedCustomer: OrderCustomer) => this.setOrderCustomer(updatedCustomer))
    );
  }

  public updateCustomerPhoneByToken(phone: string, token: string): Observable<HttpResponse<Object>> {
    return this.http.put(`${this.BASE_PATH}/updateCustomerPhone`, { phone, token }, { observe: 'response' });
  }

  public unlinkGuestCustomerAddresses(token: string): Observable<HttpResponse<Object>> {
    return this.http.put(`${this.BASE_PATH}/unlinkGuestCustomerAddresses`, { token }, { observe: 'response' });
  }

  public getPlacedOrderCustomer(): Observable<PlacedOrderCustomer> {
    return this.http
      .get(`${this.BASE_PATH}/customer/placedOrderCustomer`)
      .pipe(map(json => DeserializationUtils.deserializeObj(json, PlacedOrderCustomer)));
  }

  public subscribePlacedOrderCustomer(customer: PlacedOrderCustomer): Observable<PlacedOrderCustomer> {
    return this.http
      .post(`${this.BASE_PATH}/customer/subscribePlacedOrderCustomer`, customer.serialize())
      .pipe(map(json => DeserializationUtils.deserializeObj(json, PlacedOrderCustomer)));
  }

  public updateOrderSmsNotification(notification: OrderSmsNotification): Observable<any> {
    return this.http.post(`${this.BASE_PATH}/notification`, notification.serialize());
  }

  public updateOrderCustomerAddress(address: Address): Observable<Address> {
    return this.http.put(`${this.BASE_PATH}/customer/address`, address.serialize()).pipe(
      map(json => DeserializationUtils.deserializeObj(json, Address)),
      mergeMap(addr => this.fetchOrder().pipe(map(() => addr)))
    );
  }

  public removeOrderCustomerAddress(): Observable<HttpResponse<any>> {
    return this.http
      .delete(`${this.BASE_PATH}/customer/address`, { observe: 'response' })
      .pipe(mergeMap(response => this.fetchOrder().pipe(map(() => response))));
  }

  public validateAddress(address: Address, orderTime: string, omitType?: boolean): Observable<AddressValidationResult> {
    let query = '';
    if (!!orderTime) {
      query = query.concat(`orderTime=${encodeURIComponent(orderTime)}`);
    }
    return this.http.post<AddressValidationResult>(
      omitType ? `${this.BASE_PATH}/customer/address/validateIgnoreType` : `${this.BASE_PATH}/customer/address/validate?${query}`,
      address.serialize()
    );
  }

  public getOrderDeferOptions(): Observable<OrderDeferOptions> {
    return this.http
      .get(`${this.BASE_PATH}/selectedDeferOption`)
      .pipe(map(json => DeserializationUtils.deserializeObj(json, OrderDeferOptions)));
  }

  public addCoupon(coupon: Coupon): Observable<CouponResolution> {
    const payload = {
      code: coupon.code,
      type: coupon.type,
    };

    const coupon$ = this.http.post(`${this.BASE_PATH}/coupons`, payload).pipe(
      map(json => DeserializationUtils.deserializeObj(json, CouponResolution)),
      mergeMap(couponResolution => this.fetchOrder().pipe(map(() => couponResolution))),
      tap(() => this.accountCouponsService.reloadCoupons()),
      share()
    );

    coupon$.subscribe(
      (c: CouponResolution) => this.auditCouponAdditionSuccess(c),
      res => this.auditCouponAdditionFailure(coupon, res)
    );

    return coupon$;
  }

  public updateOrderTip(tip?: number, shouldNotFetchPaymentTypesAndCoupons = false): Observable<Order> {
    const orderCopy = this.order.value.copy();
    orderCopy.tip = tip;
    return this.updateOrder(orderCopy, !shouldNotFetchPaymentTypesAndCoupons);
  }

  public setSessionTrackerKey(key: string) {
    return this.http.put(`${this.BASE_PATH}/trackerKey`, { key });
  }

  public getAsapNote(orderType: string, address?: string, zip?: string, shouldNotCalculateDeferTime?: boolean): Observable<OrderTimeInfo> {
    let params: HttpParams = new HttpParams().set('orderType', orderType);

    if (address) {
      params = params.set('address', address);
    }

    if (zip) {
      params = params.set('zip', zip);
    }

    if (shouldNotCalculateDeferTime) {
      params = params.set('shouldNotCalculateDeferTime', 'true');
    }

    return this.http
      .get('/ws/integrated/v1/ordering/order/timeinfo', { params })
      .pipe(map(json => DeserializationUtils.deserializeObj(json, OrderTimeInfo)));
  }

  public reOrder(reOrder: ReOrder, pastOrder: Order): Observable<string[]> {
    return this.http.post<string[]>(`${this.BASE_PATH}/reorder`, reOrder.serialize()).pipe(
      tap((warnings: string[]) => {
        let audit = '';
        if (!reOrder.force && warnings && warnings.length > 0) {
          // eslint-disable-next-line max-len
          audit = `Modal with warnings opened during reordering Order ordered on ${pastOrder.dateOrdered} with number ${pastOrder.orderNumber}`;
        } else {
          // eslint-disable-next-line max-len
          audit = `Order ordered on ${pastOrder.dateOrdered} with number ${pastOrder.orderNumber} and price at ${pastOrder.price} has been reordered`;
        }

        this.auditService.createAudit(() => audit);
      })
    );
  }

  public removePendingDiscount(refetchOrder = true): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.BASE_PATH}/pendingSpecial`, { observe: 'response' }).pipe(
      tap(() => this.accountCouponsService.reloadCoupons()),
      mergeMap(response => (refetchOrder ? this.fetchOrder() : of(null)).pipe(map(() => response)))
    );
  }

  public removeAdjustment(adjustment: Adjustment, refetchOrder = true): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.BASE_PATH}/adjustments/${adjustment.ordinal}`, { observe: 'response' }).pipe(
      tap(() => this.accountCouponsService.reloadCoupons()),
      tap(() => this.auditService.createAudit(() => `Removed adjustment "${adjustment.name}" from order`)),
      mergeMap(response => (refetchOrder ? this.fetchOrder() : of(null)).pipe(map(() => response)))
    );
  }

  public cashThirdPartyReward(amount: number): Observable<Adjustment> {
    const params = new HttpParams().append('amount', amount.toString());

    return this.http
      .post<Adjustment>(`${this.BASE_PATH}/adjustments/cashReward`, null, { params })
      .pipe(
        map(json => DeserializationUtils.deserializeObj(json, Adjustment)),
        tap((adj: Adjustment) => this.auditService.createAudit(() => `Third Party Reward "${adj.name}" has been added to the order.`)),
        mergeMap((adj: Adjustment) => this.fetchOrder().pipe(map(() => adj)))
      );
  }

  public getWarnings(order: Order): OrderWarning[] {
    const warnings = [];

    warnings.push(
      ...(order.warnings || []).map(warning => ({
        type: OrderWarningType.ORDER,
        text: warning,
      }))
    );

    const incompleteItems = (order.items || []).filter(item => !item.isValid());
    const specialsIncompleteItems = (order.specials || [])
      .filter(special => !special.isValid() && !special.hasValidItems)
      .map(special => special.items.filter(item => !item.isValid()))
      .reduce((a, b) => a.concat(b), []);
    incompleteItems.push(...specialsIncompleteItems);
    if (incompleteItems.length) {
      warnings.push(
        ...incompleteItems.map((item: OrderedItem) => ({
          type: OrderWarningType.ITEM,
          item,
        }))
      );
    }

    const incompleteSpecials = (order.specials || []).filter(special => !special.isValid() && special.hasValidItems);
    if (incompleteSpecials.length) {
      warnings.push(
        ...incompleteSpecials.map((special: OrderedSpecial) => ({
          type: OrderWarningType.SPECIAL,
          special,
        }))
      );
    }

    return warnings;
  }

  public getNotificationUpsells(): Observable<NotificationUpsellGroup> {
    return this.http
      .get(`${this.BASE_PATH}/notification`)
      .pipe(map((response: Response) => new NotificationUpsellGroup().deserialize(response)));
  }

  public getOrderTypeUpsell(): Observable<OrderTypeUpsell> {
    return this.http.get<OrderTypeUpsell>(`${this.BASE_PATH}/upsellAvailability`);
  }

  public requestEmailReceipt(email: string, subscribe: boolean, order: Order): Observable<any> {
    return this.http
      .post(`${this.BASE_PATH}/${encodeURIComponent(order.orderId)}/emailReceipt/${encodeURIComponent(email)}?subscribe=${subscribe}`, {})
      .pipe(
        tap(() =>
          this.auditService.createAudit(() => `Email receipt for order #${order.orderNumber} has been sent to ${email} successfully`)
        )
      );
  }

  private fetchAndUpdateAnExistingOrder(order: Order): Observable<Order> {
    return this.fetchOrder().pipe(
      mergeMap((existingOrder: Order) => {
        if (existingOrder.orderType !== order.orderType || existingOrder.deferTime !== order.deferTime) {
          return this.updateOrder(order);
        }
        return of(order);
      })
    );
  }

  /*********************************************** Private Helpers **************************************************/

  private setOrderCustomer(customer: OrderCustomer): void {
    const order = this.order.getValue().copy();
    order.customer = customer;
    this.order.next(order);
  }

  /************************************************ Auditing helpers ************************************************/

  private auditOrderPlacement(order: Order) {
    return this.auditService.createAudit(() => ({
      message: 'Order has been placed',
      details: {
        id: order.orderId,
        orderNum: order.orderNumber,
        price: order.price,
        orderType: order.orderType,
        tip: order.tip,
        loyaltyPoints: order.loyaltyPoints,
        time: {
          ordered: this.dateFormat.transform(order.dateOrdered, 'MM-dd-yyyy HH:mm:ss'),
          promise: this.dateFormat.transform(order.promiseTime, 'MM-dd-yyyy HH:mm'),
          defer: this.dateFormat.transform(order.deferTime, 'MM-dd-yyyy HH:mm'),
          donation: { amount: order.donationAmount, charity: order.charityName },
        },
      },
    }));
  }

  private auditOrderCustomerUpdate(customer: OrderCustomer): void {
    const { firstName, lastName, phone, email, subscribe, smsMarketing } = customer;
    this.auditService.createAudit(() => ({
      message: `Order customer has been updated`,
      details: { firstName, lastName, phone, email, subscribe, smsMarketing },
    }));
  }

  private auditCouponAdditionSuccess(coupon: CouponResolution): void {
    this.auditService.createAudit(() =>
      !!coupon.name
        ? {
            message: `Coupon "${coupon.code}" has been applied to the order"`,
            details: { couponName: coupon.name },
          }
        : `Coupon "${coupon.code}" has been applied to the order"`
    );
  }

  private auditCouponAdditionFailure(coupon: Coupon, response: HttpErrorResponse) {
    const res = response.error;

    this.auditService.createAudit(() => `Applied Coupon: ${coupon.code}, Error Type: ${res.errorType}, Error: ${res.error}`);
  }
}
