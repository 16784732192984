import { BaseResource } from '../core/base-resource';

export class GoCartBillingInfo extends BaseResource {
  firstName: string;
  lastName: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phoneNumber: string;
  isDefaultShipping: boolean;
  externalId: string;

  deserialize(input: any): this {
    return super.deserialize(input);
  }

  serialize(): any {
    return super.serialize();
  }
}
