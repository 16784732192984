<label (keyup.enter)="onChange($event)" class="custom-checkbox" role="checkbox" [attr.aria-checked]="checked" [tabindex]="disabled ? -1 : 0"
       [ngClass]="{'disabled': disabled && !isUnavailable, 'ingredient': ingredient, 'position-relative': ingredient, 'align-center': alignCenter, 'outline': focused, 'has-content': !noContent}">
  <span class="sr-only" *ngIf="!!ariaLabel">{{ariaLabel}}</span>
  <span *ngIf="isUnavailable" class="sr-only">{{'component.item_editor.choice.ingredient.ingredient_card.not_available' | translate}}</span>
  <fa-icon [size]="'lg'" *ngIf="isUnavailable && storeConfig?.showSizeSpecificIngredients" class="disabled-icon text-danger" [icon]="faBan"></fa-icon>
  <input #inputRef
    hidden
    type="checkbox"
    [name]='name'
    [checked]='checked'
    [disabled]='disabled && !isUnavailable'
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    (click)="onChange($event)">
  <span class="custom-checkbox-indicator custom-checkbox" 
        [ngClass]="{'selection-bg-color selection-inverse-text-color': checked,
                    'custom-checkbox-indicator-check': checked && isHalfAndHalf,
                    'border-radius': isUnavailable && storeConfig?.showSizeSpecificIngredients, 
                    'has-content': !noContent}">
  </span>
  <div class="custom-checkbox-details" *ngIf="!noContent">
    <ng-content></ng-content>
  </div>
</label>
