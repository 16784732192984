export enum OrderPaymentType {
  CASH = 'cash',
  CREDIT_CARD = 'credit card',
  GIFT_CARD = 'gift cert',
  CHECK = 'check',
  INVOICE = 'invoice',
  HOUSE_ACCOUNT = 'house account',
  APPLE_PAY = 'apple pay',
  GO_CART = 'go cart',
}
