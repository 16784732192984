<div *ngIf="!useAddressModal" class="entry-box primary-bg-color primary-text-color shadowed fw-500">
  <fts-themeable-radio
    class='text-left'
    [name]="'new-address'"
    [ngModel]='addingNewAddress'
    (ngModelChange)='onAddingNewAddress()'
    [ariaLabel]="'Add new address'"
    [value]='true'
    [disabled]='disabled'>
    <div class="text-left address-text primary-bg-color primary-text-color text-muted fw-500 text-uppercase" [ngClass]="{'selected': addingNewAddress}">
      {{'component.add_new_address.add_new' | translate}}
    </div>
  </fts-themeable-radio>
  <div [@expanded] *ngIf='addingNewAddress' class="address-form">
    <fts-address-form
      #addressForm
      [horizontal]="false"
      [errors]='addressValidationErrors'
      [address]='address'
      [showClientValidation]='true'
      [deliveryNote]="deliveryNote"
      (submitSuccess)="selectOrderAddress()"
      (streetChange)="onStreetChange($event)"
      (addressErrors)="onAddressErrors($event)">
    </fts-address-form>
    <button ftsAsyncButton
            [subscription]="addressForm.loadingSubscription"
            (click)="createNewAddress()"
            class="btn btn-block action-bg-color action-inverse-text-color text-uppercase primary-btn action-btn font-secondary text-uppercase">
      {{'component.add_new_address.add_address' | translate}}
    </button>
  </div>
</div>
<button *ngIf="useAddressModal" class="btn btn-link font-secondary action-bg-color action-inverse-text-color" (click)='createNewAddress()'>
  {{'component.add_new_address.add_new' | translate}}
</button>
