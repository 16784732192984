"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsHamburger";
var width = 765;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "M 57.636975,0 C 25.990722,0 0,25.990718 0,57.636971 0,89.283225 25.990722,115.27395 57.636975,115.27395 H 707.5154 c 31.64626,0 57.63697,-25.990725 57.63697,-57.636979 C 765.15237,25.990718 739.16166,0 707.5154,0 Z m 0,198.91489 C 25.990722,198.91489 0,224.90562 0,256.55187 c 0,31.64625 25.990722,57.65905 57.636975,57.65905 H 707.5154 c 31.64626,0 57.63697,-26.0128 57.63697,-57.65905 0,-31.64625 -25.99071,-57.63698 -57.63697,-57.63698 z m 0,197.78908 C 25.990722,396.70397 0,422.6947 0,454.34096 0,485.98721 25.990722,512 57.636975,512 H 707.5154 c 31.64626,0 57.63697,-26.01279 57.63697,-57.65904 0,-31.64626 -25.99071,-57.63699 -57.63697,-57.63699 z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.faFtsHamburger = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
