<div [style.height]="height" class="fading">
  <ng-container *ngFor='let featuredItem of featuredItems; let i = index;' >
    <ng-container 
      *ngTemplateOutlet="featuredItem.isClickable ? actionElement : imageOnly; 
      context: {
        featuredItem: featuredItem, 
        itemIndex: i
      }"
    ></ng-container>
  </ng-container>

  <fts-step-indicator 
    [numberOfSteps]='featuredItems.length'
    [currentStep]='currentVisibleIndex'
    (currentStepChange)='onVisibleIndexChange($event)'
    (stopStepIndicator)="stopAnimation()"
    (startStepIndicator)="startAnimation()">
  </fts-step-indicator>
</div>

<ng-template #actionElement let-featuredItem="featuredItem" let-itemIndex="itemIndex">
  <button
    type="button"
    class="featured-item-wrapper padding-0 margin-0 no-border"
    [style.height]="height"
    [attr.aria-label]="featuredItemsButtonLabels[itemIndex]"
    (focus)="focusFeaturedItem(itemIndex)"
    (blur)="startAnimation()"
    (click)="onFeaturedItemChosen(featuredItem, itemIndex + 1)"
    (keydown)="handleKeyDown($event, featuredItem, itemIndex + 1)">
    <fts-featured-item-fading
      [fillParentHeight]='fillParentHeight'
      [featuredItem]="featuredItem"
      [state]="animationStates[itemIndex]"
      [skipLazyLoading]="skipLazyLoading"
      [itemImgHeight]="itemImgHeight"
      (mouseover)="stopAnimation()"
      (mouseout)="startAnimation()"
      (heightChange)="onImageLoaded($event)">
    </fts-featured-item-fading>
  </button>
</ng-template>

<ng-template #imageOnly let-featuredItem="featuredItem" let-itemIndex="itemIndex">
  <div
    class="featured-item-wrapper"
    [style.height]="height"
    (focus)="focusFeaturedItem(itemIndex)"
    (blur)="startAnimation()">
    <fts-featured-item-fading
      [fillParentHeight]='fillParentHeight'
      [featuredItem]="featuredItem"
      [state]="animationStates[itemIndex]"
      [skipLazyLoading]="skipLazyLoading"
      [itemImgHeight]="itemImgHeight"
      (mouseover)="stopAnimation()"
      (mouseout)="startAnimation()"
      (heightChange)="onImageLoaded($event)">
    </fts-featured-item-fading>
  </div>
</ng-template>