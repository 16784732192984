"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsLocation";
var width = 347;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "M 173.66234,0 C 77.850785,0 0,77.850785 0,173.66234 c 0,88.8253 140.71542,305.39933 156.68402,329.35222 3.99214,5.98822 9.99206,8.98622 16.97832,8.98622 6.98626,0 12.96668,-2.998 16.95883,-8.98622 C 206.58976,479.06167 347.32467,261.4896 347.32467,173.66234 347.32467,77.850785 269.4739,0 173.66234,0 Z m 0,39.92148 c 73.85474,0 133.74085,59.886123 133.74085,133.74086 0,50.89989 -67.87041,176.64086 -133.74085,282.43278 C 107.7919,350.3032 39.92148,224.56223 39.92148,173.66234 c 0,-73.854737 59.886123,-133.74086 133.74086,-133.74086 z m 0,46.899943 c -40.91952,-10e-7 -74.852778,33.933257 -74.852778,74.852777 0,40.91952 33.933258,74.85278 74.852778,74.85278 40.91952,0 74.85278,-33.93326 74.85278,-74.85278 0,-40.91952 -33.93326,-74.852777 -74.85278,-74.852777 z m 0,39.921487 c 18.96271,0 34.9313,15.96859 34.9313,34.93129 0,18.9627 -15.96859,34.9313 -34.9313,34.9313 -18.9627,0 -34.93129,-15.9686 -34.93129,-34.9313 0,-18.9627 15.96859,-34.93129 34.93129,-34.93129 z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData]
};

exports.faFtsLocation = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
