import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Address, Street, OrderTypeConfig, GenericObject } from 'ngx-web-api';
import { AddressFormComponent } from '../address-form/address-form.component';
import { Animations } from '../../animations';
import { AddressModalContext } from '../../domain/address-modal-context';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { ModalService } from '../../core/services/modal.service';

@Component({
  selector: 'fts-add-new-address',
  templateUrl: './add-new-address.component.html',
  styleUrls: ['./add-new-address.component.scss'],
  animations: [Animations.expanded],
})
export class AddNewAddressComponent {
  @Input()
  address = new Address();
  @Input()
  addingNewAddress = false;
  @Input()
  useAddressModal: boolean;
  @Input()
  disabled: boolean;
  @Input()
  orderTypeConfig: OrderTypeConfig;
  @Input()
  noContact: boolean;
  @Input()
  deliveryNote: string;
  @Input()
  addressValidationErrors: GenericObject<string[]> = {};

  @Output()
  addressChange = new EventEmitter<Address>();
  @Output()
  addingNewAddressChange = new EventEmitter<boolean>();
  @Output()
  streetChange = new EventEmitter<Street>();
  @Output()
  addressErrors = new EventEmitter<string[]>();

  @ViewChild('addressForm', { static: false })
  addressForm: AddressFormComponent;

  constructor(private modalService: ModalService) {}

  onAddingNewAddress() {
    this.addingNewAddressChange.emit(true);
    this.addressChange.emit(new Address());
  }

  createNewAddress() {
    if (this.useAddressModal) {
      const context = new AddressModalContext(new Address(), false).getConfig();
      this.modalService.openModal(AddressModalComponent, context);
    } else {
      this.addressForm.handleCreate();
    }
  }

  selectOrderAddress() {
    this.addingNewAddressChange.emit(false);
    this.addressChange.emit(this.address);
  }

  onStreetChange(street: Street) {
    this.streetChange.emit(street);
  }

  onAddressErrors(errors: string[]) {
    this.addressErrors.emit(errors);
  }
}
