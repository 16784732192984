import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { ImageAlternativeType } from './image-alternative-type.enum';
import { IngredientChoiceGroup } from './ingredient-choice-group.model';
import { IngredientChoice } from './ingredient-choice.model';
import { IngredientChoicesType } from './ingredient-choices-type.enum';
import { Picture } from './picture.model';
import { SizePrice } from './size-price.model';

export class OrderableItem extends BaseResource {
  readonly name: string;
  readonly label: string;
  readonly labelSymbol: string;
  readonly description: string;
  readonly category: string;
  readonly mobileIcons?: string;
  readonly pictures?: Picture[];
  readonly allowWebCustomerInstructions: boolean;
  readonly reorderable: boolean;
  readonly allowHalfs: boolean;
  readonly ingredientChoicesType: IngredientChoicesType;
  readonly showFullSizeCalories: boolean;
  readonly caloriesDisclaimer: string;
  readonly itemWithIngredientChoices: boolean;
  readonly itemWithRequiredIngredientChoices: boolean;
  readonly useBuilder: boolean;
  readonly includeEverything: boolean;
  readonly minimumQuantity: number;

  readonly choiceGroups: IngredientChoiceGroup[];
  readonly choices: IngredientChoice[];
  readonly sizePrices: SizePrice[];

  getChoice(name: string): IngredientChoice | undefined {
    return this.choices.find(choice => choice.name === name);
  }

  get hasImage(): boolean {
    return !!this.pictures && this.pictures.length > 0;
  }

  get availableSizePrices(): SizePrice[] {
    return this.sizePrices.filter(sizePrice => !sizePrice.isUnavailable);
  }

  getImage(type: ImageAlternativeType = ImageAlternativeType.ORIGINAL): Picture | undefined {
    if (this.hasImage) {
      return (this.pictures || []).find(p => p.type === type);
    }

    return undefined;
  }

  getSizePrice(sizeName: string): SizePrice | undefined {
    return this.sizePrices.find(sizePrice => sizePrice.size === sizeName);
  }

  get ingredientChoicesAsSteps(): boolean {
    return this.ingredientChoicesType === IngredientChoicesType.Steps;
  }

  get hasExpandedChoices(): boolean {
    return this.choices.some(c => c.isOpenOnWeb);
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      choiceGroups: this.calcExpandedChoiceGroups(
        DeserializationUtils.deserializeChildren(input, 'choiceGroups', IngredientChoiceGroup),
        input
      ),
      choices: DeserializationUtils.deserializeChildren(input, 'choices', IngredientChoice),
      sizePrices: DeserializationUtils.deserializeChildren(input, 'sizePrices', SizePrice),
    });
  }

  calcExpandedChoiceGroups(groups: IngredientChoiceGroup[], input: OrderableItem): IngredientChoiceGroup[] {
    groups.forEach(cg => {
      cg.isExpanded = input.choices.some(inputChoice => inputChoice.isOpenOnWeb && cg.choices.includes(inputChoice.name));
    });
    return groups;
  }
}
