import { DeserializationUtils } from '../../utils/deserialization-utils';
import { ValidatedResource } from '../core/validated-resource';
import { IngredientChoiceGroup } from '../menu/ingredient-choice-group.model';
import { IngredientChoice } from '../menu/ingredient-choice.model';
import { OrderableIngredient } from '../menu/orderable-ingredient.model';
import { OrderedChoice } from './ordered-choice.model';
import { OrderedIngredient } from './ordered-ingredient.model';
import { SizeUpsell } from './size-upsell.model';
import { IngredientUpsell } from './ingredient-upsell.model';
import { UpsellType } from './upsell-type.enum';
import { ExtraIngredientUpsell } from './extra-ingredient-upsell.model';

export class OrderedItem extends ValidatedResource {
  category: string;
  item: string;
  size: string;
  quantity: number;
  requestedQuantity: number;
  instructions: string;
  entered: boolean;
  hideIfSpecialDefault: boolean;
  isInBucket: boolean;
  isUpsell: boolean;
  isSister: boolean;
  upsellType?: UpsellType;
  featuredContentName?: string;
  featuredAreaName?: string;
  makingStatus: string;
  printName: string;
  printsInKitchen: boolean;
  sellingPrice: number;
  netPrice: number;
  specialOrdinal: number;
  specialDiscount?: number;
  voided: boolean;
  itemId: number;
  memberName: string;
  labelSymbol: string;
  tax?: number;
  taxInclusive?: boolean;
  sizeUpsell: SizeUpsell;
  ingredientUpsell: IngredientUpsell;
  choices: OrderedChoice[] = [];
  includeEverything: boolean;
  extraIngredientUpsells?: ExtraIngredientUpsell[];
  caloriesText?: string;
  canModify: boolean;
  deletedIngredientWarningMessage?: string;
  specialtyRightHalf?: string;
  specialtyLeftHalf?: string;

  constructor() {
    super();
  }

  getChoice(name): OrderedChoice {
    let choice = this.choices.find(c => c.name === name);
    if (!choice) {
      choice = new OrderedChoice();
      choice.name = name;
    }
    return choice;
  }

  hasInvalidChoiceFor(choiceGroup: IngredientChoiceGroup) {
    if (choiceGroup) {
      for (const choiceName of choiceGroup.choices) {
        const choice = this.getChoice(choiceName);

        if (choice && !choice.isValid()) {
          return true;
        }
      }
    }

    return false;
  }

  get hasInvalidChoice(): boolean {
    return this.choices.some(choice => !choice.isValid());
  }

  hasIngredient(choice: IngredientChoice, ingredient: OrderableIngredient) {
    const orderedChoice = this.getChoice(choice.name);
    if (orderedChoice) {
      return !!(orderedChoice.ingredients || []).find(i => i.ingredient === ingredient.name);
    }
    return false;
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  hasDependingIngredient(choice: IngredientChoice) {
    return !choice.dependsOn || this.allIngredients.some(i => i.ingredient === choice.dependsOn);
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  get alteredDefaultIngredients(): OrderedIngredient[] {
    return this.allIngredients
      .filter(i => i.isDefault)
      .filter(i => i.showDefault || i.isRightHalf || i.isLeftHalf || (i.qualifiers || []).length > 0);
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  get nonDefaultIngredients(): OrderedIngredient[] {
    return this.allIngredients.filter(i => !i.isDefault);
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  get removedDefaultIngredients(): OrderedIngredient[] {
    return this.choices
      .map(choice => choice.removedDefaults || [])
      .reduce((a, b) => a.concat(b), [])
      .filter(i => i.showNoDefault);
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  get extraIngredients(): OrderedIngredient[] {
    return this.nonDefaultIngredients.concat(this.alteredDefaultIngredients);
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  get extraWholeIngredients(): OrderedIngredient[] {
    return this.extraIngredients.filter(ingredient => !ingredient.isLeftHalf && !ingredient.isRightHalf);
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  get extraLeftHalfIngredients(): OrderedIngredient[] {
    return this.extraIngredients.filter(ingredient => ingredient.isLeftHalf);
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  get extraRightHalfIngredients(): OrderedIngredient[] {
    return this.extraIngredients.filter(ingredient => ingredient.isRightHalf);
  }

  /** @deprecated fuctionality moved to ordered-item.service */
  get allIngredients(): OrderedIngredient[] {
    return this.choices.map(choice => choice.ingredients || []).reduce((a, b) => a.concat(b), []);
  }

  isValid(): boolean {
    return super.isValid() && (this.choices || []).map(c => c.isValid()).reduce((a, b) => a && b, true);
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      sizeUpsell: DeserializationUtils.deserializeChild(input, 'sizeUpsell', SizeUpsell),
      choices: DeserializationUtils.deserializeChildren(input, 'choices', OrderedChoice),
    });
  }

  serialize(): any {
    return {
      category: this.category,
      item: this.item,
      size: this.size,
      quantity: this.quantity,
      instructions: this.instructions,
      isUpsell: this.isUpsell,
      isInBucket: this.isInBucket,
      upsellType: this.upsellType,
      featuredContentName: this.featuredContentName,
      featuredAreaName: this.featuredAreaName,
      specialOrdinal: this.specialOrdinal,
      itemId: this.itemId,
      memberName: this.memberName,
      choices: (this.choices || []).map(choice => choice.serialize()),
      extraIngredientUpsells: this.extraIngredientUpsells,
      specialtyRightHalf: this.specialtyRightHalf,
      specialtyLeftHalf: this.specialtyLeftHalf,
    };
  }
}
