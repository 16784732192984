import { Injectable } from '@angular/core';
import { ErrorsService } from 'app/core/services/errors.service';
import { ParsedErrorResponse } from 'app/domain/parsed-error-response.model';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class InitializationErrorsHandlerService {
  private hasErrors: BehaviorSubject<ParsedErrorResponse> = new BehaviorSubject<ParsedErrorResponse>(null);
  public errors$: Observable<ParsedErrorResponse> = this.hasErrors.asObservable();

  constructor(private errorService: ErrorsService) {}

  public initializationFailureOccured(errors: any): void {
    this.hasErrors.next(this.errorService.parseAllErrorsFromResponse(errors));
  }
}
