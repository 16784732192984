import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, OnInit } from '@angular/core';
import { AccountAddressesService, Address, Street, StoreConfig, OrderTypeConfig, Order } from 'ngx-web-api';
import { Animations } from '../../animations';
import { AddressFormComponent } from '../address-form/address-form.component';
import { AddressModalContext } from '../../domain/address-modal-context';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { ModalService } from '../../core/services/modal.service';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { mergeMap, tap } from 'rxjs/operators';
import { noop } from 'rxjs';

@Component({
  selector: 'fts-account-address-chooser',
  templateUrl: './account-address-chooser.component.html',
  styleUrls: ['./account-address-chooser.component.scss'],
  animations: [Animations.expanded],
})
export class AccountAddressChooserComponent implements OnInit, OnChanges {
  @ViewChild('addressForm', { static: false })
  public addressForm: AddressFormComponent;

  @Input()
  selectedAddress: Address;
  @Input()
  onePerRow: boolean;
  @Input()
  isInCheckout: boolean;
  @Input()
  disableRadioButtons: boolean;
  @Input()
  store: StoreConfig;
  @Input()
  orderTypeConfig: OrderTypeConfig;
  @Input()
  noContact: boolean;
  @Input()
  deliveryNote: string;
  @Input()
  singleAddressCheck: boolean;
  @Input()
  order: Order;

  @Output()
  selectedAddressChange: EventEmitter<Address> = new EventEmitter(true);
  @Output()
  streetChange = new EventEmitter<Street>(true);
  @Output()
  editing = new EventEmitter<boolean>();
  @Output()
  addressErrors = new EventEmitter<string[]>();

  addresses: Address[];
  editingAddress: Address;
  faPencil = faPencil;
  deletingAddress = false;

  constructor(private accountAddressesService: AccountAddressesService, private modalService: ModalService) {}

  ngOnInit() {
    this.accountAddressesService.addresses$.subscribe(addresses => {
      this.addresses = addresses;

      const updatedSelectedAddress = this.addresses.find(address => address.name === this.selectedAddress?.name);
      if (!!updatedSelectedAddress) {
        this.selectedAddress = updatedSelectedAddress;
        this.selectedAddressChange.emit(this.selectedAddress);
        this.onStreetChange(
          new Street().deserialize({
            address: this.selectedAddress?.street,
            zip: this.selectedAddress?.zip,
          })
        );
      }

      if (this.addresses.length === 1 && !this.selectedAddress?.street) {
        this.onAddressChosen(this.addresses[0].name);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedAddress'] &&
      this.selectedAddress &&
      this.editingAddress &&
      this.selectedAddress.name !== this.editingAddress.name
    ) {
      this.editingAddress = null;
      this.editing.emit(false);
    }
    if (changes.singleAddressCheck) {
      if (this.addresses?.length === 1 && !this.order.customer.address?.street) {
        this.onAddressChosen(this.addresses[0].name);
      }
    }
    this.deletingAddress = false;
  }

  updateEditingAddress() {
    this.addressForm.handleEdit();
  }

  onAddressDelete() {
    this.selectedAddressChange.emit();
  }

  onDeletingAddress(deletingAddress: boolean) {
    this.deletingAddress = deletingAddress;
  }

  cancelEditingAddress() {
    if (
      this.selectedAddress &&
      this.selectedAddress.name === this.editingAddress.name &&
      this.selectedAddress.warnings &&
      this.selectedAddress.warnings.length
    ) {
      this.selectedAddressChange.emit(new Address());
    }
    this.editingAddress = undefined;
    this.editing.emit(false);
  }

  onAddressUpdated(newAddress: Address) {
    if (newAddress && this.selectedAddress && this.selectedAddress.name === this.editingAddress.name) {
      this.selectedAddressChange.emit(newAddress);
    }
    this.editingAddress = undefined;
    this.editing.emit(false);
    this.streetChange.emit(new Street().deserialize(newAddress));
  }

  onAddressChosen(name: string) {
    const chosenAddress: Address = this.addresses.find(address => address.name === name);

    if (this.editingAddress && this.editingAddress.name !== name) {
      this.editingAddress = null;
      this.editing.emit(false);
    }
    this.selectedAddressChange.emit(chosenAddress);
    const street: Street = new Street().deserialize({
      address: chosenAddress.street,
      zip: chosenAddress.zip,
    });
    if (!this.deletingAddress) {
      this.onStreetChange(street);
    }
  }

  onStreetChange(street: Street) {
    this.streetChange.emit(street);
  }

  onEditingAddress(address: Address) {
    this.editingAddress = address.copy();
    this.editing.emit(true);
  }

  openEditingAddressModal(address: Address): void {
    this.onEditingAddress(address);
    this.accountAddressesService
      .getAddress(address.name)
      .pipe(
        mergeMap((address: Address) => {
          const context = new AddressModalContext(address, true).getConfig();
          return this.modalService.openModal(AddressModalComponent, context).pipe(
            tap(updatedAddress => {
              if (!!updatedAddress) {
                this.onAddressUpdated(updatedAddress);
              }
            })
          );
        })
      )
      .subscribe(noop, noop);
  }

  getFormattedAddress(address: Address): string {
    const formattedStreet = this.store.useIntlAddressFormat ? address.intlFormattedStreet : address.formattedStreet;
    const fullAddress = this.store.useIntlAddressFormat ? address.intlFullAddress : address.fullAddress;
    return this.store.hideZip ? `${formattedStreet}, ${address.city}, ${address.state}` : fullAddress;
  }

  onAddressErrors(errors: string[]) {
    this.addressErrors.emit(errors);
  }
}
