<div class="modal-content primary-bg-color primary-text-color">
  <div class="modal-header secondary-bg-color secondary-text-color">
    <h2 id="modal-title" class="modal-title float-left">{{'component.order_type_modal.header' | translate}}</h2>
    <button class="close float-right" (click)="handleCancel()" [attr.aria-label]="'component.order_type_modal.close' | translate">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-left">
    <fts-order-type-form [store]="store" [orderTypeConfigs]="orderTypes" (orderInitialized)="handleOrderInitialized()"></fts-order-type-form>
  </div>
</div>
