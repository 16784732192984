import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { noop } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreviousTabService {
  private previousTab: string;
  private previousTabLabel: string;

  constructor(private router: Router) {}

  public setupRouteChangeListener() {
    this.router.events
      .pipe(filter(event => event instanceof RoutesRecognized && event.urlAfterRedirects.indexOf('/menu/') !== -1))
      .subscribe((event: RoutesRecognized) => this.setPreviousTab(event.urlAfterRedirects), noop);
  }

  public getPreviousTab(): string {
    return this.previousTab;
  }

  public getPreviousFragment(): string {
    if (this.previousTab?.includes('#')) {
      return this.previousTab.split('#')[1].split('?')[0].replace('/', '');
    }
    return null;
  }

  public getPreviousTabLabel(): string {
    return this.previousTabLabel || 'Menu';
  }

  public setPreviousTab(url: string) {
    this.previousTab = decodeURIComponent(url.split('/menu/')[1].split('?')[0]);
    this.previousTabLabel = document.getElementById(this.getPreviousFragment())?.dataset?.tabName || 'Menu';
  }
}
