import { BaseResource } from '../core/base-resource';
import { GenericObject } from '../core/generic-object';
import { QuestionnaireResponse } from './questionnaire-response.model';
import { QuestionnaireType } from './questionnaire-type.enum';
import { Questionnaire } from './questionnaire.model';

export class QuestionnaireAnswer extends BaseResource {
  allowPublish: boolean;
  allowEmail: boolean;
  allowSubscribe: boolean;
  questionnaireName: string;
  questionnaireType: QuestionnaireType;
  email: string;
  orderId?: string;
  customerName: string;
  responses: GenericObject<QuestionnaireResponse>;

  static fromQuestionnaire(questionnaire: Questionnaire, orderId?: string): QuestionnaireAnswer {
    return new QuestionnaireAnswer().deserialize({
      questionnaireName: questionnaire.name,
      questionnaireType: questionnaire.type,
      responses: questionnaire.responses,
      allowEmail: questionnaire.allowEmail,
      allowPublish: questionnaire.allowPublish,
      allowSubscribe: questionnaire.allowSubscribe,
      customerName: questionnaire.customerName,
      email: questionnaire.email,
      orderId: orderId,
    });
  }
}
