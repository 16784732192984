import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Theme } from '../../domain/theme.model';

import { HttpClient } from '@angular/common/http';
import { WebSocketUtils } from '../../shared/web-socket-utils';
import { RxStompService } from '@stomp/ng2-stompjs';
import { PagePosition } from '../../domain/page-position.enum';
import { ViewportService } from './viewport.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly BASE_PATH = '/ws/integrated/v1/customization/latte';

  private themeSupportWSUrl = '/ws/socket/websocket';
  private _theme: BehaviorSubject<Theme> = new BehaviorSubject(new Theme());
  public theme: Observable<Theme> = this._theme.asObservable();

  constructor(private http: HttpClient, private rxStompService: RxStompService, private viewportService: ViewportService) {
    this.viewportService.viewport$
      .pipe(
        filter(() => this._theme.value.hasTiledMenu),
        map(viewport => viewport.isMobile && this._theme.value.showCategoriesUpfront)
      )
      .subscribe(hideTiledMenuInMobile => {
        const newTheme = Object.assign(new Theme(), this._theme.value);
        newTheme.shouldShowTiledMenu = !hideTiledMenuInMobile;
        this._theme.next(newTheme);
      });
  }

  loadTheme() {
    this.http
      .get(`${this.BASE_PATH}/themes/active`)
      .pipe(
        map((json): Theme => Object.assign(new Theme(), json)),
        map(theme => {
          if (theme.hasTiledMenu) {
            theme.orderTreePosition = PagePosition.Top;
            theme.menuPosition = PagePosition.Top;
          }
          return theme;
        }),
        catchError(() => {
          return throwError(new Error('Failed to load theme from server'));
        })
      )
      .subscribe((theme: Theme) => this._theme.next(theme));
  }

  initLiveThemePreview() {
    const config = WebSocketUtils.getWebSocketConfiguration(this.themeSupportWSUrl);
    this.rxStompService.configure(config);
    this.rxStompService.activate();
    this.rxStompService.watch('/user/queue/latteTheme').subscribe(data => {
      const response = JSON.parse(data.body) as Object;
      this._theme.next(Object.assign(new Theme(), response['theme'] as Theme));
      const head = document.getElementsByTagName('head')[0];
      const existingStyle = head.querySelectorAll('[data-id=custom-style]')[0];

      if (existingStyle) {
        existingStyle.innerHTML = response['css'];
      } else {
        const styleTag = document.createElement('style');

        styleTag.setAttribute('data-id', 'custom-style');
        styleTag.innerHTML = response['css'];
        head.appendChild(styleTag);
      }

      const savedStyle = head.querySelectorAll('[data-id=saved-custom-style]')[0];
      if (savedStyle) {
        savedStyle.remove();
      }
    });
    this.rxStompService.watch('/user/queue/latteThemeErrors').subscribe(e => console.log(e));
  }
}
