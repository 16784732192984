<div class="saved-address-inner">
  <div class="fw-500" *ngIf="!isEditing">
    <div class="address-text" [ngClass]="{'selected selection-text-color': selected}">
      <p class="address-name">{{address.name}}</p>
      <p>{{useIntlAddressFormat ? address.intlFormattedStreet : address.formattedStreet}}</p>

      <p *ngIf='address.formattedDiffs'>{{address.formattedDiffs}}</p>

      <p>{{address.city}}, {{address.state}}<ng-container *ngIf="!hideZip">, {{address.zip}}</ng-container></p>
    </div>

    <div *ngIf="!hideInstructions && address.deliveryInstructions">
      <p class="address-instructions no-margin">{{address.deliveryInstructions}}</p>
    </div>

    <div class="badge bg-warning" *ngIf='address.warnings?.length'>
      {{'component.account_address_chooser.account_address_entry.incomplete_or_invalid' | translate}}
    </div>
  </div>
  <button *ngIf="!isEditing" class="btn btn-link btn-undecorated btn-sm no-padding edit font-secondary" (click)='onEditing()'
          [disabled]='isDeleting || disableRadioButtons' [attr.aria-label]="'component.account_address_chooser.account_address_entry.edit_address' | translate">
    <fa-icon [fixedWidth]="true" [icon]="faPencil"></fa-icon>
  </button>
  <button *ngIf="!isEditing" class="btn btn-link btn-undecorated btn-sm no-padding remove font-secondary" (click)='deleteAddress(address)'
          [disabled]='isDeleting || disableRadioButtons' [attr.aria-label]="'component.account_address_chooser.account_address_entry.remove_address' | translate">
    <fa-icon [fixedWidth]="true" class="text-danger" [spin]="isDeleting" [icon]="isDeleting ? faCircleNotch : faTimes"></fa-icon>
  </button>
</div>
