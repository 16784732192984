import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Fundraiser } from '../../models/ordering/fundraiser.model';
import { OrderedFundraiser } from '../../models/ordering/ordered-fundraiser.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { AuditService } from '../core/audit.service';
import { OrderService } from './order.service';

@Injectable({ providedIn: 'root' })
export class OrderFundraiserService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/order/fundraisers';

  constructor(private http: HttpClient, private auditService: AuditService, private orderService: OrderService) {}

  public createOrderedFundraiser(fundraiser: Fundraiser): OrderedFundraiser {
    const orderedSponsors = fundraiser.sponsorsEnabled ? [] : undefined;

    return DeserializationUtils.deserializeObj(
      {
        name: fundraiser.name,
        sponsors: orderedSponsors,
      },
      OrderedFundraiser
    );
  }

  public addFundraiserToOrder(fundraiser: OrderedFundraiser): Observable<OrderedFundraiser> {
    return this.http.post(this.BASE_PATH, fundraiser.serialize()).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, OrderedFundraiser)),
      tap((orderedFundraiser: OrderedFundraiser) => this.auditFundraiserAddition(orderedFundraiser)),
      mergeMap(orderedFundraiser => this.orderService.fetchOrder().pipe(map(() => orderedFundraiser)))
    );
  }

  public updateFundraiser(fundraiser: OrderedFundraiser): Observable<OrderedFundraiser> {
    return this.http.put(`${this.BASE_PATH}/${fundraiser.fundraiserId}`, fundraiser.serialize()).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, OrderedFundraiser)),
      tap((orderedFundraiser: OrderedFundraiser) => this.auditFundraiserUpdate(orderedFundraiser)),
      mergeMap(orderedFundraiser => this.orderService.fetchOrder().pipe(map(() => orderedFundraiser)))
    );
  }

  public removeFundraiser(fundraiser: OrderedFundraiser): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.BASE_PATH}/${fundraiser.fundraiserId}`, { observe: 'response' }).pipe(
      tap(() => this.auditFundraiserRemoval(fundraiser)),
      mergeMap(response => this.orderService.fetchOrder().pipe(map(() => response)))
    );
  }

  /************************************************ Auditing helpers ************************************************/

  private auditFundraiserAddition(fundraiser: OrderedFundraiser): void {
    return this.auditService.createAudit(() => ({
      message: `Fundraiser "${fundraiser.name}" has been applied to order`,
      details: {
        sponsors: fundraiser.sponsors,
        fundraiserId: fundraiser.fundraiserId,
        warnings: fundraiser.warnings,
      },
    }));
  }

  private auditFundraiserUpdate(fundraiser: OrderedFundraiser): void {
    return this.auditService.createAudit(() => ({
      message: `Fundraiser "${fundraiser.name}" has been updated`,
      details: {
        sponsors: fundraiser.sponsors,
        fundraiserId: fundraiser.fundraiserId,
        warnings: fundraiser.warnings,
      },
    }));
  }

  private auditFundraiserRemoval(fundraiser: OrderedFundraiser): void {
    return this.auditService.createAudit(() => `Fundraiser "${fundraiser.name}" has been removed from order`);
  }
}
