import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, Params, Router, NavigationStart } from '@angular/router';
import {
  Account,
  AccountService,
  AuditService,
  ConfigService,
  FeaturedContent,
  FeaturedContentService,
  OrderService,
  StoreConfig,
  Utils,
  FeaturedContentName,
  TranslationService,
  RecentlyOrderedItemsService,
  GroupOrderService,
} from 'ngx-web-api';
import { Observable, of, Subscription, combineLatest, noop, interval, EMPTY, throwError } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  tap,
  take,
  withLatestFrom,
  delay,
  shareReplay,
  delayWhen,
  distinctUntilChanged,
  startWith,
  catchError,
} from 'rxjs/operators';
import { AccountLocalStorageService } from './core/services/account-local-storage.service';
import { InitParamsStorageService } from './core/services/init-params-storage.service';
import { ReloaderService } from './core/services/reloader.service';
import { ParamsOrchestratorService } from './core/services/params-orchestrator.service';
import { SessionExpirationService } from './core/services/session-expiration.service';
import { ThemeService } from './core/services/theme.service';
import { ViewportService } from './core/services/viewport.service';
import { Theme } from './domain/theme.model';
import { MetaTagService } from './core/services/meta-tag.service';
import { AccountUpdateMessage } from './domain/account-update-message';
import { AccountUpdateMessageEvent } from './domain/account-update-message-event.enum';
import { RxStompService } from '@stomp/ng2-stompjs';
import { WebSocketUtils } from './shared/web-socket-utils';
import { TranslateService } from '@ngx-translate/core';
import { CanonicalUrlService } from './services/canonical-url.service';
import smoothscroll from 'smoothscroll-polyfill';
import { PreviousTabService } from './core/services/previous-tab.service';
import { InitializationErrorsHandlerService } from './services/initialization-errors-handler.service';
import { PathService } from './shared/services/path.service';
import { SocialLinksService } from './core/services/social-links.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ModalService } from './core/services/modal.service';
import { MenuWrapperService } from './core/services/menu-wrapper.service';
import { UiOrchestratorService } from './core/services/ui-orchestrator.service';
import { SmsModalServie } from './shared/sms-modal/sms-modal.service';

@Component({
  selector: 'fts-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('mainApp', { static: true })
  mainApp: ElementRef;
  theme: Theme;
  isInIntro = false;
  isInHome = false;
  isInEditor = false;
  isInMenu = true;
  isInTiledMenuIndex = false;
  isInNotFound = false;
  readonly GENERIC_ERROR_CODE: number = 500;
  private documentTitle?: string;
  private logoHeight = 0;
  private notificationsHeight = 0;
  private excludedUrlPatterns: RegExp[] = [
    new RegExp('^/$'),
    new RegExp('/editor/item/\\d+$', 'i'),
    new RegExp('/editor/add-to-order', 'i'),
    new RegExp('/account/verify\\?Verify=\\w+$'),
  ];
  private excludedQueryParamsForAudits: string[] = ['accessToken', 'token', 'email', 'code'];
  private tiledMenuIndex = new RegExp('^/menu/?$');
  private menuRegex = new RegExp('^/menu.*$');
  private introRegex = new RegExp('^/intro.*$');
  private homeRegex = new RegExp('^/home.*$');
  private editorRegex = new RegExp('^/editor.*$');
  private notFoundRegex = new RegExp('^/not-found');
  private socketSubscription: Subscription;
  public bannerFeaturedContent$: Observable<FeaturedContent>;
  public storeConfig$: Observable<StoreConfig>;
  public loadingRoute = false;
  public isInPay$: Observable<boolean>;
  public isInSearch$: Observable<boolean>;
  public menuTabsRendered$: Observable<boolean>;
  public bannerHeight$: Observable<number>;
  public orderTreeIsOpen$: Observable<boolean>;
  public isInTiledMenuIndex$: Observable<boolean>;
  public showUpfrontOrTiled$: Observable<boolean>;
  public loadingLoginProcess: boolean;
  public isInPrintReceipt$: Observable<boolean>;

  constructor(
    private themeService: ThemeService,
    private sessionExpirationService: SessionExpirationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private orderService: OrderService,
    private auditService: AuditService,
    private accountService: AccountService,
    private configService: ConfigService,
    private cdRef: ChangeDetectorRef,
    public viewportService: ViewportService,
    private paramsOrchestratorService: ParamsOrchestratorService,
    private location: Location,
    private initParamsStorage: InitParamsStorageService,
    private accountLocalStorage: AccountLocalStorageService,
    private featuredContentService: FeaturedContentService,
    private meta: MetaTagService,
    private reloaderService: ReloaderService,
    private rxStompService: RxStompService,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private canonicalUrlService: CanonicalUrlService,
    private previousTabService: PreviousTabService,
    private initializationErrorsHandlerService: InitializationErrorsHandlerService,
    private pathService: PathService,
    private socialLinksService: SocialLinksService,
    private liveAnnouncer: LiveAnnouncer,
    private modalService: ModalService,
    private menuWrapperService: MenuWrapperService,
    private uiService: UiOrchestratorService,
    private recentlyOrderedItemsService: RecentlyOrderedItemsService,
    private groupOrderService: GroupOrderService,
    private smsModalService: SmsModalServie
  ) {
    this.checkMobileSize();
    this.bannerHeight$ = this.uiService.bannerHeight$;
    this.isInPay$ = this.pathService.inPay$;
    this.isInSearch$ = this.pathService.inSearch$;
    this.menuTabsRendered$ = this.menuWrapperService.menuTabsRendered$.pipe(delay(0));
    this.paramsOrchestratorService.initListeners();
    this.storeConfig$ = this.configService.storeConfig$;
    this.isInPrintReceipt$ = this.pathService.isInPrintReceipt$;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingRoute = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.loadingRoute = false;
      }
    });

    this.storeConfig$.subscribe((storeConfig: StoreConfig) => {
      if (storeConfig.supportsSmsSubscriptions) {
        this.accountService.setSMSDisclaimer(
          this.translateService.instant('component.sms_sign_up_information.start') +
            this.translateService.instant('component.sms_sign_up_information.terms_and_conditions') +
            this.translateService.instant('component.sms_sign_up_information.and') +
            this.translateService.instant('component.sms_sign_up_information.privacy_policy') +
            this.translateService.instant('component.sms_sign_up_information.end')
        );
      }
    });
  }

  ngOnInit(): void {
    this.queryParamLogin();
    this.showUpfrontOrTiled$ = this.uiService.showUpfrontOrTiled$.pipe(startWith(false), shareReplay(1));
    this.orderTreeIsOpen$ = this.uiService.orderTreeIsOpen$.pipe(delay(1));
    this.isInTiledMenuIndex$ = this.uiService.showTiledMenuLinks$.pipe(delay(1));
    this.checkRedirectionFromHostedPayment();
    this.previousTabService.setupRouteChangeListener();
    // TODO: Only when a specific query param is passed we should add the event listener
    this.themeService.theme.subscribe(theme => (this.theme = theme));
    this.checkInitThemeSupport();
    this.sessionExpirationService.startChecking();

    this.setDocumentTitles();
    this.setFavicon();
    this.fixScrollingWhenChangingRouter();
    this.setupNavigationEvents();
    this.setupLoginListener();
    this.setupLogoutListener();
    this.accountLocalStorage.setupAccountUpdatedListener();
    this.accountLocalStorage.setupAccountSignedInOutListener();
    this.setupAccountDeletionListener();
    this.bannerFeaturedContent$ = this.featuredContentService.getFeaturedContentsByAreaName(FeaturedContentName.TOP_BANNER).pipe(
      map((fcs: FeaturedContent[]) => fcs[0]),
      shareReplay()
    );
    this.meta.addMetaTags();

    this.canonicalUrlService.loadCanonicalUrl();
    this.initializationErrorsHandlerService.errors$
      .pipe(
        filter(hasError => !!hasError),
        take(1)
      )
      .subscribe(parsedError => {
        this.router.navigate(['404'], {
          state: {
            errorCode: this.GENERIC_ERROR_CODE,
            errorMessage: parsedError.allErrors.join(' ') || 'component.app.generic_error',
            buttonLabel: 'component.app.reload',
          },
        });
      });
    smoothscroll.polyfill();
  }

  ngAfterViewInit() {
    this.checkMobileSize();
    this.cdRef.detectChanges();

    const el = document.getElementById('ftsPreloadContent');
    if (!!el) {
      combineLatest([this.bannerFeaturedContent$, this.socialLinksService.socialLinks$])
        .pipe(
          map(([bannerContent, socialLinks]) => {
            return (
              window.innerWidth < 768 && (bannerContent?.featuredItems?.length || !!socialLinks?.androidAppId || !!socialLinks?.iOSAppId)
            );
          }),
          // Add a small extra delay to allow top content to be rendered and avoid content shift
          delayWhen(hasMobileTopContent => (hasMobileTopContent ? interval(100) : of(null))),
          tap(() => el.classList.add('fts-preload-fade-out')),
          delay(500),
          take(1)
        )
        .subscribe(() => el.parentElement.removeChild(el), noop);
    }
  }

  ngOnDestroy() {
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe();
    }
    this.rxStompService.deactivate();
  }

  @HostListener('window:resize')
  onResize() {
    this.checkMobileSize();
  }

  get title() {
    return this.titleService.getTitle();
  }

  checkMobileSize() {
    this.viewportService.checkSize(window);
  }

  setLogoHeight(height) {
    this.logoHeight = height;
  }

  setNotificationsHeight(height) {
    this.notificationsHeight = height;
  }

  private setDocumentTitles() {
    const separator = '-';
    let cacheTitle: string;
    this.configService.storeConfig$
      .pipe(map((config: StoreConfig) => config.documentTitle))
      .subscribe(documentTitle => (this.documentTitle = documentTitle));
    combineLatest([this.router.events, this.translationService.currentLanguage$])
      .pipe(
        map(([events, lang]) => events),
        filter(event => event['routerEvent'] instanceof NavigationEnd),
        mergeMap(() => this.deepActivatedRoute()),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data),
        withLatestFrom(this.configService.storeConfig$.pipe(map((config: StoreConfig) => config.documentTitle))),
        mergeMap(([event]) => (!!event['title'] ? this.translateService.get(event['title']) : of(''))),
        map(translatedTitle => `${translatedTitle} ${separator} ${this.documentTitle}`)
      )
      .subscribe(title => {
        this.titleService.setTitle(title);
        if (!!title && cacheTitle !== title) {
          setTimeout(() => this.liveAnnouncer.announce(`Navigated to ${title.split(separator)[0]}page`, 5000), 500);
          cacheTitle = title;
        }
      });
  }

  private setFavicon() {
    this.configService.storeConfig$
      .pipe(
        map((config: StoreConfig) => config.favicon),
        take(1),
        filter(icon => !!icon)
      )
      .subscribe(favicon => document.getElementById('favicon').setAttribute('href', favicon));
  }

  private fixScrollingWhenChangingRouter() {
    const logoAndNotificationsHeight = (this.logoHeight || 0) + (this.notificationsHeight || 0);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd && !this.router.parseUrl(this.router.url).fragment),
        map(() => window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
        filter(scrollTop => logoAndNotificationsHeight <= scrollTop),
        map(() => document.scrollingElement || document.documentElement)
      )
      .subscribe(scrollingElement => setTimeout(() => (scrollingElement.scrollTop = logoAndNotificationsHeight)));
  }

  private setupNavigationEvents() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel),
        take(1),
        map((event: NavigationEnd | NavigationCancel) => this.router.parseUrl(event.url).queryParams),
        map(params => params && params['FoodTecTracker']),
        filter(key => !!key),
        mergeMap((key: string) => this.orderService.setSessionTrackerKey(key))
      )
      .subscribe(() => {});

    this.router.events
      .pipe(filter(event => event instanceof NavigationError))
      .subscribe((event: NavigationError) => this.auditService.createAudit(() => `Failed navigation to: ${event.url}`));

    const navigationEnd$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects)
    );

    navigationEnd$.subscribe((eventUrl: string) => {
      this.isInMenu = this.menuRegex.test(eventUrl);
      this.isInTiledMenuIndex = this.theme.shouldShowTiledMenu && this.tiledMenuIndex.test(eventUrl);
      this.isInIntro = this.introRegex.test(eventUrl);
      this.isInHome = this.homeRegex.test(eventUrl);
      this.isInEditor = this.editorRegex.test(eventUrl);
      this.isInNotFound = this.notFoundRegex.test(eventUrl);
      this.modalService.closeAllModals();
      this.smsModalService.initSmsModal();
    });
    navigationEnd$
      .pipe(
        filter((eventUrl: string) => this.excludedUrlPatterns.every(excludedUrlPattern => !excludedUrlPattern.test(eventUrl))),
        map((eventUrl: string) => Utils.removeQueryParams(eventUrl, ...this.excludedQueryParamsForAudits)),
        filter((eventUrl: string) => !(eventUrl.indexOf('previousTab') !== -1))
      )
      .subscribe(eventUrl => this.auditService.createAudit(() => `Navigated to: ${eventUrl}`));

    navigationEnd$
      .pipe(
        take(1),
        map((url: string) => this.router.parseUrl(url).queryParams),
        tap((params: Params) => {
          if (params && (params['code'] || params['accessToken'] || (params['email'] && params['token']))) {
            delete params['accessToken'];
            delete params['email'];
            delete params['token'];
            delete params['code'];
            this.location.go(
              this.router
                .createUrlTree([], {
                  relativeTo: this.activatedRoute,
                  queryParams: params,
                })
                .toString()
            );
          }
        })
      )
      .subscribe();
  }

  private setupLogoutListener() {
    this.accountService.userLogout$
      .pipe(
        mergeMap((_: any) => this.deepActivatedRoute()),
        mergeMap((route: ActivatedRoute) => route.data),
        map(data => data['skipRedirect']),
        mergeMap(skipRedirect => (skipRedirect ? this.router.navigate(['/']) : Promise.resolve({})))
      )
      .subscribe(() => this.reloaderService.reload());
  }

  private deepActivatedRoute() {
    return of(this.activatedRoute).pipe(
      map((route: ActivatedRoute) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
    );
  }

  private setupLoginListener() {
    this.accountService.account$
      .pipe(
        filter((account: Account) => !account.dummy && account.isInstantiated),
        distinctUntilChanged(acc => acc.isInstantiated),
        mergeMap(account => this.orderService.fetchOrder().pipe(map(() => account))),
        tap(() => this.liveAnnouncer.announce(this.translateService.instant('component.login_form.login_success'), 2000))
      )
      .subscribe(
        (account: Account) => {
          this.setUpWsAccountListener(account);
        },
        () => {}
      );
  }

  private setupAccountDeletionListener() {
    this.accountService.accountDeleted$.subscribe(() => this.reloaderService.reload());
  }

  private checkInitThemeSupport() {
    if (!!this.initParamsStorage.initParams.theming) {
      this.themeService.initLiveThemePreview();
    }
  }

  private setUpWsAccountListener(account: Account) {
    if (this.rxStompService.connected()) {
      return;
    }
    const config = WebSocketUtils.getWebSocketConfiguration();
    this.rxStompService.configure(config);
    this.rxStompService.activate();
    this.socketSubscription = this.rxStompService
      .watch(`/topic/${btoa(account.webCustomerId)}`)
      .pipe(
        mergeMap(update => {
          const accountUpdateMessage: AccountUpdateMessage = JSON.parse(update.body);
          if (accountUpdateMessage.event === AccountUpdateMessageEvent.UPDATE) {
            return this.accountService.fetchAccount(true);
          } else if (accountUpdateMessage.event === AccountUpdateMessageEvent.DELETE) {
            return this.accountService.logout().pipe(
              catchError(() => {
                this.reloaderService.reload('/');
                return throwError(EMPTY);
              })
            );
          }
        })
      )
      .subscribe(noop, noop);
  }

  private checkRedirectionFromHostedPayment() {
    if (this.initParamsStorage.initParams.redirectFrom === 'hostedPayment') {
      this.auditService.createAudit(
        () => `Redirected from external payment page with status : ${this.initParamsStorage.initParams.status || 'valid'}`
      );
      this.initParamsStorage.initParams.params.set('utm_source', 'mobile_app_link');
      if (this.initParamsStorage.initParams.status === 'declined') {
        this.modalService.openErrorNotificationModal('Your credit card was declined. Please re-enter card information.');
      }
    }
  }

  private queryParamLogin() {
    const params = this.initParamsStorage.initParams;

    this.accountService.account$
      .pipe(
        take(1),
        mergeMap((account: Account) => {
          this.loadingLoginProcess = true;
          if (account.isInstantiated) {
            return of(account);
          }

          if (!Utils.isNullOrUndefined(params.accessToken)) {
            return this.accountService.oAuthLogin(params.accessToken);
          } else if (!Utils.isNullOrUndefined(params.email) && !Utils.isNullOrUndefined(params.token)) {
            return this.accountService.authTokenLogin(params.email, params.token);
          }
        }),
        mergeMap((account: Account) =>
          account?.isInstantiated ? this.groupOrderService.fetchGroupOrder().pipe(map(() => account)) : of(null)
        ),
        mergeMap((account: Account) => (account?.isInstantiated ? this.recentlyOrderedItemsService.getRecentlyOrderedItems() : of(null)))
      )
      .subscribe(
        () => (this.loadingLoginProcess = false),
        () => (this.loadingLoginProcess = false)
      );
  }
}
