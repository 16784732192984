import { BaseResource } from '../core/base-resource';
import { ChannelName } from './channel-name.enum';
import { SubscriptionsService } from './subscriptions-service.model';
import { DeserializationUtils } from '../../utils/public_api';

export class CustomerSubscription extends BaseResource {
  channelName: ChannelName;
  services: SubscriptionsService[];
  verified: boolean;

  serialize() {
    return Object.assign(this, {
      services: (this.services || []).map(svcs => svcs.serialize()),
    });
  }

  deserialize(input: any) {
    return Object.assign(this, input, {
      services: DeserializationUtils.deserializeChildren(input, 'services', SubscriptionsService),
    });
  }
}
