import { GoCartPaymentMethod } from './go-cart-payment-method';
import { BaseResource } from '../core/base-resource';
import { DeserializationUtils } from '../../utils/public_api';

export class GoCartCustomerInfo extends BaseResource {
  email: string;
  phoneNumber: string;
  paymentMethod: GoCartPaymentMethod;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      paymentMethod: DeserializationUtils.deserializeChild(input, 'paymentMethod', GoCartPaymentMethod),
    });
  }

  serialize(): any {
    return {
      email: this.email,
      phoneNumber: this.phoneNumber,
      paymentMethod: this.paymentMethod.serialize(),
    };
  }
}
