import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FeaturedContentName, FeaturedContentService, Order, OrderService } from 'ngx-web-api';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { MenuWrapperService } from '../core/services/menu-wrapper.service';
import { InitParamsStorageService } from '../core/services/init-params-storage.service';

@Injectable()
export class IntroGuard implements CanActivate {
  constructor(
    private orderService: OrderService,
    private router: Router,
    private menuService: MenuWrapperService,
    private featuredContentService: FeaturedContentService,
    private initParamsStorage: InitParamsStorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    const utmParams = this.initParamsStorage.initParams.getUtmParams(route.queryParams);

    return this.orderService.order$.pipe(
      filter((order: Order) => !order.dummy),
      mergeMap((order: Order) =>
        !order.isInitialized ? of(true) : this.menuService.getLatestMenu(order.orderType, order.deferTime).pipe(mergeMap(() => of(false)))
      ),
      mergeMap(orderNotInitialized => {
        if (orderNotInitialized) {
          return this.featuredContentService.getFeaturedContentsByAreaName(FeaturedContentName.HOME_PAGE).pipe(
            map(featureContents => !!featureContents[0]),
            tap(
              hasHomeFeaturedContent =>
                hasHomeFeaturedContent &&
                this.router.navigate(['/home'], {
                  queryParams: utmParams,
                  queryParamsHandling: 'merge',
                })
            ),
            map(hasHomeFeaturedContent => !hasHomeFeaturedContent)
          );
        } else {
          const params = route.queryParams['makeItAGroupOrder'] === 'true' ? { ...utmParams, makeItAGroupOrder: true } : utmParams;
          this.router.navigate(['menu'], {
            queryParams: params,
            queryParamsHandling: 'merge',
          });
          return of(false);
        }
      })
    );
  }
}
