import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { StoreConfig, Account, LoyaltyPlan, ConfigService, OrderTypeConfig, FeaturedContent, Order } from 'ngx-web-api';
import { Theme } from 'app/domain/theme.model';
import { FocusService } from '../../../focus.service';
import { Observable } from 'rxjs';
import { PathService } from 'app/shared/services/path.service';
import { BreakpointService } from '../../services/breakpoint.service';
import { ViewportService } from 'app/core/services/viewport.service';
import { GroupOrderOrchestratorService } from 'app/core/services/group-order-orchestrator.service';

@Component({
  selector: 'fts-vertical-menu-links',
  templateUrl: './vertical-menu-links.component.html',
  styleUrls: ['./vertical-menu-links.component.scss'],
})
export class VerticalMenuLinksComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  account: Account;
  @Input()
  store: StoreConfig;
  @Input()
  loyaltyPlan: LoyaltyPlan;
  @Input()
  order: Order;
  @Input()
  isInCheckoutDetails: boolean;
  @Input()
  theme: Theme;
  @Input()
  showChangeStoreBtn: boolean;
  @Input()
  isInIntro: boolean;
  @Input()
  isSelfOrder: boolean;
  @Input()
  featuredContents: FeaturedContent[];
  @Input()
  minifiedHeader = false;
  @Output()
  login: EventEmitter<void> = new EventEmitter();
  @Output()
  logout: EventEmitter<void> = new EventEmitter();
  @Output()
  changeStore: EventEmitter<void> = new EventEmitter();

  orderTypeConfig: OrderTypeConfig;
  isInThanksPage$: Observable<boolean>;
  isInTrackerPage$: Observable<boolean>;
  orderInitialized: boolean;

  constructor(
    private configService: ConfigService,
    private focusService: FocusService,
    private pathService: PathService,
    public breakpointService: BreakpointService,
    private menuLinksRef: ElementRef,
    private viewportService: ViewportService,
    public groupOrderOrchestrationService: GroupOrderOrchestratorService
  ) {
    this.isInThanksPage$ = this.pathService.inThanks$;
    this.isInTrackerPage$ = this.pathService.inTracker$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['order']?.currentValue?.orderType) {
      this.configService.findOrderTypeConfig(this.order.orderType).subscribe(orderTypeConfig => {
        this.orderTypeConfig = orderTypeConfig;
      });
    }

    if (changes['order']) {
      this.orderInitialized = !!this.order && !this.order.dummy && this.order.isInitialized;
    }
  }

  ngAfterViewInit() {
    if (!this.viewportService.isOpenInMobileBrowser) {
      this.focusService.activateFocusTrap(this.menuLinksRef.nativeElement);
    }
  }

  ngOnDestroy() {
    if (!this.viewportService.isOpenInMobileBrowser) {
      this.focusService.deactivateFocusTrap();
    }
  }
}
