import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { AuditService } from '../services/core/audit.service';
import { ERROR_HANDLER_OPTIONS, ErrorHandlerOptions } from './error-handler-options';
import { FtsErrorReporterService } from './fts-error-reporter.service';

@Injectable()
export class FtsErrorHandler extends ErrorHandler {
  private handlerOptions: ErrorHandlerOptions;
  private cachedError: any = {};

  constructor(
    private ftsErrorReporter: FtsErrorReporterService,
    private auditService: AuditService,
    @Inject(ERROR_HANDLER_OPTIONS) options: ErrorHandlerOptions
  ) {
    // The true parameter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
    // when an error happens. If we do not rethrow, bootstrap will always succeed.
    super();

    this.handlerOptions = options;
    Sentry.configureScope(scope =>
      scope.addEventProcessor(
        event =>
          new Promise(resolve =>
            resolve({
              ...event,
              environment: options.environment,
            })
          )
      )
    );
  }

  handleError(error: any): void {
    const cachedErrorString = this.cachedError ? this.cachedError.toString() : '';
    if (!(error instanceof HttpErrorResponse) && error.toString() !== cachedErrorString) {
      this.auditService.createAudit(() => `Client error thrown: "${error}"`);
      this.cachedError = error;
    }

    if (!this.handlerOptions.skipEmailReporting && error.stack) {
      this.ftsErrorReporter.report(error);
    }

    if (!this.handlerOptions.skipSentryReporting) {
      Sentry.captureException(error);
    }

    // delegate to the default handler
    super.handleError(error);
  }
}
