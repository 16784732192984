import { OrderableItem, OrderableSpecialStepAlternative, OrderableSpecialStepAlternativeItem, OrderedItem } from 'ngx-web-api';

export class SpecialWizardStep {
  stepIndex: number;
  title: string;
  stepAlternatives: OrderableSpecialStepAlternative[];

  selectedOrderableItem: OrderableItem | null;
  selectedOrderedItem: OrderedItem | null;

  shouldForceItemSelection: boolean;

  reset(): void {
    this.stepAlternatives.forEach(alternative => {
      alternative.items.forEach(stepItem => (stepItem.selectedSize = undefined));
    });
    this.selectedOrderableItem = null;
    this.selectedOrderedItem = null;
  }

  get canChangeItem(): boolean {
    let numberOfOneItemAlternatives = 0;
    this.stepAlternatives.forEach(alternative => {
      if (alternative.items.length === 1) {
        numberOfOneItemAlternatives++;
      }
    });
    if (this.stepAlternatives.length === numberOfOneItemAlternatives) {
      return this.stepAlternatives[0].items[0].availableSizePrices && this.stepAlternatives[0].items[0].availableSizePrices.length > 1;
    }

    return true;
  }

  get isFulfilled(): boolean {
    return !!this.selectedOrderedItem && !this.selectedOrderedItem.hasInvalidChoice;
  }

  get selectedOrderedItemSizeLabel(): string {
    const orderedSizeName = this.selectedOrderedItem ? this.selectedOrderedItem.size : '';
    const sizePrice = this.selectedOrderableItem && this.selectedOrderableItem.getSizePrice(orderedSizeName);
    return sizePrice ? sizePrice.label : '';
  }

  isSelectedAlternativeItem(stepItem: OrderableSpecialStepAlternativeItem) {
    return this.selectedOrderableItem && stepItem.label === this.selectedOrderableItem.label;
  }
}
