import { Params } from '@angular/router';
import { GenericObject, DeferChoice } from 'ngx-web-api';

export class InitParams {
  public readonly params: Map<string, any> = new Map();
  private readonly multiValueParams = ['specialCode', 'sponsor'];
  private readonly retainedKeys: string[] = ['embedded', '_ga', 'utm_source'];

  parseParams(params: Params) {
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (this.multiValueParams.indexOf(key) !== -1) {
        this.params.set(key, value);
      } else {
        this.params.set(key, value && value.length > 0 ? value[value.length - 1] : value);
      }
    });
  }

  resetParams(resetAll = false) {
    Array.from(this.params.keys())
      .filter(key => resetAll || this.retainedKeys.indexOf(key) === -1)
      .forEach(key => this.params.delete(key));
  }

  getRetainedParams(): Params {
    return this.filterParams(this.retainedKeys);
  }

  getUtmParams(currentParams: GenericObject<string>): GenericObject<string> {
    const paramKeys: string[] = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

    let hasUtmParam = false;
    this.params.forEach((value, key) => {
      hasUtmParam = hasUtmParam || currentParams.hasOwnProperty(key);
    });

    return hasUtmParam ? this.filterParams(paramKeys) : {};
  }

  filterParams(keys: string[]): Params {
    const params = {};

    this.params.forEach((value, key) => {
      if (keys.indexOf(key) !== -1) {
        params[key] = value;
      }
    });

    return params;
  }

  get orderType() {
    return this.params.get('orderType');
  }

  get address() {
    return this.params.get('address');
  }

  get zip() {
    return this.params.get('zip');
  }

  get addressType() {
    return this.params.get('addressType');
  }

  get diff1() {
    return this.params.get('diff1');
  }

  get diff2() {
    return this.params.get('diff2');
  }

  get diff3() {
    return this.params.get('diff3');
  }

  get embedded() {
    return this.params.get('embedded') === 'true';
  }

  get accessToken(): string {
    return this.params.get('accessToken');
  }

  get token(): string {
    return this.params.get('token');
  }

  get email(): string {
    return this.params.get('email');
  }

  get tab(): string {
    return this.params.get('tab');
  }

  get category(): string {
    return this.params.get('category');
  }

  get item(): string {
    return this.params.get('item');
  }

  get quantity(): number | undefined {
    const x = parseInt(this.params.get('quantity'), 10);
    if (!!x) {
      return x;
    }
  }

  get itemCode(): string {
    return this.params.get('itemCode');
  }

  get specialCode(): string[] {
    return this.params.get('specialCode');
  }

  get specialName(): string {
    return this.params.get('specialName');
  }

  get size(): string {
    return this.params.get('size');
  }

  get edit(): boolean {
    return !!this.params.get('edit') && this.params.get('edit') === 'true';
  }

  get skipIntro(): boolean {
    return !this.params.get('skipIntro') || this.params.get('skipIntro') === 'true';
  }

  get lat(): number | undefined {
    const x = parseFloat(this.params.get('lat'));
    return !!x ? x : undefined;
  }

  get lng(): number | undefined {
    const x = parseFloat(this.params.get('lng'));
    return !!x ? x : undefined;
  }

  get theming(): string {
    return this.params.get('theming');
  }

  get offerDisplayName(): string {
    return this.params.get('offerDisplayName');
  }

  get sourceIsMobile(): boolean {
    return !!this.params.get('utm_source') && this.params.get('utm_source') === 'mobile_app_link';
  }

  get makeItAGroupOrder(): boolean {
    return this.params.get('makeItAGroupOrder') === 'true';
  }

  get addCoupon(): string {
    return this.params.get('addCoupon');
  }

  get addCouponType(): string {
    return this.params.get('addCouponType');
  }

  get orderId(): string {
    return this.params.get('orderId');
  }

  get paymentOrdinal(): string {
    return this.params.get('paymentOrdinal');
  }

  get sourceIsSelfOrder(): boolean {
    return !!this.params.get('utm_source') && this.params.get('utm_source') === 'SelfOrder';
  }

  get tableNum(): string {
    return this.params.get('tableNum');
  }

  get deferChoice(): DeferChoice {
    return this.params.get('deferChoice');
  }

  get deferTime(): string {
    return this.params.get('deferTime');
  }

  get fundraisers(): string {
    return this.params.get('fundraiser');
  }

  get fundraisersSponsors(): string[] {
    return this.params.get('sponsor');
  }

  get sourceIsReorder(): boolean {
    return !!this.params.get('order');
  }

  // Worldpay will redirect with param {redirectFrom=hostedPayment}
  get redirectFrom(): string {
    return this.params.get('redirectFrom');
  }

  // Worldpay redirection URL param to handle validation status
  get status(): 'declined' | 'canceled' {
    return this.params.get('status');
  }

  get isExternalRef(): boolean {
    return this.params.get('isExternalRef') === 'true';
  }

  get ref(): string {
    return this.params.get('ref');
  }
}
