<div class="modal-content primary-bg-color primary-text-color">
  <div class="modal-header secondary-bg-color secondary-text-color">
    <h2 id="modal-title" class="modal-title float-left">
      <span *ngIf="isNewInvitation">{{'component.group_order_invitation.create_new_group_order' | translate}}</span>
      <span *ngIf="changeExpirationMode">{{'component.group_order_invitation.change_expiration' | translate}}</span>
      <span *ngIf="!isNewInvitation && !changeExpirationMode">{{'component.group_order_invitation.invite_friends' | translate}}</span>
    </h2>
    <button class="close float-right" (click)="handleCancel()" [attr.aria-label]="'component.group_order_invitation.close' | translate">
          <span aria-hidden="true">&times;</span>
        </button>
  </div>
  <div class="loader action-text-color" *ngIf="loading && groupOrderInviteWithLinkSection">
    <fts-loading-indicator></fts-loading-indicator>
  </div>
  <div class="modal-body" *ngIf="!loading">
    <div class="text-left" *ngIf="changeExpirationMode || isNewInvitation">
      <h4 *ngIf="isNewInvitation && !changeExpirationMode">{{'component.group_order_invitation.group_order_expire_heading' | translate}}</h4>
      <h4 *ngIf="!isNewInvitation && groupOrderInviteWithLinkSection && !changeExpirationMode">{{'component.group_order_invitation.sharing_link_heading' | translate}}</h4>
    </div>

    <div *ngIf="!isNewInvitation && !changeExpirationMode" class="text-left">
        <ng-container>
          <div class="expandable-buttons-container">
            <button class="btn btn-undecorated px-0 font-primary primary-text-color bg-transparent"
                aria-controls="shared-link-wrapper" [attr.aria-expanded]="expandedSharedLinkPanel"
                (click)="expandedSharedLinkPanel = !expandedSharedLinkPanel" type="button">
              <span class="fw-600">{{'component.group_order_invitation.invite_by_link' | translate}}</span>
              <fa-icon [icon]="expandedSharedLinkPanel ? faCaretDown : faCaretRight" [fixedWidth]="true"></fa-icon>
            </button>
            <span class="small">{{"component.group_order_invitation.invite_by_link_explanation_text" | translate}}</span>
          </div>

          <div id="shared-link-wrapper" [@collapse]="!expandedSharedLinkPanel" (@collapse.done)="auditSharedLinkPanelExpansion()">
            <ng-container *ngIf="((!!groupOrder?.inviteeUrl && groupOrderInviteWithLinkSection) || (!groupOrderInviteWithLinkSection && !isNewInvitation)) && !changeExpirationMode">
              <div class="d-flex flex-column align-items-start" [ngClass]="{'mb-4': !isNewInvitation && !groupOrderInviteWithLinkSection}">
                <div class="input-group margin-bottom-5">
                  <input type="text" class="form-control primary-bg-color" [value]="groupOrder?.inviteeUrl" [attr.aria-label]="('component.group_order_invitation.invite_link' | translate) + ' ' + groupOrder?.inviteeUrl" readonly>
                  <button class="btn action-bg-color action-inverse-text-color primary-btn action-btn copy-btn" type="button" (click)="onClickGroupOrderInviteLink()">
                    <span>
                      <span>{{(copiedGroupOrderInviteLink ? 'component.group_order_invitation.copied' : 'component.group_order_invitation.copy') | translate}}</span>
                      <fa-icon [size]="'lg'" [icon]="copiedGroupOrderInviteLink ? faCheck : faCopy" class="ms-2"></fa-icon>
                    </span>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <span>{{"component.group_order_invitation.or" | translate}}</span>

        <ng-container>
          <div class="expandable-buttons-container">
            <button class="btn btn-undecorated px-0 font-primary primary-text-color bg-transparent"
                aria-controls="email-wrapper" [attr.aria-expanded]="expandedEmailPanel"
                (click)="expandedEmailPanel = !expandedEmailPanel" type="button">
              <span class="fw-600">{{'component.group_order_invitation.invite_by_email' | translate}}</span>
              <fa-icon [icon]="expandedEmailPanel ? faCaretDown : faCaretRight" [fixedWidth]="true"></fa-icon>
              <span class="badge action-bg-color action-inverse-text-color ms-2">{{selectedFriendsList.length}} / {{ groupOrderingFriends.length }}</span>
            </button>
            <span class="small">{{"component.group_order_invitation.invite_by_email_explanation_text" | translate}}</span>
          </div>

          <div id="email-wrapper" [@collapse]="!expandedEmailPanel" (@collapse.done)="auditEmailLinkPanelExpansion()">
            <ng-container *ngIf="!isNewInvitation && !changeExpirationMode">
              <div *ngIf="groupOrderingFriends.length" class="d-flex bold text-left justify-content-between align-items-center">
                <span class="friends-title text-muted">{{'component.group_order_invitation.select_members' | translate}}</span>
                <div>
                  <button class="btn btn-link primary-text-color bold text-uppercase text-decoration-underline" (click)="handleSelectAll()">{{'component.group_order_invitation.select_all' | translate}}</button> / <button class="btn btn-link primary-text-color bold text-uppercase text-decoration-underline"
                    (click)="handleUnselectAll()">{{'component.group_order_invitation.un_select_all' | translate}}</button>
                </div>
              </div>
        
              <div class="friends-container text-left">
                <div class="friend-row" *ngFor="let friend of groupOrderingFriends">
                  <div class="friend-name">
                    <fts-themeable-checkbox [(ngModel)]="friend._selected" [disabled]="friend._isInvited || friend.email === userEmail" (change)="handleShowInviteButton(friend)">
                      <span class="name">{{friend.name + ' (' + friend.email + ')'}}</span> <span *ngIf="friend._isInvited" class="badge bg-dark text-uppercase">
                      {{'component.group_order_invitation.invited' | translate}}</span>
                      <span *ngIf="friend.email === userEmail" class="badge bg-dark text-uppercase">
                        {{"component.group_order_invitation.invite_yourself" | translate}}
                      </span>
                    </fts-themeable-checkbox>
                  </div>
                  <div class="friend-remove">
                    <button ftsAuditableButton auditName="GroupOrder Invitation modal DELETE FRIEND" ftsAsyncButton
                            [attr.aria-label]="'component.group_order_invitation.remove_friend' | translate"
                            [subscription]="deletingFriendSubscription" class="btn btn-link btn-undecorated btn-sm no-padding float-right font-secondary"
                      (click)="handleDeleteFriend(friend)" [disabled]="friend._isInvited">
                          <fa-icon *ngIf="!deletingFriend" [fixedWidth]="true" class="text-danger" [icon]="faTimes"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
        
              <hr *ngIf="groupOrderingFriends.length" aria-hidden="true">
        
              <p class="bold text-muted text-left">{{'component.group_order_invitation.add_new_member' | translate}}</p>
              <div class="new-friend-form text-left">
                <div class="form-group required col padded-left-0" [ngClass]="{'has-error': errors?.name?.length > 0}">
                  <input name="name" [(ngModel)]="newFriend.name" type="text" class="form-control primary-bg-color primary-text-color" [placeholder]="'component.group_order_invitation.name' | translate"
                  [ngClass]="{'is-invalid': errors?.name?.length}" [attr.aria-label]="'component.group_order_invitation.name' | translate">
                </div>
                <div class="form-group required col padded-left-0" [ngClass]="{'has-error': errors?.email?.length > 0}">
                  <input name="email" [(ngModel)]="newFriend.email" type="email" class="form-control primary-bg-color primary-text-color" [placeholder]="'component.group_order_invitation.email' | translate"
                  [ngClass]="{'is-invalid': errors?.email?.length}" [attr.aria-label]="'component.group_order_invitation.email' | translate">
                </div>
                <div class="form-group col padded-left-0">
                  <button ftsAuditableButton auditName="GroupOrder Invitation modal ADD FRIEND" ftsAsyncButton [subscription]="addingFriend"
                    (click)="handleAddFriend()"
                    class="btn primary-bg-color selection-text-color add-friends-btn font-secondary">
                    {{'component.group_order_invitation.add_friend' | translate}}
                  </button>
                </div>
              </div>
              
              <div class="alert alert-danger alert-form text-left" role="alert" aria-atomic="true" *ngIf="errors || message">
                <p *ngFor="let error of errors | errorList">{{error}}</p>
                <p *ngIf="message">{{message}}</p>
              </div>
            </ng-container>
          </div>
        </ng-container>
    </div>


    <div *ngIf="(isNewInvitation || changeExpirationMode) && !groupOrderInviteWithLinkSection" class="text-left">

      <div class="form-inline expiration-container" [ngClass]="{'no-top-margin': changeExpirationMode}">

        <div class="form-group expiration-date-group required">
          <label for="group-order-expiration-date" class="bold col-form-label">{{'component.group_order_invitation.expiration_date' | translate}}:</label>
          <div class="expiration-date">
            <div class="input-group" (click)="toggleDatePicker()">
              <input (keydown.enter)="toggleDatePicker()" type="text" class="form-control primary-bg-color primary-text-color" id="group-order-expiration-date" readonly="readonly"
              [value]="(groupOrderingInvitation?.expirationDate | dateFormat : futureDateFormat) || ''">
              <div class="input-group-prepend">
                <div class="input-group-text"><fa-icon [icon]="faCalendar"></fa-icon></div>
              </div>
            </div>
            <ngb-datepicker
              [ngClass]="{'position-top': !changeExpirationMode}"
              [hidden]="!showDatePicker"
              [(ngModel)]="theExpirationDay"
              [firstDayOfWeek]="storeConfig.startCalendarWeekOn"
              (dateSelect)="toggleDatePicker($event)"
            ></ngb-datepicker>
          </div>
        </div>
        <div class="form-group expiration-time-group required">
          <label for="group-order-expiration-time" class="bold col-form-label">{{'component.group_order_invitation.expiration_time' | translate}}:</label>
          <div class="expiration-time">
            <div class="input-group" (click)="toggleTimePicker()">
              <input id="group-order-expiration-time" type="text" class="form-control primary-bg-color primary-text-color" readonly="readonly" [value]="(dateWithTime | dateFormat : 'h:mm a') || ''" (keydown.enter)="toggleTimePicker()">
              <div class="input-group-prepend">
                <div class="input-group-text"><fa-icon [icon]="faClock"></fa-icon></div>
              </div>
            </div>
            <ngb-timepicker
              [ngClass]="{'position-top': !changeExpirationMode}"
              [hidden]="!showTimePicker"
              [minuteStep]="minuteStep"
              [(ngModel)]="expirationTime"
              (ngModelChange)="onTimeChange($event)"
              [meridian]="true"
            ></ngb-timepicker>
            <div class="sr-only" (focus)="hideTimePicker()" tabindex="0"></div>
          </div>
        </div>
        <div *ngIf="!changeExpirationMode" class="form-text text-left">{{'component.group_order_invitation.expiration_date_help_text' | translate}}</div>

      </div>

      <div *ngIf="invitationDialogConfig?.allowGroupOrderMemberPayment && !changeExpirationMode" class="form-group member-payment-container">
        <fts-themeable-checkbox [(ngModel)]="groupOrderingInvitation.memberPayment"
                                [attr.aria-describedby]="'grouporder-invitation-soloPayment'">
          {{'component.group_order_invitation.members_pay_solo' | translate}}
        </fts-themeable-checkbox>
        <span id="grouporder-invitation-soloPayment" class="form-text">{{'component.group_order_invitation.members_pay_solo_help' | translate}}</span>
      </div>

      <div class="text-left" *ngIf="!changeExpirationMode">
        <label for="group-order-invitaion-note" class="invitation-note bold">{{'component.group_order_invitation.note' | translate}}:</label>
        <textarea id="group-order-invitaion-note" class="form-control primary-bg-color primary-text-color vert-resize" rows="3" maxlength="250" [(ngModel)]="groupOrderingInvitation.note"></textarea>
      </div>
    </div>

    <div class="alert alert-danger alert-form text-left mt-3" role="alert" aria-atomic="true" *ngIf="invitationErrors || invitationMessage"
      [ngClass]="{'no-bottom-margin margin-top-15': changeExpirationMode}">
      <p *ngFor="let error of invitationErrors | errorList">{{error}}</p>
      <p *ngIf="invitationMessage">{{invitationMessage}}</p>
    </div>

  </div>
  <div class='modal-footer' *ngIf="!loading">
    <button ftsAsyncButton [subscription]="invitingFriends" (click)="(isNewInvitation) ? handleNext() : (showInviteBtn || changeExpirationMode) ? handleSubmit() : handleCancel()" class="btn action-bg-color action-inverse-text-color text-uppercase primary-btn action-btn font-secondary">
      <span *ngIf="isNewInvitation && !groupOrderInviteWithLinkSection">{{'component.group_order_invitation.next_invite_people' | translate}}</span>
      <span *ngIf="changeExpirationMode">{{'component.group_order_invitation.submit' | translate}}</span>
      <span *ngIf="!changeExpirationMode && !isNewInvitation && showInviteBtn">{{'component.group_order_invitation.invite' | translate}}</span>
      <span *ngIf="!changeExpirationMode && !isNewInvitation && !showInviteBtn">{{'component.group_order_invitation.close' | translate}}</span>
    </button>
  </div>
</div>
