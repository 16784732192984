export enum RedirectKeys {
  _ga = '_ga',
  utm_source = 'utm_source',
  address = 'address',
  orderType = 'orderType',
  makeItAGroupOrder = 'makeItAGroupOrder',
  skipIntro = 'skipIntro',
  embedded = 'embedded',
  zip = 'zip',
  addressType = 'addressType',
  diff1 = 'diff1',
  diff2 = 'diff2',
  diff3 = 'diff3',
  lat = 'lat',
  lng = 'lng',
  addressName = 'addressName',
  tab = 'tab',
  category = 'category',
  item = 'item',
  itemCode = 'itemCode',
  size = 'size',
  quantity = 'quantity',
  edit = 'edit',
  specialName = 'specialName',
  specialCode = 'specialCode',
  offerDisplayName = 'offerDisplayName',
  store = 'store',
}
