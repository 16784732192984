import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { GroupOrderStatus } from './group-order-status.enum';
import { GroupOrder } from './group-order.model';

export class GroupOrderStatusChange extends BaseResource {
  status: GroupOrderStatus;
  groupOrder: GroupOrder;

  constructor(status: GroupOrderStatus, groupOrder: GroupOrder) {
    super();
    this.status = status;
    this.groupOrder = groupOrder;
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      groupOrder: DeserializationUtils.deserializeChild(input, 'groupOrder', GroupOrder),
    });
  }
}
