export enum TrackerOrderStatus {
  MAKING = 'MAKING',
  ON_THE_WAY = 'ON_THE_WAY',
  PICKUP_READY = 'PICKUP_READY',
  DELIVERED = 'DELIVERED',
  PICKED_UP = 'PICKED_UP',
  DEFERRED = 'DEFERRED',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  SUSPENDED = 'SUSPENDED',
  CANCELED = 'CANCELED',
  REVIEWING = 'REVIEWING',
  COOKING = 'COOKING',
  MAKING_EMULATED = 'MAKING_EMULATED',
  COOKING_EMULATED = 'COOKING_EMULATED',
}
