import { DeserializationUtils } from '../../utils/deserialization-utils';
import { CreditCard } from '../core/credit-card.model';
import { GiftCard } from '../core/gift-card.model';
import { ValidatedResource } from '../core/validated-resource';
import { Invoice } from './invoice.model';
import { OrderPaymentType } from './order-payment-type.enum';
import { Utils } from '../../utils/utils';
import { GoCartPayment } from './go-cart-payment';

export class OrderPayment extends ValidatedResource {
  ordinal: number;
  paymentDisplayName: string;
  paymentType: OrderPaymentType;
  amount: number;
  giftCard: GiftCard;
  creditCard: CreditCard;
  invoice: Invoice;
  appleToken: ApplePayJS.ApplePayPaymentToken;
  fee?: number;
  sequenceNum?: number;
  goCartPaymentResource: GoCartPayment;

  public static createPayment(paymentType: OrderPaymentType): OrderPayment {
    return new OrderPayment().deserialize({ paymentType: paymentType });
  }

  constructor() {
    super();
  }

  get isCash(): boolean {
    return this.paymentType === OrderPaymentType.CASH;
  }

  get isCreditCard(): boolean {
    return this.paymentType === OrderPaymentType.CREDIT_CARD;
  }

  get isGiftCard(): boolean {
    return this.paymentType === OrderPaymentType.GIFT_CARD;
  }

  get isInvoice(): boolean {
    return this.paymentType === OrderPaymentType.INVOICE || this.paymentType === OrderPaymentType.HOUSE_ACCOUNT;
  }

  get icon(): string {
    switch (this.paymentType) {
      case OrderPaymentType.CREDIT_CARD:
        return 'fa-credit-card';
      case OrderPaymentType.GIFT_CARD:
        return 'fa-gift';
      default:
        return 'fa-money';
    }
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      giftCard: DeserializationUtils.deserializeChild(input, 'giftCard', GiftCard),
      creditCard: DeserializationUtils.deserializeChild(input, 'creditCard', CreditCard),
      invoice: DeserializationUtils.deserializeChild(input, 'invoice', Invoice),
      appleToken: Utils.isNullOrUndefined(input.appleToken) ? undefined : input.appleToken,
    });
  }

  serialize(): any {
    return {
      ordinal: this.ordinal,
      paymentType: this.paymentType,
      amount: this.amount,
      giftCard: this.giftCard ? this.giftCard.serialize() : undefined,
      creditCard: this.creditCard ? this.creditCard.serialize() : undefined,
      invoice: this.invoice ? this.invoice.serialize() : undefined,
      appleToken: Utils.isNullOrUndefined(this.appleToken) ? undefined : this.appleToken,
      fee: this.fee ? this.fee : 0,
      sequenceNum: this.sequenceNum,
      goCartPaymentResource: !!this.goCartPaymentResource ? this.goCartPaymentResource.serialize() : undefined,
    };
  }
}
