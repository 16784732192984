<div>
  <fts-cart-button [order]="order" (click)="toggleOrderTree()" class="cart-button"></fts-cart-button>

  <div id="order-tree"
        role="region"
        class="order-tree primary-bg-color primary-text-color"
        *ngIf="orderTreeVisible"
        [@expanded]>
    <div class="primary-border-color arrow arrow-bottom arrow-indicator"></div>
    <ng-container *ngIf="orderTreeComponent">
      <ng-template [ngComponentOutlet]="orderTreeComponent | async"></ng-template>
    </ng-container>
  </div>
</div>
