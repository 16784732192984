import { Injectable } from '@angular/core';
import { BrowserStorageHandlerService } from 'app/core/services/browser-storage-handler.service';

export enum CookiePersistentKeys {
  SMS_MODAL_COOKIE = 'SMS_MODAL_COOKIE',
}
@Injectable({ providedIn: 'root' })
export class CookieService {
  // Please do not change the cookie name value, since our customers use this by convention
  private readonly policyCookieName = 'PolicyConsent';
  private readonly ipAddressRegExp = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

  private readonly SMS_COOKIE_EXP_IN_DAYS = 30;

  constructor(private browserStorageHandlerService?: BrowserStorageHandlerService) {}

  getCookie(name: string): string {
    const cookies: Array<string> = document.cookie.split(';').map(c => c.replace(/^\s\+/g, '').trim());
    const cookieName = `${name}=`;

    const cookie = cookies.find(c => c.indexOf(cookieName) === 0);
    return cookie ? decodeURIComponent(cookie.substring(cookieName.length)) : '';
  }

  setCookie(name: string, value: string, expireDays = 365, domain?: string) {
    let expires = '';
    if (expireDays) {
      const date = new Date();
      date.setDate(date.getDate() + expireDays);
      expires = date.toUTCString();
    }
    document.cookie = `${encodeURIComponent(name)}=${
      encodeURIComponent(value) || ''
    }; expires=${expires}; domain=${domain}; path=/; secure=true`;
  }

  setPolicyAcknowledgement() {
    this.setCookie(this.policyCookieName, '1', 365, this.getCookieDomainName());
  }

  setSmsAcknowledgementCookie(): void {
    this.setCookie(
      this.browserStorageHandlerService?.resolveKeyName(CookiePersistentKeys.SMS_MODAL_COOKIE),
      '1',
      this.SMS_COOKIE_EXP_IN_DAYS,
      this.getCookieDomainName()
    );
  }

  getSmsAcknowledgementCookie(): string {
    return this.getCookie(this.browserStorageHandlerService?.resolveKeyName(CookiePersistentKeys.SMS_MODAL_COOKIE));
  }

  setOrderId(orderId: string, expireDate: string, cookieName: string) {
    const domain = window.location.hostname;
    document.cookie = `${encodeURIComponent(cookieName)}=${orderId || ''}; expires=${expireDate}; domain=${domain}; path=/; secure=true`;
  }

  isPolicyAcknowledged(): boolean {
    return this.getCookie(this.policyCookieName) === '1';
  }

  private getCookieDomainName() {
    const hostname = window.location.hostname;
    // Split domain on '.' to separate subdomains and suffixes
    // NOTE: This code does not handle complex suffixes like *.co.uk
    const subdomainArray = hostname.split('.');
    // If hostname is an IP address or 'localhost' return as is
    if (hostname.match(this.ipAddressRegExp) || subdomainArray.length <= 1) {
      return hostname;
    }

    // If hostname includes 'foodtecsolutions.com' string return it with its subdomain
    // Else return hostname with a dot ('.') prefix to match subdomains
    if (hostname.toLowerCase().indexOf('foodtecsolutions.com') !== -1) {
      return `${subdomainArray[subdomainArray.length - 3]}.foodtecsolutions.com`;
    }
    return `.${subdomainArray[subdomainArray.length - 2]}.${subdomainArray[subdomainArray.length - 1]}`;
  }
}
