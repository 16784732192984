import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MobileMenu } from '../../../domain/mobile-menu.enum';
import { Panel } from 'ngx-web-api';
import { ViewportService } from '../../../core/services/viewport.service';
import { ViewPort } from '../../../domain/view-port.model';
import { distinctUntilChanged, filter, map, take, debounceTime, mergeMap } from 'rxjs/operators';
import { faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, of, Subject } from 'rxjs';
import { UiOrchestratorService } from 'app/core/services/ui-orchestrator.service';
import { ThemeService } from 'app/core/services/theme.service';
import { Theme } from 'app/domain/theme.model';
@Component({
  selector: 'fts-menu-link',
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.scss'],
})
export class MenuLinkComponent implements OnInit {
  @Input()
  panel: Panel;
  @Input()
  visibleMenu?: MobileMenu;
  @Input()
  isInEditor: boolean;
  @Input()
  lightFontWeight = false;
  @Input()
  showCategoriesUpfront: boolean;
  @Input()
  minifiedHeader = false;
  @Output()
  onToggleMobileMenu = new EventEmitter<MobileMenu>();

  menus = MobileMenu;
  showMenuButton: boolean;
  hideMenuButtonUpfront: boolean;
  hideMenuLink: boolean;
  faChevronRight = faChevronRight;
  faTimes = faTimes;
  isMobile: boolean;
  theme: Theme;
  buttonClicked = new Subject<any>();

  constructor(
    private router: Router,
    private uiService: UiOrchestratorService,
    private themeService: ThemeService,
    public viewportService: ViewportService
  ) {
    const viewportChange$ = this.viewportService.viewport$.pipe(
      map((viewport: ViewPort) => {
        this.isMobile = viewport.isMobile;
        return viewport.isMobile;
      }),
      distinctUntilChanged()
    );

    const routeUrlChange$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged()
    );

    const tiledMenuCategoriesOpen$ = this.themeService.theme.pipe(
      take(1),
      mergeMap(theme => (theme.shouldShowTiledMenu ? this.uiService.showTiledMenuLinks$ : of(false)))
    );

    combineLatest([viewportChange$, routeUrlChange$, tiledMenuCategoriesOpen$]).subscribe(
      ([isMobile, route, tiledMenuCategoriesOpen]: [boolean, NavigationEnd, boolean]) => {
        this.determineLinkStyle(route.urlAfterRedirects, isMobile, tiledMenuCategoriesOpen);
      }
    );
  }

  ngOnInit() {
    this.determineLinkStyle(this.router.url);
    this.themeService.theme.pipe(take(1)).subscribe(theme => (this.theme = theme));

    this.buttonClicked.pipe(debounceTime(500)).subscribe(() => {
      this.router.navigate(['/menu']);
    });
  }

  determineLinkStyle(route: string, isMobile = false, tiledMenuCategoriesOpen = false) {
    this.hideMenuButtonUpfront = (route === '/menu' && this.isMobile && this.showCategoriesUpfront) || tiledMenuCategoriesOpen;
    this.showMenuButton = route.indexOf('/menu') === 0;

    this.hideMenuLink = route.indexOf('/menu') === 0 || (route.indexOf('/editor') === 0 && isMobile);
  }

  toggleMobileMenu(visibleMenu: MobileMenu) {
    if (this.theme.shouldShowTiledMenu) {
      this.uiService.navigateToTiledMenuIndex();
      return;
    }
    if (this.isMobile && this.showCategoriesUpfront) {
      if (this.theme.hasSinglePageMenu) {
        this.uiService.setSinglePageMobileUpfrontMenu(true);
        if (this.router.url.includes('/menu/search')) {
          this.router.navigate(['menu', 'single-page']);
        }
        return;
      }
      this.router.navigate(['/menu']);
    } else {
      this.onToggleMobileMenu.emit(visibleMenu);
    }
  }
}
