<div>
  <hr aria-hidden="true" *ngIf="!account?.isInstantiated && minifiedHeader && featuredContents?.length">
  <ng-container *ngFor="let featuredContent of featuredContents">
    <a *ngFor="let featuredItem of featuredContent.featuredItems"
       [href]='featuredItem.link'
       [target]="featuredItem.target"
       class="btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase"
       [id]='"header-featured-link-" + (featuredItem.title | slugify)'>
       {{featuredItem.title}}
    </a>
  </ng-container>

  <ng-container *ngIf="!minifiedHeader; else groupOrderButtonInMinifiedHeader">
    <fts-grouporder-button  
      [label]="'component.header.mobile_acc_menu.go' | translate"
      [buttonStyle]="'LINK'"
      [isRemovable]="true"
      [isInSidebar]="true"
      class="btn-block text-left">
    </fts-grouporder-button>
  </ng-container>

  <ng-container *ngIf='account?.isInstantiated'>
    <ng-container *ngIf="!minifiedHeader">
      <button [routerLink]="['/account', 'profile']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
        {{'component.header.mobile_acc_menu.profile' | translate}}
      </button>
      <button *ngIf='store.hasLoyalty && !!loyaltyPlan.id && !store.hasThirdPartyLoyalty' [routerLink]="['/loyalty']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
        {{loyaltyPlan.loyaltyPlanName}}
      </button>
      <button [routerLink]="['/account', 'my-orders']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
        {{'component.header.mobile_acc_menu.order_history' | translate}}
      </button>
      <button [routerLink]="['/account', 'addresses']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
        {{'component.header.mobile_acc_menu.address_book' | translate}}
      </button>
      <button [routerLink]="['/account', 'wallet']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
        {{'component.header.mobile_acc_menu.wallet' | translate}}
      </button>
      <button *ngIf='!store.hasThirdPartyLoyalty' [routerLink]="['/account', 'coupons']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
        {{'component.header.mobile_acc_menu.coupons' | translate}}
      </button>
      <button *ngIf="account?.hasDonations" [routerLink]="['/account', 'donations']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
        {{'component.header.mobile_acc_menu.donations' | translate}}
      </button>
    </ng-container>

    <ng-container *ngIf="minifiedHeader">
      <div>
        <button
          class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
          [routerLink]="['/account', 'profile']">
          <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsProfile"></fa-icon>
          <span class="link-label text-left">{{'component.header.mobile_acc_menu.profile' | translate}}</span>
        </button>
        <button
          *ngIf="store.hasLoyalty && !!loyaltyPlan.id && !store.hasThirdPartyLoyalty"
          class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
          [routerLink]="['/loyalty']">
          <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsRewards"></fa-icon>
          <span class="link-label text-left">{{loyaltyPlan.loyaltyPlanName}}</span>
        </button>
        <button
          class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
          [routerLink]="['/account', 'my-orders']">
          <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsOrders"></fa-icon>
          <span class="link-label text-left">{{'component.header.mobile_acc_menu.my_orders' | translate}}</span>
        </button>
        <button
          class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
          [routerLink]="['/account', 'addresses']">
          <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsAddressBook"></fa-icon>
          <span class="link-label text-left">{{'component.header.mobile_acc_menu.address_book' | translate}}</span>
        </button>
        <button
          class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
          [routerLink]="['/account', 'wallet']">
          <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsWallet"></fa-icon>
          <span class="link-label text-left">{{'component.header.mobile_acc_menu.wallet' | translate}}</span>
        </button>
        <button
          *ngIf="!store.hasThirdPartyLoyalty"
          class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
          [routerLink]="['/account', 'coupons']">
          <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsCoupons"></fa-icon>
          <span class="link-label text-left">{{'component.header.mobile_acc_menu.coupons' | translate}}</span>
        </button>
        <button
          *ngIf="account?.hasDonations"
          class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
          [routerLink]="['/account', 'donations']">
          <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsDonations"></fa-icon>
          <span class="link-label text-left">{{'component.header.mobile_acc_menu.donations' | translate}}</span>
        </button>
      </div>

      <hr aria-hidden="true">
    </ng-container>

    <button (click)="handleLogout()"
            class='btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase'
            [ngClass]="{'margin-top-30': (breakpointService.isTabletDown$ | async) && !minifiedHeader}">
      {{'component.header.mobile_acc_menu.sign_out' | translate}}
    </button>
  </ng-container>

  <ng-container *ngIf="!account?.isInstantiated && minifiedHeader">
    <hr aria-hidden="true" *ngIf="featuredContents?.length">

    <div>
      <button
        class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
        [routerLink]="['/account', 'my-orders']">
        <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsOrders"></fa-icon>
        <span class="link-label text-left">{{'component.header.mobile_acc_menu.my_orders' | translate}}</span>
      </button>
      <button
        *ngIf="store.hasLoyalty && !!loyaltyPlan.id && !store.hasThirdPartyLoyalty"
        class="btn btn-link btn-undecorated font-secondary fw-500 no-padding no-border text-uppercase link-wrapper"
        [routerLink]="['/loyalty']">
        <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true" [icon]="faFtsRewards"></fa-icon>
        <span class="link-label text-left">{{loyaltyPlan.loyaltyPlanName}}</span>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf='!account?.isInstantiated && !minifiedHeader'>
    <button (click)="handleLogin()" [ngClass]="{'hidden-xs': isInIntro}" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
      {{'component.header.mobile_acc_menu.sign_in' | translate}}
    </button>
    <button [routerLink]="['/register']" [ngClass]="{'hidden-xs': (isInIntro && !(menuWrapperService.orderIdCookieExists$ | async))}"
            class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
      {{'component.header.mobile_acc_menu.sign_up' | translate}}
    </button>
    <button [routerLink]="['/account', 'my-orders']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
      {{'component.header.mobile_acc_menu.my_orders' | translate}}
    </button>
    <button *ngIf='store.hasLoyalty && !!loyaltyPlan.id && !store.hasThirdPartyLoyalty' [routerLink]="['/loyalty']" class='btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase'>
      {{loyaltyPlan.loyaltyPlanName}}
    </button>
  </ng-container>
</div>

<ng-template #groupOrderButtonInMinifiedHeader>
  <fts-grouporder-button
    [label]="'component.header.mobile_acc_menu.go' | translate"
    [buttonStyle]="'LINK'"
    [isRemovable]="true"
    [isInSidebar]="true"
    [isInMinifiedAccountSidebar]="true"
    class="link-wrapper"
  >
  </fts-grouporder-button>

</ng-template>