<!-- Show the menu button for mobile and table in all pages -->
<div class="d-flex" *ngIf="(panel?.name && showMenuButton && !(viewportService.viewport$ | async)?.isDesktop)"
     [ngClass]="{'d-none': hideMenuButtonUpfront, 'align-items-baseline': !minifiedHeader}">
  <button class="btn btn-link btn-undecorated btn-header btn-menu-link text-uppercase font-secondary fw-600 text-nowrap pe-0"
          [ngClass]="{'is-active': visibleMenu === menus.tabs, 'rendered-in-minified': minifiedHeader}"
          (click)='toggleMobileMenu(menus.tabs)'
          [attr.aria-label]="(visibleMenu === menus.tabs ? 'component.header.menu_link.menu_expanded' :
          'component.header.menu_link.menu_collapsed') | translate">
    <fa-icon [fixedWidth]="true" [icon]="visibleMenu === menus.tabs ? faTimes : faChevronRight"></fa-icon>
    {{'component.header.menu_link.menu' | translate}}
  </button>
</div>
<button *ngIf='!hideMenuLink' (click)="buttonClicked.next()" class="btn btn-link btn-header text-uppercase account-link font-secondary fw-600"
  [ngClass]="{'fw-400': lightFontWeight, 'rendered-in-minified': minifiedHeader}">
  {{'component.header.menu_link.menu' | translate}}
</button>
