import { ValidatedResource } from './validated-resource';

export class GiftCard extends ValidatedResource {
  private _number = '';
  private _pin = '';
  readonly balance: number;
  readonly status: string;
  readonly dateRegistered: string;
  friendlyName: string;
  readonly isMyCard: boolean;
  addToAccount: boolean;
  readonly isActivated: boolean;

  constructor() {
    super();
    this.number = '';
    this.pin = '';
  }

  set number(val) {
    if (val) {
      this._number = val.replace(/_/g, '').replace(/-/g, '');
    } else {
      this._number = val;
    }
  }

  get number() {
    return this._number;
  }

  set pin(val) {
    if (val) {
      this._pin = val.replace(/_/g, '');
    } else {
      this._pin = val;
    }
  }

  get pin() {
    return this._pin;
  }

  serialize(): any {
    return {
      number: this._number,
      pin: this._pin,
      friendlyName: this.friendlyName,
      addToAccount: this.addToAccount,
    };
  }
}
