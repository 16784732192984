import { DeserializationUtils } from '../../utils/deserialization-utils';
import { CustomerInfo } from '../account/customer-info.model';
import { BaseResource } from '../core/base-resource';
import { Questionnaire } from './questionnaire.model';
import { RateableItem } from './rateable-item.model';

export class Survey extends BaseResource {
  reward: string;
  orderId: string;
  rateableItems: RateableItem[];
  rateableItemsQuestionnaires: Questionnaire[];
  orderRatingLabel: string;
  itemRatingLabels: string[];
  enableOrderRating: boolean;
  customer: CustomerInfo;
  questionnaire: Questionnaire;

  initQuestionnaireResponses(referencedItem?: RateableItem): void {
    this.questionnaire.initQuestionnaireResponses(this.rateableItems, referencedItem);
    this.questionnaire.customerName = this.customer.name;
    this.questionnaire.email = this.customer.email;
    this.questionnaire.orderRatingLabel = this.orderRatingLabel;
    this.questionnaire.itemRatingLabels = this.itemRatingLabels;
    this.questionnaire.rateableItems = this.rateableItems.map((item: RateableItem) => {
      const questionnaire = this.rateableItemsQuestionnaires.find((q: Questionnaire) => q.name === item.questionnaireName);

      if (!questionnaire) {
        return item;
      }

      const questionnaireJSON = JSON.parse(JSON.stringify(questionnaire));
      const questionnaireCopy = new Questionnaire().deserialize(questionnaireJSON);
      questionnaireCopy.initQuestionnaireResponses(null, item);

      questionnaireCopy.categories = questionnaireCopy.categories.map(category => {
        const questions = category.questions.map(question => {
          const dependencies = question.dependencies.map(dependency => {
            return Object.assign(dependency, { id: `${item.key}_${dependency.id}` });
          });

          return Object.assign(question, {
            id: `${item.key}_${question.id}`,
            dependencies,
          });
        });

        return Object.assign(category, { questions });
      });

      item.questionnaire = questionnaireCopy;
      return item;
    });
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      customer: DeserializationUtils.deserializeChild(input, 'customer', CustomerInfo),
      questionnaire: DeserializationUtils.deserializeChild(input, 'questionnaire', Questionnaire),
      rateableItems: DeserializationUtils.deserializeChildren(input, 'rateableItems', RateableItem),
      rateableItemsQuestionnaires: DeserializationUtils.deserializeChildren(input, 'rateableItemsQuestionnaires', Questionnaire),
    });
  }

  serialize(): any {
    return {
      reward: this.reward,
      orderId: this.orderId,
      rateableItems: this.rateableItems,
      orderRatingLabel: this.orderRatingLabel,
      itemRatingLabels: this.itemRatingLabels,
      enableOrderRating: this.enableOrderRating,
      customer: this.customer ? this.customer.serialize() : undefined,
      questionnaire: this.questionnaire ? this.questionnaire.serialize() : undefined,
    };
  }
}
