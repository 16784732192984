import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Account, LoyaltyPlan, StoreConfig, FeaturedContent } from 'ngx-web-api';
import { BreakpointService } from '../../services/breakpoint.service';
import { Observable } from 'rxjs';
import { OrderingUtilsService } from 'app/core/services/ordering-utils.service';

@Component({
  selector: 'fts-plain-account-menu',
  templateUrl: './plain-account-menu.component.html',
  styleUrls: ['./plain-account-menu.component.scss'],
})
export class PlainAccountMenuComponent {
  @Input()
  account: Account;
  @Input()
  store: StoreConfig;
  @Input()
  compactHeader: boolean;
  @Input()
  compactAccountMenu: boolean;
  @Input()
  loyaltyPlan: LoyaltyPlan;
  @Input()
  isInHome: boolean;
  @Input()
  isInIntro = false;
  @Input()
  featuredContents: FeaturedContent[];

  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();

  isSelfOrder$: Observable<boolean>;

  constructor(public breakPointService: BreakpointService, private orderingUtilsService: OrderingUtilsService) {
    this.isSelfOrder$ = this.orderingUtilsService.isSelfOrder$;
  }

  handleLogin() {
    this.login.emit();
  }

  handleLogout() {
    this.logout.emit();
  }
}
