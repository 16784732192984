import { BaseResource } from './base-resource';

export class WebSocketConfig extends BaseResource {
  url: string;
  retryInterval = 5000;
  maxRetryDuration = 90000; // must be longer than handling of session-expiration and nightly server-restart
  host?: string;
  heartbeatInterval = 25000; // heartbeats are sent on this interval to server in order to keep connection alive
  hasHeartbeats = true;

  constructor(url: string, props: any = {}) {
    super();
    this.url = url;
    Object.assign(this, props);
  }
}
