import { Injectable, NgZone } from '@angular/core';
import { InitParamsStorageService } from '../../core/services/init-params-storage.service';
import { Location } from '@angular/common';
import { RedirectKeys } from 'ngx-web-api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReloaderService {
  private reloadingPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public reloadingPage$: Observable<boolean> = this.reloadingPage.asObservable();

  constructor(private zone: NgZone, private location: Location, private initParamsStorageService: InitParamsStorageService) {}

  public reload(path = '') {
    this.setReloadingStatus(true);
    this.location.replaceState(path, this.getQueryParams(this.initParamsStorageService.retainedParams()));
    this.zone.runOutsideAngular(() => window.location.reload());
  }

  public pushStateWithoutReload(path = '', urlParams?: object) {
    const params = this.getQueryParams({ ...this.initParamsStorageService.retainedParams(), ...urlParams });
    this.location.replaceState(path, params);
  }

  public redirect(url: string, urlParams?: object) {
    this.setReloadingStatus(true);
    const params = this.mapToAcceptedQueryParams({ ...this.initParamsStorageService.retainedParams(), ...urlParams });
    const serialized = this.getQueryParams(params);
    window.location.assign(`${url}${!!serialized ? '?' : ''}${serialized}`);
  }

  public setReloadingStatus(reloading: boolean) {
    this.reloadingPage.next(reloading);
  }

  private getQueryParams(params: object): string {
    return Array.from(Object.entries(params))
      .filter(([_, value]) => !!value)
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join(`&`);
  }

  private mapToAcceptedQueryParams(params: object): object {
    const qParams = {};
    Object.keys(params).forEach(key => {
      // lat & lng should not be passed when redirecting to avoid issue 30886
      if (!!RedirectKeys[key] && key !== RedirectKeys.lat && key !== RedirectKeys.lng) {
        qParams[key] = params[key];
      }
    });
    return qParams;
  }
}
