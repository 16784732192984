import { NotificationModalType } from './../../../../../domain/notification-modal-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalContext } from './../../../../../domain/confirmation-modal-context';
import { ModalService } from './../../../../services/modal.service';
import { of } from 'rxjs';
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MerchantStore, OrderService, Order } from 'ngx-web-api';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { JoinPipe } from 'app/shared/pipes/join.pipe';
import { faFtsLocation } from '../../../../../shared/custom-fa-icons/faFtsLocation';
import { Animations } from 'app/animations';
import { map, take, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'fts-merchant-store-selector',
  templateUrl: 'merchant-store-selector.component.html',
  styleUrls: ['merchant-store-selector.component.scss'],
  animations: [Animations.fadeInOut],
})
export class MerchantStoreSelectorComponent implements OnChanges {
  @Input()
  selectedStoreState: string;
  @Input()
  storeStates: string[];
  @Input()
  selectedMerchantStore: MerchantStore;
  @Input()
  merchantStores: Array<MerchantStore>;
  @Input()
  loadingMerchantStores: boolean;
  @Input()
  currentStoreAddress: string;
  @Input()
  radioStyle: boolean;
  @Input()
  closing: boolean;
  @Input()
  containerHeight: number;
  @Input()
  isTabletDown;

  @Output()
  storeStateChange = new EventEmitter<string>();
  @Output()
  merchantStoreChange = new EventEmitter<MerchantStore>();
  @Output()
  changeStore = new EventEmitter<void>();

  faCheck = faCheck;
  citiesGroup: string[] = [];
  joinPipe = new JoinPipe();
  faFtsLocation = faFtsLocation;
  groupedStores: Array<Partial<MerchantStore>> = [];

  constructor(private orderService: OrderService, private modalService: ModalService, private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['merchantStores']) {
      if (!!this.merchantStores && this.merchantStores.length >= 1) {
        this.groupAndSortStores();
      } else {
        this.groupedStores = Array.from([]);
      }
    }
  }

  onStoreStateChange(storeState: string) {
    this.storeStateChange.emit(storeState);
  }

  onMerchantStoreChange(merchantStore: MerchantStore) {
    this.merchantStoreChange.emit(merchantStore);
  }

  onChangeStore() {
    const context = new ConfirmationModalContext();
    context.title = this.translateService.instant('component.header.store_info.modal_change_store.title');
    context.mainContent = this.translateService.instant('component.header.store_info.modal_change_store.message');
    context.submitBtnTitle = this.translateService.instant('component.header.store_info.modal_change_store.continue');
    context.dismissBtnTitle = this.translateService.instant('component.header.store_info.modal_change_store.close');
    context.type = NotificationModalType.warning;
    context.submitObservable = of(true);
    this.orderService.order$
      .pipe(
        take(1),
        map((order: Order) => order.isInitialized),
        mergeMap(isInitialized => {
          if (isInitialized) {
            return this.modalService.openConfirmationModal(context);
          }
          return of(true);
        })
      )
      .subscribe(result => {
        if (result) {
          this.changeStore.emit();
        }
      });
  }

  /**
   * Groups the merchant stores by a combination of `City, State`
   * and sorts the stores based on the following rules:
   * 1. Current store comes first
   * 2. Stores that are in the same city as the current store's city follow
   * 3. Stores that are on the same state as the current store's state are listed alphabetically by `State, City`
   * 4. Stores that are on different state are listed alphabetically by `State, City`
   * The resulting Array is a combination of {_group: string} and MerchantStore objects.
   * This is required for virtual-scrolling as it cannot be applied to nested iterables.
   * Example of sorted Array of grouped stores :
   ** [
   **   {_group: 'Toledo, OH'},
   **   { MerchantStore (current) },
   **   { MerchantStore (store in the current store's city) },
   **   {_group: 'Cincinnati, OH'},
   **   { MerchantStore (store in the current store's state) },
   **   {_group: 'Los Angeles, CA'},
   **   { MerchantStore (on Los Angeles - CA)},
   **   { MerchantStore (on Los Angeles - CA)},
   ** ]
   */
  private groupAndSortStores() {
    if (this.merchantStores && this.merchantStores.length < 1) {
      this.groupedStores = [];
      return;
    }

    if (this.merchantStores.length === 1) {
      this.groupedStores = [
        { _group: this.joinPipe.transform([this.merchantStores[0].city, this.merchantStores[0].state], ', ') },
        ...this.merchantStores,
      ];
      return;
    }

    let isInSearchMode = false;
    if (this.merchantStores.some(store => !!store.distance)) {
      isInSearchMode = true;
    }

    let currentStore = undefined;
    let inCurrentCity = [];
    let withCurrentState = [];
    let withoutCurrentState = [];
    if (!isInSearchMode) {
      currentStore = this.merchantStores.find(ms => ms.isCurrentStore);
      inCurrentCity = this.merchantStores.filter(
        ms => !ms.isCurrentStore && ms.state === currentStore.state && ms.city === currentStore.city
      );
      withCurrentState = !!currentStore
        ? this.merchantStores.filter(ms => ms.state === currentStore.state && ms.city !== currentStore.city)
        : [];
      withCurrentState = [
        currentStore,
        ...inCurrentCity,
        ...this.sortByStateAndCity(withCurrentState).sort((a, b) => (a.isCurrentStore ? -1 : 1)),
      ];
      withoutCurrentState = !!currentStore
        ? this.merchantStores.filter(ms => !ms.isCurrentStore && ms.state !== currentStore.state)
        : this.merchantStores;

      withoutCurrentState = this.sortByStateAndCity(withoutCurrentState);
    }

    const groupedArray = isInSearchMode ? this.merchantStores : [...withCurrentState, ...withoutCurrentState];

    this.groupedStores = groupedArray.reduce((a, b, i, []) => {
      const aGroup = !Array.isArray(a)
        ? this.joinPipe.transform([a.city, a.state], ', ')
        : this.joinPipe.transform([a[a.length - 1].city, a[a.length - 1].state], ', ');
      const bGroup = this.joinPipe.transform([b.city, b.state], ', ');

      if (!Array.isArray(a)) {
        return aGroup !== bGroup ? [{ _group: aGroup }, a, { _group: bGroup }, b] : [{ _group: aGroup }, a, b];
      } else {
        return aGroup !== bGroup ? [...a, { _group: bGroup }, b] : [...a, b];
      }
    });
  }

  private sortByStateAndCity(stores: Array<MerchantStore>) {
    return stores.sort((a, b) =>
      this.joinPipe.transform([a.state, a.city], ', ') < this.joinPipe.transform([b.state, b.city], ', ') ? -1 : 1
    );
  }
}
