import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap, tap, take, catchError } from 'rxjs/operators';
import { IngredientChoice } from '../../models/menu/ingredient-choice.model';
import { OrderableItem } from '../../models/menu/orderable-item.model';
import { ChoiceValidationResult } from '../../models/ordering/choice-validation-result.model';
import { OrderedChoice } from '../../models/ordering/ordered-choice.model';
import { OrderedItem } from '../../models/ordering/ordered-item.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { Utils } from '../../utils/utils';
import { AccountCouponsService } from '../account/account-coupons.service';
import { AuditService } from '../core/audit.service';
import { OrderableItemService } from '../menu/orderable-item.service';
import { OrderService } from './order.service';
import { OrderedChoiceService } from './ordered-choice.service';
import { OrderedIngredientService } from './ordered-ingredient.service';
import { HypermediaService } from '../core/hypermedia.service';
import { OrderedItemGroup } from '../../models/ordering/ordered-item-group-model';
import { StoreConfig } from '../../models/core/store-config.model';
import { ConfigService } from '../core/config.service';
import { OrderedIngredient } from '../../models/ordering/ordered-ingredient.model';
import { ItemWithIngredientPrices } from '../../models/ordering/item-with-ingredient-prices.model';
import { OrderableIngredient } from 'ngx-web-api/lib/models/menu/orderable-ingredient.model';

@Injectable({ providedIn: 'root' })
export class OrderedItemService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/order/items';
  private justWhenNothing: boolean;
  private enumerateEverything: boolean;
  private everythingButThreshold: number;

  private deletedItem: Subject<OrderedItem> = new Subject();
  public deletedItem$: Observable<OrderedItem> = this.deletedItem.asObservable();

  private addedItem: Subject<OrderedItem> = new Subject();
  public addedItem$: Observable<OrderedItem> = this.addedItem.asObservable();

  private updatedItem: Subject<OrderedItem> = new Subject();
  public updatedItem$: Observable<OrderedItem> = this.updatedItem.asObservable();

  private itemPrice: Subject<number> = new Subject();

  constructor(
    private http: HttpClient,
    private orderService: OrderService,
    private auditService: AuditService,
    private orderedChoiceService: OrderedChoiceService,
    private orderableItemService: OrderableItemService,
    private orderedIngredientService: OrderedIngredientService,
    private hypermediaService: HypermediaService,
    private accountCouponsService: AccountCouponsService,
    private configService: ConfigService
  ) {
    this.configService.storeConfig$.subscribe((c: StoreConfig) => {
      this.justWhenNothing = c.justWhenNothing;
      this.enumerateEverything = c.enumerateEverything;
      this.everythingButThreshold = c.everythingButThreshold;
    });
  }

  public createOrderedItem(orderable: OrderableItem, size: string, quantity = 1): OrderedItem {
    const item: OrderedItem = DeserializationUtils.deserializeObj(
      {
        category: orderable.category,
        item: orderable.name,
        size: size,
        quantity,
        labelSymbol: orderable.labelSymbol,
        includeEverything: orderable.includeEverything,
      },
      OrderedItem
    );
    const allTheIngridients: OrderableIngredient[] = [];
    orderable.choices.forEach(c => c.ingredients.forEach(i => allTheIngridients.push(i)));
    item.choices = orderable.choices
      .filter(ch => !ch.dependsOn || this.orderableItemService.areDependenciesMet(orderable, ch))
      .map(choice => {
        const orderedChoice: OrderedChoice = this.orderedChoiceService.createOrderedChoice(choice);
        orderedChoice.ingredients = choice.ingredients
          .filter(
            i =>
              (((!choice.dependsOn ||
                allTheIngridients.find(i => i.name == choice.dependsOn)?.sizePrices.findIndex(s => s.size === item.size) !== -1) &&
                i.isDefault) ||
                (item.includeEverything && i.isEverything)) &&
              !!i.getSizePrice(size)
          )
          .map(i => this.orderedIngredientService.createOrderedIngredientFromOrderable(i));
        return orderedChoice;
      });
    return item;
  }

  public isValid(orderedItem: OrderedItem, orderableItem: OrderableItem): boolean {
    return (
      orderedItem.isValid() &&
      (orderedItem.choices || [])
        .filter(c => this.hasDependencyMet(orderedItem, orderableItem.getChoice(c.name) || new IngredientChoice()))
        .map(c => c.isValid())
        .reduce((a, b) => a && b, true)
    );
  }

  public hasDependencyMet(orderedItem: OrderedItem, choice: IngredientChoice): boolean {
    return !choice.dependsOn || this.getAllIngredients(orderedItem).some(i => i.ingredient === choice.dependsOn);
  }

  public getFilteredIngredientChoices(orderableItem: OrderableItem, orderedItem: OrderedItem): IngredientChoice[] {
    return orderableItem.choices.filter(
      c =>
        c.ingredients &&
        c.ingredients.length > 0 &&
        c.ingredients.some(i => !!i.getSizePrice(orderedItem.size)) &&
        this.hasDependencyMet(orderedItem, c)
    );
  }

  public addItemToOrder(item: OrderedItem): Observable<OrderedItem> {
    const isSisterUpsell = item.isSister;
    const itemUpsellType = item.upsellType;
    return this.http.post(this.BASE_PATH, item.serialize()).pipe(
      map(json => DeserializationUtils.deserializeObj(json, OrderedItem)),
      tap((i: OrderedItem) => this.addedItem.next(i)),
      tap((i: OrderedItem) => {
        i.isSister = isSisterUpsell;
        i.upsellType = itemUpsellType;
      }),
      tap((orderedItem: OrderedItem) => this.auditItemAddition(orderedItem)),
      mergeMap(oItem => this.orderService.fetchOrder().pipe(map(() => oItem))),
      tap(() => this.accountCouponsService.reloadCoupons())
    );
  }

  public removeItemFromOrder(item: OrderedItem, refetchOrder = true): Observable<HttpResponse<any>> {
    const selfLink = this.hypermediaService.findLink(item.links, 'self');
    let path = `${this.BASE_PATH}/${item.itemId}`;
    if (!!selfLink) {
      path = selfLink.href;
    }

    return this.http.delete(path, { observe: 'response' }).pipe(
      tap(() => this.auditItemRemoval(item)),
      tap(() => this.deletedItem.next(item)),
      mergeMap(response => (refetchOrder ? this.orderService.fetchOrder() : of(null)).pipe(map(() => response))),
      tap(() => this.accountCouponsService.reloadCoupons())
    );
  }

  public updateItem(item: OrderedItem): Observable<OrderedItem> {
    return this.http.put(`${this.BASE_PATH}/${item.itemId}`, item.serialize()).pipe(
      map(json => DeserializationUtils.deserializeObj(json, OrderedItem)),
      tap((orderedItem: OrderedItem) => this.updatedItem.next(orderedItem)),
      tap((orderedItem: OrderedItem) => this.auditItemUpdate(orderedItem)),
      catchError(error => {
        if (error.status === 404) {
          return of(new OrderedItem().deserialize(item));
        }
        throw error;
      }),
      mergeMap(oItem => this.orderService.fetchOrder().pipe(map(() => oItem))),
      tap(() => this.accountCouponsService.reloadCoupons())
    );
  }

  public updateItems(items: OrderedItemGroup): Observable<OrderedItemGroup> {
    return this.http.put(`${this.BASE_PATH}/batchUpdate`, items.serialize()).pipe(
      map(json => DeserializationUtils.deserializeObj(json, OrderedItemGroup)),
      tap((orderedItems: OrderedItemGroup) => orderedItems.orderedItems.forEach((item: OrderedItem) => this.auditItemUpdate(item))),
      mergeMap(oItems => this.orderService.fetchOrder().pipe(map(() => oItems))),
      tap(() => this.accountCouponsService.reloadCoupons())
    );
  }

  public reorderItem(itemId: string, force?: boolean): Observable<OrderedItem> {
    return this.http.post(`/ws/integrated/v1/ordering/order/reorderItemById`, { key: itemId, force }).pipe(
      map(json => DeserializationUtils.deserializeObj(json, OrderedItem)),
      tap((i: OrderedItem) => this.addedItem.next(i)),
      tap((orderedItem: OrderedItem) => this.auditItemAddition(orderedItem)),
      mergeMap(oItem => this.orderService.fetchOrder().pipe(map(() => oItem))),
      tap(() => this.accountCouponsService.reloadCoupons())
    );
  }

  public compareOrderedItems(firstItem: OrderedItem, secondItem: OrderedItem): boolean {
    if (!firstItem || !secondItem) {
      return false;
    } else {
      return Utils.deepEqual(this.getComparableOrderedItem(firstItem), this.getComparableOrderedItem(secondItem));
    }
  }

  /**
   * Returns all the ingredients that have been applied on the ordered item (on whole or halfs).
   * @param item the ordered item
   * @returns all ingredients of the ordered item
   */
  public getAllIngredients(item: OrderedItem): OrderedIngredient[] {
    return item.choices.map(choice => choice.ingredients || []).reduce((a, b) => a.concat(b), []);
  }

  /**
   * Extracts the ingredients from the ordered item that are not marked as default.
   * @param item the ordered item
   * @returns the ingredients that are not default
   */
  public getNonDefaultIngredients(item: OrderedItem): OrderedIngredient[] {
    return this.getAllIngredients(item).filter(i => !i.isDefault);
  }

  /**
   * Extracts the ingredients from the ordered item that are not marked as default or everything.
   * @param item the ordered item
   * @returns the ingredients that are not default or everything
   */
  public getAllButDefaultAndEverythingIngredients(item: OrderedItem): OrderedIngredient[] {
    return this.getAllIngredients(item).filter(i => !(i.isDefault || i.isEverything));
  }

  /**
   * Extracts the ingredients from the ordered item that are default and have been altered.
   * @param item the ordered item
   * @returns the default ingredients that have been altered
   */
  public getAlteredDefaultIngredients(item: OrderedItem): OrderedIngredient[] {
    return this.getAllIngredients(item)
      .filter(i => i.isDefault)
      .filter(i => i.showDefault || i.isRightHalf || i.isLeftHalf || (i.qualifiers || []).length > 0);
  }

  /**
   * Extracts the default ingredients from the ordered item that have been removed.
   * @param item the ordered item
   * @returns the default ingredients that have been removed
   */
  public getRemovedDefaultIngredients(item: OrderedItem): OrderedIngredient[] {
    return item.choices
      .map(choice => choice.removedDefaults || [])
      .reduce((a, b) => a.concat(b), [])
      .filter(i => i.showNoDefault);
  }

  /**
   * Extracts the ingredients from the ordered item that are marked as everything (and they are non-default) and have been altered.
   * @param item the ordered item
   * @returns the everything ingredients, excluding defaults, that have been altered
   */
  public getAlteredEverythingIngredients(item: OrderedItem): OrderedIngredient[] {
    return this.getEverythingButNoDefaultIngredients(item).filter(i => i.isRightHalf || i.isLeftHalf || (i.qualifiers || []).length > 0);
  }

  /**
   * Extracts the everything ingredients from the ordered item that have been removed.
   * @param item the ordered item
   * @returns the everything ingredients that have been removed
   */
  public getRemovedEverythingIngredients(item: OrderedItem): OrderedIngredient[] {
    return item.choices.map(choice => choice.removedEverythings || []).reduce((a, b) => a.concat(b), []);
  }

  /**
   * Checks if the ordered item has all of its everything ingredients removed or altered.
   * Note: The enumerateEverything store pref should be set to false, otherwise the label Nothing will not be displayed.
   * @param item the ordered item
   * @returns true if Nothing label must be displayed
   */
  public showNothingLabel(item: OrderedItem): boolean {
    return (
      item.includeEverything &&
      !this.enumerateEverything &&
      this.getEverythingButNoDefaultIngredients(item).length === 0 &&
      this.getJustIngredients(item).length === 0
    );
  }

  /**
   * Checks if the ordered item has all of its everything ingredients applied.
   * Note: The enumerateEverything store pref should be set to false, otherwise the labal Everything will not be displayed.
   * @param item the ordered item
   * @returns true if Everything label must be displayed
   */
  public showEverythingLabel(item: OrderedItem): boolean {
    return (
      !item.includeEverything &&
      !this.enumerateEverything &&
      this.getRemovedEverythingIngredients(item).length === 0 &&
      this.getEverythingButNoDefaultIngredients(item).length > 0
    );
  }

  /**
   * Extracts the JUST ingredients from the ordered item only when the related store prefs:
   * - enumerateEverything
   * - justWhenNothing
   * - everythingButThreshold
   * allow the JUST label to be displayed.
   * @param item the ordered item
   * @returns the ingredients that must be placed under the JUST label
   */
  public getJustIngredients(item: OrderedItem): OrderedIngredient[] {
    let ingredients = [];

    if (
      !this.enumerateEverything &&
      this.getRemovedEverythingIngredients(item).length > this.everythingButThreshold &&
      ((!item.includeEverything && this.justWhenNothing) || item.includeEverything)
    ) {
      ingredients = this.excludeHalfIngredients([...this.getNonDefaultIngredients(item), ...this.getAlteredDefaultIngredients(item)]);
    }

    return ingredients;
  }

  /**
   * Extracts the EVERYTHING BUT ingredients from the ordered item only when the related store prefs:
   * - enumerateEverything
   * - everythingButThreshold
   * allow the EVERYTHING BUT label to be displayed.
   * @param item the ordered item
   * @returns the ingredients that must be placed under the EVERYTHING BUT label
   */
  public getEverythingButIngredients(item: OrderedItem): OrderedIngredient[] {
    let ingredients = [];

    if (!this.enumerateEverything && this.getRemovedEverythingIngredients(item).length <= this.everythingButThreshold) {
      ingredients = this.getRemovedEverythingIngredients(item);
    }

    return ingredients;
  }

  /**
   * Extracts the ingredients from the ordered item that will be applied on both sides of the item,
   * provided that there are no ingredients labeled as JUST.
   * @param item the ordered item
   * @returns the ingredients that will be applied to the whole item
   */
  public getExtraWholeIngredients(item: OrderedItem): OrderedIngredient[] {
    let ingredients = [];

    if (this.getJustIngredients(item).length === 0) {
      ingredients = this.excludeHalfIngredients(this.getExtraIngredients(item));
    }

    return ingredients;
  }

  /**
   * Extracts the ingredients from the ordered item that will be applied on left side of the item.
   * @param item the ordered item
   * @returns the ingredients that will be applied on the left item
   */
  public getExtraLeftHalfIngredients(item: OrderedItem): OrderedIngredient[] {
    return this.getExtraIngredients(item).filter(ingredient => ingredient.isLeftHalf);
  }

  /**
   * Extracts the ingredients from the ordered item that will be applied on right side of the item.
   * @param item the ordered item
   * @returns the ingredients that will be applied on the right item
   */
  public getExtraRightHalfIngredients(item: OrderedItem): OrderedIngredient[] {
    return this.getExtraIngredients(item).filter(ingredient => ingredient.isRightHalf);
  }

  /**
   * Validates an ordered item against its corresponding orderable item.
   * <b>Important: This function will mutate the contents of the provided ordered item</b>
   * @param ordered The ordered item to validate
   * @param orderable The orderable item that provides the definition of the item
   * @param useShortMessage whether a shorter version of the validation message should be used
   */
  public validateItem(ordered: OrderedItem, orderable: OrderableItem, useShortMessage = false, isHalfandHalf = false): void {
    ordered.choices.forEach(choice => {
      const orderableChoice = orderable.getChoice(choice.name);
      if (orderableChoice) {
        const validationResult: ChoiceValidationResult = this.orderedChoiceService.validateChoice(
          choice,
          orderableChoice,
          ordered.size,
          isHalfandHalf
        );
        choice.warnings = validationResult.valid ? [] : [useShortMessage ? validationResult.shortMessage : validationResult.longMessage];
      }
    });
  }

  /**
   * Make a request to get the passed item's price from the API. Pricing is performed against the current session order.
   * Note that the item is being priced as an individual, without any special offers etc.
   * @param item the item for which we want to determine its price
   * @returns the item's price in dollars
   */
  public getPrice(item: OrderedItem): Observable<number> {
    return this.http.post(`${this.BASE_PATH}/price`, item.serialize()).pipe(
      map(json => json['price']),
      tap(price => this.itemPrice.next(price))
    );
  }

  public getPriceWithIngredients(item: OrderedItem): Observable<ItemWithIngredientPrices> {
    return this.http
      .post<ItemWithIngredientPrices>(`${this.BASE_PATH}/priceWithIngredients`, item.serialize())
      .pipe(tap((itemWithIngredientsPrices: ItemWithIngredientPrices) => this.itemPrice.next(itemWithIngredientsPrices.itemPrice)));
  }

  public listenForPriceChange(): Observable<number> {
    return this.itemPrice.asObservable().pipe(take(1));
  }

  public setAddedItem(orderedItem: OrderedItem) {
    this.addedItem.next(orderedItem);
  }

  private getEverythingButNoDefaultIngredients(item: OrderedItem): OrderedIngredient[] {
    return this.getAllIngredients(item).filter(i => i.isEverything && !i.isDefault);
  }

  private getExtraIngredients(item: OrderedItem): OrderedIngredient[] {
    if (
      this.enumerateEverything ||
      (!this.justWhenNothing && this.getRemovedEverythingIngredients(item).length > this.everythingButThreshold)
    ) {
      return this.getNonDefaultIngredients(item).concat(this.getAlteredDefaultIngredients(item));
    } else {
      return this.getAllButDefaultAndEverythingIngredients(item).concat([
        ...this.getAlteredDefaultIngredients(item),
        ...this.getAlteredEverythingIngredients(item),
      ]);
    }
  }

  private excludeHalfIngredients(ingredients: OrderedIngredient[]): OrderedIngredient[] {
    return ingredients.filter(ingredient => !ingredient.isLeftHalf && !ingredient.isRightHalf);
  }

  private getComparableOrderedItem(orderedItem: OrderedItem): any {
    const sortedChoices = orderedItem.choices.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });

    const allIngredients = [];
    sortedChoices.forEach(choice => {
      const sortedIngredients = (choice.ingredients || []).sort((a, b) => {
        if (a.ingredient < b.ingredient) {
          return -1;
        } else {
          return 1;
        }
      });

      sortedIngredients.forEach(ing => {
        allIngredients.push({
          choiceName: choice.name,
          ingredient: ing.ingredient,
          isLeftHalf: ing.isLeftHalf,
          isRightHalf: ing.isRightHalf,
          qualifiers: ing.qualifiers || [],
        });
      });
    });

    return {
      allIngs: allIngredients,
      size: orderedItem.size,
      quantity: orderedItem.quantity,
      instructions: orderedItem.instructions,
    };
  }

  /************************************************ Auditing helpers ************************************************/

  private auditItemAddition(item: OrderedItem): void {
    return this.auditService.createAudit(() => ({
      message: `'${item.quantity} ${item.size} ${item.item}' item has been added to the order`,
      details: { itemId: item.itemId, isUpsell: item.isUpsell, upsellType: item.upsellType, isSister: item.isSister },
    }));
  }

  private auditItemUpdate(item: OrderedItem): void {
    return this.auditService.createAudit(() => ({
      message: `'${item.size} ${item.item}' item has been updated in the order`,
      details: { itemId: item.itemId },
    }));
  }

  private auditItemRemoval(item: OrderedItem): void {
    return this.auditService.createAudit(() => ({
      message: `'${item.size} ${item.item}' item has been removed from the order`,
      details: { itemId: item.itemId, specialOrdinal: item.specialOrdinal },
    }));
  }
}
