import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { OrderableItem } from './orderable-item.model';
import { OrderableSpecial } from './orderable-special.model';
import { Picture } from './picture.model';

export class FeaturedItem extends BaseResource {
  itemSize?: string;
  title?: string;
  subtitle?: string;
  image?: string;
  imageOnly: boolean;
  link: string;
  target?: string;
  pictures: Picture[];
  special?: OrderableSpecial;
  item?: OrderableItem;

  get targetAttribute() {
    return this.target && ['popup', 'new tab'].indexOf(this.target) >= 0 ? '_blank' : '_self';
  }

  get hasOrderable(): boolean {
    return !!(this.item || this.special);
  }

  get isClickable(): boolean {
    return !!(this.hasOrderable || this.link);
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      special: DeserializationUtils.deserializeChild(input, 'special', OrderableSpecial),
      item: DeserializationUtils.deserializeChild(input, 'item', OrderableItem),
    });
  }
}
