<div class="primary-bg-color position-relative" *ngIf="(theme?.shouldShowCategoriesUpfront || theme?.minifiedMenu) && isTabletDown" [ngClass]="{'minified-menu-container': isInHeader}" id="menuIndex">
  <fts-nav-link *ngIf="theme?.showSearchMenuLink"
                [index]='-1'
                [theme]="theme"
                [ngRouterLink]="'menu/search'"
                [tabName]="'component.vertical_categories_menu.search' | translate"
                [hasLeftMenuIcons]="!isInHeader || (isInHeader && theme?.hasLeftMenuIcons)"
                [showTabIcons]="showTabIcons"
                [isSearchLink]="true"
                [isUpFrontMenu]="true"
                [isInMinifiedMenu]="isInHeader"
                (onTabClick)="onTabClick()">
  </fts-nav-link>

  <fts-nav-link *ngIf="hasRecentlyOrderedItems"
                [theme]="theme"
                [ngRouterLink]="tabLinksMap[recentlyOrderedLabel]"
                [tabName]="'component.vertical_categories_menu.recently_ordered' | translate"
                [tabIcon]="theme?.recentlyOrderedItemIcon"
                [hasLeftMenuIcons]="!isInHeader || (isInHeader && theme?.hasLeftMenuIcons)"
                [showTabIcons]="showTabIcons"
                [isUpFrontMenu]="true"
                [isInMinifiedMenu]="isInHeader"
                (onTabClick)="onTabClick()">
  </fts-nav-link>

  <fts-nav-link *ngFor="let tab of panelTabs; let i = index"
                [index]="i"
                [theme]="theme"
                [ngRouterLink]="tabLinksMap[tab.name]"
                [tabName]="tab.name"
                [tabIcon]="tab.image"
                [hasLeftMenuIcons]="!isInHeader || (isInHeader && theme?.hasLeftMenuIcons)"
                [showTabIcons]="showTabIcons"
                [isUpFrontMenu]="true"
                [isInMinifiedMenu]="isInHeader"
                (onTabClick)="onTabClick()">
  </fts-nav-link>
</div>
