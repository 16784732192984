import { Pipe, PipeTransform } from '@angular/core';
import { LeftPadPipe } from './left-pad.pipe';

@Pipe({
  name: 'giftCardMask',
})
export class GiftCardMaskPipe implements PipeTransform {
  transform(number: string, length = 12): string {
    let result = new LeftPadPipe().transform(number.slice(8), '*', length);
    const matchObject = result.match(/.{1,4}/g);

    if (matchObject) {
      result = matchObject.join(' ');
    }

    return result;
  }
}
