import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Address, GenericObject, Street } from 'ngx-web-api';
import { AddressFormComponent } from '../address-form/address-form.component';

@Component({
  selector: 'fts-guest-address-chooser',
  templateUrl: './guest-address-chooser.component.html',
  styleUrls: ['./guest-address-chooser.component.scss'],
})
export class GuestAddressChooserComponent {
  @Input()
  addressValidationErrors: GenericObject<string[]>;
  @Input()
  customerAddress: Address;
  @Input()
  horizontal = false;
  @Input()
  isInIntro = false;

  @Output()
  customerAddressChange = new EventEmitter<Address>();
  @Output()
  streetChange = new EventEmitter<Street>();
  @Output()
  signInClick = new EventEmitter<void>();
  @Output()
  addressTypeChange = new EventEmitter<string>();
  @Output()
  addressChange = new EventEmitter<boolean>();
  @Output()
  addressErrors = new EventEmitter<string[]>();

  @ViewChild('addressForm', { static: true })
  addressForm: AddressFormComponent;

  onStreetChange(street: Street) {
    this.streetChange.emit(street);
  }

  onAddressTypeChange(addressType: string) {
    this.addressTypeChange.emit(addressType);
  }

  redirectToLogin(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.signInClick.emit();
  }

  onAddressChange() {
    this.addressChange.emit(this.addressForm.isFormValid);
  }

  onAddressErrors(errors: string[]) {
    this.addressErrors.emit(errors);
  }
}
