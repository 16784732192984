import { LocalStorageKeys } from './../../domain/local-storage-keys.enum';
import { BrowserStorageHandlerService } from './browser-storage-handler.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { SocialLinks, ConfigService } from 'ngx-web-api';
import { OperatingSystem } from 'app/domain/operating-system.enum';

@Injectable({
  providedIn: 'root',
})
export class SocialLinksService {
  private readonly GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=';
  private readonly APPS_APPLE_LINK = 'https://apps.apple.com/app/id';

  private socialLinks: ReplaySubject<SocialLinks> = new ReplaySubject(1);
  public socialLinks$: Observable<SocialLinks> = this.socialLinks.asObservable();

  private bannerUrl: BehaviorSubject<string> = new BehaviorSubject('');
  public bannerUrl$: Observable<string> = this.bannerUrl.asObservable();

  private showBanner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showBanner$: Observable<boolean> = this.showBanner.asObservable();

  private iOSAppUrl: BehaviorSubject<string> = new BehaviorSubject('');
  public iOSAppUrl$: Observable<string> = this.iOSAppUrl.asObservable();

  private androidAppUrl: BehaviorSubject<string> = new BehaviorSubject('');
  public androidAppUrl$: Observable<string> = this.androidAppUrl.asObservable();

  constructor(private configService: ConfigService, private browserStorageHandlerService: BrowserStorageHandlerService) {
    this.configService.fetchSocialLinks().subscribe(socialLinks => {
      this.calcBannerAndUrls(socialLinks);
      this.socialLinks.next(socialLinks);
    });
  }

  getOperatingSystem(): OperatingSystem | undefined {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      return OperatingSystem.android;
    }
    if (/iPhone/.test(userAgent)) {
      return OperatingSystem.iOS;
    }
    if (/iPad/.test(userAgent)) {
      return OperatingSystem.iPad;
    }
  }

  isSafariBrowser(): boolean {
    const userAgent = navigator.userAgent.toLocaleLowerCase();

    return (
      userAgent.indexOf('safari/') !== -1 &&
      userAgent.indexOf('version/') !== -1 &&
      !(userAgent.indexOf('opr/') !== -1 || userAgent.indexOf('opt/') !== -1) &&
      !(userAgent.indexOf('edgios/') !== -1 || userAgent.indexOf('edg/') !== -1)
    );
  }

  dismissBanner() {
    this.showBanner.next(false);
    this.browserStorageHandlerService.setLocalStorageItem(LocalStorageKeys.MOBILE_APP_BANNER_VISIBILITY, 'true');
  }

  private calcBannerAndUrls(socialLinks: SocialLinks) {
    const operatingSystem = this.getOperatingSystem();
    const iOSUrl = this.APPS_APPLE_LINK + socialLinks.iOSAppId;
    const androidUrl = this.GOOGLE_PLAY_LINK + socialLinks.androidAppId;

    if ((operatingSystem === OperatingSystem.iOS || operatingSystem === OperatingSystem.iPad) && !this.isSafariBrowser()) {
      this.showBanner.next(!!socialLinks.iOSAppId);
      this.bannerUrl.next(iOSUrl);
    }
    if (operatingSystem === OperatingSystem.android) {
      this.showBanner.next(!!socialLinks.androidAppId);
      this.bannerUrl.next(androidUrl);
    }
    this.iOSAppUrl.next(!!socialLinks.iOSAppId && iOSUrl);
    this.androidAppUrl.next(!!socialLinks.androidAppId && androidUrl);
  }
}
