import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { Upsell } from './upsell.model';

export class UpsellWrapper extends BaseResource {
  items: Upsell[];
  special: Upsell;
  triggeringSize: string;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      items: DeserializationUtils.deserializeChildren(input, 'items', Upsell),
      special: DeserializationUtils.deserializeChild(input, 'special', Upsell),
    });
  }
}
