import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionnaireAnswer } from '../../models/questionnaires/questionnaire-answer.model';
import { QuestionnaireType } from '../../models/questionnaires/questionnaire-type.enum';
import { Questionnaire } from '../../models/questionnaires/questionnaire.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';

@Injectable({ providedIn: 'root' })
export class QuestionnaireService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/questionnaire';

  constructor(private http: HttpClient) {}

  fetchQuestionnaire(type: QuestionnaireType, name?: string, zzz?: string): Observable<Questionnaire> {
    let params: HttpParams = new HttpParams().set('type', type);

    if (name) {
      params = params.set('name', name);
    }

    if (zzz) {
      params = params.set('zzz', zzz);
    }

    return this.http.get(this.BASE_PATH, { params }).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, Questionnaire)),
      map((questionnaire: Questionnaire) => {
        questionnaire.initQuestionnaireResponses();

        return questionnaire;
      })
    );
  }

  answerQuestionnaire(questionnaireAnswer: QuestionnaireAnswer): Observable<HttpResponse<any>> {
    return this.http.post(this.BASE_PATH, questionnaireAnswer.serialize(), { observe: 'response', responseType: 'text' });
  }
}
