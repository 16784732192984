import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilKeyChanged, filter, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import { FTS_CONFIG, FtsConfig } from '../../models/core/fts-config';
import { StoreConfig } from '../../models/core/store-config.model';
import { StoreStatus } from '../../models/core/store-status.model';
import { WebSocketConfig } from '../../models/core/web-socket-config.model';
import { WebSocketMessage } from '../../models/core/web-socket-message.model';
import { GroupOrderFriend } from '../../models/group-ordering/group-order-friend.model';
import { GroupOrderInvitation } from '../../models/group-ordering/group-order-invitation.model';
import { GroupOrderStatusChange } from '../../models/group-ordering/group-order-status-change.model';
import { GroupOrderStatus } from '../../models/group-ordering/group-order-status.enum';
import { GroupOrder } from '../../models/group-ordering/group-order.model';
import { InvitationDialogConfig } from '../../models/group-ordering/invitation-dialog-config.model';
import { AuditService } from '../core/audit.service';
import { ConfigService } from '../core/config.service';
import { StoreStatusService } from '../core/store-status.service';
import { WebSocketConnection } from '../core/web-socket-connection';
import { OrderPaymentTypesService } from '../ordering/order-payment-types.service';
import { OrderService } from '../ordering/order.service';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { isValid, addWeeks, differenceInMinutes } from 'date-fns';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { Utils } from '../../utils/utils';
import { GroupOrderJoin } from 'ngx-web-api/lib/models/group-ordering/group-order-join.model';
import { GroupOrderInfo } from 'ngx-web-api/lib/models/group-ordering/group-order-info.model';

@Injectable({ providedIn: 'root' })
export class GroupOrderService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/grouporder/';

  private groupOrder: BehaviorSubject<GroupOrder> = new BehaviorSubject(new GroupOrder());
  public groupOrder$: Observable<GroupOrder> = this.groupOrder.asObservable();

  private groupOrderStatusChanged: Subject<GroupOrderStatusChange> = new Subject();
  public groupOrderStatusChanged$: Observable<GroupOrderStatusChange> = this.groupOrderStatusChanged.asObservable();

  public storeStatus: StoreStatus;
  public timeToExpiration$: Observable<number> = this.timeToExpiration();

  private groupOrderWS?: WebSocketConnection;
  private dateFormat: DateFormatPipe = new DateFormatPipe();

  public triggerGroupOrderInvitationModal$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private orderPaymentTypesService: OrderPaymentTypesService,
    private orderService: OrderService,
    private http: HttpClient,
    private auditService: AuditService,
    private configService: ConfigService,
    private storeStatusService: StoreStatusService,
    @Inject(FTS_CONFIG) private config: FtsConfig
  ) {
    this.storeStatusService.storeStatus$.subscribe(storeStatus => (this.storeStatus = storeStatus));
    this.orderService.orderPlaced$.subscribe(() => this.removeGOAndNotifications());
  }

  public fetchGroupOrder(): Observable<GroupOrder> {
    return this.http.get(this.BASE_PATH).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, GroupOrder)),
      tap((g: GroupOrder) => this.groupOrder.next(g))
    );
  }

  public findFriends(): Observable<Array<GroupOrderFriend>> {
    return this.http
      .get(`${this.BASE_PATH}friends`)
      .pipe(map((gfs: Object[]) => (gfs || []).map(gf => new GroupOrderFriend().deserialize(gf))));
  }

  public addFriend(friend: GroupOrderFriend): Observable<any> {
    return this.http.post(`${this.BASE_PATH}addFriend`, friend.serialize()).pipe(tap(() => this.auditGroupOrderFriendAddition(friend)));
  }

  public deleteFriend(friend: GroupOrderFriend): Observable<any> {
    return this.http.post(`${this.BASE_PATH}deleteFriend`, friend.serialize()).pipe(tap(() => this.auditGroupOrderFriendDeletion(friend)));
  }

  public checkToInitGroupOrdering(): Observable<any> {
    return this.configService.storeConfig$.pipe(
      filter((c: StoreConfig) => !!c.name),
      mergeMap((config: StoreConfig) => {
        if (!config.allowGroupWebOrdering) {
          return of(null);
        } else {
          return this.fetchGroupOrder().pipe(mergeMap((g: GroupOrder) => (g && g.isActive ? this.initGroupOrdering() : of(null))));
        }
      })
    );
  }

  public inviteFriends(invitation: GroupOrderInvitation): Observable<GroupOrder> {
    return this.http.put(`${this.BASE_PATH}invitations`, invitation.serialize()).pipe(
      map((json: Object) => new GroupOrder().deserialize(json)),
      tap((g: GroupOrder) => this.groupOrder.next(g)),
      tap(() => this.auditGroupOrderFriendInvitation(invitation)),
      tap(() => this.ensurePushNotification())
    );
  }

  public addFriendsToGroupOrder(invitation: GroupOrderInvitation): Observable<GroupOrder> {
    return this.http.put(`${this.BASE_PATH}addFriendsToGroupOrder`, invitation.serialize()).pipe(
      map((json: Object) => new GroupOrder().deserialize(json)),
      tap(() => this.auditGroupOrderFriendsAdditionToGroup(invitation)),
      tap((g: GroupOrder) => this.groupOrder.next(g))
    );
  }

  public join(groupOrderJoin: GroupOrderJoin): Observable<GroupOrder> {
    let url = `${this.BASE_PATH}join?joinId=${groupOrderJoin.joinId}`;
    if (!!groupOrderJoin.email) {
      url = url.concat(`&email=${groupOrderJoin.email}`);
    }

    if (!!groupOrderJoin.inviteeEmail) {
      url = url.concat(`&inviteeEmail=${encodeURIComponent(groupOrderJoin.inviteeEmail)}&name=${groupOrderJoin.name}`);
    }

    return this.http.post(url, null).pipe(
      map((json: Object) => new GroupOrder().deserialize(json)),
      tap((g: GroupOrder) => this.groupOrder.next(g)),
      tap(g =>
        !g.orderKey
          ? this.auditGroupOrderJoin(groupOrderJoin.email || groupOrderJoin.inviteeEmail)
          : this.auditGroupOrderJoinWhileStausIsDone()
      )
    );
  }

  public cancel(): Observable<any> {
    return this.http.post(`${this.BASE_PATH}cancel`, null).pipe(
      tap(() => this.auditGroupOrderCancellation()),
      tap(() => this.removePushNotification()),
      tap(() => this.groupOrder.next(new GroupOrder()))
    );
  }

  public leave(): Observable<any> {
    return this.http.post(`${this.BASE_PATH}leave`, null).pipe(
      tap(() => this.auditGroupOrderLeaving()),
      tap(() => this.removePushNotification()),
      tap(() => this.groupOrder.next(new GroupOrder()))
    );
  }

  public clear(): Observable<any> {
    return this.http.post(`${this.BASE_PATH}clear`, null).pipe(tap(() => this.removeGOAndNotifications()));
  }

  public ready(): Observable<any> {
    return this.http.post(`${this.BASE_PATH}ready`, null).pipe(
      tap(() => this.auditGroupOrderReadiness()),
      tap(g => this.groupOrder.next(new GroupOrder().deserialize(g)))
    );
  }

  public unready(): Observable<any> {
    return this.http.post(`${this.BASE_PATH}unready`, null).pipe(
      tap(g => this.groupOrder.next(new GroupOrder().deserialize(g))),
      tap(() => this.auditGroupOrderUnreadiness())
    );
  }

  public merge(): Observable<any> {
    return this.http.post(`${this.BASE_PATH}merge`, null).pipe(
      tap((g: Object) => this.groupOrder.next(new GroupOrder().deserialize(g))),
      tap(() => this.auditGroupOrderMerge()),
      mergeMap(() => this.orderService.fetchOrder())
    );
  }

  public removePushNotification() {
    if (!this.groupOrderWS) {
      return;
    }

    this.groupOrderWS.close();
    this.groupOrderWS = undefined;
  }

  public findInvitationDialogConfig(): Observable<InvitationDialogConfig> {
    return this.http.get<InvitationDialogConfig>(`${this.BASE_PATH}findInvitationDialogConfig`);
  }

  public changeExpirationDate(expirationDate: string): Observable<GroupOrder> {
    return this.http.put(`${this.BASE_PATH}time`, { expirationDate }).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, GroupOrder)),
      tap((g: GroupOrder) => this.groupOrder.next(g))
    );
  }

  public info(joinId: string): Observable<GroupOrderInfo> {
    return this.http.get(`${this.BASE_PATH}info?joinId=${joinId}`).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, GroupOrder)),
      tap((g: GroupOrder) => this.groupOrder.next(g))
    );
  }

  private initGroupOrdering(): Observable<any> {
    this.ensurePushNotification();
    this.registerStateChangeHandler();
    return this.orderService.fetchOrder().pipe(mergeMap(o => (o ? this.orderPaymentTypesService.fetchPaymentTypes() : of(null))));
  }

  private ensurePushNotification() {
    const g = this.groupOrder.value;
    const topic = `grouporder/${g.id}`;

    if (!g || (this.groupOrderWS && this.groupOrderWS.hasSubsription(topic))) {
      return;
    }

    const messageHandler = (msg: { groupOrder: GroupOrder; webGroupOrderStatus: GroupOrderStatus } & WebSocketMessage) => {
      if (msg.membersOrderChanged) {
        this.orderService.fetchOrder().subscribe(
          () => {},
          () => {}
        );
      }

      if (msg.webGroupOrderStatus === GroupOrderStatus.EXPIRED) {
        // expire-event are pure server generated and resources need request to convert correctly
        this.fetchGroupOrder().subscribe(
          () => {},
          () => console.error()
        );
      } else {
        this.groupOrder.next(new GroupOrder().deserialize(msg.groupOrder));
      }
    };

    WebSocketConnection.create(new WebSocketConfig('/websocket/grouporder', { host: this.config.webSocketHost }))
      .then(c => {
        this.groupOrderWS = c;
        this.groupOrderWS.subscribe(topic, messageHandler);
      })
      .catch(e => console.error(e));
  }

  private registerStateChangeHandler() {
    this.groupOrder$
      .pipe(
        filter(g => !!g),
        distinctUntilKeyChanged('status'),
        map((g: GroupOrder) => new GroupOrderStatusChange(g.status, g)),
        filter(statusChange => !!statusChange)
      )
      .subscribe((statusChange: GroupOrderStatusChange) => this.groupOrderStatusChanged.next(statusChange));
  }

  private timeToExpiration(): Observable<number> {
    return this.groupOrder$.pipe(
      filter((g: GroupOrder) => !!g && !!g.expirationDate),
      switchMap((g: GroupOrder) => {
        return interval(1000).pipe(
          startWith(0),
          map(() => {
            let parsedExpirationDate = g.expirationDate && Utils.parseIsoDate(g.expirationDate);
            if (!isValid(parsedExpirationDate)) {
              parsedExpirationDate = addWeeks(Utils.getTzDate(new Date()), 1);
            }
            return differenceInMinutes(parsedExpirationDate, Utils.getTzDate(new Date()));
          })
        );
      }),
      catchError((e, c) => {
        console.error(e);
        return c;
      })
    );
  }

  private removeGOAndNotifications(): void {
    this.removePushNotification();
    this.groupOrder.next(new GroupOrder());
  }

  private auditGroupOrderFriendInvitation(invitation: GroupOrderInvitation): void {
    this.auditService.createAudit(() => {
      const invitees = invitation.friends.map(f => `<${f.name}, ${f.email}>`).join(', ');
      const { id, note, leader, expirationDate } = this.groupOrder.getValue();
      const membersCanPayIndividually = this.groupOrder.getValue().memberPayment;
      const groupOrderLeader = `<${leader ? `${leader.name}, ${leader.email}` : 'unknown'}>`;
      const formattedExpirationDate = this.dateFormat.transform(expirationDate, 'yyyy-MM-dd HH:mm:ss a');

      return {
        message: 'A new group order has been created',
        details: {
          id,
          note,
          leader: groupOrderLeader,
          invitees,
          expirationDate: formattedExpirationDate,
          membersCanPayIndividually,
        },
      };
    });
  }

  private auditGroupOrderFriendAddition(friend: GroupOrderFriend): void {
    this.auditService.createAudit(() => `Added "${friend.name}" (${friend.email}) to group order`);
  }

  private auditGroupOrderFriendDeletion(friend: GroupOrderFriend): void {
    this.auditService.createAudit(() => `Removed "${friend.name}" (${friend.email}) from group order`);
  }

  private auditGroupOrderFriendsAdditionToGroup(invitation: GroupOrderInvitation): void {
    this.auditService.createAudit(() => {
      const invitees = invitation.friends.map(f => `<${f.name}, ${f.email}>`).join(', ');
      const { id, note, leader, expirationDate } = this.groupOrder.value;
      const groupOrderLeader = `<${leader ? `${leader.name}, ${leader.email}` : 'unknown'}>`;
      const formattedExpirationDate = this.dateFormat.transform(expirationDate, 'yyyy-MM-dd HH:mm:ss a');

      return {
        message: 'Friends have been added to current group order',
        details: {
          id,
          note,
          leader: groupOrderLeader,
          invitees,
          expirationDate: formattedExpirationDate,
        },
      };
    });
  }

  private auditGroupOrderJoin(email: string): void {
    this.auditService.createAudit(() => {
      const { id, me } = this.groupOrder.value;
      const member = me ? me.name : '<unknown>';
      return `Group order member "${member}" (${email}) has joined group order ${id}`;
    });
  }

  private auditGroupOrderCancellation() {
    this.auditService.createAudit(() => `Group order ${this.groupOrder.value.id} has been canceled`);
  }

  private auditGroupOrderLeaving() {
    this.auditService.createAudit(() => {
      const { me } = this.groupOrder.value;
      const [member, email] = me ? [me.name, me.email] : ['<unknown>', '<unknown>'];
      return `Member "${member}" (${email}) has left group order ${this.groupOrder.value.id}`;
    });
  }

  private auditGroupOrderReadiness() {
    this.auditService.createAudit(() => {
      const { me } = this.groupOrder.value;
      const member = me ? me.name : '<unknown>';
      return `Group order member "${member}" has set status to ready`;
    });
  }

  private auditGroupOrderUnreadiness() {
    this.auditService.createAudit(() => {
      const { me } = this.groupOrder.value;
      const member = me ? me.name : '<unknown>';
      return `Group order member "${member}" has set status to unready`;
    });
  }

  private auditGroupOrderMerge() {
    this.auditService.createAudit(() => {
      const { me, id } = this.groupOrder.value;
      const member = me ? me.name : '<unknown>';
      return { message: `Group order member "${member}" has merged group order ${id}` };
    });
  }

  private auditGroupOrderJoinWhileStausIsDone() {
    this.auditService.createAudit(() => ({
      message: `Member could not join group order`,
      details: 'This group order has been placed.',
    }));
  }
}
