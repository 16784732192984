import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Order, OrderService } from 'ngx-web-api';

import { Observable, of } from 'rxjs';
import { filter, map, tap, mergeMap } from 'rxjs/operators';

@Injectable()
export class NeedsOrderGuard implements CanActivate {
  constructor(private orderService: OrderService, private router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.orderService.order$.pipe(
      filter((order: Order) => !order.dummy),
      mergeMap((order: Order) => {
        if (order.isWebPay) {
          return this.orderService.deleteOrder().pipe(mergeMap(() => this.orderService.fetchOrder()));
        }
        return of(order);
      }),
      map((order: Order) => order.isInitialized),
      tap(hasOrder => !hasOrder && this.router.navigate(['/intro'], { queryParamsHandling: 'merge' }))
    );
  }
}
