<div class="store-selection-container primary-bg-color mobile"
    role="dialog"
    aria-modal="true"
    [id]="'storeSelectionModal'"
    tabindex="-1"
    [attr.aria-label]="'component.header.store_info.store_selection.mobile_modal_label' | translate"
    #storeSelection
    [@show]="(breakpointService.isTabletDown$ | async) ? show : 'unset'"
    [hidden]="hidden"
    (@show.start)="animationStart()"
    (@show.done)="animationEnd()">
  <div class="done-container">
    <fts-themeable-checkbox
      *ngIf="shouldShowMakeFavoriteStore"
      class="primary-text-color text-uppercase"
      [disabled]="!selectedStore"
      [(ngModel)]="rememberMyChoice">
        {{'component.header.store_info.store_selection.make_this_my_location' | translate}}
    </fts-themeable-checkbox>
    <button ftsAsyncButton (click)="handleCancel()" translate id="cancelButton"
            ftsAuditableButton auditName="Store chooser CANCEL"
            class="btn primary-bg-color primary-text-color font-secondary text-uppercase ms-auto store-selection-cancel-btn">
              component.header.store_info.store_selection.cancel_btn
    </button>
    <button *ngIf="!this.selectedStore || this.selectedStore?.isCurrentStore" ftsAsyncButton [subscription]="changeStoreSubscription" (click)="handleDone()" translate
            ftsAuditableButton auditName="Store chooser DONE" id="doneButton"
            class="btn selection-bg-color selection-inverse-text-color font-secondary text-uppercase store-selection-done-btn">
              component.header.store_info.store_selection.done_btn
    </button>
  </div>

  <ng-container *ngIf="!isInIntroOrHome">
    <fts-order-type-selector
      class="order-type-container overlay primary-text-color"
      [ngClass]="{'no-chooser': !storeConfig?.hasStoreChooser}"
      [selectedOrderTypeConfig]="selectedOrderTypeConfig"
      [orderTypeConfigs]="orderTypeConfigs"
      [radioStyle]="true"
      [disabled]="groupOrder && groupOrder.isActive && !groupOrder.merged"
      (orderTypeConfigChange)="handleOrderTypeConfigChange($event)">
    </fts-order-type-selector>

    <fts-order-time-chooser
      *ngIf="selectedOrderTypeConfig && selectedOrderTypeConfig.type !== OrderType.Any && (orderDeferOptionsFetched || !order.isInitialized)"
      class="order-time-container overlay primary-text-color"
      [isInStoreSelection]="true"
      [orderType]="selectedOrderTypeConfig?.type"
      [deferTime]="selectedDeferTime"
      [deferChoice]="deferChoice"
      [disabled]="groupOrder && groupOrder.isActive && !groupOrder.merged"
      [orderDeferOptions]="orderDeferOptions"
      (orderTimeChange)="chooseOrderTime($event)"
      (deferChoiceChange)="chooseDeferChoice($event)">
    </fts-order-time-chooser>

    <div class="defer-time-error-container overlay primary-text-color" *ngIf="orderErrorMsg">
      <div role="alert" aria-atomic="true" class="alert alert-danger alert-form error-message">{{orderErrorMsg}}</div>
    </div>
    <div class="defer-time-error-container overlay primary-text-color px-3" *ngIf="groupOrder && groupOrder.isActive && !groupOrder.merged">
      <div role="alert" aria-atomic="true" class="alert alert-danger alert-form error-message">{{'component.header.store_info.store_selection.group_order_not_ready_message' | translate}}</div>
    </div>
  </ng-container>

  <div class="type-state-container overlay" *ngIf="storeConfig?.hasStoreChooser" [ngClass]="{'mt-60': isInIntroOrHome && (breakpointService.isTabletDown$ | async)}">
    <button *ngIf="storeConfig?.storeFinderMethod !== storeFinderMethod.NONE"
      ftsAuditableButton auditName="{{'component.header.store_info.store_selection.use_location_btn' | translate}}"
      class="btn font-secondary secondary-text-color secondary-bg-color text-uppercase location-btn fs-08" (click)="onUseLocation()">
      <fa-icon class="compass-icon" [icon]="faFtsCompass" [fixedWidth]="true"></fa-icon>
      <span translate>component.header.store_info.store_selection.use_location_btn</span>
    </button>
    <span class="font-secondary primary-text-color text-uppercase fs-08"
          [ngClass]="{'margin-left-15 text-right' : storeConfig?.storeFinderMethod !== storeFinderMethod.NONE, ' text-center': storeConfig?.storeFinderMethod === storeFinderMethod.NONE}" translate>
            {{storeConfig?.storeFinderMethod !== storeFinderMethod.NONE ?
              'component.header.store_info.store_selection.enter_address' :
              'component.header.store_info.store_selection.select_state'
            }}
    </span>
    <select *ngIf="storeConfig?.storeFinderMethod === storeFinderMethod.NONE"
            [ngModel]="selectedStoreState" (ngModelChange)="handleStoreStateChange($event)"
            class="store-states form-control primary-bg-color primary-text-color"
            [attr.aria-label]="'component.header.store_info.store_selection.merchant_store_selector.state' | translate">
      <option *ngFor="let storeState of storeStates" [value]="storeState">{{ storeState }}</option>
    </select>
  </div>

  <fts-store-finder-form
    *ngIf="storeConfig?.hasStoreChooser && storeConfig?.storeFinderMethod !== storeFinderMethod.NONE"
    class="overlay store-finder-form"
    [storeConfig]="storeConfig"
    [loadingStores]="loadingStores"
    [storeStates]="storeStates"
    [serverErrors]="serverErrors"
    [needsAddress]="selectedOrderTypeConfig?.needsAddress"
    (addressInputChange)="onAddressInput($event)"
    (findStore)="onFindStore($event)"
  ></fts-store-finder-form>

  <div class="store-container overlay" id="storeContainerRef">
    <fts-merchant-store-selector
      *ngIf="!hidden"
      [containerHeight]="containerHeight"
      [radioStyle]="true"
      [merchantStores]="merchantStores"
      [currentStoreAddress]="storeConfig?.fullAddress"
      [selectedMerchantStore]="selectedStore"
      [loadingMerchantStores]="loadingStores && !loadingStores.closed"
      [isTabletDown]="(breakpointService.isTabletDown$ | async)"
      (merchantStoreChange)="handleStoreChange($event)"
      (changeStore)="handleDone()">
    </fts-merchant-store-selector>
    <div *ngIf="message" class="info-message primary-text-color">
      <div>{{message}}</div>
      <button class="btn btn-link btn-undecorated text-uppercase padded-left-0 fw-600 margin-top-10" (click)="onAddressInput()"
        ftsAuditableButton auditName="BACK">
        <fa-icon [icon]="faChevronLeft" class="margin-right-5"></fa-icon>
        {{'component.header.store_info.store_selection.merchant_store_selector.back' | translate}}
      </button>
    </div>
  </div>
</div>
