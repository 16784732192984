import { Injectable } from '@angular/core';
import { ParsedErrorResponse } from '../../domain/parsed-error-response.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  /**
   * Parses all errors of the given Response no matter the status code.
   * In should not be used that match as {@link parseErrorResponse} is the way to go in most cases.
   */
  parseAllErrorsFromResponse(err: any): ParsedErrorResponse {
    if (typeof err['error'] === 'string') {
      err['error'] = JSON.parse(err['error']);
    }
    const errors = !!err['error'] ? err['error'].errors : [];
    const message = !!err['error'] ? err['error'].message : '';
    switch (err.status) {
      case 422:
        return this.parseValidationErrors(errors);
      case 500:
        return this.parseUnexpectedErrors(err);
      default:
        return this.parseGenericErrors(message);
    }
  }
  /**
   * Parses errors from 409 and 422 status. In any different case used ErrorNotificationModal
   * to show a notification with the returned error.
   */
  parseErrorResponse(err: any): ParsedErrorResponse {
    if (typeof err['error'] === 'string') {
      err['error'] = JSON.parse(err['error']);
    }
    const errors = !!err['error'] ? err['error'].errors : [];
    const message = !!err['error'] ? err['error'].message : '';
    switch (err.status) {
      case 409:
      case 401:
        return this.parseGenericErrors(message);
      case 422:
        return this.parseValidationErrors(errors);
      default:
        return this.parseAllErrorsFromResponse(err);
    }
  }

  private parseValidationErrors(errors): ParsedErrorResponse {
    return {
      errors,
      allErrors: Object.keys(errors)
        .map(key => errors[key])
        .reduce((a, b) => a.concat(b), []),
    };
  }

  private parseGenericErrors(error: string): ParsedErrorResponse {
    return {
      message: error,
      allErrors: [error],
    };
  }

  private parseUnexpectedErrors(error: any): ParsedErrorResponse {
    const message =
      error?.error?.message ?? error?.error?.meta?.error ?? 'There is a connectivity issue, please try again in a few moments';
    return {
      message: message,
      allErrors: [message],
    };
  }
}
