import { BaseResource } from '../core/base-resource';
import { AdjustmentType } from './adjustment-type.enum';

export class Adjustment extends BaseResource {
  ordinal: number;
  name: string;
  discount: number;
  couponCode: string;
  type: AdjustmentType;
}
