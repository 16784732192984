<div class="text-center">
  <fieldset>
    <legend class="step-legend">{{'component.guest_address_chooser.where_order' | translate}}</legend>
    <p class="small no-margin fw-600"><a class="selection-bg-color selection-inverse-text-color px-1" href="" type="button" (click)="redirectToLogin($event)">
      {{'component.guest_address_chooser.sign_in' | translate}}
    </a> {{'component.guest_address_chooser.select_or_add' | translate}}</p>
    <hr aria-hidden="true">

    <div class="row text-left">
      <div [ngClass]="isInIntro ? 'col-md-6 offset-md-3' : 'col-md-8 offset-md-2'">
        <fts-address-form
          #addressForm
          [errors]='addressValidationErrors'
          [(address)]='customerAddress'
          (addressHasChanged)='onAddressChange()'
          [isGuest]='true'
          [showClientValidation]='true'
          [horizontal]='horizontal'
          [isInIntro]="isInIntro"
          (streetChange)="onStreetChange($event)"
          (addressTypeChange)="onAddressTypeChange($event)"
          (addressErrors)="onAddressErrors($event)">
        </fts-address-form>
      </div>
    </div>

  </fieldset>
</div>
