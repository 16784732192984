import { Component, Input, OnInit } from '@angular/core';
import { GroupOrderOrchestratorService } from 'app/core/services/group-order-orchestrator.service';
import { GroupOrderButtonStyle } from 'app/domain/group-order-button-style.enum';
import { GroupOrder, GroupOrderService } from 'ngx-web-api';
import { faArrowRight, faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import { BreakpointService } from 'app/core/services/breakpoint.service';
import { faUsers } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'fts-grouporder-button',
  templateUrl: './grouporder-button.component.html',
  styleUrls: ['./grouporder-button.component.scss'],
})
export class GrouporderButtonComponent implements OnInit {
  @Input()
  label = '';
  @Input()
  isInOrderTree = false;
  @Input()
  isInSidebar = false;
  @Input()
  isInMinifiedAccountSidebar = false;
  @Input()
  buttonStyle: GroupOrderButtonStyle = undefined;
  @Input()
  isInCompactHeader: boolean = false;
  @Input()
  isRemovable = false;
  @Input()
  isTabletDown: boolean;

  showGroupOrderButton = false;
  isGroupOrderActive = false;
  buttonCSS: string;
  groupOrder: GroupOrder;
  faUserGroup: any;
  faArrowRight = faArrowRight;

  constructor(
    private groupOrderOrchestratorService: GroupOrderOrchestratorService,
    private groupOrderService: GroupOrderService,
    public breakpointService: BreakpointService
  ) {
    this.groupOrderService.groupOrder$.subscribe(g => (this.groupOrder = g));
  }

  ngOnInit(): void {
    this.buttonCSS = this.matchButtonStyle(this.buttonStyle);
    this.faUserGroup = this.isInMinifiedAccountSidebar ? faUsers : faUserGroup;
    this.groupOrderOrchestratorService.showGroupOrderButton$.subscribe(
      ([isAllowedToShowGoBtn, isGroupOrderActive, showIfNotInOrderTree, isMobile, orderTreeIsOpen, orderIdCookieExists]) => {
        this.showGroupOrderButton =
          isAllowedToShowGoBtn &&
          (this.isInOrderTree ||
            (!this.isInOrderTree &&
              showIfNotInOrderTree &&
              (!isMobile || (isMobile && (!orderIdCookieExists || this.isInSidebar) && !orderTreeIsOpen)) &&
              (!isMobile || (isMobile && !this.isInCompactHeader))));
        this.isGroupOrderActive = isGroupOrderActive;
      }
    );
  }

  initiateGoupOrder() {
    if (!this.isGroupOrderActive) {
      this.groupOrderOrchestratorService.toggleGroupOrderInvitation();
    }
  }

  private matchButtonStyle(buttonStyle: GroupOrderButtonStyle): string {
    switch (buttonStyle) {
      case 'BUTTON':
        return `selection-bg-color selection-inverse-text-color primary-btn selection-btn full-width`;
      case 'LINK':
        return `btn-link btn-undecorated fw-500 no-padding no-border ${this.isInMinifiedAccountSidebar ? 'link-label text-left' : ''}`;
      case 'LINK_MINIFIED':
        return `btn-link btn-undecorated full-width ${this.isTabletDown ? 'fw-600' : 'header-link'}`;
      default:
        return '';
    }
  }
}
