import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NetworkErrorInterceptor implements HttpInterceptor {
  // This interceptor handles Unhandled Network Request failures
  // Whenever we call Angular's HttpClient APIs (GET, POST, PUT, DELETE)
  // without using an error handler on subscription, we might end with an
  // unhandled XMLHttpRequest Error. This creates and throws a more comprehensive
  // Error in order to group and identify such cases using Sentry
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.error instanceof ProgressEvent) {
          const custom = new Error();
          custom.name = 'NetworkError';
          custom.message = `HTTP Request Failed`;
          return throwError(custom);
        }
        return throwError(err);
      })
    );
  }
}
