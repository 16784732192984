import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Account, LoyaltyPlan, StoreConfig } from 'ngx-web-api';
import { filter } from 'rxjs/operators';
import { Animations } from '../../../animations';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { BreakpointService } from '../../services/breakpoint.service';

@Component({
  selector: 'fts-collapsible-account-menu',
  templateUrl: './collapsible-account-menu.component.html',
  styleUrls: ['./collapsible-account-menu.component.scss'],
  animations: [Animations.expanded],
})
export class CollapsibleAccountMenuComponent implements OnInit {
  @ViewChild('accountMenu', { static: true })
  accountMenu: ElementRef;

  @Input()
  account: Account;
  @Input()
  store: StoreConfig;
  @Input()
  hasTopOrderTree: boolean;
  @Input()
  loyaltyPlan: LoyaltyPlan;
  @Input()
  leftPositioned: boolean;
  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();

  menuVisible = false;
  faUserCircle = faUserCircle;

  @HostBinding('style.marginBottom.px')
  readonly bottomMargin = -1;

  constructor(private router: Router, public breakPointService: BreakpointService) {}

  @HostListener('document:click', ['$event'])
  onClick(event): void {
    if (this.accountMenu && !this.accountMenu.nativeElement.contains(event.target)) {
      this.menuVisible = false;
    }
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => (this.menuVisible = false));
  }

  handleLogout() {
    this.logout.emit();
  }

  handleLogin() {
    this.login.emit();
  }

  toggleMenuVisibility() {
    this.menuVisible = !this.menuVisible;
  }
}
