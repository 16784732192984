<ng-container *ngIf="(breakpointService.isTabletDown$ | async) && !minifiedHeader">
  <div class="menu-links-container">
    <div class="store-info-container primary-bg-color" *ngIf="!isInCheckoutDetails">
      <ng-container *ngIf="!!orderTypeConfig && !(isInTrackerPage$ | async)">
        <div class="order-type fw-600 margin-bottom-5 text-uppercase">{{orderTypeConfig.displayNameOrName}}</div>
        <div>
          {{(order?.deferTime ? 'component.header.menu_links.vertical_menu_links.at' : 'component.header.menu_links.vertical_menu_links.asap') | translate}} {{order?.deferTime | todayFormat : true}}
        </div>
      </ng-container>

      <div class="store-info margin-bottom-5">
        <div>{{store?.city}}</div>
        <div>{{store?.address}}</div>
        <fts-store-phone [phone]="store?.phone"></fts-store-phone>
      </div>

      <button
        *ngIf="showChangeStoreBtn && !isSelfOrder && !(isInThanksPage$ | async)"
        ftsAuditableButton auditName="Change Store/Order type"
        class="btn btn-link btn-undecorated no-padding action-text-color change-order-type-btn text-uppercase"
        [ngClass]="{'large-font': breakpointService.isTabletDown$ | async}"
        (click)="changeStore.emit()">
        {{'component.header.menu_links.vertical_menu_links.change_order_type' | translate}}
      </button>
    </div>
    <div class="account-links secondary-bg-color secondary-text-color">
      <fts-store-logo
        *ngIf="theme?.compactHeader"
        [logoRedirectToStaticSite]="store?.logoRedirectToStaticSite"
        [staticSiteUrl]="store?.staticSiteUrl"
        [src]="store?.logo"
        [compact]="true"
        [alignCenter]="false">
      </fts-store-logo>
      <fts-mobile-account-menu
        class="mobile-account-menu"
        [isInIntro]="isInIntro"
        [account]="account"
        [store]="store"
        [featuredContents]='featuredContents'
        [loyaltyPlan]="loyaltyPlan"
        [order]="order"
        (login)="login.emit()"
        (logout)="logout.emit()"
      ></fts-mobile-account-menu>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!(breakpointService.isTabletDown$ | async) || minifiedHeader">
  <div class="logo-wrapper secondary-bg-color" *ngIf="breakpointService.isTabletDown$ | async">
    <fts-store-logo
      [logoRedirectToStaticSite]="store?.logoRedirectToStaticSite"
      [staticSiteUrl]="store?.staticSiteUrl"
      [src]="store?.logo"
      [hasFullWidth]="true"
      [compact]="true"
      [alignCenter]="true">
    </fts-store-logo>
  </div>

  <div class="sidebar-menu primary-bg-color primary-text-color fw-600">
    <button *ngIf="!account?.isInstantiated" (click)="login.emit()" class='btn btn-link btn-undecorated font-secondary secondary-bg-color secondary-text-color fw-500 text-uppercase sign-in-btn'>
      {{'component.header.menu_links.vertical_menu_links.sign_in' | translate}}
    </button>

    <div *ngIf="!isInCheckoutDetails" [ngClass]="{
      'margin-bottom-30': !account?.isInstantiated && !(breakpointService.isTabletDown$ | async),
      'margin-bottom-20': !account?.isInstantiated && (breakpointService.isTabletDown$ | async)
    }">
      <div class="text-uppercase" *ngIf="!!orderTypeConfig && !(isInTrackerPage$ | async)">
        <span >{{orderTypeConfig.displayNameOrName}}&nbsp;</span>
        <span class="small-font fw-300">{{'component.header.menu_links.vertical_menu_links.from' | translate}}</span>
      </div>

      <div class="fw-500">{{store?.address}}</div>

      <div *ngIf="!!orderTypeConfig && !(isInTrackerPage$ | async)">
        <span *ngIf="order?.deferTime" class="fw-500">{{'component.header.menu_links.vertical_menu_links.at' | translate}} </span>
        <span *ngIf="!order?.deferTime">{{'component.header.menu_links.vertical_menu_links.asap' | translate}}</span>
        <span>{{order?.deferTime | todayFormat : true}}</span>
      </div>

      <fts-store-phone [phone]="store?.phone" [minifiedHeader]="minifiedHeader" class="d-block"></fts-store-phone>

      <button
        *ngIf="showChangeStoreBtn && !isSelfOrder && !(isInThanksPage$ | async)"
        ftsAuditableButton auditName="Change Store/Order type"
        class="btn btn-link btn-undecorated no-padding text-uppercase fw-300 change-order-type-btn"
        (click)="changeStore.emit()">
        {{'component.header.menu_links.vertical_menu_links.change_order_type' | translate}}
      </button>
    </div>

    <ng-container *ngIf="account?.isInstantiated && featuredContents?.length">
      <hr aria-hidden="true" *ngIf="!isInCheckoutDetails">
      <ng-container *ngFor="let featuredContent of featuredContents">
        <a *ngFor="let featuredItem of featuredContent.featuredItems"
           [href]='featuredItem.link'
           [target]="featuredItem.target"
           class="btn btn-link btn-undecorated btn-block text-left font-secondary fw-500 no-padding no-border text-uppercase"
           [id]='"header-featured-link-" + (featuredItem.title | slugify)'>
           {{featuredItem.title}}
        </a>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="account?.isInstantiated">
      <hr aria-hidden="true" *ngIf="!isInCheckoutDetails || featuredContents?.length">
      <div class="text-uppercase margin-bottom-15">{{'component.header.menu_links.vertical_menu_links.my_account' | translate}}</div>
    </ng-container>

    <fts-mobile-account-menu
      [isInIntro]="isInIntro"
      [account]="account"
      [store]="store"
      [featuredContents]="!!account && !account.isInstantiated ? featuredContents : undefined"
      [loyaltyPlan]="loyaltyPlan"
      [minifiedHeader]="true"
      [order]="order"
      (login)="login.emit()"
      (logout)="logout.emit()"
    ></fts-mobile-account-menu>
  </div>
</ng-container>
