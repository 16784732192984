import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcceptHeader } from 'ngx-web-api';
import { Observable } from 'rxjs';

@Injectable()
export class AcceptInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return req.headers.get('Accept') === AcceptHeader.HATEOAS
      ? next.handle(req)
      : next.handle(req.clone({ setHeaders: { Accept: AcceptHeader.Plain } }));
  }
}
