import { BaseResource } from '../core/base-resource';
import { Picture } from './picture.model';

export class OrderableQualifier extends BaseResource {
  name: string;
  abbreviation: string;
  priceFactor: number;
  recipeFactor: number;
  isDefault: boolean;
  type: string;
  sizeCaloriesMap?: Map<string, number>;
  halfSizeCaloriesMap?: Map<string, number>;
  pictures?: Picture[];
}
