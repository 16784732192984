import { BaseResource } from './base-resource';

export class AddressType extends BaseResource {
  readonly addressType: string;
  readonly diff1: string;
  readonly diff2: string;
  readonly diff3: string;
  readonly requireDiff1: boolean;
  readonly requireDiff2: boolean;
  readonly requireDiff3: boolean;
}
