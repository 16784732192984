<div class="addresses-wrapper" [ngClass]="{'text-left': onePerRow}">

  <ng-container *ngIf="isInCheckout">
    <div class='entry-box primary-bg-color primary-text-color shadowed' *ngFor='let address of addresses; let first = first; let index = index;'>
      <fts-themeable-radio
        *ngIf="!editingAddress || editingAddress.name !== address.name"
        class='text-left'
        [name]="'saved-address'"
        [ngModel]='selectedAddress?.name'
        [id]="address.name | slugify"
        (ngModelChange)='onAddressChosen($event)'
        [value]='address.name'
        [disabled]="disableRadioButtons"
        >
        <fts-account-address-entry [address]="address"
                                   [isEditing]="editingAddress && editingAddress.name === address.name"
                                   [selected]="selectedAddress === address"
                                   [hideZip]="store?.hideZip"
                                   [useIntlAddressFormat]="store?.useIntlAddressFormat"
                                   [hideInstructions]="isInCheckout && selectedAddress && !editingAddress"
                                   (editingAddress)="onEditingAddress($event)"
                                   (addressDeleted)="onAddressDelete()"
                                   (deletingAddress)="onDeletingAddress($event)"
                                   [disableRadioButtons]="disableRadioButtons"></fts-account-address-entry>
      </fts-themeable-radio>
      <div class="fw-500 selection-text-color margin-left-10 margin-bottom-20" *ngIf="editingAddress && editingAddress.name === address.name">
        {{'component.account_address_chooser.edit_address' | translate}}
      </div>
      <div [@expanded] *ngIf='editingAddress && editingAddress.name === address.name' class="padded-left-10 padded-right-10">
        <fts-address-form
          #addressForm
          [isEditing]="true"
          [horizontal]="false"
          [showClientValidation]="true"
          [address]='editingAddress'
          [orderTypeConfig]="orderTypeConfig"
          [noContact]="noContact"
          [deliveryNote]="deliveryNote"
          (submitSuccess)="onAddressUpdated($event)"
          (streetChange)="onStreetChange($event)"
          (addressErrors)="onAddressErrors($event)">
        </fts-address-form>
        <button ftsAsyncButton
                [subscription]="addressForm.loadingSubscription"
                (click)="updateEditingAddress()"
                class="btn btn-block action-bg-color action-inverse-text-color text-uppercase primary-btn action-btn font-secondary">
          {{'component.account_address_chooser.save_address' | translate}}
        </button>
        <button (click)="cancelEditingAddress()"
                class="btn primary-bg-color primary-text-color btn-block text-uppercase font-secondary">
          {{'component.account_address_chooser.cancel' | translate}}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isInCheckout">
    <div *ngFor='let address of addresses; let first = first; let index = index;' class="inline-address-container">
      <fts-themeable-radio
        class='text-left'
        [name]="'saved-address'"
        [id]="address.name | slugify"
        [ngModel]='selectedAddress.name'
        (ngModelChange)='onAddressChosen($event)'
        [value]='address.name'>
        <div class='saved-address text-left'>
          <h4 class='h5 fw-600 font-secondary'>{{address.name}}</h4>
          <p class='small text-left fw-300' *ngIf='address.formattedDiffs'>{{address.formattedDiffs}}</p>
          <p class='small text-left fw-300'>{{getFormattedAddress(address)}}</p>
          <div class="badge bg-warning" *ngIf='address.warnings?.length'>
            {{'component.account_address_chooser.account_address_entry.incomplete_or_invalid' | translate}}
          </div>
        </div>
      </fts-themeable-radio>
      <button class="btn btn-link btn-undecorated btn-sm no-padding edit font-secondary"
              (click)='openEditingAddressModal(address)' [attr.aria-label]="'component.account_address_chooser.account_address_entry.edit_address' | translate">
        <fa-icon [fixedWidth]="true" [icon]="faPencil"></fa-icon>
      </button>
    </div>
  </ng-container>
</div>
