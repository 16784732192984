import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import {
  AuditService,
  DeferChoice,
  DeferService,
  OpenCloseTime,
  OrderTimeInfo,
  OrderType,
  StoreStatus,
  StoreStatusService,
  Utils,
  OrderTypeConfig,
  ConfigService,
  StoreConfig,
  DeferOptions,
  OrderDeferOptions,
} from 'ngx-web-api';
import { ThemeService } from '../../core/services/theme.service';
import { DaySlotChooserStyle } from '../../domain/day-slot-chooser-style';
import { Theme } from '../../domain/theme.model';
import { TimeslotChooserStyle } from './timeslot-chooser-style.enum';
import { Animations } from '../../animations';
import { Subscription, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { formatISO, isBefore, addMinutes } from 'date-fns';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { InitParamsStorageService } from 'app/core/services/init-params-storage.service';
import { DateFormatPipe } from 'ngx-web-api/lib/pipes/date-format.pipe';

interface DeferOption {
  label: string;
  value: string;
  disabled: boolean;
}

@Component({
  selector: 'fts-order-time-chooser',
  templateUrl: './order-time-chooser.component.html',
  styleUrls: ['./order-time-chooser.component.scss'],
  animations: [Animations.expanded],
})
export class OrderTimeChooserComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  orderType: OrderType;
  @Input()
  orderIsInitialized: boolean;
  @Input()
  isInHome: boolean;
  @Input()
  orderTimeInfo: OrderTimeInfo;
  @Input()
  loadingSubscription: Subscription;
  @Input()
  deferTime?: string;
  @Input()
  deferChoice?: DeferChoice;
  @Input()
  initFromSavedState = false;
  @Input()
  isInStoreSelection = false;
  @Input()
  disabled: boolean;
  @Input()
  orderDeferOptions: OrderDeferOptions;

  @Output()
  orderTimeChange: EventEmitter<string | undefined> = new EventEmitter<string | undefined>(true);
  @Output()
  deferDateChange: EventEmitter<string | undefined> = new EventEmitter<string | undefined>(true);
  @Output()
  deferChoiceChange: EventEmitter<DeferChoice | undefined> = new EventEmitter<DeferChoice | undefined>(true);
  @Output()
  asapButtonClick: EventEmitter<void> = new EventEmitter<void>(true);
  @Output()
  hasAvailableDeferOptions: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output()
  orderTimeSelectionChanged: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  deferChoices = DeferChoice;
  timeSlots: string[] = [];
  selectedDeferDate?: string;
  selectedDeferTime?: string;
  orderTypes = OrderType;
  storeStatus: StoreStatus;
  selectedHour?: string;
  selectedMinutes?: string;
  selectedTimeSlot?: string;
  theme: Theme;
  timeslotChooserStyles = TimeslotChooserStyle;
  futureDaySlots: string[] = [];
  selectedFutureDay: string;
  daySlotChooserStyles = DaySlotChooserStyle;
  isCalendarChooserOpen: boolean;
  storeConfig: StoreConfig;
  selectedOrderTypeConfig: OrderTypeConfig;
  availableDeferOptions: DeferOption[];
  shouldShowDeferOptionChooser: boolean;
  dateFormat = new DateFormatPipe();

  openCloseTime: OpenCloseTime;

  shouldShowAsapButton = false;

  futureDates: string[];
  timeSlotsHours: string[];
  filteredTimeSlots: string[];
  timeSlotsMinutes: string[];
  isInIntro = false;
  isEuCountry: boolean;

  private destroy$: Subject<void> = new Subject<void>();
  public loadingTimeDefferOptions$: Observable<boolean>;

  constructor(
    private deferService: DeferService,
    private storeStatusService: StoreStatusService,
    private auditService: AuditService,
    private themeService: ThemeService,
    private configService: ConfigService,
    private translateService: TranslateService,
    private initParamsService: InitParamsStorageService
  ) {}

  ngOnInit(): void {
    this.loadingTimeDefferOptions$ = this.storeStatusService.loadingTimeDefferOptions.asObservable();
    this.isInIntro = !this.isInHome && !this.isInStoreSelection;
    this.configService.storeConfig$.pipe(takeUntil(this.destroy$)).subscribe((cfg: StoreConfig) => {
      this.storeConfig = cfg;
      this.isEuCountry = cfg.country === 'IE';
    });
    this.themeService.theme.pipe(takeUntil(this.destroy$)).subscribe(theme => {
      this.theme = theme;
      this.calcShouldShowAsapButton();
    });

    this.storeStatusService.storeStatus$
      .pipe(
        mergeMap((storeStatus: StoreStatus) =>
          this.configService.findOrderTypeConfig(this.orderType).pipe(map(config => ({ storeStatus, config })))
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(({ storeStatus, config }) => {
        this.selectedOrderTypeConfig = config || new OrderTypeConfig();
        this.storeStatus = storeStatus;
        this.updateFutureDates();
        this.calcShouldShowAsapButton();
        this.setOptionsForDeferOptionChooser();
        if (this.initFromSavedState) {
          this.initializeParamsFromSavedState();
        }
        if (!this.selectedOrderTypeConfig.allowWebDeferredOrders) {
          this.changeDeferChoice(this.storeStatus.isOpen && !this.isInHome ? DeferChoice.Asap : undefined);
        }
        if (this.deferChoice !== DeferChoice.Future) {
          this.selectedDeferDate = undefined;
          this.updateOpenCloseTime();
        }
        if (!this.deferChoice) {
          let selection = undefined;
          if (storeStatus.isOpen) {
            selection = this.isInHome || !this.selectedOrderTypeConfig.hasAsap ? undefined : DeferChoice.Asap;
          } else {
            if (this.selectedOrderTypeConfig.allowWebDeferredOrders) {
              if (storeStatus.hasToday) {
                selection = DeferChoice.Today;
              } else if (this.futureDates && this.futureDates.length) {
                selection = DeferChoice.Future;
              }
            }
          }
          this.changeDeferChoice(selection);
        } else if (
          (this.deferChoice === DeferChoice.Asap && !storeStatus.isOpen) ||
          (this.deferChoice === DeferChoice.Today && !storeStatus.hasToday)
        ) {
          this.updateDeferChoice(undefined);
        }
        this.handlePreselectDeferChoice();
        this.changeDeferChoice(this.deferChoice);
        if (this.storeStatus && this.storeStatus.deferredDayTimes) {
          this.futureDaySlots = Object.keys(this.storeStatus.deferredDayTimes).map(key => this.dateFormat.transform(key, 'yyyy-MM-dd'));

          if (this.deferChoice === DeferChoice.Future && !this.futureDaySlots.length) {
            if (this.storeStatus.isOpen) {
              this.changeDeferChoice(DeferChoice.Asap);
            } else if (this.storeStatus.hasToday) {
              this.changeDeferChoice(DeferChoice.Today);
            } else {
              this.changeDeferChoice(undefined);
            }
          }
        }
        this.updateOpenCloseTime();
      });
  }

  ngOnChanges(changes: any) {
    if (!!changes['deferChoice']?.currentValue && changes['isInStoreSelection']?.currentValue) {
      const deferTimeParsed = Utils.parseIsoDate(this.deferTime);
      this.selectedTimeSlot = this.deferTime;
      if (!!this.orderDeferOptions) {
        this.selectedDeferDate = this.orderDeferOptions.deferDate;
      } else {
        this.selectedDeferDate = this.dateFormat.transform(deferTimeParsed, 'yyyy-MM-dd');
      }
      this.selectedFutureDay = this.selectedDeferDate;
      this.selectedDeferTime = this.deferTime;
    }

    if (changes['orderType'] && this.orderType !== OrderType.Any) {
      let timeSlotsDate;
      let deferOptions;
      if (this.deferChoice === DeferChoice.Today) {
        timeSlotsDate = formatISO(new Date());
        deferOptions = DeferOptions.Today;
      } else if (this.deferChoice === DeferChoice.Future && this.selectedDeferDate) {
        timeSlotsDate = this.dateFormat.transform(this.selectedDeferDate, 'yyyy-MM-dd');
        deferOptions = DeferOptions.Future;
      } else {
        return;
      }
      if (!this.initFromSavedState) {
        this.updateTimeSlots([]);
        this.fetchTimeSlots(timeSlotsDate, deferOptions).subscribe(timeSlots => {
          this.updateTimeSlots(timeSlots);
          this.handlePreselectedDateAndTime();
          if (!this.isInStoreSelection) {
            this.resetSelectedOrderTime();
            this.selectedDeferDate = undefined;
            this.resetSelectedSlots();
          }
          this.updateOpenCloseTime();
        });
      }
    }

    if (changes['deferChoice'] && !changes['isInStoreSelection']?.currentValue) {
      this.selectedDeferTime = undefined;
      this.onTimeSlotChange(this.selectedDeferTime);
      this.updateOpenCloseTime();
      this.checkIfOrderTimeSelectionValid();
    }

    if (changes['isInHome'] || changes['orderIsInitialized']) {
      this.calcShouldShowAsapButton();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handlePreselectDeferChoice() {
    let availableChoices = [];
    availableChoices.push(this.storeStatus.isOpen && this.selectedOrderTypeConfig.hasAsap ? DeferChoice.Asap : undefined);
    availableChoices.push(
      this.selectedOrderTypeConfig.allowWebDeferredOrders && this.storeStatus?.hasToday ? DeferChoice.Today : undefined
    );
    availableChoices.push(
      this.selectedOrderTypeConfig.allowWebDeferredOrders && this.futureDates && this.futureDates.length ? DeferChoice.Future : undefined
    );
    availableChoices = availableChoices.filter(choice => !!choice);
    if ((!this.deferChoice || availableChoices.indexOf(this.deferChoice) === -1) && this.storeStatus.orderType !== OrderType.Any) {
      this.changeDeferChoice(availableChoices[0]);
      this.deferChoiceChange.emit(this.deferChoice);
    }
  }

  handlePreselectedDateAndTime() {
    if (this.deferChoice !== DeferChoice.Asap && this.timeSlots.indexOf(this.selectedTimeSlot) === -1) {
      this.selectedTimeSlot = undefined;
      this.resetSelectedOrderTime();
    }
    if (this.deferChoice === DeferChoice.Future && this.futureDates.indexOf(this.selectedDeferDate) === -1) {
      this.selectedDeferDate = undefined;
      this.selectedTimeSlot = undefined;
      this.resetSelectedOrderTime();
      this.updateTimeSlots([]);
    }
  }

  trackByDeferOption(_index, deferOption: DeferOption) {
    return deferOption.label;
  }

  asapButtonOnClick(): void {
    this.asapButtonClick.emit();
  }

  changeDeferChoice(choice: DeferChoice, isInitFromSavedState = false) {
    if (this.deferChoice === choice && !isInitFromSavedState) {
      return;
    }
    this.updateDeferChoice(choice);
    this.deferChoiceChange.emit(this.deferChoice);
    switch (choice) {
      case DeferChoice.Today:
        this.updateTimeSlots([]);
        this.fetchTimeSlots(formatISO(Utils.getTzDate()), DeferOptions.Today).subscribe(timeSlots => {
          this.updateTimeSlots(timeSlots);
          if (!isInitFromSavedState) {
            this.selectedDeferTime = undefined;
            this.resetSelectedSlots();
            this.checkIfOrderTimeSelectionValid();
          }
        });
        break;
      case DeferChoice.Future:
        if (!isInitFromSavedState) {
          this.selectedDeferDate = undefined;
          this.deferDateChange.emit(this.selectedDeferDate);
          this.updateOpenCloseTime();
          this.updateTimeSlots([]);
          this.selectedDeferTime = undefined;
          this.checkIfOrderTimeSelectionValid();
          this.selectedTimeSlot = undefined;
        } else {
          this.fetchTimeSlots(this.selectedFutureDay, DeferOptions.Future).subscribe(timeSlots => {
            this.updateTimeSlots(timeSlots);
          });
        }
        break;
      case DeferChoice.Asap:
        if (this.isInHome) {
          this.updateDeferChoice(undefined);
        }
        break;
      default:
    }
    if (!isInitFromSavedState) {
      this.orderTimeChange.emit(undefined);
      this.resetSelectedSlots();
    }
    this.auditService.createAudit(() => `Setting defer type to ${choice}`);
  }

  onDeferDateChange(date: string, shouldResetTimeSlots = true) {
    this.deferDateChange.emit(date);
    this.updateOpenCloseTime();
    this.fetchTimeSlots(formatISO(Utils.parseIsoDate(date)), DeferOptions.Future).subscribe(timeSlots => {
      this.updateTimeSlots(timeSlots);
      if (shouldResetTimeSlots) {
        this.resetSelectedSlots();
        this.resetSelectedOrderTime();
      }
    });
  }

  calendarDaySelected(day: string, shouldResetTimeSlots = true) {
    this.selectedFutureDay = day;
    this.selectedDeferDate = this.dateFormat.transform(day, 'yyyy-MM-dd');
    this.deferDateChange.emit(this.selectedDeferDate);
    this.updateOpenCloseTime();
    this.fetchTimeSlots(day, DeferOptions.Future).subscribe(timeSlots => {
      this.updateTimeSlots(timeSlots);
      if (shouldResetTimeSlots) {
        this.resetSelectedSlots();
        this.resetSelectedOrderTime();
      }
      this.isCalendarChooserOpen = false;
    });
  }

  onTimeSlotChange(date: string) {
    if (!!date) {
      this.auditService.createAudit(() => `Setting defer time to ${this.dateFormat.transform(date, 'yyyy-MM-dd HH:mm')}`);
    }
    this.orderTimeChange.emit(date);
    this.checkIfOrderTimeSelectionValid();
  }

  selectedTimeSlotHourChanged() {
    this.selectedMinutes = undefined;
    this.resetSelectedOrderTime();
    this.updateFilteredTimeSlots();
    this.updateTimeSlotsMinutes();
  }

  selectedTimeSlotChanged() {
    if (this.selectedTimeSlot) {
      this.selectedDeferTime = this.selectedTimeSlot;
      this.onTimeSlotChange(this.selectedDeferTime);
      this.checkIfOrderTimeSelectionValid();
    }
  }

  selectedTimeSlotMinutesChanged(minutes: string) {
    this.selectedMinutes = minutes;
    this.updateFilteredTimeSlots();
    if (this.filteredTimeSlots.length > 0) {
      this.selectedDeferTime = this.filteredTimeSlots[0];
      this.onTimeSlotChange(this.selectedDeferTime);
      this.checkIfOrderTimeSelectionValid();
    }
  }

  suggestDayPart(): string {
    if (this.timeSlots.length > 0) {
      if (this.selectedHour) {
        const timeSlotsFromSelectedHour = this.timeSlots
          .map(t => Utils.parseIsoDate(t))
          .filter(d => this.dateFormat.transform(d, 'h') === `${this.selectedHour}`);

        return this.dateFormat.transform(timeSlotsFromSelectedHour[0], 'a');
      }

      return this.dateFormat.transform(this.timeSlots[0], 'a');
    }

    return '';
  }

  endOfTimeSlot(timeslot: string): Date {
    if (timeslot && this.selectedOrderTypeConfig.deferredOrdersWebTimeSlot) {
      return addMinutes(Utils.parseIsoDate(timeslot), this.selectedOrderTypeConfig.deferredOrdersWebTimeSlot);
    } else {
      return Utils.getTzDate();
    }
  }

  private fetchTimeSlots(day: string, deferOptions?: DeferOptions): Observable<string[]> {
    return this.deferService.getTimeSlots(day, this.orderType, deferOptions);
  }

  private resetSelectedSlots() {
    this.selectedHour = undefined;
    this.selectedMinutes = undefined;
    this.selectedTimeSlot = undefined;
    this.updateFilteredTimeSlots();
    this.updateTimeSlotsMinutes();
  }

  private resetSelectedOrderTime() {
    this.selectedDeferTime = undefined;
    this.orderTimeChange.emit(undefined);
    this.checkIfOrderTimeSelectionValid();
  }

  private initializeParamsFromSavedState() {
    const deferTimeParsed = Utils.parseIsoDate(this.deferTime);
    this.selectedHour = this.dateFormat.transform(deferTimeParsed, 'h');
    this.selectedMinutes = this.dateFormat.transform(deferTimeParsed, 'mm');
    this.selectedTimeSlot = this.deferTime;
    this.selectedDeferDate = this.dateFormat.transform(deferTimeParsed, 'yyyy-MM-dd');
    this.updateOpenCloseTime();
    this.updateFilteredTimeSlots();
    this.updateTimeSlotsMinutes();
    this.selectedFutureDay = this.selectedDeferDate;
    this.selectedDeferTime = this.deferTime;
    this.checkIfOrderTimeSelectionValid();
    if (!!this.initParamsService.initParams.deferChoice && !!this.initParamsService.initParams.deferTime) {
      setTimeout(() => {
        this.selectedHour = this.dateFormat.transform(this.initParamsService.initParams.deferTime, 'h');
        this.selectedMinutes = this.dateFormat.transform(this.initParamsService.initParams.deferTime, 'mm');
        this.selectedFutureDay = this.selectedDeferDate = this.dateFormat.transform(
          this.initParamsService.initParams.deferTime,
          'yyyy-MM-dd'
        );
        this.updateOpenCloseTime();
        this.updateFilteredTimeSlots();
        this.updateTimeSlotsMinutes();
        this.changeDeferChoice(this.initParamsService.initParams.deferChoice, true);
        this.selectedTimeSlot = this.initParamsService.initParams.deferTime;
        this.selectedTimeSlotChanged();
      });
    }
    this.initFromSavedState = false;
  }

  private setOptionsForDeferOptionChooser() {
    const deferOptions = [];

    const possibleOptions = [this.getAsapOption(), this.getTodayOption(), this.getFutureOption()];
    possibleOptions.forEach(option => !!option && deferOptions.push(option));

    this.shouldShowDeferOptionChooser = !(
      (deferOptions.length === 1 && deferOptions[0].value === DeferChoice.Asap) ||
      deferOptions.length === 0
    );
    if (deferOptions.length > 0) {
      deferOptions.unshift({
        label: this.translateService.instant(
          this.shouldShowAsapButton ? 'component.order_time_chooser.select_time' : 'component.order_time_chooser.select'
        ),
        value: undefined,
        disabled: true,
      });
    }

    this.availableDeferOptions = deferOptions;
    this.hasAvailableDeferOptions.emit(this.availableDeferOptions.length > 0);
  }

  private getAsapOption(): DeferOption {
    let asapOption = undefined;
    if (this.storeStatus && this.storeStatus.isOpen && this.selectedOrderTypeConfig && this.selectedOrderTypeConfig.hasAsap) {
      asapOption = {
        label: this.selectedOrderTypeConfig.asapLabel || this.translateService.instant('component.defer_choice.asap'),
        value: DeferChoice.Asap,
        disabled: false,
      };
    }
    return asapOption;
  }

  private getTodayOption(): DeferOption {
    let todayOption = undefined;
    if (
      this.storeStatus &&
      this.storeStatus.hasToday &&
      this.selectedOrderTypeConfig &&
      this.selectedOrderTypeConfig.allowWebDeferredOrders
    ) {
      todayOption = {
        label: this.translateService.instant('component.defer_choice.today'),
        value: DeferChoice.Today,
        disabled: false,
      };
    }
    return todayOption;
  }

  private getFutureOption(): DeferOption {
    let futureOption = undefined;
    if (
      this.futureDates &&
      this.futureDates.length &&
      this.selectedOrderTypeConfig &&
      this.selectedOrderTypeConfig.allowWebDeferredOrders
    ) {
      futureOption = {
        label: this.translateService.instant('component.defer_choice.future'),
        value: DeferChoice.Future,
        disabled: false,
      };
    }
    return futureOption;
  }

  private updateOpenCloseTime() {
    if (this.deferChoice === DeferChoice.Future) {
      this.openCloseTime = this.selectedDeferDate ? this.storeStatus.getOpenCloseTime(this.selectedDeferDate) : undefined;
    } else if (!this.storeStatus?.hasToday && this.deferChoice === undefined && !this.selectedOrderTypeConfig.allowWebDeferredOrders) {
      this.openCloseTime = this.storeStatus.getOpenCloseTime(this.dateFormat.transform(this.storeStatus.nextOpenTime, 'yyyy-MM-dd'));
    } else {
      this.openCloseTime = this.storeStatus?.getOpenCloseTime();
    }
    this.storeStatusService.selectedOpenCloseTime.next(this.openCloseTime);
  }

  private checkIfOrderTimeSelectionValid() {
    let isSelectionValid = false;

    switch (this.deferChoice) {
      case DeferChoice.Asap:
        isSelectionValid = true;
        break;
      case DeferChoice.Today:
      case DeferChoice.Future:
        isSelectionValid = this.selectedDeferTime !== undefined;
        break;
      default:
        isSelectionValid = false;
    }

    this.orderTimeSelectionChanged.emit(isSelectionValid);
  }

  private updateFutureDates() {
    if (this.storeStatus && this.storeStatus.deferredDayTimes) {
      this.futureDates = Object.keys(this.storeStatus.deferredDayTimes)
        .map(key => Utils.parseIsoDate(key))
        .sort((a, b) => (isBefore(a, b) ? -1 : 1))
        .map(date => this.dateFormat.transform(date, 'yyyy-MM-dd'));
    }
  }

  private updateTimeSlots(timeSlots: string[]) {
    this.timeSlots = timeSlots;
    const hours = this.timeSlots.map(t => this.dateFormat.transform(t, 'h'));
    this.timeSlotsHours = Utils.distinct(hours).sort((a, b) => (parseInt(a, 10) < parseInt(b, 10) ? -1 : 1));
    this.updateFilteredTimeSlots();
    this.updateTimeSlotsMinutes();
  }

  private updateFilteredTimeSlots() {
    this.filteredTimeSlots =
      !this.selectedHour && !this.selectedMinutes
        ? []
        : this.timeSlots.filter(timeSlot => this.dateFormat.transform(timeSlot, 'h mm') === `${this.selectedHour} ${this.selectedMinutes}`);
  }

  private updateTimeSlotsMinutes() {
    if (!this.selectedHour) {
      this.timeSlotsMinutes = [];
    }

    const minutes = this.timeSlots
      .map(t => Utils.parseIsoDate(t))
      .filter(m => this.dateFormat.transform(m, 'h') === this.selectedHour)
      .map(m => this.dateFormat.transform(m, 'mm'));

    this.timeSlotsMinutes = Utils.distinct(minutes).sort();
  }

  private calcShouldShowAsapButton() {
    const hasAsap =
      this.storeStatus?.isOpen &&
      (!this.deferChoice || this.deferChoice === DeferChoice.Asap) &&
      this.storeStatus?.orderType !== OrderType.Any &&
      this.selectedOrderTypeConfig.hasAsap;
    this.shouldShowAsapButton = !(
      !this.isInHome ||
      !this.orderIsInitialized ||
      (this.theme && this.theme.askForOrderTimeUpfront && !hasAsap)
    );
  }

  private updateDeferChoice(value: DeferChoice) {
    this.deferChoice = value;
    this.updateOpenCloseTime();
    this.checkIfOrderTimeSelectionValid();
    this.calcShouldShowAsapButton();
  }
}
