import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { OrderableQualifier } from './orderable-qualifier.model';
import { Picture } from './picture.model';
import { SizePrice } from './size-price.model';
import { Tag } from './tag.model';

export class OrderableIngredient extends BaseResource {
  readonly name: string;
  readonly allowHalves: boolean;
  readonly isDefault: boolean;
  readonly isEverything: boolean;
  readonly isRemovable: boolean;
  readonly showNoDefault: boolean;
  readonly stackingOrder: number;
  readonly qualifiers: OrderableQualifier[];
  readonly weight: number;
  readonly replaceable: boolean;
  readonly description?: string;
  readonly determinesRecipe?: boolean;
  readonly pictures: Picture[];
  readonly sizePrices: SizePrice[];
  readonly tags: Tag[];
  readonly isExtraIngredientUpsell: boolean;
  readonly acknowledgeRemovedDefaults: boolean;
  readonly allowSecondHalves: boolean;

  getSizePrice(size: string): SizePrice | undefined {
    return this.sizePrices.find(s => s.size === size && !s.isUnavailable);
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      qualifiers: DeserializationUtils.deserializeChildren(input, 'qualifiers', OrderableQualifier),
      sizePrices: DeserializationUtils.deserializeChildren(input, 'sizePrices', SizePrice),
      tags: DeserializationUtils.deserializeChildren(input, 'tags', Tag),
    });
  }
}
