<footer *ngIf="!isInPay && !isInPrintReceipt" class="footer primary-text-color" id="footer" [ngClass]="{'padded-top-15 padded-bottom-15': !embedded}">
  <ng-container *ngIf="storeConfig?.contentPageFooter">
    <div  class="margin-top-10 margin-bottom-10 padded-bottom-15 padded-top-10 container">
      <div  class="text-center content-page-footer primary-text-color ">
        {{storeConfig.contentPageFooter}}
      </div>
    </div>
    <hr *ngIf='!embedded' aria-hidden="true">
  </ng-container>
  <div *ngIf='!embedded' class="footer-content container">
    <section *ngIf='storeConfig.foodtecLogo' class="text-center margin-left-30 margin-right-30 margin-top-10 margin-bottom-10">
      <a rel="nofollow" href="http://www.foodtecsolutions.com" target="_blank" id="foodtec-link"
        [attr.aria-label]="'component.footer.foodtec_logo_alt' | translate">
        <img class="foodtec-logo" src="{{storeConfig.foodtecLogo}}" [attr.alt]="'component.footer.foodtec_logo_alt' | translate" id="foodtec-logo">
      </a>
    </section>

    <section *ngIf="supportsCreditCards" class="credit-cards text-center margin-left-30 margin-right-30 margin-top-10 margin-bottom-10">
      <span class="sr-only">{{'component.footer.accepted_ccs' | translate}}</span>
      <fa-icon *ngFor="let creditCardType of creditCardTypes"
        class="credit-card" [fixedWidth]="true" [icon]="$any([creditCardType.fontAwesomePrefix, creditCardType.fontAwesomeIcon])" [title]="creditCardType?.name"></fa-icon>
      <img *ngIf="storeConfig?.hasCreditCard && applePayService.isSupported$ | async" class="credit-card apple-pay-mark" src="assets/img/apple-pay-mark.svg" alt="Apple Pay" title="Apple Pay"/>
    </section>

    <section class="row social text-center  margin-left-30 margin-right-30 margin-top-10 margin-bottom-10 justify-content-center" *ngIf="hasSocialLinks">
      <a *ngIf="socialLinks.facebookPageId"
         href="https://www.facebook.com/{{socialLinks.facebookPageId}}"
         target="_blank"
         class='primary-text-color social-link'
         id="facebook"
         aria-label="Facebook (opens in a new tab)">
        <fa-icon class="social-icon" triggers="hover" [icon]="faFacebook" ></fa-icon>
      </a>

      <a *ngIf="socialLinks.twitterPageId"
         href="https://www.twitter.com/{{socialLinks.twitterPageId}}"
         target="_blank"
         class='primary-text-color social-link'
         id="twitter"
         aria-label="Twitter (opens in a new tab)">
        <fa-icon class="social-icon" triggers="hover" [icon]="faTwitter" ></fa-icon>
      </a>

      <a *ngIf="socialLinks.youtubePageId"
         href="https://www.youtube.com/{{socialLinks.youtubePageId}}"
         target="_blank"
         class='primary-text-color social-link'
         id="youtube"
         aria-label="Youtube (opens in a new tab)">
        <fa-icon class="social-icon" triggers="hover" [icon]="faYoutube" ></fa-icon>
      </a>

      <a *ngIf="socialLinks.instagramPageId"
         href="https://www.instagram.com/{{socialLinks.instagramPageId}}"
         target="_blank"
         class='primary-text-color social-link'
         id="instagram"
         aria-label="Instagram (opens in a new tab)">
        <fa-icon class="social-icon" triggers="hover" [icon]="faInstagram" ></fa-icon>
      </a>
    </section>

    <section class="text-center margin-left-30 margin-top-10 margin-right-30-xs"
             [ngClass]="storeConfig.foodtecLogo ? 'margin-bottom-10 margin-right-30' : 'margin-bottom-0-xs margin-bottom-10-sm-min'">
      <div *ngIf="storeConfig.showFoodFeedback"  class="navigation-link">
        <a [routerLink]="['/questionnaire']" [queryParams]="{type: 'Feedback'}"
          [attr.aria-label]="'component.footer.food_feedback' | translate"
           class="primary-text-color fw-300 footer-menu-link">
          {{'component.footer.food_feedback' | translate}}
        </a>
      </div>

      <div *ngIf="storeConfig.showWebsiteFeedback" class="navigation-link">
        <a [routerLink]="['/questionnaire']" [queryParams]="{type: 'Experience'}"
           class="primary-text-color fw-300 footer-menu-link">
          {{'component.footer.feedback' | translate}}
        </a>
      </div>

      <div class="navigation-link" *ngIf="!theme.hideCareerQuestionnaireLink">
        <a *ngIf='!storeConfig.careersQuestionnaireLink'
           [routerLink]="['/questionnaire']" [queryParams]="{type: 'Career'}"
           class="primary-text-color fw-300 footer-menu-link">
          {{'component.footer.careers' | translate}}
        </a>

        <a *ngIf='storeConfig.careersQuestionnaireLink'
           [href]="storeConfig.careersQuestionnaireLink"
           target="_blank"
           [attr.aria-label]="('component.footer.careers' | translate) + ' ' + ('route.new_tab.opens_new_tab' | translate)"
           class="primary-text-color fw-300 footer-menu-link">
          {{'component.footer.careers' | translate}}
        </a>
      </div>

      <div class="navigation-link" *ngIf="!theme.hideContactQuestionnaireLink">
        <a *ngIf='!storeConfig.contactQuestionnaireLink'
           [routerLink]="['/questionnaire']" [queryParams]="{type: 'Contact'}"
           class="primary-text-color fw-300 footer-menu-link">
          {{'component.footer.contact' | translate}}
        </a>

        <a *ngIf='storeConfig.contactQuestionnaireLink'
           [href]="storeConfig.contactQuestionnaireLink"
           target="_blank"
           [attr.aria-label]="('component.footer.contact' | translate) + ' ' + ('route.new_tab.opens_new_tab' | translate)"
           class="primary-text-color fw-300 footer-menu-link">
          {{'component.footer.contact' | translate}}
        </a>
      </div>

      <div class="navigation-link" *ngIf="storeConfig.showEGiftButton">
        <a *ngIf='storeConfig.eGiftPageLink'
          [href]="storeConfig.eGiftPageLink"
          target="_blank"
          [attr.aria-label]="('component.footer.gcs' | translate) + ' ' + ('route.new_tab.opens_new_tab' | translate)"
          class="primary-text-color fw-300 footer-menu-link">
          {{'component.footer.gcs' | translate}}
        </a>
      </div>

      <ng-container *ngFor="let featuredContent of footerLinksFeaturedContent$ | async">
        <div class="navigation-link" *ngFor="let featuredLink of featuredContent.featuredItems">
          <a [href]="featuredLink.link"
             [target]='featuredLink.target'
             [id]='"footer-featured-link-" + (featuredLink.title | slugify)'
             class="primary-text-color fw-300 footer-menu-link featured-link">
            {{featuredLink.title}}
          </a>
        </div>
      </ng-container>
    </section>

    <section *ngIf='!storeConfig.foodtecLogo' class="text-center margin-left-15-sm-min margin-right-30 margin-top-10-sm-min margin-bottom-10 margin-left-30-xs margin-top-0-xs">
      <a class='primary-text-color fw-300 footer-menu-link' rel="nofollow" href="http://www.foodtecsolutions.com" target="_blank"
      [attr.aria-label]="('component.footer.web_by_foodtec' | translate) + ' ' + ('route.new_tab.opens_new_tab' | translate)" id="foodtec-link-text">
        {{'component.footer.web_by_foodtec' | translate}}
      </a>
    </section>

  </div>
</footer>

<footer class="path-footer overlay" *ngIf="isInPay && !isInPrintReceipt"></footer>
