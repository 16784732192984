import { BasicModalContext } from './basic-modal-context';

export class ItemEditorModalContext extends BasicModalContext {
  constructor(public itemOrdinalId?: number) {
    super();
    this.class = ' modal-lg item-editor-modal';
    this.ignoreBackdropClick = true;
    this.config = { itemOrdinalId };
    this.config.title = 'Item Editor Modal';
  }
}
