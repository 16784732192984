import { Component, OnInit } from '@angular/core';
import { Fundraiser, GenericObject, OrderedFundraiser, OrderedSponsor, OrderFundraiserService, Sponsor } from 'ngx-web-api';
import { Subscription } from 'rxjs';
import { ErrorsService } from '../../core/services/errors.service';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FundraiserSponsorsModalContext } from 'app/domain/fundraiser-sponsors-modal-context';

@Component({
  selector: 'fts-fundraiser-sponsors-modal',
  templateUrl: './fundraiser-sponsors-modal.component.html',
  styleUrls: ['./fundraiser-sponsors-modal.component.scss'],
})
export class FundraiserSponsorsModalComponent implements OnInit {
  public context: FundraiserSponsorsModalContext;
  fundraiser: Fundraiser;
  orderedFundraiser?: OrderedFundraiser;
  errorFromSponsorParam: any;
  orderedSponsorsFromParam: OrderedSponsor[];

  orderedSponsors: OrderedSponsor[] = [];
  loadingSubscription: Subscription;

  errors?: GenericObject<string[]> = {};
  errorMessage?: string;

  constructor(
    private orderFundraiserService: OrderFundraiserService,
    private errorService: ErrorsService,
    private uiOrchestratorService: UiOrchestratorService,
    private translateService: TranslateService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.fundraiser = this.context.fundraiser;
    this.orderedFundraiser = this.context.orderedFundraiser;
    this.errorFromSponsorParam = this.context.errorFromSponsorParam;
    this.orderedSponsorsFromParam = this.context.orderedSponsorsFromParam;
    if (this.orderedFundraiser) {
      this.orderedSponsors = this.orderedFundraiser.copy().sponsors || [];
    }
    if (!!this.orderedSponsorsFromParam) {
      this.orderedSponsors = this.orderedSponsorsFromParam.filter(sp => this.fundraiser.sponsors.map(s => s.name).indexOf(sp.name) >= 0);
    }
    this.uiOrchestratorService.informModalOpened();
  }

  hideModal(): void {
    this.activeModal.close();
  }

  onSponsorSelectionChanged(sponsor: Sponsor) {
    const index = this.findSelectedSponsorIndex(sponsor);

    if (index !== -1) {
      this.orderedSponsors.splice(index, 1);
    } else {
      this.orderedSponsors.push(OrderedSponsor.fromSponsor(sponsor));
    }
  }

  onSponsorNotesChanged(notes: string, sponsor: Sponsor) {
    const orderedSponsor = this.findOrderedSponsor(sponsor);

    if (orderedSponsor) {
      orderedSponsor.notes = notes;
    }
  }

  addFundraiserToOrder() {
    const orderedFundraiser = OrderedFundraiser.fromFundraiser(this.fundraiser);
    orderedFundraiser.sponsors = this.orderedSponsors;

    this.loadingSubscription = this.orderFundraiserService.addFundraiserToOrder(orderedFundraiser).subscribe(
      () => this.hideModal(),
      error => {
        const response = this.errorService.parseErrorResponse(error);
        this.errorMessage = response.message;
        this.errors = response.errors;
      }
    );
  }

  updateOrderedFundraiser() {
    if (this.orderedFundraiser) {
      this.orderedFundraiser.sponsors = this.orderedSponsors;
      this.loadingSubscription = this.orderFundraiserService.updateFundraiser(this.orderedFundraiser).subscribe(/* NO OP */);
    }

    this.hideModal();
  }

  get title(): string {
    const hasSponsorLabel = this.fundraiser.sponsorLabel && this.fundraiser.sponsorLabel.length > 0;
    if (!!this.errorFromSponsorParam) {
      const response = this.errorService.parseAllErrorsFromResponse(this.errorFromSponsorParam);
      return response?.message ? response?.message : this.translateService.instant('component.params_orchestrator.could_not_find_sponsor');
    }
    return hasSponsorLabel ? this.fundraiser.sponsorLabel : this.translateService.instant('component.fundraiser_sponsors_modal.sponsors');
  }

  isSponsorSelected(sponsor: Sponsor): boolean {
    return !!this.findOrderedSponsor(sponsor);
  }

  findOrderedSponsorNotes(sponsor: Sponsor): string {
    const orderedSponsor = this.findOrderedSponsor(sponsor);

    return !!orderedSponsor ? orderedSponsor.notes : '';
  }

  private findOrderedSponsor(sponsor: Sponsor): OrderedSponsor | undefined {
    return this.orderedSponsors.find(sp => sp.name === sponsor.name);
  }

  private findSelectedSponsorIndex(sponsor: Sponsor): number {
    return this.orderedSponsors.findIndex(sp => sp.name === sponsor.name);
  }
}
