<input id="datePickerInput"
       (keydown.enter)="onInputClicked($event)"
       (click)="onInputClicked($event)"
       [disabled]="disabled || isOpen"
       type="text"
       [textMask]="{mask: dateMask}"
       readonly
       class="form-control primary-bg-color primary-text-color"
       [ngClass]="{'margin-top-0 margin-bottom-0': noMargins,
                   'margin-top-10 margin-bottom-10': !noMargins}"
       [value]="formattedSelectedDay"
       [placeholder]="placeholder"
       aria-label="date">
<ngb-datepicker class="date-picker"
  *ngIf="isOpen"
  [ngModel]="theDay"
  [minDate]="minDayy"
  [markDisabled]="isDisabled"
  [dayTemplate]="closedTemplate"
  [firstDayOfWeek]="startOfWeek"
  (dateSelect)="onDaySelected($event)">
</ngb-datepicker>


<ng-template #closedTemplate let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
  <div [class.calendar-event]="isCalendarEventDay(date)" [class.focused]="focused"
        [class.hidden]="date.month !== currentMonth" [class.text-muted]="disabled || isStoreClosed(date)"
        triggers="hover"
        [ngbPopover]="isCalendarEventDay(date) ? getClosedEventDescription(date) : ''"
        class="btn-light calendar-day-default"
        popoverClass="calendar-date-popover arrow font-secondary">
    {{ date.day }}
  </div>
</ng-template>
