import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Animations } from '../../../animations';
import { Fundraiser } from 'ngx-web-api';

@Component({
  selector: 'fts-fundraiser-sponsor-entry',
  templateUrl: './fundraiser-sponsor-entry.component.html',
  styleUrls: ['./fundraiser-sponsor-entry.component.scss'],
  animations: [Animations.expanded],
})
export class FundraiserSponsorEntryComponent {
  @Input()
  sponsorName: string;
  @Input()
  sponsorNotes: string;
  @Input()
  sponsorSelected: boolean;
  @Input()
  fundraiser: Fundraiser;

  @Output()
  sponsorSelectionChanged: EventEmitter<boolean> = new EventEmitter();
  @Output()
  sponsorNotesChanged: EventEmitter<string> = new EventEmitter();

  maxLength = 50;

  get charactersLeft(): number {
    let result = this.maxLength;

    if (this.sponsorNotes) {
      result -= this.sponsorNotes.length;
    }

    return result;
  }

  onSponsorSelectionChanged(selected: boolean) {
    this.sponsorSelectionChanged.emit(selected);
  }

  onSponsorNotesChanged(notes: string) {
    this.sponsorNotesChanged.emit(notes);
  }
}
