import { Questionnaire } from './questionnaire.model';
import { BaseResource } from '../core/base-resource';
import { DeserializationUtils } from '../../utils/deserialization-utils';

export class RateableItem extends BaseResource {
  key: string;
  title: string;
  questionnaireName?: string;
  questionnaire?: Questionnaire;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      questionnaire: DeserializationUtils.deserializeChild(input, 'questionnaire', Questionnaire),
    });
  }
}
