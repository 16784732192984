import { GenericObject } from '../models/core/generic-object';
import { formatISO, isBefore, addDays, isSameDay, parseISO } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { timeZone } from '../models/globals';

// @dynamic
export class Utils {
  public static flatten(arr) {
    return [].concat.apply(
      [],
      arr.map(el => (Array.isArray(el) ? Utils.flatten(el) : el))
    );
  }

  public static serializePhone(value = ''): string {
    return value.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  public static isNullOrUndefined(value): boolean {
    return typeof value === 'undefined' || value === null;
  }

  public static distinct<T>(array: T[]): T[] {
    return Array.from(new Set(array));
  }

  /**
   * Shuffle an array using Fisher–Yates shuffle algorithm
   * @param array the array to shuffle
   * @returns the shuffled array
   */
  public static shuffle<T>(array: Array<T>): Array<T> {
    let counter = array.length;

    while (counter > 0) {
      const index = Math.floor(Math.random() * counter);
      counter--;
      [array[counter], array[index]] = [array[index], array[counter]];
    }

    return array;
  }

  public static concatUnique<T>(arrayA: T[], arrayB: T[]): T[] {
    return Utils.distinct(arrayA.concat(arrayB));
  }

  public static mergeGenericObjects(mapA?: GenericObject<string[]>, mapB?: GenericObject<string[]>): GenericObject<string[]> {
    if (!mapA && !!mapB) {
      return mapB;
    } else if (!mapB && !!mapA) {
      return mapA;
    } else if (!!mapA && !!mapB) {
      const keys: string[] = Utils.concatUnique(Object.keys(mapA), Object.keys(mapB));

      return keys
        .map(key => ({ [key]: Utils.concatUnique(mapA[key] || [], mapB[key] || []) }))
        .reduce((a, b) => Object.assign({}, a, b), {});
    }

    return {};
  }

  /**
   * @param date A date in string format (ISO)
   * @returns A Date object in the timezone specified by the TimeZoneService
   * If param {date} is empty then returns now with timezone applied
   * Otherwise parses the provided date string and returns with timezone applied also
   **/
  public static parseIsoDate(date: string): Date | undefined {
    return date ? Utils.getTzDate(parseISO(Utils.removeTzFromDate(date))) : undefined;
  }

  /**
   * @param date: A Javascript Date Object
   * @returns A date object applying the timezone set by TimeZoneService
   * If no date is provided current date/time is used
   */
  public static getTzDate(date?: Date): Date {
    return toDate(date || new Date(), { timeZone: timeZone });
  }

  /**
   * Determine whether two Objects or values are equal.
   * Will perform deep equality check for Objects and simple equality check for everything else.
   */
  public static deepEqual(x, y) {
    if (x && y && typeof x === 'object' && typeof x === typeof y) {
      return Object.keys(x).length === Object.keys(y).length && Object.keys(x).every(key => Utils.deepEqual(x[key], y[key]));
    }
    return x === y;
  }

  public static roundUp(date: string, numSeconds: number): string {
    numSeconds *= 1000;
    const ceiled = Math.ceil(Utils.parseIsoDate(date).getTime() / numSeconds) * numSeconds;
    return formatISO(ceiled);
  }

  /**
   * Returns all days within a provided range.
   *
   * @param from The starting day from which to count
   * @param to The ending day to which to count
   * @returns The days within the range [from, to].
   */
  public static getDates = (from: Date, to: Date) => {
    const dates = [];
    from = Utils.getTzDate(from);
    to = Utils.getTzDate(to);

    while (isBefore(from, to) || isSameDay(from, to)) {
      dates.push(from);
      from = addDays(from, 1);
    }
    return dates;
  };

  /**
   * Remove specified query parameters from the given URL
   */
  public static removeQueryParams(url: string, ...params: string[]) {
    let rtn = url.split('?')[0];
    let params_arr = [];
    const queryString = url.indexOf('?') !== -1 ? url.split('?')[1] : '';

    if (queryString !== '') {
      params_arr = queryString.split('&');
      for (let i = params_arr.length - 1; i >= 0; i -= 1) {
        const param = params_arr[i].split('=')[0];
        if (params.indexOf(param) >= 0) {
          params_arr.splice(i, 1);
        }
      }

      rtn += `?${params_arr.join('&')}`;
    }
    return rtn;
  }

  /**
   * Replace a given query param with a new value. Note that both param and its value should be provided already encoded.
   * @param url Source URL including the param subject to change
   * @param param The param that should be changed
   * @param value The new param for the value
   * @returns string The new URL with the replaced value
   */
  public static replaceQueryParam(url: string, param: string, value: string) {
    let urlWithoutParam = this.removeQueryParams(url, param);
    if ((!urlWithoutParam.endsWith('&') || !urlWithoutParam.endsWith('?')) && !urlWithoutParam.includes('?')) {
      urlWithoutParam += '?';
    }
    if (!urlWithoutParam.endsWith('?') && !urlWithoutParam.endsWith('&')) {
      urlWithoutParam += '&';
    }
    return `${urlWithoutParam}${param}=${value}`;
  }

  /**
   * Removes timezone information from a date string.
   * The purpose is to translate a server TZ date to the same date on the client
   * @param dateStr A date or time string in format e.g.     2020-06-24T06:56:56.000-0900
   * @returns a string without the timezone information e.g. 2020-06-24 06:56:56.000
   */
  public static removeTzFromDate(dateStr: string): string {
    if (!dateStr) {
      return null;
    }
    const splitParts = dateStr.replace('T', ' ').replace('Z', '').split('+')[0].split(' ');
    return splitParts.length > 1 ? `${splitParts[0]} ${splitParts[1].split('-')[0]}` : splitParts[0];
  }

  /**
   * Freezes an object recursively
   * Useful when you need to debug where an object property is mutated
   * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze#what_is_shallow_freeze
   */
  public static deepFreeze(object: any): any {
    const propNames = Object.getOwnPropertyNames(object);
    for (const name of propNames) {
      const value = object[name];
      if (value && typeof value === 'object') {
        this.deepFreeze(value);
      }
    }
    return Object.freeze(object);
  }
}
