import { Component, Input } from '@angular/core';
import { Order } from 'ngx-web-api';
import { faFtsCart } from '../../../shared/custom-fa-icons/faFtsCart';

@Component({
  selector: 'fts-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss'],
})
export class CartButtonComponent {
  @Input()
  order: Order;

  faFtsCart = faFtsCart;
}
