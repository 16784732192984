import { BasicModalContext } from './basic-modal-context';
import { Address } from 'ngx-web-api';

export class AddressModalContext extends BasicModalContext {
  constructor(public address: Address, public isEditing: boolean) {
    super();
    this.class = 'sm';
    this.config = { address, isEditing };
  }
}
