import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { StoreConfig } from '../../models/core/store-config.model';
import { IngredientChoice } from '../../models/menu/ingredient-choice.model';
import { OrderableIngredient } from '../../models/menu/orderable-ingredient.model';
import { OrderableItem } from '../../models/menu/orderable-item.model';
import { OrderableQualifier } from '../../models/menu/orderable-qualifier.model';
import { SizePrice } from '../../models/menu/size-price.model';
import { Utils } from '../../utils/utils';
import pluralize from 'pluralize';
import { ConfigService } from '../core/config.service';

@Injectable({ providedIn: 'root' })
export class OrderableItemService {
  private allowCustomerInstructions: boolean;

  constructor(private configService: ConfigService) {
    // Slice pluralization does not work properly and since it is something we will need we override it
    // Relates pluralize issue: https://github.com/blakeembrey/pluralize/issues/90
    pluralize.addSingularRule(/slice$/i, 'slice');
    pluralize.addPluralRule(/slice$/i, 'slices');

    this.configService.storeConfig$
      .pipe(map((c: StoreConfig) => c.allowCustomerInstructions))
      .subscribe(allowInstructions => (this.allowCustomerInstructions = allowInstructions));
  }

  public areDependenciesMet(orderableItem: OrderableItem, choice: IngredientChoice): boolean {
    const dependingChoice = orderableItem.choices.find(c => c.ingredients.some(i => i.name === choice.dependsOn));
    if (!dependingChoice) {
      return false;
    }

    const ingredient: OrderableIngredient | undefined = dependingChoice.ingredients.find(i => i.name === choice.dependsOn);
    if (!dependingChoice.dependsOn) {
      return !!ingredient && ingredient.isDefault;
    } else if (!ingredient || !ingredient.isDefault) {
      return false;
    } else {
      return this.areDependenciesMet(orderableItem, dependingChoice);
    }
  }

  /**
   * Determine whether customer instructions input can be provided for a given orderable item.
   * Checks the global preference for instructions and also the item's specific option
   * @param orderableItem the item to check whether instructions can be provided
   * @returns whether instructions can be provided
   */
  public shouldAskForInstructions(orderableItem: OrderableItem): boolean {
    return this.allowCustomerInstructions || (!!orderableItem && orderableItem.allowWebCustomerInstructions);
  }

  public getItemCalories(sizePrice: SizePrice): number | string | undefined {
    if (!sizePrice) {
      return;
    }

    const hasCaloriesFrom = !Utils.isNullOrUndefined(sizePrice.caloriesFrom);
    const hasCaloriesTo = !Utils.isNullOrUndefined(sizePrice.caloriesTo);

    if (hasCaloriesFrom && !hasCaloriesTo) {
      return sizePrice.caloriesFrom;
    }

    if (hasCaloriesFrom && hasCaloriesTo) {
      if (sizePrice.caloriesFrom === sizePrice.caloriesTo) {
        return sizePrice.caloriesFrom;
      } else {
        return `${sizePrice.caloriesFrom} - ${sizePrice.caloriesTo}`;
      }
    }

    return;
  }

  public getItemCaloriesPerServing(sizePrice: SizePrice): number | undefined {
    if (sizePrice) {
      const hasCaloriesFrom = !Utils.isNullOrUndefined(sizePrice.caloriesFrom);
      const hasCaloriesTo = !Utils.isNullOrUndefined(sizePrice.caloriesTo);

      if (
        ((hasCaloriesFrom && !hasCaloriesTo) || (hasCaloriesFrom && hasCaloriesTo && sizePrice.caloriesFrom === sizePrice.caloriesTo)) &&
        sizePrice.servings
      ) {
        return this.normalizeCalories(sizePrice.caloriesFrom / sizePrice.servings);
      }
    }
  }

  public getServings(sizePrice: SizePrice): string | undefined {
    if (sizePrice && sizePrice.servings && sizePrice.servingsLabel) {
      return `${sizePrice.servings} ${pluralize(sizePrice.servingsLabel, sizePrice.servings)}`;
    }
  }

  public getQualifierCalories(qualifier: OrderableQualifier, size: string, isHalf?: boolean): number | undefined {
    if (!qualifier || !size) {
      return;
    }

    return isHalf ? qualifier.halfSizeCaloriesMap[size] : qualifier.sizeCaloriesMap[size];
  }

  public getSizeOrDefault(item: OrderableItem, size?: string): SizePrice {
    let foundSize;
    const availableSizes = item.sizePrices.filter(s => !s.isUnavailable);
    if (size) {
      foundSize = availableSizes.find(s => s.size === size);
    }
    if (!foundSize) {
      foundSize = availableSizes.find(s => s.isWebDefault);
    }
    if (!foundSize) {
      foundSize = availableSizes[0];
    }
    return foundSize;
  }

  private normalizeCalories(calories: number): number {
    if (calories != null) {
      const cals = Math.abs(calories);
      const sign = calories < 0 ? -1 : 1;
      if (cals < 5) {
        return 0;
      } else if (cals <= 50) {
        return sign * Math.round(cals / 5.0) * 5;
      } else {
        return sign * Math.round(cals / 10.0) * 10;
      }
    }
    return null;
  }
}
