<a *ngIf="(breakPointService.isTabletDown$ | async) || minifiedHeader"
   class="btn btn-link phone-number text-smaller text-underline"
   [ngClass]="isInHome ? 'btn-undecorated btn-lg btn-block text-center font-primary secondary-text-color' : 'primary-text-color font-secondary no-padding'"
   href="tel:{{phone}}">
   {{phone}}
</a>

<a *ngIf="!(breakPointService.isTabletDown$ | async) && !minifiedHeader"
   class="font-primary secondary-text-color margin-left-10 phone-number"
   href="tel:{{phone}}">
  {{phone}}
</a>
