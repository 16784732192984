import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Invitation } from '../../models/core/invitation.model';

@Injectable({ providedIn: 'root' })
export class InvitationService {
  readonly INVITATION_PATH = '/ws/integrated/v1/ordering/invitation';

  constructor(private http: HttpClient) {}

  public invite(invitation: Invitation): Observable<void> {
    return this.http.post(this.INVITATION_PATH, invitation).pipe(map(() => undefined));
  }
}
