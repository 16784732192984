import { BaseResource } from '../core/base-resource';
import { OrderableSpecialDefaultItem } from './orderable-special-default-item.model';
import { DeserializationUtils } from '../../utils/public_api';

export class OrderableSpecialDefaultChoice extends BaseResource {
  name: string;
  color: string;
  defaults: OrderableSpecialDefaultItem[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      defaults: DeserializationUtils.deserializeChildren(input, 'defaults', OrderableSpecialDefaultItem),
    });
  }
}
