<h3 class="text-center" *ngIf="featuredContent && featuredContent.title && !fillParentHeight">{{featuredContent.title}}</h3>
<div class="featured-content" [ngSwitch]="featuredContent?.style" [ngClass]="{'cover' : fillParentHeight}">
  <fts-featured-content-list
    *ngSwitchCase="style.LIST"
    [showVertically]="showVertically"
    [featuredItems]="featuredItems"
    [fillParentHeight]='fillParentHeight'
    [skipLazyLoading]="skipLazyLoading"
    [itemImgHeight]="itemImgHeight"
    (chosenFeaturedItem)="handleChosenFeaturedItem($event)"
    (loadImageHeight)="onLoadImageFeaturedItemHeight($event)">
  </fts-featured-content-list>
  <fts-featured-content-fading
    *ngSwitchCase="style.FADING"
    [featuredItems]="featuredItems"
    [speed]="featuredContent?.speed"
    [fillParentHeight]='fillParentHeight'
    [skipLazyLoading]="skipLazyLoading"
    [itemImgHeight]="itemImgHeight"
    (chosenFeaturedItem)="handleChosenFeaturedItem($event)"
    (loadImageHeight)="onLoadImageFeaturedItemHeight($event)">
  </fts-featured-content-fading>
  <fts-featured-content-scrolling
    *ngSwitchCase="style.SCROLLING"
    [featuredItems]='featuredItems'
    [speed]="featuredContent?.speed"
    [fillParentHeight]='fillParentHeight'
    [skipLazyLoading]="skipLazyLoading"
    [itemImgHeight]="itemImgHeight"
    (chosenFeaturedItem)="handleChosenFeaturedItem($event)"
    (loadImageHeight)="onLoadImageFeaturedItemHeight($event)">
  </fts-featured-content-scrolling>
</div>
