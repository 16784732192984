import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class FtsErrorReporterService {
  constructor(private http: HttpClient, private injector: Injector) {}

  public report(error: any): void {
    const currentUrl = this.injector.get(Router).url;
    this.http.post('/ws/integrated/v1/ordering/errors', { stack: error.stack, route: currentUrl }).subscribe(
      () => {},
      () => {}
    );
  }
}
