import { Order } from 'ngx-web-api';
import { BasicModalContext } from './basic-modal-context';

export class OrderWarningsModalContext extends BasicModalContext {
  constructor(public order: Order) {
    super();
    this.class = 'sm';
    this.config = { order };
  }
}
