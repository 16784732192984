import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { Account } from '../../models/account/account.model';
import { RecentlyOrderedItem } from '../../models/menu/recently-ordered-item.model';
import { AccountService } from '../account/account.service';
import { DeserializationUtils } from '../../utils/deserialization-utils';

@Injectable({ providedIn: 'root' })
export class RecentlyOrderedItemsService {
  private recentlyOrderedItems: BehaviorSubject<RecentlyOrderedItem[]> = new BehaviorSubject([]);
  public recentlyOrderedItems$: Observable<RecentlyOrderedItem[]> = this.recentlyOrderedItems.asObservable();

  constructor(private http: HttpClient, private accountService: AccountService) {}

  public getRecentlyOrderedItems(): Observable<RecentlyOrderedItem[]> {
    return this.accountService.account$.pipe(
      take(1),
      mergeMap((account: Account) => {
        if (account.isInstantiated) {
          return this.http.get('/ws/integrated/v1/ordering/account/mostOrderedItems').pipe(
            map((json: Object[]) => DeserializationUtils.deserializeArray(json, RecentlyOrderedItem)),
            catchError(() => of([]))
          );
        } else {
          return of([]);
        }
      }),
      tap((items: RecentlyOrderedItem[]) => this.recentlyOrderedItems.next(items))
    );
  }
}
