import { EventEmitter } from '@angular/core';
import { WebSocketConfig } from '../../models/core/web-socket-config.model';
import { WebSocketErrorMsg } from '../../models/core/web-socket-error-msg.model';
import { WebSocketMessageType } from '../../models/core/web-socket-message-type.enum';
import { WebSocketMessage } from '../../models/core/web-socket-message.model';
import { Utils } from '../../utils/utils';

class TopicSubscriptions {
  [topic: string]: (msg: WebSocketMessage) => void;
}

export class WebSocketConnection {
  private websocket: WebSocket;
  private subscriptions = new TopicSubscriptions();
  private closing: boolean;
  private heartBeateTimeout: any;
  retryConnect = new EventEmitter();
  errorMessage = new EventEmitter<WebSocketErrorMsg>();

  // factory
  static create(config: WebSocketConfig): Promise<WebSocketConnection> {
    const promiseHandler = resolve => {
      const instance = new WebSocketConnection(config);
      instance
        .open()
        .then(() => instance.registerHandlers())
        .then(() => instance.initHeartBeats())
        .then(() => resolve(instance))
        .catch(e => instance.handleError(e));
    };
    return new Promise(promiseHandler);
  }

  private constructor(private config: WebSocketConfig) {}

  hasSubsription(topic: string): boolean {
    return !!this.subscriptions[topic];
  }

  subscribe(topic: string, messageHandler: (m: WebSocketMessage) => void) {
    this.log(`Subscribing websocket connection ${this.config.url} to topic ${topic}`);
    this.send(new WebSocketMessage().deserialize({ type: WebSocketMessageType.SUBSCRIBE, topic }));
    this.subscriptions[topic] = messageHandler;
  }

  unsubscribe(topic: string) {
    this.log(`Unsubcribing websocket connection ${this.config.url} from topic ${topic}`);
    this.send(new WebSocketMessage().deserialize({ type: WebSocketMessageType.UNSUBSCRIBE, topic }));
    delete this.subscriptions[topic];
  }

  close() {
    this.log(`Closing websocket connection to ${this.config.url}.`);
    this.closing = true;
    this.stopHeartBeats();
    this.websocket.close();
  }

  send(msg: WebSocketMessage) {
    this.websocket.send(JSON.stringify(msg));
  }

  private initHeartBeats() {
    if (!this.config.hasHeartbeats) {
      return;
    }
    this.stopHeartBeats();
    this.sendHeartBeats();
  }

  private stopHeartBeats() {
    if (!Utils.isNullOrUndefined(this.heartBeateTimeout)) {
      clearTimeout(this.heartBeateTimeout);
    }
  }

  private sendHeartBeats() {
    const msg = new WebSocketMessage();
    msg.type = WebSocketMessageType.HEARTBEAT;
    this.send(msg);

    this.heartBeateTimeout = setTimeout(() => this.sendHeartBeats(), this.config.heartbeatInterval);
  }

  private registerHandlers() {
    this.websocket.onmessage = event => this.handleMessage(event.data);

    this.websocket.onerror = e => {
      this.log(`Error on websocket connection to ${this.config.url}`);
      this.handleError(e);
    };

    this.websocket.onclose = () => {
      if (this.closing) {
        return;
      }
      this.log(`Closed websocket connection to ${this.config.url}. Trying to reconnect.`);
      this.stopHeartBeats();
      this.retryConnect.emit();
      this.timeout(this.config.retryInterval)
        .then(() => this.open())
        .then(() => this.registerHandlers())
        .then(() => this.initHeartBeats())
        .then(() => this.resubscribe());
    };
  }

  private open(): Promise<any> {
    const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
    const host = this.config.host || window.location.host;
    const webSocketUrl = `${protocol}://${host}${this.config.url}`;

    return new Promise<void>((resolve: any) => {
      this.log(`Trying to open websocket connection to ${webSocketUrl}`);

      this.websocket = new WebSocket(webSocketUrl);
      this.websocket.onopen = () => resolve();

      this.websocket.onerror = e => {
        this.log(`Error when initializing websocket to ${this.config.url}`);
        this.log('Keeping retrying to connect');
        this.retryConnect.emit();
        this.handleError(e);
        this.timeout(this.config.retryInterval).then(() => resolve(this.open()));
      };
    });
  }

  private resubscribe() {
    Object.keys(this.subscriptions).forEach(topic => this.subscribe(topic, this.subscriptions[topic]));
  }

  private handleMessage(data: string) {
    const msg = <WebSocketMessage>JSON.parse(data);
    if (msg['errorCode']) {
      this.handleError(msg);
      this.errorMessage.emit(msg as WebSocketErrorMsg);
    }
    const messageHandler = this.subscriptions[msg.topic];
    if (messageHandler) {
      messageHandler(msg);
    }
  }

  private timeout(millis): Promise<any> {
    return new Promise(resolve => {
      setTimeout(resolve, millis);
    });
  }

  private handleError(e) {
    console.error(e);
  }

  private log(m) {
    console.log(m);
  }
}
