<div class='modal-content primary-bg-color primary-text-color text-center'>
  <div *ngIf="title" class='modal-header secondary-bg-color secondary-text-color' [ngClass]="headerBackgroundClass">
    <h2 id="modal-title" class='modal-title'>{{title}}</h2>
    <button class='close' (click)='handleDismiss()' [attr.aria-label]="'component.confirmation_modal.close' | translate">
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class="modal-body primary-bg-color">
    <div *ngIf="!!mainContent" class="main-content">
        <div *ngIf="!textOnly" [innerHTML]="mainContent"></div>
        <div *ngIf="textOnly" class="fw-500">{{mainContent}}</div>
    </div>

    <div *ngIf="hasSubmitBtn || hasDismissBtn" class="modal-buttons" [ngClass]="{'reverse': reverseButtons}">
      <button *ngIf="hasSubmitBtn"
              ftsAsyncButton
              [subscription]='loadingSubscription'
              (click)="handleSubmit()"
              class="btn font-secondary action-btn"
              [ngClass]="reverseButtons ? 'selection-bg-color selection-inverse-text-color' : 'muted-bg-color primary-text-color'">
        {{submitBtnTitle}}
      </button>
      <button *ngIf="hasDismissBtn"
              class="btn font-secondary dismiss-btn"
              (click)='handleDismiss()'
              [ngClass]="reverseButtons ? 'muted-bg-color primary-text-color' : 'selection-bg-color selection-inverse-text-color'">
        {{dismissBtnTitle}}
      </button>
    </div>
  </div>
</div>
