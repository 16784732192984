import { DeserializationUtils } from '../../utils/deserialization-utils';
import { Charity } from '../core/charity.model';
import { ValidatedResource } from '../core/validated-resource';

export class AccountCharity extends ValidatedResource {
  name: string;
  thisYearTotal: number;
  lastYearTotal: number;
  charity: Charity;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      charity: DeserializationUtils.deserializeChild(input, 'charity', Charity),
    });
  }
}
