import { Deserializable } from './deserializable';
import { Link } from './link.model';
import { Serializable } from './serializable';

export abstract class BaseResource implements Deserializable, Serializable {
  links: Link[];
  [key: string]: any;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  serialize(): any {
    return this;
  }

  copy() {
    return new (<any>this.constructor)().deserialize(this);
  }
}
