import { Directive, HostListener, Input } from '@angular/core';
import { AuditService } from 'ngx-web-api';

@Directive({
  selector: '[ftsAuditableButton]',
})
export class AuditableButtonDirective {
  @Input()
  auditName: string;
  @Input()
  auditMessage: string;

  constructor(private auditService: AuditService) {}

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    this.auditService.createAudit(
      () => this.auditMessage || `${this.auditName || `"${btn.textContent.trim().replace(/\s{2,}/g, ' ')}"`} button clicked`
    );
  }
}
