import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, OnDestroy } from '@angular/core';
import {
  GroupOrder,
  GroupOrderService,
  OrderService,
  Utils,
  TimezoneService,
  Audit,
  AuditService,
  GenericObject,
  GroupOrderInvitation,
  GroupOrderFriend,
  ConfigService,
  StoreConfig,
  AccountService,
} from 'ngx-web-api';
import { Observable, Subscription, Subject } from 'rxjs';
import { ErrorsService } from '../../core/services/errors.service';
import { InvitationDialogConfig } from '../../domain/invitation-dialog-config';
import { mergeMap, takeUntil, map } from 'rxjs/operators';
import { faCalendar, faTimes, faClock } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { formatISO, setHours, setMinutes, addMinutes, getDate, getMonth, getYear } from 'date-fns';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { utcToZonedTime, format } from 'date-fns-tz';
import { DateFormatPipe } from 'ngx-web-api/lib/pipes/date-format.pipe';
import { faCaretDown, faCaretRight, faCheck, faCopy } from '@fortawesome/pro-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { collapse } from 'app/animations';

@Component({
  selector: 'fts-grouporder-invitation',
  templateUrl: './grouporder-invitation.component.html',
  styleUrls: ['./grouporder-invitation.component.scss'],
  animations: [collapse()],
})
export class GrouporderInvitationComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly DEFAULT_GROUP_ORDER_EXPIRATION_TIME = 30;

  @Output()
  done = new EventEmitter();
  @Input()
  groupOrder: GroupOrder;
  @Input()
  groupOrderingFriends: GroupOrderFriend[];
  @Input()
  invitationDialogConfig: InvitationDialogConfig;
  @Input()
  changeExpirationMode = false;
  @Input()
  inviteeUrl: string;

  public groupOrderingInvitation = new GroupOrderInvitation();
  newFriend = new GroupOrderFriend();
  isNewInvitation: boolean;
  public expirationTime: NgbTimeStruct;

  errors?: GenericObject<string[]>;
  message?: string;
  public invitationErrors?: GenericObject<string[]>;
  public invitationMessage?: string;
  faTimes = faTimes;
  faCalendar = faCalendar;
  faClock = faClock;
  addingFriend: Subscription;
  invitingFriends: Subscription;

  hoursDiffWithLocal = 0;
  minutesDiffWithLocal = 0;

  theExpirationDay: NgbDateStruct;
  dateFormat = new DateFormatPipe();
  dateWithTime: string;
  minuteStep = 5;
  userEmail: String;

  deletingFriendSubscription: Subscription;
  get deletingFriend(): boolean {
    return this.deletingFriendSubscription && !this.deletingFriendSubscription.closed;
  }

  public showDatePicker: boolean;

  public showTimePicker: boolean;
  private destroy$: Subject<void> = new Subject<void>();
  futureDateFormat: string;
  copiedGroupOrderInviteLink = false;
  faCopy = faCopy;
  faCheck = faCheck;
  storeConfig: StoreConfig;
  groupOrderInviteWithLinkSection = false;
  showInviteBtn = false;
  selectedFriendsList: GroupOrderFriend[] = [];
  loading: boolean;
  expandedSharedLinkPanel = true;
  expandedEmailPanel = true;
  faCaretRight = faCaretRight;
  faCaretDown = faCaretDown;

  constructor(
    private groupOrderService: GroupOrderService,
    private errorService: ErrorsService,
    private auditService: AuditService,
    private orderService: OrderService,
    private translateService: TranslateService,
    private tzService: TimezoneService,
    private configService: ConfigService,
    private accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  @HostListener('click', ['$event'])
  handleClickOnDialog(event) {
    if (this.showDatePicker && !event.target.closest('.expiration-date')) {
      this.showDatePicker = false;
    }

    if (this.showTimePicker && !event.target.closest('.expiration-time')) {
      this.showTimePicker = false;
    }
  }

  ngOnInit() {
    this.isNewInvitation = !this.groupOrder;

    this.expirationTime = !!this.groupOrder
      ? this.ngbTimeFormat(Utils.parseIsoDate(this.groupOrder.expirationDate))
      : this.ngbTimeFormat(utcToZonedTime(new Date(), this.tzService.getTimeZone()));

    this.accountService.account$.pipe(takeUntil(this.destroy$)).subscribe(account => (this.userEmail = account.email));

    if (!this.isNewInvitation) {
      this.groupOrderingInvitation.expirationDate = this.groupOrder.expirationDate;
      this.initTheExpirationDay();
      this.flagInvitedFriends(this.groupOrder);
    } else {
      this.expirationTime = this.ngbTimeFormat(
        addMinutes(
          utcToZonedTime(new Date(), this.tzService.getTimeZone()),
          this.invitationDialogConfig.defaultGroupOrderExpiration <= 0
            ? this.DEFAULT_GROUP_ORDER_EXPIRATION_TIME
            : this.invitationDialogConfig.defaultGroupOrderExpiration
        )
      );
      this.groupOrderingInvitation.expirationDate = formatISO(Utils.getTzDate());
      this.initTheExpirationDay();
    }
    this.onTimeChange(this.expirationTime);
    this.configService.storeConfig$.pipe(takeUntil(this.destroy$)).subscribe((cfg: StoreConfig) => {
      this.storeConfig = cfg;
      this.futureDateFormat = cfg.country === 'IE' ? 'EEE dd/MM/yyy' : 'EEE MM/dd/yyy';
    });
  }

  ngAfterViewInit() {
    // applying theming on timepicker
    this.applyThemeOnTimepicker();

    this.groupOrderingFriends.forEach(friend => this.handleShowInviteButton(friend));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleAddFriend() {
    this.errors = undefined;
    this.message = '';

    this.newFriend._selected = true;
    this.addingFriend = this.groupOrderService.addFriend(this.newFriend).subscribe(
      () => {
        this.handleShowInviteButton(this.newFriend);
        this.groupOrderingFriends.push(this.newFriend);
        this.newFriend = new GroupOrderFriend();
      },
      e => this.handleError(e, { message: this.translateService.instant('component.group_order_invitation.added_fail') })
    );
  }

  handleDeleteFriend(friend: GroupOrderFriend) {
    this.deletingFriendSubscription = this.groupOrderService.deleteFriend(friend).subscribe(
      () => {
        friend._selected = false;
        this.handleShowInviteButton(friend);
        const idx = this.groupOrderingFriends.findIndex(f => f.email === friend.email);
        this.groupOrderingFriends.splice(idx, 1);
      },
      e => this.handleError(e, { message: this.translateService.instant('component.group_order_invitation.remove_fail') })
    );
  }

  handleSelectAll() {
    this.groupOrderingFriends
      .filter(f => !f._isInvited && f.email !== this.userEmail)
      .filter(f => !this.selectedFriendsList.find(sf => sf.email === f.email))
      .forEach(f => {
        f._selected = true;
        this.handleShowInviteButton(f);
      });
  }

  handleUnselectAll() {
    this.groupOrderingFriends
      .filter(f => !f._isInvited)
      .forEach(f => {
        f._selected = false;
        this.handleShowInviteButton(f);
      });
  }

  handleSubmit() {
    let invitationTask$: Observable<GroupOrder>;

    if (this.changeExpirationMode) {
      this.applyExpirationTime();
      invitationTask$ = this.groupOrderService.changeExpirationDate(this.groupOrderingInvitation.expirationDate);
    } else {
      this.groupOrderingInvitation.friends = this.groupOrderingFriends.filter(f => f._selected);

      if (this.isNewInvitation) {
        this.loading = true;
        this.applyExpirationTime();
        invitationTask$ = this.groupOrderService.inviteFriends(this.groupOrderingInvitation);
      } else {
        invitationTask$ = this.groupOrderService.addFriendsToGroupOrder(this.groupOrderingInvitation);
      }
    }

    this.invitingFriends = invitationTask$
      .pipe(
        mergeMap((groupOrder: GroupOrder) => {
          return this.orderService.fetchOrder().pipe(map(_order => groupOrder));
        })
      )
      .subscribe(
        groupOrder => {
          this.loading = false;
          this.groupOrder = groupOrder;
          if (this.isNewInvitation) {
            this.isNewInvitation = false;
            return;
          }
          this.deleteGroupOrderParams();
          this.done.emit();
        },
        e => {
          this.loading = false;
          this.groupOrderInviteWithLinkSection = false;
          setTimeout(() => this.applyThemeOnTimepicker());
          this.handleInvitationError(e);
        }
      );
  }

  handleCancel() {
    this.deleteGroupOrderParams();
    this.done.emit();
  }

  toggleDatePicker(day?: any) {
    if (day) {
      const myDate = new Date(day.year, day.month - 1, day.day);
      this.groupOrderingInvitation.expirationDate = formatISO(Utils.getTzDate(myDate));
    }
    this.initTheExpirationDay();
    this.showDatePicker = !this.showDatePicker;
  }

  toggleTimePicker() {
    this.showTimePicker = !this.showTimePicker;
  }

  hideTimePicker() {
    this.showTimePicker = false;
  }
  ngbDateFormat(date): NgbDateStruct {
    const dd = getDate(date);
    const mm = getMonth(date) + 1;
    const yyyy = getYear(date);
    return { year: yyyy, month: mm, day: dd };
  }

  ngbTimeFormat(time): NgbTimeStruct {
    const hh = time.getHours();
    const mm = time.getMinutes();
    return { hour: hh, minute: mm, second: null };
  }

  onTimeChange(time: NgbTimeStruct) {
    if (time) {
      time.minute = Math.ceil(time.minute / 5) * 5;
      let expirationDate = Utils.parseIsoDate(this.groupOrderingInvitation.expirationDate);
      expirationDate = setHours(expirationDate, time.hour);
      expirationDate = setMinutes(expirationDate, time.minute);
      this.dateWithTime = formatISO(expirationDate);
    }
  }

  onClickGroupOrderInviteLink() {
    navigator.clipboard
      .writeText(this.groupOrder.inviteeUrl)
      .catch(() => {
        this.invitationMessage = this.translateService.instant('component.group_order_invitation.invite_link_error');
      })
      .finally(() => (this.copiedGroupOrderInviteLink = true));
    setTimeout(() => (this.copiedGroupOrderInviteLink = false), 2000);
  }

  handleNext() {
    this.clearErrors();

    if (!this.groupOrderInviteWithLinkSection) {
      this.groupOrderInviteWithLinkSection = true;
      this.handleSubmit();
    }
  }

  handleShowInviteButton(friend: GroupOrderFriend) {
    if (friend._selected) {
      this.selectedFriendsList.push(friend);
      this.showInviteBtn = true;
    } else {
      let existFriendToList = this.selectedFriendsList.find(f => f.name == friend.name);
      if (existFriendToList) {
        this.selectedFriendsList = this.selectedFriendsList.filter(f => f !== friend);
        if (this.selectedFriendsList.length == 0) {
          this.showInviteBtn = false;
        } else {
          this.showInviteBtn = true;
        }
      } else {
        this.showInviteBtn = false;
      }
    }
  }

  auditSharedLinkPanelExpansion() {
    this.auditService.createAudit(() => `By Link panel ${this.expandedSharedLinkPanel ? 'expanded' : 'collapsed'}`);
  }

  auditEmailLinkPanelExpansion() {
    this.auditService.createAudit(() => `By Email panel ${this.expandedEmailPanel ? 'expanded' : 'collapsed'}`);
  }

  private initTheExpirationDay() {
    this.theExpirationDay = this.ngbDateFormat(Utils.parseIsoDate(this.groupOrderingInvitation.expirationDate));
  }

  private applyExpirationTime() {
    if (!this.expirationTime) {
      this.invitationErrors = {
        expirationTime: [this.translateService.instant('component.group_order_invitation.choose_expiration')],
      };
      return;
    }

    if (!this.groupOrderingInvitation.expirationDate) {
      return;
    }

    let expirationDate = Utils.parseIsoDate(this.groupOrderingInvitation.expirationDate);
    const expirationTime = this.expirationTime;
    expirationDate = setHours(expirationDate, expirationTime.hour);
    expirationDate = setMinutes(expirationDate, expirationTime.minute);
    this.groupOrderingInvitation.expirationDate = format(expirationDate, `yyyy-MM-dd'T'HH:mm:ssXXX`, {
      timeZone: this.tzService.getTimeZone(),
    });
  }

  private flagInvitedFriends(g: GroupOrder) {
    this.groupOrderingFriends
      .filter(f => g.groupMembers.findIndex(m => m.email === f.email && m.name === f.name && f.email !== this.userEmail) > -1)
      .forEach(f => {
        f._isInvited = true;
        f._selected = true;
      });
  }

  private handleInvitationError(e) {
    const parsed = this.errorService.parseErrorResponse(e);
    this.invitationErrors = parsed.errors;
    this.invitationMessage = parsed.message;
    this.auditService.createAudit(() => {
      const details = parsed.message ? Object.assign({ message: parsed.message }, { errors: parsed.errors }) : { errors: parsed.errors };
      return { message: this.translateService.instant('component.group_order_invitation.go_invitation_errors'), details };
    });
  }

  private handleError(e, action: Audit) {
    const parsed = this.errorService.parseErrorResponse(e);
    this.errors = parsed.errors;
    this.message = parsed.message;
    this.auditService.createAudit(() => {
      action.details = parsed.message ? Object.assign({ message: parsed.message }, { errors: parsed.errors }) : { errors: parsed.errors };
      return action;
    });
  }

  private deleteGroupOrderParams() {
    const params = this.router.parseUrl(this.router.url).queryParams;
    delete params['makeItAGroupOrder'];
    delete params['target'];
    this.location.go(
      this.router
        .createUrlTree([], {
          relativeTo: this.activatedRoute,
          queryParams: params,
        })
        .toString()
    );
  }

  private applyThemeOnTimepicker() {
    const timepicker = document.getElementsByTagName('ngb-timepicker')[0];
    if (!!timepicker) {
      timepicker.classList.add('primary-bg-color');
      Array.from(timepicker.getElementsByTagName('input')).forEach(element =>
        element.classList.add('primary-bg-color', 'primary-text-color', 'width-inh')
      );
      Array.from(timepicker.getElementsByClassName('ngb-tp-chevron')).forEach(element => element.classList.add('selection-text-color'));
      Array.from(timepicker.getElementsByClassName('ngb-tp-meridian')).forEach(element => element.classList.add('selection-bg-color'));
      Array.from(timepicker.getElementsByClassName('ngb-tp-meridian')).forEach(element =>
        element.classList.add('selection-inverse-text-color')
      );
      Array.from(timepicker.getElementsByClassName('ngb-tp-meridian')).forEach(element => element.classList.add('borderless'));
      Array.from(timepicker.getElementsByTagName('button')).forEach(element => element.classList.add('borderless'));
      Array.from(timepicker.getElementsByTagName('button')).forEach(element => element.classList.add('selection-inverse-text-color'));
      Array.from(timepicker.getElementsByTagName('button')).forEach(element => element.classList.remove('btn-outline-primary'));
      timepicker.querySelectorAll(`input`)[0].setAttribute('aria-label', 'hours');
      timepicker.querySelectorAll(`input`)[1].setAttribute('aria-label', 'minutes');
    }
  }

  private clearErrors() {
    this.invitationErrors = undefined;
    this.invitationMessage = undefined;
    this.errors = undefined;
    this.message = undefined;
  }
}
