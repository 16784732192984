import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';

import { Upsell } from './upsell.model';

export class NotificationUpsellGroup extends BaseResource {
  title: string;
  notificationItems: Upsell[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      notificationItems: DeserializationUtils.deserializeChild(input, 'notificationItems', Upsell),
    });
  }
}
