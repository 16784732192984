import { BaseResource } from '../core/base-resource';
import { CouponErrorType } from './coupon-error-type.enum';

export class OrderableCoupon extends BaseResource {
  name: string;
  isCoupon: boolean;
  price: number;
  percentOff: number;
  instructions: string;
  disclaimer?: string;
  errorType?: CouponErrorType;
  error?: string;
}
