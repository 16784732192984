import { BrowserStorageHandlerService } from './../core/services/browser-storage-handler.service';
import { Injectable } from '@angular/core';
import { MenuTabGroupLayout } from './domain/menu-tab-group-layout.enum';
import { ViewportService } from '../core/services/viewport.service';
import { ThemeService } from '../core/services/theme.service';
import { Theme } from '../domain/theme.model';
import { combineLatest } from 'rxjs';
import { FeaturedContentName, FeaturedContentStyle } from 'ngx-web-api';

export interface ItemsPerLine {
  treeAndMenuTop: number;
  treeTop: number;
  treeRight: number;
}

@Injectable({
  providedIn: 'root',
})
export class GhostHeightsService {
  private clientViewport: 'Desktop' | 'Tablet' | 'Mobile' | 'MobileCompact';
  private theme: Theme;
  private PANEL = {
    [MenuTabGroupLayout.CARD]: {
      Desktop: {
        height: 613,
        heading: 76,
        paddings: 20,
        itemsPerLine: {
          treeAndMenuTop: 4,
          treeTop: 3,
          treeRight: 2,
        },
      },
      Tablet: {
        height: 617,
        heading: 76,
        paddings: 20,
        itemsPerLine: {
          treeAndMenuTop: 3,
          treeTop: 3,
          treeRight: 2,
        },
      },
      Mobile: {
        height: 228,
        heading: 48,
        paddings: 20,
        itemsPerLine: {
          treeAndMenuTop: 1,
          treeTop: 1,
          treeRight: 1,
        },
      },
      MobileCompact: {
        height: 301,
        heading: 48,
        paddings: 20,
        itemsPerLine: {
          treeAndMenuTop: 2,
          treeTop: 2,
          treeRight: 2,
        },
      },
    },
    [MenuTabGroupLayout.SLIDING_CARD]: {
      Desktop: {
        height: 615,
        heading: 76,
        paddings: 0,
        itemsPerLine: Infinity,
      },
      Tablet: {
        height: 661,
        heading: 76,
        paddings: 0,
        itemsPerLine: Infinity,
      },
      Mobile: {
        height: 608,
        heading: 48,
        paddings: 0,
        itemsPerLine: Infinity,
      },
      MobileCompact: {
        height: 608,
        heading: 48,
        paddings: 0,
        itemsPerLine: Infinity,
      },
    },
    [MenuTabGroupLayout.LIST]: {
      Desktop: {
        height: 264,
        heading: 76,
        paddings: 0,
        itemsPerLine: 1,
      },
      Tablet: {
        height: 197,
        heading: 76,
        paddings: 0,
        itemsPerLine: 1,
      },
      Mobile: {
        height: 150,
        heading: 48,
        paddings: 0,
        itemsPerLine: 1,
      },
      MobileCompact: {
        height: 150,
        heading: 48,
        paddings: 0,
        itemsPerLine: 1,
      },
    },
    [MenuTabGroupLayout.FLAT]: {
      Desktop: {
        height: 264,
        heading: 76,
        paddings: 0,
        itemsPerLine: 1,
      },
      Tablet: {
        height: 314,
        heading: 76,
        paddings: 0,
        itemsPerLine: 1,
      },
      Mobile: {
        height: 575,
        heading: 48,
        paddings: 0,
        itemsPerLine: 1,
      },
      MobileCompact: {
        height: 575,
        heading: 48,
        paddings: 0,
        itemsPerLine: 1,
      },
    },
    [MenuTabGroupLayout.FLAT_WITH_CALORIES]: {
      Desktop: {
        height: 264,
        heading: 76,
        paddings: 0,
        itemsPerLine: 1,
      },
      Tablet: {
        height: 314,
        heading: 76,
        paddings: 0,
        itemsPerLine: 1,
      },
      Mobile: {
        height: 575,
        heading: 48,
        paddings: 0,
        itemsPerLine: 1,
      },
      MobileCompact: {
        height: 575,
        heading: 48,
        paddings: 0,
        itemsPerLine: 1,
      },
    },
  };
  private STORE_LOGO = {
    Desktop: {
      height: 79,
    },
    Tablet: {
      height: 79,
    },
    Mobile: {
      height: 79,
    },
    MobileCompact: {
      height: 79,
    },
  };

  constructor(
    private viewportService: ViewportService,
    private themeService: ThemeService,
    private browserStorageHandlerService: BrowserStorageHandlerService
  ) {
    combineLatest([this.viewportService.viewport$, this.themeService.theme]).subscribe(([v, t]) => {
      if (v.isMobile) {
        this.clientViewport = t.compactCardItems ? 'MobileCompact' : 'Mobile';
      } else {
        this.clientViewport = v.isTablet ? 'Tablet' : 'Desktop';
      }
      if (!this.theme) {
        this.theme = t;
      }
    });
  }

  getGhostPanelHeight(tabLayout: MenuTabGroupLayout, itemCount: number, panelName: string): number {
    const cachedHeight = this.browserStorageHandlerService.getLocalStorageItem(
      `PANEL_${tabLayout}_${this.clientViewport}_${encodeURIComponent(panelName)}`
    );
    if (!!cachedHeight && !Number.isNaN(cachedHeight as any)) {
      return parseInt(cachedHeight, 10) || 500;
    }
    const layoutData = this.PANEL[tabLayout][this.clientViewport];
    const lineHeight = layoutData.height + layoutData.paddings;
    if (typeof layoutData.itemsPerLine === 'object') {
      return lineHeight * Math.ceil(itemCount / this.getRenderedItemRowCount(layoutData.itemsPerLine));
    } else if (layoutData.itemsPerLine === Infinity) {
      return lineHeight;
    }
    return lineHeight * Math.floor(itemCount / layoutData.itemsPerLine);
  }

  setCachedPanelHeight(tabLayout: MenuTabGroupLayout, height: number, panelName: string) {
    if (!height || !panelName) {
      return;
    }
    this.browserStorageHandlerService.setLocalStorageItem(
      `PANEL_${tabLayout}_${this.clientViewport}_${encodeURIComponent(panelName)}`,
      `${Math.round(height)}`
    );
  }

  getItemImageHeight(tabLayout: MenuTabGroupLayout, noControls = false): number {
    const cached = this.browserStorageHandlerService.getLocalStorageItem(
      `ITEM_${tabLayout}_${noControls ? 'no_controls' : ''}_${this.clientViewport}`
    );
    return parseInt(cached, 10) || null;
  }

  setItemImageHeight(tabLayout: MenuTabGroupLayout, height: number, noControls = false) {
    if (!height) {
      return;
    }
    this.browserStorageHandlerService.setLocalStorageItem(
      `ITEM_${tabLayout}_${noControls ? 'no_controls' : ''}_${this.clientViewport}`,
      `${height}`
    );
  }

  getFeaturedItemImageHeight(area: FeaturedContentName, style: FeaturedContentStyle, keyName: string): number {
    if (!area || !style || !keyName) {
      return null;
    }
    const cached = this.browserStorageHandlerService.getLocalStorageItem(`FEATURED_${area}_${style}_${keyName}_${this.clientViewport}`);
    return parseInt(cached, 10) || null;
  }

  setFeaturedItemImageHeight(area: FeaturedContentName, style: FeaturedContentStyle, keyName: string, height: number) {
    if (!style || !height || !keyName) {
      return;
    }
    this.browserStorageHandlerService.setLocalStorageItem(`FEATURED_${area}_${style}_${keyName}_${this.clientViewport}`, `${height}`);
  }

  getLogoImageHeight(): number {
    const cached = this.browserStorageHandlerService.getLocalStorageItem(`STORE_LOGO_${this.clientViewport}`);
    if (!!cached) {
      return parseInt(cached, 10) || null;
    }
    const storeLogoData = this.STORE_LOGO[this.clientViewport];
    return storeLogoData.height;
  }

  setLogoImageHeight(height: number) {
    if (!height) {
      return;
    }
    this.browserStorageHandlerService.setLocalStorageItem(`STORE_LOGO_${this.clientViewport}`, `${height}`);
  }

  getLoyaltyBuilderImageHeight(): number {
    const cached = localStorage?.getItem(`LOYALTY_BUILDER_IMAGE_${this.clientViewport}`);
    if (!!cached) {
      return parseInt(cached, 10) || null;
    }
    return 250;
  }

  setLoyaltyBuilderImageHeight(height: number) {
    if (!height) {
      return;
    }
    localStorage?.setItem(`LOYALTY_BUILDER_IMAGE_${this.clientViewport}`, `${height}`);
  }

  private getRenderedItemRowCount(itemsPerLine: ItemsPerLine) {
    const topOrderTree = this.theme.hasTopOrderTree || this.theme.minifiedHeader;
    if (topOrderTree && this.theme.hasTopMenu) {
      return itemsPerLine.treeAndMenuTop;
    }
    if (topOrderTree) {
      return itemsPerLine.treeTop;
    }
    return itemsPerLine.treeRight;
  }
}
