import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from 'ngx-web-api';
import { MenuWrapperService } from 'app/core/services/menu-wrapper.service';
import { Router, NavigationExtras } from '@angular/router';
import { CwoService } from 'app/core/services/cwo.service';

@Component({
  selector: 'fts-track-order-btn',
  templateUrl: './track-order-btn.component.html',
  styleUrls: ['./track-order-btn.component.scss'],
})
export class TrackOrderBtnComponent implements OnInit {
  orderId: string;

  constructor(
    private cookieService: CookieService,
    public menuWrapperService: MenuWrapperService,
    private router: Router,
    private cwoService: CwoService
  ) {}
  @Input()
  fontWeight = false;
  @Input()
  btnUndecorated = false;
  @Input()
  minifiedHeader = false;

  ngOnInit() {
    this.orderId = this.cookieService.getCookie(`OrderID__${this.cwoService.storeName}`);
  }

  handleTrackerLinkClick() {
    this.orderId = this.cookieService.getCookie(`OrderID__${this.cwoService.storeName}`);
    const navigationExtras: NavigationExtras = {
      queryParams: { id: this.orderId },
    };
    this.router.navigate(['/tracker'], navigationExtras);
  }
}
