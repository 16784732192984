import { ValidatedResource } from '../core/validated-resource';
import { OrderedItem } from './ordered-item.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';

export class OrderedItemGroup extends ValidatedResource {
  orderedItems: OrderedItem[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      orderedItems: DeserializationUtils.deserializeChildren(input, 'orderedItems', OrderedItem),
    });
  }

  serialize(): any {
    return {
      orderedItems: (this.orderedItems || []).map(item => item.serialize()),
    };
  }
}
