import { LocalStorageKeys } from './../../domain/local-storage-keys.enum';
import { BrowserStorageHandlerService } from './../services/browser-storage-handler.service';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { StoreConfig, PrivacyPolicyAcknowledgment } from 'ngx-web-api';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { InitParamsStorageService } from '../services/init-params-storage.service';

@Component({
  selector: 'fts-policy-alert',
  templateUrl: './policy-alert.component.html',
  styleUrls: ['./policy-alert.component.scss'],
})
export class PolicyAlertComponent {
  @Input()
  store: StoreConfig;
  @ViewChild('policyRef', { static: false })
  policyRef: ElementRef;

  public embedded: boolean;
  public policyAcknowledged = false;
  public privacyPolicyAck = PrivacyPolicyAcknowledgment;
  public faTimes = faTimes;

  constructor(private initParamsStorage: InitParamsStorageService, private browserStorageHandlerService: BrowserStorageHandlerService) {
    this.policyAcknowledged = this.browserStorageHandlerService.getLocalStorageItem(LocalStorageKeys.PolicyConsent) === 'true';
    this.embedded = this.initParamsStorage.initParams.embedded;
  }

  policyDismiss() {
    this.browserStorageHandlerService.setLocalStorageItem(LocalStorageKeys.PolicyConsent, 'true');
    this.policyAcknowledged = true;
  }
}
