import { SessionStorageKeys } from './../../domain/session-storage-keys.enum';
import { BrowserStorageHandlerService } from './browser-storage-handler.service';
import { Injectable } from '@angular/core';
import { Address } from 'ngx-web-api';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressSessionStorageService {
  private useStoredAddress: Subject<void> = new Subject();
  public useStoredAddress$: Observable<void> = this.useStoredAddress.asObservable();

  constructor(private browserStorageHandlerService: BrowserStorageHandlerService) {}

  public setAddress(address: Address): void {
    this.browserStorageHandlerService.setSessionStorageItem(SessionStorageKeys.addressStorage, JSON.stringify(address));
  }

  public getAddress(): Address {
    return JSON.parse(this.browserStorageHandlerService.getSessionStorageItem(SessionStorageKeys.addressStorage));
  }

  public deleteAddress(): void {
    this.browserStorageHandlerService.setSessionStorageItem(SessionStorageKeys.addressStorage);
  }

  public storeAddress() {
    this.useStoredAddress.next();
  }
}
