import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Panel, Tab, TagTab, RecentlyOrderedItemsService, RecentlyOrderedItem } from 'ngx-web-api';
import { Theme } from 'app/domain/theme.model';
import { ThemeService } from 'app/core/services/theme.service';
import { BreakpointService } from 'app/core/services/breakpoint.service';
import { MenuWrapperService } from 'app/core/services/menu-wrapper.service';
import { Subject } from 'rxjs';
import { SlugifyPipe } from 'app/shared/pipes/slugify.pipe';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';

@Component({
  selector: 'fts-menu-index',
  templateUrl: './menu-index.component.html',
  styleUrls: ['./menu-index.component.scss'],
})
export class MenuIndexComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  panel: Panel;
  @Input()
  isInHeader = false;

  theme: Theme;
  panelTabs: (Tab | TagTab)[] = [];
  hasRecentlyOrderedItems: boolean;
  isTabletDown: boolean;
  showTabIcons: boolean;
  private destroy$: Subject<void> = new Subject<void>();
  hasBeenInitialized = false;
  recentlyOrderedLabel = 'RecentlyOrdered';

  tabLinksMap: { [key: string]: string } = {};
  private slugifyPipe = new SlugifyPipe();

  constructor(
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private recentlyOrderedItemsService: RecentlyOrderedItemsService,
    private breakPointService: BreakpointService,
    private router: Router,
    private menuService: MenuWrapperService,
    private uiOrchestrationService: UiOrchestratorService,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.recentlyOrderedItemsService.recentlyOrderedItems$
      .pipe(map((recentlyOrderedItems: RecentlyOrderedItem[]) => recentlyOrderedItems && recentlyOrderedItems.length > 0))
      .subscribe((hasRecentlyOrderedItems: boolean) => (this.hasRecentlyOrderedItems = hasRecentlyOrderedItems));

    this.activatedRoute.data.pipe(filter(d => !!d['panel'])).subscribe(data => {
      this.panel = data['panel'];
      if (!!this.panel) {
        this.initPanelTabs();
        this.initTabLinks();
      }
    });

    this.themeService.theme.subscribe(theme => {
      this.theme = theme;
      this.initTabLinks();
    });
  }

  ngOnInit() {
    this.breakPointService.isTabletDown$.pipe(takeUntil(this.destroy$)).subscribe(isTabletDown => {
      if (isTabletDown) {
        this.isTabletDown = isTabletDown;
      } else {
        this.navigateToFirstPanelName();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['panel']) {
      this.initPanelTabs();
      this.initTabLinks();
    }
  }

  ngAfterViewInit() {
    this.hasBeenInitialized = true;
    this.uiOrchestrationService.setMinifiedMenuFullyRendered(this.hasBeenInitialized);

    if (this.theme.hasSinglePageMenu && this.theme.shouldShowCategoriesUpfront) {
      setTimeout(() => {
        this.unlistener = this.renderer.listen(document, 'click', event => {
          if (!this.elRef.nativeElement.contains(event.target)) {
            this.unlistener();
            this.uiOrchestrationService.setSinglePageMobileUpfrontMenu(false);
          }
        });
      }, 100);
    }
  }

  ngOnDestroy(): void {
    this.uiOrchestrationService.setMinifiedMenuFullyRendered(false);
    this.unlistener();
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToFirstPanelName() {
    this.menuService
      .getFirstPanelItemName()
      .pipe(
        mergeMap(name => {
          return this.router.navigate(['/menu', name]);
        })
      )
      .subscribe();
  }

  onTabClick() {
    if (this.theme.hasSinglePageMenu && this.theme.shouldShowCategoriesUpfront) {
      this.unlistener();
      this.uiOrchestrationService.setSinglePageMobileUpfrontMenu(false);
    }
  }

  private initTabLinks() {
    if (this.theme) {
      if (this.theme.hasSinglePageMenu) {
        this.panelTabs.forEach(panelTab => {
          this.tabLinksMap[panelTab.name] = `menu/single-page#${this.slugifyPipe.transform(panelTab.name)}`;
        });
        this.recentlyOrderedLabel = 'Recently Ordered';
      } else {
        this.panelTabs.forEach(panelTab => {
          this.tabLinksMap[panelTab.name] = `menu/${encodeURIComponent(panelTab.name)}`;
        });
      }
      this.tabLinksMap[this.recentlyOrderedLabel] = `menu/${this.theme.hasSinglePageMenu ? 'single-page#' : ''}${
        this.theme.hasSinglePageMenu ? this.slugifyPipe.transform(this.recentlyOrderedLabel) : this.recentlyOrderedLabel
      }`;
    }
  }

  private initPanelTabs() {
    this.showTabIcons =
      this.panel && this.panel.tabs && (this.panel.tabs.some(tab => !!tab.image) || this.panel.tagTabs.some(tagTab => !!tagTab.image));
    this.panelTabs = [...(this.panel.tagTabs || []), ...(this.panel.tabs || [])];
  }

  private unlistener = () => {};
}
