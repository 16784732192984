import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HighlightElementsService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  highLightAlertDanger(scrollToView = true) {
    this.getAlertDangerElements().forEach((el: HTMLElement, index) => this.applyFlashAnimation(el, index, false, scrollToView));
  }

  highLightDataFlashable(addAlertClasses = false, scrollToView = true) {
    this.getDataFlashElements().forEach((el: HTMLElement, index) => this.applyFlashAnimation(el, index, addAlertClasses, scrollToView));
  }

  highLightAll(addAlertClasses = false, scrollToView = true) {
    [...this.getAlertDangerElements(), ...this.getDataFlashElements()].forEach((el: HTMLElement, index) =>
      this.applyFlashAnimation(el, index, addAlertClasses, scrollToView)
    );
  }

  private getAlertDangerElements(): any[] {
    return Array.from(this.document.querySelectorAll(`.alert.alert-danger`));
  }

  private getDataFlashElements(): any[] {
    return Array.from(this.document.querySelectorAll(`[data-flash]`));
  }

  private applyFlashAnimation(el: HTMLElement, index: number, alert = false, scrollToView = true) {
    if (index === 0 && scrollToView) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      el.setAttribute('role', 'alert');
    }
    if (el.classList.contains('fts-flash')) {
      return;
    }
    const hasAlert = el.classList.contains('alert-danger');
    const list = alert && !hasAlert ? ['fts-flash', 'alert', 'alert-danger', 'p-0', 'm-0'] : ['fts-flash'];
    el.classList.add(...list);
    setTimeout(() => el.classList.remove(...list), 3000);
  }
}
