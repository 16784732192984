import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddressType } from '../../models/core/address-type.model';
import { AddressValidationResult } from '../../models/core/address-validation-result.model';
import { Address } from '../../models/core/address.model';
import { Street } from '../../models/core/street.model';
import { CityZip } from '../../models/core/city-zip.model';
import { AddressResponse } from 'ngx-web-api/lib/models/core/address-response';
import { AddressResult } from 'ngx-web-api/lib/models/core/address-result';

@Injectable({ providedIn: 'root' })
export class AddressService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering';

  constructor(private http: HttpClient) {}

  public getAddressTypes(): Observable<AddressType[]> {
    return this.http
      .get(`${this.BASE_PATH}/config/addressTypes`)
      .pipe(map((json: Object[]) => (json || []).map(at => new AddressType().deserialize(at))));
  }

  public getStreetMatches(input: string, includeBuildingName = false): Observable<Street[]> {
    const params: HttpParams = new HttpParams().set('q', input).set('includeBuildingName', includeBuildingName);

    return this.http
      .get(`${this.BASE_PATH}/streets`, { params })
      .pipe(map((json: Object[]): Street[] => (json || []).map(s => new Street().deserialize(s))));
  }

  public getCities(): Observable<CityZip[]> {
    return this.http
      .get(`${this.BASE_PATH}/cities`)
      .pipe(map((json: Object[]): CityZip[] => (json || []).map(s => new CityZip().deserialize(s))));
  }

  public getBuildings(buildingType: string): Observable<Street[]> {
    const params: HttpParams = new HttpParams().set('type', buildingType);

    return this.http
      .get(`${this.BASE_PATH}/buildings`, { params })
      .pipe(map((json: Object[]): Street[] => (json || []).map(s => new Street().deserialize(s))));
  }

  public findAddress(lat: any, lng: string): Observable<Street> {
    const params: HttpParams = new HttpParams().set('lat', lat).set('lng', lng);

    return this.http
      .get<AddressResponse>(`${this.BASE_PATH}/findAddress`, { params })
      .pipe(
        map(
          (json: AddressResponse) =>
            (json.results || []).map((addressResult: AddressResult) =>
              new Street().deserialize({
                street: addressResult.addressParts.street,
                city: addressResult.addressParts.city,
                state: addressResult.addressParts.state,
                zip: addressResult.addressParts.zip,
                streetNum: addressResult.addressParts.streetNumber,
              })
            )[0]
        )
      );
  }

  /** @deprecated Please use {@link AccountAddressesService.validateNewAddress} */
  public validateNewAddress(address: Address): Observable<AddressValidationResult> {
    return this.http.post<AddressValidationResult>(`${this.BASE_PATH}/account/validate/address`, address);
  }

  /** @deprecated Please use {@link AccountAddressesService.validateExistingAddress} */
  public validateExistingAddress(address: Address): Observable<AddressValidationResult> {
    return this.http.put<AddressValidationResult>(`${this.BASE_PATH}/account/validate/address`, address);
  }
}
