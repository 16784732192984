import { Directive, AfterViewInit, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[ftsAriaForm]',
})
export class AriaFormDirective implements AfterViewInit {
  @Input()
  ftsAriaForm: FormGroup;

  constructor(private host: ElementRef) {}

  ngAfterViewInit(): void {
    Array.from(document.querySelectorAll('.required .form-control')).forEach(item => item.setAttribute('aria-live', 'polite'));
  }

  @HostListener('submit')
  onSubmit() {
    if (!this.ftsAriaForm.valid) {
      this.focusInFirstInvalidField();
      return;
    }
  }

  private focusInFirstInvalidField() {
    const invalidField = this.host.nativeElement.querySelector('.ng-invalid');

    if (invalidField) {
      invalidField.setAttribute('tabindex', '0');
      invalidField.focus();
    }
  }
}
