<div #mainApp id="ftsRootAppWrapper" class="main-application" [ngClass]="{'tiled-menu': theme.shouldShowTiledMenu && (viewportService.viewport$ | async).isMobile}">
  <fts-policy-alert [store]="storeConfig$ | async"></fts-policy-alert>
  <ng-container *ngIf="(bannerFeaturedContent$ | async) as featuredContent">
    <fts-featured-content
      [fillParentHeight]='false'
      [allowAddingItemsToOrder]='true'
      [featuredContent]='featuredContent'
      [hidden]="(showUpfrontOrTiled$ | async)"
    ></fts-featured-content>
  </ng-container>

  <fts-header
    *ngIf="!isInNotFound"
    ftsSticky [stackingOrder]="0"
    [topMargin]="((orderTreeIsOpen$ | async) || theme?.compactHeader || theme?.minifiedHeader || (showUpfrontOrTiled$ | async)) ? 0 : -(bannerHeight$ | async)"
    [isInIntro]="isInIntro"
    [isInHome]="isInHome"
    [isInEditor]="isInEditor"
    [isInPay]="isInPay$ | async"
    (logoHeightChange)="setLogoHeight($event)"
    (notificationsHeightChange)="setNotificationsHeight($event)"
    [isInPrintReceipt]="isInPrintReceipt$ | async">
  </fts-header>

  <main [ngClass]="{
      'no-overflow-x' : theme.hasSinglePageMenu && !theme.hasTopOrderTree && !theme.minifiedHeader,
      'overlay': (theme.hasTopOrderTree || theme.minifiedHeader) && theme.hasTopMenu && isInMenu && !isInEditor,
      'tiled-menu': theme.shouldShowTiledMenu
      }">
    <h1 class="sr-only">{{title}}</h1>
    <div id="mainContent">
      <router-outlet></router-outlet>
      <fts-loading-indicator *ngIf="theme.hasSinglePageMenu && isInMenu && !isInTiledMenuIndex && !(menuTabsRendered$ | async) && !(isInSearch$ | async)" [topPadding]="true"></fts-loading-indicator>
      <fts-loading-indicator *ngIf="(loadingRoute && (isInPay$ | async)) || loadingLoginProcess" [cover]="true"></fts-loading-indicator>
    </div>
  </main>

  <fts-footer class="main-footer" *ngIf="!isInNotFound" [isInPay]="isInPay$ | async" [ngClass]="{'d-none': theme.shouldShowTiledMenu && (isInTiledMenuIndex$ | async)}" [isInPrintReceipt]="isInPrintReceipt$ | async"></fts-footer>
</div>
