<div class='modal-content primary-bg-color primary-text-color'>
  <div class='modal-header secondary-bg-color secondary-text-color'>
    <h2 id="modal-title" class='modal-title float-left'>{{special?.type}} {{'component.special_instructions_modal.details' | translate}}</h2>
    <button class='close float-right' (click)='hideModal()' [attr.aria-label]="'component.special_instructions_modal.close' | translate">
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body text-left'>
    <p class="lead fw-600">{{special?.name}}</p>
    <p *ngIf="special?.instructions" class="fw-500">{{special?.instructions}}</p>
    <p *ngIf="special?.disclaimer" class="fw-500">{{special?.disclaimer}}</p>
  </div>
</div>
