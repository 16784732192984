<div>
  <fts-hamburger-button (click)="toggleSidebarMenu()"></fts-hamburger-button>

  <ng-container *ngIf="sidebarMenuVisible">
    <div class="overlay" (click)="toggleSidebarMenu()"></div>

    <fts-vertical-menu-links
      class="sidebar-menu"
      [isInIntro]="isInIntro"
      [account]="account"
      [store]="store"
      [loyaltyPlan]="loyaltyPlan"
      [order]="order"
      [isInCheckoutDetails]="isInCheckoutDetails"
      [showChangeStoreBtn]="!isInCheckout && (store.hasStoreChooser || (order?.isInitialized && hasMultipleOrderTypeConfigs))"
      [isSelfOrder]="isSelfOrder"
      [minifiedHeader]="true"
      (login)="login.emit()"
      (logout)="logout.emit()"
      (changeStore)="toggleStoreChooser()">
    </fts-vertical-menu-links>
  </ng-container>
</div>
