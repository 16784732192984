import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslationResource } from '../../models/core/translation-resource';
import { map, catchError } from 'rxjs/operators';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private readonly BASE_PATH: string = '/ws/integrated/v1/ordering/translations';
  private currentLanguage: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public currentLanguage$: Observable<string> = this.currentLanguage.asObservable();

  constructor(private http: HttpClient) {}

  public getAvailableTranslations(flavor?: string): Observable<TranslationResource[]> {
    let params: HttpParams = new HttpParams();
    if (!!flavor) {
      params = params.set('flavor', flavor);
    }
    return this.http
      .get<TranslationResource[]>(`${this.BASE_PATH}/languages`, { params })
      .pipe(map(json => DeserializationUtils.deserializeArray(json, TranslationResource)));
  }

  public getTranslation(language?: string, flavor?: string): Observable<any | undefined> {
    let params: HttpParams = new HttpParams();
    if (!!language) {
      params = params.set('language', language);
    }
    if (!!flavor) {
      params = params.set('flavor', flavor);
    }
    return this.http.get(this.BASE_PATH, { params }).pipe(catchError(() => of(undefined)));
  }

  public setCurrentLanguage(langName: string): void {
    this.currentLanguage.next(langName);
  }
}
