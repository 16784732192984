"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsCart";
var width = 466;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "M 228.31702,0 C 173.95491,0 128.49999,40.528346 121.58117,92.913657 H 23.725511 C 10.876284,92.913657 0,103.78993 0,116.63916 V 488.27449 C 0,501.12373 10.876284,512 23.725511,512 H 441.80802 c 12.84922,0 23.7255,-10.87627 23.7255,-23.72551 h 0.98455 V 116.63916 c 0,-12.84923 -10.85699,-23.725503 -23.70621,-23.725503 H 336.05671 C 328.1495,40.528346 283.66368,0 229.30156,0 Z m -0.98454,48.435563 h 0.98454 c 27.67525,0 51.40077,18.775779 58.31959,45.462635 H 169.01289 c 6.91882,-26.686856 30.64434,-45.462635 58.31959,-45.462635 z m 109.70876,91.909807 h 81.06057 V 464.54898 H 47.451022 V 141.34922 h 72.141768 v 29.65206 c 0,12.84922 10.87628,23.70621 23.72551,23.70621 12.84922,0 23.72552,-10.85699 23.72552,-23.70621 v -29.65206 h 121.56187 v 29.65206 c 0,12.84922 10.87628,23.70621 23.7255,23.70621 12.84923,0 23.72552,-10.85699 23.72552,-23.70621 h 0.98453 z M 88.956188,353.85566 c -12.849228,0 -22.740969,10.86085 -22.740969,22.72167 0,13.83763 10.880144,23.72551 22.740969,23.72551 H 371.63532 c 12.84923,0 22.74098,-10.88014 22.74098,-22.74096 v -0.98455 c 0,-11.86082 -10.88015,-21.73327 -22.74098,-22.72167 z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.faFtsCart = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
