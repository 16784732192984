import { BasicModalContext } from './basic-modal-context';
import { Fundraiser, OrderedFundraiser, OrderedSponsor } from 'ngx-web-api';

export class FundraiserSponsorsModalContext extends BasicModalContext {
  constructor(
    public fundraiser: Fundraiser,
    public orderedFundraiser?: OrderedFundraiser,
    public errorFromSponsorParam?: any,
    public orderedSponsorsFromParam?: OrderedSponsor[]
  ) {
    super();
    this.config = { fundraiser, orderedFundraiser, errorFromSponsorParam, orderedSponsorsFromParam };
  }
}
