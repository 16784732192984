import { ValidatedResource } from '../core/validated-resource';
import { Sponsor } from './sponsor.model';

export class OrderedSponsor extends ValidatedResource {
  name: string;
  notes: string;

  static fromSponsor(sponsor: Sponsor): OrderedSponsor {
    return new OrderedSponsor().deserialize({
      name: sponsor.name,
      notes: '',
    });
  }
}
