import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { Picture } from './picture.model';
import { SizePrice } from './size-price.model';
import { Tag } from './tag.model';

export class OrderableSpecialStepAlternativeItem extends BaseResource {
  label: string;
  labelSymbol: string;
  description: string;
  sizePrices: SizePrice[];
  pictures: Picture[];
  itemWithIngredientChoices: boolean;
  itemWithRequiredIngredientChoices: boolean;
  showFullSizeCalories: boolean;
  selectedSize: string | undefined;
  tags: Tag[];

  get availableSizePrices(): SizePrice[] {
    return (this.sizePrices || []).filter(sizePrice => !sizePrice.isUnavailable);
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      sizePrices: DeserializationUtils.deserializeChildren(input, 'sizePrices', SizePrice),
      tags: DeserializationUtils.deserializeChildren(input, 'tags', Tag),
    });
  }
}
