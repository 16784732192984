import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HypermediaService,
  MenuItemsLayout,
  OrderableItem,
  OrderedItem,
  OrderedItemService,
  OrderType,
  UpsellGroup,
  UpsellWrapper,
  DeserializationUtils,
  OrderableIngredient,
  Utils,
  UpsellType,
} from 'ngx-web-api';
import { ModalService } from '../../core/services/modal.service';
import { Observable, of, Subject, EMPTY, ReplaySubject, noop } from 'rxjs';
import { map, mergeMap, tap, shareReplay, filter } from 'rxjs/operators';

import { UpsellSpecialWizardModalContext } from '../../domain/upsell-special-wizard-modal-context';
import { UpsellSpecialModalComponent } from '../../shared/upsell-special-modal/upsell-special-modal.component';
import { ItemEditorService } from './item-editor.service';
import { SpecialWizardModalComponent } from '../../shared/special-wizard-modal/special-wizard-modal.component';
import { SpecialWizardModalContext } from '../../domain/special-wizard-modal-context';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EditingItemContext } from 'app/domain/editing-item-context';
import { ItemUpsellMap } from 'app/domain/item-upsell-map';
import { OrderUpsellWrapper } from 'app/domain/order-upsell-wrapper';
import { GTMService } from './gtm.service';
import { EcommerceTriggeringArea } from '../../domain/ecommerce-triggering-area.enum';
import { EcommerceTriggeringPoint } from '../../domain/ecommerce-triggering-point.enum';

@Injectable({
  providedIn: 'root',
})
export class OrderUpsellService {
  readonly BASE_PATH = '/ws/integrated/v1/ordering/order';
  addedUpsell: boolean;

  private addedOrderableItem: Subject<OrderableItem> = new Subject();
  public addedOrderableItem$: Observable<OrderableItem> = this.addedOrderableItem.asObservable();

  private itemUpsellMap: ReplaySubject<ItemUpsellMap> = new ReplaySubject();
  public itemUpsellMap$: Observable<ItemUpsellMap> = this.itemUpsellMap.asObservable();

  private addedOrderableItems: OrderableItem[] = [];
  public addedOrderableItems$: Observable<OrderableItem[]> = of(this.addedOrderableItems);

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private router: Router,
    private itemEditorService: ItemEditorService,
    private hypermedia: HypermediaService,
    private orderedItemService: OrderedItemService,
    private gtmService: GTMService
  ) {
    this.orderedItemService.deletedItem$
      .pipe(
        map(item => ({ category: item.category, name: item.item })),
        map(itemId => this.addedOrderableItems.findIndex(i => Utils.deepEqual({ category: i.category, name: i.name }, itemId))),
        filter(removedOrderableIndex => removedOrderableIndex > -1)
      )
      .subscribe(removedOrderableIndex => this.addedOrderableItems.splice(removedOrderableIndex, 1), noop);
  }

  setAddedOrderableItem(orderableItem: OrderableItem) {
    this.addedOrderableItem.next(orderableItem);
    this.pushAddedOrderableItem(orderableItem);
  }

  pushAddedOrderableItem(orderableItem: OrderableItem) {
    this.addedOrderableItems.push(orderableItem);
  }

  fetchOrderUpsells(orderType?: OrderType, date?: string): Observable<OrderUpsellWrapper> {
    const params: HttpParams = new HttpParams();

    if (orderType) {
      params.set('orderType', orderType);
    }

    if (date) {
      params.set('date', date);
    }
    return this.http
      .get<OrderUpsellWrapper>(`${this.BASE_PATH}/compactUpsells`, { params })
      .pipe(
        map((response: OrderUpsellWrapper) => ({
          orderBasedUpsells: DeserializationUtils.deserializeArray(response.orderBasedUpsells, UpsellGroup),
          itemUpsells: response.itemUpsells,
        })),
        tap((extUpsells: OrderUpsellWrapper) => {
          this.itemUpsellMap.next(extUpsells.itemUpsells);
        })
      );
  }

  fetchUpsellGroups(orderType?: OrderType, date?: string): Observable<UpsellGroup[]> {
    const params: HttpParams = new HttpParams();

    if (orderType) {
      params.set('orderType', orderType);
    }

    if (date) {
      params.set('date', date);
    }
    return this.http
      .get(`${this.BASE_PATH}/upsells`, { params })
      .pipe(map((response: Object[]) => DeserializationUtils.deserializeArray(response, UpsellGroup)));
  }

  /**
   * Adds an item to the order checking for combo upsells first.
   * The item can be a plain orderable or a 'recently ordered'
   *
   * @param orderedItem The ordered item to add to order
   * @param orderableItem The orderable item definition of the ordered item
   * @param skipNavigation Skip the navigation to any page after the item addition.
   * This is needed for most ordered items or card layout items
   * @param menuItemsLayout The initiated item's layout
   * @param itemId The 'Recently Ordered' items are added by this id.
   * @returns {Observable<R>}
   */
  addToOrderOrUpsell(
    orderedItem: OrderedItem,
    orderableItem: OrderableItem,
    skipNavigation?: boolean,
    menuItemsLayout?: MenuItemsLayout,
    itemId?: string,
    selectedUpfrontIngredient?: OrderableIngredient,
    unsatisfiedIngredientChoiceName?: string,
    selectedUpfrontQualifier?: string[],
    upsellType?: UpsellType,
    force?: boolean
  ): Observable<OrderedItem | null> {
    return this.getUpsells(orderableItem, orderedItem, false).pipe(
      mergeMap((upsellWrappers: UpsellWrapper[]) => {
        orderedItem.quantity = orderedItem.quantity < orderableItem?.minimumQuantity ? orderableItem.minimumQuantity : orderedItem.quantity;
        const upsellSpecialWrappers = upsellWrappers.filter(upsellWrapper => !!upsellWrapper.special);

        if (upsellSpecialWrappers) {
          const matchedUpsellWrapper = upsellSpecialWrappers.find(upsellWrapper => upsellWrapper.triggeringSize === orderedItem.size);

          if (matchedUpsellWrapper) {
            this.showUpsellSpecialWizard(matchedUpsellWrapper, orderableItem, menuItemsLayout, orderedItem, itemId, skipNavigation, {
              selectedUpfrontIngredient: selectedUpfrontIngredient,
              selectedUpfrontQualifier: selectedUpfrontQualifier,
              unsatisfiedIngredientChoiceName: unsatisfiedIngredientChoiceName,
            });
          } else if (skipNavigation && itemId) {
            return this.orderedItemService.reorderItem(itemId, force).pipe(
              tap(_ => this.setAddedOrderableItem(orderableItem)),
              tap((item: OrderedItem) => this.orderedItemService.setAddedItem(item)),
              tap((item: OrderedItem) =>
                this.gtmService.pushAddToCartEvent({
                  items: [item],
                  triggeringArea: EcommerceTriggeringArea.RECENTLY_ORDERED,
                  triggeringPoint: EcommerceTriggeringPoint.ORDER_BUTTON,
                })
              )
            );
          } else {
            if (skipNavigation) {
              return this.orderedItemService.addItemToOrder(orderedItem).pipe(
                tap((item: OrderedItem) =>
                  this.gtmService.pushAddToCartEvent({
                    items: [item],
                    triggeringArea: orderedItem.isUpsell ? EcommerceTriggeringArea.UPSELL : EcommerceTriggeringArea.MENU,
                    triggeringPoint: upsellType || EcommerceTriggeringPoint.ORDER_BUTTON,
                  })
                )
              );
            } else {
              this.itemEditorService.editOrderableItem(
                orderableItem,
                orderedItem.size,
                orderedItem,
                menuItemsLayout,
                orderedItem.quantity,
                '',
                '',
                selectedUpfrontIngredient,
                unsatisfiedIngredientChoiceName,
                selectedUpfrontQualifier
              );
              this.router.navigate(['/editor', 'add-to-order']);
            }
          }
        }

        return of(null);
      })
    );
  }

  public getUpsells(
    orderableItem: OrderableItem,
    orderedItem: OrderedItem,
    withSisterUpsells: boolean = true
  ): Observable<UpsellWrapper[]> {
    let linkToUpsell = this.hypermedia.findLink(orderableItem.links, 'upsells');
    let link = new URL(linkToUpsell.href);
    link.searchParams.set('sizeName', orderedItem.size);
    link.searchParams.set('withSisterUpsells', withSisterUpsells.toString());
    linkToUpsell.href = link.href;

    return this.hypermedia.get(orderableItem.links, 'upsells').pipe(
      map((json: Object[]) => DeserializationUtils.deserializeArray(json, UpsellWrapper)),
      shareReplay(1)
    );
  }

  public getOrderableFromOrdered(orderedItem: OrderedItem): Observable<OrderableItem> {
    return this.addedOrderableItems$.pipe(
      mergeMap(addedOrderables => {
        const matchingOrderable = addedOrderables.find(i => {
          return !!Utils.deepEqual({ category: i.category, name: i.name }, { category: orderedItem.category, name: orderedItem.item });
        });
        if (!!matchingOrderable) {
          return of(matchingOrderable);
        }
        return this.hypermedia.get(orderedItem.links, 'orderableItem').pipe(map(json => new OrderableItem().deserialize(json)));
      })
    );
  }

  private showUpsellSpecialWizard(
    upsellWrapper: UpsellWrapper,
    orderableItem: OrderableItem,
    menuItemsLayout?: MenuItemsLayout,
    orderedItem?: OrderedItem,
    itemId?: string,
    skipNavigation?: boolean,
    editingItemContext?: EditingItemContext
  ) {
    if (orderedItem) {
      orderedItem.quantity = 1;
    }
    const context = new UpsellSpecialWizardModalContext(
      upsellWrapper,
      orderableItem,
      menuItemsLayout,
      orderedItem,
      itemId,
      skipNavigation,
      editingItemContext
    ).getConfig();
    this.modalService
      .openModal(UpsellSpecialModalComponent, context)
      .pipe(
        mergeMap(specialWizard => {
          if (!!specialWizard) {
            const special = new SpecialWizardModalContext(specialWizard);
            return this.modalService.openModal(SpecialWizardModalComponent, special.getConfig());
          }
          return EMPTY;
        })
      )
      .subscribe(() => {});
  }
}
