import { AutoReplyType } from './auto-reply-type.enum';
import { BaseResource } from './base-resource';

export class AutoReply extends BaseResource {
  readonly type: AutoReplyType;
  readonly couponInstruction: string;
  readonly description: string;
  readonly reward: string;
  readonly webImage: string;
}
