import { Utils } from './../../../ngx-web-api/lib/utils/utils';
import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Account,
  AccountService,
  Address,
  AddressValidationResult,
  AuditService,
  GenericObject,
  Order,
  OrderService,
  OrderType,
  OrderTypeConfig,
  StoreConfig,
  StoreStatusService,
  OrderTimeInfo,
  Street,
  StoreStatus,
  ConfigService,
  DeferChoice,
  FeaturedContentService,
  FeaturedContentName,
  GroupOrderService,
} from 'ngx-web-api';
import { ModalService } from '../../core/services/modal.service';
import { mergeMap, tap, take, catchError, map, distinctUntilChanged, takeUntil, switchMap } from 'rxjs/operators';
import { Subscription, of, Observable, Subject, from, noop, combineLatest } from 'rxjs';

import { Animations } from '../../animations';
import { AddressWrapperService } from '../../core/services/address-wrapper.service';
import { ErrorsService } from '../../core/services/errors.service';
import { InitParamsStorageService } from '../../core/services/init-params-storage.service';
import { MenuWrapperService } from '../../core/services/menu-wrapper.service';
import { ParamsOrchestratorService } from '../../core/services/params-orchestrator.service';
import { ThemeService } from '../../core/services/theme.service';
import { AddressConfirmationModalContext } from '../../domain/address-confirmation-modal-context';
import { Theme } from '../../domain/theme.model';
import { AddressConfirmationModalComponent } from '../../shared/address-confirmation-modal/address-confirmation-modal.component';
import { AddressSessionStorageService } from '../../core/services/address-session-storage.service';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { TranslateService } from '@ngx-translate/core';
import { CheckoutDetailsInvalidFieldsFocusService } from 'app/core/services/checkout-details-invalid-fields-focus.service';
import { HighlightElementsService } from '../highlight-elements.service';
import { GuestAddressChooserComponent } from '../guest-address-chooser/guest-address-chooser.component';
import { GroupOrderOrchestratorService } from '../../core/services/group-order-orchestrator.service';

declare type TemplateSection = 'orderType' | 'orderTime' | 'addressForm';

@Component({
  selector: 'fts-order-type-form',
  templateUrl: './order-type-form.component.html',
  styleUrls: ['./order-type-form.component.scss'],
  animations: [Animations.expanded],
})
export class OrderTypeFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  store: StoreConfig;
  @Input()
  orderTypeConfigs: OrderTypeConfig[];
  @Input()
  isInIntro = false;
  @Input()
  orderTypeModalOpen: boolean;
  @Output()
  orderInitialized: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(GuestAddressChooserComponent, { static: false })
  guestAddressRef: GuestAddressChooserComponent;

  private continueBtnDisabled: Subject<boolean> = new Subject();
  private continueBtnDisabled$: Observable<boolean> = from(this.continueBtnDisabled);
  private destroy$: Subject<void> = new Subject<void>();
  private orderTimeShow: Subject<boolean> = new Subject();
  private orderTimeShow$: Observable<boolean> = from(this.orderTimeShow);
  private deferChoiceChange: Subject<void> = new Subject();
  private deferChoiceChange$: Observable<void> = from(this.deferChoiceChange);

  theme: Theme;
  order: Order;
  account: Account;
  customerAddress: Address = new Address();
  addressOutOfMapValidation: AddressValidationResult = {};
  addressValidationErrors: GenericObject<string[]> = {};
  loadingSubscription: Subscription;
  timeChooserOverflowVisible = false;
  deferChoice: DeferChoice;
  orderTimeInfo: OrderTimeInfo;
  initFromSavedState = false;
  storeConfig$: Observable<StoreConfig>;
  isGroupOrder: boolean;
  hasRenderedHomePageOnInit = false;
  canContinueToMenu = false;
  selectedOrderType: OrderTypeConfig;
  showAddressChooser = false;
  showOrderTimeChooser = false;
  isOrderTimeSelectionValid = false;
  genericErrors: string[] = [];
  templateErrors: GenericObject<string[]> = {};
  showMenuLabel = false;
  showValidation = false;

  private storeStatus: StoreStatus;
  public loadingTimeDefferOptions$: Observable<boolean>;

  constructor(
    private orderService: OrderService,
    private accountService: AccountService,
    private addressService: AddressWrapperService,
    private errorsService: ErrorsService,
    private menuService: MenuWrapperService,
    private storeStatusService: StoreStatusService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private themeService: ThemeService,
    private initParamsStorageService: InitParamsStorageService,
    private paramsOrchestratorService: ParamsOrchestratorService,
    private auditService: AuditService,
    private addressSessionStorageService: AddressSessionStorageService,
    private configService: ConfigService,
    private cdRef: ChangeDetectorRef,
    private uiService: UiOrchestratorService,
    private featuredContentService: FeaturedContentService,
    private translateService: TranslateService,
    private checkoutDetailsInvalidFieldsFocusService: CheckoutDetailsInvalidFieldsFocusService,
    private highlightService: HighlightElementsService,
    private groupOrderService: GroupOrderService,
    private groupOrderOrchestratorService: GroupOrderOrchestratorService
  ) {}

  ngOnInit() {
    this.loadingTimeDefferOptions$ = this.storeStatusService.loadingTimeDefferOptions.asObservable();
    this.storeConfig$ = this.configService.storeConfig$;
    this.themeService.theme.pipe(takeUntil(this.destroy$)).subscribe(theme => {
      this.theme = theme;
      this.checkIfCanContinueToMenu();
      this.shouldShowAddressChooser();
      this.shouldShowOrderTimeChooser();
    });
    this.accountService.account$.pipe(takeUntil(this.destroy$)).subscribe(account => {
      this.account = account;
      this.checkIfCanContinueToMenu();
    });
    this.order = new Order();

    this.storeStatusService.storeStatus$.pipe(takeUntil(this.destroy$)).subscribe(storeStatus => {
      this.storeStatus = storeStatus;
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if (params['deferChoice'] && params['orderType']) {
        if (params['deferTime']) {
          this.order.deferTime = params['deferTime'];
        }
        this.order.orderType = params['orderType'];
        this.deferChoice = params['deferChoice'];
        this.initFromSavedState = true;
      }
    });

    this.groupOrderService.triggerGroupOrderInvitationModal$
      .pipe(takeUntil(this.destroy$))
      .subscribe((triggerGroupOrderModal: boolean) => (this.isGroupOrder = triggerGroupOrderModal));

    this.preselectOrderType();
    this.initOrderTypeAndAddressFromParams();

    this.uiService.setNoContactToSession(false);

    this.featuredContentService
      .getFeaturedContentsByAreaName(FeaturedContentName.HOME_PAGE)
      .pipe(
        map(featureContents => !!featureContents[0]),
        takeUntil(this.destroy$)
      )
      .subscribe(hasHomeFeaturedContent => {
        this.hasRenderedHomePageOnInit = hasHomeFeaturedContent;
        this.checkIfCanContinueToMenu();
      });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();

    combineLatest([
      this.continueBtnDisabled$.pipe(distinctUntilChanged()),
      this.orderTimeShow$.pipe(distinctUntilChanged()),
      this.deferChoiceChange$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cdRef.detectChanges(), noop);

    this.checkIfCanContinueToMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['store'] || changes['orderTypeConfigs']) {
      this.preselectOrderType();
    }

    if (changes['store']) {
      this.checkIfCanContinueToMenu();
      this.shouldShowAddressChooser();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onStreetChange(street: Street) {
    if (this.store.usesAutomatedPromiseTime) {
      this.initOrderTimeInfo(this.order.orderType, street.address, street.zip);
    }
  }

  onAddressTypeChange(addressType: string) {
    this.customerAddress.addressType = addressType;
    this.checkIfCanContinueToMenu();
  }

  chooseOrderType(orderType: OrderType, initFromParams = false) {
    this.selectedOrderType = this.orderTypeConfigs.find(ot => ot.type === orderType);

    if ((!!this.selectedOrderType && this.order && orderType !== this.order.orderType) || initFromParams) {
      this.order.orderType = orderType;
      this.storeStatusService.updateStoreStatus(orderType);
      this.order.deferTime = undefined;
      this.initOrderTimeInfo(orderType);
      this.shouldShowAddressChooser();
      this.shouldShowOrderTimeChooser();
      this.checkIfCanContinueToMenu();
    }
    this.updateErrors([], 'orderType');
    this.updateErrors([], 'addressForm');
  }

  chooseOrderTime(date: string | undefined) {
    this.order.deferTime = date;
    this.checkIfCanContinueToMenu();
    this.updateErrors([], 'orderTime');
    this.shouldShowAddressChooser();
  }

  continueToMenu() {
    this.showValidation = true;
    if (!this.checkIfCanContinueToMenu()) {
      this.checkoutDetailsInvalidFieldsFocusService.triggerValidation();
      this.highlightService.highLightAlertDanger(false);
      return;
    }
    this.addressOutOfMapValidation = {};
    this.addressValidationErrors = {};
    this.genericErrors = [];
    this.customerAddress.force = false;
    if (
      !this.hasRenderedHomePageOnInit &&
      this.selectedOrderType?.needsAddress &&
      this.store.askForAddressUpfront &&
      this.showAddressChooser &&
      this.customerAddress.addressType
    ) {
      this.loadingSubscription = this.orderService
        .validateAddress(this.customerAddress, this.order.deferTime)
        .pipe(
          tap(() =>
            this.auditService.createAudit(
              () => `Validating selected address:
              ${this.store.useInternationalStreetFormat ? this.customerAddress.intlFullAddress : this.customerAddress.fullAddress}`
            )
          )
        )
        .subscribe(
          validation => this.handleAddressValidationSuccess(validation),
          error => this.handleAddressValidationError(error)
        );
    } else if (this.hasRenderedHomePageOnInit) {
      this.loadingSubscription = this.createOrderDataAndContinueToMenu();
    } else {
      this.checkToContinueDirectlyToMenu();
    }
  }

  handleAddressValidationSuccess(validation: AddressValidationResult) {
    if (validation.error) {
      this.auditService.createAudit(
        () => `Address validation failed: [overridable]: ${validation.overridable}, [error]: ${validation.error}`
      );
      this.addressOutOfMapValidation = validation;
      const context = new AddressConfirmationModalContext(this.addressOutOfMapValidation, {
        orderType: this.order.orderType,
        ...this.customerAddress,
      }).getConfig();
      this.modalService.openModal(AddressConfirmationModalComponent, context).subscribe(result => {
        this.handleAddressValidationModalResult(result);
      });
    } else {
      this.loadingSubscription = this.createOrderDataAndContinueToMenu();
    }
  }

  handleAddressValidationError(error) {
    const result = this.errorsService.parseErrorResponse(error);
    if (this.account.isInstantiated && result.errors?.deliveryInstructions?.length) {
      this.genericErrors = result.errors?.deliveryInstructions;
    } else {
      if (result.errors) {
        this.addressValidationErrors = result.errors;
      } else {
        this.modalService.notifyErrors(result);
      }
    }
    this.auditService.createAudit(() => `Address validation failed: ${result.errors || result}`);
  }

  handleAddressValidationModalResult(force: boolean) {
    if (this.addressOutOfMapValidation.overridable && force) {
      this.customerAddress.force = true;
      this.loadingSubscription = this.createOrderDataAndContinueToMenu();
    }
  }

  chooseDeferChoice(deferChoice: DeferChoice | undefined) {
    this.deferChoice = deferChoice;
    this.deferChoiceChange.next();
    this.checkIfCanContinueToMenu();
    if (!!this.templateErrors.orderTime && this.deferChoice === DeferChoice.Asap) {
      this.updateErrors([], 'orderTime');
    }
  }

  redirectToLogin() {
    this.initParamsStorageService.initParams.params.set('orderType', this.order.orderType);
    this.initParamsStorageService.initParams.params.set('deferTime', this.order.deferTime);
    this.initParamsStorageService.initParams.params.set('deferChoice', this.deferChoice);

    this.router.navigate(['/login'], {
      queryParams: {
        target: 'intro',
        orderType: this.order.orderType,
        deferTime: this.order.deferTime,
        deferChoice: this.deferChoice,
        skipIntro: false,
      },
    });
  }

  onOrderTimeSelectionChanged(isValid: boolean) {
    this.isOrderTimeSelectionValid = isValid;
    this.checkIfCanContinueToMenu();
    if (this.isOrderTimeSelectionValid) {
      this.updateErrors([], 'orderTime');
    }
    this.shouldShowAddressChooser();
  }

  onAddressChange(isValid: boolean) {
    this.checkIfCanContinueToMenu();
    if (isValid) {
      this.updateErrors([], 'addressForm');
    }
  }

  onCustomerAddressChange() {
    this.calcBtnLabel();
    this.updateErrors([], 'addressForm');
  }

  onGroupOrderChange() {
    this.calcBtnLabel();
  }

  updateErrors(errors: string[], template: TemplateSection) {
    if (!errors.length) {
      this.showValidation = false;
    }
    this.templateErrors = Object.assign({}, { ...this.templateErrors }, { [template]: errors });
  }

  private initOrderTimeInfo(orderType: string, street?: string, zip?: string) {
    if (this.storeStatus.isOpen) {
      this.orderService.getAsapNote(orderType, street, zip).subscribe(orderTimeInfo => (this.orderTimeInfo = orderTimeInfo));
    }
  }

  private checkToContinueDirectlyToMenu() {
    if (this.canContinueToMenu && !this.showMenuLabel) {
      this.loadingSubscription = this.createOrderDataAndContinueToMenu();
    } else {
      this.continueToMenuWithoutOrderType();
    }
  }

  private continueToMenuWithoutOrderType() {
    this.loadingSubscription = this.menuService.fetchMenuAndFeaturedContents(OrderType.Any, undefined).subscribe(
      () => {
        this.router.navigate(['menu']);
      },
      error => this.modalService.parseAndNotifyErrors(error)
    );
  }

  private createOrderDataAndContinueToMenu() {
    this.order.isMobileSource = this.initParamsStorageService.initParams.sourceIsMobile;
    return this.orderService
      .createOrder(this.order)
      .pipe(
        mergeMap(() => {
          if (!Utils.isNullOrUndefined(this.customerAddress.street) && this.selectedOrderType.needsAddress) {
            return of(this.addressSessionStorageService.setAddress(this.customerAddress)).pipe(
              mergeMap((_unusedVariable: void) => this.orderService.updateOrderCustomerAddress(this.customerAddress)),
              map(res => !!res),
              catchError(response => {
                this.modalService.parseAndNotifyErrors(response, true);
                return of(false);
              }),
              mergeMap((successCall: boolean) => (successCall ? of(this.addressSessionStorageService.deleteAddress()) : of(null)))
            );
          }
          return of(null);
        }),
        mergeMap(() =>
          this.isInIntro ? this.menuService.fetchMenuAndFeaturedContents(this.order.orderType, this.order.deferTime) : of(null)
        ),
        switchMap(() => {
          if (this.isInIntro) {
            const params = this.isGroupOrder ? { makeItAGroupOrder: true } : {};
            return from(
              this.router.navigate(['menu'], {
                queryParams: params,
                queryParamsHandling: 'merge',
              })
            ).pipe(tap(() => this.paramsOrchestratorService.markOrderInitializationDone()));
          } else {
            if (
              !this.initParamsStorageService.initParams.edit ||
              !this.initParamsStorageService.initParams.addCoupon ||
              (this.initParamsStorageService.initParams.edit && this.initParamsStorageService.initParams.addCoupon)
            ) {
              this.paramsOrchestratorService.markOrderInitializationDone();
            }
            this.orderInitialized.emit();
            return of(null);
          }
        })
      )
      .subscribe(
        () => {
          if (this.isGroupOrder && !this.isInIntro) {
            this.groupOrderOrchestratorService.toggleGroupOrderInvitation();
          }
          this.uiService.markedOrderInitializationDoneFromOrderTypeModal(true);
        },
        response => this.modalService.parseAndNotifyErrors(response)
      );
  }

  private initOrderTypeAndAddressFromParams() {
    const initParams = this.initParamsStorageService.initParams;

    if (initParams.orderType) {
      const orderType = initParams.orderType as OrderType;
      this.chooseOrderType(orderType, true);

      this.selectedOrderType = this.orderTypeConfigs.find(ot => ot.type === orderType);
      if (!this.hasRenderedHomePageOnInit && this.selectedOrderType?.needsAddress) {
        this.addressService.extractAddressAttributesFromParams(initParams).subscribe(r => {
          this.customerAddress = Object.assign(new Address(), r);
          this.checkIfCanContinueToMenu();
        });

        if (this.store.usesAutomatedPromiseTime) {
          this.initOrderTimeInfo(orderType, this.customerAddress.street, this.customerAddress.zip);
        } else {
          this.initOrderTimeInfo(orderType);
        }
      } else {
        this.initOrderTimeInfo(orderType);
      }
    }
  }

  private preselectOrderType() {
    if (
      this.store.defaultOrderType &&
      this.orderTypeConfigs.length &&
      this.orderTypeConfigs.map(orderType => orderType.type).indexOf(this.store.defaultOrderType as OrderType) !== -1 &&
      !this.initParamsStorageService.initParams.orderType
    ) {
      this.chooseOrderType(this.store.defaultOrderType as OrderType);
    } else if (this.orderTypeConfigs.length === 1) {
      this.chooseOrderType(this.orderTypeConfigs[0].type as OrderType);
    }
  }

  private checkIfCanContinueToMenu(): boolean {
    this.calcBtnLabel();
    if (this.showMenuLabel) {
      return true;
    }
    if (
      this.isInIntro &&
      this.theme?.skipIntroPage &&
      !this.isGroupOrder &&
      !(this.showAddressChooser && !!this.customerAddress.addressType)
    ) {
      this.canContinueToMenu = true;
      return true;
    }
    if (!this.order?.isInitialized || (this.theme?.askForOrderTimeUpfront && !this.isOrderTimeSelectionValid)) {
      this.updateErrors(
        [this.translateService.instant('component.order_type_form.order_details_warning')],
        !this.order?.isInitialized ? 'orderType' : 'orderTime'
      );
      this.canContinueToMenu = false;
    } else if (
      !this.hasRenderedHomePageOnInit &&
      this.selectedOrderType?.needsAddress &&
      this.store.askForAddressUpfront &&
      ((!this.account.isInstantiated && !this.guestAddressRef?.addressForm?.isFormValid) ||
        (this.account.isInstantiated && (!this.customerAddress.name || this.customerAddress.warnings?.length)))
    ) {
      this.updateErrors([this.translateService.instant('component.order_type_form.address_warning')], 'addressForm');
      this.canContinueToMenu = false;
    } else {
      this.canContinueToMenu = true;
    }
    this.continueBtnDisabled.next(this.canContinueToMenu);
    return this.canContinueToMenu;
  }

  private calcBtnLabel() {
    this.showMenuLabel =
      this.isInIntro &&
      this.theme?.skipIntroPage &&
      !this.isGroupOrder &&
      !(this.account?.isInstantiated && !this.customerAddress) &&
      (!this.order?.isInitialized ||
        (this.order?.isInitialized && this.showOrderTimeChooser && !this.isOrderTimeSelectionValid) ||
        (this.showAddressChooser && !this.customerAddress.addressType));
  }

  private shouldShowAddressChooser() {
    this.showAddressChooser =
      this.selectedOrderType?.needsAddress &&
      this.store.askForAddressUpfront &&
      (!this.theme?.askForOrderTimeUpfront || this.isOrderTimeSelectionValid);
    if (!this.showAddressChooser) {
      this.updateErrors([], 'addressForm');
    }
  }

  private shouldShowOrderTimeChooser() {
    this.showOrderTimeChooser = this.theme?.askForOrderTimeUpfront && this.order?.hasOrderType;
    this.orderTimeShow.next(this.showOrderTimeChooser);
  }
}
