import { Deserializable } from '../models/core/deserializable';
import { Utils } from './utils';

// @dynamic
export class DeserializationUtils {
  public static deserializeChildren<T extends Deserializable>(input: any, key: any, type: new () => T): T[] {
    return (input[key] || []).map(v => new type().deserialize(v));
  }

  public static deserializeChild<T extends Deserializable>(input: any, key: any, type: new () => T): T | undefined {
    const value = input[key];
    return Utils.isNullOrUndefined(value) ? undefined : new type().deserialize(value);
  }

  public static deserializeArray<T extends Deserializable>(input: any[], type: new () => T): T[] {
    return (input || []).map(v => new type().deserialize(v));
  }

  public static deserializeObj<T extends Deserializable>(input: any, type: new () => T): T {
    return input ? new type().deserialize(input) : undefined;
  }
}
