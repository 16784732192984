import { Component, OnInit } from '@angular/core';
import { OrderedSpecial, OrderService, OrderWarning, OrderWarningType } from 'ngx-web-api';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderWarningsModalContext } from 'app/domain/order-warnings-modal-context';

@Component({
  selector: 'fts-order-warnings-modal',
  templateUrl: './order-warnings-modal.component.html',
  styleUrls: ['./order-warnings-modal.component.scss'],
})
export class OrderWarningsModalComponent implements OnInit {
  public context: OrderWarningsModalContext;
  public itemWarnings: OrderWarning[];
  public orderWarnings: OrderWarning[];

  public OrderWarningType = OrderWarningType;

  constructor(
    private uiOrchestratorService: UiOrchestratorService,
    private orderService: OrderService,
    private router: Router,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    const warnings = this.orderService.getWarnings(this.context.order);
    this.itemWarnings = warnings.filter(warning => warning.type === OrderWarningType.ITEM || warning.type === OrderWarningType.SPECIAL);
    this.orderWarnings = warnings.filter(warning => warning.type === OrderWarningType.ORDER);
    this.uiOrchestratorService.informModalOpened();
  }

  public showInstructions(special: OrderedSpecial) {
    this.activeModal.close(special);
  }

  hideModal(res) {
    this.activeModal.close(res);
  }

  changeRouteAndHideModal(itemId: number) {
    this.router.navigate(['/editor', 'item', itemId]);
    this.activeModal.close(null);
  }
}
