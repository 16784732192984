import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeaturedItem } from 'ngx-web-api';
import { Observable, of } from 'rxjs';
import { ItemEditorService } from './item-editor.service';
import { ModalService } from './modal.service';
import { GTMService } from './gtm.service';
import { EcommerceTriggeringArea } from '../../domain/ecommerce-triggering-area.enum';

@Injectable({
  providedIn: 'root',
})
export class FeaturedItemService {
  constructor(
    private router: Router,
    private modalService: ModalService,
    private itemEditoService: ItemEditorService,
    private gtmService: GTMService
  ) {}

  public addFeaturedItemToOrder(
    featuredItem: FeaturedItem,
    featuredAreaName: string,
    featuredContentName: string,
    slotNum?: string
  ): Observable<any> {
    if (featuredItem.item) {
      return this.addOrderableItemToOrder(featuredItem, featuredAreaName, featuredContentName);
    } else if (featuredItem.special) {
      return this.modalService.openFeaturedSpecialWizard(featuredItem, featuredAreaName, featuredContentName, slotNum);
    }
    return of(null);
  }

  private addOrderableItemToOrder(featuredItem: FeaturedItem, featuredAreaName: string, featuredContentName: string): Observable<any> {
    const orderableItem = featuredItem.item;
    if (orderableItem) {
      let size = featuredItem.itemSize || '';
      if (!size) {
        const availableSizes = orderableItem.sizePrices.filter(sizePrice => !sizePrice.isUnavailable);
        if (availableSizes.length) {
          size = availableSizes[0].size;
        }
      }
      this.itemEditoService.editOrderableItem(orderableItem, size, null, null, 1, featuredAreaName, featuredContentName);
      const price = orderableItem.sizePrices.find(sp => sp.label === size)?.price;
      this.gtmService.pushViewItemEvent(
        orderableItem,
        size,
        price,
        orderableItem?.minimumQuantity || 1,
        featuredContentName,
        EcommerceTriggeringArea.FEATURED_AREA
      );
      this.router.navigate(['/editor', 'add-to-order']);
    }
    return of(null);
  }
}
