import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { Account } from '../../models/account/account.model';
import { Coupon } from '../../models/core/coupon.model';
import { CouponService } from '../core/coupon.service';
import { HypermediaService } from '../core/hypermedia.service';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class AccountCouponsService {
  private coupons: BehaviorSubject<Coupon[]> = new BehaviorSubject([]);
  public coupons$: Observable<Coupon[]> = this.coupons.asObservable();

  private fetchCoupons: Subject<void> = new Subject<void>();

  constructor(private accountService: AccountService, private hypermedia: HypermediaService, private couponService: CouponService) {
    combineLatest([
      this.accountService.account$.pipe(
        filter((account: Account) => account.isInstantiated),
        take(1)
      ),
      this.fetchCoupons.asObservable(),
    ])
      .pipe(
        mergeMap(([account]) => this.hypermedia.get(account.links, 'coupons')),
        map((json: Object[]) => (json || []).map(c => new Coupon().deserialize(c))),
        map((coupons: Coupon[]) => coupons.sort(c => (c && this.couponService.isApplicable(c) ? -1 : 1))),
        catchError(() => of([]))
      )
      .subscribe((coupons: Coupon[]) => this.coupons.next(coupons));

    this.reloadCoupons();
  }

  public reloadCoupons(): void {
    this.fetchCoupons.next();
  }
}
