import { Injectable, ElementRef } from '@angular/core';
import {
  AuditService,
  HypermediaService,
  IngredientChoicesType,
  Link,
  OrderableItem,
  OrderableSpecial,
  OrderableSpecialStep,
  OrderableSpecialStepAlternativeItem,
  OrderedItem,
  OrderedItemService,
  OrderSpecialService as OrderSpecialServiceNgx,
  Utils,
  OrderedIngredient,
} from 'ngx-web-api';
import { Observable, from, of } from 'rxjs';
import { map, mergeMap, tap, toArray, finalize } from 'rxjs/operators';

import { SpecialWizard } from '../../domain/special-wizard';
import { SpecialWizardStep } from '../../domain/special-wizard-step';
import { ItemEditorService } from './item-editor.service';
import { EditingItemContext } from 'app/domain/editing-item-context';

@Injectable({
  providedIn: 'root',
})
export class SpecialWizardService {
  private loadingSpecialWizard = false;
  private specialWizardBody: ElementRef;

  constructor(
    private hypermedia: HypermediaService,
    private orderedItemService: OrderedItemService,
    private orderSpecialService: OrderSpecialServiceNgx,
    private itemEditorService: ItemEditorService,
    private auditService: AuditService
  ) {}

  public setSpecialWizardBody(specialWizardBody: ElementRef) {
    this.specialWizardBody = specialWizardBody;
  }

  public getSpecialWizardBody() {
    return this.specialWizardBody;
  }

  public createSpecialWizard(
    links: Link[],
    type = 'special',
    selectedStepOrdinal?: number,
    selectedOrderableItem?: OrderableItem,
    selectedOrderedItem?: OrderedItem,
    triggeredByCustomize?: boolean,
    editingItemContext?: EditingItemContext
  ): Observable<SpecialWizard> {
    if (this.loadingSpecialWizard) {
      return of(null);
    }
    this.loadingSpecialWizard = true;

    return this.hypermedia.get(links, type).pipe(
      map((json: Object) => new OrderableSpecial().deserialize(json)),
      mergeMap((orderableSpecial: OrderableSpecial) => this.orderSpecialService.validate(orderableSpecial)),
      mergeMap((orderableSpecial: OrderableSpecial) =>
        this.getSpecialWizardConfig(
          orderableSpecial,
          selectedStepOrdinal,
          selectedOrderableItem,
          selectedOrderedItem,
          triggeredByCustomize,
          editingItemContext
        )
      ),
      finalize(() => (this.loadingSpecialWizard = false))
    );
  }

  public getSpecialWizardConfig(
    orderableSpecial: OrderableSpecial,
    selectedStepOrdinal?: number,
    selectedOrderableItem?: OrderableItem,
    selectedOrderedItem?: OrderedItem,
    triggeredByCustomize?: boolean,
    editingItemContext?: EditingItemContext
  ): Observable<SpecialWizard> {
    return this.getSpecialWizardSteps(
      orderableSpecial,
      selectedStepOrdinal,
      selectedOrderableItem,
      selectedOrderedItem,
      editingItemContext
    ).pipe(
      map((steps: SpecialWizardStep[]) => new SpecialWizard(orderableSpecial, steps)),
      map((wizard: SpecialWizard) => {
        if (
          wizard.steps.length > 1 &&
          this.shouldSkipFirstStep(selectedStepOrdinal, selectedOrderableItem, selectedOrderedItem, triggeredByCustomize)
        ) {
          wizard.setCurrentStepIndex(1);
        }
        return wizard;
      })
    );
  }

  private getSpecialWizardSteps(
    orderableSpecial: OrderableSpecial,
    selectedStepOrdinal?: number,
    selectedOrderableItem?: OrderableItem,
    selectedOrderedItem?: OrderedItem,
    editingItemContext?: EditingItemContext
  ): Observable<SpecialWizardStep[]> {
    return from(orderableSpecial.steps).pipe(
      map((s: OrderableSpecialStep) => {
        const wizardStep = new SpecialWizardStep();
        wizardStep.stepIndex = s.ordinal;
        wizardStep.title = s.title;
        return { wizardStep, step: s };
      }),
      mergeMap(({ step, wizardStep }: { step: OrderableSpecialStep; wizardStep: SpecialWizardStep }) => {
        wizardStep.stepAlternatives = step.alternatives;
        if (selectedStepOrdinal === step.ordinal && selectedOrderableItem && selectedOrderedItem) {
          wizardStep.selectedOrderableItem = selectedOrderableItem;
          wizardStep.selectedOrderedItem = selectedOrderedItem;
          if (editingItemContext.selectedUpfrontIngredient) {
            const newSelectedUpfrontIngredientRef = editingItemContext.selectedUpfrontIngredient.copy();
            newSelectedUpfrontIngredientRef.qualifiers = editingItemContext.selectedUpfrontIngredient.qualifiers.map(q => ({ ...q }));
            newSelectedUpfrontIngredientRef.qualifiers.forEach(q => (q.isDefault = false));
            const upfrontChoice = wizardStep.selectedOrderedItem.choices.find(
              ch => ch.name === editingItemContext.unsatisfiedIngredientChoiceName
            );
            upfrontChoice.ingredients = [];
            // Mutate OrderableItem to set default pre-selected qualifiers
            newSelectedUpfrontIngredientRef.qualifiers
              .filter(q => !!editingItemContext.selectedUpfrontQualifier.find(sq => sq === q.name))
              .forEach(q => (q.isDefault = true));
            upfrontChoice.ingredients.push(OrderedIngredient.createFromOrderable(newSelectedUpfrontIngredientRef, false, false));
          }
          return of(wizardStep);
        }
        const items = this.getSpecialWizardStepItems(step);
        if (items.length === 1 && (!items[0].availableSizePrices || items[0].availableSizePrices.length === 1)) {
          return this.hypermedia.get(items[0].links, 'orderableItem').pipe(
            map((json: Object): OrderableItem => new OrderableItem().deserialize(json)),
            map((orderableItem: OrderableItem) => ({
              orderableItem,
              orderedItem: this.orderedItemService.createOrderedItem(orderableItem, items[0].availableSizePrices[0].size),
            })),
            tap(({ orderedItem }) => this.auditService.createAudit(() => `Selected ${orderedItem.size} ${orderedItem.item}`)),
            map(({ orderableItem, orderedItem }) => {
              this.orderedItemService.validateItem(
                orderedItem,
                orderableItem,
                this.itemEditorService.getChoicesLayout(orderableItem) === IngredientChoicesType.Steps
              );
              wizardStep.selectedOrderedItem = orderedItem;
              wizardStep.selectedOrderableItem = orderableItem;
              return wizardStep;
            })
          );
        }
        return of(wizardStep);
      }),
      toArray(),
      map((steps: SpecialWizardStep[]) => {
        steps.sort((a, b) => a.stepIndex - b.stepIndex);

        // Make selected orderable step the first one
        if (!Utils.isNullOrUndefined(selectedStepOrdinal) && selectedStepOrdinal >= 0 && selectedOrderableItem && selectedOrderedItem) {
          steps.unshift(
            ...steps.splice(
              steps.findIndex(s => s.stepIndex === selectedStepOrdinal),
              1
            )
          );
          steps.forEach((step, index) => (step.stepIndex = index));
        }

        return steps;
      })
    );
  }

  private getSpecialWizardStepItems(step: OrderableSpecialStep): OrderableSpecialStepAlternativeItem[] {
    const items: OrderableSpecialStepAlternativeItem[] = Utils.flatten(step.alternatives.map(alt => Utils.flatten(alt.items)));
    return items.filter(item => item.availableSizePrices.length);
  }

  private shouldSkipFirstStep(
    selectedStepOrdinal?: number,
    selectedOrderableItem?: OrderableItem,
    selectedOrderedItem?: OrderedItem,
    triggeredByCustomize?: boolean
  ): boolean {
    if (
      !Utils.isNullOrUndefined(selectedStepOrdinal) &&
      selectedStepOrdinal >= 0 &&
      !!selectedOrderableItem &&
      !!selectedOrderedItem &&
      !triggeredByCustomize
    ) {
      this.orderedItemService.validateItem(
        selectedOrderedItem,
        selectedOrderableItem,
        this.itemEditorService.getChoicesLayout(selectedOrderableItem) === IngredientChoicesType.Steps
      );
      const ordered = selectedOrderedItem.copy();
      const orderable = selectedOrderableItem.copy();
      return this.orderedItemService.isValid(ordered, orderable);
    }

    return false;
  }
}
