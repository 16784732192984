export enum ImageAlternativeType {
  ORIGINAL = 'ORIGINAL',
  DETAIL = 'DETAIL',
  THUMBNAIL = 'THUMBNAIL',
  BUILDER_FIXED = 'BUILDER_FIXED',
  BUILDER_FIXED_LEFT_HALF = 'BUILDER_FIXED_LEFT_HALF',
  BUILDER_FIXED_RIGHT_HALF = 'BUILDER_FIXED_RIGHT_HALF',
  BUILDER_FIXED_QUALIFIER = 'BUILDER_FIXED_QUALIFIER',
  BUILDER_FIXED_QUALIFIER_LEFT_HALF = 'BUILDER_FIXED_QUALIFIER_LEFT_HALF',
  BUILDER_FIXED_QUALIFIER_RIGHT_HALF = 'BUILDER_FIXED_QUALIFIER_RIGHT_HALF',
  BUILDER_REPEAT = 'BUILDER_REPEAT',
}
