import { BrowserStorageHandlerService } from './browser-storage-handler.service';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { InitParams } from '../../domain/init-params.model';
import { RedirectKeys, OrderService } from 'ngx-web-api';
import { filter, map, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root',
})
export class InitParamsStorageService {
  public initParams: InitParams = new InitParams();
  private initRoute: string[];

  constructor(
    private orderService: OrderService,
    private location: Location,
    private browserStorageHandlerService: BrowserStorageHandlerService,
    private themeService: ThemeService
  ) {}

  public parseParams(params: Params): InitParams {
    this.initRoute = this.location.path().split('?')[0].split('/').splice(1);
    this.initParams.parseParams(params);
    this.saveRedirectParamsToStorage();
    this.fillCategoryFromUrl();
    return this.initParams;
  }

  public resetParams(resetAll = false): InitParams {
    this.initParams.resetParams(resetAll);
    this.clearRedirectParams();
    return this.initParams;
  }

  public retainedParams() {
    return this.initParams.getRetainedParams();
  }

  public redirectParams(): Object {
    const rParams = {};
    Object.keys(window.sessionStorage).forEach(key => {
      if (!!RedirectKeys[key]) {
        rParams[key] = this.browserStorageHandlerService.getSessionStorageItem(key);
      }
    });
    return rParams;
  }

  public canNavigateToCheckout() {
    const targetsCheckout = !!this.initRoute.find(seg => seg.indexOf('checkout') > -1);
    const hasItemParams = !!this.initParams.itemCode || (!!this.initParams.category && !!this.initParams.item);
    const hasOrderType = !!this.initParams.orderType;
    return hasOrderType && hasItemParams && targetsCheckout;
  }

  private clearRedirectParams() {
    Object.keys(window.sessionStorage).forEach(key => {
      if (!!RedirectKeys[key]) {
        this.browserStorageHandlerService.setSessionStorageItem(key);
      }
    });
  }

  private saveRedirectParamsToStorage() {
    this.orderService.order$
      .pipe(
        map(order => order.isInitialized),
        take(1)
      )
      .subscribe(orderInitialized => {
        if (!orderInitialized) {
          this.clearRedirectParams();
        }
        this.initParams.params.forEach((val, key) => {
          if (!!val && !!RedirectKeys[key]) {
            this.browserStorageHandlerService.setSessionStorageItem(key, val);
          }
        });
      });
  }

  private fillCategoryFromUrl() {
    // We only want this when users are redirected to a store with Single page Menu and the URL is
    // formed for non SPM navigation to handle URL redirection e.g.
    // from: {host}/menu/Pizza
    // to:   {host}/menu/single-page#Pizza
    this.themeService.theme
      .pipe(
        filter(theme => theme.hasSinglePageMenu != null && theme.hasSinglePageMenu),
        take(1)
      )
      .subscribe(() => {
        if (this.initRoute.length > 1 && this.initRoute[0]?.toLowerCase() === 'menu') {
          if (this.initRoute[1] === 'search' || this.initRoute[1] === 'single-page') {
            return;
          }
          this.initParams.params.set('category', this.initRoute[1]);
        }
      });
  }
}
