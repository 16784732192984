<div
  [attr.role]="featuredItem?.isClickable ? 'button' : null"
  [attr.aria-hidden]="!featuredItem?.isClickable || null"
  [tabindex]="featuredItem?.isClickable ? 0 : -1"
  class="item-wrapper"
  (click)="featuredItem?.isClickable && onClickFeaturedItem(featuredItem)"
  (keydown.enter)="featuredItem?.isClickable && onClickFeaturedItem(featuredItem)"
  (keydown.space)="featuredItem?.isClickable && onClickFeaturedItem(featuredItem)"
>
  <span class="sr-only">{{featuredItemLabel}}</span>
  <div class="image-wrapper" aria-hidden="true">
    <img
      #imgEl
      ftsPicture
      [skipLazyLoading]="skipLazyLoading"
      #pictureRef='pictureRef'
      [imgSrc]='featuredItemPicture?.src'
      [imgSrcset]='featuredItemPicture?.srcset'
      [imgName]="featuredItemPicture?.name"
      [isFeatureItem]="true"
      [alt]="featuredItem?.title || featuredItemLabel"
      class='img-fluid'
      (load)="imgLoaded()"
      [ngClass]="{ 'cursor-pointer': featuredItem.isClickable, 'cover': fillParentHeight, 'img-fluid': !fillParentHeight}"/>
    <fts-ghost-element *ngIf='!skipLazyLoading && (featuredItemPicture?.src || featuredItemPicture?.srcset) && !pictureRef?.isLoaded ' [height]="itemImgHeight || 240"></fts-ghost-element>
  <div *ngIf='!featuredItem?.imageOnly && (featuredItem?.title || featuredItem?.subtitle)'
      class='titles-wrapper text-center'
      [ngClass]="{ 'cursor-pointer': featuredItem.isClickable }">
    <h2 *ngIf='featuredItem?.title' class='h3 fw-600'>{{featuredItem?.title}}</h2>
    <p *ngIf='featuredItem?.subtitle' class='no-margin'>{{featuredItem?.subtitle}}</p>
  </div>
  </div>
</div>
