import { Injector } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { CookieService } from '../services/core/cookie.service';

import { BaseTransportOptions, Transport, TransportMakeRequestResponse, TransportRequest } from '@sentry/types';
import { createTransport } from '@sentry/core';
import { CwoService } from 'app/core/services/cwo.service';

// Custom transports refactored based on the following migration guide :
// https://github.com/getsentry/sentry-javascript/blob/master/MIGRATION.md#custom-transports
export function makeMyCustomTransport(options: BaseTransportOptions): Transport {
  const storeName = injectCwoService().storeName;
  function makeRequest(request: TransportRequest): PromiseLike<TransportMakeRequestResponse> {
    const cookieService = injectCookieService();
    const httpClient = injectHttpClient();

    const token = cookieService.getCookie('XSRF-TOKEN') as string;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-XSRF-TOKEN', token)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Sentry-package', 'sentry-browser-v2')
      .set('X-FTS-STORE', storeName);

    // Append the URL (required by our API), by extending the Envelopes format
    // see : https://develop.sentry.dev/sdk/envelopes/
    // Our API
    const newBody = `${request.body}${String.fromCharCode(10)}{"url":"${options.url}"}`;

    return httpClient
      .post('/ws/integrated/v1/ordering/errors/sentryV2', newBody, { headers })
      .toPromise()
      .then((response: any) => ({
        headers: {
          'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
          'retry-after': response.headers.get('Retry-After'),
        },
      }));
  }

  return createTransport(options, makeRequest);
}

function injectHttpClient(): HttpClient {
  const httpInjector = Injector.create({
    providers: [
      { provide: HttpClient, deps: [HttpHandler] },
      { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest() }) },
    ],
  });
  return httpInjector.get(HttpClient);
}

function injectCookieService(): CookieService {
  const tokenInjector = Injector.create({
    providers: [{ provide: CookieService, useValue: new CookieService() }],
  });
  return tokenInjector.get(CookieService);
}

function injectCwoService(): CwoService {
  const tokenInjector = Injector.create({
    providers: [{ provide: CwoService, useValue: new CwoService() }],
  });
  return tokenInjector.get(CwoService);
}
