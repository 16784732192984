import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'leftPad' })
export class LeftPadPipe implements PipeTransform {
  transform(str: string, ch: string, length: number): string {
    if (length < str.length) {
      return str;
    }
    return ch.repeat(length - str.length) + str;
  }
}
