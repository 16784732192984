<fts-themeable-checkbox [ngModel]='sponsorSelected' (ngModelChange)="onSponsorSelectionChanged($event)">
  {{sponsorName}}
</fts-themeable-checkbox>
<div [@expanded] *ngIf='fundraiser.notesEnabled && sponsorSelected'>
  <p *ngIf="fundraiser.noteLabel && fundraiser.noteLabel.length">{{fundraiser.noteLabel}}</p>
  <textarea [ngModel]="sponsorNotes"
            (ngModelChange)="onSponsorNotesChanged($event)"
            [attr.aria-label]="fundraiser.noteLabel"
            class="form-control vert-resize primary-bg-color primary-text-color"
            [maxLength]="maxLength"
            [attr.aria-describedby]="'address-form-deliveryInstructions-charactersLeft'"></textarea>
  <span id="fundraiser-sponsor-entry-charactersLeft" class="form-text">{{'component.fundraiser_sponsor_entry.chars_left' | translate}}: {{charactersLeft}}</span>
</div>
