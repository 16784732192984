import { Component, forwardRef, Input, ChangeDetectorRef, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StoreConfig } from 'ngx-web-api';
import { faBan } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'fts-themeable-checkbox',
  templateUrl: './themeable-checkbox.component.html',
  styleUrls: ['./themeable-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThemeableCheckboxComponent),
      multi: true,
    },
  ],
})
export class ThemeableCheckboxComponent implements ControlValueAccessor {
  @Input()
  name: string;
  @Input()
  ingredient: boolean;
  @Input()
  disabled: boolean;
  @Input()
  isInModal: boolean;
  @Input()
  waitForConfirmation: boolean;
  @Input()
  alignCenter: boolean;
  @Input()
  needsMaxIngredientsWarningsCheck?: boolean;
  @Input()
  storeConfig: StoreConfig;
  @Input()
  isUnavailable: boolean;
  @Input()
  ariaLabel: string;
  @Input()
  noContent = false;
  @Input()
  isHalfAndHalf = false;

  @ViewChild('inputRef', { read: ElementRef, static: false })
  inputRef: ElementRef;

  checked = false;
  focused = false;
  faBan = faBan;

  constructor(private cdRef: ChangeDetectorRef, private renderer: Renderer2) {}

  writeValue(value?: boolean): void {
    this.checked = !!value;
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {
    /* Just inherit the method from the ControlValueAccessor */
  }

  onChange(event: any) {
    if (this.isUnavailable) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if ((this.checked && this.waitForConfirmation) || (this.needsMaxIngredientsWarningsCheck && !this.checked)) {
      event.preventDefault();
      event.stopPropagation();
      this.propagateChange(!!this.checked);
      return;
    }

    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    this.propagateChange(this.checked);
  }

  onInputFocus() {
    this.focused = true;
  }

  onInputBlur() {
    this.focused = false;
  }

  setDisabledState(isDisabled: boolean) {
    if (!this.inputRef?.nativeElement) {
      return;
    }
    isDisabled
      ? this.renderer.setAttribute(this.inputRef.nativeElement, 'disabled', 'true')
      : this.renderer.removeAttribute(this.inputRef.nativeElement, 'disabled');
  }

  private propagateChange = (_: any) => {};
}
