import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Account } from '../../models/account/account.model';
import { Address } from '../../models/core/address.model';
import { AuditService } from '../core/audit.service';
import { AccountService } from './account.service';
import { ConfigService } from '../core/config.service';
import { AddressValidationResult } from '../../models/core/address-validation-result.model';

@Injectable({ providedIn: 'root' })
export class AccountAddressesService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/account';
  private readonly ADDRESS_PATH = `${this.BASE_PATH}/addresses`;
  private readonly VALIDATION_PATH = `${this.BASE_PATH}/validate/address`;
  private reloadAddresses: Subject<void> = new Subject<void>();

  private addresses: BehaviorSubject<Address[]> = new BehaviorSubject([]);
  public addresses$: Observable<Address[]> = this.addresses.asObservable();

  constructor(
    private http: HttpClient,
    private auditService: AuditService,
    private accountService: AccountService,
    private storeConfig: ConfigService
  ) {
    this.initAddressesLoader();
  }

  public getAddress(name: string): Observable<Address> {
    return this.http.get(`${this.ADDRESS_PATH}/${encodeURIComponent(name)}`).pipe(map(json => new Address().deserialize(json)));
  }

  public addAddress(address: Address): Observable<Address> {
    return this.http.post(this.ADDRESS_PATH, address.serialize()).pipe(
      map(json => new Address().deserialize(json)),
      tap(() => this.auditAddressAddition(address)),
      tap(() => this.reloadAddresses.next())
    );
  }

  public updateAddress(address: Address): Observable<Address> {
    return this.http.put(`${this.ADDRESS_PATH}/${encodeURIComponent(address.name)}`, address.serialize()).pipe(
      map(json => new Address().deserialize(json)),
      tap(() => this.auditAddressUpdate(address)),
      tap(() => this.reloadAddresses.next())
    );
  }

  public deleteAddress(address: Address): Observable<any> {
    return this.http.delete(`${this.ADDRESS_PATH}/${encodeURIComponent(address.name)}`).pipe(
      tap(() => this.reloadAddresses.next()),
      tap(() => this.auditAddressDeletion(address))
    );
  }

  public validateNewAddress(address: Address): Observable<AddressValidationResult> {
    return this.http.post<AddressValidationResult>(this.VALIDATION_PATH, address);
  }

  public validateExistingAddress(address: Address): Observable<AddressValidationResult> {
    return this.http.put<AddressValidationResult>(this.VALIDATION_PATH, address);
  }

  /**************************************************** Helpers *****************************************************/

  private initAddressesLoader(): void {
    combineLatest([
      this.accountService.account$.pipe(
        filter((account: Account) => account.isInstantiated),
        take(1)
      ),
      this.reloadAddresses.asObservable(),
    ])
      .pipe(
        mergeMap(() => this.http.get(this.ADDRESS_PATH)),
        map((json: Object[]) => (json || []).map(a => new Address().deserialize(a)))
      )
      .subscribe(
        (addresses: Address[]) => this.addresses.next(addresses),
        () => {}
      );

    this.reloadAddresses.next();
  }

  /************************************************ Auditing helpers ************************************************/

  private auditAddressDeletion(address: Address): void {
    this.getFullAddress(address).subscribe(fullAddress => {
      this.auditService.createAudit(() => ({
        message: `Address has been deleted`,
        details: {
          addressName: address.name,
          fullAddress: fullAddress,
        },
      }));
    });
  }

  private auditAddressAddition(address: Address) {
    this.getFullAddress(address).subscribe(fullAddress => {
      this.auditService.createAudit(() => ({
        message: `New address has been created`,
        details: { addressName: address.name, fullAddress: fullAddress, diff1: address.diff1, diff2: address.diff2, diff3: address.diff3 },
      }));
    });
  }

  private auditAddressUpdate(address: Address): void {
    this.getFullAddress(address).subscribe(fullAddress => {
      this.auditService.createAudit(() => ({
        message: `Address has been updated`,
        details: { addressName: address.name, fullAddress: fullAddress },
      }));
    });
  }

  private getFullAddress(address: Address): Observable<string> {
    return this.storeConfig.storeConfig$.pipe(
      map(storeConfig => (storeConfig.useInternationalStreetFormat ? address.intlFullAddress : address.fullAddress))
    );
  }
}
