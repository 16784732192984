import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FeaturedItem, Picture } from 'ngx-web-api';
import { MenuWrapperService } from '../../../../core/services/menu-wrapper.service';

@Component({
  selector: 'fts-featured-list-item',
  templateUrl: './featured-list-item.component.html',
  styleUrls: ['./featured-list-item.component.scss'],
})
export class FeaturedListItemComponent implements OnInit, OnChanges {
  @Input()
  fillParentHeight: boolean;
  @Input()
  featuredItem: FeaturedItem;
  @Input()
  skipLazyLoading: boolean;
  @Input()
  itemImgHeight: number;
  @Output()
  chosenFeaturedItem: EventEmitter<FeaturedItem> = new EventEmitter();
  @Output()
  heightChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('imgEl', { static: false })
  private imageElement: ElementRef;

  featuredItemLabel: string;
  featuredItemPicture: Picture;

  constructor(private menuWrapperService: MenuWrapperService) {}

  ngOnInit() {
    this.featuredItemLabel = this.generateFeaturedItemLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['featuredItem'] && !!this.featuredItem) {
      this.featuredItemPicture = this.menuWrapperService.getPicture(this.featuredItem.pictures);
    }
  }

  imgLoaded() {
    this.heightChange.emit(this.imageElement.nativeElement.clientHeight);
  }

  onClickFeaturedItem(featuredItem: FeaturedItem) {
    this.chosenFeaturedItem.emit(featuredItem);
  }

  generateFeaturedItemLabel(): string {
    if (this.featuredItem.item) {
      return `Featured item ${(this.featuredItem.title || '') + (this.featuredItem.subtitle || '') || this.featuredItem.item.name}`;
    } else if (this.featuredItem.special) {
      return `Featured special ${(this.featuredItem.title || '') + (this.featuredItem.subtitle || '') || this.featuredItem.special.label}`;
    } else {
      return 'Featured item';
    }
  }
}
