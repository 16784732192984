import { BaseResource } from '../core/base-resource';

export class GoCartPaymentMethod extends BaseResource {
  paymentToken: string;
  nickName: string;
  cardBrand: string;
  fullNameCard: string;
  last4CardNumber: string;
  expMonth: number;
  expYear: number;
  isDefault: boolean;
  addressId: string;
  needsCvv: boolean;
  externalId: string;

  deserialize(input: any): this {
    return super.deserialize(input);
  }

  serialize(): any {
    return super.serialize();
  }
}
