import { BaseResource } from '../core/base-resource';

export class GroupOrderFriend extends BaseResource {
  groupName?: string;
  email: string;
  name: string;

  _selected: boolean;
  _isInvited: boolean;

  serialize(): any {
    return {
      groupName: this.groupName,
      email: this.email,
      name: this.name,
    };
  }
}
