import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuditService, ConfigService, DeliveryCompany, OrderType, OrderTypeConfig } from 'ngx-web-api';
import { Observable } from 'rxjs';
import { Animations } from '../../animations';

@Component({
  selector: 'fts-order-type-chooser',
  templateUrl: './order-type-chooser.component.html',
  styleUrls: ['./order-type-chooser.component.scss'],
  animations: [Animations.expanded],
})
export class OrderTypeChooserComponent implements OnInit {
  @Input()
  selectedOrderType: OrderType;
  @Input()
  isInHome: boolean;
  @Output()
  onOrderTypeChange: EventEmitter<OrderType> = new EventEmitter<OrderType>(true);
  orderTypes: OrderTypeConfig[];
  selectedOrderTypeConfig: OrderTypeConfig;
  description: string;
  deliveryCompanies$: Observable<DeliveryCompany[]>;

  constructor(private configService: ConfigService, private auditService: AuditService) {}

  ngOnInit() {
    this.configService.orderTypesConfig$.subscribe(orderTypes => {
      this.orderTypes = orderTypes;
      this.showDescription(this.selectedOrderType);
    });
    this.deliveryCompanies$ = this.configService.deliveryCompanies$;
  }

  showDescription(orderType: OrderType) {
    this.selectedOrderTypeConfig = this.orderTypes[this.orderTypes.map(type => type.type).indexOf(orderType)];
    this.description =
      this.selectedOrderTypeConfig && this.selectedOrderTypeConfig.description ? this.selectedOrderTypeConfig.description : '';
  }

  chooseOrderType(orderType: OrderType) {
    this.showDescription(orderType);
    this.onOrderTypeChange.next(orderType);
    this.auditService.createAudit(() => `Setting order type to ${orderType}`);
  }
}
