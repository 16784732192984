<div class="wrapper" [style.height]="height" [ngClass]="{'fillHeightFix': fillParentHeight}">
  <ng-container *ngFor='let featuredItem of featuredItems; let i = index; let first = first; let count = count'>
    <ng-container 
      *ngTemplateOutlet="featuredItem.isClickable ? actionElement : imageOnly;
      context: {
        featuredItem: featuredItem, 
        itemIndex: i, 
        first: first, 
        count: count
      }"
    ></ng-container>
  </ng-container>

  <fts-step-indicator
    [currentStep]='visibleIndex'
    [numberOfSteps]='featuredItems.length'
    (stopStepIndicator)="stopAnimation()"
    (startStepIndicator)="startAnimation()" 
    (currentStepChange)="onVisibleIndexChange($event)">
  </fts-step-indicator>
</div>

<ng-template 
  #actionElement 
  let-featuredItem="featuredItem" 
  let-itemIndex="itemIndex" 
  let-first="first" 
  let-count="count">
  <button
    type="button"
    class="full-width padding-0 margin-0 no-border"
    [attr.aria-label]="featuredItemsButtonLabels[itemIndex]"
    (focus)="focusFeaturedItem(itemIndex)"
    (blur)="startAnimation()"
    (click)="onFeaturedItemChosen(featuredItem, itemIndex + 1)"
    (keydown)="handleKeyDown($event, featuredItem, itemIndex + 1)">
    <fts-slide
      [first]='first'
      [fillParentHeight]='count === 1 || fillParentHeight'
      [featuredItem]='featuredItem'
      [slideVisible]='count === 1 || (itemIndex === visibleIndex) && !fillParentHeight'
      [slideVisibleFillParentHeight]='count === 1 || (itemIndex === visibleIndex) && fillParentHeight'
      [ngClass]="{'cursor-pointer': featuredItem.isClickable}"
      [skipLazyLoading]="skipLazyLoading"
      [itemImgHeight]="itemImgHeight"
      (mouseover)="stopAnimation()"
      (mouseout)="startAnimation()"
      (heightChange)='onImageLoaded($event)'>
    </fts-slide>
  </button>
</ng-template>

<ng-template 
  #imageOnly 
  let-featuredItem="featuredItem" 
  let-itemIndex="itemIndex" 
  let-first="first" 
  let-count="count">
  <div (focus)="focusFeaturedItem(itemIndex)" (blur)="startAnimation()">
    <fts-slide
      [first]='first'
      [fillParentHeight]='count === 1 || fillParentHeight'
      [featuredItem]='featuredItem'
      [slideVisible]='count === 1 || (itemIndex === visibleIndex) && !fillParentHeight'
      [slideVisibleFillParentHeight]='count === 1 || (itemIndex === visibleIndex) && fillParentHeight'
      [ngClass]="{'cursor-pointer': featuredItem.isClickable}"
      [skipLazyLoading]="skipLazyLoading"
      [itemImgHeight]="itemImgHeight"
      (mouseover)="stopAnimation()"
      (mouseout)="startAnimation()"
      (heightChange)='onImageLoaded($event)'>
    </fts-slide>
  </div>
</ng-template>