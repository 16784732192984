import { Component, OnInit } from '@angular/core';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { SpecialWizard } from '../../domain/special-wizard';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecialWizardModalContext } from 'app/domain/special-wizard-modal-context';

@Component({
  selector: 'fts-special-wizard-modal',
  templateUrl: './special-wizard-modal.component.html',
  styleUrls: ['./special-wizard-modal.component.scss'],
})
export class SpecialWizardModalComponent implements OnInit {
  public context: SpecialWizardModalContext;
  specialWizard: SpecialWizard;

  constructor(private uiOrchestratorService: UiOrchestratorService, private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.specialWizard = this.context.specialWizard;
    this.uiOrchestratorService.informModalOpened();
  }

  hideModal(shouldHide: boolean): void {
    if (shouldHide) {
      this.activeModal.close();
    }
  }
}
