<button (click)="onClick()" class="logo-link btn btn-link btn-undecorated" [ngClass]="{'full-width-important': hasFullWidth && !isInPrintReceipt, 'print-receipt-link': isInPrintReceipt}" role="button">
  <!--Id 'store-logo' is used to calculate logo's height in another component-->
  <img [imgSrc]='src'
     id="store-logo"
     [ngClass]="{'full-width': hasFullWidth, 'compact': compact, 'align-center': alignCenter , 'in-pay': inPay, 'in-print-receipt': isInPrintReceipt}"
     [alt]="'component.header.store_logo.store_logo' | translate"
     ftsPicture #pictureRef1='pictureRef'
     (load)='onLoad()'/>
  <fts-ghost-element *ngIf='src && homepage && !pictureRef1?.isLoaded' [height]="itemImgHeight"></fts-ghost-element>
</button>
