/**
 ** TOKEN_SUCCESS ('A0000') Generate token success
 ** ENCRYPTION_FAILURE ('TSEP001') Encryption Failure (transaction expired, need new script initialization)
 ** CVV_LENGTH_ERROR ('TSEPERR101') CVV length error (length should be >=3 && <=4)
 ** DATE_FORMAT_ERROR ('TSEPERR102') Date format error (format should be 'MM/YYYY')
 ** DATE_PAST_ERROR ('TSEPERR103') Expiration date is in past error
 ** INVALID_MONTH_ERROR ('TSEPERR104') Invalid month error (month should be [1 - 12])
 ** CARD_NUM_LENGTH_ERROR ('TSEPERR105') Card number length error (length should be >= 13)
 ** AUTHENTICATION_FAILURE ('TSEPERR911') Authentication failure : 'Incorrect Manifest' || 'Incorrect Domain URL' || 'Incorrect Device ID'
 */
export enum TsepResponseCode {
  TOKEN_SUCCESS = 'A0000',
  ENCRYPTION_FAILURE = 'TSEP001',
  CVV_LENGTH_ERROR = 'TSEPERR101',
  DATE_FORMAT_ERROR = 'TSEPERR102',
  DATE_PAST_ERROR = 'TSEPERR103',
  INVALID_MONTH_ERROR = 'TSEPERR104',
  CARD_NUM_LENGTH_ERROR = 'TSEPERR105',
  AUTHENTICATION_FAILURE = 'TSEPERR911',
}
