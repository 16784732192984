import { GoCartCustomerInfo } from './go-cart-customer-info';
import { GoCartBillingInfo } from './go-cart-billing-info';
import { DeserializationUtils } from '../../utils/public_api';
import { BaseResource } from '../core/base-resource';
import { GoCartTransactionInfo } from './go-cart-transaction-info';

export class GoCartPayment extends BaseResource {
  orderId: string;
  customerInfo: GoCartCustomerInfo;
  billingInfo: GoCartBillingInfo;
  transactionInfo: GoCartTransactionInfo;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      customerInfo: DeserializationUtils.deserializeChild(input, 'customerInfo', GoCartCustomerInfo),
      billingInfo: DeserializationUtils.deserializeChild(input, 'billingInfo', GoCartBillingInfo),
      transactionInfo: DeserializationUtils.deserializeChild(input, 'transactionInfo', GoCartTransactionInfo),
    });
  }

  serialize(): any {
    return {
      orderId: this.orderId,
      customerInfo: this.customerInfo.serialize(),
      billingInfo: this.billingInfo.serialize(),
      transactionInfo: this.transactionInfo.serialize(),
    };
  }
}
