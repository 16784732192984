import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Deserializable } from '../../models/core/deserializable';
import { Link } from '../../models/core/link.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';

@Injectable({ providedIn: 'root' })
export class HypermediaService {
  constructor(private http: HttpClient) {}

  get(links: Link[], rel: string): Observable<Object> {
    const link = this.mapURLToClientHost(this.findLink(links, rel));
    if (link) {
      return this.http.get(link.href);
    }
    throw new Error(`no links exists for ${rel}`);
  }

  findLink(links: Link[], rel: string): Link | undefined {
    return this.mapURLToClientHost(links.find(l => l.rel.toLowerCase() === rel.toLowerCase()));
  }

  getObjFromLinks<T extends Deserializable>(links: Link[], rel: string, type: new () => T): Observable<T> {
    const link = this.mapURLToClientHost(this.findLink(links, rel));
    if (link) {
      return this.getObjFromUrl(link.href, type);
    }
    throw new Error(`no links exists for ${rel}`);
  }

  getObjFromUrl<T extends Deserializable>(url: string, type: new () => T): Observable<T> {
    return this.http.get(url).pipe(map(json => DeserializationUtils.deserializeObj(json, type)));
  }

  getObjArrayFromLinks<T extends Deserializable>(links: Link[], rel: string, type: new () => T): Observable<T[]> {
    const link = this.mapURLToClientHost(this.findLink(links, rel));
    if (link) {
      return this.getObjArrayFromUrl(link.href, type);
    }
    throw new Error(`no links exists for ${rel}`);
  }

  getObjArrayFromUrl<T extends Deserializable>(url: string, type: new () => T): Observable<T[]> {
    return this.http.get(url).pipe(map((json: any[]) => DeserializationUtils.deserializeArray(json, type)));
  }

  /**
   * Only during development try to replace the HATEOAS link's host to match the client's one
   * This is required in order to be able to proxy requests to the web server host that
   * serves the web ordering API. Otherwise we get CORS errors.
   */
  private mapURLToClientHost(obj: Link): Link {
    if (environment.production) {
      return obj;
    }
    if (!!obj?.href) {
      let url;
      try {
        url = new URL(obj.href);
        if (url.host !== location.host) {
          url.host = location.host;
          obj.href = url.href;
        }
      } catch {
        // Ignore invalid URIs
      }
    }
    return obj;
  }
}
