import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { Order } from '../ordering/order.model';

export class GroupOrderMember extends BaseResource {
  email: string;
  name: string;
  orderSequenceNum: number;
  isLeader: boolean;
  order: Order;
  ready: boolean;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      order: DeserializationUtils.deserializeChild(input, 'order', Order),
    });
  }

  serialize(): any {
    return Object.assign({}, this, {
      order: this.order ? this.order.serialize() : undefined,
    });
  }
}
