import { DeserializationUtils } from '../../utils/deserialization-utils';
import { ValidatedResource } from '../core/validated-resource';
import { OrderedIngredient } from './ordered-ingredient.model';

export class OrderedChoice extends ValidatedResource {
  name: string;
  ingredients?: OrderedIngredient[];
  removedDefaults?: OrderedIngredient[];
  removedEverythings?: OrderedIngredient[];

  get firstIngredient(): OrderedIngredient | undefined {
    return (this.ingredients || [])[0];
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      ingredients: DeserializationUtils.deserializeChildren(input, 'ingredients', OrderedIngredient),
      removedDefaults: DeserializationUtils.deserializeChildren(input, 'removedDefaults', OrderedIngredient),
      removedEverythings: DeserializationUtils.deserializeChildren(input, 'removedEverythings', OrderedIngredient),
    });
  }

  serialize(): any {
    return {
      name: this.name,
      ingredients: (this.ingredients || []).map(ingr => ingr.serialize()),
    };
  }
}
