import { BaseResource } from './base-resource';

export class Street extends BaseResource {
  readonly address: string;
  readonly streetNum?: number;
  readonly buildingName: string;
  readonly city: string;
  readonly state: string;
  readonly zip: string;
  readonly partialMatch: boolean;
}
