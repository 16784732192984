import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ModalService } from 'app/core/services/modal.service';
import { ConfigService, Order, OrderService } from 'ngx-web-api';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ValidSelfOrderGuard implements CanActivate {
  constructor(private orderService: OrderService, private configService: ConfigService, private modalService: ModalService) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.orderService.order$.pipe(
      filter((order: Order) => !order.dummy),
      mergeMap(order => this.configService.findOrderTypeConfig(order.orderType).pipe(map(orderType => ({ order, orderType })))),
      map(({ order, orderType }) => {
        if (order?.isSelfOrderSource) {
          if (orderType.needsTableNumber && !order.orderTable) {
            return false;
          }
        }
        return true;
      }),
      tap(canActivate => !canActivate && this.modalService.notValidSelfOrderModal())
    );
  }
}
