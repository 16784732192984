<div class='modal-content primary-bg-color primary-text-color'>
  <div class='modal-header secondary-bg-color secondary-text-color error-bg-color'>
      <h2 id="modal-title" class='modal-title'>{{'component.order_warnings_modal.modifications_required' | translate}}</h2>
      <button class='close float-right' (click)='hideModal(null)' [attr.aria-label]="'component.order_warnings_modal.close' | translate">
        <span aria-hidden='true'>&times;</span>
      </button>
  </div>
  <div class='modal-body'>
    <ng-container *ngFor='let orderWarning of orderWarnings'>
      <p class='fw-500'>{{orderWarning.text}}</p>
    </ng-container>

    <hr *ngIf='itemWarnings?.length > 0 && orderWarnings?.length > 0 ' aria-hidden="true">

    <p *ngIf='itemWarnings?.length > 0' class='fw-500'>{{'component.order_warnings_modal.please_complete' | translate}}</p>
    <ng-container *ngFor='let itemWarning of itemWarnings'>
        <div class='warning-wrapper'>
          <ng-container [ngSwitch]='itemWarning.type'>
            <ng-container *ngSwitchCase='OrderWarningType.ITEM'>

              <button class="btn btn-link no-padding fw-500 margin-bottom-5 font-primary order-warning"
                  (click)='changeRouteAndHideModal(itemWarning.item?.itemId)'>
                <ng-container *ngTemplateOutlet='itemContent'></ng-container>
              </button>

            </ng-container>

            <ng-container *ngSwitchCase='OrderWarningType.SPECIAL'>
              <button class="btn btn-link no-padding fw-500 margin-bottom-5 font-primary order-warning"
                      (click)='showInstructions(itemWarning.special)'>
                <ng-container *ngTemplateOutlet='specialContent'></ng-container>
              </button>
            </ng-container>

          </ng-container>
        </div>

          <div *ngIf='itemWarnings?.length === 1' class="modal-buttons">
            <button class='btn secondary-bg-color secondary-text-color action-btn font-secondary'
              *ngIf='itemWarning.type === OrderWarningType.ITEM'
              (click)='changeRouteAndHideModal(itemWarning.item?.itemId)'>{{'component.order_warnings_modal.take_me_there' | translate}}</button>

            <button class='btn secondary-bg-color secondary-text-color action-btn font-secondary'
              *ngIf='itemWarning.type === OrderWarningType.SPECIAL'
              (click)='showInstructions(itemWarning.special)'>{{'component.order_warnings_modal.take_me_there' | translate}}</button>
          </div>

          <ng-template #itemContent>
            <div>
              <p class='fw-500 margin-bottom-0 text-uppercase'>{{itemWarning.item?.printName}}</p>
              <ng-container *ngIf="itemWarning.item?.warnings?.length">
                <p class='margin-bottom-0 fw-300 warning-description' *ngFor="let singleWarning of itemWarning.item.warnings">{{singleWarning}}</p>
              </ng-container>
            </div>
          </ng-template>

          <ng-template #specialContent>
            <div>
              <p class='fw-500 margin-bottom-0'>{{itemWarning.special?.special}}</p>
            </div>
          </ng-template>

    </ng-container>
  </div>
</div>
