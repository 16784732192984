import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import {
  Utils,
  Constants,
  ConfigService,
  StoreConfig,
  DeferService,
  OrderType,
  StoreStatusService,
  CalendarEvent,
  StoreStatus,
} from 'ngx-web-api';
import { formatISO, getDate, getMonth, getYear, isBefore, format } from 'date-fns';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil, filter, switchMap, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DateFormatPipe } from 'ngx-web-api/lib/pipes/date-format.pipe';

interface UnavailableDate {
  date: Date;
  mode: string;
}

@Component({
  selector: 'fts-calendar-day-chooser',
  templateUrl: './calendar-day-chooser.component.html',
  styleUrls: ['./calendar-day-chooser.component.scss'],
})
export class CalendarDayChooserComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  days: any[];
  @Input()
  selectedDay: string;
  @Input()
  disabled: boolean;
  @Input()
  isOpen: boolean;
  @Input()
  placeholder = '';
  @Input()
  noMargins: boolean;
  @Input()
  startOfWeek: number;
  @Output()
  daySelected = new EventEmitter<string>();

  dateMask = Constants.DATE_MASK;
  formattedSelectedDay: string;
  minDayy: NgbDateStruct;
  maxDayy: NgbDateStruct;
  minDay: Date;
  maxDay: Date;
  theDay: NgbDateStruct;
  unavailableDays: UnavailableDate[];
  calendarEvents: CalendarEvent[] = [];
  isDisabled: (date: NgbDateStruct) => boolean;
  isStoreClosed: (date: NgbDateStruct) => boolean;
  timeZone: string;
  dateFormat = new DateFormatPipe();
  availableDates: NgbDateStruct[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  isEuCountry: boolean;
  storeStatus: StoreStatus;
  calendarEventsDays: NgbDateStruct[];
  calendarEventsClosedDays: NgbDateStruct[];

  constructor(
    private ref: ElementRef,
    private configService: ConfigService,
    private deferService: DeferService,
    private storeStatusService: StoreStatusService
  ) {
    this.storeStatusService.storeStatus$
      .pipe(
        filter(status => status.orderType !== OrderType.Any),
        switchMap(status => this.deferService.calendarEvents$.pipe(map(events => [events, status]))),
        takeUntil(this.destroy$)
      )
      .subscribe(([events, status]: [CalendarEvent[], StoreStatus]) => {
        this.calendarEvents = events.filter(e => e.calendarType === 'StoreClose');
        this.storeStatus = status;
        this.calculateCalendarEventDays();
      });
  }

  ngOnInit() {
    if (!this.selectedDay) {
      this.formattedSelectedDay = '';
    }
  }

  ngOnChanges(changes: any) {
    if (changes['selectedDay']) {
      if (!this.selectedDay) {
        this.formattedSelectedDay = '';
      } else {
        this.theDay = this.ngbDateFormat(Utils.parseIsoDate(this.selectedDay));
        this.configService.storeConfig$.pipe(takeUntil(this.destroy$)).subscribe((cfg: StoreConfig) => {
          this.isEuCountry = cfg.country === 'IE';
          this.setFormattedDay(this.ngbDateFormat(new Date(this.selectedDay)));
        });
      }
    }

    if (changes['days']) {
      this.initMinMaxDays();
      this.initAvailableDays();

      this.isDisabled = (date: NgbDateStruct) =>
        !this.availableDates.some(x => new NgbDate(x.year, x.month, x.day).equals(date)) && !this.isStoreClosed(date);

      this.calculateCalendarEventDays();
      this.isStoreClosed = (date: NgbDateStruct) => {
        this.minDayy =
          !this.calendarEventsClosedDays.length ||
          this.minDayy.day < this.calendarEventsClosedDays[0].day ||
          this.minDayy.month < this.calendarEventsClosedDays[0].month ||
          this.minDayy.year < this.calendarEventsClosedDays[0].year
            ? this.minDayy
            : this.calendarEventsClosedDays[0];

        return !!this.calendarEventsClosedDays.find(d => new NgbDate(d.year, d.month, d.day).equals(date));
      };
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  initMinMaxDays() {
    this.minDayy = this.ngbDateFormat(Utils.parseIsoDate(this.days[0]));
    this.maxDayy = this.ngbDateFormat(Utils.parseIsoDate(this.days[this.days.length - 1]));
  }

  ngbDateFormat(date): NgbDateStruct {
    const dd = getDate(date);
    const mm = getMonth(date) + 1;
    const yyyy = getYear(date);
    return { year: yyyy, month: mm, day: dd };
  }

  initAvailableDays() {
    this.availableDates = [];
    this.days = this.days.map(key => Utils.parseIsoDate(key)).sort((a, b) => (isBefore(a, b) ? -1 : 1));
    this.days.forEach(day => {
      this.availableDates.push(this.ngbDateFormat(day));
    });
  }

  onInputClicked(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.isOpen = true;
  }

  onDaySelected(day: NgbDateStruct) {
    if (this.isStoreClosed(day)) {
      return;
    }
    this.setFormattedDay(day);
    const myDate = new Date(day.year, day.month - 1, day.day);
    this.daySelected.emit(formatISO(Utils.getTzDate(myDate)));
    this.isOpen = false;
  }

  setFormattedDay(day: NgbDateStruct) {
    const myDate = new Date(day.year, day.month - 1, day.day);
    this.formattedSelectedDay = format(myDate, this.isEuCountry ? 'dd/MM/yyyy' : 'MM/dd/yyyy');
  }

  getClosedEventDescription(date): string {
    return (
      this.calendarEvents.find(ce => {
        const ngbDates = ce.allDaysISO.map(d => this.ngbDateFormat(d));
        return ngbDates.find(d => new NgbDate(d.year, d.month, d.day).equals(date));
      }).description || 'Store closed'
    );
  }

  isFullDayEvent(date): boolean {
    return !this.storeStatus.deferredDayTimes[this.dateFormat.transform(date, 'yyyy-MM-dd')];
  }

  isCalendarEventDay(day: NgbDateStruct): boolean {
    return !!this.calendarEventsDays.find(d => new NgbDate(d.year, d.month, d.day).equals(day));
  }

  calculateCalendarEventDays() {
    const eventDays = this.calendarEvents
      .map(ev => ev.allDaysISO)
      .reduce((a, b) => a.concat(b), [])
      .sort((a, b) => (a < b ? -1 : 1));

    this.calendarEventsClosedDays = eventDays.filter(date => this.isFullDayEvent(date)).map(closed => this.ngbDateFormat(closed));

    this.calendarEventsDays = eventDays.map(closed => this.ngbDateFormat(closed));
  }
}
