import { BaseResource } from './base-resource';
import { OrderType } from './order-type.enum';

export class OrderTypeConfig extends BaseResource {
  readonly asapLabel: string;
  readonly displayName?: string;
  readonly hasAsap: boolean;
  readonly needsAddress: boolean;
  readonly needsGuests: boolean;
  readonly needsName: boolean;
  readonly needsPhone: boolean;
  readonly needsTableNumber: boolean;
  readonly promiseTime: number;
  readonly tippable: boolean;
  readonly type: OrderType;
  readonly usesGuests: boolean;
  readonly usesName: boolean;
  readonly usesTableNumber: boolean;
  readonly description: string;
  readonly smsNotify: boolean;
  readonly deferredOrdersWebTimeSlot: number;
  readonly webNoContactEnabled: boolean;
  readonly noContactLabel: string;
  readonly noContactTipLabel: string;
  readonly noContactTipNote: string;
  readonly webDeliveryInstructionsNote: string;
  readonly noContactWebDeliveryInstructionsNote: string;
  readonly noContactDescription: string;
  readonly noContactNote: string;
  readonly instructionsLabel: string;
  readonly instructionsHelpText: string;
  readonly tipLabel: string;
  readonly tipNote: string;
  readonly tipInfoMessage: string;
  readonly hasOrderInstructions: boolean;
  readonly needsOrderInstructions: boolean;
  readonly allowWebDeferredOrders: boolean;
  readonly handleArrivalStatus: boolean;
  readonly webEnabled: boolean;
  readonly page: string;
  readonly allowParkingSpots: boolean;
  readonly hasOrderTracker: boolean;
  readonly maximumOrderInstructionsLength: number;

  get displayNameOrName() {
    return this.displayName || this.type;
  }
}
