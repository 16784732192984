import { Injectable } from '@angular/core';
import { AddressService, Street, Utils, StoreConfig, ConfigService } from 'ngx-web-api';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InitParams } from '../../domain/init-params.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AddressWrapperService extends AddressService {
  private addressValid: Subject<boolean> = new Subject();
  public addressValid$: Observable<boolean> = this.addressValid.asObservable();

  constructor(http: HttpClient, private configService: ConfigService) {
    super(http);
  }

  public isAddressValid(value: boolean) {
    this.addressValid.next(value);
  }

  extractAddressAttributesFromParams(initParams: InitParams): Observable<any> {
    return this.configService.storeConfig$.pipe(
      mergeMap((storeConfig: StoreConfig) => {
        const fromParams = this.initAddressFromParams(initParams);

        if (!Utils.isNullOrUndefined(initParams.lat) && !Utils.isNullOrUndefined(initParams.lng) && !storeConfig.useBuildingsInWeb) {
          return of(fromParams);
        } else if (initParams.address) {
          return this.initAddressFromMatches(initParams.address, initParams.zip, storeConfig).pipe(map(r => ({ ...fromParams, ...r })));
        } else {
          return of(this.getOtherAddressParams(initParams));
        }
      })
    );
  }
  private getOtherAddressParams(initParams: InitParams): {} {
    const addressParamNames = ['addressType', 'zip', 'diff1', 'diff2', 'diff3'];
    const addressParams = {};
    initParams.params.forEach((v, param) => {
      if (addressParamNames.includes(param)) {
        if (param.includes('diff')) {
          param = param.replace('diff', 'value');
        }
        addressParams[param] = v;
      }
    });
    return addressParams;
  }

  private initAddressFromParams(initParams: InitParams): any {
    return {
      street: initParams.address,
      addressType: initParams.addressType,
      value1: initParams.diff1,
      value2: initParams.diff2,
      value3: initParams.diff3,
      lng: initParams.lng,
      lat: initParams.lat,
      zip: initParams.zip,
    };
  }

  private initAddressFromMatches(address: string, zip: string, storeConfig: StoreConfig): Observable<any> {
    return this.getStreetMatches(address, storeConfig.useBuildingsInWeb).pipe(
      catchError(() => of([])),
      map((matchedStreets: Street[]) =>
        matchedStreets.find((matchedStreet: Street) => !matchedStreet.partialMatch && (!!zip ? matchedStreet.zip === zip : true))
      ),
      map((matchedStreet: Street) => {
        return !!matchedStreet
          ? {
              street: matchedStreet.address,
              city: matchedStreet.city,
              state: matchedStreet.state,
              zip: matchedStreet.zip,
              buildingName: matchedStreet.buildingName,
            }
          : {};
      })
    );
  }
}
