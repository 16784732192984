import { Component, forwardRef, Input, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils, StoreConfig } from 'ngx-web-api';
import { faBan } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'fts-themeable-radio',
  templateUrl: './themeable-radio.component.html',
  styleUrls: ['./themeable-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThemeableRadioComponent),
      multi: true,
    },
  ],
})
export class ThemeableRadioComponent implements ControlValueAccessor, OnChanges {
  @Input()
  name: string;
  @Input()
  id: string;
  @Input()
  value: any;
  @Input()
  label: string;
  @Input()
  ingredient: boolean;
  @Input()
  disabled: boolean;
  @Input()
  fullWidth: boolean;
  @Input()
  highlighted: boolean;
  @Input()
  noPadding: boolean;
  @Input()
  private _groupValue: any;
  @Input()
  ariaLabel: string;
  @Input()
  isInModal: boolean;
  @Input()
  topAlignRadio: boolean;
  @Input()
  baselineAlignment: boolean;
  @Input()
  singleDefaultName: string;
  @Input()
  storeConfig: StoreConfig;
  @Input()
  isUnavailable: boolean;
  @Input()
  isHalfAndHalf: boolean;

  @Input()
  calories: number;
  @Input()
  servings: string;
  @Input()
  displayedIngredientPrice: number;
  @Input()
  availableSizes: string;

  checked: boolean;
  focused = false;
  faBan = faBan;
  decriptiveLabel = '';

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.calcChecked();
    this.decriptiveLabel = `${this.ariaLabel || this.value} ${!!this.displayedIngredientPrice ? `${this.displayedIngredientPrice}$ ` : ''}
      ${!!this.calories ? `${this.calories.toString()} cal` : ''} ${!!this.servings ? `per ${this.servings}` : ''}
      ${this.isUnavailable && !this.storeConfig.showSizeSpecificIngredients ? 'Not Available' : ''}
      ${this.isUnavailable && this.storeConfig.showSizeSpecificIngredients ? `Available for ${this.availableSizes} only` : ''}`;
  }

  get groupValue(): any {
    return this._groupValue;
  }

  set groupValue(value: any) {
    this._groupValue = value;
    this.propagateChange(this._groupValue);
    this.calcChecked();
  }

  onGroupSelect(groupValue, event: any) {
    if (!!this.singleDefaultName && this.singleDefaultName === this.groupValue && this.singleDefaultName !== this.value) {
      event.preventDefault();
      event.stopPropagation();
      this.propagateChange(null);
      this.calcChecked();
      return;
    }

    if (!this.disabled && this.groupValue !== groupValue) {
      this.groupValue = groupValue;
      this.calcChecked();
    }
  }

  calcChecked() {
    this.checked = Utils.deepEqual(this.value, this.groupValue);
    if (!this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
  }

  writeValue(obj: any): void {
    this._groupValue = obj;
    this.calcChecked();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {
    /* Just inherit the method from the ControlValueAccessor */
  }

  onInputFocus() {
    this.focused = true;
  }

  onInputBlur() {
    this.focused = false;
  }

  private propagateChange = (_: any) => {};
}
