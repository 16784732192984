import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transforms a color expression like rgb(...) | rgba(...) | '#ff2233' by applying an alpha to the given color.
 * Example usage :
 *
 * 'rgb(23, 44, 54)' | rgba : 0.4
 *
 * 'rgba(23, 44, 54, 0.8)' | rgba : 0.4
 *
 * '#3422ff' | rgba : 0.4
 *
 * '#D37' | rgba : 0.4
 *
 */
@Pipe({
  name: 'rgba',
  pure: true,
})
export class RgbaPipe implements PipeTransform {
  private readonly eightDigitHEXcolorRegex = /^#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/;
  private readonly sixDigitHEXcolorRegex = /^#([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/;
  private readonly threeDigitHEXcolorRegex = /^#([\da-fA-F])([\da-fA-F])([\da-fA-F])/;
  private readonly rgbaColorRegex = /^rgba\(([\d]+),([\d]+),([\d]+),([\d]+|[\d]*.[\d]+)\)/;
  private readonly rgbColorRegex = /^rgb\(([\d]+),([\d]+),([\d]+)\)/;

  transform(colorExpression: string, alpha = '0.4'): string {
    if (!colorExpression || colorExpression.length === 0 || isNaN(parseFloat(alpha)) || parseFloat(alpha) > 1 || parseFloat(alpha) < 0) {
      return null;
    } else {
      return this.generateRGBA(colorExpression, alpha);
    }
  }

  generateRGBA(color: string, alpha: string): string {
    let cache;
    const p = parseInt;
    color = color.replace(/\s\s*/g, '');

    if ((cache = this.eightDigitHEXcolorRegex.exec(color))) {
      cache = [p(cache[1], 16), p(cache[2], 16), p(cache[3], 16)];
    } else if ((cache = this.sixDigitHEXcolorRegex.exec(color))) {
      cache = [p(cache[1], 16), p(cache[2], 16), p(cache[3], 16)];
    } else if ((cache = this.threeDigitHEXcolorRegex.exec(color))) {
      cache = [p(cache[1], 16) * 17, p(cache[2], 16) * 17, p(cache[3], 16) * 17];
    } else if ((cache = this.rgbaColorRegex.exec(color))) {
      cache = [+cache[1], +cache[2], +cache[3]];
    } else if ((cache = this.rgbColorRegex.exec(color))) {
      cache = [+cache[1], +cache[2], +cache[3]];
    } else {
      return null;
    }
    return `rgba(${cache[0]}, ${cache[1]}, ${cache[2]}, ${alpha})`;
  }
}
