import { Injectable } from '@angular/core';
import { ModalService } from 'app/core/services/modal.service';
import {
  Account,
  AccountService,
  ChannelName,
  ConfigService,
  CookieService,
  CustomerSubscription,
  ServiceName,
  SubscriptionsService,
} from 'ngx-web-api';
import { SmsModalComponent } from './sms-modal.component';
import { SmsModalContext } from './sms-modal.context';
import { Observable, noop } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SmsModalServie {
  account: Account = null;
  hasSmsOffersCoupon: boolean = false;

  constructor(
    private cookieService: CookieService,
    private modalService: ModalService,
    private accountService: AccountService,
    private storeConfig: ConfigService,
    private http: HttpClient
  ) {
    this.accountService.account$.subscribe(user => {
      this.account = user.copy();
    });
    this.storeConfig.storeConfig$.subscribe(store => {
      this.hasSmsOffersCoupon = store.hasSmsOffersCoupon;
    });
  }

  initSmsModal() {
    if (this.shouldOpenModal()) {
      this.modalService.openModal(SmsModalComponent, new SmsModalContext().getConfig()).subscribe(noop);
    }
  }

  fetchCoupon(): Observable<any> {
    //here will make the http call to get the coupon info
    return this.http.get('/ws/integrated/v1/ordering/config/smsOffersCoupon');
  }

  /**
   * This method is resposible desiding if the modal should open or not.
   * Any cases that affect this should be added here
   *
   * case 1 : User has the cookie set on his browser
   * case 2 : User is already subscribed.
   *
   * @returns if we should show the modal or not.
   */
  private shouldOpenModal(): boolean {
    // Cookie Guard
    const cookie_value = this.cookieService.getSmsAcknowledgementCookie();
    if (cookie_value === '1') {
      return false;
    }

    if (!this.hasSmsOffersCoupon) {
      return false;
    }

    // User Has already subscribed
    if (!this.account) return true;
    for (const sub of this.account.subscriptions) {
      if (
        sub.channelName === ChannelName.SMS &&
        sub.services.findIndex(subService => subService.serviceName == ServiceName.OFFERS && subService.subscribed) !== -1
      ) {
        return false;
      }
    }

    return true;
  }

  addSmsChannel(subscriptions: CustomerSubscription[]): CustomerSubscription[] {
    const subsciptionToAdd = new CustomerSubscription();
    subsciptionToAdd.channelName = ChannelName.SMS;
    subscriptions.push(subsciptionToAdd);
    return subscriptions;
  }

  addOffersService(subscriptions: CustomerSubscription[]): CustomerSubscription[] {
    //Preper the service
    const serviceToAdd = new SubscriptionsService();
    serviceToAdd.serviceName = ServiceName.OFFERS;
    serviceToAdd.subscribed = true;

    //Add the service to the channel
    for (let i = 0; i < subscriptions.length; i++) {
      if (subscriptions[i].channelName === ChannelName.SMS) {
        const offersIndex = subscriptions[i].services?.findIndex(service => service.serviceName == ServiceName.OFFERS);
        if (offersIndex == -1) {
          subscriptions[i].services.push(serviceToAdd);
          return subscriptions;
        } else if (offersIndex === undefined) {
          subscriptions[i].services = [serviceToAdd];
        } else {
          subscriptions[i].services[offersIndex].subscribed = true;
          return subscriptions;
        }
      }
    }
    return subscriptions;
  }
}
