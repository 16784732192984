import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable, Subject, of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { AccountCharity } from '../../models/account/account-charity.model';
import { Account } from '../../models/account/account.model';
import { CustomerInfo } from '../../models/account/customer-info.model';
import { PasswordUpdateData } from '../../models/account/password-update-data.model';
import { AcceptHeader } from '../../models/core/accept-header.enum';
import { Order } from '../../models/ordering/order.model';
import { AuditService } from '../core/audit.service';
import { HypermediaService } from '../core/hypermedia.service';
import { VerificationRequestData } from '../../models/core/verification-request-data.model';
import { DeserializationUtils } from '../../utils/public_api';
import { CustomerSubscription } from '../../models/account/customer-subscription.model';
import { ChannelName, ServiceName } from 'ngx-web-api/lib/models/public_api';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/account';
  private readonly HATEOAS_HEADERS = new HttpHeaders({ Accept: AcceptHeader.HATEOAS });

  private account: BehaviorSubject<Account> = new BehaviorSubject(Account.createDummyAccount());
  public account$: Observable<Account> = this.account.asObservable();

  private userLogin: Subject<Account> = new Subject();
  public userLogin$: Observable<Account> = this.userLogin.asObservable();

  private userLogout: Subject<void> = new Subject<void>();
  public userLogout$: Observable<void> = this.userLogout.asObservable();

  private accountDeleted: Subject<void> = new Subject<void>();
  public accountDeleted$: Observable<void> = this.accountDeleted.asObservable();

  private accountVerified: Subject<void> = new Subject<void>();
  public accountVerified$: Observable<void> = this.accountVerified.asObservable();

  private markedForDelete: boolean;
  public smsDisclaimer: string = '';

  constructor(private http: HttpClient, private auditService: AuditService, private hypermedia: HypermediaService) {}

  public login(email: string, password: string, recaptcha?: string): Observable<Account> {
    return this.http
      .post(
        `${this.BASE_PATH}/login`,
        {
          email,
          password,
          recaptcha: !!recaptcha ? encodeURIComponent(recaptcha) : undefined,
        },
        { headers: this.HATEOAS_HEADERS }
      )
      .pipe(
        map((json: Object) => new Account().deserialize(json)),
        tap((account: Account) => this.auditService.createAudit(() => `User ${account.email} has logged in`)),
        tap((account: Account) => this.account.next(account)),
        tap((account: Account) => this.userLogin.next(account))
      );
  }

  public setSMSDisclaimer(context: string) {
    this.smsDisclaimer = context;
  }

  public fetchAccount(latest = false): Observable<Account> {
    let params: HttpParams = new HttpParams();
    if (latest) {
      params = params.set('latest', latest.toString());
    }
    return this.http
      .get(this.BASE_PATH, {
        headers: this.HATEOAS_HEADERS,
        params,
      })
      .pipe(
        map((json: Object): Account => new Account().deserialize(json)),
        catchError(() => of(new Account())),
        tap((account: Account) => this.account.next(account))
      );
  }

  public oAuthLogin(token: string): Observable<Account> {
    return this.http.post(`${this.BASE_PATH}/oauth/token/authenticate`, { token }, { headers: this.HATEOAS_HEADERS }).pipe(
      map((json: Object): Account => new Account().deserialize(json)),
      tap((account: Account) => this.auditService.createAudit(() => `User ${account.email} has logged in using an oauth token`)),
      tap((account: Account) => this.account.next(account)),
      tap((account: Account) => this.userLogin.next(account))
    );
  }

  public authTokenLogin(email: string, token: string): Observable<Account> {
    return this.http.post(`${this.BASE_PATH}/auth/token/authenticate`, { email, token }, { headers: this.HATEOAS_HEADERS }).pipe(
      map((json: Object): Account => new Account().deserialize(json)),
      tap((account: Account) => this.auditService.createAudit(() => `User ${account.email} has logged in using an auth token`)),
      tap((account: Account) => this.account.next(account)),
      tap((account: Account) => this.userLogin.next(account))
    );
  }

  public forgotPassword(email: string): Observable<any> {
    return this.http
      .put(`${this.BASE_PATH}/remindPassword`, { email })
      .pipe(tap(() => this.auditService.createAudit(() => `Email has been sent to ${email} reset the password`)));
  }

  public resetPassword(password: string, passwordConfirmation: string, key: string): Observable<any> {
    return this.http
      .put(`${this.BASE_PATH}/resetPassword`, { password, passwordConfirmation, key })
      .pipe(tap(() => this.auditService.createAudit(() => 'Password has been reset')));
  }

  public verify(token: string): Observable<any> {
    return this.http.put(`${this.BASE_PATH}/verify`, { token }).pipe(
      tap(() => this.auditService.createAudit(() => 'Email has been verified')),
      mergeMap(response => this.fetchAccount().pipe(map(() => response))),
      tap(() => this.accountVerified.next())
    );
  }

  public unsubscribe(email: string): Observable<any> {
    return this.http
      .put(`${this.BASE_PATH}/unsubscribe`, { email })
      .pipe(tap(() => this.auditService.createAudit(() => 'Email has been unsubscribed')));
  }

  public unsubscribeOrderSurveys(email: string): Observable<any> {
    return this.http.put(`${this.BASE_PATH}/unsubscribeOrderSurveys`, { email }).pipe(
      tap(() => this.auditService.createAudit(() => `Email ${email} has been unsubscribed from order surveys`)),
      mergeMap(() => this.fetchAccount())
    );
  }

  public logout(): Observable<any> {
    if (this.markedForDelete) {
      return EMPTY;
    }
    this.auditService.createAudit(() => `User ${this.account.value.email} has logged out`);
    return this.http.delete(this.BASE_PATH + '/logout').pipe(tap(() => this.userLogout.next()));
  }

  public register(account: Account): Observable<Account> {
    if (account.smsMarketing) {
      account.smsDisclaimer = this.smsDisclaimer;
    }

    return this.http.post(this.BASE_PATH, account.serialize(), { headers: this.HATEOAS_HEADERS }).pipe(
      map((json: Object): Account => new Account().deserialize(json)),
      tap((ac: Account) => this.auditService.createAudit(() => `Account ${ac.email} has been created`)),
      tap((ac: Account) => this.account.next(ac))
    );
  }

  public getCustomerInfoFromZZZ(zzz: string): Observable<CustomerInfo> {
    return this.http.get(`${this.BASE_PATH}/${zzz}`).pipe(map((json: Object): CustomerInfo => new CustomerInfo().deserialize(json)));
  }

  public updateCustomerSubcriptions(subscriptions: CustomerSubscription[]): Observable<CustomerSubscription[]> {
    const subs = (subscriptions || []).map(sub => sub.serialize());

    return this.http
      .put<CustomerSubscription[]>(`${this.BASE_PATH}/subscriptions`, {
        channelDTOS: subs,
        smsDisclaimer: this.checkedIfSmsChannelSubscribed(subscriptions) ? this.smsDisclaimer : undefined,
      })
      .pipe(map((json: Object[]): CustomerSubscription[] => DeserializationUtils.deserializeArray(json, CustomerSubscription)));
  }

  public updateGuestCustomerSubcriptions(
    subscriptions: CustomerSubscription[],
    name: string,
    email: string,
    cellPhone: string
  ): Observable<CustomerSubscription[]> {
    const subs = (subscriptions || []).map(sub => sub.serialize());

    return this.http
      .post<CustomerSubscription[]>(`/ws/integrated/v1/ordering/customer/subscriptions`, {
        subscriptions: subs,
        smsDisclaimer: this.smsDisclaimer,
        firstName: name,
        lastName: name,
        email,
        cellPhone,
      })
      .pipe(map((json: Object[]): CustomerSubscription[] => DeserializationUtils.deserializeArray(json, CustomerSubscription)));
  }

  public updateAccount(newAccount: Account): Observable<Account> {
    if (this.checkedIfSmsChannelSubscribed(newAccount.subscriptions)) {
      newAccount.smsDisclaimer = this.smsDisclaimer;
    }

    return this.http.put(this.BASE_PATH, newAccount.serialize(), { headers: this.HATEOAS_HEADERS }).pipe(
      map((json: Object): Account => new Account().deserialize(json)),
      tap((account: Account) => this.auditAccountUpdate(account)),
      tap((account: Account) => this.account.next(account))
    );
  }

  public updatePassword(password: PasswordUpdateData): Observable<any> {
    return this.http
      .put(`${this.BASE_PATH}/password`, password.serialize())
      .pipe(tap(() => this.auditService.createAudit(() => 'Password has been updated')));
  }

  public updateFavoriteStore(account: Account): Observable<Account> {
    return this.http.put(`${this.BASE_PATH}/favoriteStore `, account.serialize()).pipe(
      map((json: Object): Account => new Account().deserialize(json)),
      tap(() => this.auditService.createAudit(() => 'Favorite store has been updated')),
      tap(acc => this.account.next(acc))
    );
  }

  public deleteAccount(): Observable<any> {
    return this.http.delete(this.BASE_PATH).pipe(
      map((): any => {}),
      tap(() => {
        this.markedForDelete = true;
        const accountEmail = this.account.getValue().email;
        this.auditService.createAudit(() => `Account ${accountEmail} has been deleted`);
        this.accountDeleted.next();
      }),
      finalize(() => (this.markedForDelete = false))
    );
  }

  public resendVerificationEmail(email: string): Observable<any> {
    return this.http
      .post(`${this.BASE_PATH}/resendVerificationEmail`, { email: email })
      .pipe(tap(() => this.auditService.createAudit(() => `Email verification has been sent to ${email}`)));
  }

  public getCharities(): Observable<AccountCharity[]> {
    return this.account.pipe(
      filter((account: Account) => account.isInstantiated),
      mergeMap((account: Account) => this.hypermedia.get(account.links, 'charities')),
      map((json: Object[]) => (json || []).map(v => new AccountCharity().deserialize(v)))
    );
  }

  public getMyOrders(): Observable<Order[]> {
    return this.account$.pipe(
      filter((account: Account) => account.isInstantiated),
      mergeMap((account: Account) => this.hypermedia.get(account.links, 'orders')),
      map((json: Object[]): Order[] => (json || []).map(o => new Order().deserialize(o)))
    );
  }

  public getOrderById(orderId: string): Observable<Order> {
    return this.account$.pipe(
      filter((account: Account) => account.isInstantiated),
      mergeMap(() => this.http.get(`${this.BASE_PATH}/orders/${encodeURIComponent(orderId)}`)),
      map(json => DeserializationUtils.deserializeObj(json, Order))
    );
  }

  public markOrderAsFavorite(orderId: string): Observable<any> {
    return this.http.post(`${this.BASE_PATH}/orders/favoriteOrder`, !!orderId ? { orderId: orderId } : {});
  }

  public sendVerificationCode(verificationRequestData: VerificationRequestData): Observable<any> {
    return this.http
      .post(`${this.BASE_PATH}/verification`, verificationRequestData)
      .pipe(tap(() => this.auditService.createAudit(() => `Verification code has been send to ${verificationRequestData.phone}`)));
  }

  public sendVerificationPhone(phone: string): Observable<Object> {
    return this.http
      .post(`${this.BASE_PATH}/phoneVerification`, {})
      .pipe(tap(() => this.auditService.createAudit(() => `Phone verification successfully requested for ${phone}`)));
  }

  public setMarkForDelete(value: boolean): void {
    this.markedForDelete = value;
  }

  public checkedIfSmsChannelSubscribed(subscriptions: CustomerSubscription[]): boolean {
    return !!subscriptions.find(sub => sub.channelName === ChannelName.SMS)?.services.find(s => s.subscribed);
  }

  public checkedIfSmsOffersChannelSubscribed(subscriptions: CustomerSubscription[]): boolean {
    return !!subscriptions
      .find(sub => sub.channelName === ChannelName.SMS)
      ?.services.find(s => s.subscribed && s.serviceName === ServiceName.OFFERS);
  }

  /************************************************ Auditing helpers ************************************************/

  private auditAccountUpdate(account: Account): void {
    const { firstName, lastName, phone, email, subscribed, smsMarketing, subscribedOrderSurveys } = account;
    const details = { firstName, lastName, phone, email, subscribed, smsMarketing, subscribedOrderSurveys };
    this.auditService.createAudit(() => ({ message: `Account ${account.email} has been updated`, details }));
  }
}
