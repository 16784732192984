import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { OrderPayment } from '../../models/ordering/order-payment.model';
import { PaymentSetup } from '../../models/ordering/payment-setup';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { AuditService } from '../core/audit.service';
import { GiftCardsService } from '../core/gift-cards.service';
import { OrderService } from './order.service';
import { PaymentSetupRequest } from '../../models/ordering/payment-setup-request.model';
import { PaymentSetupType } from '../../models/ordering/payment-setup-type.enum';

@Injectable({ providedIn: 'root' })
export class OrderPaymentsService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/order/payments';

  private orderPayments: BehaviorSubject<OrderPayment[]> = new BehaviorSubject([]);
  public orderPayments$: Observable<OrderPayment[]> = this.orderPayments.asObservable();

  public finishedWithExternalPayment: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public finishedWithExternalPayment$: Observable<boolean> = this.finishedWithExternalPayment.asObservable();

  public giftCardPayments$: Observable<OrderPayment[]> = this.orderPayments.pipe(
    map((orderPayments: OrderPayment[]) => orderPayments.filter(orderPayment => orderPayment.isGiftCard))
  );

  public cashPayment$: Observable<OrderPayment> = this.orderPayments.pipe(
    map((orderPayments: OrderPayment[]) => orderPayments.filter(orderPayment => orderPayment.isCash)[0])
  );

  public creditCardPayment$: Observable<OrderPayment> = this.orderPayments.pipe(
    map((orderPayments: OrderPayment[]) => orderPayments.filter(orderPayment => orderPayment.isCreditCard)[0])
  );

  public nonCashPayments$: Observable<OrderPayment[]> = this.orderPayments.pipe(
    map((orderPayments: OrderPayment[]) => orderPayments.filter(orderPayment => !orderPayment.isCash))
  );

  public invoicePayment$: Observable<OrderPayment> = this.orderPayments.pipe(
    map((orderPayments: OrderPayment[]) => orderPayments.filter(orderPayment => orderPayment.isInvoice)[0])
  );

  public giftCardPaymentsTotal$: Observable<number> = this.giftCardPayments$.pipe(
    map((giftCardPayments: OrderPayment[]) => giftCardPayments.map(payment => payment.amount).reduce((a, b) => a + b, 0)),
    map(giftCardPaymentsTotal => parseFloat(giftCardPaymentsTotal.toFixed(2)))
  );

  constructor(
    private http: HttpClient,
    private orderService: OrderService,
    private giftCardService: GiftCardsService,
    private auditService: AuditService
  ) {}

  public fetchPayments(): Observable<OrderPayment[]> {
    return this.http.get(this.BASE_PATH).pipe(
      map((json: Object[]) => DeserializationUtils.deserializeArray(json, OrderPayment)),
      catchError(() => of([])),
      tap((orderPayments: OrderPayment[]) => this.orderPayments.next(orderPayments))
    );
  }

  public addPayment(payment: OrderPayment): Observable<OrderPayment> {
    return this.http.post(this.BASE_PATH, payment.serialize()).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, OrderPayment)),
      mergeMap(p => this.refreshRelatedData(payment).pipe(map(() => p)))
    );
  }

  public updatePayment(payment: OrderPayment): Observable<OrderPayment> {
    return this.http.put(this.BASE_PATH, payment.serialize()).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, OrderPayment)),
      tap(() => this.auditService.createAudit(() => `${payment.paymentType} payment has been updated`)),
      mergeMap(o => this.fetchPayments().pipe(map(() => o)))
    );
  }

  public deletePayment(payment: OrderPayment): Observable<void> {
    return this.http.delete(`${this.BASE_PATH}/${payment.ordinal}`).pipe(
      tap(() => this.auditService.createAudit(() => `${payment.paymentType} payment has been deleted`)),
      mergeMap(() => this.refreshRelatedData(payment).pipe(map(() => {})))
    );
  }

  public canFullyPayWithGiftCards(): Observable<boolean> {
    return combineLatest([this.giftCardPaymentsTotal$, this.orderService.order$]).pipe(
      map(([giftCardPaymentsTotal, order]) => giftCardPaymentsTotal >= order.price)
    );
  }

  public setupWorldpay(isMobileSource?: boolean, recaptcha?: string): Observable<PaymentSetup> {
    const paymentSetupRequest: PaymentSetupRequest = {
      type: PaymentSetupType.WORLDPAY,
      isMobileSource: !!isMobileSource,
    };
    if (!!recaptcha) {
      paymentSetupRequest.recaptcha = encodeURIComponent(recaptcha);
    }

    return this.http
      .post(`${this.BASE_PATH}/setup`, paymentSetupRequest)
      .pipe(map((json: Object) => DeserializationUtils.deserializeObj(json, PaymentSetup)));
  }

  private refreshRelatedData(payment: OrderPayment): Observable<any> {
    const observables$: Array<Observable<any>> = [this.fetchPayments()];
    if (payment.isGiftCard) {
      observables$.push(this.giftCardService.fetchAuthorizedGiftCardsWrapper(), this.orderService.fetchOrder());
    }
    return forkJoin(observables$);
  }
}
