import { BrowserStorageHandlerService } from './services/browser-storage-handler.service';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
  AccountService,
  AuditService,
  ConfigService,
  FeaturedContentService,
  GroupOrderService,
  OrderPaymentTypesService,
  OrderService,
  StoreStatusService,
  TranslationService,
  TimezoneService,
} from 'ngx-web-api';
import { TextMaskModule } from 'angular2-text-mask';
import { HomeGuard } from '../home/home-guard';
import { HomeComponent } from '../home/home.component';
import { IntroGuard } from '../intro/intro-guard';
import { NoSkipIntroGuard } from '../intro/no-skip-intro.guard';
import { IntroComponent } from '../intro/intro.component';
import { SharedModule } from '../shared/shared.module';
import { initApplicationData, initThemeFactory } from './app-initializer';
import { FooterComponent } from './footer/footer.component';
import { BannerHeaderComponent } from './header/banner-header/banner-header.component';
import { CollapsibleAccountMenuComponent } from './header/collapsible-account-menu/collapsible-account-menu.component';
import { CompactHeaderComponent } from './header/compact-header/compact-header.component';
import { HeaderComponent } from './header/header.component';
import { MenuLinkComponent } from './header/menu-link/menu-link.component';
import { MenuLinksComponent } from './header/menu-links/menu-links.component';
import { MobileAccountMenuComponent } from './header/mobile-account-menu/mobile-account-menu.component';
import { PlainAccountMenuComponent } from './header/plain-account-menu/plain-account-menu.component';
import { StoreInfoComponent } from './header/store-info/store-info.component';
import { StoreLogoComponent } from './header/store-logo/store-logo.component';
import { PromptGuestAddressModalComponent } from './prompt-guest-address-modal/prompt-guest-address-modal.component';
import { GTMService } from './services/gtm.service';
import { InitParamsStorageService } from './services/init-params-storage.service';
import { MenuWrapperService } from './services/menu-wrapper.service';
import { ThemeService } from './services/theme.service';
import { AddressWrapperService } from './services/address-wrapper.service';
import { StoreSelectionComponent } from './header/store-info/store-selection/store-selection.component';
import { StorePhoneComponent } from './header/store-phone/store-phone.component';
import { OrderTypeSelectorComponent } from './header/store-info/store-selection/order-type-selector/order-type-selector.component';
import { MerchantStoreSelectorComponent } from './header/store-info/store-selection/merchant-store-selector/merchant-store-selector.component';
import { AddressSessionStorageService } from './services/address-session-storage.service';
import { RxStompService } from '@stomp/ng2-stompjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InitializationErrorsHandlerService } from '../services/initialization-errors-handler.service';
import { PolicyAlertComponent } from './policy-alert/policy-alert.component';
import { VerticalMenuLinksComponent } from './header/vertical-menu-links/vertical-menu-links.component';
import { StoreFinderFormComponent } from './header/store-info/store-selection/store-finder-form/store-finder-form.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TrackOrderBtnComponent } from './header/track-order-link/track-order-btn/track-order-btn.component';
import { MinifiedHeaderComponent } from './header/minified-header/minified-header.component';
import { MenuCartComponent } from './header/menu-cart/menu-cart.component';
import { SidebarMenuComponent } from './header/sidebar-menu/sidebar-menu.component';
import { CartButtonComponent } from './header/cart-button/cart-button.component';
import { HamburgerButtonComponent } from './header/hamburger-button/hamburger-button.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    FontAwesomeModule,
    TranslateModule,
    ScrollingModule,
  ],
  declarations: [
    HeaderComponent,
    IntroComponent,
    FooterComponent,
    PromptGuestAddressModalComponent,
    MobileAccountMenuComponent,
    CollapsibleAccountMenuComponent,
    PlainAccountMenuComponent,
    StoreInfoComponent,
    StoreLogoComponent,
    MenuLinkComponent,
    HomeComponent,
    CompactHeaderComponent,
    BannerHeaderComponent,
    MenuLinksComponent,
    StoreSelectionComponent,
    StorePhoneComponent,
    OrderTypeSelectorComponent,
    MerchantStoreSelectorComponent,
    PolicyAlertComponent,
    VerticalMenuLinksComponent,
    StoreFinderFormComponent,
    TrackOrderBtnComponent,
    MinifiedHeaderComponent,
    MenuCartComponent,
    SidebarMenuComponent,
    CartButtonComponent,
    HamburgerButtonComponent,
  ],
  providers: [
    RxStompService,
    IntroGuard,
    NoSkipIntroGuard,
    HomeGuard,
    Title,
    GTMService,
    InitParamsStorageService,
    MenuWrapperService,
    AddressWrapperService,
    AddressSessionStorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApplicationData,
      deps: [
        ConfigService,
        GroupOrderService,
        InitParamsStorageService,
        AccountService,
        OrderService,
        MenuWrapperService,
        OrderPaymentTypesService,
        FeaturedContentService,
        AddressWrapperService,
        StoreStatusService,
        AddressSessionStorageService,
        AuditService,
        TranslationService,
        TranslateService,
        InitializationErrorsHandlerService,
        GTMService,
        TimezoneService,
        BrowserStorageHandlerService,
      ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initThemeFactory,
      deps: [ThemeService],
      multi: true,
    },
  ],
  exports: [HeaderComponent, FooterComponent, IntroComponent, HomeComponent, PolicyAlertComponent, StoreLogoComponent],
})
export class CoreModule {}
