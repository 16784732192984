import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { OrderableSpecialStepAlternative } from './orderable-special-step-alternative.model';
import { OrderableSpecialDefaultChoice } from './orderable-special-default-choice.model';

export class OrderableSpecialStep extends BaseResource {
  title: string;
  ordinal: number;
  alternatives: OrderableSpecialStepAlternative[];
  isBucket: boolean;
  bucketQuantity: number;
  hideIfDefault: boolean;
  defaultChoices: OrderableSpecialDefaultChoice[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      alternatives: DeserializationUtils.deserializeChildren(input, 'alternatives', OrderableSpecialStepAlternative),
      defaultChoices: DeserializationUtils.deserializeChildren(input, 'defaultChoices', OrderableSpecialDefaultChoice),
    });
  }
}
