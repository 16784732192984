<div #modalRef class='modal-content primary-bg-color primary-text-color'>
  <div class='modal-header secondary-bg-color secondary-text-color'>
    <h2 id="modal-title" class='modal-title float-left'>{{'component.coupon_instructions_modal.details' | translate}}</h2>
    <button class='close float-right' (click)='hideModal(null)' [attr.aria-label]="'component.coupon_instructions_modal.close' | translate">
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div [ngSwitch]="coupon?.errorType" class='modal-body'>
    <h3 class="h5 fw-600">{{coupon?.name}}</h3>

    <p *ngIf="coupon?.instructions">{{coupon?.instructions}}</p>
    <p *ngIf="coupon?.disclaimer">{{coupon?.disclaimer}}</p>

    <div class="coupon-errors" *ngSwitchCase="'NOT_LOGGED_IN'">
      <p>{{'component.coupon_instructions_modal.sign_in_before' | translate: {coupon: coupon.name} }}</p>
      <p>{{'component.coupon_instructions_modal.no_account' | translate}}<a [routerLink]="['/register']" (click)="hideModal(null)" class="selection-text-color">{{'component.coupon_instructions_modal.sign_up' | translate}}</a>
        {{'component.coupon_instructions_modal.verify_email' | translate}}</p>
    </div>
    <div class="coupon-errors" *ngSwitchCase="'NOT_VERIFIED'">
      <p>{{'component.coupon_instructions_modal.need_verified' | translate:{coupon: coupon.name} }}</p>
      <p>{{'component.coupon_instructions_modal.cant_find' | translate}} <a [routerLink]="['/account/profile']" (click)="hideModal(null)" class="selection-text-color">
        {{'component.coupon_instructions_modal.go_account' | translate}}
      </a>
        {{'component.coupon_instructions_modal.to_resend' | translate}}</p>
    </div>
    <ng-container *ngSwitchDefault>
      <div class="coupon-errors" *ngIf="coupon.errorType !== 'APPLICATION_FAILED' && coupon?.error">{{ coupon?.error }}</div>
      <div class="coupon-errors" *ngIf="coupon.errorType === 'APPLICATION_FAILED'">
        {{'component.coupon_instructions_modal.not_applicable' | translate}}
      </div>
    </ng-container>

    <div class='modal-buttons'>
      <button *ngIf="coupon?.errorType === 'NOT_LOGGED_IN'" class="btn primary-bg-color action-text-color text-uppercase dismiss-btn action-btn font-secondary" (click)='hideModal(null)'>{{'component.coupon_instructions_modal.no_thanks' | translate}}</button>
      <button *ngIf="coupon?.errorType === 'NOT_LOGGED_IN'" (click)="handleLogin()" class="btn action-bg-color action-inverse-text-color text-uppercase primary-btn action-btn font-secondary">
        {{'component.coupon_instructions_modal.sign_in' | translate}}</button>
    </div>
  </div>
</div>
