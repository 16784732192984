import { OrderableSpecial } from 'ngx-web-api';
import { BasicModalContext } from '../domain/basic-modal-context';

export class SpecialInstructionsModalContext extends BasicModalContext {
  constructor(public special: OrderableSpecial) {
    super();
    this.class = 'sm';
    this.config = { special };
  }
}
