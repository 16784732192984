import { SpecialWizardStep } from './special-wizard-step';
import { OrderableSpecial, UpsellType } from 'ngx-web-api';

export class SpecialWizard {
  currentStepIndex = 0;
  currentStep?: SpecialWizardStep;
  isLastStepSelected: boolean;
  isUpsell?: boolean;
  upsellType?: UpsellType;
  featuredContentName?: string;
  featuredAreaName?: string;
  removePending?: boolean;

  constructor(public orderableSpecial: OrderableSpecial, public steps: SpecialWizardStep[]) {
    this.changeStep();
  }

  setCurrentStepIndex(stepIndex: number): void {
    this.currentStepIndex = stepIndex;
    this.changeStep();
  }

  goToNextStep(): void {
    this.currentStepIndex++;
    this.changeStep();
  }

  goToPreviousStep(): void {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.changeStep();
    }
  }

  getPreviousSteps(step: SpecialWizardStep): SpecialWizardStep[] {
    if (step.stepIndex > 0) {
      return this.steps.filter(s => s.stepIndex < step.stepIndex);
    }

    return [];
  }

  hasAllPreviousStepsFulfilled(step: SpecialWizardStep): boolean {
    const previousSteps = this.getPreviousSteps(step);

    if (previousSteps.length > 0) {
      return previousSteps.every(s => s.isFulfilled);
    }

    return true;
  }

  private changeStep(): void {
    this.currentStep = this.steps[this.currentStepIndex];
    this.isLastStepSelected = this.steps.length <= this.currentStepIndex + 1;
  }

  get isComplete(): boolean {
    return this.steps.every(step => !!step.selectedOrderedItem && !step.selectedOrderedItem.hasInvalidChoice);
  }
}
