import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AccountResourceConfig, ConfigService } from 'ngx-web-api';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable()
export class AccountResourceConfigResolver implements Resolve<AccountResourceConfig> {
  private accountConfig: AccountResourceConfig;

  constructor(private configService: ConfigService) {}

  resolve(): Observable<AccountResourceConfig> {
    let accountConfig$: Observable<AccountResourceConfig> = of(this.accountConfig);

    if (!this.accountConfig) {
      accountConfig$ = this.configService.getAccountResourceConfig().pipe(
        tap(c => (this.accountConfig = c)),
        take(1)
      );
    }

    return accountConfig$;
  }
}
