import { Component, OnInit } from '@angular/core';
import { GroupOrder, GroupOrderFriend } from 'ngx-web-api';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { InvitationDialogConfig } from '../../domain/invitation-dialog-config';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GrouporderInvitationModalContext } from 'app/domain/grouporder-invitation-modal-context';

@Component({
  selector: 'fts-grouporder-invitation-modal',
  templateUrl: './grouporder-invitation-modal.component.html',
  styleUrls: ['./grouporder-invitation-modal.component.scss'],
})
export class GrouporderInvitationModalComponent implements OnInit {
  public context: GrouporderInvitationModalContext;
  groupOrder?: GroupOrder;
  groupOrderingFriends: GroupOrderFriend[];
  invitationDialogConfig: InvitationDialogConfig;
  changeExpirationMode: boolean;

  constructor(private uiOrchestratorService: UiOrchestratorService, private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.groupOrder = this.context.groupOrder;
    this.groupOrderingFriends = this.context.groupOrderingFriends;
    this.invitationDialogConfig = this.context.invitationDialogConfig;
    this.changeExpirationMode = this.context.changeExpirationMode;
    this.uiOrchestratorService.informModalOpened();
  }

  handleDone(): void {
    this.activeModal.close(null);
  }
}
