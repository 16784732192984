import { Pipe, PipeTransform } from '@angular/core';

import { GenericObject } from 'ngx-web-api';

@Pipe({
  name: 'errorList',
})
export class ErrorListPipe implements PipeTransform {
  transform(v: GenericObject<string[]>, _args?: any): any {
    if (!v) {
      return new Array<string>();
    }

    return Object.getOwnPropertyNames(v)
      .filter(p => !(v[p] instanceof Function))
      .reduce((acc, p) => {
        acc.push(...v[p]);
        return acc;
      }, new Array<string>());
  }
}
