import { Utils } from '../../utils/utils';
import { ValidatedResource } from '../core/validated-resource';

export class Invoice extends ValidatedResource {
  ordinal: number;
  businessName: string;
  accountNumber: string;
  firstName: string;
  lastName: string;
  phone: string;
  poNumber: string;

  constructor() {
    super();
  }

  serialize() {
    return {
      ordinal: this.ordinal,
      businessName: this.businessName,
      accountNumber: this.accountNumber,
      firstName: this.firstName,
      lastName: this.lastName,
      poNumber: this.poNumber,
      phone: Utils.serializePhone(this.phone),
    };
  }
}
