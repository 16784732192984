import { BaseResource } from '../core/base-resource';
import { TrackerOrderStatus } from './tracker-order-status.enum';
import { OrderTrackerButtonState } from './order-tracker-button-state.enum';

export class OrderTrackerStatus extends BaseResource {
  orderId: string;
  orderKey: string;
  orderSecondaryId: string;

  status: TrackerOrderStatus;
  hasBakingStep: boolean;
  eligibleForLateCoupon: boolean;
  hasRegisteredWebCustomer: boolean;

  orderTimeDeferPrep: string;

  storeLat: number;
  storeLng: number;

  deliveryAddressLat: number;
  deliveryAddressLng: number;

  driverName: string;
  veteranDriver: boolean;
  driverLat: number;
  driverLng: number;
  driverDestinationRadius: number;
  showActualDriverLocation: boolean;
  minutesToDelivery: number;
  driverGPSOff: boolean;
  lastGPSRefresh: number;

  printReceiptButtonState: OrderTrackerButtonState;
  reorderButtonState: OrderTrackerButtonState;

  get driverDestinationRadiusInKm() {
    return this.driverDestinationRadius * 1609.34;
  }
}
