import { Injectable } from '@angular/core';
import { ConfigService } from 'ngx-web-api';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GoogleMapsApiKeyService {
  private static scriptTagStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static scriptTagStatus$: Observable<boolean> = GoogleMapsApiKeyService.scriptTagStatus.asObservable();

  private shouldAdd: boolean;
  private key: string;

  constructor(private configService: ConfigService) {
    this.shouldAdd = false;
    this.key = '';
    this.configService.storeConfig$.subscribe(storeConfig => {
      if (!!storeConfig?.googleMapsAPIKey && document.getElementById('gm-api-key-header-script')) {
        this.shouldAdd = true;
        this.key = storeConfig?.googleMapsAPIKey;
      }
    });
  }

  public loadLibrary(): void {
    const isPresent = document.getElementById('gm-api-key-header-script').getElementsByTagName('script');
    if (this.shouldAdd && !!this.key && isPresent?.length === 0) {
      const libEl = document.createElement('script');
      libEl.src = `https://maps.googleapis.com/maps/api/js?key=${this.key}&callback=initMap&loading=async`;
      libEl.async = true;
      window['initMap'] = this.initMapScriptTag.bind(GoogleMapsApiKeyService);
      document.getElementById('gm-api-key-header-script').appendChild(libEl);
      return;
    }

    if (this.shouldAdd && !!this.key) {
      this.initMapScriptTag();
    }
  }

  public initMapScriptTag() {
    GoogleMapsApiKeyService.scriptTagStatus.next(true);
  }
}
