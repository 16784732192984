import { InitModalConfig } from './init-modal-config';

export class BasicModalContext {
  config: any = {};
  class?: string;
  ignoreBackdropClick = false;
  ignoreDismissOnNavigate = false;

  getConfig(): InitModalConfig {
    return {
      initialState: {
        context: this.config,
      },
      class: this.class,
      ignoreBackdropClick: this.ignoreBackdropClick,
      ignoreDismissOnNavigate: this.ignoreDismissOnNavigate,
    };
  }
}
