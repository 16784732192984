export enum OrderStatus {
  editing = 'editing',
  making = 'making',
  canceled = 'canceled',
  returned = 'returned',
  made = 'made',
  assigned = 'assigned',
  driving = 'driving',
  delivered = 'delivered',
  suspended = 'suspended',
  deferred = 'deferred',
  replaced = 'replaced',
  favorite = 'favorite',
  training = 'training',
  waste = 'waste',
  unwaste = 'unwaste',
  partialgrouporder = 'partialgrouporder',
}
