import { UpsellWrapper, OrderableItem, OrderedItem, MenuItemsLayout } from 'ngx-web-api';
import { BasicModalContext } from './basic-modal-context';
import { EditingItemContext } from './editing-item-context';

export class UpsellSpecialWizardModalContext extends BasicModalContext {
  constructor(
    public upsellWrapper: UpsellWrapper,
    public upsellTriggerOrderableItem: OrderableItem,
    public menuItemsLayout?: MenuItemsLayout,
    public upsellTriggerOrderedItem?: OrderedItem,
    public upsellTriggerItemId?: string,
    public skipNavigation?: boolean,
    public editingContext?: EditingItemContext
  ) {
    super();
    this.config = {
      upsellWrapper,
      upsellTriggerOrderableItem,
      menuItemsLayout,
      upsellTriggerOrderedItem,
      upsellTriggerItemId,
      skipNavigation,
      editingContext,
    };
    this.class = 'md';
  }
}
