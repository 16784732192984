import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map, tap, mergeMap } from 'rxjs/operators';
import { ConfigService } from '../core/config.service';
import { AuditService } from '../core/audit.service';
import { PaymentSetupType } from '../../models/ordering/payment-setup-type.enum';
import { PaymentSetupRequest } from '../../models/ordering/payment-setup-request.model';
import { PaymentSetup } from '../../models/ordering/payment-setup';
import { ApplePaySupportedNetworks } from '../../models/ordering/apple-pay-supported-networks.enum';
import { AcceptHeader } from '../../models/core/accept-header.enum';

@Injectable({
  providedIn: 'root',
})
export class ApplePayService {
  private storeName: string;
  private ccSupportedNetworks: string[];
  private applePaySession: ApplePaySession;
  private applePayPaymentRequest: ApplePayJS.ApplePayPaymentRequest;
  private readonly APPLE_PAY_JS_VERSION = 3;

  private readonly BASE_PATH = '/ws/integrated/v1/ordering/order/payments';
  private readonly HATEOAS_HEADERS = new HttpHeaders({ Accept: AcceptHeader.HATEOAS });

  private applePayToken: Subject<ApplePayJS.ApplePayPaymentToken | undefined> = new Subject();
  public applePayToken$: Observable<ApplePayJS.ApplePayPaymentToken | undefined> = this.applePayToken.asObservable();

  public isSupported$: Observable<boolean>;

  constructor(private http: HttpClient, private config: ConfigService, private auditService: AuditService) {
    this.isSupported$ = this.config.storeConfig$.pipe(
      tap(store => (this.storeName = store.name)),
      mergeMap(() => this.config.creditCardConfig$),
      tap(
        ccConfig =>
          (this.ccSupportedNetworks = ccConfig.creditCardTypes
            .map(network => network.lowerCaseFirstChar)
            .filter(network => ApplePaySupportedNetworks[network] >= 0))
      ),
      map(
        ccConfig =>
          this.ccSupportedNetworks &&
          this.ccSupportedNetworks.length > 0 &&
          ccConfig.supportsApplePay &&
          window['ApplePaySession'] &&
          window['ApplePaySession'].canMakePayments()
      )
    );
  }

  createPaymentRequest(dueAmount: number) {
    this.applePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: this.ccSupportedNetworks,
      total: {
        label: this.storeName,
        type: 'final',
        amount: dueAmount.toString(),
      },
    };

    if (this.applePayPaymentRequest) {
      this.createSession();
    }
  }

  createSession() {
    this.applePaySession = new ApplePaySession(this.APPLE_PAY_JS_VERSION, this.applePayPaymentRequest);
    this.applePaySession.begin();
    this.applePaySession.onvalidatemerchant = event => this.validateMerchant(event);
    this.applePaySession.onpaymentmethodselected = event => this.paymentMethodSelected(event);
    this.applePaySession.onpaymentauthorized = event => this.paymentAuthorized(event);
    this.applePaySession.oncancel = () => this.onCancelSession();
  }

  validateMerchant(merchantEvent: ApplePayJS.ApplePayValidateMerchantEvent) {
    const paymentSetupRequest: PaymentSetupRequest = {
      type: PaymentSetupType.APPLE_PAY,
      applePayValidationUrl: merchantEvent.validationURL,
    };

    this.http
      .post<PaymentSetup>(`${this.BASE_PATH}/setup`, paymentSetupRequest, { headers: this.HATEOAS_HEADERS })
      .subscribe(
        paymentSetup => {
          if (!paymentSetup || !paymentSetup.applePay) {
            this.handlePaymentError('Payment setup response is empty or invalid');
            return;
          }
          this.applePaySession.completeMerchantValidation(paymentSetup.applePay);
        },
        err => {
          this.handlePaymentError(`Merchant validation failed, ${JSON.stringify(err)}`);
        }
      );
  }

  paymentMethodSelected(methodSelectedEvent: ApplePayJS.ApplePayPaymentMethodSelectedEvent) {
    // TODO: Here we can handle changes in order, based on the payment method selected (CC type/issuer)
    this.auditService.createAudit(() => `Apple Pay payment method selected: ${methodSelectedEvent.paymentMethod.displayName}`);
    this.applePaySession.completePaymentMethodSelection({ newTotal: this.applePayPaymentRequest.total });
  }

  paymentAuthorized(authorizationEvent: ApplePayJS.ApplePayPaymentAuthorizedEvent) {
    this.auditService.createAudit(() => 'Apple Pay payment authorized');
    this.applePayToken.next(authorizationEvent.payment.token);
  }

  handlePaymentError(error: string | ApplePayJS.ApplePayErrorCode) {
    this.auditService.createAudit(() => ({
      message: 'Apple Pay payment failed',
      details: error,
    }));

    try {
      this.applePaySession.completePayment({
        status: ApplePaySession.STATUS_FAILURE,
        errors: [
          {
            code: 'unknown', // ApplePayErrorCode => We could specify error type if server responses provide ApplePayErrorCode(s)
            message: error,
          },
        ],
      });
    } catch {
      // Do not handle internal ApplePayJS API error
    }
  }

  completePayment() {
    this.applePaySession.completePayment({ status: ApplePaySession.STATUS_SUCCESS });
    this.auditService.createAudit(() => 'Apple Pay payment completed successfully');
  }

  onCancelSession() {
    this.auditService.createAudit(() => 'Apple Pay payment cancelled');
  }
}
