import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderType } from '../../models/core/order-type.enum';
import { StoreStatus } from '../../models/core/store-status.model';
import { StoreStatusService } from '../core/store-status.service';
import { CalendarEvent } from '../../models/core/calendar-event.model';
import { map, tap } from 'rxjs/operators';
import { DeferOptions } from 'ngx-web-api';

@Injectable({ providedIn: 'root' })
export class DeferService {
  public storeStatus: StoreStatus;

  private calendarEvents: BehaviorSubject<CalendarEvent[]> = new BehaviorSubject([]);
  public calendarEvents$: Observable<CalendarEvent[]> = this.calendarEvents.asObservable();

  constructor(private http: HttpClient, private storeStatusService: StoreStatusService) {
    this.storeStatusService.storeStatus$.subscribe(storeStatus => (this.storeStatus = storeStatus));
  }

  getTimeSlots(day: string, orderType: OrderType, deferOption?: DeferOptions): Observable<string[]> {
    let params: HttpParams = new HttpParams().set('date', day).set('orderType', orderType);

    if (deferOption) {
      params = params.set('deferOption', deferOption);
    }

    return this.http.get<string[]>('/ws/integrated/v1/ordering/deferOptions/timeSlots', { params });
  }

  getCalendarEvents(orderType: OrderType): Observable<CalendarEvent[]> {
    const params: HttpParams = new HttpParams().set('orderType', orderType);
    return this.http
      .get<CalendarEvent[]>('/ws/integrated/v1/ordering/deferOptions/calendarEvents', { params })
      .pipe(
        map((json: Object[]) => (json || []).map(ev => new CalendarEvent().deserialize(ev))),
        tap((ce: CalendarEvent[]) => this.calendarEvents.next(ce))
      );
  }
}
