<ng-container *ngIf="!fillParentHeight">
  <img [@slideVisible]="slideVisible? 1 : 0"
       ftsPicture
       [skipLazyLoading]="skipLazyLoading"
       #pictureRef1="pictureRef"
       [imgSrc]='featuredItemPicture?.src'
       [imgSrcset]='featuredItemPicture?.srcset'
       [imgName]="featuredItemPicture?.name"
       [isFeatureItem]="true"
       class='slide'
       [ngClass]="{'cursor-pointer': featuredItem.isClickable, 'first': first, 'cover': fillParentHeight}"
       [alt]="featuredItem?.title"
       aria-hidden="true"
       (load)="imgLoaded()"
       #imgEl>
  <fts-ghost-element 
    *ngIf='!skipLazyLoading && (slideVisible || slideVisibleFillParentHeight) && (featuredItemPicture?.src || featuredItemPicture?.srcset) && !pictureRef1?.isLoaded'
    [height]="itemImgHeight || 240"
    class="pos-absolute full-width"></fts-ghost-element>
</ng-container>

<ng-container *ngIf="fillParentHeight">
  <img [@slideVisible]="slideVisible? 1 : 0"
       [@slideVisibleFillParentHeight]="slideVisibleFillParentHeight? 1 : 0"
       ftsPicture
       [skipLazyLoading]="skipLazyLoading"
       #pictureRef2="pictureRef"
       [imgSrc]='featuredItemPicture?.src'
       [imgSrcset]='featuredItemPicture?.srcset'
       [imgName]="featuredItemPicture?.name"
       [isFeatureItem]="true"
       class='slide'
       [ngClass]="{
          'cursor-pointer': featuredItem.isClickable,
          'first': first,
          'cover': fillParentHeight && !(viewport$ | async)?.isMobile,
          'cover-mobile': fillParentHeight && (viewport$ | async)?.isMobile}"
      [alt]="featuredItem?.title"
       aria-hidden="true"
       (load)="imgLoaded()"
       #imgEl>
  <fts-ghost-element 
    *ngIf='!skipLazyLoading && (slideVisible || slideVisibleFillParentHeight) && (featuredItemPicture?.src || featuredItemPicture?.srcset) && !pictureRef2?.isLoaded'
    [height]="itemImgHeight || 240"
    class="pos-absolute full-width"></fts-ghost-element>
</ng-container>

<div *ngIf='!featuredItem?.imageOnly && (featuredItem?.title || featuredItem?.subtitle)'
     class='titles-wrapper text-center'
     aria-hidden="true"
     [ngClass]="{ 'first': first, 'cursor-pointer': featuredItem.isClickable }"
     [@slideVisible]="slideVisible? 1 : 0">
  <h3 aria-hidden="true" *ngIf="featuredItem?.title" class='fw-600'>{{featuredItem?.title}}</h3>
  <p aria-hidden="true" *ngIf="featuredItem?.subtitle">{{featuredItem?.subtitle}}</p>
</div>
