import { TranslateService } from '@ngx-translate/core';
import { mergeMap, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItemsLayout, OrderableItem, OrderedItem, OrderedItemService, UpsellType, UpsellWrapper } from 'ngx-web-api';
import { Subscription, of, noop } from 'rxjs';
import { ItemEditorService } from '../../core/services/item-editor.service';
import { ModalService } from '../../core/services/modal.service';
import { SpecialWizardService } from '../../core/services/special-wizard.service';
import { SpecialWizard } from '../../domain/special-wizard';
import { ViewportService } from 'app/core/services/viewport.service';
import { EditingItemContext } from 'app/domain/editing-item-context';
import { EcommerceTriggeringArea } from '../../domain/ecommerce-triggering-area.enum';
import { EcommerceTriggeringPoint } from '../../domain/ecommerce-triggering-point.enum';
import { GTMService } from '../../core/services/gtm.service';
import { ConfirmationModalContext } from 'app/domain/confirmation-modal-context';

@Component({
  selector: 'fts-upsell-special-modal-content',
  templateUrl: './upsell-special-modal-content.component.html',
  styleUrls: ['./upsell-special-modal-content.component.scss'],
})
export class UpsellSpecialModalContentComponent implements OnInit {
  @Input()
  upsellWrapper: UpsellWrapper;
  @Input()
  upsellTriggerOrderableItem: OrderableItem;
  @Input()
  upsellTriggerOrderedItem: OrderedItem;
  @Input()
  menuItemsLayout: MenuItemsLayout;
  @Input()
  upsellTriggerItemId: string; // This is needed for 'Recently Ordered' items
  @Input()
  editingItemContext: EditingItemContext;
  @Input()
  skipNavigation: boolean;
  @Output()
  hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  makeCombo: EventEmitter<SpecialWizard> = new EventEmitter<SpecialWizard>();

  makeComboLoadingSubscription: Subscription;
  addItemLoadingSubscription: Subscription;

  upsellTriggerOrderedItemOrFromOrderable: OrderedItem;

  constructor(
    private orderedItemService: OrderedItemService,
    private specialWizardService: SpecialWizardService,
    private itemEditorService: ItemEditorService,
    private modalService: ModalService,
    private router: Router,
    public viewportService: ViewportService,
    private gtmService: GTMService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.upsellTriggerOrderedItemOrFromOrderable =
      this.upsellTriggerOrderedItem ||
      this.orderedItemService.createOrderedItem(this.upsellTriggerOrderableItem, this.upsellWrapper.triggeringSize);
  }

  handleMakeCombo() {
    const orderedItem: OrderedItem = this.upsellTriggerOrderedItemOrFromOrderable.copy();
    orderedItem.isUpsell = true;
    orderedItem.upsellType = UpsellType.ITEM_BASED;

    this.makeComboLoadingSubscription = this.specialWizardService
      .createSpecialWizard(
        this.upsellWrapper.special.orderableSpecial.links,
        'special',
        this.upsellWrapper.special.stepOrdinal,
        this.upsellTriggerOrderableItem,
        orderedItem,
        !this.skipNavigation,
        this.editingItemContext
      )
      .subscribe(
        specialWizard => {
          specialWizard.isUpsell = true;
          specialWizard.upsellType = UpsellType.ITEM_BASED;
          this.makeCombo.emit(specialWizard);
          const special = this.upsellWrapper.special.orderableSpecial;
          this.gtmService.pushViewPromotionEvent(
            special.label || special.name,
            special.code,
            EcommerceTriggeringArea.UPSELL,
            EcommerceTriggeringPoint.COMBO,
            this.upsellTriggerOrderableItem.category
          );
        },
        error => this.modalService.openErrorNotificationModal(error)
      );
  }

  handleAddItem() {
    if (this.skipNavigation) {
      if (this.upsellTriggerItemId) {
        this.addItemLoadingSubscription = this.orderedItemService.reorderItem(this.upsellTriggerItemId).subscribe(
          orderedItem => {
            this.gtmService.pushAddToCartEvent({
              items: [orderedItem],
              triggeringArea: EcommerceTriggeringArea.RECENTLY_ORDERED,
              triggeringPoint: EcommerceTriggeringPoint.ORDER_BUTTON,
            });
            this.handleCloseModal();
          },
          errorResult => (!!errorResult.error && errorResult.status === 409 ? this.reorderItemWarningModal(errorResult) : noop)
        );
      } else {
        const orderedItem =
          this.upsellTriggerOrderedItem ||
          this.orderedItemService.createOrderedItem(this.upsellTriggerOrderableItem, this.upsellWrapper.triggeringSize);

        orderedItem.quantity =
          orderedItem.quantity < this.upsellTriggerOrderableItem?.minimumQuantity
            ? this.upsellTriggerOrderableItem?.minimumQuantity
            : orderedItem.quantity;
        this.addItemLoadingSubscription = this.orderedItemService.addItemToOrder(orderedItem).subscribe((item: OrderedItem) => {
          this.gtmService.pushAddToCartEvent({
            items: [item],
            triggeringArea: EcommerceTriggeringArea.MENU,
            triggeringPoint: EcommerceTriggeringPoint.ORDER_BUTTON,
          });
          this.handleCloseModal();
          if (this.menuItemsLayout !== MenuItemsLayout.CardMenu) {
            this.router.navigate(['/editor', 'item', item.itemId]);
          }
        });
      }
    } else {
      this.itemEditorService.editOrderableItem(
        this.upsellTriggerOrderableItem,
        this.upsellWrapper.triggeringSize,
        this.upsellTriggerOrderedItem,
        this.menuItemsLayout,
        1,
        '',
        '',
        this.editingItemContext.selectedUpfrontIngredient,
        this.editingItemContext.unsatisfiedIngredientChoiceName,
        this.editingItemContext.selectedUpfrontQualifier
      );
      const price = this.upsellTriggerOrderableItem.sizePrices.find(sp => sp.label === this.upsellWrapper.triggeringSize)?.price;
      this.gtmService.pushViewItemEvent(
        this.upsellTriggerOrderableItem,
        this.upsellWrapper.triggeringSize,
        price,
        this.upsellTriggerOrderableItem.minimumQuantity,
        null
      );
      this.handleCloseModal();
      this.router.navigate(['/editor', 'add-to-order']);
    }
  }

  handleCloseModal() {
    this.hideModal.emit(true);
  }

  private reorderItemWarningModal(error: any) {
    const context = new ConfirmationModalContext();
    context.mainContent = error.error.message;
    context.title = this.translateService.instant('component.modal_service.reorder_modal.title');
    context.submitBtnTitle = this.translateService.instant('component.modal_service.reorder_modal.submitBtnTitle');
    context.submitObservable = of(true);
    this.modalService
      .openConfirmationModal(context)
      .pipe(
        mergeMap((result: boolean) =>
          result
            ? this.orderedItemService.reorderItem(this.upsellTriggerItemId, true).pipe(tap(() => this.modalService.closeAllModals()))
            : of(this.modalService.closeAllModals())
        )
      )
      .subscribe();
  }
}
