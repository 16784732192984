"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsOrders";
var width = 464;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "M 226.7347,0 C 172.4,0 128.51429,43.889796 127.46939,98.22449 H 10.44898 C 4.1795918,98.22449 0,102.40408 0,108.67347 V 501.55102 C 0,507.82041 4.1795918,512 10.44898,512 h 442 c 6.26938,0 10.44898,-4.17959 10.44898,-10.44898 v -22.32653 c 0.65105,-1.4149 1.04082,-3.02004 1.04082,-4.83673 V 108.67347 c 0,-6.26939 -4.17959,-10.44898 -10.44898,-10.44898 H 326 C 326,43.889796 282.13061,0 227.79592,0 Z m -1.04083,20.897959 h 1.04083 c 42.84081,0 77.32245,34.485714 78.36734,77.326531 H 148.36735 c 0,-42.840817 34.48571,-77.326531 77.32652,-77.326531 z M 20.897959,119.12245 H 127.46939 v 45.97959 c 0,6.26939 4.17959,10.44898 10.44898,10.44898 6.26939,0 10.44898,-4.17959 10.44898,-10.44898 v -45.97959 h 157.79591 v 45.97959 c 0,6.26939 4.17959,10.44898 10.44898,10.44898 6.26939,0 10.44898,-4.17959 10.44898,-10.44898 v -45.97959 h 115.9796 V 460.10204 C 442.38155,461.52321 442,463.15102 442,464.97959 v 26.12245 H 20.897959 Z m 38.653062,254.95918 c -5.224491,0 -9.387756,4.16327 -9.387756,9.38776 10e-7,5.22449 4.163266,9.40816 9.387756,9.40816 H 396.02041 c 5.22449,0 9.40816,-4.18367 9.40816,-9.40816 0,-5.22449 -4.18367,-9.38776 -9.40816,-9.38776 z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.faFtsOrders = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
