<nav id="categoryLinksContainer">
  <fts-nav-link *ngIf="theme?.showSearchMenuLink"
                [index]='-1'
                [theme]="theme"
                [ngRouterLink]="'menu/search'"
                [tabName]="'component.vertical_categories_menu.search' | translate"
                [hasLeftMenuIcons]="hasLeftMenuIcons"
                [showTabIcons]="showTabIcons"
                [isSearchLink]="true"
                (onTabClick)="tabClick()"
                ></fts-nav-link>
  <fts-nav-link *ngIf="hasRecentlyOrderedItems"
                [index]='-1'
                [theme]="theme"
                [ngRouterLink]="tabLinksMap[recentlyOrderedLabel]"
                [tabName]="'component.vertical_categories_menu.recently_ordered' | translate"
                [tabIcon]="theme?.recentlyOrderedItemIcon"
                [hasLeftMenuIcons]="hasLeftMenuIcons"
                [showTabIcons]="showTabIcons"
                (onTabClick)="tabClick()"
                ></fts-nav-link>
  <fts-nav-link *ngFor="let tab of panelTabs; let i=index"
                [ngRouterLink]="tabLinksMap[tab.name]"
                [index]='i'
                [theme]="theme"
                [tabName]="tab.name"
                [tabIcon]="tab.image"
                [hasLeftMenuIcons]="hasLeftMenuIcons"
                [showTabIcons]="showTabIcons"
                (onTabClick)="tabClick()"></fts-nav-link>
</nav>
