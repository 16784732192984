import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GiftCardWrapper } from '../../models/core/gift-card-wrapper.model';
import { GiftCard } from '../../models/core/gift-card.model';

@Injectable({ providedIn: 'root' })
export class GiftCardsService {
  readonly BASE_PATH = '/ws/integrated/v1/ordering/giftcards';
  readonly BASE_PATH_WALLET = '/ws/integrated/v1/ordering/account/giftcards';

  private authorizedGiftCardsWrapper: BehaviorSubject<GiftCardWrapper> = new BehaviorSubject(new GiftCardWrapper());
  public authorizedGiftCardsWrapper$: Observable<GiftCardWrapper> = this.authorizedGiftCardsWrapper.asObservable();

  public tipPaidWithGc$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private http: HttpClient) {}

  public authorizeGiftCard(giftCard: GiftCard, inWalletPage: boolean = false): Observable<GiftCard> {
    return this.http
      .post(inWalletPage ? this.BASE_PATH_WALLET : this.BASE_PATH, giftCard.serialize())
      .pipe(map((json: Object) => new GiftCard().deserialize(json)));
  }

  public fetchAuthorizedGiftCardsWrapper(inWalletPage: boolean = false): Observable<GiftCardWrapper> {
    return this.http.get(inWalletPage ? this.BASE_PATH_WALLET : this.BASE_PATH).pipe(
      map((json: Object) => new GiftCardWrapper().deserialize(json)),
      catchError(() => of(new GiftCardWrapper())),
      tap((giftCardWrapper: GiftCardWrapper) => this.authorizedGiftCardsWrapper.next(giftCardWrapper))
    );
  }
}
