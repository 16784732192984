import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { IngredientChoice } from './ingredient-choice.model';
import { ItemType } from './item-type.enum';
import { Picture } from './picture.model';
import { SizePrice } from './size-price.model';
import { Tag } from './tag.model';

export class PanelItem extends BaseResource {
  keyName: string;
  name: string;
  label: string;
  labelSymbol: string;
  description: string;
  pictures: Picture[];
  type: ItemType;
  isHalfAndHalf: boolean;
  itemWithIngredientChoices: boolean;
  itemWithRequiredIngredientChoices: boolean;
  showFullSizeCalories: boolean;
  itemUsesBuilder: boolean;
  singleRequiredChoice?: IngredientChoice;
  sizePrices: SizePrice[];
  tags: Tag[];
  unsatisfiedIngredientChoice: IngredientChoice;
  hasMultipleChoices: boolean;
  hideUnlessFilter: boolean;
  minimumQuantity: number;

  get isItem() {
    return this.type === ItemType.ITEM;
  }

  get isSpecial() {
    return this.type === ItemType.SPECIAL;
  }

  get isFundraiser() {
    return this.type === ItemType.FUNDRAISER;
  }

  get hasImage(): boolean {
    return !!this.pictures && this.pictures.length > 0;
  }

  get hasSingleSize(): boolean {
    return this.sizePrices && this.sizePrices.length === 1;
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      singleRequiredChoice: DeserializationUtils.deserializeChild(input, 'singleRequiredChoice', IngredientChoice),
      sizePrices: DeserializationUtils.deserializeChildren(input, 'sizePrices', SizePrice),
      tags: DeserializationUtils.deserializeChildren(input, 'tags', Tag),
      unsatisfiedIngredientChoice: DeserializationUtils.deserializeChild(input, 'unsatisfiedIngredientChoice', IngredientChoice),
    });
  }
}
