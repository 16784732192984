<div
  class="indicator" 
  *ngIf='numberOfSteps > 1' 
  (focus)="stopStepIndicator.emit()" 
  (blur)="startStepIndicator.emit()"
>
  <div *ngFor='let step of steps'
       aria-hidden="true"
       class="step"
       [ngClass]="{'filled': step === currentStep}"
       (click)='selectStep(step)'
  ></div>
</div>