import { DeserializationUtils } from '../../utils/deserialization-utils';
import { Utils } from '../../utils/utils';
import { CreditCard } from '../core/credit-card.model';
import { GiftCardWrapper } from '../core/gift-card-wrapper.model';
import { ValidatedResource } from '../core/validated-resource';
import { QuestionnaireAnswer } from '../questionnaires/questionnaire-answer.model';
import { CustomerSubscription } from './customer-subscription.model';

export class Account extends ValidatedResource {
  webCustomerId: string;
  firstName: string;
  lastName: string;

  phone: string;
  phoneConfirmation?: string;

  email: string;
  emailConfirmation?: string;

  password: string;
  passwordConfirmation?: string;

  signUpForLoyalty: boolean;
  subscribed: boolean;
  smsMarketing: boolean;
  subscribedOrderSurveys: boolean;
  dummy: boolean; // we will use this to identify when the order service observable has properly initialized/fetched
  recaptcha: string;
  acceptTerms?: boolean;
  favoriteStore: string;
  questionnaireAnswer?: QuestionnaireAnswer;
  verificationCode?: string;
  isCellPhoneVerified: boolean;

  readonly registerQuestionnaireMessage?: string;
  readonly verifiedEmail: boolean;
  readonly flagBouncedEmail: boolean;
  readonly hasPassword: boolean;
  readonly fullName: string;
  readonly hasDonations: boolean;
  readonly hasPushNotifications: boolean;

  giftCards: GiftCardWrapper;
  creditCards: CreditCard[];
  subscriptions: CustomerSubscription[];
  smsDisclaimer: string;

  static createDummyAccount() {
    return new Account().deserialize({ dummy: true });
  }

  get isInstantiated(): boolean {
    return !!this.email;
  }

  get isLoyalty(): boolean {
    return !this.dummy && this.isInstantiated && this.signUpForLoyalty;
  }

  serialize(): Object {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      phone: Utils.serializePhone(this.phone),
      email: this.email,
      password: this.password,
      signUpForLoyalty: this.signUpForLoyalty,
      subscribed: this.subscribed,
      smsMarketing: this.smsMarketing,
      subscribedOrderSurveys: this.subscribedOrderSurveys,
      recaptcha: this.recaptcha,
      acceptTerms: this.acceptTerms,
      favoriteStore: this.favoriteStore,
      questionnaireAnswer: this.questionnaireAnswer ? this.questionnaireAnswer.serialize() : undefined,
      verificationCode: this.verificationCode ? this.verificationCode : undefined,
      subscriptions: (this.subscriptions || []).map(s => s.serialize()),
      smsDisclaimer: this.smsDisclaimer,
    };
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      giftCards: DeserializationUtils.deserializeChild(input, 'giftCards', GiftCardWrapper),
      creditCards: DeserializationUtils.deserializeChildren(input, 'creditCards', CreditCard),
      subscriptions: DeserializationUtils.deserializeChildren(input, 'subscriptions', CustomerSubscription),
    });
  }
}
