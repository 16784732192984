import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PathService {
  private inHome: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public inHome$: Observable<boolean> = this.inHome.asObservable();

  private inIntro: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public inIntro$: Observable<boolean> = this.inIntro.asObservable();

  private inMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public inMenu$: Observable<boolean> = this.inMenu.asObservable();

  private inPay: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public inPay$: Observable<boolean> = this.inPay.asObservable();

  private inThanks: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public inThanks$: Observable<boolean> = this.inThanks.asObservable();

  private inOrderDetails: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public inOrderDetails$: Observable<boolean> = this.inOrderDetails.asObservable();

  private inTracker: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public inTracker$: Observable<boolean> = this.inTracker.asObservable();

  private inSearch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public inSearch$: Observable<boolean> = this.inSearch.asObservable();

  private isInCheckout: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isInCheckout$: Observable<boolean> = this.isInCheckout.asObservable();

  private isInExpiration: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isInExpiration$: Observable<boolean> = this.isInExpiration.asObservable();

  private isInPrintReceipt: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isInPrintReceipt$: Observable<boolean> = this.isInPrintReceipt.asObservable();

  public availablePagesToShowGroupOrderButton$: Observable<boolean>;

  constructor(private router: Router) {
    const browserUrl = window.location.href;
    if (browserUrl.indexOf('/pay?orderId') !== -1 || (browserUrl.indexOf('/login') !== -1 && browserUrl.indexOf('target=/pay') !== -1)) {
      this.inPay.next(true);
    }

    if (browserUrl.indexOf('/printReceipt?orderId') !== -1) {
      this.isInPrintReceipt.next(true);
    }

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => decodeURIComponent(event.urlAfterRedirects))
      )
      .subscribe(url => {
        this.inHome.next(url.indexOf('/home') === 0);
        this.inIntro.next(url.indexOf('/intro') === 0);
        this.inMenu.next(url.indexOf('/menu') === 0);
        this.inThanks.next(url.indexOf('/thanks') === 0);
        this.inOrderDetails.next(url.indexOf('/checkout/order-details') === 0);
        this.inPay.next(url.indexOf('/pay') === 0 || (url.indexOf('/login') === 0 && url.indexOf('target=/pay') !== -1));
        this.inTracker.next(url.indexOf('/tracker') === 0);
        this.inSearch.next(url.indexOf('/menu/search') === 0);
        this.isInCheckout.next(url.indexOf('/checkout') === 0);
        this.isInExpiration.next(url.indexOf('/expiration') === 0);
        this.isInPrintReceipt.next(url.indexOf('/printReceipt') === 0);
      });

    this.availablePagesToShowGroupOrderButton$ = combineLatest([
      this.inTracker$,
      this.isInCheckout$,
      this.isInExpiration$,
      this.inIntro$,
      this.inHome$,
    ]).pipe(
      map(
        ([isTracker, isInCheckout, isInExpiration, inIntro, inHome]) => !(isTracker || isInCheckout || isInExpiration || inIntro || inHome)
      )
    );
  }

  public inOrderRelatedRoutes(urlAfterNav?: string): boolean {
    return (
      this.router.url.indexOf('/intro') !== 0 &&
      this.router.url.indexOf('/home') !== 0 &&
      (urlAfterNav ?? this.router.url) !== '/' &&
      this.router.url.indexOf('/expiration') !== 0 &&
      this.router.url.indexOf('/404') !== 0
    );
  }
}
