import { BrowserStorageHandlerService } from './services/browser-storage-handler.service';
import { Params } from '@angular/router';
import {
  AccountService,
  Address,
  AuditService,
  ConfigService,
  FeaturedContentService,
  GroupOrderService,
  Order,
  OrderPaymentTypesService,
  OrderService,
  OrderTypeConfig,
  StoreConfig,
  StoreStatusService,
  TimezoneService,
  TranslationResource,
  TranslationService,
  Utils,
} from 'ngx-web-api';

import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { InitParams } from '../domain/init-params.model';
import { AddressWrapperService } from './services/address-wrapper.service';

import { InitParamsStorageService } from './services/init-params-storage.service';
import { MenuWrapperService } from './services/menu-wrapper.service';
import { ThemeService } from './services/theme.service';
import { AddressSessionStorageService } from './services/address-session-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { InitializationErrorsHandlerService } from 'app/services/initialization-errors-handler.service';
import { GTMService } from './services/gtm.service';

export function initApplicationData(
  configService: ConfigService,
  groupOrderService: GroupOrderService,
  paramsStorageService: InitParamsStorageService,
  accountService: AccountService,
  orderService: OrderService,
  menuService: MenuWrapperService,
  orderPaymentTypesService: OrderPaymentTypesService,
  featuredContentService: FeaturedContentService,
  addressService: AddressWrapperService,
  storeStatusService: StoreStatusService,
  addressSessionStorageService: AddressSessionStorageService,
  auditService: AuditService,
  translationService: TranslationService,
  translateService: TranslateService,
  initializationErrorsHandlerService: InitializationErrorsHandlerService,
  gtmService: GTMService,
  timezoneService: TimezoneService,
  browserStorageHandlerService: BrowserStorageHandlerService
) {
  browserStorageHandlerService.initStoreIdStorageKey();

  gtmService.setupGTM();

  function parseInitParams(): InitParams {
    const params: Params = {};
    const qParams = window.location.search.split('?')[1] || '';

    qParams.split('&').forEach(param => {
      const [key, value] = param.split('=');
      if (key) {
        const parsedValue = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        if (!params[key]) {
          params[key] = [];
        }
        params[key].push(parsedValue);
      }
    });

    return paramsStorageService.parseParams(params);
  }

  function getStoreConfigDerivedInitializers(storeConfig: StoreConfig): Observable<any> | Array<Observable<any>> {
    const obs = [];
    if (!storeConfig) {
      return obs;
    }
    if (storeConfig.hasCreditCard) {
      obs.push(configService.fetchCreditCardConfig().pipe(take(1)));
    }
    if (storeConfig.hasLoyalty) {
      obs.push(configService.fetchLoyaltyPlanConfig().pipe(take(1)));
      if (storeConfig.hasLoyaltyBadges) {
        obs.push(configService.fetchLoyaltyBadgesConfig().pipe(take(1)));
      }
    }

    return obs?.length ? obs : of(null).pipe(take(1));
  }

  function initializeGroupOrdering(storeConfig: StoreConfig): Observable<any> {
    if (storeConfig.allowGroupWebOrdering) {
      return groupOrderService.checkToInitGroupOrdering().pipe(
        take(1),
        catchError(() => {
          Object.assign(storeConfig, { allowGroupWebOrdering: false });
          return of(null);
        })
      );
    }
    return of(null).pipe(take(1));
  }

  function getOrderDerivedInitializers(order: Order, storeConfig: StoreConfig, orderTypes: OrderTypeConfig[]): Array<Observable<any>> {
    const params = paramsStorageService.initParams;
    let skipIntro: boolean = params.skipIntro;
    const obs: Array<Observable<any>> = [];
    if (!storeConfig) {
      return obs;
    }
    const paramsOrderType = orderTypes.find(t => t.type === params.orderType);
    if (!!paramsOrderType && params.sourceIsSelfOrder) {
      order.isSelfOrderSource = true;
      order.orderTable = params.tableNum;
      skipIntro = true;
    }
    const orderType = (!!paramsOrderType && paramsOrderType.type) || undefined;

    if (skipIntro && !Utils.isNullOrUndefined(orderType)) {
      if (order.isInitialized) {
        if (order.orderType !== orderType) {
          order.orderType = orderType;
          obs.push(orderService.updateOrder(order));
        }
      } else {
        order.orderType = orderType;
        order.isMobileSource = params.sourceIsMobile;
        obs.push(orderService.createOrder(order));
      }
    }

    paramsStorageService.initParams.params.set('orderType', orderType);

    obs.push(featuredContentService.fetchFeaturedContents(order.orderType, order.deferTime).pipe(take(1)));

    if (order.hasOrderType) {
      obs.push(
        menuService.getLatestMenu(order.orderType, order.deferTime).pipe(take(1)),
        orderPaymentTypesService.fetchPaymentTypes().pipe(take(1))
      );
    }
    configService.findOrderTypeConfig(orderType).subscribe(config => {
      if ((skipIntro && !!config && config.needsAddress && params.address) || Utils.isNullOrUndefined(orderType)) {
        obs.push(
          findAddress(params).pipe(
            mergeMap((a: Address) => of(addressSessionStorageService.setAddress(a)).pipe(map(_unusedParameter => a))),
            mergeMap((a: Address) => orderService.updateOrderCustomerAddress(a)),
            map(res => !!res),
            catchError(() => of(false)),
            mergeMap((successCall: boolean) => (successCall ? of(addressSessionStorageService.deleteAddress()) : of(null))),
            take(1)
          )
        );
      }
    });

    return obs;
  }

  function findAddress(params: InitParams): Observable<Address> {
    return addressService.extractAddressAttributesFromParams(params).pipe(map(r => Object.assign(new Address(), r)));
  }

  function getAccountFetchingChain(): Observable<any> {
    return accountService.fetchAccount().pipe(take(1));
  }

  function getReferrerAuditInitializer(): Observable<any> {
    const referrer = document.referrer;
    if (!!referrer && referrer !== document.location.href) {
      const queryString =
        document.location.href.indexOf('?') !== -1 ? document.location.href.slice(document.location.href.indexOf('?')) : '';
      const referrerWithQueryParams = referrer + queryString;
      return of(
        auditService.createAudit(
          () => `Navigated to client from referrer ${referrer} ${queryString.length ? `and query string ${queryString}` : ``}`,
          referrerWithQueryParams
        )
      );
    }
    return of({});
  }

  function initDefaultTimezone(timeZone: string) {
    timezoneService.setTimeZone(timeZone);
    return of(null);
  }

  function initTranslations() {
    translateService.onLangChange.subscribe(languageObj => translationService.setCurrentLanguage(languageObj.lang));
    translateService.setDefaultLang('en');
    return translationService.getAvailableTranslations().pipe(
      mergeMap((translations: TranslationResource[]) => {
        const defaultTranslation = translations.find(tr => tr.defaultLanguage);
        return translationService.getTranslation(defaultTranslation.language).pipe(map(jsonObj => [jsonObj, defaultTranslation.language]));
      }),
      tap(([jsonObject, name]) => {
        if (!!jsonObject && !!Object.keys(jsonObject).length) {
          translateService.setTranslation(name, jsonObject);
          translateService.use(name);
        }
      }),
      catchError(() => of(null))
    );
  }

  function majorFetchFunctionWrapper<R>(observable: Observable<R> | Observable<R>[]): Observable<R> {
    if (Array.isArray(observable)) {
      return combineLatest(observable).pipe(
        catchError(err => {
          initializationErrorsHandlerService.initializationFailureOccured(err);
          return of(null);
        }),
        take(1)
      );
    }
    return observable.pipe(
      catchError(err => {
        initializationErrorsHandlerService.initializationFailureOccured(err);
        return of(null);
      }),
      take(1)
    );
  }

  function resetForSelfSourceOrder(order: Order, orderTypes: OrderTypeConfig[]): Observable<Order> {
    const params = paramsStorageService.initParams;
    const paramsOrderType = orderTypes.find(t => t.type === params.orderType);
    let resetOrder$ = of(order);
    if (order.isInitialized && !order.isSelfOrderSource && !!paramsOrderType && params.sourceIsSelfOrder) {
      resetOrder$ = orderService.deleteOrder();
    }
    return resetOrder$;
  }

  return function () {
    if (window['___ftsCwoInitFailedNoValidStoreId___']) {
      return of(null).toPromise();
    }
    parseInitParams();
    const params = paramsStorageService.initParams;

    return orderService
      .fetchOrder()
      .pipe(
        take(1),
        mergeMap(_order =>
          forkJoin([
            majorFetchFunctionWrapper(configService.fetchStoreConfig()),
            of(_order),
            majorFetchFunctionWrapper(
              configService.fetchOrderTypesConfig(
                (_order && _order.isSelfOrderSource) || params.sourceIsSelfOrder ? 'Self Order' : undefined,
                true
              )
            ),
            majorFetchFunctionWrapper(configService.fetchAddressTypesConfig()),
            majorFetchFunctionWrapper(configService.fetchDeliveryCompanies()),
            majorFetchFunctionWrapper(storeStatusService.storeStatus$.pipe(take(1))),
          ]).pipe(
            mergeMap(([storeConfig, order, orderTypes, _a, _d, _s]: [StoreConfig, Order, OrderTypeConfig[], any, any, any]) =>
              resetForSelfSourceOrder(order, orderTypes).pipe(map(resetOrder => [storeConfig, resetOrder, orderTypes]))
            ),
            mergeMap(([storeConfig, order, orderTypes, _]: [StoreConfig, Order, OrderTypeConfig[], any]) =>
              forkJoin([
                majorFetchFunctionWrapper(getStoreConfigDerivedInitializers(storeConfig)),
                majorFetchFunctionWrapper(getOrderDerivedInitializers(order, storeConfig, orderTypes)),
                majorFetchFunctionWrapper(getAccountFetchingChain()),
                majorFetchFunctionWrapper(initializeGroupOrdering(storeConfig)),
                initDefaultTimezone(storeConfig && storeConfig.timeZone),
                initTranslations(),
                majorFetchFunctionWrapper(getReferrerAuditInitializer().pipe(take(1))),
              ])
            )
          )
        )
      )
      .toPromise();
  };
}

export function initThemeFactory(themeService: ThemeService) {
  return function () {
    if (window['___ftsCwoInitFailedNoValidStoreId___']) {
      return of(null).toPromise();
    }
    return themeService.loadTheme();
  };
}
