import { Pipe, PipeTransform } from '@angular/core';
import { parse, parseISO, isValid } from 'date-fns';
import { Utils } from '../utils/utils';
import { timeZone } from '../models/globals';
import { format } from 'date-fns-tz';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string | Date, dateFormat: string, parsingFormat?: string): string {
    if (!value || !dateFormat) {
      return null;
    }
    if (typeof value === 'string') {
      value = Utils.removeTzFromDate(value);
      if (!!parsingFormat) {
        return format(parse(value, parsingFormat, parseISO(new Date().toISOString())), dateFormat, { timeZone: timeZone });
      }
      const parsedISODate = parseISO(value);
      if (!isValid(parsedISODate)) {
        return null;
      }
      return format(parsedISODate, dateFormat, { timeZone: timeZone });
    }
    if (value instanceof Date) {
      return format(value, dateFormat, { timeZone: timeZone });
    }
    return null;
  }
}
