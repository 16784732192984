import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { StoreFinderMethod, StoreConfig, Address, GenericObject, FormValidationService, AuditService } from 'ngx-web-api';
import { Subscription, noop } from 'rxjs';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { faFtsArrowRight } from '../../../../../shared/custom-fa-icons/faFtsArrowRight';

@Component({
  selector: 'fts-store-finder-form',
  templateUrl: './store-finder-form.component.html',
  styleUrls: ['./store-finder-form.component.scss'],
})
export class StoreFinderFormComponent implements OnInit, OnChanges {
  @Input()
  storeConfig: StoreConfig;
  @Input()
  loadingStores: Subscription;
  @Input()
  needsAddress = true;
  @Input()
  storeStates: string[];
  @Input()
  serverErrors: GenericObject<string[]>;

  @Output()
  addressInputChange: EventEmitter<string> = new EventEmitter();
  @Output()
  findStore: EventEmitter<string | Partial<Address>> = new EventEmitter();

  private validationMessages: GenericObject<GenericObject<string>> = {
    street: {
      required: this.translateService.instant('component.header.store_info.store_selection.store_finder_form.validations.street'),
    },
    address: {
      required: this.translateService.instant('component.header.store_info.store_selection.store_finder_form.validations.address'),
    },
    city: {
      required: this.translateService.instant('component.header.store_info.store_selection.store_finder_form.validations.city'),
    },
    zip: {
      required: this.translateService.instant('component.header.store_info.store_selection.store_finder_form.validations.zip'),
    },
    state: {
      required: this.translateService.instant('component.header.store_info.store_selection.store_finder_form.validations.state'),
    },
  };

  storeFinderForm: FormGroup;
  storeFinderMethod = StoreFinderMethod;
  showClearBtn = false;
  faFtsArrowRight = faFtsArrowRight;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private formValidationService: FormValidationService,
    private auditService: AuditService
  ) {}

  ngOnInit() {
    this.storeFinderForm = this.formBuilder.group({});
    if (this.storeConfig.storeFinderMethod === StoreFinderMethod.SINGLE) {
      this.storeFinderForm.addControl('address', new FormControl('', [Validators.required]));
    } else {
      if (this.needsAddress) {
        this.storeFinderForm.addControl('street', new FormControl('', [Validators.required]));
      }
      this.storeFinderForm.addControl('city', new FormControl('', [Validators.required]));
      this.storeFinderForm.addControl('zip', new FormControl('', this.storeConfig.isZipRequired ? [Validators.required] : []));
      this.storeFinderForm.addControl('state', new FormControl('', [Validators.required]));
    }

    this.storeFinderForm.valueChanges.subscribe(() => this.validateForm(false), noop);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['needsAddress'] && !!this.storeFinderForm) {
      if (!this.needsAddress && !!this.storeFinderForm.get('street')) {
        this.storeFinderForm.removeControl('street');
      } else if (
        this.needsAddress &&
        !this.storeFinderForm.get('street') &&
        this.storeConfig.storeFinderMethod !== StoreFinderMethod.SINGLE
      ) {
        this.storeFinderForm.addControl('street', new FormControl('', [Validators.required]));
      }
    }
  }

  onAddressInput(input?: string) {
    this.showClearBtn = !!input;
    if (!input) {
      this.addressInputChange.emit(input);
    }
  }

  onFindStore() {
    this.serverErrors = undefined;
    const addressFormData: Partial<Address> = this.storeFinderForm.getRawValue();
    if (this.storeFinderForm.invalid) {
      this.validateForm(true);
      const errors = Object.values(this.serverErrors).reduce((a, b) => [...a, ...b], []);
      this.auditService.createAudit(() => ({
        message: 'Find Store Address Form Errors',
        details: errors,
      }));
      return;
    }

    this.findStore.emit(addressFormData.address || addressFormData);
  }

  validateForm(submitted = false) {
    this.serverErrors = this.formValidationService.validateForm(this.storeFinderForm, this.validationMessages);
    if (!submitted) {
      Object.keys(this.serverErrors).forEach(key => {
        if (this.storeFinderForm.get(key).errors && this.storeFinderForm.get(key).pristine) {
          delete this.serverErrors[key];
        }
      });
    }
  }
}
