import { BaseResource } from './base-resource';

export class SocialLinks extends BaseResource {
  readonly facebookPageId?: string;
  readonly twitterPageId?: string;
  readonly youtubePageId?: string;
  readonly instagramPageId?: string;
  readonly iOSAppId?: string;
  readonly androidAppId?: string;
  readonly mobileAppTitle?: string;
  readonly mobileAppDescription?: string;
  readonly mobileAppLogo?: string;
}
