import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Account, LoyaltyPlan, StoreConfig, FeaturedContent, Order } from 'ngx-web-api';
import { MenuWrapperService } from '../../services/menu-wrapper.service';
import { BreakpointService } from '../../services/breakpoint.service';
import { faFtsProfile } from '../../../shared/custom-fa-icons/faFtsProfile';
import { faFtsRewards } from '../../../shared/custom-fa-icons/faFtsRewards';
import { faFtsOrders } from '../../../shared/custom-fa-icons/faFtsOrders';
import { faFtsAddressBook } from '../../../shared/custom-fa-icons/faFtsAddressBook';
import { faFtsWallet } from '../../../shared/custom-fa-icons/faFtsWallet';
import { faFtsCoupons } from '../../../shared/custom-fa-icons/faFtsCoupons';
import { faFtsDonations } from '../../../shared/custom-fa-icons/faFtsDonations';
import { faUsers } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'fts-mobile-account-menu',
  templateUrl: './mobile-account-menu.component.html',
  styleUrls: ['./mobile-account-menu.component.scss'],
})
export class MobileAccountMenuComponent {
  @Input()
  account: Account;
  @Input()
  loyaltyPlan: LoyaltyPlan;
  @Input()
  store: StoreConfig;
  @Input()
  isInIntro: boolean;
  @Input()
  featuredContents: FeaturedContent[];
  @Input()
  minifiedHeader = false;
  @Input()
  order: Order;

  @Output()
  login: EventEmitter<any> = new EventEmitter();
  @Output()
  logout: EventEmitter<any> = new EventEmitter();

  faFtsProfile = faFtsProfile;
  faFtsRewards = faFtsRewards;
  faFtsOrders = faFtsOrders;
  faFtsAddressBook = faFtsAddressBook;
  faFtsWallet = faFtsWallet;
  faFtsCoupons = faFtsCoupons;
  faFtsDonations = faFtsDonations;
  faUsers = faUsers;

  constructor(public menuWrapperService: MenuWrapperService, public breakpointService: BreakpointService) {}

  handleLogout(): void {
    this.logout.emit();
  }

  handleLogin() {
    this.login.emit();
  }
}
