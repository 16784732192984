import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { FeaturedContentName } from './featured-content-name.enum';
import { FeaturedContentStyle } from './featured-content-style.enum';
import { FeaturedItem } from './featured-item.model';

export class FeaturedContent extends BaseResource {
  name?: string;
  areaName: FeaturedContentName;
  title: string;
  style: FeaturedContentStyle;
  numberOfItems: number;
  speed: number;
  featuredItems: FeaturedItem[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      featuredItems: DeserializationUtils.deserializeChildren(input, 'featuredItems', FeaturedItem),
    });
  }
}
