import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionType } from '../../models/questionnaires/question-type.enum';
import { Question } from '../../models/questionnaires/question.model';
import { QuestionnaireAnswer } from '../../models/questionnaires/questionnaire-answer.model';
import { Questionnaire } from '../../models/questionnaires/questionnaire.model';
import { RateableItem } from '../../models/questionnaires/rateable-item.model';
import { SurveyAnswer } from '../../models/questionnaires/survey-answer.model';
import { SurveyResponse } from '../../models/questionnaires/survey-response.model';
import { Survey } from '../../models/questionnaires/survey.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';

@Injectable({ providedIn: 'root' })
export class SurveyService {
  private readonly SURVEY_PATH = '/ws/survey';
  private readonly SURVEY_POST_PATH = ' /ws/integrated/v1/ordering/survey/answer';

  constructor(private http: HttpClient) {}

  public getSurvey(code: string, isExternalRef = false): Observable<Survey> {
    let params: HttpParams = new HttpParams();
    if (isExternalRef) {
      params = params.set('isExternalRef', isExternalRef.toString());
    }

    return this.http.get(`${this.SURVEY_PATH}/${code}`, { params }).pipe(map(json => DeserializationUtils.deserializeObj(json, Survey)));
  }

  public answerSurvey(surveyAnswer: SurveyAnswer, isExternalRef = false): Observable<SurveyResponse> {
    let params: HttpParams = new HttpParams();
    if (isExternalRef) {
      params = params.set('isExternalRef', isExternalRef.toString());
    }

    return this.http.post<SurveyResponse>(this.SURVEY_POST_PATH, surveyAnswer.serialize(), { params });
  }

  public getQuestionFromRateableItem(item: RateableItem): Question {
    const options = [
      { option: 'Terrible' },
      { option: 'Disappointing' },
      { option: 'Average' },
      { option: 'Good' },
      { option: 'Excellent!' },
    ];

    return {
      id: item.key,
      question: item.title,
      questionType: QuestionType.RATING,
      options: options,
      help: '',
      required: false,
    };
  }

  public initSurveyAnswerFromQuestionnaire(refCode: string, questionnaire: Questionnaire, orderId?: string): SurveyAnswer {
    const json = {
      refCode: refCode,
      questionnaire: QuestionnaireAnswer.fromQuestionnaire(questionnaire, orderId),
    };
    return DeserializationUtils.deserializeObj(json, SurveyAnswer);
  }
}
