import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateIds',
})
export class GenerateIdsPipe implements PipeTransform {
  transform(elementList: any[], idPrefix: string, defaults: string = ''): any {
    let ids = '';
    if (elementList && elementList.length) {
      elementList.forEach((el, i) => (ids = ids.concat(` ${idPrefix}-${i}`)));
    }

    // Wave is really sensitive regarding spaces.
    ids = [defaults ? defaults.trim() : null, ids.trim()].join(' ').trim();
    return ids || null;
  }
}
