<ng-container *ngIf="!!orderTypeConfigs">
  <select *ngIf="!radioStyle"
          [ngModel]="selectedOrderTypeConfig" (ngModelChange)="onOrderTypeConfigChange($event)"
          class="form-control primary-bg-color primary-text-color"
          [attr.aria-label]="'component.header.store_info.store_selection.order_type_selector.order_type' | translate">
    <option *ngFor="let orderTypeConfig of orderTypeConfigs" [ngValue]="orderTypeConfig">
      {{orderTypeConfig.displayName || orderTypeConfig.type}}
    </option>
  </select>

  <div *ngIf="radioStyle" class="radio-selector">
    <fts-themeable-radio *ngFor="let orderTypeConfig of orderTypeConfigs"
      class="primary-text-color margin-5"
      [name]="orderTypeConfig.displayNameOrName"
      [label]='orderTypeConfig.displayNameOrName'
      [value]="orderTypeConfig.type"
      [ngModel]="selectedOrderTypeConfig?.type"
      [noPadding]="true"
      [disabled]="disabled"
      [ariaLabel]="orderTypeConfig.displayNameOrName"
      (ngModelChange)="onOrderTypeConfigChange(orderTypeConfig)">
    </fts-themeable-radio>
  </div>
</ng-container>
