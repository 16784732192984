import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Charity } from '../../models/core/charity.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';

@Injectable({ providedIn: 'root' })
export class CharityService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/charities';

  constructor(private http: HttpClient) {}

  getCharities(): Observable<Charity[]> {
    return this.http.get(this.BASE_PATH).pipe(map((json: Object[]) => DeserializationUtils.deserializeArray(json, Charity)));
  }
}
