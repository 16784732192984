import { BaseResource } from './base-resource';
import { Utils } from '../../utils/utils';
import { eachDayOfInterval } from 'date-fns';

export class CalendarEvent extends BaseResource {
  public id: string;
  public calendarType: string | 'StoreClose';
  public event: string;
  public startDate: string;
  public startTime: string;
  public endDate?: string;
  public endTime?: string;
  public description: string;

  constructor() {
    super();
  }

  get startDateISO(): Date {
    return Utils.parseIsoDate(this.startDate);
  }

  get endDateISO(): Date {
    return !this.endDate ? null : Utils.parseIsoDate(this.endDate);
  }

  get allDaysISO(): Date[] {
    return !this.endDate
      ? [this.startDateISO]
      : eachDayOfInterval({
          start: Utils.parseIsoDate(this.startDate),
          end: Utils.parseIsoDate(this.endDate),
        });
  }
}
