import { CouponErrorType } from '../ordering/coupon-error-type.enum';
import { OrderableCoupon } from '../ordering/orderable-coupon.model';
import { CouponStatus } from './coupon-status.enum';
import { CouponType } from './coupon-type.enum';
import { ValidatedResource } from './validated-resource';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { isToday } from 'date-fns';
import { Utils } from '../../utils/utils';
import { CouponOfferType } from './coupon-offer-type.enum';

export class Coupon extends ValidatedResource {
  name: string;
  code: string;
  readonly amount: number;
  readonly expirationDate: string;
  readonly type: CouponType;
  readonly errorType: CouponErrorType;
  readonly error: string;
  readonly status: CouponStatus;
  readonly message: string;
  readonly instructions: string;
  readonly disclaimer: string;
  readonly offerType: CouponOfferType;
  readonly isAdjustment: boolean;
  private dateFormat: DateFormatPipe = new DateFormatPipe();

  static createCouponByCode(code: string, type?: string, offerType?: CouponOfferType): Coupon {
    return new Coupon().deserialize({ code, type, offerType });
  }

  static fromOrderableCoupon(coupon: OrderableCoupon): Coupon {
    return new Coupon().deserialize({
      name: coupon.name,
      instructions: coupon.instructions,
      disclaimer: coupon.disclaimer,
      amount: coupon.price,
      type: coupon.isCoupon ? CouponType.COUPON : CouponType.SPECIAL,
      errorType: coupon.errorType,
      error: coupon.error,
    });
  }

  constructor() {
    super();
  }

  /** @deprecated Please use {@link CouponService.isLoyalty} */
  get isLoyalty() {
    return this.type === CouponType.LOYALTY || this.type === CouponType.OPTIONAL;
  }

  /** @deprecated Please use {@link CouponService.isOptional} */
  get isOptional() {
    return this.type === CouponType.OPTIONAL;
  }

  /** @deprecated Please use {@link CouponService.isApplicable} */
  get isApplicable() {
    return this.status === CouponStatus.APPLICABLE || this.isOptional;
  }

  /** @deprecated Please use {@link CouponService.isApplied} */
  get isApplied() {
    return this.status === CouponStatus.APPLIED;
  }

  /** @deprecated Please use {@link CouponService.isNonApplicable} */
  get nonApplicable() {
    return this.status === CouponStatus.NON_APPLICABLE;
  }

  /** @deprecated Please use {@link CouponService.getTypeLabel} */
  get typeLabel(): string {
    return this.isLoyalty ? 'Loyalty Coupon' : 'One time Coupon';
  }

  /** @deprecated Please use {@link CouponService.expiresInApproximatelyOneDay} */
  get expiresInApproximatelyOneDay(): boolean {
    return isToday(Utils.parseIsoDate(this.expirationDate));
  }

  /** @deprecated Please use {@link CouponService.formattedExpirationDate} */
  get formattedExpirationDate(): string {
    return this.dateFormat.transform(this.expirationDate, 'MM/dd/yyyy');
  }

  serialize(): any {
    return {
      name: this.name,
      code: this.code,
      type: this.type,
    };
  }
}
