import { Injectable } from '@angular/core';
import { TranslationWrapperService } from 'ngx-web-api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationInjectionService implements TranslationWrapperService {
  constructor(private translationService: TranslateService) {}

  translate(key: string | Array<string>, interpolateParams?: Object): any {
    return this.translationService.instant(key, interpolateParams);
  }
}
