export function getCwoBaseHref() {
  const routedSegments = getRoutedSegments();
  const urlSegmentArray = window.location.pathname.split('/');

  // Production URL path: host/ordering/{storeId}/{clientRoute} => baseHref: '/ordering/{storeId}/'
  if (urlSegmentArray[1] === 'ordering' && !!urlSegmentArray[2] && !routedSegments.includes(urlSegmentArray[2])) {
    return `/ordering/${urlSegmentArray[2]}/`;
  }
  // Development URL path: host/{storeId}/{clientRoute} => baseHref: '/{storeId}/'
  if (!!urlSegmentArray[1] && !routedSegments.includes(urlSegmentArray[1])) {
    return `/${urlSegmentArray[1]}/`;
  }
  throwNoStoreIdError();
}

export function getCwoStoreId() {
  const routedSegments = getRoutedSegments();
  const urlSegmentArray = window.location.pathname.split('/');

  // Production
  if (urlSegmentArray[1] === 'ordering' && !!urlSegmentArray[2] && !routedSegments.includes(urlSegmentArray[2])) {
    return urlSegmentArray[2];
  }
  // Development
  if (!!urlSegmentArray[1] && !routedSegments.includes(urlSegmentArray[1])) {
    return urlSegmentArray[1];
  }
  throwNoStoreIdError();
}

function getRoutedSegments() {
  return [
    'intro',
    'home',
    'expiration',
    'addCoupon',
    'reorder',
    'invite',
    'survey',
    'login',
    'register',
    'receiptError',
    'account',
    'checkout',
    'pay',
    'loyalty',
    'menu',
    'tracker',
    'questionnaire',
    'editor',
    'thanks',
    'joinGroupOrder',
    '404',
  ];
}

export function throwNoStoreIdError() {
  if (!!document.getElementById('ftsErrorHelpText')) {
    return;
  }

  const myNode = document.getElementById("ftsPreloadContent");
  while (myNode.firstChild) {
    myNode.removeChild(myNode.lastChild);
  }
  myNode.parentElement.removeChild(myNode);

  const newMain = document.createElement('div');
  newMain.classList.add('preload-container');
  newMain.id = 'ftsNotFoundErrorContent';
  document.body.prepend(newMain);

  newMain.style.backgroundColor = 'rgb(230 230 230)';
  newMain.style.flexDirection = 'row';
  newMain.style.flexWrap = 'wrap';
  newMain.style.alignItems = 'center';
  newMain.style.justifyContent = 'center';
  newMain.style.overflow = 'auto';

  const pizzaImg = document.createElement('img');
  pizzaImg.src = window.location.pathname.includes('ordering') ? '/ordering/assets/img/pizza.png' : '/assets/img/pizza.png';
  pizzaImg.style.objectFit = 'contain';
  pizzaImg.style.maxWidth = '400px';
  pizzaImg.style.width = '80vw';

  const helpText = document.createElement('div');
  helpText.id = 'ftsErrorHelpText';
  helpText.style.color = '#414141';
  helpText.style.fontSize = '4rem';
  helpText.style.fontFamily = 'Trebuchet MS, Verdana, Tahoma';
  helpText.style.padding = '1.6rem';
  helpText.style.fontWeight = 'bold';
  helpText.textContent = 'Oh No!';

  const helpText2 = document.createElement('div');
  helpText2.innerHTML = 'The URL &quot;' + location.pathname + '&quot;<br>was not found.';
  helpText.style.color = '#414141';
  helpText.style.fontFamily = 'Trebuchet MS, Verdana, Tahoma';
  helpText2.style.fontSize = '1.6rem';
  helpText.style.fontWeight = 'normal';

  document.getElementById('ftsNotFoundErrorContent').appendChild(helpText);
  document.getElementById('ftsErrorHelpText').appendChild(helpText2);
  document.getElementById('ftsErrorHelpText').insertAdjacentElement('afterend', pizzaImg);
  window.history.replaceState('null', 'null', window.location.pathname.includes('ordering') ? "/ordering/not-found" : '/not-found');
  window.___ftsCwoInitFailedNoValidStoreId___ = true;

  while(!document.querySelector('fts-root')) {
    // do nothing
  }
  const appRoot = document.querySelector('fts-root');
  appRoot.parentElement.removeChild(appRoot);

  throw new Error('No valid store identifier provided in URL');
}
