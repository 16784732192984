<div class="secondary-bg-color secondary-text-color">
  <nav
    id="headerMenu"
    class="header-container"
    [ngClass]="{'render-in-home container no-padding': isInHome}"
    [attr.aria-label]="'component.header.minified_header.navigation_label' | translate">
    <div class="header" *ngIf="!(breakpointService.isTabletDown$ | async)">
      <div class="left-container">
        <div class="store-logo-wrapper">
          <fts-store-logo
            *ngIf="!isInHome"
            [logoRedirectToStaticSite]="store?.logoRedirectToStaticSite"
            [staticSiteUrl]="store?.staticSiteUrl"
            [src]="store?.logo"
            [compact]="true">
          </fts-store-logo>
        </div>

        <fts-menu-link
          *ngIf="showMenuBtn && (breakpointService.isMdViewport$ | async)"
          [panel]="panel"
          [visibleMenu]="visibleMenu"
          [minifiedHeader]="true"
          (onToggleMobileMenu)="toggleMobileMenu($event)">
        </fts-menu-link>
      </div>

      <div class="right-container">
        <fts-menu-link
          *ngIf="showMenuBtn && (breakpointService.isDesktop$ | async)"
          [panel]="panel"
          [minifiedHeader]="true"
          (onToggleMobileMenu)="toggleMobileMenu($event)">
        </fts-menu-link>

        <ng-container *ngFor="let featuredContent of featuredContents">
          <a *ngFor="let featuredItem of featuredContent.featuredItems"
            [href]='featuredItem.link'
            [target]="featuredItem.target"
            class="btn btn-link btn-undecorated font-secondary text-uppercase header-link featured-link"
            [id]='"header-featured-link-" + (featuredItem.title | slugify)'>
            {{featuredItem.title}}
          </a>
        </ng-container>

        <ng-container [ngTemplateOutlet]="groupOrderButton"></ng-container>

        <a *ngIf="store.hasLoyalty && !!loyaltyPlan?.id && !store.hasThirdPartyLoyalty && (breakpointService.isDesktop$ | async)"
          [routerLink]="['/loyalty']"
          class="btn btn-link btn-undecorated font-secondary text-uppercase header-link"
          id="header-link-loyalty">{{loyaltyPlan?.loyaltyPlanName}}</a>

        <fts-track-order-btn
          class="header-link"
          *ngIf="!isSelfOrder && (menuWrapperService.orderIdCookieExists$ | async)"
          [fontWeight]="true"
          [btnUndecorated]="true"
          [minifiedHeader]="true"
        ></fts-track-order-btn>

        <ng-container *ngIf="!isSelfOrder && !(isInThanksPage$ | async) && (store?.hasStoreChooser || (order?.isInitialized && hasMultipleOrderTypeConfigs)) && !isInCheckoutDetails && (breakpointService.isDesktop$ | async)">
          <button
            *ngIf="!isSmallDesktop"
            class="btn btn-link btn-undecorated font-secondary no-border header-link store-info-btn"
            ftsAsyncButton ftsAuditableButton auditName="Change Store/Order type"
            [attr.aria-label]="'component.header.minified_header.store_icon.store_info' | translate"
            (click)="toggleMobileMenu(menus.storeChooser)">
            <fts-store-info
              [orderType]="order?.orderType"
              [storeConfig]="store"
              [minifiedHeader]="true"
            ></fts-store-info>
          </button>

          <button
            *ngIf="isSmallDesktop"
            class="btn-undecorated primary-bg-color primary-text-color primary-border-color store-icon-button"
            ftsAsyncButton ftsAuditableButton auditName="Change Store/Order type"
            [subscription]="loadingStoreStates"
            [attr.aria-label]="'component.header.menu_links.minified_header.store_info' | translate"
            (click)="toggleMobileMenu(menus.storeChooser)"
            [title]="'component.header.minified_header.store_icon.store_info' | translate">
            <fa-icon [size]="'lg'" [fixedWidth]="true" [icon]="faFtsStore"></fa-icon>
          </button>
        </ng-container>

        <button class="btn-undecorated primary-bg-color primary-text-color primary-border-color account-button" (click)="handleAccountIconClicked()"
              [attr.aria-label]="'component.header.minified_header.account_options' | translate"
              [title]="'component.header.minified_header.account_options' | translate">
          <fa-icon [size]="'lg'" [fixedWidth]="true" [icon]="faFtsAccount"></fa-icon>
        </button>

        <fts-menu-cart
          *ngIf="!(isInHome || isInIntro) && !isInCheckout && !order.dummy && order.isInitialized && !(isInTrackerPage$ | async)"
          class="margin-left-15"
          [order]="order"
        ></fts-menu-cart>

        <fts-sidebar-menu
          class="margin-left-15"
          [order]="order"
          [store]="store"
          [account]="account"
          [loyaltyPlan]="loyaltyPlan"
          [hasMultipleOrderTypeConfigs]="hasMultipleOrderTypeConfigs"
          [isInIntro]="isInIntro"
          [isSelfOrder]="isSelfOrder"
          [isInCheckoutDetails]="isInCheckoutDetails"
          [isInCheckout]="isInCheckout"
          [openSidebarMenu]="openSideBarMenu"
          [featuredContents]="featuredContents"
          (login)="login.emit()"
          (logout)="logout.emit()"
          (sidebarMenuClosed)="openSideBarMenu = false"
          (onToggleMobileMenu)="toggleMobileMenu($event)"
        ></fts-sidebar-menu>
      </div>

      <div *ngIf="!(breakpointService.isDesktop$ | async)"
           id="overlay-menu-tablet"
           class="sidebar-menu primary-bg-color"
           [ngClass]="{
            'd-none': !(visibleMenu === menus.tabs && (breakpointService.isMdViewport$ | async))
          }"
           [ngStyle]="{
             'height': (mobileMenuHeight$ | async) + 'px',
             'top': (mobileMenuTopPosition$ | async) + 'px'
           }">
        <fts-vertical-categories-menu
          [panel]='panel'
          [hasLeftMenuIcons]="theme.hasLeftMenuIcons"
          (onTabClick)="toggleMobileMenu($event)">
        </fts-vertical-categories-menu>
      </div>
    </div>

    <div *ngIf="breakpointService.isTabletDown$ | async" class="mobile-header-wrapper">
      <div class="mobile-header">
        <div class="d-flex">
          <button class="btn btn-link btn-header btn-undecorated text-uppercase font-secondary fw-600 no-right-padding d-flex"
                  *ngIf="!isInCheckout && visibleMenu === menus.orderTree && !(isInThanksPage$ | async)"
                  (click)="toggleMobileMenu(menus.orderTree)">
            <fa-icon [fixedWidth]="true" [icon]="faChevronLeft"></fa-icon>
            <span>{{'component.header.minified_header.close_order_tree' | translate}}</span>
          </button>
          <fts-menu-link
            *ngIf="showMenuBtn && visibleMenu !== menus.orderTree"
            [visibleMenu]="visibleMenu"
            [panel]="panel"
            [showCategoriesUpfront]="theme.shouldShowCategoriesUpfront"
            (onToggleMobileMenu)="toggleMobileMenu($event)"
          ></fts-menu-link>

          <fts-track-order-btn
            class="header-link"
            *ngIf="!isSelfOrder && (menuWrapperService.orderIdCookieExists$ | async) && visibleMenu !== menus.orderTree"
            [fontWeight]="true"
          ></fts-track-order-btn>
        </div>

        <div class="right-container">
          <ng-container [ngTemplateOutlet]="groupOrderButton"></ng-container>

          <fts-cart-button
            *ngIf="!(isInHome || isInIntro) && !isInCheckout && !order.dummy && order.isInitialized && !(isInTrackerPage$ | async)"
            class="margin-right-10"
            [order]="order"
            (click)="toggleMobileMenu(menus.orderTree)"
          ></fts-cart-button>

          <button *ngIf="isInIntro && !account?.isInstantiated"
                  class="btn btn-link btn-undecorated btn-header btn-menu-link text-uppercase visible-xs font-secondary fw-600"
                  (click)="login.emit()">
            {{'component.header.minified_header.sign_in' | translate}}
          </button>

          <button *ngIf="isInIntro && !account?.isInstantiated && !(menuWrapperService.orderIdCookieExists$ | async)"
                  [routerLink]="['/register']"
                  class="btn btn-link btn-undecorated btn-header btn-menu-link text-uppercase visible-xs font-secondary fw-600">
            {{'component.header.minified_header.sign_up' | translate}}
          </button>

          <fts-hamburger-button class="margin-right-10" (click)="toggleMobileMenu(menus.links)" [ngClass]="{'margin-left-10': isInIntro }"></fts-hamburger-button>
        </div>
      </div>

      <div id="minifiedMenu" *ngIf="showMinifiedMenu">
        <fts-menu-index [panel]="panel" [isInHeader]="true"></fts-menu-index>
      </div>

      <div class="overlay" *ngIf="visibleMenu !== undefined && visibleMenu !== menus.orderTree && (breakpointService.isTabletDown$ | async)" [style.top.px]="mobileMenuTopPosition$ | async" (click)="handleOverlayClick()"></div>

      <!-- We need the undefined check for visibleMenu since it is a typescript enum which can be 0 but still we want to show the menu-->
      <div id='overlay-menu'
           *ngIf=" (theme.hasSinglePageMenu || shouldRenderOverlayMenu) && !(breakpointService.isDesktop$ | async)"
           class="overlay-menu primary-bg-color primary-text-color"
           [ngStyle]="{
             'height': (mobileMenuHeight$ | async) + 'px',
             'top': (mobileMenuTopPosition$ | async) + 'px',
             'z-index':visibleMenu === menus.storeChooser ? '1':''
           }"
           [ngClass]="{
             'full-width': theme.hasTopMenu || visibleMenu !== menus.tabs,
             'mobile-tabs': visibleMenu === menus.tabs,
             'mobile-links': visibleMenu === menus.links,
             'order-tree': visibleMenu === menus.orderTree,
             'd-none': visibleMenu === undefined || (theme.hasSinglePageMenu && !shouldRenderOverlayMenu && !theme.minifiedMenu)
           }">
        <div class="content"
             [style.height.px]="contentMobileMenuHeight$ | async">
          <fts-vertical-categories-menu
            *ngIf="(theme.hasSinglePageMenu && !theme.minifiedMenu) || visibleMenu === menus.tabs"
            [ngClass]="{'d-none': theme.hasSinglePageMenu && !theme.minifiedMenu && visibleMenu !== menus.tabs}"
            [panel]='panel'
            [hasLeftMenuIcons]="theme.hasLeftMenuIcons"
            (onTabClick)="toggleMobileMenu($event)">
          </fts-vertical-categories-menu>

          <fts-vertical-menu-links
            *ngIf="visibleMenu === menus.links"
            [isInIntro]="isInIntro"
            [account]="account"
            [store]="store"
            [loyaltyPlan]="loyaltyPlan"
            [order]="order"
            [isInCheckoutDetails]="isInCheckoutDetails"
            [theme]="theme"
            [showChangeStoreBtn]="!isInCheckout && (store.hasStoreChooser || (order?.isInitialized && hasMultipleOrderTypeConfigs))"
            [isSelfOrder]="isSelfOrder"
            [minifiedHeader]="true"
            [featuredContents]="featuredContents"
            (login)="login.emit()"
            (logout)="logout.emit()"
            (changeStore)="toggleStoreChooser()">
          </fts-vertical-menu-links>
        </div>

        <div class="order-tree-container primary-bg-color" *ngIf="visibleMenu === menus.orderTree" [@expanded] (@expanded.done)="expandedAnimationEnd()">
          <div class="mobile-order-tree visible-xs">
            <ng-template [ngComponentOutlet]="mobileOrderMenu | async"></ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="order-notification" [style.top.px]="(mobileMenuTopPosition$ | async)">
      <ng-template *ngIf="!isInCheckoutDetails && orderNotification" [ngComponentOutlet]="orderNotification | async"></ng-template>
    </div>
  </nav>
</div>

<ng-template #groupOrderButton>
  <fts-grouporder-button 
    [label]="'component.header.minified_header.group_order' | translate"
    [buttonStyle]="'LINK_MINIFIED'"
    [isTabletDown]="breakpointService.isTabletDown$ | async"
>
    >
  </fts-grouporder-button>
</ng-template>