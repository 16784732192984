import { ConfirmationModalContext } from './confirmation-modal-context';

export class ResendVerificationEmailModalContext extends ConfirmationModalContext {
  constructor(email: String) {
    super();
    this.title = 'Your email is not verified';
    this.dismissBtnTitle = 'Close';
    this.submitBtnTitle = 'Resend Verification';
    this.mainContent = `<p>Click "Resend Verification Email" below, and check your <strong>${email}</strong> email. Within 2 minutes you
    should receive the email, click the link contained in that email to be able to continue and checkout.</p>`;
  }
}
