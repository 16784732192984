<section class="overlay">
  <div class="container padded">
    <div class="text-center" *ngIf='store'>
        <h2 class='title font-secondary'>{{'route.intro.welcome' | translate:{store: store.name} }}</h2>
    </div>

    <fts-order-type-form [store]="store" [orderTypeConfigs]="orderTypes" [isInIntro]="true"></fts-order-type-form>
  </div>
  <div *ngIf="store?.webOrderingIntroDisclaimer" class="margin-top-10 margin-bottom-10 padded-bottom-15 padded-top-10 text-center">
    {{store.webOrderingIntroDisclaimer}}
  </div>
</section>
