import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckoutDetailsInvalidFieldsFocusService {
  private orderedCustomerFields: Array<string> = [];
  private orderedAddressFields: Array<string> = [];
  private firstInvalidFieldName: Subject<string> = new Subject<string>();
  public firstInvalidFieldName$: Observable<string> = this.firstInvalidFieldName.asObservable();
  public forceValidate$: Subject<void> = new Subject();

  public findFirstElementToFocus(errors: Array<string>): boolean {
    const filteredArray = this.filterFieldsByErrors(errors, [...this.orderedCustomerFields, ...this.orderedAddressFields]);
    this.firstInvalidFieldName.next(filteredArray[0]);
    return !!filteredArray[0];
  }

  public addCustomerFieldName(name: string) {
    if (!(this.orderedCustomerFields.indexOf(name) !== -1)) {
      this.orderedCustomerFields.push(name);
    }
  }

  public addAddressFieldName(name: string) {
    if (!(this.orderedAddressFields.indexOf(name) !== -1)) {
      this.orderedAddressFields.push(name);
    }
  }

  public triggerValidation() {
    this.forceValidate$.next();
  }

  private filterFieldsByErrors(errors: Array<string>, orderedFields: Array<string>): Array<string> {
    return orderedFields.filter(e => {
      return errors.indexOf(e) >= 0;
    });
  }
}
