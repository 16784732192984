import { Injectable } from '@angular/core';
import { getCwoStoreId, getCwoBaseHref } from '../../../assets/scripts/fts-cwo.js';

@Injectable({
  providedIn: 'root',
})
export class CwoService {
  private _storeName: string;
  private _baseHref: string;

  constructor() {
    this._storeName = getCwoStoreId();
    this._baseHref = getCwoBaseHref();
  }

  get storeName(): string {
    if (!this._storeName) {
      this._storeName = getCwoStoreId();
    }
    return this._storeName;
  }

  get baseHref(): string {
    if (!this._baseHref) {
      this._baseHref = getCwoBaseHref();
    }
    return this._baseHref;
  }
}
