"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsRewards";
var width = 540;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "m 538.79702,193.70032 c -3.26461,-10.88204 -13.05844,-18.49947 -25.02869,-18.49947 L 348.36132,171.93624 293.95112,17.411265 C 290.6865,6.5292242 279.80446,0 268.92242,0 258.04038,0 247.15834,7.6174283 243.89373,17.411265 l -54.4102,154.524975 -163.230608,3.26461 c -10.88204,0 -20.6758763,7.61743 -25.0286925,18.49947 -3.2646121,10.88204 0,22.85228 9.7938365,29.38151 L 141.60255,321.02019 94.809777,477.72157 c -3.264612,10.88204 1.088204,22.85229 9.793833,29.38151 8.70564,6.52923 21.76408,6.52923 30.46972,0 l 134.9373,-93.58555 136.0255,93.58555 c 4.35282,3.26461 9.79384,4.35282 15.23486,4.35282 5.44102,0 10.88204,-2.17641 15.23485,-5.44102 8.70564,-6.52923 13.05845,-18.49947 9.79384,-29.38151 L 398.4187,321.02019 530.09139,221.99362 c 8.70563,-6.52922 11.97025,-17.41126 8.70563,-28.2933 z m -21.76408,11.97024 -137.11371,103.37939 c -3.26461,3.26461 -5.44102,7.61743 -4.35281,11.97024 L 425.6238,484.2508 c 1.08821,2.17641 0,4.35281 -2.1764,5.44102 -1.08821,1.0882 -4.35282,1.0882 -5.44102,0 L 276.53985,391.75345 c -2.17641,-1.0882 -4.35281,-2.1764 -6.52922,-2.1764 -2.17641,0 -4.35282,1.0882 -6.52923,2.1764 l -141.46652,97.93837 c -2.17641,1.0882 -3.26461,1.0882 -5.44102,0 -1.08821,-1.08821 -2.17641,-3.26461 -2.17641,-5.44102 l 50.05739,-163.23061 c 1.0882,-4.35281 0,-8.70563 -4.35282,-11.97024 L 24.076514,205.67056 c -2.176408,-1.0882 -2.176408,-3.26461 -2.176408,-5.44102 1.088204,-2.17641 2.176408,-3.26461 4.352816,-3.26461 l 173.024438,-3.26461 c 4.35282,0 8.70564,-3.26461 9.79384,-7.61743 L 265.65781,25.028693 c 1.0882,-3.264612 7.61743,-3.264612 8.70563,0 l 56.58661,161.054197 c 1.08821,4.35282 5.44102,7.61743 9.79384,7.61743 l 173.02444,3.26461 c 2.17641,0 3.26461,1.0882 4.35282,3.26461 1.0882,2.17641 0,4.35282 -1.08821,5.44102 z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.faFtsRewards = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
