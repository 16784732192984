import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingAction } from 'app/domain/landing-action.enum';
import { HomeGuard } from './home/home-guard';
import { HomeComponent } from './home/home.component';
import { IntroGuard } from './intro/intro-guard';
import { IntroComponent } from './intro/intro.component';
import { NeedsSignedOutGuard } from './shared/guards/signed-out-guard';
import { OrderTypesResolver } from './shared/resolvers/order-types-resolver';
import { StoreConfigResolver } from './shared/resolvers/store-config-resolver';
import { NoSkipIntroGuard } from './intro/no-skip-intro.guard';
import { HasTokenParamGuard } from './shared/guards/has-token-param.guard';
import { SelfSourceOrderGuard } from './shared/guards/self-source-order.guard';
import { AccountResourceConfigResolver } from './shared/resolvers/account-resource-config-resolver';
import { ValidSelfOrderGuard } from './checkout/valid-self-order-guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'intro' },
  {
    path: 'intro',
    component: IntroComponent,
    canActivate: [IntroGuard, NoSkipIntroGuard],
    data: { title: 'component.routing.intro' },
    resolve: {
      store: StoreConfigResolver,
      orderTypes: OrderTypesResolver,
    },
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [HomeGuard],
    data: { title: 'component.routing.home' },
    resolve: {
      store: StoreConfigResolver,
    },
  },
  {
    path: 'expiration',
    loadChildren: () => import('./session-expiration/session-expiration.module').then(m => m.SessionExpirationModule),
    data: { title: 'component.routing.expired' },
  },
  {
    path: 'addCoupon',
    loadChildren: () => import('./shared/landing-action/landing-action.module').then(m => m.LandingActionModule),
    data: { action: LandingAction.ADD_COUPON, skipRedirect: true },
  },
  {
    path: 'reorder',
    loadChildren: () => import('./shared/landing-action/landing-action.module').then(m => m.LandingActionModule),
    data: { action: LandingAction.REORDER, skipRedirect: true },
  },
  {
    path: 'invite',
    loadChildren: () => import('./invitation/invitation.module').then(m => m.InvitationModule),
  },
  { path: 'survey', loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule) },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [NeedsSignedOutGuard],
    resolve: {
      accountResourceConfig: AccountResourceConfigResolver,
    },
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
    canActivate: [NeedsSignedOutGuard],
  },
  {
    path: 'receiptError',
    loadChildren: () => import('./receipt-error/receipt-error.module').then(m => m.ReceiptErrorModule),
    canActivate: [HasTokenParamGuard],
  },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: 'pay',
    loadChildren: () => import('./pay/pay.module').then(m => m.PayModule),
  },
  { path: 'loyalty', loadChildren: () => import('./loyalty/loyalty.module').then(m => m.LoyaltyModule) },
  { path: 'menu', loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule), canActivate: [ValidSelfOrderGuard] },
  {
    path: 'tracker',
    loadChildren: () => import('./order-tracker/order-tracker.module').then(m => m.OrderTrackerModule),
  },
  {
    path: 'questionnaire',
    loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
  },
  {
    path: 'editor',
    loadChildren: () => import('./item-editor/item-editor.module').then(m => m.ItemEditorModule),
  },
  {
    path: 'thanks',
    loadChildren: () => import('./shared/self-source-thanks-page/self-source-thanks.module').then(m => m.SelfSourceThanksModule),
    canActivate: [SelfSourceOrderGuard],
  },
  {
    path: 'joinGroupOrder',
    loadChildren: () => import('./join-group-order/join-group-order.module').then(m => m.JoinGroupOrderModule),
  },
  {
    path: 'printReceipt',
    loadChildren: () => import('./print-receipt/print-receipt.module').then(m => m.PrintReceiptModule),
  },
  {
    path: 'not-found',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
    data: { title: 'component.routing.page_not_found' },
  },
  {
    path: '**',
    loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPageModule),
    data: { title: 'component.routing.page_not_found' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
