import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { StoreConfig, Order } from 'ngx-web-api';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil, map, take, startWith } from 'rxjs/operators';
import { Subject, combineLatest, interval } from 'rxjs';
import { ScrollEventService } from 'app/shared/scroll-event.service';
import { UiOrchestratorService } from 'app/core/services/ui-orchestrator.service';
import { ViewportService } from 'app/core/services/viewport.service';
import { StickyDirective } from 'app/shared/sticky.directive';
import { MenuWrapperService } from 'app/core/services/menu-wrapper.service';
import { Theme } from 'app/domain/theme.model';

@Component({
  selector: 'fts-banner-header',
  templateUrl: './banner-header.component.html',
  styleUrls: ['./banner-header.component.scss'],
})
export class BannerHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  store: StoreConfig;
  @Input()
  hasMultipleOrderTypeConfigs: boolean;
  @Input()
  isInCheckout: boolean;
  @Input()
  order: Order;
  @Input()
  theme: Theme;
  @Input()
  isMobile: boolean;

  @Output()
  bannerHeaderChange: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  logoHeightChange: EventEmitter<number> = new EventEmitter();

  @Output()
  verticalMenuHeightRecalc: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('storeLogoWrapper', { static: true })
  private storeLogoWrapper: ElementRef;

  @ViewChild(StickyDirective, { static: true })
  private stickyRef: StickyDirective;

  private destroy$ = new Subject<void>();
  initHeight = 0;
  bannerOpacity = 1;
  hideBanner = false;
  showSPMUpfrontOrTiledMenu: boolean;

  constructor(
    private router: Router,
    private scrollService: ScrollEventService,
    private uiService: UiOrchestratorService,
    private viewportService: ViewportService,
    private menuService: MenuWrapperService
  ) {}

  ngOnInit() {
    this.uiService.showUpfrontOrTiled$.subscribe(upFrontMenu => (this.showSPMUpfrontOrTiledMenu = upFrontMenu));
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((val: NavigationEnd) => {
        this.bannerHeaderChange.next();
      });
    this.scrollService.unthrottledScroll$.pipe(takeUntil(this.destroy$)).subscribe(() => this.calcHeight());

    combineLatest([
      this.viewportService.viewport$.pipe(map(v => v.isMobile)),
      this.uiService.orderTreeIsOpen$,
      this.uiService.showSinglePageMobileUpfrontMenu$.pipe(startWith(false)),
      this.uiService.showTiledMenuLinks$,
    ])
      .pipe(
        map(
          ([isMobile, treeOpen, showSinglePageMobileUpfrontMenu, showTiledMenuLinks]) =>
            (isMobile && (treeOpen || showSinglePageMobileUpfrontMenu)) || showTiledMenuLinks
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(hideBanner => {
        this.hideBanner = hideBanner;
        this.calcHeight();
      });

    this.scrollService.resize$.pipe(takeUntil(this.destroy$)).subscribe(() => (this.calcHeight(), this.verticalMenuHeightRecalc.emit()));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setInitHeight();
    }, 1);
    interval(500)
      .pipe(take(6))
      .subscribe(() => setTimeout(() => this.menuService.setHeaderHeight(this.calcHeaderHeight()), 100));
  }

  setInitHeight() {
    this.initHeight = this.storeLogoWrapper.nativeElement.getBoundingClientRect().height;
    this.uiService.setBannerHeight(this.initHeight);
    this.logoHeightChange.emit(this.initHeight);
    this.stickyRef.refreshStickyList();
  }

  private calcHeight() {
    const currentHeight = this.initHeight - window.pageYOffset < 0 ? 0 : this.initHeight - window.pageYOffset;
    this.bannerOpacity = (currentHeight ?? this.initHeight) / this.initHeight;
    this.stickyRef.refreshStickyList();
    setTimeout(() => this.menuService.setHeaderHeight(this.calcHeaderHeight()), 100);
  }

  private calcHeaderHeight(): number {
    const header = document.getElementById('header');
    if (this.isMobile && this.showSPMUpfrontOrTiledMenu && (!this.theme.compactHeader || !this.theme.minifiedHeader)) {
      return header.clientHeight - Math.abs(header.getBoundingClientRect().top);
    } else {
      return header.clientHeight;
    }
  }
}
