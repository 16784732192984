import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OrderType, OpenCloseTime } from 'ngx-web-api';

@Component({
  selector: 'fts-store-open-close-time',
  templateUrl: 'store-open-close-time.component.html',
  styleUrls: ['store-open-close-time.component.scss'],
})
export class StoreOpenCloseTimeComponent implements OnChanges {
  orderTypeIsAny: boolean;

  @Input()
  orderType: OrderType;
  @Input()
  openCloseTime: OpenCloseTime;
  @Input()
  multiline = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orderType) {
      this.orderTypeIsAny = this.orderType === OrderType.Any;
    }
  }
}
