import { Component, EventEmitter, Input, Output, forwardRef, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { CheckoutDetailsInvalidFieldsFocusService } from '../../core/services/checkout-details-invalid-fields-focus.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fts-edit-address-diff',
  templateUrl: './edit-address-diff.component.html',
  styleUrls: ['./edit-address-diff.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditAddressDiffComponent),
      multi: true,
    },
  ],
})
export class EditAddressDiffComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input()
  name: string;
  @Input()
  private _diffValue: string;
  @Input()
  required: boolean;
  @Input()
  errors: string[];
  @Input()
  showClientValidation: boolean;
  @Input()
  horizontal = true;
  @Input()
  diffValueMaxLength: number;
  @Input()
  addressTypeDiffNumber: number;

  @Output()
  diffValueChange = new EventEmitter<string>();

  @ViewChild(NgModel, { read: ElementRef, static: true })
  formField: ElementRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private checkoutDetailsInvalidFieldsFocusService: CheckoutDetailsInvalidFieldsFocusService) {}

  ngAfterViewInit() {
    this.checkoutDetailsInvalidFieldsFocusService.addAddressFieldName(`value${this.addressTypeDiffNumber}`);
    this.checkoutDetailsInvalidFieldsFocusService.firstInvalidFieldName$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value === `value${this.addressTypeDiffNumber}`) {
        this.formField.nativeElement.focus();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get diffValue(): string {
    return this._diffValue;
  }

  set diffValue(value: string) {
    this._diffValue = value;
    this.propagateChange(this._diffValue);
  }

  handleChange(value) {
    this.diffValueChange.emit(value);
  }

  writeValue(obj: any): void {
    this._diffValue = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(_fn: any): void {
    /* Just inherit the method from the ControlValueAccessor */
  }

  private propagateChange = (_: any) => {};
}
