<button 
  [ngClass]="buttonCSS" 
  class="btn text-uppercase font-secondary full-height "
  *ngIf="showGroupOrderButton && !(isRemovable && isGroupOrderActive)"
  (click)="initiateGoupOrder()"
  [ngStyle]="{'cursor' : isGroupOrderActive ? 'default' : 'pointer'}">
  <ng-container *ngIf="isGroupOrderActive">
    <fa-icon [icon]="faUserGroup"></fa-icon>
    <span class="active-text">{{'component.header.active_group_order' | translate}}</span>
  </ng-container>
  <ng-container *ngIf="!isGroupOrderActive">
    <fa-icon class="link-icon" [size]="'lg'" [fixedWidth]="true"  [icon]="faUserGroup" *ngIf="isInMinifiedAccountSidebar"></fa-icon>
    <span>{{label}}</span>
  </ng-container>
</button>
