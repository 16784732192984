"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var prefix = "faf";
var iconName = "faFtsArrowRight";
var width = 706;
var height = 512;
var ligatures = [];
var unicode = null;
var svgPathData =
  "m 396.98628,3.352645e-7 c -4.20821,0 -7.03015,-0.005512415265 -9.83561,1.3971350647355 -4.20822,1.4027303 -8.40001,2.8164165 -11.20548,4.2191605 -16.83288,8.4164231 -28.0548,26.6356401 -28.0548,46.2739951 V 137.4793 L 91.178078,164.10961 C 40.67945,164.10961 0,206.21369 0,256.71231 c 0,50.49864 40.67945,92.57535 91.178078,92.57535 l 256.712312,25.23291 v 85.58899 c 0,4.20824 -0.006,8.40003 1.39727,11.20553 0,1.40273 -0.006,1.41367 1.39727,4.21916 1.40273,4.20819 2.81645,7.00275 4.21918,9.80822 l 1.39727,2.82189 c 2.80548,2.80549 4.21368,5.61096 5.61643,7.0137 l 1.39726,2.79454 1.39725,1.39727 c 4.20822,2.80548 7.02465,5.61097 11.23287,7.0137 7.0137,4.20823 15.41918,5.61643 23.83562,5.61643 11.22191,0 21.05205,-2.81642 32.27397,-11.23285 L 684.54792,297.3699 c 12.62464,-9.81917 21.04109,-25.24933 21.04109,-42.0822 0,-15.43014 -8.41645,-30.83838 -21.04109,-40.65756 L 430.63013,9.8082388 C 420.81096,2.7945462 411.00272,3.352645e-7 399.78081,3.352645e-7 Z M 395.56162,42.082218 h 1.42466 2.79453 c 2.80549,0 4.21917,1.397107 4.21917,1.397107 L 657.89038,246.8766 c 2.80549,1.40273 4.1918,4.20821 4.1918,7.01369 0,2.80549 -1.38634,5.60548 -4.1918,8.41096 L 405.39724,467.12313 c -2.80546,2.80548 -7.03013,2.80001 -9.83562,1.39714 -1.40273,0 -1.39179,0.006 -2.7945,-1.39714 l -1.39728,-2.8219 c -1.40274,-1.40273 -1.39727,-2.79453 -1.39727,-2.79453 V 338.05467 L 93.9726,307.20548 h -1.397254 c -28.054794,0 -50.49315,-22.43837 -50.49315,-50.49317 0,-28.05479 22.438356,-50.5205 50.49315,-50.5205 L 389.97258,173.94521 V 50.49317 c 0,-2.805475 2.78358,-7.008222 5.58904,-8.410952 z";

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData]
};

exports.faFtsArrowRight = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
