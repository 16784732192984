import { AbstractControl } from '@angular/forms';
import { QuestionnaireResponse } from '../../models/questionnaires/questionnaire-response.model';

export class QuestionnaireCustomValidators {
  static date(control: AbstractControl): { [key: string]: boolean } {
    const response: QuestionnaireResponse = control.value;

    if (!response || !response.answer) {
      return {};
    }
    // eslint-disable-next-line max-len
    const pattern = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;

    if (pattern.test(response.answer)) {
      return {};
    }
    return { date: true };
  }
}
