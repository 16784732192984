import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { GroupOrderFriend } from './group-order-friend.model';

export class GroupOrderInvitation extends BaseResource {
  friends: GroupOrderFriend[] = [];
  note: string;
  expirationDate: string;
  memberPayment: boolean;

  deserialize(input: any): this {
    return Object.assign(this, input, {
      friends: DeserializationUtils.deserializeChildren(input, 'friends', GroupOrderFriend),
    });
  }

  serialize(): any {
    return Object.assign({}, this, {
      friends: (this.friends || []).map(f => f.serialize()),
    });
  }
}
