import { DeserializationUtils } from '../../utils/deserialization-utils';
import { Utils } from '../../utils/utils';
import { Address } from '../core/address.model';
import { ValidatedResource } from '../core/validated-resource';

export class OrderCustomer extends ValidatedResource {
  customerId: number;
  firstName: string;
  lastName: string;
  loyaltyPlan: string;
  loyaltyPlanDisplayName: string;
  subscribe: boolean;
  smsMarketing: boolean;
  email: string;
  address: Address;
  phone: string;
  phoneConfirmation: string;
  smsDisclaimer: string;

  get fullName(): string {
    return [this.firstName, this.lastName].filter(v => !!v).join(' ');
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      address: DeserializationUtils.deserializeChild(input, 'address', Address),
    });
  }

  serialize(isConfirmMethod?: boolean): any {
    return {
      customerId: this.customerId,
      firstName: this.firstName,
      lastName: this.lastName,
      subscribe: this.subscribe,
      smsMarketing: this.smsMarketing,
      email: this.email,
      address: this.address ? this.address.serialize() : undefined,
      phone: Utils.serializePhone(this.phone),
      phoneConfirmation: isConfirmMethod ? this.phoneConfirmation : Utils.serializePhone(this.phoneConfirmation),
      smsDisclaimer: this.smsDisclaimer,
    };
  }
}
