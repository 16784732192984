<div id='full-address' class="store-info" ftsSticky [stackingOrder]="2">
  <div class="store-info-bg secondary-bg" 
       [style.opacity]="hideBanner ? 1 : (1 - bannerOpacity)">
  </div>
  <div class="container text-center secondary-text-color store-info-text">
    <fts-store-info
      [orderType]="order?.orderType"
      [storeConfig]="store"
      [hasMultipleOrderTypeConfigs]="hasMultipleOrderTypeConfigs"
      [showChangeOrderTypeBtn]="!isInCheckout">
    </fts-store-info>
  </div>
</div>

<div id="store-logo-container" #storeLogoWrapper
     class="text-center store-logo-container" [class.d-none]="hideBanner">
  <div class="store-logo" [style.opacity]="bannerOpacity">
        <fts-store-logo
          [logoRedirectToStaticSite]="store?.logoRedirectToStaticSite"
          [staticSiteUrl]="store?.staticSiteUrl"
          [src]="store?.logo"
          (loadEvent)="setInitHeight()"></fts-store-logo>
  </div>
</div>
