import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FeaturedContent, FeaturedContentStyle, FeaturedItem, Utils } from 'ngx-web-api';
import { Subject } from 'rxjs';
import { FeaturedItemService } from '../../core/services/featured-item.service';
import { takeUntil } from 'rxjs/operators';
import { GhostHeightsService } from 'app/menu/ghost-heights.service';

@Component({
  selector: 'fts-featured-content',
  templateUrl: './featured-content.component.html',
  styleUrls: ['./featured-content.component.scss'],
})
export class FeaturedContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  showVertically: boolean;
  @Input()
  featuredContent: FeaturedContent;
  @Input()
  fillParentHeight: boolean;
  @Input()
  allowAddingItemsToOrder: boolean;
  @Input()
  skipLazyLoading: boolean;

  @Output()
  featuredItemClicked: EventEmitter<FeaturedItem> = new EventEmitter();
  @Output()
  featuredItemImageHeight: EventEmitter<void> = new EventEmitter();

  style = FeaturedContentStyle;
  featuredItems: FeaturedItem[];
  destroy$: Subject<void> = new Subject<void>();
  itemImgHeight: number;

  constructor(private featuredItemService: FeaturedItemService, private ghostHeightsService: GhostHeightsService) {}

  ngOnInit(): void {
    if (this.featuredContent) {
      this.setFeaturedItems(this.featuredContent);
      this.getItemImgHeight();
    }
  }

  ngOnChanges(changes: any): void {
    if (
      !!changes.featuredContent &&
      !!changes.featuredContent.currentValue &&
      changes.featuredContent.previousValue !== changes.featuredContent.currentValue
    ) {
      this.setFeaturedItems(changes.featuredContent.currentValue);
      this.getItemImgHeight();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleChosenFeaturedItem(featuredItem: FeaturedItem & { __slotNum?: string }): void {
    this.featuredItemClicked.next(featuredItem);
    if (this.allowAddingItemsToOrder && featuredItem.hasOrderable) {
      this.featuredItemService
        .addFeaturedItemToOrder(
          featuredItem,
          this.featuredContent.name || this.featuredContent.title,
          this.featuredContent.areaName.toString(),
          featuredItem.slotNum
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {});
    } else if (featuredItem.link) {
      let serializedPopupParams = '';
      let windowName;
      if (featuredItem.target === 'popup') {
        const popupParams = {
          width: window.innerWidth,
          height: window.innerHeight,
          resizable: 'yes',
          scrollbars: 'yes',
        };
        windowName = featuredItem.title || '';
        serializedPopupParams = Object.keys(popupParams)
          .map(prop => `${prop}=${popupParams[prop]}`)
          .join(',');
      } else if (featuredItem.target === 'new tab') {
        windowName = '_target';
      } else {
        windowName = '_self';
      }
      window.open(featuredItem.link, windowName, serializedPopupParams);
    }
  }

  onLoadImageFeaturedItemHeight(imgHeight: number) {
    this.ghostHeightsService.setFeaturedItemImageHeight(
      this.featuredContent.areaName,
      this.featuredContent.style,
      this.featuredContent.keyName,
      imgHeight
    );
    this.featuredItemImageHeight.emit();
  }

  getItemImgHeight() {
    this.itemImgHeight =
      this.itemImgHeight ??
      this.ghostHeightsService.getFeaturedItemImageHeight(
        this.featuredContent.areaName,
        this.featuredContent.style,
        this.featuredContent.keyName
      );
  }

  private setFeaturedItems(featuredContent: FeaturedContent) {
    if (featuredContent.numberOfItems === 0 || featuredContent.featuredItems.length <= featuredContent.numberOfItems) {
      this.featuredItems = featuredContent.featuredItems;
    } else {
      this.featuredItems = Utils.shuffle(featuredContent.featuredItems).slice(0, featuredContent.numberOfItems);
    }
  }
}
