import { BaseResource } from './base-resource';

export class CreditCardType extends BaseResource {
  readonly name: string;
  readonly image: string;

  get fontAwesomeIcon(): string {
    const cardTypeName = this.name.toLowerCase();
    switch (cardTypeName) {
      case 'visa':
      case 'mastercard':
      case 'amex':
      case 'discover':
      case 'jcb':
        return `cc-${cardTypeName}`;

      case 'diners':
        return 'cc-diners-club';
      default:
        return 'credit-card';
    }
  }

  get fontAwesomePrefix(): string {
    const cardTypeName = this.name.toLowerCase();
    switch (cardTypeName) {
      case 'visa':
      case 'mastercard':
      case 'amex':
      case 'discover':
      case 'jcb':
      case 'diners':
        return 'fab';
      default:
        return 'fas';
    }
  }

  get lowerCaseFirstChar(): string {
    return `${this.name.charAt(0).toLowerCase()}${this.name.slice(1)}`;
  }
}
