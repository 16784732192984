<button [class.active]="isActive" (click)="navigateToTab()"
   class='btn primary-text-color menu-item font-secondary'
   [ngClass]="{
     'font-size-12': isUpFrontMenu,
     'btn-block btn-menu': !isInMinifiedMenu,
     'text-uppercase fw-600 minified-menu-btn': isInMinifiedMenu,
     'selection-bg-color selection-inverse-text-color': isActive && isInMinifiedMenu
    }">
   <ng-container [ngTemplateOutlet]="imgTemplate"></ng-container>
</button>

<hr *ngIf="!isUpFrontMenu" aria-hidden="true" class="no-margin hidden-sm hidden-md hidden-lg">

<ng-template #imgTemplate>
  <div class="d-flex" [ngClass]="{'align-items-center': isInMinifiedMenu, 'w-100 justify-content-center': !isInMinifiedMenu}">
    <ng-container *ngIf="hasLeftMenuIcons && (!isUpFrontMenu || (isUpFrontMenu && isInMinifiedMenu))">
      <img *ngIf="tabIcon && !isSearchLink"
          [src]="tabIcon"
          [alt]="'component.vertical_categories_menu.nav_link.category_icon' | translate:{name: tabName}"
          aria-hidden="true"
          class='tab-icon small menu-item-image'
          [ngClass]="{'margin-right-10': isInMinifiedMenu}"/>
      <fa-icon *ngIf="isSearchLink" [icon]="faSearch"
               class="tab-icon small menu-item-image d-flex justify-content-center align-items-center fs-15"
               [ngClass]="{'margin-right-10': isInMinifiedMenu}" [fixedWidth]="true"></fa-icon>
    </ng-container>
    <fa-icon *ngIf="isTiledMenuIndexLink" [icon]="faChevronLeft" [fixedWidth]="true"
             class="tab-icon small menu-item-image d-flex justify-content-center align-items-center fs-15"></fa-icon>
    <span class='tab-name menu-item-name'
          [ngClass]="{
            'left-side-icon': hasLeftMenuIcons && showTabIcons && !isUpFrontMenu,
            'no-icon': !isSearchLink && hasLeftMenuIcons && showTabIcons && !tabIcon && !isUpFrontMenu,
            'text-center font-size-lg pt-3': isUpFrontMenu && !isInMinifiedMenu
          }">
      {{tabName}}
    </span>
    <ng-container *ngIf="!hasLeftMenuIcons && (!isUpFrontMenu || (isUpFrontMenu && isInMinifiedMenu))">
      <img *ngIf="tabIcon && !isSearchLink"
          [src]="tabIcon"
          [alt]="'component.vertical_categories_menu.nav_link.category_icon' | translate:{name: tabName}"
          aria-hidden="true"
          class='tab-icon small menu-item-image'
          [ngClass]="{'margin-left-10': isInMinifiedMenu}"/>
      <fa-icon *ngIf="isSearchLink" [icon]="faSearch"
               class="tab-icon small menu-item-image d-flex justify-content-center align-items-center fs-15"
               [ngClass]="{'margin-left-10': isInMinifiedMenu}" [fixedWidth]="true"></fa-icon>
    </ng-container>
  </div>
</ng-template>
