import { BaseResource } from '../core/base-resource';

export class OrderSubTax extends BaseResource {
  who: string;
  taxRate: string;
  subTotalName: string;
  tax: number;
  inclusive: boolean;
  taxable: number;
}
