import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreConfig, OrderTypeConfig } from 'ngx-web-api';

@Component({
  selector: 'fts-intro',
  templateUrl: 'intro.component.html',
  styleUrls: ['intro.component.scss'],
})
export class IntroComponent implements OnInit {
  store: StoreConfig;
  orderTypes: OrderTypeConfig[];

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      this.store = data['store'];
      this.orderTypes = data['orderTypes'];
    });
  }
}
