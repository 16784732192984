import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { GroupOrderMember } from './group-order-member.model';
import { GroupOrderStatus } from './group-order-status.enum';

export class GroupOrder extends BaseResource {
  readonly id: number;
  readonly startTime: string;
  readonly timeFrame: number;
  readonly expirationDate: string;

  readonly status: GroupOrderStatus;
  readonly groupMembers: GroupOrderMember[] = [];
  readonly note: string;
  readonly merged: boolean;
  readonly memberPayment: boolean;
  readonly asLeader: boolean;
  readonly memberEmail: string;
  readonly inviteeUrl: string;
  readonly isGroupMemberPayment: boolean;
  readonly leaderName: string;

  get isActive(): boolean {
    return !!this.status && this.status !== GroupOrderStatus.CANCELED && this.status !== GroupOrderStatus.DONE;
  }

  get started(): boolean {
    return this.status === GroupOrderStatus.STARTED;
  }

  get leader(): GroupOrderMember | undefined {
    return this.groupMembers.find(m => m.isLeader);
  }

  get me(): GroupOrderMember | undefined {
    return this.groupMembers.find(m => m.email === this.memberEmail);
  }

  get isInvitedMember(): boolean {
    if (this.me) {
      return this.isActive && !this.me.isLeader;
    } else {
      return false;
    }
  }

  get isLeader(): boolean {
    return this.isActive && this.asLeader;
  }

  get isInvitedMemberOfMemberPaid(): boolean {
    return this.isInvitedMember && this.memberPayment;
  }

  get isMergedLeaderOfMemberPaid(): boolean {
    return this.isLeader && this.merged && this.memberPayment;
  }

  get allowEditOrder(): boolean {
    if (!this.isActive) {
      return true;
    }

    if (this.me && !this.me.ready) {
      return true;
    }

    if (this.me && this.me.isLeader && this.merged) {
      return true;
    }

    return false;
  }

  get isInvitedMemberNotReady(): boolean {
    return this.isInvitedMember && !(this.me || { ready: false }).ready;
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      groupMembers: DeserializationUtils.deserializeChildren(input, 'groupMembers', GroupOrderMember),
    });
  }
}
