import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { Account } from '../../models/account/account.model';
import { LoyaltyHistoryRecord } from '../../models/account/loyalty-history-record.model';
import { LoyaltyMembership } from '../../models/account/loyalty-membership.model';
import { LoyaltyNotification } from '../../models/account/loyalty-notification.model';
import { LoyaltySignupData } from '../../models/account/loyalty-signup-data.model';
import { LoyaltyBadge } from '../../models/core/loyalty-badge.model';
import { AuditService } from '../core/audit.service';
import { HypermediaService } from '../core/hypermedia.service';
import { AccountService } from './account.service';
import { differenceInMilliseconds } from 'date-fns';
import { Utils } from '../../utils/utils';

@Injectable({ providedIn: 'root' })
export class AccountLoyaltyService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/account/loyalty';

  private loyaltyMembership: BehaviorSubject<LoyaltyMembership> = new BehaviorSubject<LoyaltyMembership>(null);
  public loyaltyMembership$: Observable<LoyaltyMembership> = this.loyaltyMembership.asObservable();

  constructor(
    private http: HttpClient,
    private hypermedia: HypermediaService,
    private auditService: AuditService,
    private accountService: AccountService
  ) {}

  public signupForLoyalty(data: LoyaltySignupData): Observable<Account> {
    return this.http.post(this.BASE_PATH, data.serialize()).pipe(
      tap(() => this.auditService.createAudit(() => ({ message: `User with cellphone ${data.cellphone} has signed up for loyalty` }))),
      mergeMap(() => this.accountService.fetchAccount())
    );
  }

  public getLoyaltyNotifications(email: string, token: string): Observable<LoyaltyNotification[]> {
    // We use encodeURIComponent here since we might have emails with characters that should be escaped and
    // angular escapes them (they have a bug here).
    const query = `Email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`;
    return this.http
      .get(`${this.BASE_PATH}/notifications?${query}`)
      .pipe(map((json: Object[]) => (json || []).map(v => new LoyaltyNotification().deserialize(v))));
  }

  public updateLoyaltyNotifications(membership: LoyaltyMembership, notifications: LoyaltyNotification[]) {
    const notificationsLink = this.hypermedia.findLink(membership.links, 'notifications');
    if (!notificationsLink) {
      const noLinkMessage = 'No notifications link has been found';
      this.auditService.createAudit(() => noLinkMessage);
      throw new Error(noLinkMessage);
    }
    return this.http
      .put(notificationsLink.href, notifications)
      .pipe(map((json: Object[]) => (json || []).map(v => new LoyaltyNotification().deserialize(v))));
  }

  public updateLoyaltyNotificationsViaToken(
    email: string,
    token: string,
    notifications: LoyaltyNotification[]
  ): Observable<LoyaltyNotification[]> {
    return this.http
      .post(`${this.BASE_PATH}/notifications`, {
        email,
        token,
        notifications: notifications.map(n => n.serialize()),
      })
      .pipe(
        map((json: Object) => json['notifications']),
        map((nots: Object[]) => (nots || []).map(n => new LoyaltyNotification().deserialize(n))),
        tap(() => this.auditService.createAudit(() => ({ message: 'Loyalty notifications have been updated', details: { notifications } })))
      );
  }

  public getLoyaltyMembership(): Observable<LoyaltyMembership> {
    return this.accountService.account$.pipe(
      filter((account: Account) => account.isInstantiated && account.signUpForLoyalty),
      distinctUntilChanged(),
      mergeMap((account: Account) => this.hypermedia.get(account.links, 'memberships')),
      map((json: Object) => new LoyaltyMembership().deserialize(json)),
      map(memberships => memberships[0]),
      tap((m: LoyaltyMembership) => this.loyaltyMembership.next(m))
    );
  }

  public getLoyaltyMembershipHistory(membership: LoyaltyMembership): Observable<LoyaltyHistoryRecord[]> {
    return this.hypermedia.get(membership.links, 'history').pipe(
      map((json: Object[]) => (json || []).map(r => new LoyaltyHistoryRecord().deserialize(r))),
      map((records: LoyaltyHistoryRecord[]) =>
        records.sort((a, b) => differenceInMilliseconds(Utils.parseIsoDate(b.date), Utils.parseIsoDate(a.date)))
      )
    );
  }

  public getLoyaltyMembershipBadges(membership: LoyaltyMembership): Observable<LoyaltyBadge[]> {
    return this.hypermedia
      .get(membership.links, 'badges')
      .pipe(map((json: Object[]) => (json || []).map(b => new LoyaltyBadge().deserialize(b))));
  }

  public getLoyaltyMembershipNotifications(membership: LoyaltyMembership): Observable<LoyaltyNotification[]> {
    return this.hypermedia
      .get(membership.links, 'notifications')
      .pipe(map((json: Object[]) => (json || []).map(n => new LoyaltyNotification().deserialize(n))));
  }

  public getThridPartyRewardBalance(): Observable<any> {
    return this.http.get(`${this.BASE_PATH}/balance`);
  }
}
