import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReloaderService } from '../../core/services/reloader.service';
import { ErrorCodes } from 'app/domain/error-codes.enum';
import { Router } from '@angular/router';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(private reloaderService: ReloaderService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.error && err.error.code === ErrorCodes.CSRF_CODE && this.router.url.indexOf('expiration') === -1) {
          if (!navigator.cookieEnabled) {
            alert('This website requires cookies in order to work. Please enable cookies on your browser and try again');
          } else {
            this.reloaderService.reload('/expiration');
          }
        }
        return throwError(err);
      })
    );
  }
}
