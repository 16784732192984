import { Injectable } from '@angular/core';
import { IngredientSelection } from 'app/domain/ingredient-selection';
import { OrderableIngredient, OrderableItem, OrderedIngredient, OrderedItem } from 'ngx-web-api';
import { HalfSelectionSideType } from 'ngx-web-api/lib/models/ordering/half-selection-side.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HalfsOrderingService {
  public isHalfAndHalf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isHalfAndHalf$: Observable<boolean> = this.isHalfAndHalf.asObservable();

  public halfAndHalfLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public halfAndHalfLabel$: Observable<string> = this.halfAndHalfLabel.asObservable();

  public activeHalfSide: BehaviorSubject<HalfSelectionSideType> = new BehaviorSubject<HalfSelectionSideType>(HalfSelectionSideType.LEFT);
  public activeHalfSide$: Observable<HalfSelectionSideType> = this.activeHalfSide.asObservable();

  public leftHalfOrderedItem: BehaviorSubject<OrderedItem> = new BehaviorSubject<OrderedItem>(null);
  public leftHalfOrderedItem$: Observable<OrderedItem> = this.leftHalfOrderedItem.asObservable();

  public leftHalfDefaults: BehaviorSubject<OrderedIngredient[]> = new BehaviorSubject<OrderedIngredient[]>([]);
  public rightHalfDefaults: BehaviorSubject<OrderedIngredient[]> = new BehaviorSubject<OrderedIngredient[]>([]);

  public rightHalfOrderedItem: BehaviorSubject<OrderedItem> = new BehaviorSubject<OrderedItem>(null);
  public rightHalfOrderedItem$: Observable<OrderedItem> = this.rightHalfOrderedItem.asObservable();

  public addOrRemoveIngredientToHalfOrderedItem(orderableIngredient: OrderableIngredient, choiceName: string) {
    if (!orderableIngredient.allowHalves) {
      return;
    }
    if (this.activeHalfSide.getValue() === HalfSelectionSideType.RIGHT) {
      const indexes = this.getIndexesIfExists(choiceName, this.rightHalfOrderedItem.getValue(), orderableIngredient);
      if (indexes) {
        if (indexes.ingredientIndex !== undefined) {
          const tempItem = this.rightHalfOrderedItem.getValue();
          tempItem.choices[indexes.choiceIndex].ingredients.splice(indexes.ingredientIndex, 1);
          this.rightHalfOrderedItem.next(tempItem);
        } else {
          const tempItem = this.rightHalfOrderedItem.getValue();
          tempItem.choices[indexes.choiceIndex].ingredients.push(
            OrderedIngredient.createFromOrderable(
              orderableIngredient,
              orderableIngredient.allowHalves && this.activeHalfSide.getValue() === HalfSelectionSideType.LEFT,
              orderableIngredient.allowHalves && this.activeHalfSide.getValue() === HalfSelectionSideType.RIGHT
            )
          );
          this.rightHalfOrderedItem.next(tempItem);
        }
      }
    } else {
      const indexes = this.getIndexesIfExists(choiceName, this.leftHalfOrderedItem.getValue(), orderableIngredient);
      if (indexes) {
        if (indexes.ingredientIndex !== undefined) {
          const tempItem = this.leftHalfOrderedItem.getValue();
          tempItem.choices[indexes.choiceIndex].ingredients.splice(indexes.ingredientIndex, 1);
          this.leftHalfOrderedItem.next(tempItem);
        } else {
          const tempItem = this.leftHalfOrderedItem.getValue();
          tempItem.choices[indexes.choiceIndex].ingredients.push(
            OrderedIngredient.createFromOrderable(
              orderableIngredient,
              orderableIngredient.allowHalves && this.activeHalfSide.getValue() === HalfSelectionSideType.LEFT,
              orderableIngredient.allowHalves && this.activeHalfSide.getValue() === HalfSelectionSideType.RIGHT
            )
          );
          this.leftHalfOrderedItem.next(tempItem);
        }
      }
    }
  }

  public getIndexesIfExists(
    choiceName: string,
    orderedItem: OrderedItem,
    orderableIngredient: OrderableIngredient
  ): { choiceIndex: number; ingredientIndex?: number } | null {
    const index = orderedItem?.choices?.findIndex(c => c.name === choiceName);

    if (index > -1) {
      const ingredientIndex = orderedItem?.choices[index].ingredients?.findIndex(i => i.ingredient === orderableIngredient?.name);
      if (ingredientIndex > -1) {
        return {
          choiceIndex: index,
          ingredientIndex: ingredientIndex,
        };
      } else {
        return { choiceIndex: index };
      }
    } else {
      return null;
    }
  }

  public updateIngredientQualifiers(selection: IngredientSelection) {
    if (this.activeHalfSide.getValue() === HalfSelectionSideType.RIGHT) {
      const tempItem = this.rightHalfOrderedItem.getValue();
      let tempIngredient: OrderedIngredient;
      const orderedChoice = tempItem?.choices.find(c => c.name === selection.choice.name);
      if (orderedChoice) {
        tempIngredient = orderedChoice.ingredients?.find(i => i.ingredient === selection.ingredient.name);
      }
      if (!tempIngredient) return;
      if (!tempIngredient['qualifiers']) {
        tempIngredient['qualifiers'] = [selection.qualifier.name];
      } else {
        const qualifierIndex = tempIngredient['qualifiers'].findIndex(qualifier => qualifier === selection.qualifier.name);
        if (qualifierIndex >= 0) {
          tempIngredient['qualifiers'].splice(qualifierIndex, 1);
        } else {
          const sameGroupQualifierIndex = tempIngredient['qualifiers']
            .map(qualifier => selection.ingredient.qualifiers.find(q => q.name === qualifier))
            .findIndex(q => !!q && q.type === selection.qualifier.type);

          if (sameGroupQualifierIndex >= 0) {
            tempIngredient['qualifiers'].splice(sameGroupQualifierIndex, 1);
          }
          tempIngredient['qualifiers'].push(selection.qualifier.name);
        }
      }
      this.rightHalfOrderedItem.next(tempItem);
    } else {
      const tempItem = this.leftHalfOrderedItem.getValue();
      let tempIngredient: OrderedIngredient;
      const orderedChoice = tempItem.choices.find(c => c.name === selection.choice.name);
      if (orderedChoice) {
        tempIngredient = (orderedChoice.ingredients || []).find(i => i.ingredient === selection.ingredient.name);
      }

      if (!tempIngredient) return;
      if (!tempIngredient['qualifiers']) {
        tempIngredient['qualifiers'] = [selection.qualifier.name];
      } else {
        const qualifierIndex = tempIngredient['qualifiers'].findIndex(qualifier => qualifier === selection.qualifier.name);
        if (qualifierIndex >= 0) {
          tempIngredient['qualifiers'].splice(qualifierIndex, 1);
        } else {
          const sameGroupQualifierIndex = tempIngredient['qualifiers']
            .map(qualifier => selection.ingredient.qualifiers.find(q => q.name === qualifier))
            .findIndex(q => !!q && q.type === selection.qualifier.type);

          if (sameGroupQualifierIndex >= 0) {
            tempIngredient['qualifiers'].splice(sameGroupQualifierIndex, 1);
          }
          tempIngredient['qualifiers'].push(selection.qualifier.name);
        }
      }
      this.leftHalfOrderedItem.next(tempItem);
    }
  }

  public calculateItemWithHalfs(
    orderedItem: OrderedItem,
    orderableItem: OrderableItem,
    leftOrderedItem: OrderedItem,
    rightOrderedItem: OrderedItem
  ) {
    const tempOrderedItem = orderedItem.copy();

    //filter our non halve ingredients
    tempOrderedItem.choices.forEach(choice => {
      choice.ingredients = choice.ingredients.filter(ingredient => {
        const orderableIngredient = orderableItem.choices
          .find(c => c.name === choice.name)
          .ingredients.find(i => i.name === ingredient.ingredient);
        if (orderableIngredient?.allowHalves) {
          return false;
        } else {
          return true;
        }
      });
    });

    //populate the base item based on the left ordered item choices
    leftOrderedItem?.choices.forEach(choice => {
      const index = tempOrderedItem.choices.findIndex(c => c.name === choice.name);
      if (index > -1) {
        choice.ingredients.forEach(ingredient => {
          const orderableIngredient = orderableItem.choices
            .find(c => c.name === choice.name)
            .ingredients.find(i => i.name === ingredient.ingredient);
          if (orderableIngredient?.allowHalves) {
            const ingIndex = tempOrderedItem.choices[index].ingredients.findIndex(i => i.ingredient === ingredient.ingredient);
            if (ingIndex > -1) {
              tempOrderedItem.choices[index].ingredients[ingIndex].isLeftHalf = true;
              tempOrderedItem.choices[index].ingredients[ingIndex].isRightHalf = false;
              tempOrderedItem.choices[index].ingredients[ingIndex].qualifiers = [...ingredient.qualifiers];
              tempOrderedItem.choices[index].ingredients[ingIndex].secondHalfQualifiers = [];
              tempOrderedItem.choices[index].ingredients[ingIndex].isSecondHalf = false;
            } else {
              const tempIngredient = ingredient.copy();
              tempIngredient.isLeftHalf = true;
              tempIngredient.isRightHalf = false;
              tempOrderedItem.choices[index].ingredients.push(tempIngredient);
            }
          }
        });
      }
    });

    //manipulate the base item based on the right ordered item choices in order
    //to calculate halfs choices and qualifiers
    rightOrderedItem?.choices.forEach(choice => {
      const index = tempOrderedItem.choices.findIndex(c => c.name === choice.name);
      if (index > -1) {
        choice.ingredients.forEach(ingredient => {
          const orderableIngredient = orderableItem.choices
            .find(c => c.name === choice.name)
            .ingredients.find(i => i.name === ingredient.ingredient);
          if (orderableIngredient?.allowHalves) {
            const ingIndex = tempOrderedItem.choices[index].ingredients.findIndex(i => i.ingredient === ingredient.ingredient);
            if (ingIndex > -1) {
              if (
                tempOrderedItem.choices[index].ingredients[ingIndex].qualifiers?.length &&
                tempOrderedItem.choices[index].ingredients[ingIndex].isLeftHalf
              ) {
                if (!ingredient.qualifiers?.length) {
                  tempOrderedItem.choices[index].ingredients[ingIndex].isLeftHalf = false;
                  tempOrderedItem.choices[index].ingredients[ingIndex].isRightHalf = true;
                  tempOrderedItem.choices[index].ingredients[ingIndex].secondHalfQualifiers = [
                    ...tempOrderedItem.choices[index].ingredients[ingIndex].qualifiers,
                  ];
                  tempOrderedItem.choices[index].ingredients[ingIndex].qualifiers = [];
                  tempOrderedItem.choices[index].ingredients[ingIndex].isSecondHalf = true;
                } else {
                  tempOrderedItem.choices[index].ingredients[ingIndex].isLeftHalf = true;
                  tempOrderedItem.choices[index].ingredients[ingIndex].isRightHalf = false;
                  tempOrderedItem.choices[index].ingredients[ingIndex].isSecondHalf = true;
                  tempOrderedItem.choices[index].ingredients[ingIndex].secondHalfQualifiers = [...ingredient.qualifiers];
                }
              } else {
                if (!ingredient.qualifiers?.length) {
                  tempOrderedItem.choices[index].ingredients[ingIndex].isLeftHalf = false;
                } else {
                  tempOrderedItem.choices[index].ingredients[ingIndex].isLeftHalf = true;
                  tempOrderedItem.choices[index].ingredients[ingIndex].isRightHalf = false;
                  tempOrderedItem.choices[index].ingredients[ingIndex].isSecondHalf = true;
                  tempOrderedItem.choices[index].ingredients[ingIndex].secondHalfQualifiers = [...ingredient.qualifiers];
                }
              }
            } else {
              const tempIngredient = ingredient.copy();
              tempIngredient.isLeftHalf = false;
              tempIngredient.isRightHalf = true;
              tempOrderedItem.choices[index].ingredients.push(tempIngredient);
            }
          }
        });
      }
    });

    Object.assign(orderedItem, tempOrderedItem);
  }

  public getValueForThemableOption(ingredientName: string) {
    if (!ingredientName) {
      return false;
    }
    if (this.activeHalfSide.getValue() === HalfSelectionSideType.RIGHT && this.rightHalfOrderedItem.getValue()) {
      this.rightHalfOrderedItem.getValue().choices.forEach(c => c.ingredients.findIndex(i => i.name === ingredientName));
      const item = this.rightHalfOrderedItem.getValue().choices.find(choice => {
        return choice.ingredients.some(ingredient => ingredient.ingredient === ingredientName);
      });

      return item != undefined;
    }
    if (this.activeHalfSide.getValue() === HalfSelectionSideType.LEFT && this.leftHalfOrderedItem.getValue()) {
      this.leftHalfOrderedItem.getValue().choices.forEach(c => c.ingredients.findIndex(i => i.name === ingredientName));
      const item = this.leftHalfOrderedItem.getValue().choices.find(choice => {
        return choice.ingredients.some(ingredient => ingredient.ingredient === ingredientName);
      });

      return item != undefined;
    }
    return false;
  }

  resetHalfs() {
    this.leftHalfOrderedItem.next(undefined);
    this.rightHalfOrderedItem.next(undefined);
    this.isHalfAndHalf.next(undefined);
    this.activeHalfSide.next(HalfSelectionSideType.LEFT);
  }

  setDefaultLeftHalfs() {
    const tempDefaults: OrderedIngredient[] = [];
    this.leftHalfOrderedItem.getValue().choices.forEach(choice => {
      choice.ingredients.forEach(ingredient => {
        if (ingredient.isDefault) {
          tempDefaults.push(ingredient);
        }
      });
    });
    this.leftHalfDefaults.next(tempDefaults);
  }

  setDefaultRightHalfs() {
    const tempDefaults: OrderedIngredient[] = [];
    this.rightHalfOrderedItem.getValue().choices.forEach(choice => {
      choice.ingredients.forEach(ingredient => {
        if (ingredient.isDefault) {
          tempDefaults.push(ingredient);
        }
      });
    });
    this.rightHalfDefaults.next(tempDefaults);
  }
}
