import { Component, OnInit } from '@angular/core';
import { AuditService, OrderableSpecial } from 'ngx-web-api';
import { UiOrchestratorService } from '../../core/services/ui-orchestrator.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecialInstructionsModalContext } from 'app/menu/special-instructions-modal-context';

@Component({
  selector: 'fts-special-instructions-modal',
  templateUrl: './special-instructions-modal.component.html',
  styleUrls: ['./special-instructions-modal.component.scss'],
})
export class SpecialInstructionsModalComponent implements OnInit {
  public context: SpecialInstructionsModalContext;
  special: OrderableSpecial;

  constructor(
    private auditService: AuditService,
    private uiOrchestratorService: UiOrchestratorService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.special = this.context.special;
    this.auditService.createAudit(() => `Special instructions modal has opened`);
    this.uiOrchestratorService.informModalOpened();
  }

  hideModal(): void {
    this.activeModal.close(null);
  }
}
