import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OrderTypeConfig } from 'ngx-web-api';

@Component({
  selector: 'fts-order-type-selector',
  templateUrl: 'order-type-selector.component.html',
  styleUrls: ['order-type-selector.component.scss'],
})
export class OrderTypeSelectorComponent {
  @Input()
  orderTypeConfigs: OrderTypeConfig[];
  @Input()
  selectedOrderTypeConfig: OrderTypeConfig;
  @Input()
  radioStyle: boolean;
  @Input()
  disabled: boolean;

  @Output()
  orderTypeConfigChange = new EventEmitter<OrderTypeConfig>();

  onOrderTypeConfigChange(orderTypeConfig: OrderTypeConfig) {
    this.orderTypeConfigChange.emit(orderTypeConfig);
  }
}
