import { Injectable } from '@angular/core';
import { Subject, Observable, asyncScheduler, of, fromEvent, combineLatest, merge, BehaviorSubject } from 'rxjs';
import { throttleTime, skip, delay, filter, take, takeUntil, share, switchMap, map, tap } from 'rxjs/operators';
import { ThemeService } from 'app/core/services/theme.service';
import { PathService } from './services/path.service';

@Injectable({
  providedIn: 'root',
})
export class ScrollEventService {
  private scroll$: Observable<any> = of(null);
  private stopScrollListener$: Subject<void> = new Subject();
  private skipOnHashNav = false;

  public unthrottledScroll$: Observable<void>;
  public throttledScroll$: Observable<void>;
  public topMenuThrottled$: Observable<void>;
  public resize$: Observable<void>;
  public throttledScrollAndResize$: Observable<void>;
  private hashNavigation: Subject<void> = new Subject();
  public hashNavigation$: Observable<any> = this.hashNavigation.asObservable();
  public blockingScrollEvent = new BehaviorSubject(false);

  constructor(private themeService: ThemeService, private pathService: PathService) {
    this.resize$ = fromEvent(window, 'resize').pipe(
      share(),
      throttleTime(100),
      map(() => null)
    );
    this.scroll$ = fromEvent(window, 'scroll').pipe(share(), takeUntil(this.stopScrollListener$));
    this.unthrottledScroll$ = this.scroll$.pipe(map(e => e));

    this.throttledScroll$ = this.scroll$.pipe(
      skip(1),
      filter(() => !this.skipOnHashNav),
      throttleTime(60, asyncScheduler, {
        leading: true,
        trailing: true,
      })
    );

    this.throttledScrollAndResize$ = merge(this.resize$, this.throttledScroll$);

    this.initTopMenuScrollListener();
  }

  public onHashNavigation() {
    this.skipOnHashNav = true;
    of(null)
      .pipe(
        delay(500),
        take(1),
        tap(() => this.hashNavigation.next())
      )
      .subscribe(() => (this.skipOnHashNav = false));
  }

  public stopScrollListener() {
    this.stopScrollListener$.next();
    this.stopScrollListener$.complete();
  }

  private initTopMenuScrollListener() {
    const hasTopMenu$ = this.themeService.theme.pipe(
      take(1),
      filter(theme => theme.hasTopMenu)
    );

    this.topMenuThrottled$ = combineLatest([this.pathService.inMenu$, hasTopMenu$]).pipe(
      filter(([inMenu, _hasTop]) => inMenu),
      switchMap(() => this.throttledScroll$)
    );
  }
}
