import { Component } from '@angular/core';
import { faFtsHamburger } from '../../../shared/custom-fa-icons/faFtsHamburger';

@Component({
  selector: 'fts-hamburger-button',
  templateUrl: './hamburger-button.component.html',
  styleUrls: ['./hamburger-button.component.scss'],
})
export class HamburgerButtonComponent {
  faFtsHamburger = faFtsHamburger;
}
