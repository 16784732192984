import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpenCloseTime } from 'ngx-web-api/lib/models/public_api';
import { BehaviorSubject, Observable, merge, Subject } from 'rxjs';
import { distinctUntilChanged, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { OrderType } from '../../models/core/order-type.enum';
import { StoreStatus } from '../../models/core/store-status.model';
import { Order } from '../../models/ordering/order.model';
import { OrderService } from '../ordering/order.service';

@Injectable({ providedIn: 'root' })
export class StoreStatusService {
  private storeStatus: BehaviorSubject<StoreStatus> = new BehaviorSubject(new StoreStatus());
  public storeStatus$: Observable<StoreStatus> = this.storeStatus.asObservable();

  private reloadStoreStatus: Subject<OrderType> = new Subject<OrderType>();

  public selectedOpenCloseTime: Subject<OpenCloseTime> = new Subject();
  public selectedOpenCloseTime$: Observable<OpenCloseTime> = this.selectedOpenCloseTime.asObservable();

  public loadingTimeDefferOptions: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient, private orderService: OrderService) {
    const orderType$ = this.orderService.order$.pipe(
      map((order: Order) => order.orderType),
      distinctUntilChanged()
    );

    merge(orderType$, this.reloadStoreStatus.asObservable())
      .pipe(mergeMap((orderType: OrderType) => this.fetchStoreStatus(orderType)))
      .subscribe(storeStatus => this.storeStatus.next(storeStatus));
  }

  public updateStoreStatus(orderType: OrderType) {
    this.reloadStoreStatus.next(orderType);
  }

  public getStoreStatus(orderType: OrderType): Observable<StoreStatus> {
    return this.fetchStoreStatus(orderType).pipe(tap(storeStatus => this.storeStatus.next(storeStatus)));
  }

  public fetchStoreStatus(orderType: OrderType): Observable<StoreStatus> {
    const params: HttpParams = new HttpParams().set('orderType', orderType);
    this.loadingTimeDefferOptions.next(true);
    return this.http.get('/ws/integrated/v1/ordering/store/time', { params }).pipe(
      map((json: Object) => new StoreStatus().deserialize(json)),
      finalize(() => {
        this.loadingTimeDefferOptions.next(false);
      })
    );
  }
}
