import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from './base-resource';
import { LoyaltyBadgeItem } from './loyalty-badge-item.model';

export class LoyaltyBadge extends BaseResource {
  readonly displayName?: string;
  readonly imageUrl?: string;
  readonly name?: string;
  readonly earned: boolean;
  readonly itemsTasted?: number;
  readonly totalItems?: number;
  readonly startDate?: string;
  readonly imageLastUpdated?: string;
  readonly endDate?: string;
  readonly dateEarned?: string;
  readonly items: LoyaltyBadgeItem[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      items: DeserializationUtils.deserializeChildren(input, 'itmes', LoyaltyBadgeItem),
    });
  }
}
