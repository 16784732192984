<div class="secondary-bg-color secondary-text-color">
  <nav [attr.aria-label]="'component.header.menu_links.label' | translate" id='menu-links' class="action-buttons-wrapper font-secondary fw-400" [ngClass]="{'bottom-border': !theme.compactHeader}">
    <div class="no-padding links-wrapper" [ngClass]="theme?.compactHeader ? 'container-fluid': 'container'" id="headerMenu">
      <div class="left-menu-side" [ngClass]="{'hidden-sm hidden-md hidden-lg': theme.compactHeader}">
        <fts-compact-header-overlay *ngIf="theme.compactHeader"></fts-compact-header-overlay>
        <button class="btn btn-link btn-header btn-undecorated text-uppercase font-secondary fw-600 no-right-padding d-flex"
                *ngIf="(viewPort$ | async)?.isMobile && !isInCheckout && orderTreeIsOpen && !(isInThanksPage$ | async)"
                (click)="toggleMobileMenu(menus.orderTree)">
          <fa-icon [fixedWidth]="true" [icon]="faChevronLeft"></fa-icon>
          <span>
            {{'component.header.menu_links.close_order_tree' | translate}}
          </span>
        </button>
        <fts-menu-link *ngIf="(viewPort$ | async)?.isMobile && (theme.skipIntroPage || !isInIntro) && showMenuBtn && visibleMenu !== menus.orderTree"
                        [panel]="panel"
                        [visibleMenu]="visibleMenu"
                        [showCategoriesUpfront]="theme.shouldShowCategoriesUpfront"
                        (onToggleMobileMenu)="toggleMobileMenu($event)"></fts-menu-link>
        <fts-track-order-btn *ngIf="!isSelfOrder && (viewPort$ | async)?.isMobile && visibleMenu !== menus.orderTree" [fontWeight]="true"></fts-track-order-btn>
      </div>

      <div class="right-menu-side">
        <ng-container *ngIf="!theme.compactHeader && !(breakPointService.isTabletDown$ | async)">
          <div class='header-account-menu-container'>
            <div [ngClass]="{'full-width flex-1 d-flex': !(breakPointService.isTabletDown$ | async)}">
              <fts-menu-link *ngIf="theme.skipIntroPage || !isInIntro"
                          [panel]="panel"
                          [visibleMenu]="visibleMenu"
                          [showCategoriesUpfront]="theme.shouldShowCategoriesUpfront"
                          (onToggleMobileMenu)="toggleMobileMenu($event)"></fts-menu-link>
              <fts-track-order-btn *ngIf="!isSelfOrder && (breakPointService.isMdViewport$ | async) && account.isInstantiated" [fontWeight]="true"></fts-track-order-btn>

              <ng-container *ngIf="(breakPointService.isMdViewport$ | async) && account?.isInstantiated && theme.hasTopOrderTree">
                <ng-container *ngFor="let featuredContent of headerFeaturedLinks">
                  <a *ngFor="let featuredItem of featuredContent.featuredItems"
                     [href]='featuredItem.link'
                     [target]="featuredItem.target"
                     class="btn btn-link btn-header hidden-xs text-uppercase account-link font-secondary featured-link"
                     [id]='"header-featured-link-" + (featuredItem.title | slugify)'>
                     {{featuredItem.title}}
                  </a>
                </ng-container>
              </ng-container>

              <fts-plain-account-menu
                *ngIf="(breakPointService.isDesktop$ | async) || ((breakPointService.isMdViewport$ | async) && !(theme.hasTopOrderTree && account.isInstantiated))"
                [account]='account'
                [compactHeader]="theme.compactHeader"
                [compactAccountMenu]="theme.hasCompactAccountMenu"
                [featuredContents]='headerFeaturedLinks'
                [store]='store'
                [isInIntro]="isInIntro"
                [loyaltyPlan]='loyaltyPlan'
                (login)='handleLogin()'
                (logout)='handleLogout()'
              ></fts-plain-account-menu>
            </div>


            <div class="bottom-container">
              <fts-collapsible-account-menu
                *ngIf='(!(breakPointService.isTabletDown$ | async) && account?.isInstantiated) || ((breakPointService.isMdViewport$ | async) && !isInIntro)'
                [ngClass]="{'hidden-md hidden-lg': !theme.hasCompactAccountMenu}"
                [store]='store'
                [account]='account'
                [hasTopOrderTree]="theme.hasTopOrderTree"
                [loyaltyPlan]="loyaltyPlan"
                (login)='handleLogin()'
                (logout)='handleLogout()'
              ></fts-collapsible-account-menu>

              <ng-container [ngTemplateOutlet]="!(breakPointService.isTabletDown$ | async) ? groupOrderButton : null"></ng-container>
            </div>
          </div>
          <div *ngIf="showTopOrderTree && !!order && !order.dummy && order.isInitialized && headerOrderTree"
               [ngClass]="{'visible-sm visible-md visible-lg align-self-end': !theme.compactHeader}" [@slideX]>
            <ng-template [ngComponentOutlet]="headerOrderTree | async"></ng-template>
          </div>
        </ng-container>

        <ng-container [ngTemplateOutlet]="(breakPointService.isTabletDown$ | async) ? groupOrderButton : null"></ng-container>

        <button class="btn btn-link btn-header top-right-badge concise visible-xs secondary-text-color font-secondary"
                [attr.data-count]="order?.itemsCount"
                *ngIf='!isInCheckout && !isInEditor && order?.isInitialized'
                (click)='toggleMobileMenu(menus.orderTree)'>
          <span class="sr-only" translate>component.header.menu_links.order_total</span>
          <span class="visible-xs mobile-price">
            {{order?.price | currency:'USD':'symbol'}}
          </span>
          <span *ngIf="order?.itemsCount" class="sr-only">
            {{(order?.itemsCount === 1 ? 'component.header.menu_links.one_item' : 'component.header.menu_links.multiple_items') | translate : {itemsCount: order?.itemsCount} }}
          </span>
          <fa-icon [fixedWidth]="true" [icon]="faShoppingCart"></fa-icon>
        </button>

        <button *ngIf='isInIntro && !account?.isInstantiated'
                class="btn btn-link btn-undecorated btn-header btn-menu-link text-uppercase visible-xs font-secondary fw-600"
                (click)='handleLogin()'>
          {{'component.header.menu_links.sign_in' | translate}}
        </button>

        <button *ngIf='isInIntro && !account?.isInstantiated && !(menuWrapperService.orderIdCookieExists$ | async)'
                [routerLink]="['/register']"
                class="btn btn-link btn-undecorated btn-header btn-menu-link text-uppercase visible-xs font-secondary fw-600">
          {{'component.header.menu_links.sign_up' | translate}}
        </button>

        <button class="btn btn-link btn-header visible-xs secondary-text-color font-secondary fix-sr-overflow"
                [attr.aria-label]="'component.header.menu_links.acc_drop_down' | translate"
                [attr.aria-expanded]="visibleMenu === menus.links"
                (click)='toggleMobileMenu(menus.links)'>
          <fa-icon [fixedWidth]="true" [icon]="visibleMenu === menus.links ? faTimes : faBars" [size]="'lg'"></fa-icon>
        </button>
      </div>
    </div>

    <div id="minifiedMenu" *ngIf="showMinifiedMenu">
      <fts-menu-index [panel]="panel" [isInHeader]="true"></fts-menu-index>
    </div>

    <div class="overlay" *ngIf="visibleMenu !== undefined && visibleMenu !== menus.orderTree && (viewPort$ | async)?.isMobile" [style.top.px]="mobileMenuTopPosition$ | async" (click)="handleOverlayClick()"></div>

    <!-- We need the undefined check for visibleMenu since it is a typescript enum which can be 0 but still we want to show the menu-->
    <div id='overlay-menu' role="region"
         *ngIf=" (theme.hasSinglePageMenu || shouldRenderOverlayMenu) && !(breakPointService.isDesktop$ | async)"
         class="overlay-menu primary-bg-color primary-text-color"
         [ngStyle]="{
           'height': (mobileMenuHeight$ | async) + 'px',
           'top': (mobileMenuTopPosition$ | async) + 'px'
         }"
         [ngClass]="{
           'full-width': theme.hasTopMenu || visibleMenu !== menus.tabs,
           'mobile-tabs': visibleMenu === menus.tabs,
           'mobile-links': visibleMenu === menus.links,
           'order-tree': visibleMenu === menus.orderTree,
           'd-none': visibleMenu === undefined || (theme.hasSinglePageMenu && !shouldRenderOverlayMenu && !theme.minifiedMenu)
         }">
      <div class="content"
           [style.height.px]="contentMobileMenuHeight$ | async">
        <fts-vertical-categories-menu
          *ngIf="(!theme.shouldShowCategoriesUpfront && theme.hasSinglePageMenu && !theme.minifiedMenu) || visibleMenu === menus.tabs"
          [ngClass]="{'d-none': theme.hasSinglePageMenu && !theme.minifiedMenu && visibleMenu !== menus.tabs}"
          [panel]='panel'
          [hasLeftMenuIcons]="theme.hasLeftMenuIcons"
          (onTabClick)="toggleMobileMenu($event)">
        </fts-vertical-categories-menu>

        <fts-vertical-menu-links
          *ngIf="visibleMenu === menus.links"
          [isInIntro]="isInIntro"
          [account]="account"
          [store]="store"
          [loyaltyPlan]="loyaltyPlan"
          [order]="order"
          [isInCheckoutDetails]="isInCheckoutDetails"
          [theme]="theme"
          [showChangeStoreBtn]="!isInCheckout && (store.hasStoreChooser || (order?.isInitialized && hasMultipleOrderTypeConfigs))"
          [isSelfOrder]="isSelfOrder"
          [featuredContents]='headerFeaturedLinks'
          (login)="handleLogin()"
          (logout)="handleLogout()"
          (changeStore)="toggleStoreChooser()">
        </fts-vertical-menu-links>
      </div>

      <div class="order-tree-container primary-bg-color" *ngIf="visibleMenu === menus.orderTree" [@expanded] (@expanded.done)="expandedAnimationEnd()">
        <div class="mobile-order-tree visible-xs">
          <ng-template [ngComponentOutlet]="mobileOrderMenu | async"></ng-template>
        </div>
      </div>
    </div>

    <ng-template *ngIf="!theme?.compactHeader && (viewPort$ | async)?.isMobile && !isInCheckoutDetails && orderNotification" [ngComponentOutlet]="orderNotification | async"></ng-template>
  </nav>
</div>

<ng-template #groupOrderButton>
  <fts-grouporder-button
    [label]="(!(breakPointService.isDesktop$ | async) ? 'component.header.minified_header.group_order' : 'component.header.menu_links.make_it_go') | translate"
    [buttonStyle]="'BUTTON'"
    class="margin-right-5 make-group-order-btn" 
    [ngClass]="{'margin-left-5': this.theme.orderTreePosition !== pagePosition.Top}">
  </fts-grouporder-button>
</ng-template>
