import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FeaturedItem } from 'ngx-web-api';

@Component({
  selector: 'fts-featured-content-scrolling',
  templateUrl: './featured-content-scrolling.component.html',
  styleUrls: ['./featured-content-scrolling.component.scss'],
})
export class FeaturedContentScrollingComponent implements OnInit, OnChanges {
  @Input()
  speed: number;
  @Input()
  fillParentHeight: boolean;
  @Input()
  featuredItems: FeaturedItem[];
  @Input()
  skipLazyLoading: boolean;
  @Input()
  itemImgHeight: number;

  @Output()
  chosenFeaturedItem: EventEmitter<FeaturedItem & { __slotNum?: string }> = new EventEmitter();
  @Output()
  loadImageHeight: EventEmitter<number> = new EventEmitter();

  private SPACE_KEY_CODE = 32;
  private ENTER_KEY_CODE = 13;
  private SPACE_KEY = ' ';
  private ENTER_KEY = 'Enter';
  private maxImageHeight = 0;
  visibleIndex = 0;
  intervalPointer: any;
  featuredItemsButtonLabels: string[];
  height: string;

  ngOnInit() {
    this.startAnimation();

    this.featuredItemsButtonLabels = this.featuredItems.map(featuredItem => {
      if (featuredItem.item) {
        return `Featured item ${(featuredItem.title || '') + (featuredItem.subtitle || '') || featuredItem.item.name}`;
      } else if (featuredItem.special) {
        return `Featured special ${(featuredItem.title || '') + (featuredItem.subtitle || '') || featuredItem.special.label}`;
      } else {
        return '';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fillParentHeight']) {
      this.calcHeight();
    }
  }

  handleKeyDown(event, featuredItem: FeaturedItem & { __slotNum?: string }, index: number) {
    if (this.isEnterKey(event) || this.isSpaceKey(event)) {
      event.preventDefault();
      event.stopPropagation();
      this.onFeaturedItemChosen(featuredItem, index);
    }
  }

  focusFeaturedItem(index: number) {
    this.stopAnimation();
    this.onVisibleIndexChange(index);
  }

  startAnimation() {
    this.stopAnimation();
    this.intervalPointer = setInterval(() => this.changeVisibleSlide(), this.speed * 1000);
  }

  stopAnimation() {
    clearInterval(this.intervalPointer);
  }

  onImageLoaded(imgHeight: number) {
    if (imgHeight > this.maxImageHeight) {
      this.maxImageHeight = imgHeight;
    }
    this.loadImageHeight.emit(imgHeight);

    this.calcHeight();
  }

  calcHeight() {
    if (this.fillParentHeight) {
      this.height = '100%';
    } else {
      this.height = `${this.maxImageHeight}px`;
    }
  }

  onFeaturedItemChosen(featuredItem: FeaturedItem & { __slotNum?: string }, index: number) {
    featuredItem.slotNum = `${index}`;
    this.chosenFeaturedItem.emit(featuredItem);
  }

  onVisibleIndexChange(index: number) {
    this.visibleIndex = index;
  }

  private changeVisibleSlide() {
    this.visibleIndex = (this.visibleIndex + 1) % this.featuredItems.length;
  }

  private isEnterKey(event) {
    return (
      (event.key !== undefined && event.key === this.ENTER_KEY) || (event.keyCode !== undefined && event.keyCode === this.ENTER_KEY_CODE)
    );
  }

  private isSpaceKey(event) {
    return (
      (event.key !== undefined && event.key === this.SPACE_KEY) || (event.keyCode !== undefined && event.keyCode === this.SPACE_KEY_CODE)
    );
  }
}
