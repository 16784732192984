import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { BootstrapBreakpoints } from '../../domain/bootstrap-breakpoints.enum';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  constructor(public breakpointObserver: BreakpointObserver) {}

  public isMobile$ = this.breakpointObserver.observe([BootstrapBreakpoints.SM]).pipe(
    map(state => state.matches),
    shareReplay(1)
  );

  public isDesktop$ = this.breakpointObserver.observe([BootstrapBreakpoints.LG_MIN]).pipe(
    map(state => state.matches),
    shareReplay(1)
  );

  public isXSMobile$ = this.breakpointObserver.observe([BootstrapBreakpoints.XS]).pipe(
    map(state => state.matches),
    shareReplay(1)
  );

  public isMdViewport$ = this.breakpointObserver.observe([BootstrapBreakpoints.MD]).pipe(
    map(state => state.matches),
    shareReplay(1)
  );

  public isTabletDown$ = this.breakpointObserver.observe([BootstrapBreakpoints.SM_MAX]).pipe(
    map(state => state.matches),
    shareReplay(1)
  );
}
