import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AuditService,
  ConfigService,
  AccountService,
  MerchantStore,
  OrderTypeConfig,
  StoreConfig,
  Account,
  StoreFinderMethod,
  Address,
  GenericObject,
  Order,
  OrderService,
  DeferChoice,
  Utils,
  OrderType,
  StoreStatusService,
  GroupOrderService,
  GroupOrder,
  OrderDeferOptions,
} from 'ngx-web-api';
import { ModalService } from '../../../services/modal.service';
import { Subscription, of, Observable, noop, fromEvent, EMPTY } from 'rxjs';
import { InitParamsStorageService } from '../../../../core/services/init-params-storage.service';
import { Animations } from '../../../../animations';
import { BreakpointService } from 'app/core/services/breakpoint.service';
import { UiOrchestratorService } from 'app/core/services/ui-orchestrator.service';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { FocusService } from 'app/focus.service';
import { ErrorsService } from 'app/core/services/errors.service';
import { TranslateService } from '@ngx-translate/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ReloaderService } from 'app/core/services/reloader.service';
import { Theme } from 'app/domain/theme.model';
import { faFtsCompass } from 'app/shared/custom-fa-icons/faFtsCompass';
import { DateFormatPipe } from 'ngx-web-api/lib/pipes/date-format.pipe';
import { Position } from '@maplibre/ngx-maplibre-gl';
import { CwoService } from 'app/core/services/cwo.service';

@Component({
  selector: 'fts-store-selection',
  templateUrl: './store-selection.component.html',
  styleUrls: ['./store-selection.component.scss'],
  animations: [Animations.topOverlay],
})
export class StoreSelectionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  storeStates: string[];
  @Input()
  orderTypeConfig: OrderTypeConfig;
  @Input()
  orderTypeConfigs: OrderTypeConfig[];
  @Input()
  storeConfig: StoreConfig;
  @Input()
  account: Account;
  @Input()
  show = false;
  @Input()
  order: Order;
  @Input()
  isInIntroOrHome: boolean;
  @Input()
  theme: Theme;
  @Input()
  isMobile: boolean;
  @Input()
  orderDeferOptions: OrderDeferOptions;
  @Input()
  orderDeferOptionsFetched: boolean;

  @Output()
  selectionDone = new EventEmitter<void>();

  loadingStores: Subscription;
  favoriteStoreSubscription: Subscription;
  changeStoreSubscription: Subscription;
  merchantStores: MerchantStore[];
  selectedStore: MerchantStore;
  selectedStoreState: string;
  selectedOrderTypeConfig: OrderTypeConfig;
  rememberMyChoice = true;
  shouldShowMakeFavoriteStore = false;
  hidden = true;
  storeFinderMethod = StoreFinderMethod;
  message: string;
  serverErrors: GenericObject<string[]>;
  faChevronLeft = faChevronLeft;
  containerHeight = 0;

  deferChoice: DeferChoice;
  OrderType = OrderType;
  selectedDeferTime: string;
  dateFormat = new DateFormatPipe();
  orderErrorMsg: string;
  groupOrder: GroupOrder;
  faFtsCompass = faFtsCompass;

  constructor(
    private configService: ConfigService,
    private modalService: ModalService,
    private accountService: AccountService,
    private auditService: AuditService,
    private initParamsStorageService: InitParamsStorageService,
    private uiOrchestrator: UiOrchestratorService,
    public breakpointService: BreakpointService,
    private focusService: FocusService,
    private errorService: ErrorsService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private orderService: OrderService,
    private reloaderService: ReloaderService,
    private storeStatusService: StoreStatusService,
    private groupOrderService: GroupOrderService,
    private cwoService: CwoService
  ) {}

  @HostListener('document:keydown', ['$event'])
  onKeyPress(event) {
    if ((this.show && event.key !== undefined && event.key === 'Esc') || event.key === 'Escape') {
      this.selectionDone.emit();
    }
  }

  ngOnInit() {
    fromEvent(window, 'popstate')
      .pipe(take(1))
      .subscribe(() => this.handleCancel());
    if (this.storeConfig.hasStoreChooser) {
      this.preSelectCurrentStore();
    }
    this.calculateContainerHeight();
    this.cdRef.detectChanges();
    this.groupOrderService.groupOrder$
      .pipe(
        mergeMap(groupOrder =>
          !!groupOrder ? of(groupOrder) : this.groupOrderService.fetchGroupOrder().pipe(map(groupOrder => groupOrder))
        ),
        take(1)
      )
      .subscribe((groupOrder: GroupOrder) => (this.groupOrder = groupOrder));

    const defaultOrderType = this.orderTypeConfigs?.find(c => c.type === (this.storeConfig.defaultOrderType as OrderType));
    if (!this.order.isInitialized && !!defaultOrderType) {
      this.handleOrderTypeConfigChange(defaultOrderType);
    } else if (this.orderTypeConfigs.length === 1) {
      this.handleOrderTypeConfigChange(this.orderTypeConfigs[0]);
    } else if (this.order.isInitialized) {
      this.handleOrderTypeConfigChange(this.orderTypeConfigs?.find(c => c.type === this.order.orderType));
    }
  }

  ngAfterViewInit() {
    this.calculateContainerHeight();
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['orderTypeConfig']) {
      this.selectedOrderTypeConfig = changes['orderTypeConfig'].currentValue;
    }
    if (this.show) {
      setTimeout(() => this.focusService.activateFocusTrap('storeSelectionModal', 'cancelButton'));
    }
    if (changes['show'] && !changes['show'].firstChange && this.show) {
      this.selectedStore = null;
      this.shouldShowMakeFavoriteStore = false;
      this.calculateContainerHeight();
      this.hidden = !this.show;
      this.cdRef.detectChanges();
    }

    if (!!changes['orderDeferOptions']?.currentValue) {
      this.deferChoice = this.orderDeferOptions?.selectedOption;
      this.selectedDeferTime = this.order.deferTime;
      this.cdRef.detectChanges();
    }
  }

  handleOrderTypeConfigChange(orderTypeConfig: OrderTypeConfig) {
    this.selectedOrderTypeConfig = orderTypeConfig;
    this.storeStatusService.updateStoreStatus(this.selectedOrderTypeConfig.type);
  }

  handleStoreStateChange(state: string) {
    this.selectedStoreState = state;
    this.selectedStore = null;

    this.loadingStores = (this.selectedStoreState ? this.configService.fetchMerchantStores(state) : of([])).subscribe(
      m => {
        this.merchantStores = m;
        if (this.merchantStores.length === 1) {
          this.handleStoreChange(this.merchantStores[0]);
        }
      },
      e => this.modalService.parseAndNotifyErrors(e)
    );
  }

  handleStoreChange(store: MerchantStore) {
    this.selectedStore = store;
    this.shouldShowMakeFavoriteStore = this.account && this.account.isInstantiated && this.account.favoriteStore !== store.primaryKey;
    if (window.innerWidth > 767) {
      document.querySelector(`#storeSelectionModal`).scrollIntoView();
    }
  }

  handleMyFavoriteStore() {
    if (this.selectedStore && this.account && this.account.isInstantiated) {
      this.favoriteStoreSubscription = this.setMyFavoriteStore(this.selectedStore.primaryKey).subscribe(
        account => {
          this.account = account;
          this.shouldShowMakeFavoriteStore = false;
        },
        err => this.modalService.parseAndNotifyErrors(err)
      );
    }
  }

  handleChangeStoreInfo(favoriteSaved?: boolean) {
    if (this.selectedStore && !this.selectedStore.isCurrentStore) {
      this.redirectToStore(favoriteSaved);
      return;
    }
    if (this.theme.hasSinglePageMenu && this.isMobile) {
      window.scrollTo(0, 0);
    }
    this.selectionDone.emit();
  }

  handleCancel() {
    this.focusService.deactivateFocusTrap();
    this.selectionDone.emit();
  }

  handleDone() {
    this.orderErrorMsg = '';
    if ((!this.selectedStore || this.selectedStore?.isCurrentStore) && this.deferChoice !== DeferChoice.Asap && !this.selectedDeferTime) {
      this.orderErrorMsg = this.translateService.instant('component.header.store_info.store_selection.defer_time_missing');
      return;
    }

    let orderObservable$ = of(null);

    if (
      this.selectedOrderTypeConfig &&
      (this.order.orderType !== this.selectedOrderTypeConfig?.type || this.order.deferTime !== this.selectedDeferTime)
    ) {
      const orderTime = this.selectedDeferTime
        ? ` at ${this.dateFormat.transform(
            this.selectedDeferTime,
            this.storeConfig.country === 'IE' ? 'yyyy-dd-MM HH:mm' : 'yyyy-MM-dd HH:mm'
          )}`
        : '';
      this.auditService.createAudit(() => `Setting order type to ${this.selectedOrderTypeConfig?.type}${orderTime}`);
      const orderCopy = this.order ? this.order.copy() : new Order();
      orderCopy.orderType = this.selectedOrderTypeConfig?.type;
      orderCopy.deferTime = this.selectedDeferTime;
      orderCopy.isMobileSource = this.initParamsStorageService.initParams.sourceIsMobile;
      orderObservable$ = this.order.isInitialized ? this.orderService.updateOrder(orderCopy) : this.orderService.createOrder(orderCopy);
    }

    this.changeStoreSubscription = orderObservable$
      .pipe(
        mergeMap(() => {
          if (
            this.selectedStore &&
            this.rememberMyChoice &&
            this.account &&
            this.account.isInstantiated &&
            this.selectedStore.primaryKey !== this.account.favoriteStore
          ) {
            return this.setMyFavoriteStore(this.selectedStore.primaryKey).pipe(
              tap(() => this.handleChangeStoreInfo(true)),
              catchError(err => (this.modalService.parseAndNotifyErrors(err), of(EMPTY)))
            );
          } else {
            return of(this.handleChangeStoreInfo());
          }
        })
      )
      .subscribe(() => this.focusService.deactivateFocusTrap(), noop);
  }

  animationStart() {
    this.uiOrchestrator.showMobileStoreChooserOverlay(this.show);
  }

  animationEnd() {
    this.hidden = !this.show;
    this.calculateContainerHeight();
  }

  updateStoreList(stores: MerchantStore[], withErrorMessage = true) {
    this.merchantStores = stores;
    this.selectedStore = null;
    this.auditService.createAudit(() => `${stores.length} stores found`);

    if (stores.length === 0 && withErrorMessage) {
      this.message = this.translateService.instant('component.header.store_info.store_selection.no_stores');
    }
  }

  onFindStore(address: string | Partial<Address>) {
    this.auditService.createAudit(() => `Searching store by address: ${JSON.stringify(address)}`);
    this.clearErrors();
    if (!!address) {
      this.loadingStores = this.configService.getAddressSupportingStores(address).subscribe(
        stores => this.updateStoreList(stores),
        error => this.parseErrors(error)
      );
    }
  }

  onAddressInput(input?: string) {
    this.clearErrors();
    if (!input) {
      this.preSelectCurrentStore();
    }
  }

  onUseLocation() {
    this.clearErrors();
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (location: Position) => {
          this.loadingStores = this.configService
            .getMapPointSupportingStores(location.coords.latitude, location.coords.longitude)
            .subscribe(
              stores => this.updateStoreList(stores),
              error => this.parseErrors(error)
            );
        },
        (error: any) => {
          this.parseErrors(
            null,
            (error && error.message) || this.translateService.instant('component.header.store_info.store_selection.no_location')
          );
          this.auditService.createAudit(() => `HTML Geolocation Service error : ${error && error.message}`);
        }
      );
    } else {
      this.parseErrors(null, this.translateService.instant('component.header.store_info.store_selection.no_location'));
      this.auditService.createAudit(() => `The HTML Geolocation Service is not supported`);
    }
  }

  clearErrors() {
    this.message = undefined;
    this.serverErrors = undefined;
  }

  parseErrors(errorResponse: any, errorMessage = '') {
    if (!Utils.isNullOrUndefined(errorResponse)) {
      const parsedError = this.errorService.parseErrorResponse(errorResponse);
      this.message = parsedError.message;
      this.serverErrors = parsedError.errors;
    } else if (errorMessage !== '') {
      this.message = errorMessage;
    }
    this.updateStoreList([], false);
  }

  chooseOrderTime(date: string | undefined) {
    this.selectedDeferTime = date;
  }

  chooseDeferChoice(deferChoice: DeferChoice | undefined) {
    this.deferChoice = deferChoice;
  }

  private calculateContainerHeight() {
    const elRef = document.querySelector(`#storeContainerRef`) as HTMLElement;
    this.containerHeight = !!elRef && elRef.offsetHeight > 1 ? elRef.offsetHeight - 1 : 500;
  }

  private redirectToStore(favoriteSaved?: boolean) {
    this.auditService.createAudit(
      () => `User clicked to change from "${this.storeConfig.name}" store, to "${this.selectedStore.name}" store`
    );

    if (this.rememberMyChoice && this.account && this.account.isInstantiated && !favoriteSaved) {
      this.changeStoreSubscription = this.setMyFavoriteStore(this.selectedStore.primaryKey).subscribe(
        () => (this.selectionDone.emit(), this.redirectToUrl()),
        err => this.modalService.parseAndNotifyErrors(err)
      );
      return;
    }
    this.changeStoreSubscription = null;
    this.redirectToUrl();
  }

  private redirectToUrl() {
    this.reloaderService.redirect(`/ws/integrated/v1/store/${encodeURIComponent(this.selectedStore.primaryKey)}/url`, {
      ...this.initParamsStorageService.redirectParams(),
      orderType: this.selectedOrderTypeConfig && this.selectedOrderTypeConfig.type,
      store: this.cwoService.storeName,
    });
  }

  private setMyFavoriteStore(favoriteStore: string): Observable<Account> {
    const accountCopy = new Account().deserialize({ ...this.account, favoriteStore });
    return this.accountService.updateFavoriteStore(accountCopy).pipe(mergeMap(() => this.accountService.fetchAccount(true)));
  }

  private preSelectCurrentStore() {
    this.selectedStoreState = this.storeConfig.state;
    this.loadingStores = this.configService.fetchMerchantStores().subscribe(
      m => {
        this.merchantStores = m;
        this.shouldShowMakeFavoriteStore =
          this.account && this.account.isInstantiated && this.selectedStore && this.account.favoriteStore !== this.selectedStore.primaryKey;
        this.selectedStore = this.merchantStores.find(s => s.isCurrentStore);
      },
      e => this.modalService.parseAndNotifyErrors(e)
    );
  }
}
