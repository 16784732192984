import { Component } from '@angular/core';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { UiOrchestratorService } from 'app/core/services/ui-orchestrator.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditService } from 'ngx-web-api';

@Component({
  selector: 'fts-confirmation-modal-inline',
  templateUrl: './confirmation-modal-inline.component.html',
  styleUrls: ['./confirmation-modal-inline.component.scss'],
})
export class ConfirmationModalInlineComponent extends ConfirmationModalComponent {
  constructor(
    uiOrchestratorService: UiOrchestratorService,
    translateService: TranslateService,
    activeModal: NgbActiveModal,
    auditService: AuditService
  ) {
    super(uiOrchestratorService, translateService, activeModal, auditService);
  }
}
