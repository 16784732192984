import { DeserializationUtils } from '../../utils/deserialization-utils';
import { GiftCard } from './gift-card.model';
import { ValidatedResource } from './validated-resource';

export class GiftCardWrapper extends ValidatedResource {
  meta: any;
  data: GiftCard[];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      data: DeserializationUtils.deserializeChildren(input, 'data', GiftCard),
    });
  }
}
