import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Account,
  AccountService,
  ChannelName,
  Constants,
  CookieService,
  CustomValidators,
  CustomerSubscription,
  FormValidationService,
  GenericObject,
  Utils,
} from 'ngx-web-api';
import { SmsModalContext } from './sms-modal.context';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ViewportService } from 'app/core/services/viewport.service';
import { Subscription } from 'rxjs';
import { SmsModalServie } from './sms-modal.service';
import { ModalService } from 'app/core/services/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorsService } from 'app/core/services/errors.service';

@Component({
  selector: 'fts-sms-modal',
  templateUrl: './sms-modal.component.html',
  styleUrls: ['./sms-modal.component.scss'],
})
export class SmsModalComponent implements OnInit, OnDestroy {
  private context: SmsModalContext;
  private subscriptions: Subscription[] = [];

  isUserInstantiated: boolean;
  colapsed = true;
  faXmark = faXmark;
  phoneMask = Constants.PHONE_MASK;
  account: Account = null;
  fontSize = 4;
  isMobile = false;
  loadingSub: Subscription;

  title = '';
  imgSrc = '';
  imageLoaded = false;
  error = '';

  form: FormGroup;
  private formErrors: GenericObject<string[]> = {};
  private serverErrors: GenericObject<string[]> = {};
  private showValidationErrors = false;

  private validationMessages: GenericObject<GenericObject<string>> = {
    name: {
      required: this.translateService.instant('component.sms_sub_promo_modal.name_required'),
    },
    phone: {
      required: this.translateService.instant('component.sms_sub_promo_modal.phone_required'),
      phone: this.translateService.instant('component.sms_sub_promo_modal.phone_invalid'),
    },
    email: {
      required: this.translateService.instant('component.sms_sub_promo_modal.email_required'),
      email: this.translateService.instant('component.sms_sub_promo_modal.email_invalid'),
    },
    password: {
      required: this.translateService.instant('component.sms_sub_promo_modal.password_required'),
    },
  };

  constructor(
    private accountService: AccountService,
    public translateService: TranslateService,
    private viewPortService: ViewportService,
    private cookieService: CookieService,
    private smsModalService: SmsModalServie,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private validationService: FormValidationService,
    private errorService: ErrorsService
  ) {
    this.subscriptions = [
      this.accountService.account$.subscribe(user => {
        this.isUserInstantiated = user.isInstantiated;
        this.account = user.copy();
      }),
      this.viewPortService.viewport$.subscribe(viewport => {
        this.isMobile = viewport.isMobile;
      }),
      this.smsModalService.fetchCoupon().subscribe(res => {
        this.title = res.coupon?.displayName;
        this.imgSrc = res.coupon?.imageUrl;
        if (!!this.imgSrc) {
          this.colapsed = window.innerWidth < 900;
        } else {
          this.colapsed = true;
        }
      }),
    ];
    this.buildForm();
  }

  private buildForm(): void {
    const controls = {
      name: ['', !this.isUserInstantiated ? [Validators.required] : []],
      password: ['', this.isUserInstantiated && !this.account.phone ? [Validators.required] : []],
      phone: [!!this.account.phone ? this.account.phone : '', !this.account.phone ? [Validators.required, CustomValidators.phone] : []],
      email: ['', !this.isUserInstantiated ? [Validators.required, CustomValidators.email] : []],
    };
    this.form = this.formBuilder.group(controls);
    this.form.valueChanges.subscribe(() => this.validateForm());
    this.validateForm();
  }

  imageLoadedEvent(): void {
    this.imageLoaded = true;
  }

  ngOnInit(): void {
    this.updateFontSize();
    this.cookieService.setSmsAcknowledgementCookie();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub?.unsubscribe();
    }
  }

  close(): void {
    if (!!this.context['ref']) {
      this.context['ref'].close();
    }
  }

  noThanksClicked(): void {
    this.close();
  }

  private validateForm() {
    this.serverErrors = undefined;
    this.formErrors = this.validationService.validateForm(this.form, this.validationMessages);
  }

  get errors(): GenericObject<string[]> {
    return this.showValidationErrors ? Utils.mergeGenericObjects(this.formErrors, this.serverErrors) : {};
  }

  submitClicked(): void {
    this.showValidationErrors = true;
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      return;
    }

    if (this.isUserInstantiated) {
      if (!this.account.phone) {
        this.updateAccountsPhone();
      } else {
        this.subscibeInstansiatedUser();
      }
    } else {
      this.subscibeGuestUser();
    }
  }

  private subscibeGuestUser(): void {
    const subs = this.smsModalService.addOffersService(this.smsModalService.addSmsChannel([]));
    let formatedPhone = this.dashedPhoneFormater(this.form.get('phone').value);

    this.loadingSub = this.accountService
      .updateGuestCustomerSubcriptions(subs, this.form.get('name').value, this.form.get('email').value, formatedPhone)
      .subscribe(
        res => this.handleSuccess(res),
        error => this.handleError(error)
      );
  }

  private updateAccountsPhone(): void {
    const updatedAccount = this.account
      .copy()
      .deserialize({ phone: this.form.get('phone').value, password: this.form.get('password').value });
    this.loadingSub = this.accountService.updateAccount(updatedAccount).subscribe(
      () => this.subscibeInstansiatedUser(),
      error => this.handleError(error)
    );
  }

  private subscibeInstansiatedUser(): void {
    let updatedSubscriptions = [];
    if (this.account.subscriptions.findIndex(sub => sub.channelName === ChannelName.SMS) === -1) {
      //Channel doesn't exist
      updatedSubscriptions = this.smsModalService.addOffersService(this.smsModalService.addSmsChannel([...this.account.subscriptions]));
    } else {
      updatedSubscriptions = this.smsModalService.addOffersService([...this.account.subscriptions]);
    }
    this.loadingSub = this.accountService.updateCustomerSubcriptions(updatedSubscriptions).subscribe((result: CustomerSubscription[]) => {
      this.handleSuccess(result);
    });
    this.subscriptions.push(this.loadingSub);
  }

  private handleSuccess(response: CustomerSubscription[]): void {
    if (typeof response === typeof [CustomerSubscription] && this.accountService.checkedIfSmsOffersChannelSubscribed(response)) {
      this.modalService
        .openSuccessNotificationModal(this.translateService.instant('component.sms_sub_promo_modal.thanks_for_sub'))
        .subscribe(res => {
          this.close();
        });
    } else {
      this.modalService
        .openErrorNotificationModal(
          this.translateService.instant('component.sms_sub_promo_modal.something_wrong'),
          this.translateService.instant('component.sms_sub_promo_modal.something_wrong_details')
        )
        .subscribe();
    }
  }

  private handleError(err: any) {
    if (err.status === 401 || err.status === 409) {
      if (err.error.message.includes('Password')) {
        this.serverErrors = { password: [err.error.message] };
      } else if (err.error.message.includes('phone')) {
        this.serverErrors = { phone: [err.error.message] };
      }
    } else {
      const parsedError = this.errorService.parseErrorResponse(err);
      if (!!parsedError?.errors['cellPhone']) {
        parsedError.errors['phone'] = parsedError.errors['cellPhone'];
      }
      this.serverErrors = parsedError?.errors;
    }
  }

  private updateFontSize(): void {
    if (this.title.length <= 8) {
      this.fontSize = this.isMobile ? 3 : 4;
    } else if (8 < this.title.length && this.title.length <= 16) {
      this.fontSize = this.isMobile ? 2 : 3;
    } else if (16 < this.title.length && this.title.length <= 40) {
      this.fontSize = this.isMobile ? 1.5 : !this.colapsed ? 1.8 : 2;
    } else {
      this.fontSize = this.isMobile ? 1 : 2;
    }
  }

  private dashedPhoneFormater(value: string): string {
    let formatedPhone = '';
    for (let c of value) {
      if (c != '(' && c != ')') {
        if (c == ' ') {
          formatedPhone += '-';
        } else {
          formatedPhone += c;
        }
      }
    }
    return formatedPhone;
  }
}
