import { animate, AnimationTriggerMetadata, state, style, transition, trigger, keyframes } from '@angular/animations';

export class Animations {
  public static isSafari(): boolean {
    return (
      navigator.userAgent.toLocaleLowerCase().indexOf('safari/') !== -1 &&
      navigator.userAgent.toLocaleLowerCase().indexOf('version/') !== -1 &&
      !(navigator.userAgent.toLocaleLowerCase().indexOf('opr/') !== -1 || navigator.userAgent.toLocaleLowerCase().indexOf('opt/') !== -1) &&
      !(navigator.userAgent.toLocaleLowerCase().indexOf('edgios/') !== -1 || navigator.userAgent.toLocaleLowerCase().indexOf('edg/') !== -1)
    );
  }

  public static expanded = trigger('expanded', [
    state(
      'true, :enter',
      style(
        Animations.isSafari()
          ? {
              overflow: 'hidden',
              opacity: 1,
            }
          : {
              height: '*',
              'padding-top': '*',
              'padding-bottom': '*',
              overflow: 'hidden',
              opacity: 1,
            }
      )
    ),
    state(
      'false, void, undefined, :leave',
      style(
        Animations.isSafari()
          ? {
              overflow: 'hidden',
              opacity: 0,
            }
          : {
              height: '0px',
              'padding-top': '0px',
              'padding-bottom': '0px',
              overflow: 'hidden',
              opacity: 0,
            }
      )
    ),
    transition('* <=> *', [animate('{{speed}}')], { params: { speed: '300ms' } }),
  ]);

  public static scaleX = trigger('scaleX', [
    state('void', style({ transform: 'scaleX(0)' })),
    state('true', style({ transform: 'scaleX(1)' })),
    transition('* => *', animate(100)),
  ]);

  public static slideX = trigger('slideX', [
    transition(':enter', [style({ width: '0', overflow: 'hidden' }), animate(500, style({ width: '*', overflow: '*' }))]),
    transition(':leave', [style({ width: '*', overflow: 'hidden' }), animate(500, style({ width: '0' }))]),
  ]);

  public static fadeInOut = trigger('fadeInOut', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [style({ opacity: 0 }), animate(300)]),
    transition(':leave', animate(300, style({ opacity: 0 }))),
  ]);

  public static topOverlay = trigger('show', [
    state(
      'true',
      style({
        height: '100vh',
        visibility: 'visible',
      })
    ),
    state(
      'false',
      style({
        height: '0px',
        visibility: 'hidden',
      })
    ),
    transition('false => true', animate('300ms ease-out')),
    transition('true => false', animate('300ms ease-in')),
  ]);

  public static fadeIn = trigger('fadeIn', [
    state('true', style({ visibility: 'visible', opacity: 1 })),
    state('false', style({ visibility: 'hidden', opacity: 0 })),
    transition('false => true', animate('200ms ease-in')),
    transition('true => false', animate('0ms')),
  ]);

  public static fadeInB = trigger('fadeInB', [transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))])]);

  public static fadeInOutIf = trigger('fadeInOutIf', [
    state('true', style({ opacity: 1 })),
    state('false', style({ opacity: 0 })),
    transition('false => true', animate('300ms ease-in')),
    transition('true => false', animate('300ms ease-out')),
  ]);

  public static expandNoHide = trigger('expandNoHide', [
    state('false, void, undefined', style({ height: '0px', opacity: 0, width: '0px' })),
    state('true', style({ height: '{{height}}px', opacity: 1, width: 'unset' }), { params: { height: '*' } }),
    transition(
      '* => true',
      animate('250ms', keyframes([style({ width: 'unset', offset: 0 }), style({ height: '{{height}}px', opacity: 1, offset: 1 })]))
    ),
    transition('* => false', animate('250ms', keyframes([style({ height: '0px', opacity: 0, width: '0px', offset: 1 })]))),
  ]);
}

export function rotate(angle, time = '300ms'): AnimationTriggerMetadata {
  return trigger('rotate', [
    state('true', style({ transform: '*' })),
    state('false', style({ transform: `rotate(${angle})` })),
    transition('false <=> true', animate(`${time}`)),
  ]);
}

export function collapse(time = '300ms'): AnimationTriggerMetadata {
  return trigger('collapse', [
    state(
      'true',
      style(
        Animations.isSafari()
          ? {
              opacity: 0,
              display: 'none',
            }
          : { height: '0px', overflow: 'hidden', display: 'hidden' }
      )
    ),
    state(
      'false',
      style(
        Animations.isSafari()
          ? {
              opacity: 1,
              display: 'visible',
            }
          : { height: '*', overflow: 'visible', display: '*' }
      )
    ),
    transition('false <=> true', animate(`${time}`)),
  ]);
}

export function flipX(time = '300ms'): AnimationTriggerMetadata {
  return trigger('flipX', [
    state('true', style({ transform: '*' })),
    state('false', style({ transform: `rotateX(180deg)` })),
    transition('false <=> true', animate(`${time}`)),
  ]);
}
