import { Component, Input } from '@angular/core';

@Component({
  selector: 'fts-ghost-element',
  templateUrl: './ghost-element.component.html',
  styleUrls: ['./ghost-element.component.scss'],
})
export class GhostElementComponent {
  @Input()
  height: number;

  @Input()
  width: number;
}
