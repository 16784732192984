import { ValidatedResource } from './validated-resource';

export class Address extends ValidatedResource {
  name: string;
  street: string;
  streetNum: number;
  zip: string;
  deliveryInstructions: string;
  addressType?: string;
  force: boolean;
  city: string;
  state: string;
  buildingName: string;
  diff1: string;
  diff2: string;
  diff3: string;
  value1?: string;
  value2?: string;
  value3?: string;
  lat?: number;
  lng?: number;
  isSaved: boolean;

  constructor() {
    super();
  }

  isAddressValid(): boolean {
    return !!this.street && !!this.zip;
  }

  get formattedStreet() {
    return (this.street || '')
      .toLowerCase()
      .split(' ')
      .map(c => c.charAt(0).toUpperCase() + c.slice(1))
      .join(' ');
  }

  get fullAddress(): string {
    const address = [this.formattedStreet, this.city, this.state].filter(v => !!v).join(', ');
    return [address, this.zip].filter(v => !!v).join(' ');
  }

  get intlFormattedStreet() {
    return `${(this.street || '')
      .toLowerCase()
      .split(' ')
      .map(c => c.charAt(0).toUpperCase() + c.slice(1))
      .join(' ')} ${this.streetNum}`;
  }

  get intlFullAddress(): string {
    const address = [this.intlFormattedStreet, this.city, this.state].filter(v => !!v).join(', ');
    return [address, this.zip].filter(v => !!v).join(' ');
  }

  get formattedDiffs(): string {
    const diffs: string[] = [];
    if (this.value1 && this.diff1) {
      diffs.push(`${this.diff1} ${this.value1}`);
    }
    if (this.value2 && this.diff2) {
      diffs.push(`${this.diff2} ${this.value2}`);
    }
    if (this.value3 && this.diff3) {
      diffs.push(`${this.diff3} ${this.value3}`);
    }

    return diffs.join(', ');
  }

  getDiffName(idx: number): string {
    return this['diff' + idx];
  }

  getDiffValue(idx: number): string {
    const diffVal = this['value' + idx];
    if (diffVal) {
      return diffVal.trim();
    }
    return diffVal;
  }
}
