import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConfigService,
  HypermediaService,
  IngredientChoice,
  IngredientChoiceGroup,
  IngredientChoicesType,
  Link,
  MenuItemsLayout,
  OrderableItem,
  OrderedItem,
  OrderedItemService,
  SizePrice,
  StoreConfig,
  UpsellType,
  Utils,
  OrderableIngredient,
  OrderableQualifier,
} from 'ngx-web-api';
import { BehaviorSubject, EMPTY, merge, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EditingItemContext } from '../../domain/editing-item-context';

import { ThemeService } from './theme.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ItemEditorService {
  private editingItem: BehaviorSubject<EditingItemContext | null> = new BehaviorSubject(null);
  public editingItem$: Observable<EditingItemContext> = this.editingItem.asObservable();

  private sizeChanged: Subject<string> = new Subject();
  public sizeChanged$: Observable<string> = this.sizeChanged.asObservable();

  private recipeChanged: Subject<string> = new Subject();
  public recipeChanged$: Observable<string> = this.recipeChanged.asObservable();

  private ingredientChanged: Subject<void> = new Subject();
  public ingredientChanged$: Observable<void> = this.ingredientChanged.asObservable();

  private editingItemDefaultSelectedSize$: Observable<string> = this.editingItem$.pipe(
    filter(itemContext => !!itemContext),
    map((itemContext: EditingItemContext) => itemContext.orderedItem),
    map((item: OrderedItem) => (item ? item.size : undefined))
  );

  public editingOrderableItem$: Observable<OrderableItem> = this.editingItem$.pipe(
    filter(itemContext => !!itemContext),
    map((itemContext: EditingItemContext) => itemContext.orderableItem)
  );

  public editingItemSelectedSizePrice$: Observable<SizePrice> = this.editingOrderableItem$.pipe(
    map((item: OrderableItem) => (item ? item.sizePrices || [] : [])),
    withLatestFrom(merge(this.editingItemDefaultSelectedSize$, this.sizeChanged$)),
    map(([sizePrices, size]: [SizePrice[], string]) => sizePrices.find(s => s.size === size)),
    filter(size => !!size)
  );

  public editingItemShouldShowFullCalorieInfo$: Observable<boolean> = this.editingItem$.pipe(
    filter(itemContext => !!itemContext),
    map((itemContext: EditingItemContext) => itemContext.orderableItem),
    map((item: OrderableItem) => !!item && item.showFullSizeCalories)
  );

  private ingredientChoicesType: IngredientChoicesType;

  private orderableItemLink$: Observable<string> = this.editingOrderableItem$.pipe(
    filter(item => !!item),
    map((item: OrderableItem) => this.hypermedia.findLink(item.links, 'self')),
    filter(link => !!link),
    map((link: Link) => link.href)
  );

  constructor(
    private themeService: ThemeService,
    private orderedItemService: OrderedItemService,
    private router: Router,
    private http: HttpClient,
    private configService: ConfigService,
    private hypermedia: HypermediaService
  ) {
    this.themeService.theme.subscribe(theme => (this.ingredientChoicesType = theme.ingredientChoicesType));
    this.subscribeToRecipeChanges();
  }

  public editOrderableItem(
    orderableItem: OrderableItem,
    size: string,
    _orderedItem?: OrderedItem,
    menuItemsLayout?: MenuItemsLayout,
    quantity = 1,
    featuredAreaName: string = '',
    featuredContentName: string = '',
    selectedUpfrontIngredient?: OrderableIngredient,
    unsatisfiedIngredientChoiceName?: string,
    selectedUpfrontQualifier?: string[]
  ): void {
    const orderedItem: OrderedItem = _orderedItem ? _orderedItem : this.orderedItemService.createOrderedItem(orderableItem, size, quantity);
    if (!!featuredContentName && !!featuredAreaName) {
      orderedItem.isUpsell = true;
      orderedItem.upsellType = UpsellType.FEATURED_AREA;
      orderedItem.featuredAreaName = featuredAreaName;
      orderedItem.featuredContentName = featuredContentName;
    }
    this.editingItem.next({
      orderableItem,
      orderedItem,
      menuItemsLayout,
      selectedUpfrontIngredient,
      unsatisfiedIngredientChoiceName,
      selectedUpfrontQualifier,
    });
  }

  public editOrderedItem(item: OrderedItem): void {
    this.editingItem.next({ orderedItem: item });
  }

  public updateEditingItem(orderable: OrderableItem, ordered?: OrderedItem): void {
    const values: EditingItemContext = this.editingItem.getValue() || {};
    values.orderableItem = orderable;
    if (!Utils.isNullOrUndefined(ordered)) {
      values.orderedItem = ordered;
    }
    this.editingItem.next(values);
  }

  public setSizeChanged(size: string) {
    this.sizeChanged.next(size);
  }

  public itemIngredientsChanged(): void {
    this.ingredientChanged.next();
  }

  public navigateBackToMenu(routerLink: string[], fragment?: string) {
    return this.router.navigate(routerLink, { fragment: fragment });
  }

  public setRecipe(recipe: string) {
    this.recipeChanged.next(recipe);
  }

  public getOrderableItemFromUrl(url: string): Observable<OrderableItem> {
    return this.http.get(url).pipe(map((json: Object): OrderableItem => new OrderableItem().deserialize(json)));
  }
  /**
   * @returns {IngredientChoicesType} the choices layout that item editor should use
   */
  public getChoicesLayout(orderableItem: OrderableItem): IngredientChoicesType {
    return orderableItem.ingredientChoicesType || this.ingredientChoicesType;
  }

  /**
   * @returns {number} the index of the choice in the filtered choices of the orderable item
   */
  public getFilteredChoiceIndex(orderableItem: OrderableItem, orderedItem: OrderedItem, choice?: IngredientChoice): number {
    if (!choice) {
      return -1;
    }
    return this.orderedItemService.getFilteredIngredientChoices(orderableItem, orderedItem).findIndex(c => c.name === choice.name);
  }

  /**
   * @returns {IngredientChoiceGroup[]} the @param choiceGroup fitlered to contain
   * only those having at least one choice with it's dependcy met
   */
  public filterChoiceGroupsByDependency(
    choiceGroups: IngredientChoiceGroup[],
    choices: IngredientChoice[],
    orderedItem: OrderedItem
  ): IngredientChoiceGroup[] {
    return choiceGroups.filter(g =>
      g.choices.some(choice => {
        const ingredientChoice = choices.find(ch => choice === ch.name);
        return this.orderedItemService.hasDependencyMet(orderedItem, ingredientChoice);
      })
    );
  }

  public hasSideQualsOnly(qualifiers: OrderableQualifier[]): boolean {
    const availableTypes = Array.isArray(qualifiers) && Utils.distinct(qualifiers.map(q => q.type));
    return !!availableTypes && availableTypes.length === 1 && availableTypes[0] === 'Side';
  }

  private subscribeToRecipeChanges() {
    this.configService.storeConfig$
      .pipe(
        map((config: StoreConfig): boolean => config.showCalories),
        filter(showCalories => !!showCalories),
        mergeMap(() => this.recipeChanged.asObservable()),
        withLatestFrom(this.orderableItemLink$.pipe(distinctUntilChanged())),
        filter(([recipe, link]: [string, string]) => !Utils.isNullOrUndefined(link) && !Utils.isNullOrUndefined(recipe)),
        map(([recipe, link]: [string, string]) => Utils.replaceQueryParam(link, 'recipe', encodeURIComponent(recipe))),
        distinctUntilChanged(),
        switchMap((url: string) => this.getOrderableItemFromUrl(url)),
        catchError(() => EMPTY)
      )
      .subscribe((orderableItem: OrderableItem) => this.updateEditingItem(orderableItem));
  }
}
