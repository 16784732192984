import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FeaturedItem, Picture } from 'ngx-web-api';
import { MenuWrapperService } from 'app/core/services/menu-wrapper.service';
import { ViewportService } from 'app/core/services/viewport.service';
import { ViewPort } from 'app/domain/view-port.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'fts-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
  animations: [
    /* true <=> false does not work properly so using 1 <=> 0 */
    trigger('slideVisible', [
      state('1', style({ transform: 'translateX(0%)' })),
      state('0', style({ transform: 'translateX(100%)' })),
      transition('1 => 0', animate('1s', style({ transform: 'translateX(-100%)' }))),
      transition('0 => 1', animate('1s')),
    ]),
    trigger('slideVisibleFillParentHeight', [
      state('1', style({ transform: 'translate(-50%, -50%)' })),
      state('0', style({ transform: 'translate(100%, -50%)' })),
      transition('1 => 0', animate('1s', style({ transform: 'translate(-150%, -50%)' }))),
      transition('0 => 1', animate('1s', style({ transform: 'translate(-50%, -50%)' }))),
    ]),
  ],
})
export class SlideComponent implements OnChanges {
  @Input()
  fillParentHeight: boolean;
  @Input()
  featuredItem: FeaturedItem;
  @Input()
  slideVisible = false;
  @Input()
  displayTabIndex = false;
  @Input()
  slideVisibleFillParentHeight = false;
  @Input()
  first: boolean;
  @Input()
  skipLazyLoading: boolean;
  @Input()
  itemImgHeight: number;

  @Output()
  heightChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('imgEl', { static: false })
  private imageElement: ElementRef;
  viewport$: Observable<ViewPort>;
  featuredItemPicture: Picture;

  constructor(private menuWrapperService: MenuWrapperService, private viewportService: ViewportService) {
    this.viewport$ = this.viewportService.viewport$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['featuredItem'] && !!this.featuredItem) {
      this.featuredItemPicture = this.menuWrapperService.getPicture(this.featuredItem.pictures);
    }
  }

  imgLoaded() {
    this.heightChange.emit(this.imageElement.nativeElement.clientHeight);
  }
}
