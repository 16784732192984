import { BasicModalContext } from './basic-modal-context';
import { AddressValidationResult } from 'ngx-web-api';

export class AddressConfirmationModalContext extends BasicModalContext {
  constructor(public addressOutOfMapValidation: AddressValidationResult, public redirectParams?: object) {
    super();
    this.class = 'sm';
    this.config = { addressOutOfMapValidation, redirectParams };
  }
}
