export const cardTypes = [
  {
    type: 'Visa',
    ranges: [{ min: 400000, max: 499999, length: [13, 16] }],
  },
  {
    type: 'MasterCard',
    ranges: [
      { min: 510000, max: 559999, length: [16] },
      { min: 222100, max: 272099, length: [16] },
    ],
  },
  {
    type: 'Amex',
    ranges: [
      { min: 340000, max: 349999, length: [15] },
      { min: 370000, max: 379999, length: [15] },
    ],
  },
  {
    type: 'Diners',
    ranges: [
      { min: 300000, max: 305999, length: [14, 16] },
      { min: 380000, max: 399999, length: [14, 16] },
      { min: 360000, max: 369999, length: [14] },
    ],
  },
  {
    type: 'Discover',
    ranges: [
      { min: 601100, max: 601199, length: [16] },
      { min: 622126, max: 622925, length: [16] },
      { min: 640000, max: 659999, length: [16] },
    ],
  },
  {
    type: 'JCB',
    ranges: [
      { min: 352800, max: 358999, length: [16] },
      { min: 308800, max: 309499, length: [16] },
      { min: 309600, max: 310299, length: [16] },
      { min: 311200, max: 312099, length: [16] },
      { min: 315800, max: 315999, length: [16] },
      { min: 333700, max: 334999, length: [16] },
    ],
  },
  {
    type: 'UnionPay',
    ranges: [
      { min: 810000, max: 817199, length: [16] },
      { min: 621094, max: 621094, length: [16] },
      { min: 624000, max: 626999, length: [16] },
      { min: 628200, max: 628899, length: [16] },
    ],
  },
];
