import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ConfigService, OrderTypeConfig } from 'ngx-web-api';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class OrderTypesResolver implements Resolve<OrderTypeConfig[]> {
  constructor(private configService: ConfigService) {}

  resolve(): Observable<OrderTypeConfig[]> {
    return this.configService.orderTypesConfig$.pipe(take(1));
  }
}
