import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { OrderableIngredientGroup } from './orderable-ingredient-group.model';
import { OrderableIngredient } from './orderable-ingredient.model';

export class IngredientChoice extends BaseResource {
  readonly name: string;
  readonly ordinal: number;
  readonly maxIngredients: number;
  readonly requiredIngredients: number;
  readonly enforcedIngredients: number;
  readonly dependsOn?: string;
  readonly defaultsNonRemovable: boolean;
  readonly noReplacedDefaults: boolean;
  readonly primaryItems: number;
  readonly showIngredientPrices: boolean;
  readonly showIngredientImages: boolean;
  readonly isInstructions: boolean;
  readonly ingredients: OrderableIngredient[];
  readonly groups: OrderableIngredientGroup[];
  readonly maxSubstitutions: number;
  readonly strictSubstitutions: boolean;
  readonly recommendedIngredients: number;
  readonly allowSecondHalves: boolean;
  readonly isOpenOnWeb: boolean;

  get hasHalves(): boolean {
    return this.ingredients.some(i => i.allowHalves);
  }

  get needsSingleIngredientSelection(): boolean {
    return this.maxIngredients === 1 && this.enforcedIngredients === 1;
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      ingredients: DeserializationUtils.deserializeChildren(input, 'ingredients', OrderableIngredient),
      groups: DeserializationUtils.deserializeChildren(input, 'groups', OrderableIngredientGroup),
    });
  }
}
