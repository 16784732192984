<div class="store-info-container justify-content-center d-flex" *ngIf="!minifiedHeader">
  <span class='hidden-xs'>
    <span class="text-uppercase fw-600" class="compact-store-info" [ngClass]="{'change-btn-visible': (storeConfig?.hasStoreChooser || hasMultipleOrderTypeConfigs) && !isStoreChooserOpened}">
      <ng-container *ngIf="(!compact && (viewportService?.viewport$ | async)?.isTablet) || (viewportService?.viewport$ | async)?.isDesktop">
        <span *ngIf="hasOrderType && !isStoreChooserOpened  && !(isInTrackerPage$ | async)">{{orderTypeConfig.displayNameOrName}} <span class="from-container text-uppercase">{{'component.header.store_info.from' | translate}}&nbsp;</span></span>
        <span *ngIf="!isStoreChooserOpened"> {{storeConfig.address}}</span>
        <span *ngIf="hasOrderType && !isStoreChooserOpened && !(isInTrackerPage$ | async)">
          &nbsp;{{((orderService.order$ | async)?.deferTime ? 'component.header.store_info.at' : 'component.header.store_info.asap') | translate}} {{(orderService.order$ | async)?.deferTime | todayFormat : true : isEuCountry}}
        </span>
      </ng-container>

      <button
        *ngIf="!(isSelfOrder$ | async) && !(isInThanksPage$ | async) && (storeConfig?.hasStoreChooser || ((orderService.order$ | async)?.isInitialized && hasMultipleOrderTypeConfigs)) && showChangeOrderTypeBtn && compact && (viewportService?.viewport$ | async)?.isTablet"
        class="btn btn-link btn-header font-secondary"
        ftsAsyncButton ftsAuditableButton auditName="Change Store/Order type"
        [subscription]="loadingStates"
        [attr.aria-label]="'component.header.menu_links.store_icon.store_info' | translate"
        (click)="toggleMobileMenu(menus.storeChooser)">
        <fa-icon [icon]="faStore" class="secondary-text-color"></fa-icon>
      </button>

      <fts-store-phone *ngIf="storeConfig?.phone" class="store-phone" [ngClass]="isStoreChooserOpened ? 'store-chooser-open' : ''" [phone]="storeConfig?.phone"></fts-store-phone>
    </span>
  </span>
  <span class='visible-xs'>
    <strong>{{storeConfig?.name}}</strong>
  </span>

  <button class="btn btn-link text-uppercase secondary-text-color change-store-btn hidden-xs"
          [ngClass]="{'hidden-sm': compact}"
          ftsAsyncButton
          [subscription]="loadingStates"
          (click)="handleChangeClick()"
          *ngIf="!(isSelfOrder$ | async) && !(isInThanksPage$ | async) && (storeConfig?.hasStoreChooser || ((orderService.order$ | async)?.isInitialized && hasMultipleOrderTypeConfigs) ) && !isStoreChooserOpened && showChangeOrderTypeBtn"
          ftsAuditableButton
          auditName="Change Store/Order type">
    {{'component.header.store_info.change' | translate}}
  </button>

  <button *ngIf="!(isSelfOrder$ | async) && !(isInThanksPage$ | async) && shouldShowFavoriteStoreBtn && !isStoreChooserOpened" ftsAsyncButton [subscription]="favoriteStoreSubscription" (click)="handleMyFavoriteStore()"
          class="btn btn-link text-uppercase secondary-text-color make-favorite-store-btn">
    {{'component.header.store_info.make_my_location' | translate}}
  </button>
</div>

<div class="text-uppercase fw-600" *ngIf="minifiedHeader">
  <span *ngIf="hasOrderType && !(isInTrackerPage$ | async)">{{orderTypeConfig.displayNameOrName}}&nbsp;</span>
  <span>{{storeConfig.address}}</span>
</div>
