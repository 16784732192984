import { Injectable, Inject } from '@angular/core';
import {
  DeserializationUtils,
  Group,
  HypermediaService,
  ImageAlternativeType,
  MenuService,
  OrderableItemService,
  OrderType,
  Panel,
  PanelItem,
  Picture,
  RecentlyOrderedItem,
  RecentlyOrderedItemsService,
  SizePrice,
  StoreStatusService,
  Tab,
  TagTab,
  Utils,
  CookieService,
  Order,
  FeaturedContentService,
  OrderService,
  AuditService,
} from 'ngx-web-api';
import { combineLatest, Observable, of, BehaviorSubject, interval, Subject, from } from 'rxjs';
import { map, tap, mergeMap, delay, finalize, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { addMinutes, subDays, parseISO } from 'date-fns';
import { ThemeService } from './theme.service';
import { DOCUMENT } from '@angular/common';
import { retryWithDelay } from 'app/shared/custom-operators/retry-with-delay';
import { CwoService } from './cwo.service';

@Injectable({
  providedIn: 'root',
})
export class MenuWrapperService extends MenuService {
  private groupsCache: Map<string, Group[]> = new Map<string, Group[]>();

  private searchResultGroups: Subject<Group[]> = new Subject();
  public searchResultGroups$: Observable<Group[]> = from(this.searchResultGroups);

  private refreshResults: Subject<void> = new Subject();
  public refreshResults$: Observable<void> = this.refreshResults.asObservable();

  private readonly defaultImageUrl = 'assets/img/card-placeholder.svg';
  private orderDeferTime: string;
  private orderType: OrderType;

  private orderIdCookieExists: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public orderIdCookieExists$: Observable<boolean> = this.orderIdCookieExists.asObservable();

  private headerHeight: BehaviorSubject<number> = new BehaviorSubject(0);
  public headerHeight$: Observable<number> = this.headerHeight.asObservable();

  private topMenuHeight = 0;

  private activeTabId: BehaviorSubject<string> = new BehaviorSubject(null);
  public activeTabId$: Observable<string> = this.activeTabId.asObservable();

  private menuTabsRendered: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public menuTabsRendered$: Observable<boolean> = this.menuTabsRendered.asObservable();

  private initParamsTab: BehaviorSubject<string> = new BehaviorSubject(null);
  public initParamsTab$: Observable<string> = this.initParamsTab.asObservable();

  private loading = false;

  constructor(
    private recentlyOrderedItemsService: RecentlyOrderedItemsService,
    private hypermediaService: HypermediaService,
    private orderableItemService: OrderableItemService,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private featuredContentService: FeaturedContentService,
    private themeService: ThemeService,
    orderService: OrderService,
    private auditService: AuditService,
    @Inject(DOCUMENT) private document: any,
    storeStatusService: StoreStatusService,
    http: HttpClient,
    private cwoService: CwoService
  ) {
    super(http, storeStatusService, orderService);
    this.startIntervalCheck();
  }

  /**
   * Get the groups for a given tab.
   * For a 'RecentlyOrdered' tab an empty array is returned.
   * For a regular tab the groups are sought in an internal cache, if none found
   * a request is made to fetch them and store in cache.
   * The delays and the recursive call are necessary in case of 2 consecutive function calls.
   * They ensure that only one request will be sent to the server.
   *
   * @param {Tab | TagTab} tab
   * @returns {Observable<Group[]>}
   */
  getGroups(tab: Tab | TagTab): Observable<Group[]> {
    if (tab.name === 'RecentlyOrdered') {
      return of([]);
    } else if (this.groupsCache.has(tab.name)) {
      return of(this.groupsCache.get(tab.name));
    } else {
      if (this.loading) {
        return of(null).pipe(
          delay(100),
          mergeMap(() => this.getGroups(tab))
        );
      }
      this.loading = true;
      return this.hypermediaService.get(tab.links, 'groups').pipe(
        map((json: Object[]): Group[] => DeserializationUtils.deserializeArray(json, Group)),
        tap((groups: Group[]) => this.groupsCache.set(tab.name, groups)),
        retryWithDelay(2000, 3),
        finalize(() => (this.loading = false))
      );
    }
  }

  getFirstPanelItemName(): Observable<string> {
    return combineLatest([this.recentlyOrderedItemsService.recentlyOrderedItems$, this.menu$]).pipe(
      map(([recentlyOrderedItems, menu]: [RecentlyOrderedItem[], Panel]) => {
        const recentlyOrderedItemsExist = recentlyOrderedItems && recentlyOrderedItems.length > 0;

        if (recentlyOrderedItemsExist) {
          return 'RecentlyOrdered';
        } else if (menu.tabs && menu.tabs.length > 0) {
          return menu.tabs[0].name;
        } else if (menu.tagTabs && menu.tagTabs.length > 0) {
          return menu.tagTabs[0].name;
        } else {
          return undefined;
        }
      })
    );
  }

  getPicture(pictures?: Picture[], type?: ImageAlternativeType, fallbackImage = this.defaultImageUrl): Picture | undefined {
    return super.getPicture(pictures, type) || (fallbackImage === null ? null : { src: fallbackImage, srcset: fallbackImage });
  }

  getPanelItemPictureSrc(panelItem: PanelItem, type?: ImageAlternativeType): string {
    return super.getPanelItemPictureSrc(panelItem, type) || this.defaultImageUrl;
  }

  getPanelItemPictureSrcset(panelItem: PanelItem, type?: ImageAlternativeType): string {
    return super.getPanelItemPictureSrcset(panelItem, type) || '';
  }

  getItemCalories(sizePrice: SizePrice, showWholeCalories = false): string | undefined {
    if (!sizePrice) {
      return undefined;
    }

    const hasServings = sizePrice.servings && sizePrice.servings > 1 && sizePrice.servingsLabel;

    let perServingCalories;
    if (hasServings) {
      perServingCalories = this.orderableItemService.getItemCaloriesPerServing(sizePrice);
    }
    const wholeCalories = this.orderableItemService.getItemCalories(sizePrice);

    const hasPerServingCalories = !Utils.isNullOrUndefined(perServingCalories);
    const hasWholeCalories = !Utils.isNullOrUndefined(wholeCalories);

    if (!hasPerServingCalories && hasWholeCalories) {
      return `${wholeCalories} ${this.translateService.instant('component.menu_wrapper_service.cal')}`.toLowerCase();
    }

    if (hasPerServingCalories) {
      let result = `${perServingCalories} ${this.translateService.instant('component.menu_wrapper_service.cal_per')} ${
        sizePrice.servingsLabel
      }`;
      if (showWholeCalories && hasWholeCalories) {
        result = `${wholeCalories} ${this.translateService.instant('component.menu_wrapper_service.cal')} / ${result}`;
      }
      return result.toLowerCase();
    }
  }
  // this will return the latest menu in cases where the order deferTime or type have been changed.
  getLatestMenu(orderType = OrderType.Pickup, date?: string): Observable<Panel> {
    return (!date && !!this.orderDeferTime) ||
      (!date && this.orderType !== orderType) ||
      (!!date && (this.orderType !== orderType || date !== this.orderDeferTime))
      ? this.fetchMenu(orderType, date)
      : this.menu$;
  }

  setHeaderHeight(height: number, propagate = true) {
    if (!height) {
      return;
    }
    this.document.body.parentElement.style['scrollPaddingTop'] = `${height + this.topMenuHeight}px`;
    if (propagate) {
      this.headerHeight.next(height);
    }
  }

  setTopMenuHeight(height: number) {
    this.topMenuHeight = height;
    this.headerHeight.next(this.headerHeight.value);
  }

  setActiveTabId(fragmentId: string) {
    this.activeTabId.next(fragmentId);
  }

  onMenuTabsRendered(value: boolean) {
    this.menuTabsRendered.next(value);
  }

  setInitParamsTab(tab: string) {
    this.initParamsTab.next(tab);
  }

  setOrderIdCookie(placedOrder: Order) {
    const OrderIdCookieExpTime = addMinutes(parseISO(placedOrder.promiseTime), 60);
    this.cookieService.setOrderId(placedOrder.orderId, OrderIdCookieExpTime.toUTCString(), `OrderID__${this.cwoService.storeName}`);
    this.checkTrackBtn();
  }

  removeOrderIdCookie(placedOrder: Order) {
    const OrderIdCookieExpTime = subDays(new Date(), 1);
    this.cookieService.setOrderId(placedOrder.orderId, OrderIdCookieExpTime.toUTCString(), `OrderID__${this.cwoService.storeName}`);
  }

  groupHasItemsWithCalories(group: Group) {
    return (
      group.panelItems
        .filter(data => data.isItem)
        .filter(data => {
          const selectedSizePrice = this.getDefaultSizePrice(data.sizePrices);
          return this.getItemCalories(selectedSizePrice, data.showFullSizeCalories);
        }).length > 0
    );
  }

  fetchMenuAndFeaturedContents(orderType: OrderType, deferTime: string): Observable<Panel> {
    return this.featuredContentService.fetchFeaturedContents(orderType, deferTime).pipe(
      mergeMap(() => this.recentlyOrderedItemsService.getRecentlyOrderedItems()),
      mergeMap(() => this.getLatestMenu(orderType, deferTime))
    );
  }

  searchMenu(searchTerm: string, orderType: OrderType, deferTime?: string, searchIngredients = false): Observable<Group[]> {
    this.auditService.createAudit(
      () => `Searching menu for : "${searchTerm}". Include matches on default ingredients: ${searchIngredients ? 'checked' : 'unchecked'}`
    );
    return this.searchMenuForItems(searchTerm, orderType, deferTime, searchIngredients).pipe(
      tap((groups: Group[]) => {
        if (!groups?.length) {
          groups.push(this.getEmptyResultsGroup());
        }
        this.searchResultGroups.next(groups);
      })
    );
  }

  clearSearchResults() {
    this.searchResultGroups.next(null);
  }

  refreshSearchResults() {
    this.refreshResults.next();
  }

  getDefaultFallbackImageUrl(): string {
    return this.defaultImageUrl;
  }

  private getEmptyResultsGroup(): Group {
    const group = new Group();
    return group.deserialize({
      keyName: 'No results found',
      name: 'No results found',
      panelItems: [],
    });
  }

  private fetchMenu(orderType = OrderType.Pickup, date?: string): Observable<Panel> {
    this.groupsCache = new Map<string, Group[]>();
    this.orderDeferTime = date;
    this.orderType = orderType;
    return this.themeService.theme.pipe(
      mergeMap(theme => {
        return super.getMenu('Web', orderType, date, theme.hasSinglePageMenu, true, !theme.hasSinglePageMenu);
      }),
      take(1)
    );
  }

  private checkTrackBtn() {
    this.orderIdCookieExists.next(this.checkOrderIdCookie());
  }

  private checkOrderIdCookie(): boolean {
    return !!this.cookieService.getCookie(`OrderID__${this.cwoService.storeName}`);
  }

  private startIntervalCheck() {
    this.checkTrackBtn();
    interval(60000).subscribe(() => this.checkTrackBtn());
  }
}
