import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrderType } from '../../models/core/order-type.enum';
import { StoreStatus } from '../../models/core/store-status.model';
import { Group } from '../../models/menu/group.model';
import { ImageAlternativeType } from '../../models/menu/image-alternative-type.enum';
import { ItemType } from '../../models/menu/item-type.enum';
import { OrderableItem } from '../../models/menu/orderable-item.model';
import { OrderableSpecial } from '../../models/menu/orderable-special.model';
import { PanelItem } from '../../models/menu/panel-item.model';
import { Panel } from '../../models/menu/panel.model';
import { Picture } from '../../models/menu/picture.model';
import { SizePrice } from '../../models/menu/size-price.model';
import { Tab } from '../../models/menu/tab.model';
import { Upsell } from '../../models/menu/upsell.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { StoreStatusService } from '../core/store-status.service';
import { TagTab } from '../../models/menu/tag-tab.model';
import { OrderService } from '../../services/ordering/order.service';
import { Order } from '../../models/ordering/order.model';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private readonly BASE_PATH = '/ws/integrated/v1/menu';

  protected menu: BehaviorSubject<Panel> = new BehaviorSubject(<Panel>{});
  public menu$: Observable<Panel> = this.menu.asObservable();

  public storeStatus: StoreStatus;
  protected order: Order;

  constructor(private http: HttpClient, private storeStatusService: StoreStatusService, protected orderService: OrderService) {
    this.storeStatusService.storeStatus$.subscribe(storeStatus => (this.storeStatus = storeStatus));
    this.orderService.order$.subscribe(order => (this.order = order));
  }

  public getMenu(
    panel: string,
    orderType = OrderType.Pickup,
    date?: string,
    compact = false,
    compactItems = true,
    compactTabs = false
  ): Observable<Panel> {
    let query = `page=${this.getSourcePage()}&orderType=${orderType.toString()}&compact=${compact}&compactTabs=${compactTabs}&compactItems=${compactItems}`;
    if (date) {
      query = query.concat(`&date=${encodeURIComponent(date)}`);
    }
    return this.http.get(`${this.BASE_PATH}/panels?${query}`).pipe(
      map((json: Object): Panel => DeserializationUtils.deserializeObj(json, Panel)),
      tap((menu: Panel) => this.menu.next(menu))
    );
  }

  public getCategoryItem(
    orderType: OrderType,
    categoryName?: string,
    itemName?: string,
    itemCode?: string,
    date?: string,
    compactUpsells = false
  ): Observable<OrderableItem> {
    let query = `categoryName=${categoryName}&itemName=${itemName}&orderType=${orderType.toString()}&page=${this.getSourcePage()}&compactUpsells=${compactUpsells}`;
    if (date) {
      query = query.concat(`&date=${encodeURIComponent(date)}`);
    }
    if (itemCode) {
      query = query.concat(`&itemCode=${itemCode}`);
    }
    return this.http
      .get(`${this.BASE_PATH}/categoryItem?${query}`)
      .pipe(map((json: Object): OrderableItem => DeserializationUtils.deserializeObj(json, OrderableItem)));
  }

  public getSpecial(
    orderType: OrderType,
    specialName?: string,
    specialCode?: string | string[],
    date?: string,
    compact = false,
    compactAlternativeItems = false,
    withCoupons = false
  ): Observable<OrderableSpecial> {
    let query = `page=${this.getSourcePage()}&orderType=${orderType.toString()}&compact=${compact}&compactAlternativeItems=${compactAlternativeItems}&withCoupons=${withCoupons}`;
    if (specialCode instanceof Array) {
      for (const code of specialCode) {
        query = query.concat(`&specialCode=${code}`);
      }
    } else if (specialCode) {
      query = query.concat(`&specialCode=${specialCode}`);
    }
    if (date) {
      query = query.concat(`&date=${encodeURIComponent(date)}`);
    }
    if (specialName) {
      query = query.concat(`&specialName=${encodeURIComponent(specialName)}`);
    }
    return this.http
      .get(`${this.BASE_PATH}/specials?${query}`)
      .pipe(map((json: Object): OrderableSpecial => DeserializationUtils.deserializeObj(json, OrderableSpecial)));
  }

  public getTab(name: string): Observable<Tab | TagTab> {
    return this.menu$.pipe(
      map(
        (menu: Panel) =>
          (menu.tabs && menu.tabs.find(tab => tab.name === name)) || (menu.tagTabs && menu.tagTabs.find(tagTab => tagTab.name === name))
      )
    );
  }

  public getPanelItem(tabName: string, groupName: string, panelItemName: string): Observable<PanelItem> {
    return this.getTab(tabName).pipe(
      map((tab: Tab): Group => tab && tab.groups && tab.groups.find(g => g.name === groupName)),
      map((group: Group): PanelItem => group && group.panelItems.find(pi => pi.name === panelItemName))
    );
  }

  public getPicture(pictures: Picture[] = [], type: ImageAlternativeType = ImageAlternativeType.ORIGINAL): Picture | undefined {
    return pictures.find(p => p.type === type);
  }

  public getPanelItemPicture(panelItem: PanelItem, type: ImageAlternativeType = ImageAlternativeType.ORIGINAL): Picture | undefined {
    if (panelItem.hasImage) {
      return panelItem.pictures.find(p => p.type === type);
    }
  }

  public getPanelItemPictureSrc(panelItem: PanelItem, type: ImageAlternativeType = ImageAlternativeType.ORIGINAL): string | undefined {
    const picture = this.getPanelItemPicture(panelItem, type);
    return picture ? picture.src : undefined;
  }

  public getPanelItemPictureSrcset(panelItem: PanelItem, type: ImageAlternativeType = ImageAlternativeType.ORIGINAL): string | undefined {
    const picture = this.getPanelItemPicture(panelItem, type);
    return picture ? picture.srcset : undefined;
  }

  public convertUpsellToPanelItem(upsell: Upsell): PanelItem {
    const panelItem = new PanelItem().deserialize({
      label: upsell.orderableItem.label,
      name: upsell.orderableItem.label || upsell.orderableItem.name,
      labelSymbol: upsell.orderableItem.labelSymbol,
      type: ItemType.ITEM,
      links: upsell.orderableItem.links,
      sizePrices: upsell.size ? [upsell.size] : upsell.orderableItem.sizePrices,
      description: upsell.orderableItem.description,
      showFullSizeCalories: upsell.orderableItem.showFullSizeCalories,
    });

    if (!!upsell.imageUrl) {
      const picture = { src: upsell.imageUrl, srcset: upsell.imageUrl, type: ImageAlternativeType.ORIGINAL } as Picture;
      panelItem.pictures = [picture];
    }

    return panelItem;
  }

  public getDefaultSizePrice(sizePrices: SizePrice[]): SizePrice | undefined {
    return sizePrices.find(sizePrice => sizePrice.isWebDefault) || sizePrices[0];
  }

  public searchMenuForItems(searchTerm: string, orderType: OrderType, deferTime?: string, searchIngredients = false): Observable<Group[]> {
    if (!searchTerm) {
      return of([]);
    }
    if (!orderType) {
      orderType = OrderType.Any;
    }

    let queryParams = `page=${this.getSourcePage()}&name=${encodeURIComponent(searchTerm)}&orderType=${orderType.toString()}`;
    if (!!deferTime) {
      queryParams = queryParams.concat(`&date=${encodeURIComponent(deferTime)}`);
    }
    if (searchIngredients) {
      queryParams = queryParams.concat(`&searchDefaultIngredients=true`);
    }
    return this.http
      .get<Group[]>(`/ws/integrated/v1/menu/search?${queryParams}`)
      .pipe(map((json: Object[]): Group[] => DeserializationUtils.deserializeArray(json, Group)));
  }

  private getSourcePage(): string {
    return this.order?.isInitialized && this.order?.isSelfOrderSource ? 'SelfOrdering' : 'Web';
  }
}
