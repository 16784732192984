export enum OrderType {
  Pickup = 'Pickup',
  Delivery = 'Delivery',
  Any = 'Any',
  ForHere = 'For Here',
  Curbside = 'Curbside',
  ToGo = 'To Go',
  ToStay = 'To Stay',
  DineIn = 'Dine In',
  Bar = 'Bar',
  GiftReload = 'Gift Reload',
  DriveThru = 'Drive Thru',
  PreOrder = 'PreOrder',
  Banquet = 'Banquet',
}
