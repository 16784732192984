import { BaseResource } from './base-resource';
import { GenericObject } from './generic-object';

export abstract class ValidatedResource extends BaseResource {
  warnings: string[];
  errors: GenericObject<string[]>;

  constructor() {
    super();
  }

  isValid(): boolean {
    return (!this.warnings || !this.warnings.length) && !this.errors;
  }
}
