import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { PaymentType } from '../../models/ordering/payment-type.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { AccountService } from '../account/account.service';
import { PaymentTypeFee } from '../../models/ordering/payment-type-fee.model';

@Injectable({ providedIn: 'root' })
export class OrderPaymentTypesService {
  private paymentTypes: BehaviorSubject<PaymentType[]> = new BehaviorSubject(<PaymentType[]>[]);
  public paymentTypes$: Observable<PaymentType[]> = this.paymentTypes.asObservable();

  constructor(private http: HttpClient, private accountService: AccountService) {
    this.accountService.userLogin$.pipe(mergeMap(() => this.fetchPaymentTypes())).subscribe(
      () => {},
      () => {}
    );
  }

  public fetchPaymentTypes(noContact?: boolean): Observable<PaymentType[]> {
    let params: HttpParams = new HttpParams();
    if (noContact) {
      params = params.set('noContact', 'true');
    }
    return this.http.get('/ws/integrated/v1/ordering/order/paymentTypes', { params }).pipe(
      map((json: Object[]) => DeserializationUtils.deserializeArray(json, PaymentType)),
      catchError(() => of([])),
      tap((paymentTypes: PaymentType[]) => this.paymentTypes.next(paymentTypes))
    );
  }

  public fetchPaymentTypeFee(paymentType: string, amount: string): Observable<PaymentTypeFee> {
    let params: HttpParams = new HttpParams();
    params = params.set('paymentType', paymentType);
    params = params.set('amount', amount);
    return this.http
      .get('/ws/integrated/v1/ordering/order/payments/getFee', { params })
      .pipe(map((json: Object) => DeserializationUtils.deserializeObj(json, PaymentTypeFee)));
  }
}
