import { Injectable } from '@angular/core';
import {
  StoreConfig,
  OrderService,
  ConfigService,
  Group,
  Utils,
  PanelItem,
  RecentlyOrderedItem,
  RecentlyOrderedItemsService,
  HypermediaService,
  OrderedItem,
  OrderableItem,
  AuditService,
  MenuItemsLayout,
  GroupOrder,
  Fundraiser,
  OrderedFundraiser,
  OrderFundraiserService,
  SizePrice,
  UpsellType,
  OrderedItemService,
  OrderedChoice,
  OrderedIngredient,
  OrderableIngredient,
  Tab,
  OrderedSpecial,
  Order,
  OrderType,
} from 'ngx-web-api';
import { Observable, of, throwError, BehaviorSubject, noop, Subject } from 'rxjs';
import { mergeMap, take, map, filter, tap, catchError, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { ModalService } from './modal.service';
import { MenuWrapperService } from './menu-wrapper.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderUpsellService } from './order-upsell.service';
import { isSameDay, addMinutes } from 'date-fns';
import { GroupOrderOrchestratorService } from './group-order-orchestrator.service';
import { SpecialWizardModalComponent } from 'app/shared/special-wizard-modal/special-wizard-modal.component';
import { FundraiserSponsorsModalContext } from 'app/domain/fundraiser-sponsors-modal-context';
import { FundraiserSponsorsModalComponent } from 'app/shared/fundraiser-sponsors-modal/fundraiser-sponsors-modal.component';
import { SpecialWizardService } from './special-wizard.service';
import { SpecialWizard } from 'app/domain/special-wizard';
import { SpecialWizardModalContext } from 'app/domain/special-wizard-modal-context';
import { ThemeService } from './theme.service';
import { Theme } from 'app/domain/theme.model';
import { GTMService } from './gtm.service';
import { EcommerceTriggeringArea } from '../../domain/ecommerce-triggering-area.enum';
import { EcommerceTriggeringPoint } from '../../domain/ecommerce-triggering-point.enum';
import { PathService } from 'app/shared/services/path.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DateFormatPipe } from 'ngx-web-api/lib/pipes/date-format.pipe';
import { UiOrchestratorService } from './ui-orchestrator.service';
import { HttpClient } from '@angular/common/http';
import { SpecialtyHalfs } from 'ngx-web-api/lib/models/menu/specialty-halfs.model';
import { HalfsOrderingService } from './halfs-ordering.service';

@Injectable({
  providedIn: 'root',
})
export class OrderingUtilsService {
  private readonly BASE_PATH = '/ws/integrated/v1/menu';

  private isSelfOrder: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isSelfOrder$: Observable<boolean> = this.isSelfOrder.asObservable();
  private itemAddedToOrder: Subject<boolean> = new Subject();
  public itemAddedToOrder$: Observable<boolean> = this.itemAddedToOrder.asObservable();
  dateFormat = new DateFormatPipe();
  menuItemsLayoutEnum = MenuItemsLayout;
  storeConfig: StoreConfig;
  theme: Theme;
  isInSearch = false;

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private orderService: OrderService,
    private configService: ConfigService,
    private menuService: MenuWrapperService,
    private translateService: TranslateService,
    private recentlyOrderedItemsService: RecentlyOrderedItemsService,
    private hypermedia: HypermediaService,
    private auditService: AuditService,
    private orderUpsellService: OrderUpsellService,
    private groupOrderOrchestrationService: GroupOrderOrchestratorService,
    private orderFundraiserService: OrderFundraiserService,
    private orderedItemService: OrderedItemService,
    private specialWizardService: SpecialWizardService,
    private themeService: ThemeService,
    private gtmService: GTMService,
    private pathService: PathService,
    private liveAnnouncer: LiveAnnouncer,
    private uiOrchestratorService: UiOrchestratorService,
    private halfsOrderingService: HalfsOrderingService
  ) {
    this.themeService.theme.subscribe(theme => (this.theme = theme));
    this.configService.storeConfig$.subscribe((cfg: StoreConfig) => (this.storeConfig = cfg));
    this.orderService.order$
      .pipe(
        mergeMap(order => this.configService.findOrderTypeConfig(order.orderType).pipe(map(config => ({ order, config })))),
        map(({ order, config }) => !!order && order.isInitialized && order.isSelfOrderSource && !!config),
        distinctUntilChanged(),
        shareReplay()
      )
      .subscribe(isSelfOrder => this.isSelfOrder.next(isSelfOrder), noop);
    this.pathService.inSearch$.pipe(distinctUntilChanged()).subscribe(inSearch => (this.isInSearch = inSearch));
  }

  addToOrder(
    groupOrder: GroupOrder,
    panelItem: PanelItem,
    currentTab: string,
    menuFetchedWithoutOrderType: boolean,
    selectedSizePrice: SizePrice,
    menuItemsLayout?: MenuItemsLayout,
    quantity?: number,
    upfrontChoice?: string,
    upfrontIngredient?: OrderableIngredient,
    upfrontQualifiers?: string[],
    isUpsell?: boolean,
    upsellType?: UpsellType,
    isSister?: boolean
  ) {
    if (groupOrder && !groupOrder.allowEditOrder) {
      this.groupOrderOrchestrationService.notifyOrderNotEditable().subscribe(
        () => {},
        () => {}
      );
      return null;
    }
    if (panelItem.isSpecial) {
      return this.addSpecialToOrder(panelItem, currentTab, menuFetchedWithoutOrderType).subscribe(
        context => {
          this.modalService.openModal(SpecialWizardModalComponent, context.getConfig());
          this.gtmService.pushViewPromotionEvent(
            panelItem.label || panelItem.name,
            panelItem.code,
            EcommerceTriggeringArea.MENU,
            EcommerceTriggeringPoint.ORDER_BUTTON,
            currentTab,
            panelItem.__slotNum
          );
        },
        error => this.modalService.parseAndNotifyErrors(error)
      );
    } else if (panelItem.isFundraiser) {
      return this.addFundraiserToOrder(panelItem, currentTab, menuFetchedWithoutOrderType).subscribe(noop, noop);
    }
    return this.addItemToOrder(
      panelItem,
      currentTab,
      menuFetchedWithoutOrderType,
      selectedSizePrice,
      menuItemsLayout,
      quantity,
      upfrontChoice,
      upfrontIngredient,
      upfrontQualifiers,
      isUpsell,
      upsellType,
      isSister
    ).subscribe(orderedItem => {
      if (!!orderedItem) {
        this.liveAnnouncer.announce(
          this.translateService.instant('component.size_price_button.add_to_order_confrim_message', {
            printName: orderedItem.printName,
          }),
          2000
        );
      }
      this.itemAddedToOrder.next(true);
    }, noop);
  }

  public addOrCustomizeItem(
    groupOrder: GroupOrder,
    panelItem: PanelItem,
    selectedSizePrice: SizePrice,
    currentTab: string,
    menuFetchedWithoutOrderType: boolean,
    tabName: string,
    menuItemsLayout?: MenuItemsLayout,
    quantity?: number,
    upfrontChoice?: string,
    selectedUpfrontIngredient?: OrderableIngredient,
    selectedUpfrontQualifier?: string[]
  ) {
    if (
      (groupOrder && !groupOrder.allowEditOrder) ||
      panelItem.isSpecial ||
      panelItem.isFundraiser ||
      (panelItem.hasSingleSize && !panelItem.itemWithIngredientChoices)
    ) {
      return this.addToOrder(
        groupOrder,
        panelItem,
        currentTab,
        menuFetchedWithoutOrderType,
        selectedSizePrice,
        menuItemsLayout,
        quantity,
        upfrontChoice,
        selectedUpfrontIngredient,
        selectedUpfrontQualifier
      );
    }
    // Customize Item
    return this.findSelectedItemInMenu(panelItem, currentTab, menuFetchedWithoutOrderType, selectedSizePrice.size)
      .pipe(
        mergeMap((item: PanelItem) => this.hypermedia.get(item.links, item.type)),
        map((json: Object): OrderableItem => new OrderableItem().deserialize(json)),
        tap(() => {
          this.halfsOrderingService.isHalfAndHalf.next(panelItem.isHalfAndHalf);
          this.halfsOrderingService.halfAndHalfLabel.next(panelItem.label);
        }),
        mergeMap((orderableItem: OrderableItem) => {
          this.gtmService.pushViewItemEvent(orderableItem, selectedSizePrice.label, selectedSizePrice.price, quantity, tabName);
          const orderedItem = this.orderedItemService.createOrderedItem(orderableItem, selectedSizePrice.size, quantity);
          this.auditService.createAudit(() => `Customize "${orderableItem.name}" of size "${selectedSizePrice.size}" button pressed`);
          return this.orderUpsellService.addToOrderOrUpsell(
            orderedItem,
            orderableItem,
            false,
            menuItemsLayout,
            null,
            selectedUpfrontIngredient,
            upfrontChoice,
            selectedUpfrontQualifier
          );
        })
      )
      .subscribe(() => {});
  }

  public findSelectedItemInMenu(panelItem: PanelItem, tabName: string, shouldFetchMenu: boolean, size?: string): Observable<PanelItem> {
    return this.uiOrchestratorService.ensureOrderHasType().pipe(
      mergeMap(() => (shouldFetchMenu && !this.isInSearch ? this.findSelectedItemInUpdatedTab(tabName, panelItem, size) : of(panelItem))),
      filter((item: PanelItem) => !!item)
    );
  }

  public findRecentlyOrderedItemInTab(selectedItem: RecentlyOrderedItem, shouldFetchTab: boolean): Observable<RecentlyOrderedItem> {
    return this.uiOrchestratorService.ensureOrderHasType().pipe(
      mergeMap(() =>
        shouldFetchTab
          ? this.findSelectedItemInUpdatedTab('RecentlyOrdered', selectedItem, null, selectedItem.lastOrderedTime)
          : of(selectedItem)
      ),
      filter((item: RecentlyOrderedItem) => !!item)
    );
  }

  public addRecentlyOrderedItemToOrder(
    selectedItem: RecentlyOrderedItem,
    menuFetchedWithoutOrderType: boolean,
    isCustomizing?: boolean,
    force?: boolean
  ): Observable<OrderedItem> {
    return this.findRecentlyOrderedItemInTab(selectedItem, menuFetchedWithoutOrderType).pipe(
      mergeMap((item: RecentlyOrderedItem) => this.hypermedia.get(item.links, 'orderedItem')),
      map(json => new OrderedItem().deserialize(json)),
      mergeMap(orderedItem => {
        if (orderedItem.deletedIngredientWarningMessage) {
          this.modalService.openWarningNotificationModal(
            this.translateService.instant('component.order_utils.unavailable_ingredient'),
            orderedItem.deletedIngredientWarningMessage
          );
        }
        return this.getOrderableItem(orderedItem).pipe(map(orderableItem => ({ orderedItem, orderableItem })));
      }),
      mergeMap(({ orderedItem, orderableItem }) => {
        orderedItem.itemId = undefined;
        if (isCustomizing) {
          this.auditService.createAudit(() => `Customize "${orderableItem.name}" button pressed`);
          this.gtmService.pushViewItemEvent(selectedItem, selectedItem.sizeLabel, selectedItem.price, 1, 'Recently Ordered');
        }
        if (!!orderedItem && !orderableItem.sizePrices.find(sp => sp.size === orderedItem.size)) {
          return throwError(
            this.translateService.instant('route.menu.menu_tab.card_menu_item.size_unavailable', {
              size: orderedItem.size,
              item: orderedItem.item,
              category: orderedItem.category,
            })
          );
        }
        return this.orderUpsellService.addToOrderOrUpsell(
          orderedItem,
          orderableItem,
          !isCustomizing,
          MenuItemsLayout.CardMenu,
          selectedItem.itemId,
          null,
          null,
          null,
          null,
          force
        );
      })
    );
  }

  public getFormattedPromiseTime(promiseTime: string, leeway: number, roundUpPromiseTime: boolean) {
    const time = this.getRoundUpPromiseTimeIfNeeded(promiseTime, roundUpPromiseTime);

    if (time) {
      let result = this.dateFormat.transform(time, 'h:mm a');
      const parsedTime = Utils.parseIsoDate(time);
      const isPromiseTimeToday = time && isSameDay(new Date(), parsedTime);

      if (leeway && leeway > 0) {
        const promiseTimeWithLeeway = addMinutes(parsedTime, leeway);
        const formattedPromiseTimeWithLeniency = this.dateFormat.transform(promiseTimeWithLeeway, 'h:mm a');
        result += ` - ${formattedPromiseTimeWithLeniency}`;
      }

      if (!isPromiseTimeToday) {
        result +=
          this.storeConfig.country === 'IE'
            ? ` ${this.dateFormat.transform(parsedTime, `'on' d MMM`)}`
            : ` ${this.dateFormat.transform(parsedTime, `'on' MMM d`)}`;
      }

      return result;
    }
  }

  public calcItemsToRemove(existingOrder: Order, newOrder: Order): OrderedItem[] {
    const itemsToRemove = existingOrder.items.filter(item => !newOrder.items.find(i => item.itemId === i.itemId));

    if (itemsToRemove.length) {
      return itemsToRemove;
    }

    return existingOrder.specials.filter(special => !newOrder.specials.find(sp => special.couponCode === sp.couponCode))[0]?.items ?? [];
  }

  public calcItemsToAdd(existingItems: OrderedItem[], newItems: OrderedItem[]): OrderedItem[] {
    return newItems.filter(item => !existingItems.find(i => item.itemId === i.itemId));
  }

  public findAddedSpecial(existingSpecials: OrderedSpecial[], newSpecials: OrderedSpecial[]): OrderedSpecial {
    return (
      newSpecials.find(special => !existingSpecials.find(sp => special.ordinal === sp.ordinal)) ??
      newSpecials.find(special => !existingSpecials.find(sp => special.couponCode === sp.couponCode))
    );
  }

  private getRoundUpPromiseTimeIfNeeded(promiseTime: string, roundUpPromiseTime: boolean) {
    if (promiseTime) {
      let time = promiseTime;

      if (roundUpPromiseTime) {
        const FIVE_MINS_IN_SECS: number = 5 * 60;
        time = Utils.roundUp(time, FIVE_MINS_IN_SECS);
      }

      return time;
    }
  }

  private getOrderableItem(item: OrderedItem) {
    return this.orderService.order$.pipe(
      take(1),
      map(order => order.deferTime),
      mergeMap(deferTime => {
        // The API does not append the `date` URL param in recently ordered item links so we do it manually
        if (!!deferTime) {
          const orderableLink = item.links.find(l => l.rel === 'orderableItem');
          orderableLink.href =
            !!orderableLink && orderableLink.href.indexOf('date=') === -1
              ? `${orderableLink.href}${orderableLink.href.indexOf('?') > -1 ? '&' : '?'}date=${encodeURIComponent(deferTime)}`
              : orderableLink.href;
        }
        return this.hypermedia.get(item.links, 'orderableItem').pipe(
          catchError(_err =>
            throwError(
              this.translateService.instant(
                'route.menu.menu_tab.recently_ordered_items_group_entry.recently_ordered_item.price_period_error'
              )
            )
          ),
          map(json => new OrderableItem().deserialize(json))
        );
      })
    );
  }

  private findSelectedItemInUpdatedTab(
    tabName: string,
    selectedItem: PanelItem | RecentlyOrderedItem,
    size?: string,
    orderedTime?: string
  ): Observable<PanelItem | RecentlyOrderedItem> {
    return this.orderService.order$.pipe(
      mergeMap(order => this.menuService.fetchMenuAndFeaturedContents(order.orderType, order.deferTime)),
      catchError(error => {
        this.modalService.parseAndNotifyErrors(error);
        return of(null);
      }),
      mergeMap(panel => {
        if (tabName === 'RecentlyOrdered') {
          return this.recentlyOrderedItemsService.recentlyOrderedItems$.pipe(
            map((items: RecentlyOrderedItem[]) => {
              return items.find(item => item.name === selectedItem.name && item.lastOrderedTime === orderedTime);
            }),
            tap((item: RecentlyOrderedItem) => (!item ? this.openModalForUnavailability('item') : null))
          );
        } else {
          if (this.isInSearch) {
            return this.menuService.searchResultGroups$.pipe(
              mergeMap(groups => of(this.findItemInGroups(null, groups, selectedItem, size)))
            );
          }
          const selectedTab = panel.tabs.find(t => tabName === t.name);
          return this.menuService.getGroups(selectedTab).pipe(
            map((groups: Group[]) => {
              return this.findItemInGroups(selectedTab, groups, selectedItem, size);
            })
          );
        }
      }),
      take(1)
    );
  }

  private findItemInGroups(selectedTab: Tab, groups: Group[], selectedItem: PanelItem | RecentlyOrderedItem, size?: string): PanelItem {
    const selectedGroups = !!groups ? groups : selectedTab?.groups;
    const panelItem = Utils.flatten(selectedGroups.map(group => group.panelItems)).find(item => item.name === selectedItem.name);

    if (!panelItem) {
      this.openModalForUnavailability(selectedItem.isSpecial ? 'special' : 'item');
    }

    const notAvailableSize = !!size && panelItem && !panelItem.sizePrices.find(sizePrice => sizePrice.size === size);
    if (notAvailableSize) {
      this.openModalForUnavailability('size');
    }

    return notAvailableSize ? null : panelItem;
  }

  private openModalForUnavailability(entity: string) {
    this.modalService.openWarningNotificationModal(this.translateService.instant('component.order_utils.unavailable', { entity }));
  }

  private addFundraiserToOrder(panelItem: PanelItem, currentTab: string, menuFetchedWithoutOrderType: boolean) {
    return this.findSelectedItemInMenu(panelItem, currentTab, menuFetchedWithoutOrderType).pipe(
      mergeMap((item: PanelItem) => this.hypermedia.get(item.links, item.type)),
      map((json: Object) => new Fundraiser().deserialize(json)),
      mergeMap((fundraiser: Fundraiser) => {
        if (fundraiser.sponsorsEnabled) {
          const context = new FundraiserSponsorsModalContext(fundraiser, undefined).getConfig();
          return this.modalService.openModal(FundraiserSponsorsModalComponent, context);
        }
        return this.orderFundraiserService.addFundraiserToOrder(OrderedFundraiser.fromFundraiser(fundraiser));
      })
    );
  }

  private addSpecialToOrder(panelItem: PanelItem, currentTab: string, menuFetchedWithoutOrderType: boolean) {
    return this.findSelectedItemInMenu(panelItem, currentTab, menuFetchedWithoutOrderType).pipe(
      mergeMap((item: PanelItem) =>
        this.specialWizardService.createSpecialWizard(item.links, item.type).pipe(
          filter((specialWizard: SpecialWizard) => !!specialWizard),
          map((specialWizard: SpecialWizard) => new SpecialWizardModalContext(specialWizard))
        )
      )
    );
  }

  private addItemToOrder(
    panelItem: PanelItem,
    currentTab: string,
    menuFetchedWithoutOrderType: boolean,
    selectedSizePrice: SizePrice,
    menuItemsLayout?: MenuItemsLayout,
    quantity?: number,
    upfrontChoice?: string,
    upfrontIngredient?: OrderableIngredient,
    upfrontQualifiers?: string[],
    isUpsell?: boolean,
    upsellType?: UpsellType,
    isSister?: boolean
  ) {
    return this.findSelectedItemInMenu(panelItem, currentTab, menuFetchedWithoutOrderType, selectedSizePrice.size).pipe(
      mergeMap((item: PanelItem) => this.hypermedia.get(item.links, item.type)),
      map((json: Object) => new OrderableItem().deserialize(json)),
      tap(() => {
        this.halfsOrderingService.isHalfAndHalf.next(panelItem.isHalfAndHalf);
        this.halfsOrderingService.halfAndHalfLabel.next(panelItem.label);
      }),
      mergeMap((orderableItem: OrderableItem) => {
        this.orderUpsellService.setAddedOrderableItem(orderableItem);
        const skipNavigation = menuItemsLayout === this.menuItemsLayoutEnum.CardMenu;
        const orderedItem = this.orderedItemService.createOrderedItem(orderableItem, selectedSizePrice.size, quantity);
        if (upfrontChoice && upfrontIngredient) {
          let choice = orderedItem.choices.find(c => c.name === upfrontChoice);
          if (!choice) {
            choice = new OrderedChoice().deserialize({
              name: upfrontChoice,
              ingredients: [{ ingredient: upfrontIngredient }],
            });
            orderedItem.choices.push(choice);
          } else {
            const ingredient = OrderedIngredient.createDefaultIngredient(upfrontIngredient.name);

            if (upfrontQualifiers && upfrontQualifiers.length) {
              ingredient.qualifiers = upfrontQualifiers;
            }

            choice.ingredients = [ingredient];
          }
        }
        orderedItem.isUpsell = isUpsell;
        orderedItem.upsellType = upsellType;
        orderedItem.isSister = isSister;

        return this.orderUpsellService.addToOrderOrUpsell(
          orderedItem,
          orderableItem,
          skipNavigation,
          menuItemsLayout,
          null,
          null,
          null,
          null,
          upsellType
        );
      })
    );
  }
  public getHalfs(categoryName: string, selectedSize: string, orderType = OrderType.Pickup, date?: string): Observable<SpecialtyHalfs[]> {
    let query = `categoryName=${categoryName}&size=${selectedSize}&orderType=${OrderType.Pickup.toString()}`;
    if (date) {
      query = query.concat(`&date=${encodeURIComponent(date)}`);
    }
    return this.http
      .get(`${this.BASE_PATH}/specialtyHalfs?${query}`)
      .pipe(map((json: Object[]) => (json || []).map(r => new SpecialtyHalfs().deserialize(r))));
  }
}
