<div id='home-page' class="secondary-bg-color">
  <div class='header fw-600 secondary-bg secondary-text-color hidden-xs' *ngIf="!theme.minifiedHeader">
    <fts-compact-header-overlay *ngIf="theme.compactHeader"></fts-compact-header-overlay>
    <div class='container no-padding flex'>
      <fts-menu-link *ngIf="theme.skipIntroPage" [lightFontWeight]="!theme.compactHeader"></fts-menu-link>
      <ng-container *ngIf="(breakpointService.isMdViewport$ | async) && account?.isInstantiated && theme.hasTopOrderTree">
        <ng-container *ngFor="let featuredContent of (headerFeaturedLinks$ | async)">
          <a *ngFor="let featuredItem of featuredContent.featuredItems"
             [href]='featuredItem.link'
             [target]="featuredItem.target"
             class="btn btn-link btn-header hidden-xs text-uppercase account-link font-secondary featured-link"
             [id]='"header-featured-link-" + (featuredItem.title | slugify)'>
             {{featuredItem.title}}
          </a>
        </ng-container>
      </ng-container>
      <div class='account-menu-wrapper' [ngClass]="{'wrap': (breakpointService.isMdViewport$ | async)}">
        <fts-plain-account-menu
          class="hidden-xs"
          [ngClass]="{'hidden-sm': theme.hasTopOrderTree && account.isInstantiated}"
          [account]='account'
          [compactHeader]="theme.compactHeader"
          [compactAccountMenu]="theme.hasCompactAccountMenu"
          [store]='store'
          [isInHome]='true'
          [featuredContents]='headerFeaturedLinks$ | async'
          [loyaltyPlan]='loyaltyPlan'
          (login)='handleLogin()'
          (logout)='handleLogout()'
        ></fts-plain-account-menu>
        <fts-track-order-btn *ngIf="(breakpointService.isMdViewport$ | async) && account.isInstantiated"
                         class="account-link"
                         [fontWeight]="true"
                         ></fts-track-order-btn>
        <fts-collapsible-account-menu
          *ngIf='account?.isInstantiated'
          class="hidden-xs"
          [ngClass]="{'hidden-md hidden-lg': !theme.hasCompactAccountMenu}"
          [store]='store'
          [account]='account'
          [hasTopOrderTree]="theme.hasTopOrderTree"
          [loyaltyPlan]="loyaltyPlan"
          [leftPositioned]="true"
          (login)='handleLogin()'
          (logout)='handleLogout()'>
        </fts-collapsible-account-menu>
      </div>

      <div class='store text-right text-uppercase'>
        <span class="hidden-sm" *ngIf="!isStoreChooserOpened">{{store?.fullAddress}}</span>
        <button
          *ngIf="store?.hasStoreChooser"
          class="btn btn-link btn-header store-chooser-link"
          ftsAsyncButton [subscription]="loadingStates"
          [attr.aria-expanded]="this.isStoreChooserOpened"
          [attr.aria-label]="'component.header.menu_links.store_icon.store_info' | translate"
          (click)="handleStoreIconClick()">
          <fa-icon [icon]="faStore" class="secondary-text-color"></fa-icon>
        </button>
        <fts-store-phone *ngIf="store.phone" [phone]="store.phone" [isInHome]="true" [ngClass]="isStoreChooserOpened ? 'store-chooser-open' : ''"></fts-store-phone>
        <button class="btn btn-link text-uppercase secondary-text-color change-store-btn d-block hidden-sm" ftsAsyncButton [subscription]="loadingStates"
                (click)="handleChangeClick()" *ngIf="store?.hasStoreChooser && !isStoreChooserOpened"
                ftsAuditableButton auditName="Change Store/Order type">{{'route.home.change' | translate}}</button>
        <button *ngIf="shouldShowFavoriteStoreBtn && !isStoreChooserOpened" ftsAsyncButton [subscription]="favoriteStoreSubscription" (click)="handleMyFavoriteStore()"
                class="btn btn-link text-uppercase secondary-text-color make-favorite-store-btn d-block hidden-sm">{{'route.home.make_my_location' | translate}}</button>
      </div>
    </div>
  </div>

  <div class='container no-padding' id="homeMainContent">
    <div class='home-wrapper secondary-bg-color secondary-text-color'>
      <div class='home-info-bg-fix secondary-bg-color' [@fullWidth]="expanding" (@fullWidth.done)="fullWidthAnimationDone()" (@fullWidth.start)="fullWidthAnimationStart()"></div>
      <div class='home-info padded-left-xs padded-right-xs' [@fullWidth]="expanding">
        <div *ngIf="!embedded" class='logo-wrapper' [ngClass]="{'feature-expanded': expanded}" #storeLogoWrapper>
          <fts-store-logo
            [logoRedirectToStaticSite]="store?.logoRedirectToStaticSite"
            [staticSiteUrl]="store?.staticSiteUrl"
            [src]="store?.logo"
            [hasFullWidth]="true"
            [homepage]="true"
            [itemImgHeight]="itemImgHeight"
            (loadEvent)="setInitHeight()">
          </fts-store-logo>
        </div>

        <button class="btn btn-link btn-undecorated no-padding dismiss-btn close-btn font-secondary" *ngIf="pendingFeaturedItem && !isMobile" (click)="clearPendingFeaturedItem()">
          <img src="assets/img/close.svg" alt="" aria-hidden="true"/>
          <span class="sr-only">{{'component.home.close' | translate}}</span>
        </button>

        <div *ngIf="expanded" class='featured-title fw-600 text-center hidden-xs' [ngClass]="{'intro-highlight-text-color': expanded}" >
          {{'route.home.tell_us_about_order' | translate}}
        </div>

        <div class='order-info-wrapper text-center' [@fullWidthOrderType]="expanding">
          <div class='order-type-chooser-wrapper'>
            <fts-order-type-chooser
              [isInHome]='true'
              [selectedOrderType]='order.orderType'
              (onOrderTypeChange)='chooseOrderType($event)'></fts-order-type-chooser>
          </div>

          <div class="text-center" *ngIf="store?.allowGroupWebOrdering">
            <div class="group-order-checkbox-wrapper">
              <fts-themeable-checkbox  [name]="'groupOrder'"
                                       [(ngModel)]="isGroupOrder">
                {{'route.home.make_it_go' | translate}}
              </fts-themeable-checkbox>
            </div>
          </div>

          <div class='padding-top-20' [ngClass]="{'overflow-visible': timeChooserOverflowVisible}"
                 [@expanded] *ngIf='shouldShowOrderTimeChooser' (@expanded.done)="timeChooserOverflowVisible = shouldShowOrderTimeChooser">
            <fts-order-time-chooser
              *ngIf="shouldShowOrderTimeChooser"
              [isInHome]='true'
              [orderType]='order.orderType'
              [orderIsInitialized]="order.isInitialized"
              [loadingSubscription]="loadingSubscription"
              [orderTimeInfo]="orderTimeInfo"
              [deferChoice]="deferChoice"
              (orderTimeChange)='chooseOrderTime($event)'
              (deferChoiceChange)="chooseDeferChoice($event)"
              (deferDateChange)="chooseOrderDate($event)"
              (asapButtonClick)="continueToMenu()"
              (hasAvailableDeferOptions)="hasAvailableDeferOptions = $event"></fts-order-time-chooser>
          </div>

          <div *ngIf="showOrderError" class="alert alert-danger mt-3" role="alert" aria-atomic="true"
               translate>route.home.order_error</div>

          <ng-container *ngIf="shouldShowStartButton">
            <div class='margin-top-20' [@expanded]='shouldShowStartButton'>
              <button
                ftsAsyncButton
                ftsAuditableButton
                auditName="Continue to menu"
                [subscription]='loadingSubscription'
                (click)='continueToMenu()'
                id='continue-to-menu'
                class='btn btn-lg intro-highlight-bg-color intro-highlight-inverse-text-color shadowed fw-600 margin-bottom-15 font-secondary text-uppercase'>
                {{(canContinueToMenu || isGroupOrder || pendingFeaturedItem || !theme.skipIntroPage ? 'route.home.start' : 'route.home.view_menu') | translate}}
              </button>
            </div>
          </ng-container>
          <div>
            <fts-store-open-close-time [orderType]="order.orderType"
                                       [openCloseTime]="openCloseTime"
                                       [multiline]="true"></fts-store-open-close-time>
          </div>
          <div *ngIf="store?.webOrderingIntroDisclaimer" class="margin-top-10 margin-bottom-10 padded-bottom-15 padded-top-10 text-center">
            {{store.webOrderingIntroDisclaimer}}
          </div>

          <div class="mobile-info-wrapper margin-top-20">
            <div class="account-store-container">
              <fts-track-order-btn [btnUndecorated]="true" [fontWeight]="true"></fts-track-order-btn>
              <button *ngIf='!account?.isInstantiated' class="btn btn-link btn-undecorated visible-xs fw-600 font-secondary text-uppercase" (click)="handleLogin()">{{'route.home.sign_in' | translate}}</button>
              <button *ngIf='account?.isInstantiated' class="btn btn-link btn-undecorated visible-xs fw-600 font-secondary text-uppercase" (click)="handleLogout()">{{'route.home.sign_out' | translate}}</button>

              <div class="my-store-btn">
                <div class="btn btn-link btn-undecorated visible-xs fw-600 font-secondary text-uppercase">
                  {{'route.home.my_store' | translate}}
                </div>
                <fa-icon [fixedWidth]="true" class="address-indicator" [icon]="faCaretDown"></fa-icon>
              </div>
            </div>

            <div class="margin-top-5 fw-500">{{store?.fullAddress}}</div>

            <fts-store-phone *ngIf="store?.phone" [phone]="store?.phone" [isInHome]="true"></fts-store-phone>

            <button class="btn btn-link text-uppercase secondary-text-color change-store-btn" ftsAsyncButton [subscription]="loadingStates"
            (click)="handleChangeClick()" *ngIf="store?.hasStoreChooser">{{'route.home.change' | translate}}</button>

          </div>

        </div>
      </div>
      <div class='home-featured-content'>
        <fts-featured-content
          *ngIf='featuredContent'
          [showVertically]="false"
          [featuredContent]="featuredContent"
          [fillParentHeight]='!isMobile'
          (featuredItemClicked)='featuredItemClicked($event)'
          [allowAddingItemsToOrder]="false"
        ></fts-featured-content>
        <div *ngIf="pendingFeaturedItem" class="overlay text-center">
          <button class="btn btn-link btn-undecorated no-padding dismiss-btn close-btn font-secondary" *ngIf="pendingFeaturedItem && isMobile" (click)="clearPendingFeaturedItem()">
            <img src="assets/img/close.svg" alt="" aria-hidden="true"/>
            <span class="sr-only">{{'component.home.close' | translate}}</span>
          </button>
          <div class="overlay-bg secondary-bg-color home-info-bg-fix"></div>
          <div class="fw-600 intro-highlight-text-color overlay-text">{{'route.home.fill_in_details' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
