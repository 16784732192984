import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ThemeService } from '../core/services/theme.service';
import { Theme } from '../domain/theme.model';
import {
  RecentlyOrderedItemsService,
  StoreStatusService,
  OrderService,
  DeferService,
  OrderType,
  StoreConfig,
  Order,
  StoreStatus,
  ConfigService,
  OrderTypeConfig,
  Utils,
  DeferOptions,
} from 'ngx-web-api';
import { ModalService } from '../core/services/modal.service';
import { MenuWrapperService } from '../core/services/menu-wrapper.service';
import { ParamsOrchestratorService } from '../core/services/params-orchestrator.service';

import { take, map, mergeMap, tap, catchError } from 'rxjs/operators';
import { forkJoin, of, Observable } from 'rxjs';

import { InitParamsStorageService } from '../core/services/init-params-storage.service';
import { formatISO } from 'date-fns';

@Injectable()
export class NoSkipIntroGuard implements CanActivate {
  constructor(
    private themeService: ThemeService,
    private storeStatusService: StoreStatusService,
    private configService: ConfigService,
    private deferService: DeferService,
    private orderService: OrderService,
    private router: Router,
    private menuService: MenuWrapperService,
    private recentlyOrderedItemsService: RecentlyOrderedItemsService,
    private paramsOrchestratorService: ParamsOrchestratorService,
    private modalService: ModalService,
    private initParamsStorageService: InitParamsStorageService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return forkJoin([
      this.themeService.theme.pipe(take(1)),
      this.configService.storeConfig$.pipe(take(1)),
      this.configService.orderTypesConfig$.pipe(take(1)),
    ]).pipe(
      mergeMap(([theme, storeConfig, orderTypeConfig]: [Theme, StoreConfig, OrderTypeConfig[]]) => {
        let selectedOrderType: OrderType;
        if (storeConfig.defaultOrderType) {
          selectedOrderType = storeConfig.defaultOrderType as OrderType;
        } else if (orderTypeConfig.length === 1) {
          selectedOrderType = orderTypeConfig[0].type as OrderType;
        }
        if (theme.skipIntroPage && !theme.askForOrderTimeUpfront && !storeConfig.askForAddressUpfront && selectedOrderType) {
          return this.getAvailableTimeslotAndRedirectToMenu(selectedOrderType).pipe(map(res => !res));
        } else {
          return of(true);
        }
      }),
      catchError(response => {
        this.modalService.parseAndNotifyErrors(response);
        return of(true);
      })
    );
  }

  createOrderAndFetchMenu(order: Order): Observable<any> {
    order.isMobileSource = this.initParamsStorageService.initParams.sourceIsMobile;
    return this.orderService
      .createOrder(order)
      .pipe(
        mergeMap((o: Order) =>
          forkJoin([this.menuService.getLatestMenu(o.orderType, o.deferTime), this.recentlyOrderedItemsService.getRecentlyOrderedItems()])
        )
      );
  }

  getAvailableTimeslotAndRedirectToMenu(selectedOrderType: OrderType) {
    return this.storeStatusService.fetchStoreStatus(selectedOrderType).pipe(
      mergeMap((newStatus: StoreStatus) => {
        if (newStatus.isOpen) {
          return of(null);
        } else if (newStatus.hasToday) {
          return this.deferService
            .getTimeSlots(formatISO(Utils.getTzDate()), selectedOrderType, DeferOptions.Today)
            .pipe(map((timeSlots: string[]) => timeSlots[0]));
        } else if (!!Object.keys(newStatus.deferredDayTimes)[0]) {
          return of(newStatus.deferredDayTimes[Object.keys(newStatus.deferredDayTimes)[0]].open);
        }
      }),
      map((deferTime: string) => {
        const order = new Order();
        order.orderType = selectedOrderType;
        order.deferTime = !!deferTime ? deferTime : undefined;
        return order;
      }),
      mergeMap((order: Order) => this.createOrderAndFetchMenu(order)),
      tap(() => this.router.navigate(['/menu']).then(() => this.paramsOrchestratorService.markOrderInitializationDone()))
    );
  }
}
