import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ConfigService, StoreConfig } from 'ngx-web-api';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class StoreConfigResolver implements Resolve<StoreConfig> {
  constructor(private configService: ConfigService) {}

  resolve(): Observable<StoreConfig> {
    return this.configService.storeConfig$.pipe(
      filter((store: StoreConfig) => !!store.name),
      take(1)
    );
  }
}
