import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { OrderableSpecial } from '../../models/menu/orderable-special.model';
import { OrderedSpecial } from '../../models/ordering/ordered-special.model';
import { DeserializationUtils } from '../../utils/deserialization-utils';
import { AccountCouponsService } from '../account/account-coupons.service';
import { AuditService } from '../core/audit.service';
import { OrderService } from './order.service';
import { OrderedItem } from '../../models/ordering/ordered-item.model';

@Injectable({ providedIn: 'root' })
export class OrderSpecialService {
  private readonly BASE_PATH = '/ws/integrated/v1/ordering/order/specials';

  private addedSpecial: Subject<OrderedSpecial> = new Subject();
  public addedSpecial$: Observable<OrderedSpecial> = this.addedSpecial.asObservable();

  private removedSpecial: Subject<OrderedSpecial> = new Subject();
  public removedSpecial$: Observable<OrderedSpecial> = this.removedSpecial.asObservable();

  constructor(
    private http: HttpClient,
    protected auditService: AuditService,
    private orderService: OrderService,
    private accountCouponsService: AccountCouponsService
  ) {}

  public validate(orderableSpecial: OrderableSpecial): Observable<OrderableSpecial> {
    return this.http.post(`${this.BASE_PATH}/validate`, orderableSpecial.serialize()).pipe(mergeMap(() => of(orderableSpecial)));
  }

  public addSpecialToOrder(orderedSpecial: OrderedSpecial): Observable<OrderedSpecial> {
    return this.http.post(this.BASE_PATH, orderedSpecial.serialize()).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, OrderedSpecial)),
      tap((special: OrderedSpecial) => this.addedSpecial.next(special)),
      tap((special: OrderedSpecial) => this.auditSpecialAddition(special)),
      mergeMap(special => this.orderService.fetchOrder().pipe(map(() => special))),
      tap(() =>
        setTimeout(() => {
          this.accountCouponsService.reloadCoupons();
        }, 100)
      )
    );
  }

  public updateSpecial(orderedSpecial: OrderedSpecial): Observable<OrderedSpecial> {
    return this.http.put(`${this.BASE_PATH}/${orderedSpecial.ordinal}`, orderedSpecial.serialize()).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, OrderedSpecial)),
      tap((special: OrderedSpecial) => this.addedSpecial.next(special)),
      tap((special: OrderedSpecial) => this.auditSpecialUpdate(special)),
      mergeMap(special => this.orderService.fetchOrder().pipe(map(() => special))),
      tap(() => this.accountCouponsService.reloadCoupons())
    );
  }

  public removeSpecialFromOrder(orderedSpecial: OrderedSpecial): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.BASE_PATH}/${orderedSpecial.ordinal}`, { observe: 'response' }).pipe(
      tap(() => this.auditSpecialDeletion(orderedSpecial)),
      tap(() => this.removedSpecial.next(orderedSpecial)),
      mergeMap(response => this.orderService.fetchOrder().pipe(map(() => response))),
      tap(() => this.accountCouponsService.reloadCoupons())
    );
  }

  public removeItemFromSpecial(orderedSpecial: OrderedSpecial, item: OrderedItem, refetchOrder = true): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.BASE_PATH}/${orderedSpecial.ordinal}/items/${item.itemId}`, { observe: 'response' }).pipe(
      tap(() => this.auditItemRemovalFromSpecial(orderedSpecial, item)),
      mergeMap(response => (refetchOrder ? this.orderService.fetchOrder() : of(null)).pipe(map(() => response)))
    );
  }

  public getBestOfferSpecial(): Observable<OrderableSpecial> {
    return this.http
      .get(`${this.BASE_PATH}/bestOffer`)
      .pipe(map((json: Object) => DeserializationUtils.deserializeObj(json, OrderableSpecial)));
  }

  public addBestOfferSpecialToOrder(orderedSpecial: OrderedSpecial): Observable<OrderedSpecial> {
    return this.http.post(`${this.BASE_PATH}/byName`, orderedSpecial.serialize()).pipe(
      map((json: Object) => DeserializationUtils.deserializeObj(json, OrderedSpecial)),
      tap((special: OrderedSpecial) => this.addedSpecial.next(special)),
      tap((special: OrderedSpecial) => this.auditSpecialAddition(special)),
      mergeMap(special => this.orderService.fetchOrder().pipe(map(() => special))),
      tap(() => this.accountCouponsService.reloadCoupons())
    );
  }

  /************************************************ Auditing helpers ************************************************/

  private auditSpecialAddition(special: OrderedSpecial): void {
    this.auditService.createAudit(() => `Special "${special.special}" has been added to order`);
  }

  private auditSpecialUpdate(special: OrderedSpecial): void {
    this.auditService.createAudit(() => `Special "${special.special}" has been updated`);
  }

  private auditSpecialDeletion(special: OrderedSpecial): void {
    this.auditService.createAudit(() => `Special "${special.special}" has been removed from order`);
  }

  private auditItemRemovalFromSpecial(special: OrderedSpecial, item: OrderedItem): void {
    this.auditService.createAudit(() => ({
      message: `'${item.size} ${item.item}' item has been removed from the "${special.special}" special`,
      details: { specialOrdinal: special.ordinal, itemId: item.itemId },
    }));
  }
}
