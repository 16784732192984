<div class="secondary-bg-color secondary-text-color">
  <div class="compact-header" [ngClass]="{'right-order-tree': theme.hasTopOrderTree && !theme.hasTopMenu}">
    <fts-compact-header-overlay></fts-compact-header-overlay>
    <div class="compact-header-content no-padding container">
      <div class='logo-container padded-left-sm-down' *ngIf="!(breakPointService.isTabletDown$ | async)">
        <div id="logo-wrapper" class="logo-wrapper">
          <fts-store-logo
            *ngIf="!embedded"
            [logoRedirectToStaticSite]="store?.logoRedirectToStaticSite"
            [staticSiteUrl]="store?.staticSiteUrl"
            [src]="store?.logo"
            [compact]="true">
          </fts-store-logo>
        </div>
      </div>

      <div class='menu-container'>
        <div class='top-row'>
          <div class='plain-account-links-container' [ngClass]="{'d-flex': breakPointService.isMdViewport$ | async}">
            <ng-container *ngIf="!(breakPointService.isTabletDown$ | async)">
              <fts-menu-link
                *ngIf="theme.skipIntroPage || !isInIntro"
                [panel]="panel"
                [visibleMenu]="visibleMenu"
                [showCategoriesUpfront]="theme.shouldShowCategoriesUpfront"
                (onToggleMobileMenu)="toggleMobileMenu($event)">
              </fts-menu-link>
              <fts-track-order-btn *ngIf="!isSelfOrder && account?.isInstantiated && (breakPointService.isMdViewport$ | async)" [fontWeight]="true"></fts-track-order-btn>

              <ng-container *ngIf="(breakPointService.isMdViewport$ | async) && account?.isInstantiated && theme.hasCompactAccountMenu">
                <ng-container *ngFor="let featuredContent of featuredContents">
                  <a *ngFor="let featuredItem of featuredContent.featuredItems"
                    [href]='featuredItem.link'
                    [target]="featuredItem.target"
                    class="btn btn-link btn-header hidden-xs text-uppercase account-link font-secondary fw-600 featured-link"
                    [id]='"header-featured-link-" + (featuredItem.title | slugify)'>
                    {{featuredItem.title}}
                  </a>
                </ng-container>
              </ng-container>
            </ng-container>
            <fts-plain-account-menu
              *ngIf="!(breakPointService.isTabletDown$ | async) && !((breakPointService.isMdViewport$ | async) && account?.isInstantiated && theme.hasCompactAccountMenu)"
              [account]='account'
              [compactHeader]="true"
              [compactAccountMenu]="theme.hasCompactAccountMenu"
              [store]='store'
              [loyaltyPlan]='loyaltyPlan'
              [featuredContents]='featuredContents'
              [isInIntro]="isInIntro"
              (login)='handleLogin()'
              (logout)='handleLogout()'
            ></fts-plain-account-menu>
          </div>
          <div class='store-info-container' *ngIf="!isInCheckoutDetails && !(breakPointService.isTabletDown$ | async)">
            <fts-store-info
              class='store-info'
              [orderType]="order?.orderType"
              [storeConfig]="store"
              [hasMultipleOrderTypeConfigs]="hasMultipleOrderTypeConfigs"
              [showChangeOrderTypeBtn]="!isInCheckout"
              [compact]="true"
              (onToggleMobileMenu)="toggleMobileMenu($event)"></fts-store-info>
          </div>
        </div>
        
        <div class='bottom-row' [ngClass]="{'pt-5': breakPointService.isMdViewport$ | async}">
          <div class='right-container' [ngClass]="{'has-group-ordering-btn': !!groupOrderButton && (breakPointService.isDesktop$ | async)}">
            <fts-grouporder-button 
              #groupOrderButton
              [buttonStyle]="'BUTTON'"
              [isInCompactHeader]="true"
              [label]="(!(breakPointService.isDesktop$ | async) ? 'component.header.minified_header.group_order' : 'component.header.menu_links.make_it_go') | translate"
              class="margin-right-5"
              [ngClass]="{'margin-left-5': this.theme.orderTreePosition !== pagePosition.Top}" >

            </fts-grouporder-button>

            <ng-container *ngIf="orderInitialized && showTopOrderTree && !(isInTrackerPage$ | async) && !(breakPointService.isTabletDown$ | async) && headerOrderTree">
              <ng-template [ngComponentOutlet]="headerOrderTree | async"></ng-template>
            </ng-container>
            
            <fts-collapsible-account-menu
              *ngIf="(!(breakPointService.isTabletDown$ | async) && theme?.hasCompactAccountMenu && account?.isInstantiated) || ((breakPointService.isMdViewport$ | async) && (!isInIntro || account?.isInstantiated))"
              [store]='store'
              [account]='account'
              [loyaltyPlan]="loyaltyPlan"
              [hasTopOrderTree]="theme?.hasTopOrderTree"
              (login)='handleLogin()'
              (logout)='handleLogout()'>
            </fts-collapsible-account-menu>

          </div>
        </div>
      </div>
    </div>
    <ng-template *ngIf="(breakPointService.isTabletDown$ | async) && !isInCheckoutDetails && orderNotification" [ngComponentOutlet]="orderNotification | async"></ng-template>
  </div>

  <div *ngIf="!store.hasStoreChooser && showIpadStoreInfo && !hasMultipleOrderTypeConfigs" class="ipad-store-info full-width btn btn-link btn-lg btn-undecorated primary-bg-color primary-text-color font-primary">
    <div *ngIf="!!orderTypeConfig">{{orderTypeConfig?.displayName || orderTypeConfig?.type}} <span class="from-container">
      {{'component.header.compact_header.from' | translate}}
    </span></div>
    <div>{{store?.name}} - {{store?.fullAddress}}</div>
  </div>
</div>
