import { Picture } from '../public_api';
import { BaseResource } from './base-resource';
import { Coupon } from './coupon.model';

export class LoyaltyPlanAward extends BaseResource {
  readonly name: string;
  readonly amount: number;
  readonly daysExpires: number;
  readonly optional: boolean;
  readonly description?: string;
  readonly pictures?: Picture[];
  matchingCoupon?: Coupon;
}
