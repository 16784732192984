import { DeserializationUtils } from '../../utils/deserialization-utils';
import { Utils } from '../../utils/utils';
import { DonationInfo } from '../core/donation-info.model';
import { OrderType } from '../core/order-type.enum';
import { ValidatedResource } from '../core/validated-resource';
import { Adjustment } from './adjustment.model';
import { OrderCustomer } from './order-customer.model';
import { OrderPayment } from './order-payment.model';
import { OrderStatus } from './order-status.enum';
import { OrderableCoupon } from './orderable-coupon.model';
import { OrderedFundraiser } from './ordered-fundraiser.model';
import { OrderedItem } from './ordered-item.model';
import { OrderedSpecial } from './ordered-special.model';
import { TaxRateGroup } from './tax-rate-group.model';
import { PaymentFee } from './payment-fee.model';
import { OrderNotificationTrigger } from '../post-order/order-notifcation-trigger.model';
import { CandidatePayment } from './candidate-payment.model';
import { ChannelName } from '../account/channel-name.enum';

export class Order extends ValidatedResource {
  orderId?: string;
  orderNumber: number;
  price: number;
  subTotal: number;
  amountPaid: number;
  due: number;
  unadjustedOrderTotal: number;
  donationAmount?: number;
  charityName?: string;
  tax: number;
  tip?: number;
  tipBasis?: number;
  discount?: number;
  deliveryCharge?: number;
  trackerLink?: string;
  status?: OrderStatus;
  isClosed: boolean;
  dateOrdered?: string;
  loyaltyPoints: number;
  itemsCount: number;
  orderType: OrderType = OrderType.Any;
  webCharge?: number;
  customerDriverFee?: number;
  reorderable?: boolean;
  deferTime?: string;
  promiseTime?: string;
  promiseTimeLeeway?: number;
  items?: OrderedItem[];
  fundraisers?: OrderedFundraiser[];
  specials?: OrderedSpecial[];
  customer: OrderCustomer;
  taxRateGroups?: TaxRateGroup[];
  pendingSpecial?: OrderableCoupon;
  adjustments: Adjustment[];
  payments: OrderPayment[];
  paymentFees: PaymentFee[];
  isMobileSource?: boolean;
  notificationPhone?: string;
  supportsNotification?: boolean;
  orderNotificationTriggers: OrderNotificationTrigger[];
  dummy: boolean; // we will use this to identify when the order service observable has properly initialized/fetched
  instructions?: string;
  candidatePayments: CandidatePayment[];
  isWebPay?: boolean;
  dateArrived?: string;
  isSelfOrderSource?: boolean;
  orderTable?: string;
  guestCount?: number;
  parkingSpot?: string;
  isFavorite: boolean;
  orderNotificationChannels?: ChannelName[];
  serviceChargeName: string;
  serviceCharge: number;
  creator?: string;

  static createDummyOrder() {
    return new Order().deserialize({ dummy: true });
  }

  constructor() {
    super();
  }

  get hasOrderType(): boolean {
    return !!this.orderType && this.orderType !== OrderType.Any;
  }

  get isInitialized(): boolean {
    return this.hasOrderType;
  }

  get isPickup(): boolean {
    return OrderType.Pickup === this.orderType;
  }

  get allWarnings(): string[] {
    const warnings: string[] = [];
    if (!Utils.isNullOrUndefined(this.warnings)) {
      warnings.push(...this.warnings);
    }

    const incompleteItems = (this.items || []).filter(item => !item.isValid()).length;
    if (incompleteItems) {
      warnings.push(`You have ${incompleteItems} incomplete item${incompleteItems > 1 ? 's' : ''} in your order.`);
    }

    const incompleteSpecials = (this.specials || []).filter(special => !special.isValid() && special.hasValidItems).length;
    if (incompleteSpecials) {
      warnings.push(`You have ${incompleteSpecials} incomplete special${incompleteSpecials > 1 ? 's' : ''} in your order.`);
    }

    const specialsIncompleteItems = (this.specials || [])
      .filter(special => !special.isValid() && !special.hasValidItems)
      .map(special => special.items.filter(item => !item.isValid()).length)
      .reduce((a, b) => a + b, 0);
    if (specialsIncompleteItems) {
      warnings.push(`You have ${specialsIncompleteItems} incomplete special item${specialsIncompleteItems > 1 ? 's' : ''} in your order.`);
    }

    return warnings;
  }

  get hasWarnings(): boolean {
    return !!this.allWarnings.length;
  }

  get hasFundraisers(): boolean {
    return !!this.fundraisers && this.fundraisers.length > 0;
  }

  get donationInfo(): DonationInfo | undefined {
    let donationInfo;

    if (this.donationAmount) {
      donationInfo = new DonationInfo();
      donationInfo.donationAmount = this.donationAmount;
    }

    return donationInfo;
  }

  get allItems() {
    return (this.items || []).concat(Utils.flatten((this.specials || []).map(s => s.items || [])));
  }

  get isCanceled() {
    return this.status === OrderStatus.canceled;
  }

  get isDeferred() {
    return !!this.deferTime;
  }

  get orderSupportsSmsNotifications() {
    return !!this.orderNotificationChannels?.find(c => c === ChannelName.SMS);
  }

  get orderSupportsPushNotifications() {
    return !!this.orderNotificationChannels?.find(c => c === ChannelName.PUSH_NOTIFICATIONS);
  }

  get orderSupportsEmailNotifications() {
    return !!this.orderNotificationChannels?.find(c => c === ChannelName.EMAIL);
  }

  deserialize(input: any): this {
    return Object.assign(this, input, {
      items: DeserializationUtils.deserializeChildren(input, 'items', OrderedItem),
      fundraisers: DeserializationUtils.deserializeChildren(input, 'fundraisers', OrderedFundraiser),
      specials: DeserializationUtils.deserializeChildren(input, 'specials', OrderedSpecial),
      customer: DeserializationUtils.deserializeChild(input, 'customer', OrderCustomer),
      taxRateGroups: DeserializationUtils.deserializeChildren(input, 'taxRateGroups', TaxRateGroup),
      pendingSpecial: DeserializationUtils.deserializeChild(input, 'pendingSpecial', OrderableCoupon),
      adjustments: DeserializationUtils.deserializeChildren(input, 'adjustments', Adjustment),
      payments: DeserializationUtils.deserializeChildren(input, 'payments', OrderPayment),
    });
  }

  serialize(): any {
    return {
      orderId: this.orderId,
      orderNumber: this.orderNumber,
      donationAmount: this.donationAmount,
      charityName: this.charityName,
      tip: this.tip,
      orderType: this.orderType,
      deferTime: this.deferTime,
      isMobileSource: this.isMobileSource,
      instructions: this.instructions,
      isSelfOrderSource: this.isSelfOrderSource,
      orderTable: this.orderTable,
      guestCount: this.guestCount,
    };
  }
}
