<header *ngIf="isInPay && !isInPrintReceipt" class="web-pay-header secondary-bg-color secondary-text-color">
  <fts-store-logo class="logo"
    [logoRedirectToStaticSite]="store?.logoRedirectToStaticSite"
    [staticSiteUrl]="store?.staticSiteUrl"
    [src]="store?.logo"
    [inPay]="true"></fts-store-logo>

  <div class="login-btn">
    <button *ngIf="!account?.isInstantiated"
      class='btn btn-link btn-header text-uppercase account-link font-secondary padding-0'
      id="account-link-login"
      (click)="handleLogin()">{{'component.header.collapsible_account_menu.sign_in' | translate}}
    </button>
    <button *ngIf="account?.isInstantiated"
      id="account-link-logout"
      class='btn btn-link btn-header text-uppercase account-link font-secondary padding-0'
      (click)='webPayLogout()'>{{'component.header.collapsible_account_menu.sign_out' | translate}}
    </button>
  </div>
</header>

<header id="header" *ngIf="!isInPay && !isInPrintReceipt" class="store-header secondary-text-color secondary-bg-color" #wholeHeader>
  <div class="skip-to-content" *ngIf="!embedded">
    <button
       id="skip-to-main-content-btn"
       (focus)="onSkipToMainFocus()"
       (click)="skipToMainContent()"
       class="action-bg-color action-inverse-text-color btn btn-sm"
    >{{'component.header.skip_to_main_content' | translate}}</button>
  </div>

  <div class='notifications text-center' id="notifications" ftsDimensions (onDimensionsChange)="notificationsDimensionsChanged($event)"
       ftsSticky [stackingOrder]="1" [topMargin]="0">
    <div class='message' *ngIf="!(isSelfOrder$ | async) && (storeStatus?.statusMessage || selectedDeferDateCalendarDescription)">{{!!selectedDeferDateCalendarDescription ? selectedDeferDateCalendarDescription : storeStatus?.statusMessage}}</div>

    <div class="message selection-bg-color selection-inverse-text-color featured-notification" *ngFor="let featuredAnnouncement of featuredAnnouncements$ | async; trackBy: trackByAnnouncement">
      <span>{{featuredAnnouncement.title}}</span>
      <button class='btn btn-link btn-undecorated close-btn' (click)='dismissFeaturedAnnouncement(featuredAnnouncement)'>
        <img src="assets/img/close.svg" alt="" aria-hidden="true"/>
        <span class="sr-only">{{'component.header.close' | translate}}</span>
      </button>
    </div>

    <div *ngIf="(showBanner$ | async) && !embedded && !bannerInLocalStorage" id="mobile-banner" style="background-color:white">
       <div class="banner-wrapper">
        <button ngClass="banner-close-btn" id='banner-close-btn' class='btn btn-link btn-undecorated font-secondary text-uppercase' (click)='dismissBanner()'>
          <fa-icon   [icon]="faTimes"  [size]="'1x'"></fa-icon>
          <span class="sr-only">{{'component.header.close' | translate}}</span>
        </button>
        <div *ngIf="(socialLinks$ | async)?.mobileAppLogo" class="banner-logo-wrapper">
            <img id='banner-logo' class="banner-logo" [alt]="'component.header.mobile_banner_logo' | translate" [src]="(socialLinks$ | async)?.mobileAppLogo" aria-hidden="true">
        </div>
        <div class="title-wrapper">
            <span id='banner-title' >{{(socialLinks$ | async)?.mobileAppTitle}}</span>
            <span id='banner-description' >{{"component.header.mobile_app_banner_category" | translate}}</span>
            <span id='banner-description' >{{(socialLinks$ | async)?.mobileAppDescription}}</span>
        </div>
        <div class="banner-action-button-container">
          <button  class="btn btn-link btn-undecorated banner-action-button" (click)="dismissBannerAndRedirect()">
              {{"component.header.mobile_app_banner_action" | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>


  <ng-container *ngIf="(breakpoint.isTabletDown$ | async)">
    <ng-container *ngTemplateOutlet="storeSelectionTemplate"></ng-container>
  </ng-container>

  <div *ngIf="!((isInHome && !theme.minifiedHeader) || (isInHome && theme.minifiedHeader && (breakpoint.isTabletDown$ | async)))" #header>

    <ng-container *ngIf="!theme.minifiedHeader">
      <fts-banner-header
        *ngIf="!embedded && !theme.compactHeader"
        [order]="order"
        [store]="store"
        [hasMultipleOrderTypeConfigs]="hasMultipleOrderTypeConfigs"
        [isInCheckout]="isInCheckout"
        [theme]="theme"
        [isMobile]="(breakpoint.isTabletDown$ | async)"
        (logoHeightChange)="logoHeightChanged($event)"
        (verticalMenuHeightRecalc)="calculateVerticalMenuHeight()"
        (bannerHeaderChange)="onBannerHeaderChange()"
      ></fts-banner-header>

      <fts-menu-links
        [theme]="theme"
        [loyaltyPlan]="loyaltyPlan"
        [store]="store"
        [hasMultipleOrderTypeConfigs]="hasMultipleOrderTypeConfigs"
        [account]="account"
        [order]="order"
        [panel]="panel"
        [visibleMenu]="visibleMenu"
        [showTopOrderTree]="showTopOrderTree"
        [isInEditor]="isInEditor"
        [orderTypeConfig]="orderTypeConfig"
        [headerFeaturedLinks]='headerLinksFeaturedContent$ | async'
        [isInCheckout]="isInCheckout"
        [isSelfOrder]="isSelfOrder$ | async"
        (login)="handleLogin()"
        (logout)="handleLogout()"
        (onToggleMobileMenu)="toggleMobileMenu($event)"
      ></fts-menu-links>

      <fts-compact-header
        *ngIf="theme.compactHeader"
        [embedded]="embedded"
        [theme]="theme"
        [loyaltyPlan]="loyaltyPlan"
        [store]="store"
        [hasMultipleOrderTypeConfigs]="hasMultipleOrderTypeConfigs"
        [account]="account"
        [order]="order"
        [panel]="panel"
        [(visibleMenu)]="visibleMenu"
        [showTopOrderTree]="showTopOrderTree"
        [orderTypeConfig]="orderTypeConfig"
        [isInCheckout]="isInCheckout"
        [isInIntro]="isInIntro"
        [featuredContents]='headerLinksFeaturedContent$ | async'
        [isSelfOrder]="isSelfOrder$ | async"
        (login)="handleLogin()"
        (logout)="handleLogout()"
        (onToggleMobileMenu)="toggleMobileMenu($event)"
      ></fts-compact-header>
    </ng-container>


    <fts-minified-header
      *ngIf="theme.minifiedHeader"
      [store]="store"
      [order]="order"
      [account]="account"
      [theme]="theme"
      [loyaltyPlan]="loyaltyPlan"
      [hasMultipleOrderTypeConfigs]="hasMultipleOrderTypeConfigs"
      [isInIntro]="isInIntro"
      [isInHome]="isInHome"
      [isSelfOrder]="isSelfOrder$ | async"
      [isInEditor]="isInEditor"
      [isInCheckout]="isInCheckout"
      [visibleMenu]="visibleMenu"
      [panel]="panel"
      [featuredContents]="headerLinksFeaturedContent$ | async"
      (login)="handleLogin()"
      (logout)="handleLogout()"
      (onToggleMobileMenu)="toggleMobileMenu($event)">
    </fts-minified-header>
  </div>

</header>

<ng-template #storeSelectionTemplate>
  <div [ngClass]="{'modal-content primary-bg-color primary-text-color' : !(breakpoint.isTabletDown$ | async)}">
    <div [ngClass]="{'modal-body no-padding' : !(breakpoint.isTabletDown$ | async)}">
      <fts-store-selection
        [show]="!!storeStates"
        [account]="account"
        [storeStates]="storeStates"
        [orderTypeConfig]="orderTypeConfig"
        [orderTypeConfigs]="orderTypeConfigs$ | async"
        [order]="order"
        [storeConfig]="store"
        [isInIntroOrHome]="isInIntro || isInHome"
        [theme]="theme"
        [isMobile]="(breakpoint.isTabletDown$ | async)"
        [orderDeferOptions]="orderDeferOptions"
        [orderDeferOptionsFetched]="orderDeferOptionsFetched"
        (selectionDone)="handleCloseStoreSelection()"></fts-store-selection>
    </div>
  </div>
</ng-template>
