<div [ngClass]="{'rendered-in-modal': !isInIntro}">
  <div class='step-entry'>
    <fts-order-type-chooser
      [selectedOrderType]='order.orderType'
      (onOrderTypeChange)='chooseOrderType($event)'>
    </fts-order-type-chooser>
  </div>

  <div class="text-center" *ngIf="(storeConfig$ | async)?.allowGroupWebOrdering && isInIntro">
    <div class="group-order-checkbox-wrapper">
      <fts-themeable-checkbox  [name]="'groupOrder'"
                              [(ngModel)]="isGroupOrder"
                              (ngModelChange)="onGroupOrderChange()">
        {{'component.order_type_form.make_it_go' | translate}}
      </fts-themeable-checkbox>
    </div>
  </div>

  <div class='step-entry' [ngClass]="{'overflow-visible': timeChooserOverflowVisible}"
      [@expanded]='showOrderTimeChooser' (@expanded.done)="timeChooserOverflowVisible = showOrderTimeChooser" *ngIf="showOrderTimeChooser">
    <fts-order-time-chooser
      [orderType]='order.orderType'
      [deferTime]='order.deferTime'
      [deferChoice]="deferChoice"
      (deferChoiceChange)="chooseDeferChoice($event)"
      [orderTimeInfo]="orderTimeInfo"
      [initFromSavedState]="initFromSavedState"
      (orderTimeChange)='chooseOrderTime($event)'
      (orderTimeSelectionChanged)="onOrderTimeSelectionChanged($event)">
    </fts-order-time-chooser>
  </div>

  <div class="step-entry" [@expanded]='showAddressChooser' *ngIf="!hasRenderedHomePageOnInit && showAddressChooser">
    <fts-guest-address-chooser
      *ngIf='!account.isInstantiated'
      [addressValidationErrors]='addressValidationErrors'
      [(customerAddress)]='customerAddress'
      [isInIntro]="isInIntro"
      (signInClick)="redirectToLogin()"
      (streetChange)="onStreetChange($event)"
      (addressTypeChange)="onAddressTypeChange($event)"
      (addressChange)="onAddressChange($event)"
      (addressErrors)="updateErrors($event, 'addressForm')"></fts-guest-address-chooser>

    <div class="row" *ngIf='account.isInstantiated'>
      <div class="col-12 text-center">
        <h3 class="h5">
          {{'component.order_type_form.where_delivered' | translate}}
        </h3>
        <fts-add-new-address [useAddressModal]="true"
                             (streetChange)="onStreetChange($event)"
                             (addressErrors)="updateErrors($event, 'addressForm')"></fts-add-new-address>
        <hr aria-hidden="true">
        <fts-account-address-chooser *ngIf='account.isInstantiated'
                                    [(selectedAddress)]='customerAddress'
                                    [store]="store"
                                    [order]="order"
                                    (streetChange)="onStreetChange($event)"
                                    (selectedAddressChange)="onCustomerAddressChange()"></fts-account-address-chooser>
      </div>
    </div>
  </div>

  <div class="generic-errors-wrapper" *ngIf="genericErrors.length > 0">
    <div role="alert" aria-atomic="true" class="alert alert-danger alert-form error-message" *ngFor="let error of genericErrors">{{error}}</div>
  </div>
  <ng-container *ngIf="showValidation && !showMenuLabel">
    <div *ngFor="let terror of (templateErrors | errorList)"
          class="alert alert-danger alert-form error-message mx-auto w-fit-content"
          role="alert" aria-atomic="true">{{terror}}</div>
  </ng-container>

  <div class="text-center">
    <button ftsAsyncButton
            ftsAuditableButton
            id='continue-to-menu'
            class='btn btn-lg action-bg-color action-inverse-text-color shadowed text-uppercase primary-btn action-btn font-secondary'
            [disabled]="loadingTimeDefferOptions$ | async"
            [subscription]='loadingSubscription'
            (click)='continueToMenu()'>
      <ng-container *ngIf="isInIntro">
        {{(showMenuLabel ? 'component.order_type_form.view_menu' : 'component.order_type_form.start_order') | translate}}
      </ng-container>
      <ng-container *ngIf="!isInIntro">{{'component.order_type_form.continue_ordering' | translate}}</ng-container>
    </button>

    <div *ngIf="store?.webOrderingIntroDisclaimer && !isInIntro" class="margin-top-20">
      {{store.webOrderingIntroDisclaimer}}
    </div>
  </div>
</div>
