<div *ngIf="!embedded && store?.privacyPolicyAcknowledgment !== privacyPolicyAck.NONE && !policyAcknowledged"
      class="policy-alert">
  <div class="policy-overlay secondary-bg-color"></div>
  <span class="secondary-text-color">
  {{'component.policy_alert.message' | translate}}
  <a *ngIf="!!store?.privacyPolicyUrl" target="_blank" class="btn btn-sm action-bg-color action-inverse-text-color shadowed primary-btn action-btn mb-1 privacy-policy-btn" [attr.aria-label]="('component.policy_alert.link_alt' | translate) + ' ' + ('route.new_tab.opens_new_tab' | translate)"
  [href]="store?.privacyPolicyUrl" rel="noopener noreferrer" translate>component.policy_alert.link_alt</a>
  </span>
  <button class="btn btn-link btn-undecorated no-padding secondary-text-color margin-left-10"
      (click)="policyDismiss()" [attr.aria-label]="'component.policy_alert.dismiss_aria_label' | translate">
    <fa-icon [icon]="faTimes" [size]="'lg'"></fa-icon>
  </button>
</div>
