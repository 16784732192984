<div class='custom-radio' (keyup.enter)="onGroupSelect(value, $event)" (click)="onGroupSelect(value, $event)"
     [ngClass]="{'full-width': fullWidth, 'disabled': disabled, 'ingredient': ingredient, 'no-padding': noPadding,'position-relative': ingredient, 'top-align': topAlignRadio || isHalfAndHalf, 'baseline-alignment': baselineAlignment, 'outline': focused}">
  <fa-icon [size]="'lg'" *ngIf="isUnavailable && storeConfig?.showSizeSpecificIngredients" class="disabled-icon text-danger" [icon]="faBan"></fa-icon>
  <input *ngIf="!isHalfAndHalf" [attr.id]="!!id ? id : undefined" type="radio" [name]="name" [value]="value" [disabled]='disabled' [checked]='checked'  [attr.aria-label]="decriptiveLabel"
  (focus)="onInputFocus()"
  (blur)="onInputBlur()">
  <span *ngIf="!isHalfAndHalf" aria-hidden="true" class='custom-radio-indicator' [ngClass]="{'selection-inverse-bg-color selection-border-color': checked, 'highlighted': highlighted, 'top-align': topAlignRadio}"></span>
  <div  class='custom-radio-details' [ngClass]="{'text-muted': disabled && !(isUnavailable && storeConfig?.showSizeSpecificIngredients)}">
    <span *ngIf="label">{{label}}</span>
    <ng-content></ng-content>
  </div>
</div>