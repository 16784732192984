<div class='modal-content primary-bg-color primary-text-color'>
  <div class='modal-header secondary-bg-color secondary-text-color title-wrapper'>
    <h2 id="modal-title" class='modal-title float-left title'>{{title}}</h2>
    <h2 *ngIf="!!errorFromSponsorParam" id="modal-help-title" class='modal-title float-left title'>{{'component.fundraiser_sponsors_modal.select_available_sponsor' | translate}}</h2>
    <button class='close float-right' (click)='hideModal()' [attr.aria-label]="'component.fundraiser_sponsors_modal.close' | translate">
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body transparent text-left'>
    <fts-fundraiser-sponsor-entry *ngFor="let sponsor of fundraiser.sponsors"
                                  [sponsorName]="sponsor.name"
                                  [sponsorSelected]="isSponsorSelected(sponsor)"
                                  [sponsorNotes]="findOrderedSponsorNotes(sponsor)"
                                  [fundraiser]="fundraiser"
                                  (sponsorSelectionChanged)="onSponsorSelectionChanged(sponsor)"
                                  (sponsorNotesChanged)="onSponsorNotesChanged($event, sponsor)"></fts-fundraiser-sponsor-entry>

    <div class="alert alert-danger alert-form" *ngFor="let error of errors?.base" role="alert" aria-atomic="true">{{error}}</div>
    <div class="alert alert-danger alert-form" role="alert" aria-atomic="true" *ngIf="!!errorMessage">{{errorMessage}}</div>
  </div>
  <div class='modal-footer'>

    <button class="btn primary-bg-color action-text-color text-uppercase dismiss-btn action-btn font-secondary" (click)='hideModal()'>
      {{'component.fundraiser_sponsors_modal.cancel' | translate}}
    </button>
    <button ftsAsyncButton [subscription]="loadingSubscription"
            (click)="orderedFundraiser ? updateOrderedFundraiser() : addFundraiserToOrder()"
            class="btn action-bg-color action-inverse-text-color text-uppercase primary-btn action-btn font-secondary">
      {{(orderedFundraiser ? 'component.fundraiser_sponsors_modal.update' : 'component.fundraiser_sponsors_modal.apply') | translate}}
    </button>
  </div>
</div>
