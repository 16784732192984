import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Account, StoreConfig, LoyaltyPlan, Order, AuditService, FeaturedContent } from 'ngx-web-api';
import { MobileMenu } from '../../../domain/mobile-menu.enum';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'fts-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  order: Order;
  @Input()
  account: Account;
  @Input()
  store: StoreConfig;
  @Input()
  loyaltyPlan: LoyaltyPlan;
  @Input()
  hasMultipleOrderTypeConfigs: boolean;
  @Input()
  isInIntro: boolean;
  @Input()
  isSelfOrder: boolean;
  @Input()
  isInCheckoutDetails: boolean;
  @Input()
  isInCheckout: boolean;
  @Input()
  openSidebarMenu: boolean;
  @Input()
  featuredContents: FeaturedContent[];

  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  sidebarMenuClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onToggleMobileMenu: EventEmitter<MobileMenu> = new EventEmitter();

  sidebarMenuVisible = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private auditService: AuditService, private router: Router) {}

  @HostListener('document:keydown', ['$event'])
  onKeyPress(event) {
    if (this.sidebarMenuVisible && ((event.key !== undefined && event.key === 'Esc') || event.key === 'Escape')) {
      this.toggleSidebarMenu();
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.sidebarMenuVisible) {
          this.toggleSidebarMenu();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['openSidebarMenu'] && changes['openSidebarMenu'].currentValue) {
      this.toggleSidebarMenu();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebarMenu() {
    this.sidebarMenuVisible = !this.sidebarMenuVisible;
    this.auditService.createAudit(() => `Right sidebar menu ${this.sidebarMenuVisible ? 'expanded' : 'collapsed'}.`);

    if (!this.sidebarMenuVisible) {
      this.sidebarMenuClosed.emit();
    }
  }

  toggleStoreChooser() {
    this.onToggleMobileMenu.next(MobileMenu.storeChooser);
    this.toggleSidebarMenu();
  }
}
