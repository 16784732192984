import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Account, AccountService } from 'ngx-web-api';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class NeedsSignedOutGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.accountService.account$.pipe(
      filter((account: Account) => !account.dummy),
      map((account: Account) => {
        if (account.isInstantiated) {
          this.router.navigate(['/intro'], { queryParamsHandling: 'merge' });
          return false;
        }

        return true;
      })
    );
  }
}
