import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  Account,
  ConfigService,
  LoyaltyPlan,
  Order,
  OrderType,
  OrderTypeConfig,
  Panel,
  StoreConfig,
  FeaturedContent,
  Utils,
} from 'ngx-web-api';
import { Observable, Subscription, Subject, combineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Animations } from '../../../animations';
import { MobileMenu } from '../../../domain/mobile-menu.enum';
import { Theme } from '../../../domain/theme.model';
import { UiOrchestratorService } from '../../services/ui-orchestrator.service';
import { ModalService } from '../../services/modal.service';
import { ViewPort } from '../../../domain/view-port.model';
import { ViewportService } from '../../services/viewport.service';
import { PagePosition } from '../../../domain/page-position.enum';
import { BreakpointService } from '../../services/breakpoint.service';
import { faShoppingCart, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Router, NavigationEnd } from '@angular/router';
import { FocusService } from 'app/focus.service';
import { PathService } from 'app/shared/services/path.service';
import { MenuWrapperService } from '../../services/menu-wrapper.service';

@Component({
  selector: 'fts-menu-links',
  templateUrl: './menu-links.component.html',
  styleUrls: ['./menu-links.component.scss'],
  animations: [Animations.slideX, Animations.expanded],
})
export class MenuLinksComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  theme: Theme;
  @Input()
  visibleMenu: MobileMenu;
  @Input()
  store: StoreConfig;
  @Input()
  account: Account;
  @Input()
  loyaltyPlan: LoyaltyPlan;
  @Input()
  order: Order;
  @Input()
  panel: Panel;
  @Input()
  showTopOrderTree: boolean;
  @Input()
  isInEditor: boolean;
  @Input()
  orderTypeConfig: OrderTypeConfig;
  @Input()
  hasMultipleOrderTypeConfigs: boolean;
  @Input()
  headerFeaturedLinks: FeaturedContent[];
  @Input()
  isInCheckout = false;
  @Input()
  isSelfOrder: boolean;

  @Output()
  onToggleMobileMenu: EventEmitter<MobileMenu> = new EventEmitter();
  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('headerMenu', { static: true })
  headerMenu: ElementRef;

  menus = MobileMenu;
  faShoppingCart = faShoppingCart;
  faBars = faBars;
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  private destroy$: Subject<void> = new Subject<void>();
  loadingStoreStates: Subscription;
  viewPort$: Observable<ViewPort>;
  pagePosition = PagePosition;
  isInCheckoutDetails = false;
  orderTreeIsOpen = false;
  isInIntro = false;
  private isMobile: boolean;
  isInThanksPage$: Observable<boolean>;
  headerOrderTree: any;
  mobileOrderMenu: any;
  orderNotification: any;
  shouldRenderOverlayMenu = false;
  showMenuBtn = false;
  showMinifiedMenu = false;
  mobileMenuHeight$: Observable<number>;
  mobileMenuTopPosition$: Observable<number>;
  contentMobileMenuHeight$: Observable<number>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private uiOrchestrationService: UiOrchestratorService,
    private configService: ConfigService,
    private modalService: ModalService,
    private viewPortService: ViewportService,
    public breakPointService: BreakpointService,
    private router: Router,
    private focusService: FocusService,
    private pathService: PathService,
    public menuWrapperService: MenuWrapperService
  ) {
    this.isInThanksPage$ = this.pathService.inThanks$;
    if (this.pathService.inOrderRelatedRoutes()) {
      this.importLazyComponents();
    }
  }

  ngOnInit(): void {
    this.mobileMenuHeight$ = this.uiOrchestrationService.mobileMenuHeight$;
    this.mobileMenuTopPosition$ = this.uiOrchestrationService.mobileMenuTopPosition$;
    this.contentMobileMenuHeight$ = this.uiOrchestrationService.contentMobileMenuHeight$;

    this.breakPointService.isTabletDown$.pipe(takeUntil(this.destroy$)).subscribe(isMobile => {
      this.isMobile = isMobile;
      this.calcShouldRenderOverlayMenu();
    });

    this.uiOrchestrationService.orderTreeShown$
      .pipe(takeUntil<void>(this.destroy$))
      .subscribe(() => this.toggleMobileMenu(this.menus.orderTree));
    this.viewPort$ = this.viewPortService.viewport$;

    combineLatest([this.pathService.inMenu$, this.uiOrchestrationService.orderTreeIsOpen$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([inMenu, isOpen]) => {
        this.orderTreeIsOpen = isOpen;
        if (!(this.theme.hasSinglePageMenu && inMenu)) {
          if (this.orderTreeIsOpen && this.isMobile && this.theme.compactHeader) {
            document.querySelector(`body`).classList.add('mobile-overlay');
          } else {
            document.querySelector(`body`).classList.remove('mobile-overlay');
          }
        }
        this.cdRef.detectChanges();
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((val: NavigationEnd) => {
        this.isInIntro = val.urlAfterRedirects.split('?')[0] === '/intro';
        this.isInCheckoutDetails = val.url === '/checkout/details';
        if (this.pathService.inOrderRelatedRoutes(val.url)) {
          this.importLazyComponents();
        }
      });

    this.uiOrchestrationService.showMinifiedMenu$.pipe(takeUntil(this.destroy$)).subscribe(showMinifiedMenu => {
      this.showMinifiedMenu = showMinifiedMenu;
      this.cdRef.detectChanges();
      this.calcShouldRenderOverlayMenu();
    });

    this.uiOrchestrationService.showMenuBtn$.pipe(takeUntil(this.destroy$)).subscribe(showMenuBtn => {
      this.showMenuBtn = showMenuBtn;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const visibleMenuChange = changes['visibleMenu'];
    if (!visibleMenuChange) {
      this.uiOrchestrationService.setMobileMenuChanged(true);
      return;
    }

    this.calcShouldRenderOverlayMenu();
    if (visibleMenuChange && !Utils.isNullOrUndefined(changes['visibleMenu'].currentValue) && this.isMobile) {
      if (visibleMenuChange.currentValue !== MobileMenu.storeChooser) {
        setTimeout(() => {
          this.focusService.applyFocus('overlay-menu');
        }, 100);
      }
    }

    if (visibleMenuChange.currentValue === MobileMenu.storeChooser) {
      this.openStoreChooser();
    }

    if (visibleMenuChange.previousValue === MobileMenu.storeChooser) {
      this.closeStoreChooser();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleOverlayClick() {
    this.onToggleMobileMenu.next(undefined);
  }

  get hasOrderType() {
    return this.order.orderType && this.order.orderType !== OrderType.Any;
  }

  toggleStoreChooser() {
    this.onToggleMobileMenu.next(MobileMenu.storeChooser);
  }

  toggleMobileMenu(visibleMenu: MobileMenu) {
    this.onToggleMobileMenu.next(visibleMenu);
  }

  handleLogin() {
    this.login.emit();
  }

  handleLogout() {
    this.logout.emit();
  }

  expandedAnimationEnd() {
    this.uiOrchestrationService.setOrderTreeAnimationEnd();
  }

  private closeStoreChooser() {
    if (this.loadingStoreStates && !this.loadingStoreStates.closed) {
      this.loadingStoreStates.unsubscribe();
    }
    this.uiOrchestrationService.setStoreStates(null);
  }

  private openStoreChooser() {
    this.uiOrchestrationService.storeChooseModalTriggered();
    if (!this.store.hasStoreChooser) {
      this.uiOrchestrationService.setStoreStates([]);
      return;
    }
    this.loadingStoreStates = this.configService
      .fetchStoreStates()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (ss: string[]) => {
          this.uiOrchestrationService.setStoreStates(ss);
        },
        e => this.modalService.parseAndNotifyErrors(e)
      );
  }

  private importLazyComponents() {
    if (!this.headerOrderTree) {
      this.headerOrderTree = import(`../header-order-tree/header-order-tree.component`).then(
        ({ HeaderOrderTreeComponent }) => HeaderOrderTreeComponent
      );
    }
    if (!this.mobileOrderMenu) {
      this.mobileOrderMenu = import(`../mobile-order-menu/mobile-order-menu.component`).then(
        ({ MobileOrderMenuComponent }) => MobileOrderMenuComponent
      );
    }
    if (!this.orderNotification) {
      this.orderNotification = import(`../../../shared/order-notification/order-notification.component`).then(
        ({ OrderNotificationComponent }) => OrderNotificationComponent
      );
    }
  }

  private calcShouldRenderOverlayMenu(): void {
    this.shouldRenderOverlayMenu =
      this.visibleMenu !== undefined &&
      this.visibleMenu !== this.menus.storeChooser &&
      !(this.visibleMenu === this.menus.orderTree && !this.isMobile);
  }
}
