import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, Subject, merge } from 'rxjs';
import { Order, DeferChoice, StoreConfig, Account, FieldDisplayPolicy, Constants, OrderService } from 'ngx-web-api';
import { map, distinctUntilKeyChanged, distinctUntilChanged, filter, take } from 'rxjs/operators';
import { InitParamsStorageService } from 'app/core/services/init-params-storage.service';
import { OrderingUtilsService } from 'app/core/services/ordering-utils.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private account: Subject<Account> = new Subject();
  private order: Subject<Order> = new Subject();
  private store: Subject<StoreConfig> = new Subject();
  private needsAddress: Subject<boolean> = new Subject();
  private timeSlotsCheck: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private deferChoiceOptionsCheck: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private selectedDeferChoice: Subject<DeferChoice> = new Subject();
  private checkValidationForContinueButton: Subject<any> = new Subject();
  private needsLastname: Subject<boolean> = new Subject();
  private addressFormValid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private customerInfoFormValid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public revalidateCustomerInfo: Subject<void> = new Subject();
  public dineInFormValid: Subject<boolean> = new Subject();

  public continueButtonDisabled$: Observable<boolean>;
  isSelfOrder: boolean;

  constructor(
    private initParamsService: InitParamsStorageService,
    private orderService: OrderService,
    private orderingUtilsService: OrderingUtilsService
  ) {
    this.orderingUtilsService.isSelfOrder$.subscribe(isSelfOrder => {
      this.isSelfOrder = isSelfOrder;
      this.initContinueButtonDisabled();
    });
  }

  initContinueButtonDisabled() {
    this.continueButtonDisabled$ = this.isSelfOrder
      ? this.checkContinueButtonDisabledInSelfOrder()
      : combineLatest([
          this.account,
          this.order,
          this.store,
          this.needsAddress,
          this.timeSlotsCheck,
          this.deferChoiceOptionsCheck,
          this.selectedDeferChoice,
          this.checkValidationForContinueButton,
          this.needsLastname,
          this.addressFormValid,
          this.customerInfoFormValid,
        ]).pipe(
          map(
            ([
              account,
              order,
              store,
              needsAddress,
              timeSlotsCheck,
              deferChoiceOptionsCheck,
              selectedDeferChoice,
              checkValidationForContinueButton,
              needsLastname,
              addressFormValid,
              customerInfoFormValid,
            ]) => {
              if (
                (!order.customer.address && needsAddress) ||
                timeSlotsCheck ||
                deferChoiceOptionsCheck ||
                (selectedDeferChoice !== DeferChoice.Asap && !order.deferTime) ||
                !order.customer.firstName ||
                (needsLastname && !order.customer.lastName) ||
                (!account.isInstantiated && store.guestCheckoutEmailPolicy === FieldDisplayPolicy.Require && !order.customer.email) ||
                !order.customer.phone ||
                order.customer.phone.replace(/\D+/g, '').length !== Constants.PHONE_DIGITS ||
                (account.isInstantiated &&
                  needsAddress &&
                  (!order.customer.address.street || (order.customer.address.warnings && order.customer.address.warnings.length))) ||
                !addressFormValid ||
                !customerInfoFormValid
              ) {
                return true;
              } else {
                return false;
              }
            }
          )
        );
  }

  setAccount(account: Account) {
    this.account.next(account);
  }

  setOrder(order: Order) {
    this.order.next(order);
  }

  setStore(store: StoreConfig) {
    this.store.next(store);
  }

  setNeedsAddress(needsAddress: boolean) {
    this.needsAddress.next(needsAddress);
  }

  setTimeSlotsCheck(timeSlotsCheck: boolean) {
    this.timeSlotsCheck.next(timeSlotsCheck);
  }

  setDeferChoiceOptionsCheck(deferChoiceOptionsCheck: boolean) {
    this.deferChoiceOptionsCheck.next(deferChoiceOptionsCheck);
  }

  setSelectedDeferChoice(selectedDeferChoice: DeferChoice) {
    this.selectedDeferChoice.next(selectedDeferChoice);
  }

  setNeedsLastname(needsLastname: boolean) {
    this.needsLastname.next(needsLastname);
  }

  setAddressFormValid(addressFormValid: boolean) {
    this.addressFormValid.next(addressFormValid);
  }

  setValidationForContinueButton() {
    this.checkValidationForContinueButton.next();
  }

  setCustomerInfoFormValid(customerInfoFormValid: boolean) {
    this.customerInfoFormValid.next(customerInfoFormValid);
  }

  setDineInFormValid(dineInForm: boolean) {
    this.dineInFormValid.next(dineInForm);
  }

  validCheckoutWithUrlParams(): Observable<Order> {
    return merge(this.order, this.orderService.order$).pipe(
      distinctUntilKeyChanged('itemsCount'),
      filter(order => order?.itemsCount > 0 && this.initParamsService.canNavigateToCheckout()),
      take(1)
    );
  }

  checkContinueButtonDisabledInSelfOrder(): Observable<boolean> {
    return combineLatest([this.dineInFormValid, this.customerInfoFormValid]).pipe(
      map(([dineInFormValid, customerInfoFormValid]) => {
        return !dineInFormValid || !customerInfoFormValid;
      }),
      distinctUntilChanged()
    );
  }
}
