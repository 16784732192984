import { DeserializationUtils } from '../../utils/deserialization-utils';
import { BaseResource } from '../core/base-resource';
import { Sponsor } from './sponsor.model';

export class Fundraiser extends BaseResource {
  name: string;
  description: string;
  noteLabel: string;
  sponsorLabel: string;
  notesEnabled: boolean;
  sponsorsEnabled: boolean;
  sponsors: Sponsor[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      sponsors: DeserializationUtils.deserializeChildren(input, 'sponsors', Sponsor),
    });
  }
}
