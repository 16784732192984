import { BaseResource } from '../core/base-resource';
import { OrderableSpecialDefaultIngredient } from './orderable-special-default-ingredient.model';
import { DeserializationUtils } from '../../utils/public_api';

export class OrderableSpecialDefaultItem extends BaseResource {
  quantity: number;
  item: string;
  size: string;
  ingredients: OrderableSpecialDefaultIngredient[];

  deserialize(input: any): this {
    return Object.assign(this, input, {
      ingredients: DeserializationUtils.deserializeChildren(input, 'ingredients', OrderableSpecialDefaultIngredient),
    });
  }
}
