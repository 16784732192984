<img class="fading-img"
     [ngClass]="{'cursor-pointer': featuredItem.isClickable, 'cover': fillParentHeight}"
     ftsPicture
     [skipLazyLoading]="skipLazyLoading"
     #pictureRef='pictureRef'
     [imgSrc]='featuredItemPicture?.src'
     [imgSrcset]='featuredItemPicture?.srcset'
     [imgName]="featuredItemPicture?.name"
     [isFeatureItem]="true"
     [alt]="featuredItem?.title"
     [@display]="state"
     (load)="imgLoaded()"
     #imgEl />
<fts-ghost-element *ngIf='!skipLazyLoading && (featuredItemPicture?.src || featuredItemPicture?.srcset) && !pictureRef?.isLoaded ' [height]="itemImgHeight ||240"></fts-ghost-element>
<div *ngIf='!featuredItem?.imageOnly && (featuredItem?.title || featuredItem?.subtitle)'
     class='titles-wrapper text-center'
     aria-hidden="true"
     [ngClass]="{'cursor-pointer': featuredItem.isClickable}"
     [@display]="state">
  <h3 aria-hidden="true" *ngIf='featuredItem?.title' class='fw-600'>{{featuredItem?.title}}</h3>
  <p aria-hidden="true" *ngIf='featuredItem?.subtitle'>{{featuredItem?.subtitle}}</p>
</div>