import { Street } from 'ngx-web-api';
import { BasicModalContext } from './basic-modal-context';

export class AcknowledgeGeolocationAddressModalComponentContext extends BasicModalContext {
  constructor(public lat: number, public lng: number, public matchedStreet: Street) {
    super();
    this.class = 'md';
    this.config = { lat, lng, matchedStreet };
  }
}
