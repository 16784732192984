import { Utils } from '../../utils/utils';
import { BaseResource } from '../core/base-resource';
import { GenericObject } from '../core/generic-object';
import { QuestionCategory } from './question-category.model';
import { Question } from './question.model';
import { QuestionnaireResponse } from './questionnaire-response.model';
import { QuestionnaireType } from './questionnaire-type.enum';
import { RateableItem } from './rateable-item.model';

export class Questionnaire extends BaseResource {
  name: string;
  type: QuestionnaireType;
  publishable: boolean;
  allowAnonymous: boolean;
  allowSubscribe: boolean;
  registeredOnly: boolean;
  introText: string;
  submitValue: string;
  needsCustomerName: boolean;
  bottomText: string;
  topText: string;
  orderRatingLabel: string;
  itemRatingLabels: string[];
  rateableItems: RateableItem[];
  categories: QuestionCategory[];
  responses: GenericObject<QuestionnaireResponse>;
  customerName?: string;
  email?: string;
  allowPublish: boolean;
  allowEmail: boolean;
  isDefault: boolean;
  linkRef?: string;

  get shouldShowName() {
    return this.type !== QuestionnaireType.EXPERIENCE;
  }

  get allQuestions(): Question[] {
    return Utils.flatten(this.categories.map(cat => cat.questions));
  }

  initQuestionnaireResponses(rateableItems?: RateableItem[], referencedItem?: RateableItem): void {
    const itemKey = `${referencedItem ? referencedItem.key : ''}${referencedItem && referencedItem.key ? '_' : ''}`;
    this.responses = {};
    this.categories.forEach(category => category.questions.forEach(question => (this.responses[`${itemKey}${question.id}`] = {})));
    if (rateableItems && rateableItems.length) {
      rateableItems.forEach(item => (this.responses[item.key] = {}));
    }
    this.allowPublish = false;
    this.allowEmail = false;
  }
}
