import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuditService, ConfigService, StoreConfig } from 'ngx-web-api';
import { ItemEditorService } from 'app/core/services/item-editor.service';
import { ViewportService } from 'app/core/services/viewport.service';
import { ItemEditorModalContext } from 'app/domain/item-editor-modal-context';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fts-item-editor-modal',
  templateUrl: './item-editor-modal.component.html',
  styleUrls: ['./item-editor-modal.component.scss'],
})
export class ItemEditorModalComponent implements OnInit, OnDestroy {
  public context: ItemEditorModalContext;
  storeConfig: StoreConfig;
  itemOrdinalId: number;
  itemName: string;
  finishLoading = false;
  orderedItemHasWarnings: boolean;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private configService: ConfigService,
    public viewportService: ViewportService,
    private itemEditorService: ItemEditorService,
    private auditService: AuditService
  ) {}

  ngOnInit(): void {
    this.auditService.createAudit(() => `Item Editor modal has been opened`);
    this.configService.storeConfig$.pipe(take(1)).subscribe((config: StoreConfig) => (this.storeConfig = config));
    this.itemOrdinalId = this.context.itemOrdinalId;
    this.itemEditorService.editingItem$.pipe(takeUntil(this.destroy$)).subscribe(editingItem => {
      if (!!editingItem?.orderedItem) {
        this.orderedItemHasWarnings = !!editingItem.orderedItem.warnings;
      }
      if (!!editingItem?.orderableItem) {
        this.itemName = editingItem.orderableItem?.name;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFinishLoading(finishLoading: boolean) {
    this.finishLoading = finishLoading;
  }
}
