import { BaseResource } from '../core/base-resource';
import { OrderPaymentType } from './order-payment-type.enum';

export class PaymentType extends BaseResource {
  displayName: string;
  type: OrderPaymentType;
  _paymentFee: number;
  _paymentFeeNoTax: number;
  _tax: number;
  paymentFeeText: string;
  paymentNote: string;
  paymentFeePercent: number;
  webPayEnabled: boolean;

  get paymentFee() {
    return this._paymentFee / 100;
  }

  /** @deprecated subject for removal */
  get paymentFeeNoTax() {
    return this._paymentFeeNoTax / 100;
  }

  /** @deprecated subject for removal */
  get tax() {
    return this._tax / 100;
  }

  set paymentFee(fee: number) {
    this._paymentFee = fee;
  }

  /** @deprecated subject for removal */
  set paymentFeeNoTax(fee: number) {
    this._paymentFeeNoTax = fee;
  }

  /** @deprecated subject for removal */
  set tax(tax: number) {
    this._tax = tax;
  }

  get isCreditCard(): boolean {
    return OrderPaymentType.CREDIT_CARD === this.type;
  }

  get isCash(): boolean {
    return OrderPaymentType.CASH === this.type;
  }

  get isGiftCard(): boolean {
    return OrderPaymentType.GIFT_CARD === this.type;
  }

  get isInvoice(): boolean {
    return OrderPaymentType.INVOICE === this.type || OrderPaymentType.HOUSE_ACCOUNT === this.type;
  }

  get isGoCart(): boolean {
    return OrderPaymentType.GO_CART === this.type;
  }
}
