import { ValidatedResource } from '../core/validated-resource';
import { Fundraiser } from './fundraiser.model';
import { OrderedSponsor } from './ordered-sponsor.model';

export class OrderedFundraiser extends ValidatedResource {
  fundraiserId: number;
  name: string;
  sponsors: OrderedSponsor[];
  sponsorable: boolean;

  static fromFundraiser(fundraiser: Fundraiser): OrderedFundraiser {
    const orderedSponsors = fundraiser.sponsorsEnabled ? [] : undefined;

    const orderedFundraiser: OrderedFundraiser = new OrderedFundraiser().deserialize({
      name: fundraiser.name,
      sponsors: orderedSponsors,
    });

    return orderedFundraiser;
  }

  get hasSponsors(): boolean {
    return this.sponsors && this.sponsors.length > 0;
  }
}
